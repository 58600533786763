import { Challenge, GeogroupService } from '@geovelo-frontends/commons';
import { Box, DialogProps, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import Dialog from '../../../../components/dialog';

function RemoveDialog({
  challenge,
  ...props
}: Omit<DialogProps, 'onClose'> & {
  challenge?: Challenge;
  onClose: (removed?: boolean) => void;
}): JSX.Element {
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  async function handleRemove() {
    if (!currentPartner || !challenge) return;

    setLoading(true);

    try {
      await GeogroupService.removeChallenge(currentPartner, challenge.id);

      props.onClose(true);
      enqueueSnackbar(t('commons.challenge_details.remove_dialog.success'));
    } catch {
      enqueueSnackbar(t('commons.challenge_details.remove_dialog.server_error'), {
        variant: 'error',
      });
    }

    setLoading(false);
  }

  return (
    <Dialog
      fullWidth
      dialogTitle={t('commons.challenge_details.remove_dialog.title')}
      loading={loading}
      maxWidth="sm"
      onCancel={props.onClose}
      onConfirm={handleRemove}
      {...props}
      title={t('commons.challenge_details.remove_dialog.title')}
    >
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography variant="body2">
          {t('commons.challenge_details.remove_dialog.description')}
        </Typography>
      </Box>
    </Dialog>
  );
}

export default RemoveDialog;
