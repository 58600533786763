import { IPhoto, Ride, RideService } from '@geovelo-frontends/commons';
import { AddCircleOutline, Delete, Edit } from '@mui/icons-material';
import { Avatar, Box, CardHeader, IconButton, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '../../../../components/card';
import ConfirmDialog from '../../../../components/confirm-dialog';

import ImageFormDialog from './image-form-dialog';

interface IProps {
  canWrite: boolean;
  onChange: (ride: Ride) => void;
  ride: Ride;
}

function ImageList({ canWrite, ride, onChange }: IProps): JSX.Element {
  const [photoToUpdate, setPhotoToUpdate] = useState<IPhoto | null>(null);
  const [formDialogOpen, openFormDialog] = useState(false);
  const [photoToRemove, setPhotoToRemove] = useState<number | null>(null);
  const [removeConfirmOpen, openRemoveConfirm] = useState(false);
  const [removing, setRemoving] = useState(false);
  const { t } = useTranslation();
  const { transitions } = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  function handleFormDialogClose(photo?: IPhoto) {
    const updatedRide = ride.clone();
    if (photo) {
      if (photoToUpdate) {
        updatedRide.photos.splice(
          ride.photos.findIndex(({ id }) => id === photoToUpdate.id),
          1,
          photo,
        );

        onChange(updatedRide);
      } else {
        updatedRide.photos.push(photo);
        onChange(updatedRide);
      }
    }

    openFormDialog(false);
    setTimeout(() => setPhotoToUpdate(null), transitions.duration.leavingScreen);
  }

  async function handleRemove() {
    if (!ride.id || !photoToRemove) return;

    setRemoving(true);

    try {
      await RideService.removeRidePhoto(ride.id, photoToRemove);

      const updatedRide = ride.clone();
      updatedRide.photos.splice(
        ride.photos.findIndex((photo) => photoToRemove === photo.id),
        1,
      );

      openRemoveConfirm(false);
      setPhotoToRemove(null);
      onChange(updatedRide);
      enqueueSnackbar(t('cycling-insights.ride.updated'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.not_updated'), { variant: 'error' });
    }

    setRemoving(false);
  }

  const photo = ride.photos?.[0];

  return (
    <>
      <Card
        actions={
          canWrite && !photo
            ? [
                {
                  children: t('cycling-insights.ride.image_list.actions.add'),
                  color: 'primary',
                  key: 'add',
                  onClick: () => openFormDialog(true),
                  startIcon: <AddCircleOutline />,
                  variant: 'contained',
                },
              ]
            : []
        }
        title={t('cycling-insights.ride.image_list.title')}
      >
        {photo ? (
          <CardHeader
            action={
              canWrite && (
                <Box display="flex" gap={0.5}>
                  <Tooltip title={t('commons.actions.remove')}>
                    <IconButton
                      onClick={() => {
                        if (photo.id) {
                          setPhotoToRemove(photo.id);
                          openRemoveConfirm(true);
                        }
                      }}
                      size="small"
                    >
                      <Delete color="error" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('commons.actions.update')}>
                    <IconButton
                      onClick={() => {
                        setPhotoToUpdate(photo);
                        openFormDialog(true);
                      }}
                      size="small"
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </Box>
              )
            }
            avatar={<Avatar src={photo.squaredThumbnailUrl} />}
            subheader={photo.copyright ? <>&copy; {photo.copyright}</> : ''}
            title={photo.title || t('cycling-insights.ride.image_list.item_title', { index: 1 })}
          />
        ) : (
          <Box padding={2}>
            <Typography color="textSecondary" variant="caption">
              {t('cycling-insights.ride.image_list.empty')}
            </Typography>
          </Box>
        )}
      </Card>
      <ConfirmDialog
        dialogTitle="ride-image-remove-confirm"
        loading={removing}
        onCancel={() => {
          openRemoveConfirm(false);
          setPhotoToRemove(null);
        }}
        onConfirm={handleRemove}
        open={removeConfirmOpen}
        title={t('cycling-insights.ride.image_list.remove_dialog.title')}
      />
      <ImageFormDialog
        onClose={handleFormDialogClose}
        open={formDialogOpen}
        photo={photoToUpdate}
        ride={ride}
      />
    </>
  );
}

export default ImageList;
