import { TFacilitiesType, THighwayType, TSurfaceType, useSource } from '@geovelo-frontends/commons';
import { blue, grey, orange, red } from '@mui/material/colors';
import center from '@turf/center';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DataDrivenPropertyValueSpecification,
  LineLayerSpecification,
  Map,
  Popup,
} from '!maplibre-gl';

function useScenario(
  map: Map | null | undefined,
  onClick: (value?: {
    id: number;
    geometry: GeoJSON.LineString;
    amenagement: TFacilitiesType;
    highway: THighwayType;
    surface: TSurfaceType;
  }) => void,
): {
  initialized: boolean;
  init: () => void;
  update: (collection: GeoJSON.FeatureCollection, creating?: boolean) => void;
  updateModifications: (
    roads: {
      id: number;
      road: {
        geometry: GeoJSON.LineString;
        properties: {
          amenagement: TFacilitiesType;
          highway: THighwayType;
          newAmenagement: TFacilitiesType | undefined;
          newHighway: THighwayType | undefined;
          newSurface: TSurfaceType | undefined;
          surface: TSurfaceType;
        };
      };
    }[],
  ) => void;
  updateTooltip: (
    section: {
      id: number;
      geometry: GeoJSON.LineString;
      amenagement: TFacilitiesType;
      highway: THighwayType;
      surface: TSurfaceType;
    },
    modification: {
      amenagement: TFacilitiesType | undefined;
      highway: THighwayType | undefined;
      surface: TSurfaceType | undefined;
    },
  ) => void;
  selectRoad: (
    id?: number,
    road?: {
      geometry: GeoJSON.LineString;
      properties: {
        amenagement: TFacilitiesType;
        blocked: boolean | undefined;
        highway: THighwayType;
        newAmenagement: TFacilitiesType | undefined;
        newHighway: THighwayType | undefined;
        newSurface: TSurfaceType | undefined;
        surface: TSurfaceType;
      };
    },
  ) => void;
  clear: () => void;
} {
  const [initialized, setInitialized] = useState(false);
  const initializedRef = useRef(false);
  const creatingRef = useRef(false);
  const clickedRoadIdRef = useRef<number | string | undefined>();
  const highlightedRoadTooltip = useRef<Popup>();
  const { t } = useTranslation();
  const {
    addGeoJSONSource: addHeatmapSource,
    getGeoJSONSource: getHeatmapSource,
    updateGeoJSONSource: updateHeatmapSource,
    clearGeoJSONSource: clearHeatmapSource,
  } = useSource(map, 'scenario');
  const {
    addGeoJSONSource: addModifiedRoadsSource,
    updateGeoJSONSource: updateModifiedRoadsSource,
    clearGeoJSONSource: clearModifiedRoadSource,
  } = useSource(map, 'roads-modified');
  const { addGeoJSONSource: addHighlightedRoadSource, getGeoJSONSource: getHighlightedRoadSource } =
    useSource(map, 'road-highlight');

  let hoveredRoadId: number | string | undefined;
  let blockTooltipRemove: boolean;

  function init() {
    if (!map || initializedRef.current) return;
    if (getHeatmapSource()) {
      initializedRef.current = true;
      setInitialized(true);
      return;
    }

    addHeatmapSource();
    addHighlightedRoadSource();
    addModifiedRoadsSource();

    highlightedRoadTooltip.current = new Popup({
      className: 'map-tooltip',
      closeButton: false,
      maxWidth: 'none',
    });

    const paint: LineLayerSpecification['paint'] = {
      'line-width': [
        'interpolate',
        ['exponential', 2],
        ['zoom'],
        10,
        ['*', ['get', 'width'], ['^', 2, -2]],
        24,
        ['*', ['get', 'width'], ['^', 2, 8]],
      ] as DataDrivenPropertyValueSpecification<number>,
      'line-offset': [
        'interpolate',
        ['exponential', 2],
        ['zoom'],
        14,
        0,
        24,
        ['*', ['get', 'width'], ['/', ['^', 2, 8], 2]],
      ] as DataDrivenPropertyValueSpecification<number>,
    };

    map.addLayer(
      {
        id: 'scenario',
        type: 'line',
        source: 'scenario',
        paint: {
          ...paint,
          'line-color': ['get', 'color'],
          'line-opacity': 0.8,
        },
      },
      'labels',
    );

    map.addLayer(
      {
        id: 'roads-modified',
        type: 'line',
        source: 'roads-modified',
        paint: {
          ...paint,
          'line-color': '#00e0ff',
          'line-opacity': 1,
        },
      },
      'labels',
    );

    map.addLayer(
      {
        id: 'road-highlight',
        type: 'line',
        source: 'road-highlight',
        paint: {
          ...paint,
          'line-color': '#ffeb3b',
          'line-opacity': 1,
        },
      },
      'labels',
    );

    map.on('mousemove', 'scenario', ({ lngLat, features }) => {
      if (map.getZoom() < 14) return;

      if (features && features.length > 0) {
        const { geometry, properties } = features[0];

        if (!creatingRef.current) {
          highlightedRoadTooltip.current
            ?.setHTML(
              `<h3>${properties?.routelink_id}</h3>
        <div>${t('cycling-insights.usage.travel_simulations.passages', {
          count: properties?.nominal_frequency,
        })}<span style="color: ${
          properties?.frequency_difference > 0
            ? blue[500]
            : properties?.frequency_difference < 0
              ? red[500]
              : 'inherit'
        };"> &#8594; ${t('cycling-insights.usage.travel_simulations.passages', {
          count: properties?.scenario_frequency,
        })}</span></div>`,
            )
            .setLngLat(lngLat)
            .addTo(map);
        }

        if (hoveredRoadId !== properties?.routelink_id && !clickedRoadIdRef.current) {
          const highlightedRoadSource = getHighlightedRoadSource();
          if (!highlightedRoadSource) return;

          hoveredRoadId = properties?.routelink_id;
          if (hoveredRoadId) {
            map.getCanvas().style.cursor = 'pointer';

            highlightedRoadSource.setData({
              type: 'Feature',
              geometry,
              properties: {
                ...properties,
                width: 6,
              },
            });
          } else {
            clearHighlight();
          }
        }
      }
    });

    map.on('mouseleave', 'scenario', () => {
      map.getCanvas().style.cursor = '';
      if (!clickedRoadIdRef.current) clearHighlight();
    });

    map.on('click', 'roads-modified', (event) => {
      if (creatingRef.current && !clickedRoadIdRef.current) {
        if (event.features && event.features.length > 0) {
          blockTooltipRemove = true;
          const { properties, geometry } = event.features[0];
          onClick({
            id: properties?.routelink_id,
            geometry: geometry as GeoJSON.LineString,
            amenagement: properties?.amenagement,
            highway: properties?.highway,
            surface: properties?.surface,
          });
          clickedRoadIdRef.current = properties?.routelink_id;
          highlightedRoadTooltip.current
            ?.setHTML(
              `<h3>${properties?.routelink_id}</h3>
        <div>Aménagement : ${properties?.amenagement}${
          properties?.newAmenagement
            ? `<span style="color: 
            ${orange[500]};"> &#8594; ${properties?.newAmenagement}</span>`
            : ''
        }</div>
        <div>Type de voie : ${properties?.highway}${
          properties?.newHighway
            ? `<span style="color: 
      ${orange[500]};"> &#8594; ${properties?.newHighway}</span>`
            : ''
        }</div>
        <div>Revêtement : ${properties?.surface}${
          properties?.newSurface
            ? `<span style="color: 
      ${orange[500]};"> &#8594; ${properties?.newSurface}</span>`
            : ''
        }</div>`,
            )
            .setLngLat(event.lngLat)
            .addTo(map);
        }
      }
    });

    map.on('click', 'scenario', (event) => {
      if (creatingRef.current && !clickedRoadIdRef.current) {
        if (event.features && event.features.length > 0) {
          blockTooltipRemove = true;
          const { properties, geometry } = event.features[0];
          onClick({
            id: properties?.routelink_id,
            geometry: geometry as GeoJSON.LineString,
            amenagement: properties?.amenagement,
            highway: properties?.highway,
            surface: properties?.surface,
          });
          clickedRoadIdRef.current = properties?.routelink_id;
          highlightedRoadTooltip.current
            ?.setHTML(
              `<h3>${properties?.routelink_id}</h3>
        <div>Aménagement : ${properties?.amenagement}</div>
        <div>Type de voie : ${properties?.highway}</div>
        <div>Revêtement : ${properties?.surface}</div>`,
            )
            .setLngLat(event.lngLat)
            .addTo(map);
        }
      }
    });

    map.on('click', () => {
      if (blockTooltipRemove) {
        blockTooltipRemove = !blockTooltipRemove;
      } else {
        if (creatingRef.current && clickedRoadIdRef.current) {
          onClick();
          clearHighlight();
          clickedRoadIdRef.current = undefined;
        }
      }
    });

    initializedRef.current = true;
    setInitialized(true);
  }

  function selectRoad(
    id?: number,
    road?: {
      geometry: GeoJSON.LineString;
      properties: {
        amenagement: TFacilitiesType;
        blocked: boolean | undefined;
        highway: THighwayType;
        newAmenagement: TFacilitiesType | undefined;
        newHighway: THighwayType | undefined;
        newSurface: TSurfaceType | undefined;
        surface: TSurfaceType;
      };
    },
  ) {
    if (!map) return;
    if (!road) {
      highlightedRoadTooltip.current?.remove();
      clearModifiedRoadSource();
      clearHighlight();
      clickedRoadIdRef.current = undefined;
    } else {
      const { geometry, properties } = road;
      const highlightedRoadSource = getHighlightedRoadSource();
      if (!highlightedRoadSource) return;
      clickedRoadIdRef.current = id;
      blockTooltipRemove = true;
      highlightedRoadSource.setData({
        type: 'Feature',
        geometry,
        properties: {
          ...properties,
          width: 6,
        },
      });
      let lngLat: [number, number];
      if (geometry.coordinates.length > 2) {
        const coordinates = geometry.coordinates[Math.floor(geometry.coordinates.length / 2)];
        lngLat = [coordinates[0], coordinates[1]];
      } else {
        const coordinates = center(geometry).geometry.coordinates;
        lngLat = [coordinates[0], coordinates[1]];
      }
      highlightedRoadTooltip.current
        ?.setHTML(
          `<h3>${id}</h3>
          <div>Aménagement : ${properties?.amenagement}${
            properties.newAmenagement
              ? `<span style="color: 
            ${orange[500]};"> &#8594; ${properties.newAmenagement}</span>`
              : ''
          }</div>
          <div>Type de voie : ${properties?.highway}${
            properties.newHighway
              ? `<span style="color: 
              ${orange[500]};"> &#8594; ${properties.newHighway}</span>`
              : ''
          }</div>
          <div>Revêtement : ${properties?.surface}${
            properties.newSurface
              ? `<span style="color: 
              ${orange[500]};"> &#8594; ${properties.newSurface}</span>`
              : ''
          }</div>`,
        )
        .setLngLat(lngLat)
        .addTo(map);
      map.flyTo({
        center: lngLat,
        zoom: map.getZoom(),
      });
    }
  }

  function update({ features }: GeoJSON.FeatureCollection, creating?: boolean) {
    creatingRef.current = !!creating;
    updateHeatmapSource({
      type: 'FeatureCollection',
      features: features
        .filter(({ properties }) => (creating ? true : properties?.frequency_difference !== 0))
        .map(({ properties, ...feature }) => {
          return {
            ...feature,
            properties: {
              ...properties,
              color:
                properties?.frequency_difference > 0
                  ? blue[500]
                  : properties?.frequency_difference < 0
                    ? red[500]
                    : grey[500],
              width: creating ? 3 : 6,
            },
          };
        }),
    });
  }

  function updateModifications(
    roads: {
      id: number;
      road: {
        geometry: GeoJSON.LineString;
        properties: {
          amenagement: TFacilitiesType;
          highway: THighwayType;
          newAmenagement: TFacilitiesType | undefined;
          newHighway: THighwayType | undefined;
          newSurface: TSurfaceType | undefined;
          surface: TSurfaceType;
        };
      };
    }[],
  ) {
    updateModifiedRoadsSource({
      type: 'FeatureCollection',
      features: roads.map(({ id, road: { geometry, properties } }) => {
        return {
          type: 'Feature',
          geometry: geometry,
          properties: { ...properties, width: 6, routelink_id: id },
        };
      }),
    });
  }

  function updateTooltip(
    section: {
      id: number;
      geometry: GeoJSON.LineString;
      amenagement: TFacilitiesType;
      highway: THighwayType;
      surface: TSurfaceType;
    },
    modification: {
      amenagement: TFacilitiesType | undefined;
      highway: THighwayType | undefined;
      surface: TSurfaceType | undefined;
    },
  ) {
    highlightedRoadTooltip.current?.setHTML(
      `<h3>${section?.id}</h3>
        <div>Aménagement : ${section?.amenagement}${
          modification.amenagement
            ? `<span style="color: 
          ${orange[500]};"> &#8594; ${modification.amenagement}</span>`
            : ''
        }</div>
        <div>Type de voie : ${section?.highway}${
          modification.highway
            ? `<span style="color: 
            ${orange[500]};"> &#8594; ${modification.highway}</span>`
            : ''
        }</div>
        <div>Revêtement : ${section?.surface}${
          modification.surface
            ? `<span style="color: 
            ${orange[500]};"> &#8594; ${modification.surface}</span>`
            : ''
        }</div>`,
    );
  }

  function clearHighlight() {
    highlightedRoadTooltip.current?.remove();

    const highlightedRoadSource = getHighlightedRoadSource();
    if (highlightedRoadSource) {
      highlightedRoadSource.setData({ type: 'FeatureCollection', features: [] });
    }

    hoveredRoadId = undefined;
  }

  function clear() {
    clearHeatmapSource();
    clearHighlight();

    initializedRef.current = false;
    setInitialized(false);
  }

  return { initialized, init, update, updateModifications, updateTooltip, selectRoad, clear };
}

export default useScenario;
