import { PartnerService, PartnerUser, User, UserService } from '@geovelo-frontends/commons';
import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  DialogProps,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { FormEvent, Fragment, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { AppContext } from '../../../app/context';
import Dialog from '../../../components/dialog';

const dialogTitle = 'user-form-dialog-title';

interface IValues {
  email: string;
  selectedUserType: 'user' | 'admin';
}

type TProps = Omit<DialogProps, 'onClose'> & {
  onClose: (userId?: number) => void;
  user: PartnerUser | null;
};

function UsersFormDialog({ onClose, open, user, ...props }: TProps): JSX.Element {
  const [confirmMessageShowed, showConfirmMessage] = useState(false);
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isSubmitting,
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    setTouched,
    setErrors,
  } = useFormik<IValues>({
    initialValues: {
      email: '',
      selectedUserType: 'user',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required(),
    }),
    onSubmit,
    enableReinitialize: true,
    validateOnMount: true,
  });

  useEffect(() => {
    if (open) {
      if (user) {
        setValues({
          email: user.email,
          selectedUserType: user.isAdmin ? 'admin' : 'user',
        });
      } else {
        setValues({ email: '', selectedUserType: 'user' });
      }

      setErrors({});
      setTouched({ email: false, selectedUserType: false });
    } else {
      showConfirmMessage(false);
    }
  }, [open]);

  async function onSubmit(
    { email, selectedUserType }: IValues,
    { setSubmitting }: FormikHelpers<IValues>,
  ) {
    if (!currentPartner) return;

    if (!user && !email) {
      showConfirmMessage(false);
      setErrors({ email: 'email is required' });
      return;
    }

    setErrors({});
    setSubmitting(true);

    if (user) {
      try {
        const result = await PartnerService.updatePartnerUser(
          currentPartner,
          user.id,
          selectedUserType === 'admin',
        );

        onClose(result);
        enqueueSnackbar(t('cycling-insights.admin.manage_partner.form_dialog.updated'));
      } catch (err) {
        enqueueSnackbar(t('cycling-insights.admin.manage_partner.form_dialog.not_updated'), {
          variant: 'error',
        });
      }
    } else {
      try {
        let user: User | null = null;
        user = await UserService.getUserByEmail(email);
        if (!user) user = await UserService.createUser(email);

        const result = await PartnerService.addPartnerUser(
          currentPartner,
          user.id,
          selectedUserType === 'admin',
        );

        onClose(result);
        enqueueSnackbar(t('cycling-insights.admin.manage_partner.form_dialog.added'));
      } catch (err) {
        enqueueSnackbar(t('cycling-insights.admin.manage_partner.form_dialog.not_added'), {
          variant: 'error',
        });
      }
    }

    setSubmitting(false);
  }

  function handleConfirm(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    showConfirmMessage(true);
  }

  return (
    <Dialog
      disableEscapeKeyDown
      isForm
      confirmTitle={
        user ? <Trans i18nKey="commons.actions.update" /> : <Trans i18nKey="commons.actions.add" />
      }
      dialogTitle={dialogTitle}
      loading={isSubmitting}
      maxWidth="sm"
      onCancel={confirmMessageShowed ? () => showConfirmMessage(false) : () => onClose()}
      onConfirm={confirmMessageShowed ? handleSubmit : handleConfirm}
      open={open}
      scroll="paper"
      title={
        <Trans
          i18nKey="cycling-insights.admin.manage_partner.form_dialog.title"
          values={{ context: user ? 'update' : 'create' }}
        />
      }
      {...props}
    >
      {confirmMessageShowed ? (
        <Typography>
          {user ? (
            <Trans i18nKey="cycling-insights.admin.manage_partner.form_dialog.confirm_message_update" />
          ) : (
            <Trans i18nKey="cycling-insights.admin.manage_partner.form_dialog.confirm_message_create" />
          )}
        </Typography>
      ) : (
        <>
          {!user && (
            <Typography component="p" variant="caption">
              <Trans i18nKey="cycling-insights.admin.manage_partner.form_dialog.description_update" />
            </Typography>
          )}
          <TextField
            fullWidth
            disabled={Boolean(user)}
            error={touched.email && Boolean(errors.email)}
            label={t('commons.email')}
            margin="normal"
            name="email"
            onChange={handleChange}
            required={!user}
            size="small"
            value={values.email}
            variant="outlined"
          />
          <Typography
            color="textSecondary"
            component="p"
            style={{ marginTop: 16 }}
            variant="caption"
          >
            <Trans i18nKey="cycling-insights.admin.manage_partner.form_dialog.role" />
          </Typography>
          <Box columnGap={2} display="flex" flexWrap="wrap" marginTop={1} rowGap={1}>
            <RadioGroup
              row
              name="selectedUserType"
              onChange={({ target: { value } }) => setFieldValue('selectedUserType', value)}
              sx={{ gap: '144px' }}
              value={String(values.selectedUserType)}
            >
              <FormControlLabel
                control={<Radio />}
                disabled={isSubmitting}
                label={
                  <Box alignItems="center" display="flex" gap={1}>
                    <Trans i18nKey="cycling-insights.admin.manage_partner.roles.user" />
                    <Tooltip
                      title={
                        <Trans i18nKey="cycling-insights.admin.manage_partner.roles.user_tooltip" />
                      }
                    >
                      <InfoOutlined fontSize="small" sx={{ color: '#97A3BA' }} />
                    </Tooltip>
                  </Box>
                }
                value="user"
              />
              <FormControlLabel
                control={<Radio />}
                disabled={isSubmitting}
                label={
                  <Box alignItems="center" display="flex" gap={1}>
                    <Trans i18nKey="cycling-insights.admin.manage_partner.roles.admin" />
                    <Tooltip
                      title={
                        <Trans i18nKey="cycling-insights.admin.manage_partner.roles.admin_tooltip" />
                      }
                    >
                      <InfoOutlined fontSize="small" sx={{ color: '#97A3BA' }} />
                    </Tooltip>
                  </Box>
                }
                value="admin"
              />
            </RadioGroup>
          </Box>
        </>
      )}
    </Dialog>
  );
}

export default UsersFormDialog;
