/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Period } from '@geovelo-frontends/commons';
import { Chart, TooltipItem } from 'chart.js';
import moment, { Moment } from 'moment';

export type TStatsAgregationType = 'cumulative' | 'average' | 'minimum' | 'maximum';

export const timeFormat = 'YYYY-MM-DD HH:mm';

export function titleCallback<T extends 'bar' | 'line'>(period: Period) {
  return (items: TooltipItem<T>[]): string => {
    const { raw } = items[0];
    const { initialDate, x } = raw as { initialDate?: Moment; x: string };

    const date = initialDate || moment(x);

    return period.unit === 'month' ? date.format('MMMM YYYY') : date.format('LL');
  };
}

function parseCurrentPeriodData(
  { unit }: Period,
  data: { t: Moment; y: number }[],
  statsAgregationType: TStatsAgregationType,
): Array<{ x: string; y: number }> {
  if (unit === 'month') {
    const currentPeriodValues: {
      [key: string]: { x: string; y: number; nbValues: number };
    } = {};

    data.forEach(({ t, y }) => {
      const key = t.clone().startOf('month').format('YYYY-MM-DD');

      switch (statsAgregationType) {
        case 'minimum':
          if (currentPeriodValues[key] && currentPeriodValues[key].y > y) {
            currentPeriodValues[key].y = y;
          } else currentPeriodValues[key] = { x: t.format(timeFormat), y, nbValues: 1 };
          break;

        case 'maximum':
          if (currentPeriodValues[key] && currentPeriodValues[key].y < y) {
            currentPeriodValues[key].y = y;
          } else currentPeriodValues[key] = { x: t.format(timeFormat), y, nbValues: 1 };
          break;

        case 'average':
        case 'cumulative':
        default:
          if (currentPeriodValues[key]) {
            currentPeriodValues[key].nbValues += 1;
            currentPeriodValues[key].y += y;
          } else currentPeriodValues[key] = { x: t.format(timeFormat), y, nbValues: 1 };
      }
    });

    if (statsAgregationType === 'average') {
      for (const key in currentPeriodValues) {
        if (currentPeriodValues[key])
          currentPeriodValues[key].y = Math.round(
            currentPeriodValues[key].y / currentPeriodValues[key].nbValues,
          );
      }
    }

    return Object.values(currentPeriodValues);
  } else if (unit === 'day') {
    return data.map(({ t, y }) => ({ x: t.format(timeFormat), y }));
  }

  return [];
}

export function updateData(
  chart: Chart,
  period: Period,
  data: { t: Moment; y: number }[],
  statsAgregationType: TStatsAgregationType,
  disableBeginAtZero?: boolean,
): void {
  if (chart && chart.data.datasets) {
    if (disableBeginAtZero && chart.options.scales?.y && 'beginAtZero' in chart.options.scales.y) {
      chart.options.scales.y.beginAtZero = data.every(({ y }) => y === 0);
    }

    // @ts-ignore typings are uncorrect
    chart.data.datasets[0].data = parseCurrentPeriodData(period, data, statsAgregationType);

    chart.update();
  }
}

export function clearData(chart: Chart): void {
  chart.data.datasets?.forEach((dataset) => {
    dataset.data = [];
  });

  if (chart.options.scales?.y && 'beginAtZero' in chart.options.scales.y) {
    chart.options.scales.y.beginAtZero = true;
  }

  chart.update();
}

export function resetChart<T extends 'bar' | 'line'>(
  chart: Chart,
  period: Period,
  _titleCallback: (period: Period) => (items: TooltipItem<T>[]) => string,
  labelFormat: (label: string | number) => string,
  periodLabels: [string] | [string, string],
): void {
  if (chart.options.scales?.x && 'time' in chart.options.scales.x && chart.options.scales.x.time) {
    chart.options.scales.x.time.unit = period.unit;
    if (chart.options.scales.x.time.displayFormats)
      chart.options.scales.x.time.displayFormats.day = period.type === 'week' ? 'ddd' : 'DD';
    if (period.type === 'custom' && chart.options.scales.x.ticks) {
      chart.options.scales.x.ticks.callback = (_, index) => {
        const prevPeriodDate = period
          .getPrevPeriod()
          .from.add(index, period.unit === 'month' ? 'months' : 'days');
        return period.unit === 'month'
          ? `${prevPeriodDate.format('MMM YYYY')} / ${period.from
              .clone()
              .add(index, 'months')
              .format('MMM YYYY')}`
          : `${prevPeriodDate.format('ll').slice(0, -5)} / ${period.from
              .clone()
              .add(index, 'days')
              .format('ll')
              .slice(0, -5)}`;
      };
    } else delete chart.options.scales.x.ticks?.callback;
  }

  if (chart.options.scales?.x) {
    // @ts-ignore typings are uncorrect
    chart.options.scales.x.min = period.from.format(timeFormat);
    // @ts-ignore typings are uncorrect
    chart.options.scales.x.max = period.to.format(timeFormat);
  }

  if (chart.options.scales?.y?.ticks) {
    chart.options.scales.y.ticks.callback = (value) => labelFormat(value);
  }

  if (chart.options.plugins?.tooltip?.callbacks) {
    chart.options.plugins.tooltip.callbacks.title = _titleCallback(period);
    chart.options.plugins.tooltip.callbacks.label = ({ parsed }) =>
      'y' in parsed ? labelFormat(parsed.y) : '';
  }

  if (chart.data.datasets) {
    chart.data.datasets[0].label = periodLabels[0];
    chart.data.datasets[0].data = [];

    if (periodLabels[1]) {
      chart.data.datasets[1].label = periodLabels[1];
      chart.data.datasets[1].data = [];
    }
  }

  chart.update();
}
