import {
  Autocomplete,
  Counter,
  CounterService,
  GeocoderService,
  Place,
  VirtualCounterService,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Box, Button, CircularProgress, TextField, Typography, useTheme } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import useCounters from '../../../../hooks/map/counters';
import { IBicycleObservatoryPageContext } from '../../context';

import { LngLat } from '!maplibre-gl';

interface IValues {
  title: string;
}

function CountersForm({
  header: { setPrevButtonClick, setTitle },
  counters: { editingCounter, list, setEditingCounter, setList, selectKey },
}: IBicycleObservatoryPageContext): JSX.Element {
  const {
    map: { current: currentMap },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancelPromises } = useCancellablePromise();
  const { state } = useLocation();
  const [selectedPlace, selectPlace] = useState<Place | null>(
    state?.location ? new Place(-1, state.location) : null,
  );
  const [placeError, setPlaceError] = useState(false);
  const { isSubmitting, errors, values, setValues, handleChange, handleSubmit } =
    useFormik<IValues>({
      initialValues: { title: '' },
      onSubmit,
      enableReinitialize: true,
    });
  const theme = useTheme();
  const [newCounter, setNewCounter] = useState<Counter>();
  const {
    initialized: layersInitialized,
    init: initLayers,
    update: updateLayers,
    updateMinimizeCounters,
    destroy: destroyLayers,
  } = useCounters(currentMap, newCounter ? [newCounter] : undefined, theme, {
    onDragEnd: handlePlaceDrag,
  });
  const navigate = useNavigate();

  useEffect(() => {
    setPrevButtonClick(() => () => navigate('../counters'));

    if (editingCounter) {
      setTitle(<Trans i18nKey="cycling-insights.usage.point_attendance.form.title_update" />);
      setValues({ title: editingCounter.title });
      selectPlace(editingCounter.place);
    }

    if (!list) getCounters();

    return () => {
      setPrevButtonClick(undefined);
      setTitle(undefined);
      cancelPromises();
    };
  }, []);

  useEffect(() => {
    if (selectedPlace && !selectedPlace.addressDetail) {
      try {
        GeocoderService.reverseGeocode(undefined, selectedPlace.point, (place) => {
          selectPlace(place);
        });
      } catch {}
    }
  }, [selectedPlace]);

  useEffect(() => {
    if (currentMap) {
      initLayers();
      currentMap.on('click', ({ lngLat }) => handleMapClick(lngLat));
    }

    return () => {
      destroyLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    if (layersInitialized) {
      if (selectedPlace)
        setNewCounter(new Counter(editingCounter?.id || 0, values.title, selectedPlace, true));
      else setNewCounter(undefined);
    }
  }, [layersInitialized, selectedPlace]);

  useEffect(() => {
    if (newCounter) {
      updateLayers(null, { draggable: true });
    } else {
      updateLayers(null);
    }
  }, [newCounter]);

  useEffect(() => {
    if (list) updateMinimizeCounters(list);
  }, [list]);

  async function getCounters() {
    try {
      const virtualCounters = await VirtualCounterService.getVirtualCounters();

      virtualCounters.sort((a, b) => b.id - a.id);

      const _counters: Counter[] = [];

      if (
        currentPartner?.dashboardTabsPermissions.usagePointAttendance === 'extrapolated' &&
        currentPartner?.hasRealCounters
      ) {
        const realCounters = await CounterService.getCounters();

        realCounters.sort((a, b) => a.id - b.id);

        _counters.push(...realCounters);
      }

      setList([..._counters, ...virtualCounters]);
    } catch {
      enqueueSnackbar(t('cycling-insights.usage.point_attendance.server_error'), {
        variant: 'error',
      });
      setList([]);
    }
  }

  async function onSubmit(
    { title }: IValues,
    { setSubmitting, setErrors }: FormikHelpers<IValues>,
  ) {
    if (!title) {
      setErrors({ title: 'Title is required' });
      return;
    } else if (!selectedPlace) {
      setErrors({});
      setPlaceError(true);
      return;
    }

    setErrors({});
    setPlaceError(false);
    setSubmitting(true);

    try {
      if (editingCounter) {
        const updatedCounter = await VirtualCounterService.updateVirtualCounter(editingCounter.id, {
          title,
          place: selectedPlace,
        });

        handleSent(updatedCounter);
      } else {
        const createdCounter = await VirtualCounterService.addVirtualCounter({
          title,
          place: selectedPlace,
        });

        handleSent(createdCounter);
      }
    } catch (err) {
      enqueueSnackbar(
        t('cycling-insights.usage.point_attendance.form.server_error', {
          context: editingCounter ? 'update' : 'add',
        }),
      );
      setSubmitting(false);
    }
  }

  function handleSent(counter: Counter) {
    if (list) {
      if (editingCounter) {
        const index = list.findIndex(({ id, isVirtual }) => isVirtual && id === editingCounter.id);
        if (index >= 0) {
          const countersUpdated = [...list];
          countersUpdated.splice(index, 1, counter);

          setList(countersUpdated);
        }
      } else {
        const countersUpdated = [counter, ...list];

        setList(countersUpdated);
        selectKey(`${counter.isVirtual ? 'virtual' : 'real'}_${counter.id}`);
      }
      enqueueSnackbar(t('cycling-insights.usage.point_attendance.wait_for_data'));
    }
    setEditingCounter();
    navigate('../counters');
  }

  async function handlePlaceDrag(_: number, point: GeoJSON.Point) {
    const place = new Place(undefined, point);

    selectPlace(place);
  }

  function handleMapClick(lngLat: LngLat) {
    const place = new Place(undefined, { type: 'Point', coordinates: [lngLat.lng, lngLat.lat] });
    selectPlace(place);
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        justifyContent="space-between"
        paddingTop={4}
      >
        <Box display="flex" flexDirection="column" gap={2}>
          <TextField
            disabled={isSubmitting}
            error={Boolean(errors.title)}
            id="title"
            label={<Trans i18nKey="cycling-insights.usage.point_attendance.form.name" />}
            onChange={handleChange}
            value={values.title}
            variant="outlined"
          />
          <Typography fontSize="1.125rem" fontWeight={700} marginTop={3}>
            <Trans i18nKey="cycling-insights.reports.osm_cartographic_reports.form_dialog.location" />
          </Typography>
          <Typography variant="body1">
            <Trans i18nKey="cycling-insights.reports.osm_cartographic_reports.form_dialog.location_subtitle" />
          </Typography>
          <Autocomplete
            defaultValue={selectedPlace}
            disabled={isSubmitting}
            error={placeError}
            onSelect={selectPlace}
            size="small"
          />
        </Box>
        <Box display="flex" gap={3} justifyContent="flex-end">
          <Button
            component={Link}
            disabled={isSubmitting}
            size="large"
            to="../counters"
            variant="outlined"
          >
            <Trans i18nKey="commons.actions.cancel" />
          </Button>
          <Button
            disableElevation
            disabled={isSubmitting}
            onClick={() => handleSubmit()}
            size="large"
            startIcon={isSubmitting && <CircularProgress color="inherit" size={16} thickness={4} />}
            variant="contained"
          >
            <Trans i18nKey="commons.actions.validate" />
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default CountersForm;
