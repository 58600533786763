import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { ComponentType } from 'react';

import DocumentationTab from '../tab';

import RequestTab from './request';
import ResponseTab from './response';

type TTab = 'm2m-request' | 'm2m-response';

const tabs: Array<{
  key: TTab;
  icon: JSX.Element;
  label: string;
  Tab: ComponentType<Record<string, never>>;
}> = [
  { key: 'm2m-request', icon: <ArrowUpward />, label: 'Request', Tab: RequestTab },
  { key: 'm2m-response', icon: <ArrowDownward />, label: 'Response', Tab: ResponseTab },
];

function M2MDocumentation(): JSX.Element {
  return (
    <DocumentationTab
      defaultTab="m2m-request"
      tabs={tabs}
      titleKey="cycling-insights.api.doc.title_m2m"
    />
  );
}

export default M2MDocumentation;
