import { Tab, Typography } from '@mui/material';
import { Fragment, ReactNode, RefObject, useRef, useState } from 'react';
import ReactJson from 'react-json-view';
import styled from 'styled-components';

import doc from '../../../../../assets/m2m-api-doc';
import SimpleTable, { TRow } from '../../../../../components/simple-table';

type TTab = 'json';

const tabs: Array<{
  key: TTab;
  label: string;
}> = [{ key: 'json', label: 'JSON' }];

type TKey = 'method' | 'url' | 'key' | 'value' | 'type' | 'values' | 'description';

const requestKeys: TKey[] = ['method', 'url'];
const headerKeys: TKey[] = ['key', 'value'];
const fullKeys: TKey[] = ['key', 'type', 'values', 'description'];

function RequestTab(): JSX.Element {
  const headerParamsRef = useRef<HTMLSpanElement>(null);
  const bodyParamsRef = useRef<HTMLSpanElement>(null);

  const executeScroll = (ref: RefObject<HTMLSpanElement>) => {
    if (ref.current !== null) {
      ref.current.scrollIntoView();
    }
  };

  const {
    request: {
      data: requestMethod,
      parameters: {
        header: headerParams,
        body: {
          data: bodyParams,
          objects: { wayPoint, pedestrianDetails, bikeDetails },
        },
      },
      example: { url: exampleUrl },
    },
  } = doc;

  const [requestRows] = useState<TRow<number, TKey>[] | undefined>(
    [...requestMethod].map(({ method, url }, index) => {
      return {
        key: index,
        cells: {
          method: { value: method },
          url: {
            value: url,
            format: (url) => {
              return (
                <>
                  <span>{url}</span>{' '}
                  <RefToSectionSpan onClick={() => executeScroll(headerParamsRef)}>
                    +headerParams
                  </RefToSectionSpan>{' '}
                  <RefToSectionSpan onClick={() => executeScroll(bodyParamsRef)}>
                    +bodyParams
                  </RefToSectionSpan>
                </>
              );
            },
          },
        },
      };
    }),
  );
  const [headerParamsRows] = useState<TRow<number, TKey>[] | undefined>(
    [...headerParams].map(({ key, value, keyIsOptional }, index) => {
      return {
        key: index,
        cells: {
          key: {
            value: key,
            format: formatWithOptional(keyIsOptional),
          },
          value: { value: value },
        },
      };
    }),
  );
  const [bodyParamsRows] = useState<TRow<number, TKey>[] | undefined>(
    [...bodyParams].map(
      ({ key, type, values, description, keyIsOptional, defaultValueIndex }, index) => {
        return {
          key: index,
          cells: {
            key: {
              value: key,
              format: formatWithOptional(keyIsOptional),
            },
            type: { value: type },
            values: {
              value: values,
              format: values ? formatWithDefaultValue(defaultValueIndex) : undefined,
            },
            description: {
              value: description,
              format: formatCellFontNotConsole,
            },
          },
        };
      },
    ),
  );
  const [wayPointRows] = useState<TRow<number, TKey>[] | undefined>(
    [...wayPoint].map(({ key, type, description }, index) => {
      return {
        key: index,
        cells: {
          key: {
            value: key,
            format: formatWithOptional(false),
          },
          type: { value: type },
          description: {
            value: description,
            format: formatCellFontNotConsole,
          },
        },
      };
    }),
  );
  const [pedestrianDetailsRows] = useState<TRow<number, TKey>[] | undefined>(
    [...pedestrianDetails].map(
      ({ key, type, values, description, keyIsOptional, defaultValueIndex }, index) => {
        return {
          key: index,
          cells: {
            key: {
              value: key,
              format: formatWithOptional(keyIsOptional),
            },
            type: { value: type },
            values: {
              value: values,
              format: values ? formatWithDefaultValue(defaultValueIndex) : undefined,
            },
            description: {
              value: description,
              format: formatCellFontNotConsole,
            },
          },
        };
      },
    ),
  );
  const [bikeDetailsRows] = useState<TRow<number, TKey>[] | undefined>(
    [...bikeDetails].map(
      ({ key, type, values, description, keyIsOptional, defaultValueIndex }, index) => {
        return {
          key: index,
          cells: {
            key: {
              value: key,
              format: formatWithOptional(keyIsOptional),
            },
            type: { value: type },
            values: {
              value: values,
              format: values ? formatWithDefaultValue(defaultValueIndex) : undefined,
            },
            description: {
              value: description,
              format: formatCellFontNotConsole,
            },
          },
        };
      },
    ),
  );

  function formatWithOptional(keyIsOptional: boolean) {
    return function format(key: number) {
      return (
        <>
          <span>{key}</span>
          <br />
          {keyIsOptional ? (
            <TagSpan className="tag optional">Optional</TagSpan>
          ) : (
            <TagSpan className="tag required">Required</TagSpan>
          )}
        </>
      );
    };
  }

  function formatWithDefaultValue(defaultValueIndex: number | null) {
    return (values: string[]) => {
      return values.map((value, index) => {
        const isDefault = index === defaultValueIndex;
        return (
          <Fragment key={value}>
            {index > 0 && (
              <>
                ,<br />
              </>
            )}
            <span>{value}</span>
            {isDefault && (
              <>
                <br />
                <TagSpan className="tag default">Default</TagSpan>
              </>
            )}
          </Fragment>
        );
      });
    };
  }

  function formatCellFontNotConsole(description: string): ReactNode {
    return (
      <span
        dangerouslySetInnerHTML={{ __html: description }}
        style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif' }}
      />
    );
  }

  return (
    <Wrapper>
      <StyledTitle variant="h6">Request</StyledTitle>
      <SimpleTable
        headers={{
          method: { label: 'Method' },
          url: { label: 'URL' },
        }}
        keys={requestKeys}
        rows={requestRows}
        title={'request-method'}
      />

      <StyledSectionTitle noWrap ref={headerParamsRef} variant="h6">
        Header parameters
      </StyledSectionTitle>
      <SimpleTable
        headers={{
          key: { label: 'Key' },
          value: { label: 'Value' },
        }}
        keys={headerKeys}
        rows={headerParamsRows}
        title={'header-params'}
      />

      <StyledSectionTitle noWrap ref={bodyParamsRef} variant="h6">
        Body parameters
      </StyledSectionTitle>
      <SimpleTable
        headers={{
          key: { label: 'Key' },
          type: { label: 'Type' },
          values: { label: 'Values' },
          description: { label: 'Description' },
        }}
        keys={fullKeys}
        rows={bodyParamsRows}
        title={'body-params'}
      />

      <StyledSectionTitle noWrap className="object" variant="h6">
        TPoint
      </StyledSectionTitle>
      <SimpleTable
        headers={{
          key: { label: 'Key' },
          type: { label: 'Type' },
        }}
        keys={fullKeys}
        rows={wayPointRows}
        title={'way-point'}
      />

      <StyledSectionTitle noWrap className="object" variant="h6">
        TPedestrianDetails
      </StyledSectionTitle>
      <SimpleTable
        headers={{
          key: { label: 'Key' },
          type: { label: 'Type' },
          values: { label: 'Values' },
          description: { label: 'Description' },
        }}
        keys={fullKeys}
        rows={pedestrianDetailsRows}
        title={'bike-details'}
      />

      <StyledSectionTitle noWrap className="object" variant="h6">
        TBikeDetails
      </StyledSectionTitle>
      <SimpleTable
        headers={{
          key: { label: 'Key' },
          type: { label: 'Type' },
          values: { label: 'Values' },
          description: { label: 'Description' },
        }}
        keys={fullKeys}
        rows={bikeDetailsRows}
        title={'bike-details'}
      />

      <StyledSeparator />
      <StyledSectionTitle noWrap className="example" variant="h6">
        Example :
      </StyledSectionTitle>
      <StyledExampleFrame>
        <ExampleContent className="example">
          {tabs.map(({ key, label }) => (
            <StyledTab
              aria-controls={`tabpanel-${key}`}
              id={`tab-${key}`}
              key={key}
              label={label}
              style={{ textTransform: 'initial' }}
              value={key}
            />
          ))}
          <code>
            <span className="bold">POST</span>&nbsp;&nbsp;
            <span>{exampleUrl}</span>
          </code>
          <br />
          <ReactJson
            displayDataTypes={false}
            displayObjectSize={false}
            name={false}
            src={doc.request.example.body_parameters}
          />
        </ExampleContent>
      </StyledExampleFrame>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
`;

const StyledTitle = styled(Typography)`
  && {
    display: none;
    font-size: 1.5rem;

    @media print {
      display: block;
    }
  }
`;

const TagSpan = styled.span`
  &&.tag {
    border-radius: 5px;
    padding: 2px 5px;
    font-family: 'Courier New', Courier, monospace;

    &.default,
    &.optional {
      background-color: rgba(196, 196, 196, 0.3);
      color: DimGray;
    }

    &.required {
      background-color: #aa7078;
      color: white;
    }
  }
`;

const RefToSectionSpan = styled.span`
  color: #944000;
  font-style: italic;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const StyledSectionTitle = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    border-radius: 10px;
    min-height: 36px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px 20px;
    font-size: 1.2rem;
    background-color: rgba(196, 196, 196, 0.5);
    font-weight: 500;

    &.object {
      min-height: 30px;
      font-size: 1.1rem;
      background-color: rgba(196, 196, 196, 0.3);
    }

    &.example {
      background-color: inherit;
      font-size: 1.1rem;
      margin-top: 0;
      margin-bottom: 0;
    }

    @media print {
      background-color: transparent;
      padding: 5px 0;
    }
  }
`;

const StyledSeparator = styled.hr`
  width: 100%;
  border: solid thin rgba(196, 196, 196, 0.5);
  margin-top: 32px;
`;

const StyledExampleFrame = styled.div`
  border-radius: 10px;
  background-color: #f8f8f8;
`;

const ExampleContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  font-size: 0.8rem;

  .bold {
    font-weight: 500;
  }
`;

const StyledTab = styled(Tab)`
  && {
    min-height: 30px;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 32px;
    border-bottom: solid 1px green;
  }
`;

export default RequestTab;
