import { ButtonProps, Button as MuiButton } from '@mui/material';
import { red } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export type TButtonProps = ButtonProps & {
  component?: 'a' | typeof Link;
  dangerous?: boolean;
  rel?: 'noreferrer';
  target?: '_blank';
  to?: string;
};

function Button({ dangerous, ...props }: TButtonProps): JSX.Element {
  return <StyledButton className={dangerous ? 'dangerous' : ''} size="small" {...props} />;
}

const StyledButton = styled(MuiButton)`
  && {
    border-radius: 8px;
    text-transform: initial;
  }

  &.MuiButton-contained:not(.MuiButton-containedPrimary):not(.MuiButton-containedSecondary):not(
      .MuiButton-containedWarning
    ):not(.MuiButton-containedError):not(.dangerous):not(.MuiButton-containedOrange):not(
      .MuiButton-containedYellow
    ):not(.MuiButton-containedPurple) {
    background-color: #fff;
  }

  &.dangerous {
    background-color: ${red[500]};
    color: #fff;

    &:hover {
      background-color: ${red[900]};
    }
  }
`;

export default Button;
