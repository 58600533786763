import { BikeRoute } from '@geovelo-frontends/commons';
import { Explore } from '@mui/icons-material';
import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppContext } from '../../../../../app/context';
import { publicationStatus } from '../../../../../models/publication-status';

interface IProps {
  bikeRoute?: BikeRoute;
}

function BikeRouteListItem({ bikeRoute }: IProps): JSX.Element {
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();

  if (!bikeRoute) {
    return (
      <ListItem>
        <ListItemAvatar>
          <Skeleton height={40} variant="circular" width={40} />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <Typography component="div" variant="body1">
              <Skeleton variant="text" width={250} />
            </Typography>
          }
          secondary={
            <Typography component="div" variant="caption">
              <Skeleton variant="text" width={200} />
            </Typography>
          }
        />
      </ListItem>
    );
  }

  const { id, title, iconUrl, publicationStatus: statusKey, rides } = bikeRoute;
  const status = publicationStatus[statusKey];

  return (
    <ListItemButton component={Link} to={`/${currentPartner?.code}/promotion/bike-routes/${id}`}>
      <ListItemAvatar>
        {iconUrl ? (
          <Avatar src={iconUrl} />
        ) : (
          <Avatar>
            <Explore />
          </Avatar>
        )}
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={
          <Box alignItems="center" display="flex" gap={1}>
            <Typography variant="body1">{title}</Typography>
            {status && (
              <Box
                borderRadius="6px"
                height="12px"
                sx={{ backgroundColor: status.color }}
                title={t(status.titleKey, {
                  context: 'female',
                })}
                width="12px"
              />
            )}
          </Box>
        }
        secondary={
          <Typography variant="caption">
            <Trans
              count={rides.length}
              i18nKey={'cycling-insights.tourism.bike_routes.list.steps'}
              values={{ count: rides.length }}
            />
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default BikeRouteListItem;
