import { PartnerContract, Permissions, TAdministrativeLevel } from '@geovelo-frontends/commons';
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import { ChangeEvent, ReactNode, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../app/context';

export interface IContractFormValues {
  administrativeLevel?: TAdministrativeLevel;
  apiUsageLimit: number;
  contractTemplateId: number;
  endDate: string;
  limitAPIUsage: boolean;
  startDate: string;
}

function ContractForm<T extends IContractFormValues>({
  contractToUpdate,
  children,
  isSubmitting,
  values,
  touched,
  errors,
  handleChange,
}: {
  children?: ReactNode;
  contractToUpdate?: PartnerContract;
  errors: FormikErrors<T>;
  handleChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<number | string>,
  ) => void;
  isSubmitting: boolean;
  touched: FormikTouched<T>;
  values: T;
}): JSX.Element {
  const {
    partner: { contractTemplates },
  } = useContext(AppContext);
  const { t } = useTranslation();

  const contractTemplate = contractTemplates?.find(({ id }) => id === values.contractTemplateId);

  return (
    <Box display="flex" flexDirection="column" flexShrink={0} gap={1}>
      <Box display="flex" gap={2}>
        <TextField
          fullWidth
          required
          disabled={isSubmitting}
          error={touched.startDate && Boolean(errors.startDate)}
          id="startDate"
          InputLabelProps={{
            shrink: true,
          }}
          label={t('cycling-insights.admin.manage_contracts.form_dialog.start_date')}
          margin="dense"
          name="startDate"
          onChange={handleChange}
          size="small"
          type="date"
          value={values.startDate}
          variant="outlined"
        />
        <TextField
          fullWidth
          required
          disabled={isSubmitting}
          error={touched.endDate && Boolean(errors.endDate)}
          id="endDate"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{ inputProps: { min: values.startDate } }}
          label={t('cycling-insights.admin.manage_contracts.form_dialog.end_date')}
          margin="dense"
          name="endDate"
          onChange={handleChange}
          size="small"
          type="date"
          value={values.endDate}
          variant="outlined"
        />
      </Box>
      <FormControl
        fullWidth
        disabled={isSubmitting || Boolean(contractToUpdate)}
        margin="dense"
        size="small"
        variant="outlined"
      >
        <InputLabel htmlFor="template-field-input">
          <Trans i18nKey="cycling-insights.admin.manage_contracts.form_dialog.template" />
        </InputLabel>
        <Select
          inputProps={{
            id: 'template-field-input',
          }}
          label={<Trans i18nKey="cycling-insights.admin.manage_contracts.form_dialog.template" />}
          name="contractTemplateId"
          onChange={handleChange}
          value={values.contractTemplateId}
        >
          {contractTemplates?.map(({ id, title }) => (
            <MenuItem key={id} value={id}>
              {title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {children}

      {contractTemplate?.permissions.includes(Permissions.API) && (
        <TextField
          disabled={!values.limitAPIUsage || isSubmitting}
          error={touched.apiUsageLimit && Boolean(errors.apiUsageLimit)}
          id="apiUsageLimit"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Trans i18nKey="cycling-insights.admin.manage_contracts.form_dialog.calls" />
              </InputAdornment>
            ),
          }}
          margin="dense"
          name="apiUsageLimit"
          onChange={handleChange}
          size="small"
          sx={{ width: 180 }}
          type="number"
          value={values.apiUsageLimit}
          variant="outlined"
        />
      )}
    </Box>
  );
}

export default ContractForm;
