/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Period } from '@geovelo-frontends/commons';
import { Theme } from '@mui/material';
import { Chart, TooltipItem } from 'chart.js';
import moment, { Moment } from 'moment';
import { useEffect, useRef } from 'react';

import { clearData, resetChart, timeFormat } from '../../utils/charts';
import usePeriod from '../period';

function useDualColumnChart(
  chartId: string,
  initialCurrentPeriod: Period,
  initialPrevPeriod: Period | null,
  { palette }: Theme,
  labelFormat: (label: string | number) => string = (label) => `${label}`,
  type: 'bar' | 'line' = 'bar',
  hideLegend?: boolean,
): {
  updateData: (
    { unit }: Period,
    _currentPeriodData: { t: Moment; y: number }[],
    _prevPeriodData: { initialDate: Moment; t: Moment; y: number }[],
    props?: { computeMean?: boolean },
  ) => void;
  clearData: () => void;
  reset: (currentPeriod: Period, prevPeriod: Period) => void;
} {
  const chartRef = useRef<Chart<'bar' | 'line', number[], string>>();
  const { getTitle: getPeriodTitle } = usePeriod();

  useEffect(() => {
    const ctx = document.getElementById(chartId);
    if (!chartRef.current && ctx && ctx instanceof HTMLCanvasElement) {
      chartRef.current = new Chart(ctx, {
        type,
        data: {
          datasets: [
            {
              label: initialPrevPeriod ? getPeriodTitle(initialPrevPeriod) : '',
              data: [],
              backgroundColor: '#cacaca',
              borderColor: '#cacaca',
            },
            {
              label: initialCurrentPeriod ? getPeriodTitle(initialCurrentPeriod) : '',
              data: [],
              backgroundColor: palette.primary.main,
              borderColor: palette.primary.main,
            },
          ],
        },
        options: {
          scales: {
            x: {
              // @ts-ignore typings are uncorrect
              type: 'time',
              time: {
                unit: initialCurrentPeriod.unit,
                parser: timeFormat,
                displayFormats: {
                  day: initialCurrentPeriod.type === 'week' ? 'ddd' : 'DD',
                  month: 'MMM',
                },
              },
              // @ts-ignore typings are uncorrect
              min: initialCurrentPeriod.from.format(timeFormat),
              // @ts-ignore typings are uncorrect
              max: initialCurrentPeriod.to.format(timeFormat),
              grid: { display: false },
              offset: true,
            },
            y: {
              beginAtZero: true,
              ticks: {
                maxTicksLimit: 5,
                callback: (value) => labelFormat(value),
              },
              border: { dash: [8, 8] },
            },
          },
          plugins: {
            legend: {
              align: 'end',
              display: !hideLegend,
              labels: { usePointStyle: true },
            },
            tooltip: {
              mode: 'point',
              callbacks: {
                title: titleCallback(initialCurrentPeriod),
                label: ({ parsed }) => ('y' in parsed ? labelFormat(parsed.y) : ''),
              },
            },
          },
          maintainAspectRatio: false,
        },
      });
    }
  }, []);

  function titleCallback(period: Period) {
    return (items: TooltipItem<'bar'>[]) => {
      const { dataset, dataIndex } =
        items.find(({ datasetIndex }) => datasetIndex === 1) || items[0];
      const data = dataset.data[dataIndex];
      if (!data || typeof data === 'number') return '';

      const { initialDate, x } = data as unknown as { initialDate?: Moment; x: string };
      const date = initialDate || moment(x);

      return period.unit === 'month' ? date.format('MMMM YYYY') : date.format('LL');
    };
  }

  function updateData(
    { unit }: Period,
    _currentPeriodData: { t: Moment; y: number }[],
    _prevPeriodData: { initialDate: Moment; t: Moment; y: number }[],
    { computeMean }: { computeMean?: boolean } = {},
  ) {
    if (chartRef.current && chartRef.current.data.datasets) {
      let prevPeriodData: { initialDate: Moment; x: string; y: number }[] = [];
      let currentPeriodData: { x: string; y: number }[] = [];

      if (unit === 'month') {
        const prevPeriodValues: {
          [key: string]: { initialDate: Moment; x: string; y: number; count: number };
        } = {};
        const currentPeriodValues: { [key: string]: { x: string; y: number; count: number } } = {};

        _prevPeriodData.forEach(({ initialDate, t, y }) => {
          const key = t.clone().startOf('month').format('YYYY-MM-DD');
          if (prevPeriodValues[key]) {
            prevPeriodValues[key].y += y;
            if (y) ++prevPeriodValues[key].count;
          } else
            prevPeriodValues[key] = { initialDate, x: t.format(timeFormat), y, count: y ? 1 : 0 };
        });

        _currentPeriodData.forEach(({ t, y }) => {
          const key = t.clone().startOf('month').format('YYYY-MM-DD');
          if (currentPeriodValues[key]) {
            currentPeriodValues[key].y += y;
            if (y) ++currentPeriodValues[key].count;
          } else currentPeriodValues[key] = { x: t.format(timeFormat), y, count: y ? 1 : 0 };
        });

        if (computeMean) {
          Object.keys(prevPeriodValues).forEach((key) => {
            const { count } = prevPeriodValues[key];
            if (count > 1) {
              prevPeriodValues[key].y /= count;
            }
          });

          Object.keys(currentPeriodValues).forEach((key) => {
            const { count } = currentPeriodValues[key];
            if (count > 1) {
              currentPeriodValues[key].y /= count;
            }
          });
        }

        prevPeriodData = Object.values(prevPeriodValues);
        currentPeriodData = Object.values(currentPeriodValues);
      } else if (unit === 'day') {
        prevPeriodData = _prevPeriodData.map(({ initialDate, t, y }) => ({
          initialDate,
          x: t.format(timeFormat),
          y,
        }));
        currentPeriodData = _currentPeriodData.map(({ t, y }) => ({ x: t.format(timeFormat), y }));
      }

      // @ts-ignore typings are uncorrect
      chartRef.current.data.datasets[0].data = [...prevPeriodData];
      // @ts-ignore typings are uncorrect
      chartRef.current.data.datasets[1].data = currentPeriodData;

      chartRef.current.update();
    }
  }

  function _clearData() {
    if (chartRef.current) clearData(chartRef.current);
  }

  function reset(currentPeriod: Period, prevPeriod: Period) {
    if (chartRef.current)
      resetChart(chartRef.current, currentPeriod, titleCallback, labelFormat, [
        getPeriodTitle(prevPeriod) || '',
        getPeriodTitle(currentPeriod) || '',
      ]);
  }

  return { updateData, clearData: _clearData, reset };
}

export default useDualColumnChart;
