import { BikeRoute, Ride } from '@geovelo-frontends/commons';
import { Grid } from '@mui/material';
import styled from 'styled-components';

import IconForm from './icon-form';
import ImageList from './image-list';
import MainForm from './main-form';
import BikeRouteMap from './map';
import MediaList from './media-list';
import StepsList from './step-list';

interface IProps {
  bikeRoute: BikeRoute;
  canWrite: boolean;
  onChange: (bikeRoute: BikeRoute) => void;
  onRidesChange: (rides: Ride[]) => void;
  rides?: Ride[];
}

function DataTab(props: IProps): JSX.Element {
  return (
    <Wrapper>
      <StyledContent>
        <Grid container spacing={2} style={{ height: '100%' }}>
          <StyledGridItem item md={6} xs={12}>
            <MainForm {...props} />
            <IconForm {...props} />
            <ImageList {...props} />
            <MediaList {...props} />
          </StyledGridItem>
          <StyledGridItem item md={6} xs={12}>
            <BikeRouteMap {...props} />
            <StepsList {...props} />
          </StyledGridItem>
        </Grid>
      </StyledContent>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 32px);
  padding: 16px;
`;

const StyledGridItem = styled(Grid)`
  > *:not(:first-child) {
    margin-top: 16px;
  }
`;

export default DataTab;
