import { useFileSaver } from '@geovelo-frontends/commons';
import { Add, FileDownloadOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useOutletContext } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import ReportDetails from '../../../../components/report-details';
import ReportEdit from '../../../../components/report-edit';
import { environment } from '../../../../environment';
import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { TQAPageContext } from '../../context';

import ReportsForm from './form';

function ReportsTab(): JSX.Element {
  const context = useOutletContext<TQAPageContext & TOutletContext>();
  const {
    header: { setActions },
    period,
    osmReports: { tableRef, data: reports, selectedId, setData, selectId },
    setLoading,
  } = context;
  const {
    report: { types: reportTypes },
  } = useContext(AppContext);
  const [isEditing, setEditing] = useState<boolean>(false);
  const { downloadCSV } = useFileSaver();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setActions(undefined);
    };
  }, []);

  function handleDownload() {
    if (!reports) return;

    downloadCSV(
      `${t('cycling-insights.reports.osm_cartographic_reports.table.title')
        .replace(/ /g, '_')
        .toLowerCase()}-${period.values.current.from.format(
        'YYYY-MM-DD',
      )}_${period.values.current.to.format('YYYY-MM-DD')}.csv`,
      [
        'X',
        'Y',
        t('commons.administrative_levels.city_one'),
        'INSEE',
        t('commons.stats.date_label'),
        t('commons.stats.creator_label'),
        t('commons.stats.update_label'),
        t('commons.stats.type_label'),
        t('commons.stats.source_label'),
        t('commons.description'),
        t('commons.status'),
        t('commons.stats.links', { count: 1 }),
      ],
      reports.map(
        ({
          id,
          geoPoint,
          created,
          creator,
          updated,
          typeCode,
          source,
          description,
          status,
          city,
        }) => [
          geoPoint.coordinates[0],
          geoPoint.coordinates[1],
          city?.name || '',
          city?.reference || '',
          created.toISOString(),
          creator,
          updated.toISOString(),
          t(reportTypes?.find((type) => type.code === typeCode)?.titleKey || '').toString(),
          t(source?.title || '').toString(),
          `"${(description || '').replace(/"/g, "'")}"`,
          status,
          `${environment.bicycleFacilitiesUrl}fr/contributions/${id}`,
        ],
      ),
    );
  }

  useEffect(() => {
    setActions(
      <>
        <Tooltip title={<Trans i18nKey="cycling-insights.reports.navigation.new_report" />}>
          <IconButton color="primary" component={Link} size="small" to="./new-report">
            <Add />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={<Trans i18nKey="cycling-insights.reports.osm_cartographic_reports.download" />}
        >
          <span>
            <IconButton
              color="primary"
              disabled={!reports}
              onClick={() => handleDownload()}
              size="small"
            >
              <FileDownloadOutlined />
            </IconButton>
          </span>
        </Tooltip>
      </>,
    );
  }, [reports]);

  return (
    <PageContentLayout
      leftPanel={<ReportsForm {...context} />}
      rightPanel={
        <>
          {isEditing ? (
            <ReportEdit
              isBindToOSM
              reloadTablePage={tableRef.current?.reloadTablePage}
              reloadTableReport={tableRef.current?.reloadTableReport}
              reports={reports}
              selectedId={selectedId}
              selectId={selectId}
              setEditing={setEditing}
              setLoading={setLoading}
              setReports={setData}
            />
          ) : (
            <ReportDetails
              hasActions
              isBindToOSM
              reloadTableReport={tableRef.current?.reloadTableReport}
              reports={reports}
              selectedId={selectedId}
              setEditing={setEditing}
              setReports={setData}
            />
          )}
        </>
      }
    />
  );
}

export default ReportsTab;
