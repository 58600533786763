import { useOutletContext } from 'react-router-dom';

import PageContentLayout from '../../../../layouts/page/content';
import { IBicycleObservatoryPageContext } from '../../context';

import CountersForm from './form';

function CountersFormTab(): JSX.Element {
  const context = useOutletContext<IBicycleObservatoryPageContext>();

  return <PageContentLayout leftPanel={<CountersForm {...context} />} />;
}

export default CountersFormTab;
