import { TPermission } from '@geovelo-frontends/commons';
import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  List as MuiList,
  Skeleton,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Fragment, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import Card, { TAction } from './card';

export interface IItem<T> {
  action?: ReactNode;
  activeColor?: string;
  created?: ReactNode;
  creator?: ReactNode;
  disableActions?: boolean;
  icon?: ReactNode;
  key: T;
  source?: ReactNode;
  status?: ReactNode;
  subtitle?: ReactNode;
  title?: ReactNode;
  titleProps?: TypographyProps<'span', { component?: 'span' }>;
  type?: ReactNode;
  updated?: ReactNode;
}

interface IProps<T> {
  actions?: TAction[];
  emptyState?: ReactNode;
  items?: IItem<T>[];
  onClick?: (index: number) => void;
  onEdit?: (key: T) => void;
  onRemove?: (key: T) => void;
  permission?: TPermission;
  selectable?: boolean;
  selectIds?: (ids: number[]) => void;
  selectedIds?: number[];
  selectedIndex?: number | null;
  dense?: boolean;
  title?: ReactNode;
}

function List<T extends string | number>({
  permission,
  dense,
  emptyState,
  title,
  actions,
  items,
  selectable,
  selectedIds,
  selectedIndex,
  onClick,
  onEdit,
  onRemove,
  selectIds,
}: IProps<T>): JSX.Element {
  const loadingItems = [1, 2, 3];
  const hasActions = permission === 'write' && (Boolean(onEdit) || Boolean(onRemove));

  function handleToggle(key: number) {
    if (!selectedIds || !selectIds) return;
    const index = selectedIds.indexOf(key);
    if (index === -1) selectIds([...selectedIds, key]);
    else {
      selectedIds?.splice(index, 1);
      selectIds([...selectedIds]);
    }
  }

  if (items && items.length === 0) {
    return (
      <Card
        actions={actions}
        contentProps={{ elevation: 0 }}
        permission={permission}
        title={title}
        titleVariant={dense ? 'body1' : 'h6'}
      >
        <Box padding={2}>
          <Typography align="center" color="textSecondary" component="p" variant="caption">
            {emptyState || <Trans i18nKey="commons.stats.no_data" />}
          </Typography>
        </Box>
      </Card>
    );
  }

  return (
    <Card
      actions={actions}
      contentProps={{ elevation: 0, sx: { border: '1px solid #CFCFCF', borderRadius: '16px' } }}
      permission={permission}
      title={title}
      titleVariant={dense ? 'body1' : 'h6'}
    >
      <MuiList disablePadding>
        {items
          ? items.map((item, index) => {
              return (
                <Fragment key={item.key}>
                  {index > 0 && <Divider />}
                  {onClick ? (
                    <StyledListItemButton
                      active-color={item.activeColor}
                      className={index === selectedIndex ? 'active' : ''}
                      onClick={() => onClick(index)}
                    >
                      <ListItemContent
                        dense={dense}
                        item={item}
                        onEdit={onEdit}
                        onRemove={onRemove}
                        permission={permission}
                      />
                    </StyledListItemButton>
                  ) : (
                    <StyledListItem
                      active-color={item.activeColor}
                      className={index === selectedIndex ? 'active' : ''}
                      secondaryAction={
                        selectable && typeof item.key === 'number' ? (
                          <Checkbox
                            checked={(selectedIds || []).indexOf(item.key) !== -1}
                            edge="end"
                            onChange={() => handleToggle(item.key as number)}
                          />
                        ) : (
                          <></>
                        )
                      }
                    >
                      <ListItemContent
                        dense={dense}
                        item={item}
                        onEdit={onEdit}
                        onRemove={onRemove}
                        permission={permission}
                      />
                    </StyledListItem>
                  )}
                </Fragment>
              );
            })
          : loadingItems.map((key, index) => (
              <Fragment key={key}>
                {index > 0 && <Divider />}
                <ListItem>
                  <ListItemText
                    primary={<Skeleton variant="text" width={200} />}
                    sx={{ paddingRight: hasActions ? '32px' : undefined }}
                  />
                  {hasActions && (
                    <StyledListItemSecondaryAction>
                      {permission === 'write' && onEdit && (
                        <Skeleton height={24} variant="circular" width={24} />
                      )}
                      {permission === 'write' && onRemove && (
                        <Skeleton height={24} variant="circular" width={24} />
                      )}
                    </StyledListItemSecondaryAction>
                  )}
                </ListItem>
              </Fragment>
            ))}
      </MuiList>
    </Card>
  );
}

function ListItemContent<T extends string | number>({
  permission,
  dense,
  item: { key, icon, title, titleProps, subtitle, action, disableActions },
  onEdit,
  onRemove,
}: {
  dense?: boolean;
  item: IItem<T>;
  onEdit?: (key: T) => void;
  onRemove?: (key: T) => void;
  permission?: TPermission;
}): JSX.Element {
  const hasActions =
    !disableActions && permission === 'write' && (Boolean(onEdit) || Boolean(onRemove));

  return (
    <>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText
        primary={title}
        primaryTypographyProps={titleProps}
        secondary={subtitle}
        secondaryTypographyProps={{ color: '#326AC2' }}
        sx={{ paddingRight: hasActions ? '32px' : undefined }}
      />
      {(hasActions || action) && (
        <StyledListItemSecondaryAction>
          {permission === 'write' && onEdit && (
            <Tooltip placement="left" title={<Trans i18nKey="commons.actions.edit" />}>
              <IconButton onClick={() => onEdit(key)} size="small">
                <Edit fontSize={dense ? 'small' : 'medium'} />
              </IconButton>
            </Tooltip>
          )}
          {permission === 'write' && onRemove && (
            <Tooltip placement="left" title={<Trans i18nKey="commons.actions.remove" />}>
              <IconButton
                color="error"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  onRemove(key);
                }}
                size="small"
              >
                <Delete fontSize={dense ? 'small' : 'medium'} />
              </IconButton>
            </Tooltip>
          )}
          {action}
        </StyledListItemSecondaryAction>
      )}
    </>
  );
}

const StyledListItemButton = styled(ListItemButton)<{ 'active-color'?: string }>`
  && {
    padding: 18px 24px;
  }

  &&:hover {
    background-color: ${({ theme, 'active-color': activeColor }) =>
      alpha(activeColor || theme.palette.primary.light, 0.1)};
  }

  &.active {
    background-color: ${({ theme, 'active-color': activeColor }) =>
      alpha(activeColor || theme.palette.primary.light, 0.2)};
  }

  &&.active:hover {
    background-color: ${({ theme, 'active-color': activeColor }) =>
      alpha(activeColor || theme.palette.primary.light, 0.3)};
  }
`;

const StyledListItem = styled(ListItem)<{ 'active-color'?: string }>`
  &&:hover {
    background-color: ${({ theme, 'active-color': activeColor }) =>
      alpha(activeColor || theme.palette.primary.light, 0.1)};
  }

  &.active {
    background-color: ${({ theme, 'active-color': activeColor }) =>
      alpha(activeColor || theme.palette.primary.light, 0.2)};
  }

  &&.active:hover {
    background-color: ${({ theme, 'active-color': activeColor }) =>
      alpha(activeColor || theme.palette.primary.light, 0.3)};
  }
`;

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .MuiSkeleton-root {
    display: inline-block;
  }
`;

export default List;
