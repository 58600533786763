const doc = {
  request: {
    data: [
      {
        method: 'POST',
        url: 'https://backend.geovelo.fr/api/v2/routes_m2m',
      },
    ],
    parameters: {
      header: [
        {
          key: 'Content-Type',
          keyIsOptional: false,
          value: 'application/json',
        },
        {
          key: 'Source',
          keyIsOptional: true,
          value: 'The source name given by Geovelo (when given)',
        },
        {
          key: 'Api-Key',
          keyIsOptional: false,
          value: 'The API key given by Geovelo',
        },
      ],
      body: {
        data: [
          {
            key: 'starts',
            keyIsOptional: false,
            type: 'Array<TPoint>',
            values: null,
            defaultValueIndex: null,
            description: 'List of start points',
          },
          {
            key: 'ends',
            keyIsOptional: false,
            type: 'Array<TPoint>',
            values: null,
            defaultValueIndex: null,
            description: 'List of end points',
          },
          {
            key: 'pedestrianDetails',
            keyIsOptional: true,
            type: 'TPedestrianDetails',
            values: null,
            defaultValueIndex: null,
            description: 'See object description bellow',
          },
          {
            key: 'bikeDetails',
            keyIsOptional: true,
            type: 'TBikeDetails',
            values: null,
            defaultValueIndex: null,
            description: 'See object description bellow',
          },
          {
            key: 'transportModes',
            keyIsOptional: true,
            type: "Array<'BIKE' | 'PEDESTRIAN'>",
            values: ['BIKE', 'PEDESTRIAN'],
            defaultValueIndex: 0,
            description: 'Allowed transport modes',
          },
        ],
        objects: {
          wayPoint: [
            { key: 0, type: 'number', description: 'latitude' },
            { key: 1, type: 'number', description: 'longitude' },
            { key: 2, type: 'string', description: 'unique key of the point' },
          ],
          pedestrianDetails: [
            {
              key: 'profile',
              keyIsOptional: true,
              type: 'string',
              values: ['WALKING', 'WALKING_DIRECT', 'WALKING_SAFE', 'WALKING_RIDE'],
              defaultValueIndex: 0,
              description: 'The pedestrian profile to use to compute the itinerary',
            },
            {
              key: 'averageSpeed',
              keyIsOptional: true,
              type: 'number',
              values: null,
              defaultValueIndex: null,
              description: 'The average speed',
            },
          ],
          bikeDetails: [
            {
              key: 'profile',
              keyIsOptional: true,
              type: 'string',
              values: ['BEGINNER', 'MEDIAN', 'EXPERT', 'VTC', 'CARGO'],
              defaultValueIndex: 1,
              description:
                'The bike profile to use to compute the itinerary. It sets the average speed accordingly',
            },
            {
              key: 'bikeType',
              keyIsOptional: true,
              type: 'string',
              values: ['TRADITIONAL', 'BSS'],
              defaultValueIndex: 1,
              description: 'The type of bike of the user',
            },
            {
              key: 'averageSpeed',
              keyIsOptional: true,
              type: 'number',
              values: [
                '13 km/h for BEGINNER',
                '16 km/h for MEDIAN',
                '20 km/h for EXPERT',
                '20 km/h for VTC',
                '15 km/h for CARGO',
              ],
              defaultValueIndex: 0,
              description: 'The average speed value must be between 5 and 45 km/h',
            },
            {
              key: 'bikeStations',
              keyIsOptional: true,
              type: 'Array<TBikeStation>',
              values: null,
              defaultValueIndex: null,
              description: 'Bike stations parameters',
            },
            {
              key: 'eBike',
              keyIsOptional: true,
              type: 'boolean',
              values: ['true', 'false'],
              defaultValueIndex: 1,
              description: 'Use an electric bike',
            },
          ],
        },
      },
    },
    example: {
      url: 'https://backend.geovelo.fr/api/v2/routes_m2m',
      body_parameters: {
        starts: [[47.39037, 0.69408, 'start1']],
        ends: [[47.390363, 0.687492, 'end1']],
        bikeDetails: {
          profile: 'MEDIAN',
          bikeType: 'TRADITIONAL',
          averageSpeed: 15,
        },
        transportModes: ['BIKE'],
      },
    },
  },
  response: {
    data: [
      {
        status: '200 Ok',
        description: 'Returns a matrice of times',
      },
      {
        status: '400 Bad Request',
        description: 'Parameters sent are not valid',
      },
    ],
    examples: {
      response: {
        url: 'https://backend.geovelo.fr/api/v2/routes_m2m',
        body: [
          ['start_reference', 'end_reference', 'duration'],
          ['start1', 'end1', 248],
        ],
      },
    },
  },
};

export default doc;
