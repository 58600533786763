import { SvgIcon, SvgIconProps } from '@mui/material';

function NorthIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 49" {...props}>
      <path
        d="M11.94 16.5115L19.38 24.3515L11.6755 2.96973L4.5 24.3515L11.94 16.5115ZM5.78004 41.8727C6.03338 42.1235 6.39338 42.2489 6.86004 42.2489C7.34004 42.2489 7.70004 42.1235 7.94004 41.8727C8.18004 41.6087 8.30004 41.2392 8.30004 40.764V33.1459L14.78 41.3382C15.0334 41.6549 15.2667 41.8859 15.48 42.0311C15.7067 42.1763 16.0067 42.2489 16.38 42.2489C16.7667 42.2489 17.0734 42.1235 17.3 41.8727C17.5267 41.6087 17.64 41.259 17.64 40.8234V29.4C17.64 28.938 17.5134 28.5817 17.26 28.3309C17.02 28.0669 16.6667 27.9349 16.2 27.9349C15.7334 27.9349 15.3734 28.0669 15.12 28.3309C14.88 28.5817 14.76 28.938 14.76 29.4V36.9948L8.28004 28.8258C8.02671 28.5091 7.80004 28.2847 7.60004 28.1527C7.41338 28.0075 7.14004 27.9349 6.78004 27.9349C6.36671 27.9349 6.03338 28.0669 5.78004 28.3309C5.54004 28.5949 5.42004 28.9776 5.42004 29.4792V40.764C5.42004 41.2392 5.54004 41.6087 5.78004 41.8727Z"
        fill="black"
      />
    </SvgIcon>
  );
}

export default NorthIcon;
