import { Navigate, Route, RouteProps } from 'react-router-dom';

import BikeRouteDetail from '../../pages/bike-route';
import pages from '../../pages/dashboard-routes';
import ForgotPassword from '../../pages/forgot-password';
import Help from '../../pages/help';
import Login from '../../pages/login';
import DashboardPage from '../../pages/page';
import Partner from '../../pages/partner';
import PrivacyPolicy from '../../pages/privacy-policy';
import { ChallengeDetails, ChallengeUpdate, NewsUpdate } from '../../pages/promotion';
import RideDetail from '../../pages/ride';
import SetPassword from '../../pages/set-password';
import SettingsPage from '../../pages/settings';

type TPaths =
  | '/'
  | ':partner'
  | 'login'
  | 'set-password'
  | 'forgot-password'
  | '/login'
  | 'privacy-policy'
  | 'help';

export const defaultPrivatePath: TPaths = '/';
export const defaultGuestPath: TPaths = '/login';

const routes: Array<
  Omit<RouteProps, 'path' | 'index' | 'lazy'> & {
    isGuest?: boolean;
    isPrivate?: boolean;
    path?: TPaths;
  }
> = [
  {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    index: true,
    element: <Partner />,
    isPrivate: true,
  },
  { path: 'login', element: <Login />, isGuest: true },
  { path: 'set-password', element: <SetPassword />, isGuest: true },
  { path: 'forgot-password', element: <ForgotPassword />, isGuest: true },
  {
    path: ':partner',
    children: (
      <>
        {pages.map(({ PageElement, ...props }) => {
          const { pageKey, titleKey, descriptionKey, tabs, ...otherProps } = props;

          return (
            <Route
              element={
                <DashboardPage descriptionKey={descriptionKey} page={pageKey} titleKey={titleKey}>
                  <PageElement {...props} />
                </DashboardPage>
              }
              key={pageKey}
              {...otherProps}
            >
              {tabs && tabs?.length > 0 && (
                <>
                  {tabs.map(({ key, path, TabElement }) => (
                    <Route element={<TabElement />} key={key} path={path} />
                  ))}
                  <Route index element={<Navigate to={tabs[0].path} />} />
                </>
              )}
            </Route>
          );
        })}
        <Route element={<RideDetail />} path="promotion/rides/:id">
          <Route index element={<Navigate to="data" />} />
          <Route element={<></>} path=":tab" />
        </Route>
        <Route element={<BikeRouteDetail />} path="promotion/bike-routes/:id" />
        <Route element={<NewsUpdate />} path="promotion/post/:id/update" />
        <Route element={<ChallengeUpdate />} path="promotion/challenges/:id/update" />
        <Route element={<ChallengeDetails />} path="promotion/challenges/:id" />
        <Route element={<SettingsPage />} path="settings" />
        <Route element={<Navigate to="/" />} path="*" />
      </>
    ),
    element: <Partner />,
    isPrivate: true,
  },
  { path: 'help', element: <Help />, isPrivate: true },
  { path: 'privacy-policy', element: <PrivacyPolicy /> },
];

export default routes;
