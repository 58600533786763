import {
  SectionStats,
  StatsService,
  facilitiesLabels,
  roadTypesLabels,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../app/context';
import { getFacilityLabelKey } from '../../../components/form/facilities';
import useHeatmap from '../../../hooks/map/heatmap';
import { TOutletContext } from '../../../layouts/page/container';
import { TSectionProperties } from '../../../models/sections';
import { getFilteredSections as _getFilteredSections } from '../../../utils/sections';
import { TCartographicDataPageContext } from '../context';

function useDiscontinuity({
  context: {
    discontinuity: {
      currentRange,
      data,
      selectedFacilities,
      selectedSection,
      setBounds,
      setCurrentRange,
      setData,
      selectSection,
    },
    setLoading,
  },
}: {
  context: TCartographicDataPageContext & TOutletContext;
}) {
  const [stats, setStats] = useState<{ [key: number]: { current?: SectionStats } }>();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const {
    map: { current: currentMap, zoom },
    partner: { current: currentPartner, sections },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const {
    initialized: layersInitialized,
    init: initLayers,
    update: updateLayers,
    destroy: destroyLayers,
  } = useHeatmap(
    currentMap,
    {
      sourceId: 'discontinuity',
      layerId: 'discontinuity',
      primaryCriterion: 'discontinuity',
      colors: [{ value: '#d73027' }],
    },
    function discontinuityTooltipContent({
      id,
      wayName,
      roadType,
      contactFacility,
      beforeFacility,
      cyclability,
      tracesCount,
      discontinuityTracesCount,
    }: TSectionProperties) {
      return (
        `<div style="padding: 8px"><h3>${
          wayName ? wayName : t(roadTypesLabels[roadType || 'unclassified']) + ' - ' + id
        }</h3>` +
        (cyclability === 4
          ? `<div style="background-color: #C2E4BF; color: #1B6515; border: 1px solid #5ABA52; border-radius: 4px; width: fit-content; padding: 2px 6px; margin: 6px 0;">
          ${t(`cycling-insights.facilities.cyclability.very_cycleable`)}</div>`
          : cyclability === 3
            ? `<div style="background-color: #E4F2DE; color: #268B1D; border: 1px solid #97D391; border-radius: 4px; width: fit-content; padding: 2px 6px; margin: 6px 0;">
          ${t(`cycling-insights.facilities.cyclability.cycleable`)}</div>`
            : cyclability === 2
              ? `<div style="background-color: #FFF3F3; color: #D34949; border: 1px solid #FFA3A3; border-radius: 4px; width: fit-content; padding: 2px 6px; margin: 6px 0;">
          ${t(`cycling-insights.facilities.cyclability.not_very_cycleable`)}</div>`
              : `<div style="background-color: #FFD7D7; color: #A32828; border: 1px solid #F36565; border-radius: 4px; width: fit-content; padding: 2px 6px; margin: 6px 0;">
          ${t(`cycling-insights.facilities.cyclability.difficult_to_cycle`)}</div>`) +
        (roadType &&
          `<div>${
            t('cycling-insights.facilities.cyclability.road_type') +
            t(roadTypesLabels[roadType || 'unclassified'])
          }</div>`) +
        `<div>${t(
          'cycling-insights.facilities.discontinuity.' +
            (beforeFacility ? 'entering_facility' : 'exiting_facility'),
          {
            facility: t(
              contactFacility ? getFacilityLabelKey(contactFacility) : facilitiesLabels['unknown'],
            ),
          },
        )}</div>` +
        `<div>${t('cycling-insights.facilities.discontinuity.traces_count', {
          count: tracesCount,
          discontinuityCount: discontinuityTracesCount,
        })}</div>`
      );
    },
    (properties) => selectSection?.(properties),
  );

  useEffect(() => {
    if (currentMap) {
      initLayers();
    }

    return () => {
      destroyLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    if (!selectedSection) getStats();

    return () => {
      cancelPromises();
      setStats(undefined);
      setData(undefined);
      setBounds(undefined);
      setCurrentRange(undefined);

      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [selectedSection]);

  useEffect(() => {
    if (!sections || !data) setLoading(true);

    return () => setLoading(false);
  }, [sections, data]);

  useEffect(() => {
    if (layersInitialized && sections && stats) getFilteredSections();

    return () => {
      setData(undefined);
      setBounds(undefined);
      setCurrentRange(undefined);
    };
  }, [layersInitialized, sections, stats]);

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (!layersInitialized) return;

    if (data) {
      timeoutRef.current = setTimeout(() => {
        updateLayers(data, { currentRange });
      }, 300);
    } else {
      updateLayers(
        { type: 'FeatureCollection', features: [] },
        {
          currentRange: [0, 1],
          primaryBounds: { min: 0, max: 1 },
        },
      );
    }
  }, [layersInitialized, data, currentRange]);

  async function getStats() {
    if (!currentPartner) return;

    if (selectedFacilities.length === 0) {
      setStats({});
      return;
    }

    try {
      const discontinuities = await cancellablePromise(
        StatsService.getDiscontinuities({
          partnerId: currentPartner.id,
          facility_types: selectedFacilities,
        }),
      );

      const _stats: { [key: number]: { current?: SectionStats; prev?: SectionStats } } = {};
      discontinuities?.forEach(
        ({
          id,
          sectionId,
          isOnward,
          index,
          contactFacility,
          beforeFacility,
          cyclability,
          tracesCount,
          discontinuityTracesCount,
        }) => {
          let discontinuityStats = _stats[sectionId]?.current;
          if (discontinuityStats) {
            if (isOnward) {
              discontinuityStats = {
                ...discontinuityStats,
                discontinuityOnwardId: id,
                discontinuityOnwardIndex: index,
                contactFacilityOnward: contactFacility,
                beforeFacilityOnward: beforeFacility,
                cyclabilityOnward: cyclability,
                tracesCountOnward: tracesCount,
                discontinuityTracesCountOnward: discontinuityTracesCount,
              };
            } else {
              discontinuityStats = {
                ...discontinuityStats,
                discontinuityBackwardId: id,
                discontinuityBackwardIndex: index,
                contactFacilityBackward: contactFacility,
                beforeFacilityBackward: beforeFacility,
                cyclabilityBackward: cyclability,
                tracesCountBackward: tracesCount,
                discontinuityTracesCountBackward: discontinuityTracesCount,
              };
            }
          } else {
            const sectionStats: SectionStats = isOnward
              ? {
                  sectionId,
                  discontinuityOnwardId: id,
                  discontinuityBackwardId: undefined,
                  discontinuityOnwardIndex: index,
                  discontinuityBackwardIndex: undefined,
                  contactFacilityOnward: contactFacility,
                  contactFacilityBackward: undefined,
                  beforeFacilityOnward: beforeFacility,
                  beforeFacilityBackward: undefined,
                  cyclabilityOnward: cyclability,
                  cyclabilityBackward: undefined,
                  tracesCountOnward: tracesCount,
                  tracesCountBackward: undefined,
                  discontinuityTracesCountOnward: discontinuityTracesCount,
                  discontinuityTracesCountBackward: undefined,
                }
              : {
                  sectionId,
                  discontinuityOnwardId: undefined,
                  discontinuityBackwardId: id,
                  discontinuityOnwardIndex: undefined,
                  discontinuityBackwardIndex: index,
                  contactFacilityOnward: undefined,
                  contactFacilityBackward: contactFacility,
                  beforeFacilityOnward: undefined,
                  beforeFacilityBackward: beforeFacility,
                  cyclabilityOnward: undefined,
                  cyclabilityBackward: cyclability,
                  tracesCountOnward: undefined,
                  tracesCountBackward: tracesCount,
                  discontinuityTracesCountOnward: undefined,
                  discontinuityTracesCountBackward: discontinuityTracesCount,
                };

            _stats[sectionId] = { current: sectionStats };
          }
        },
      );

      setStats(_stats);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('commons.stats.no_data'), { variant: 'error' });
      }
    }
  }

  function getFilteredSections() {
    if (!sections || !stats || zoom === undefined) return;

    const {
      filteredSections,
      bounds: _bounds,
      currentRange: _currentRange,
    } = _getFilteredSections({
      sections,
      zoom,
      stats,
      primaryCriterion: 'discontinuity',
    });

    setData(filteredSections);
    setBounds(_bounds);
    setCurrentRange(_currentRange);
  }

  return { initialized: layersInitialized };
}

export default useDiscontinuity;
