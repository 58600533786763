import { TAdministrativeLevel, TBNACFacility, bnacFacilitiesMap } from '@geovelo-frontends/commons';
import { VectorTile } from '@mapbox/vector-tile';
import { latLngToCell } from 'h3-js';
import moment from 'moment';
import Pbf from 'pbf';

import { defaultResolution } from '../../../hooks/map/h3';
import { TFubFeature, tilesZoom } from '../models/accidentology';

function useVectorTiles() {
  function getTileXY(lat: number, lng: number, zoom: number) {
    const rad = lat * (Math.PI / 180);
    const n = 2.0 ** zoom;
    const x = Math.floor(((lng + 180.0) / 360.0) * n);
    const y = Math.floor(((1.0 - Math.asinh(Math.tan(rad)) / Math.PI) / 2.0) * n);
    return { y, x };
  }

  async function getAccidents(
    administrativeLevel: TAdministrativeLevel,
    tiles: Array<{ x: number; y: number }>,
  ) {
    const years: number[] = [];

    const res = await Promise.all(
      tiles.map(({ x, y }, index) => {
        return new Promise<TFubFeature[]>((resolve) => {
          const req = new XMLHttpRequest();

          req.onload = () => {
            const arraybuffer = req.response;
            const pbf = new Pbf(arraybuffer);
            const {
              layers: { accidents },
            } = new VectorTile(pbf);

            if (accidents?.length) {
              resolve(
                new Array(accidents.length).fill(null).map((_, index) => {
                  const feature = accidents.feature(index).toGeoJSON(x, y, tilesZoom);
                  const {
                    geometry,
                    properties: { datetime, data },
                  } = feature as GeoJSON.Feature<GeoJSON.Point, { data: string; datetime: string }>;
                  const { location, users, vehicles, way } = JSON.parse(data) as {
                    location?: { road_category?: string };
                    users?: Array<{ injury_severity: 'blesse_hospitalise' | 'tue' | 'indemne' }>;
                    vehicles?: Array<{ vehicle_category?: string }>;
                    way?: { facility: TBNACFacility | null };
                  };
                  const date = moment(datetime);

                  years.push(date.get('year'));

                  return {
                    ...feature,
                    geometry,
                    properties: {
                      h3Indexes: {
                        [defaultResolution]: latLngToCell(
                          geometry.coordinates[1],
                          geometry.coordinates[0],
                          defaultResolution,
                        ),
                      },
                      date,
                      accidentData: {
                        type: users?.find(({ injury_severity }) => injury_severity === 'tue')
                          ? 'deadly'
                          : users?.find(
                                ({ injury_severity }) => injury_severity === 'blesse_hospitalise',
                              )
                            ? 'hospitalized'
                            : 'injured',
                        roadType: location?.road_category,
                        vehicles:
                          vehicles
                            ?.map(({ vehicle_category }) => vehicle_category || '')
                            .filter((vehicle) => vehicle && vehicle !== 'sans_objet') || [],
                        facility: (way?.facility && bnacFacilitiesMap[way.facility]) || null,
                      },
                    },
                  };
                }),
              );
            } else resolve([]);
          };

          req.onerror = () => {
            resolve([]);
          };

          const tileServerIndex = (index % 4) + 1;
          req.open(
            'GET',
            `https://tiles${
              !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development'
                ? '-dev'
                : tileServerIndex > 1
                  ? tileServerIndex
                  : ''
            }.geovelo.fr/vector/accidents/${tilesZoom}/${x}/${y}.pbf`,
          );
          req.responseType = 'arraybuffer';
          req.send();
        });
      }),
    );

    return {
      features: res.flatMap((features) => features),
      years: [...new Set(years)].sort((a, b) => a - b),
    };
  }

  async function getBlackSpots(
    administrativeLevel: TAdministrativeLevel,
    tiles: Array<{ x: number; y: number }>,
  ) {
    const years: number[] = [];

    const res = await Promise.all(
      tiles.map(({ x, y }, index) => {
        return new Promise<TFubFeature[]>((resolve) => {
          const req = new XMLHttpRequest();

          req.onload = () => {
            const arraybuffer = req.response;
            const pbf = new Pbf(arraybuffer);
            const {
              layers: { pointsnoirs },
            } = new VectorTile(pbf);

            if (pointsnoirs?.length) {
              resolve(
                new Array(pointsnoirs.length).fill(null).map((_, index) => {
                  const feature = pointsnoirs.feature(index).toGeoJSON(x, y, tilesZoom);
                  const {
                    geometry,
                    properties: { datetime },
                  } = feature as GeoJSON.Feature<GeoJSON.Point, { datetime: string }>;
                  const date = moment(datetime);

                  years.push(date.get('year'));

                  return {
                    ...feature,
                    geometry,
                    properties: {
                      h3Indexes: {
                        [defaultResolution]: latLngToCell(
                          geometry.coordinates[1],
                          geometry.coordinates[0],
                          defaultResolution,
                        ),
                      },
                      date: date.add(1, 'day'),
                    },
                  };
                }),
              );
            } else resolve([]);
          };

          req.onerror = () => {
            resolve([]);
          };

          const tileServerIndex = (index % 4) + 1;
          req.open(
            'GET',
            `https://tiles${
              tileServerIndex > 1 ? tileServerIndex : ''
            }.geovelo.fr/vector/pointsnoirs_fub/${tilesZoom}/${x}/${y}.pbf`,
          );
          req.responseType = 'arraybuffer';
          req.send();
        });
      }),
    );

    return {
      features: res.flatMap((features) => features),
      years: [...new Set(years)].sort((a, b) => a - b),
    };
  }

  return { getTileXY, getAccidents, getBlackSpots };
}

export default useVectorTiles;
