import { useOutletContext } from 'react-router-dom';

import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { TQAPageContext } from '../../context';

import BikeRoutesForm from './form';
import BikeRouteList from './list';

function BikeRoutesTab(): JSX.Element {
  const context = useOutletContext<TQAPageContext & TOutletContext>();

  return (
    <PageContentLayout
      leftPanel={<BikeRoutesForm {...context} />}
      main={<BikeRouteList {...context} />}
    />
  );
}

export default BikeRoutesTab;
