import { BikeRoute, BikeRouteService, FileInput, TFile } from '@geovelo-frontends/commons';
import { CircularProgress, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from '../../../components/button';
import Card from '../../../components/card';

interface IProps {
  bikeRoute: BikeRoute;
  canWrite: boolean;
  onChange: (bikeRoute: BikeRoute) => void;
}

function IconForm({ canWrite, bikeRoute, onChange }: IProps): JSX.Element {
  const [icon, setIcon] = useState<TFile>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    init();
  }, [bikeRoute]);

  function init() {
    const { iconUrl, iconTitle } = bikeRoute;

    if (iconUrl) {
      setIcon({
        url: iconUrl,
        name: iconTitle || '',
      });
    } else {
      setIcon(undefined);
    }
  }

  async function handleSubmit() {
    if (!(icon instanceof File)) return;

    setIsSubmitting(true);

    try {
      const updatedRide = await BikeRouteService.updateBikeRouteIcon(bikeRoute.id, icon);

      enqueueSnackbar(t('cycling-insights.bike_route.updated'), { variant: 'success' });
      onChange(updatedRide);
    } catch {
      enqueueSnackbar(t('cycling-insights.bike_route.not_updated'), { variant: 'error' });
    }

    setIsSubmitting(false);
  }

  return (
    <Card title={<Trans i18nKey="cycling-insights.bike_route.icon_form.title" />}>
      <Wrapper>
        {icon === null && (
          <Typography color="error" variant="caption">
            <Trans i18nKey="cycling-insights.bike_route.icon_form.required" />
          </Typography>
        )}
        {!canWrite && !bikeRoute.iconUrl && (
          <Typography color="textSecondary" variant="caption">
            <Trans i18nKey="cycling-insights.bike_route.icon_form.empty" />
          </Typography>
        )}
        {canWrite && (
          <>
            <FileInput
              disabled={isSubmitting}
              file={icon}
              onChange={setIcon}
              size="small"
              type="image"
            />
            <StyledActions>
              <Button
                disabled={isSubmitting}
                onClick={() => init()}
                size="medium"
                variant="outlined"
              >
                <Trans i18nKey="commons.actions.reset" />
              </Button>
              <Button
                color="primary"
                disabled={isSubmitting || !(icon instanceof File)}
                onClick={handleSubmit}
                size="medium"
                startIcon={
                  isSubmitting && <CircularProgress color="inherit" size={16} thickness={4} />
                }
                variant="contained"
              >
                <Trans i18nKey="commons.actions.update" />
              </Button>
            </StyledActions>
          </>
        )}
      </Wrapper>
    </Card>
  );
}

const Wrapper = styled.div`
  padding: 16px;
`;

const StyledActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  button:not(:first-child) {
    margin-left: 8px;
  }
`;

export default IconForm;
