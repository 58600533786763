import { facilitiesLabels, roadTypesLabels } from '@geovelo-frontends/commons';
import { InfoOutlined, MapOutlined, Place } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import { getFacilityLabelKey } from '../../../../components/form/facilities';
import Ranking from '../../../../components/ranking';
import { TOutletContext } from '../../../../layouts/page/container';
import { TCartographicDataPageContext } from '../../context';
import useDiscontinuity from '../../hooks/discontinuity';

function MostImportantDiscontinuities({
  clickDisabled,
  ...context
}: TCartographicDataPageContext & TOutletContext & { clickDisabled?: boolean }): JSX.Element {
  const {
    discontinuity: { data, selectSection },
  } = context;
  const {
    map: { current: currentMap },
  } = useContext(AppContext);
  const { t } = useTranslation();
  useDiscontinuity({ context });

  return (
    <Ranking
      disableValue
      enableSubtitles
      action={
        <Tooltip title={t('cycling-insights.facilities.discontinuity.ranking.details')}>
          <InfoOutlined fontSize="small" />
        </Tooltip>
      }
      data={data?.features
        .sort(
          (a, b) => (a.properties.discontinuityIndex || 0) - (b.properties.discontinuityIndex || 0),
        )
        .slice(0, 5)
        .reduce<{ id?: number; subtitle: string; title: string }[]>(
          (
            res,
            {
              properties: {
                id: propertiesId,
                wayName,
                roadType: _roadType,
                beforeFacility,
                contactFacility: _contactFacility,
                tracesCount,
              },
            },
          ) => {
            const contactFacility = t(
              _contactFacility
                ? getFacilityLabelKey(_contactFacility)
                : facilitiesLabels['unknown'],
            );
            const roadType = t(roadTypesLabels[_roadType || 'unclassified']);

            res.push({
              id: propertiesId,
              title: wayName || roadType + ' - ' + propertiesId.toString(),
              subtitle: `${beforeFacility ? `${contactFacility} → ${roadType}` : `${roadType} → ${contactFacility}`} • ${t('commons.journeys', { count: tracesCount })}`,
            });

            return res;
          },
          [],
        )}
      onClick={
        !clickDisabled
          ? (id) => {
              const feature = data?.features.find(({ properties }) => properties.id === id);
              selectSection?.(feature?.properties);
            }
          : undefined
      }
      onSecondaryClick={(id) => {
        const feature = data?.features.find(({ properties }) => properties.id === id);
        const coordinates = feature?.geometry.coordinates;

        if (coordinates) {
          const [lng, lat] = coordinates[Math.round(coordinates.length / 2)];
          currentMap?.flyTo({
            center: { lat, lng },
            zoom: 18,
            animate: false,
          });
        }
      }}
      secondaryAction={{
        Icon: MapOutlined,
        tooltip: t('cycling-insights.facilities.discontinuity.actions.display_on_map'),
      }}
      startIcon={<Place sx={{ color: '#E76685' }} />}
      subtitle={
        <Typography marginBottom={2} marginTop={1} variant="body2">
          {t('cycling-insights.facilities.discontinuity.ranking.subtitle')}
        </Typography>
      }
      title="cycling-insights.facilities.discontinuity.ranking.title"
    />
  );
}

export default MostImportantDiscontinuities;
