import { DialogProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { Trans } from 'react-i18next';

import Dialog from './dialog';

type TProps = Omit<DialogProps, 'onClose' | 'title'> & {
  description?: ReactNode;
  dialogTitle: string;
  onCancel: () => void;
  onConfirm: () => void;
  safe?: boolean;
  loading: boolean;
  title: ReactNode;
};

function ConfirmDialog({ loading, children, description, safe, ...props }: TProps): JSX.Element {
  return (
    <Dialog
      confirmTitle={<Trans i18nKey="commons.actions.confirm" />}
      dangerous={!safe}
      loading={loading}
      maxWidth="xs"
      {...props}
    >
      {description || (
        <Typography color="error">
          <Trans i18nKey="commons.confirm_dialog.description" />
        </Typography>
      )}
    </Dialog>
  );
}

export default ConfirmDialog;
