import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TH3CellFeatureProps } from '../../models/accidentology';

function H3CellTooltip({
  h3map,
  object: {
    properties: { h3Index, accidents, blackSpots, suddenBrakings, reports, frequency },
  },
}: {
  h3map?: { [key: string]: { id: number } };
  object: GeoJSON.Feature<GeoJSON.Polygon, TH3CellFeatureProps>;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography fontWeight={600} variant="body2">
          {t('cycling-insights.usage.accidentology.h3_cell_tooltip.title', {
            id: h3map?.[h3Index].id,
          })}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          <b>{t('cycling-insights.usage.accidentology.h3_cell_tooltip.label_accidents')}</b>{' '}
          {accidents || 0}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          <b>{t('cycling-insights.usage.accidentology.h3_cell_tooltip.label_black_spots')}</b>{' '}
          {blackSpots || 0}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          <b>{t('cycling-insights.usage.accidentology.h3_cell_tooltip.label_sudden_brakings')}</b>{' '}
          {suddenBrakings || 0}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          <b>{t('cycling-insights.usage.accidentology.h3_cell_tooltip.label_reports')}</b>{' '}
          {reports || 0}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          <b>{t('commons.stats.roads_uses_label')}</b> {frequency || 0}
        </Typography>
      </Box>
    </Box>
  );
}

export default H3CellTooltip;
