import { SvgIcon, SvgIconProps } from '@mui/material';

function MembershipsIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 69 64" {...props}>
      <ellipse cx="32.0357" cy="32" fill="#86D1F1" opacity="0.2" rx="32.0357" ry="32" />
      <ellipse cx="32.0354" cy="32.0007" fill="#37B1E5" rx="18.8929" ry="18.8718" />
      <ellipse cx="32.0354" cy="31.9993" fill="white" rx="13.1429" ry="13.1282" />
      <path
        d="M27.1077 31.1809C28.015 31.1809 28.7506 30.0788 28.7506 28.7194C28.7506 27.3599 28.015 26.2578 27.1077 26.2578C26.2004 26.2578 25.4648 27.3599 25.4648 28.7194C25.4648 30.0788 26.2004 31.1809 27.1077 31.1809Z"
        fill="#005A44"
      />
      <path
        d="M27.1067 32.8196C28.014 32.8196 28.7496 31.7175 28.7496 30.358C28.7496 28.9986 28.014 27.8965 27.1067 27.8965C26.1994 27.8965 25.4639 28.9986 25.4639 30.358C25.4639 31.7175 26.1994 32.8196 27.1067 32.8196Z"
        fill="white"
      />
      <path
        d="M36.9641 31.1809C37.8715 31.1809 38.607 30.0788 38.607 28.7194C38.607 27.3599 37.8715 26.2578 36.9641 26.2578C36.0568 26.2578 35.3213 27.3599 35.3213 28.7194C35.3213 30.0788 36.0568 31.1809 36.9641 31.1809Z"
        fill="#005A44"
      />
      <path
        d="M36.9641 32.8196C37.8715 32.8196 38.607 31.7175 38.607 30.358C38.607 28.9986 37.8715 27.8965 36.9641 27.8965C36.0568 27.8965 35.3213 28.9986 35.3213 30.358C35.3213 31.7175 36.0568 32.8196 36.9641 32.8196Z"
        fill="white"
      />
      <path
        clipRule="evenodd"
        d="M50.3212 16.9477C50.3212 15.3067 51.3069 12.0246 55.2498 12.0246C53.6069 12.0246 50.3212 11.04 50.3212 7.10156L50.3212 16.9477ZM50.3212 7.10201C50.3212 8.74304 49.3354 12.0251 45.3926 12.0251C47.0354 12.0251 50.3212 13.0097 50.3212 16.9482V7.10201Z"
        fill="#37B1E5"
        fillRule="evenodd"
      />
      <path
        d="M55.2498 12.5246C55.5259 12.5246 55.7498 12.3008 55.7498 12.0246C55.7498 11.7485 55.5259 11.5246 55.2498 11.5246V12.5246ZM49.8212 16.9477C49.8212 17.2239 50.045 17.4477 50.3212 17.4477C50.5973 17.4477 50.8212 17.2239 50.8212 16.9477H49.8212ZM50.8212 7.10156C50.8212 6.82542 50.5973 6.60156 50.3212 6.60156C50.045 6.60156 49.8212 6.82542 49.8212 7.10156L50.8212 7.10156ZM45.3926 11.5251C45.1164 11.5251 44.8926 11.7489 44.8926 12.0251C44.8926 12.3012 45.1164 12.5251 45.3926 12.5251V11.5251ZM50.8212 7.10201C50.8212 6.82587 50.5973 6.60201 50.3212 6.60201C50.045 6.60201 49.8212 6.82587 49.8212 7.10201H50.8212ZM49.8212 16.9482C49.8212 17.2243 50.045 17.4482 50.3212 17.4482C50.5973 17.4482 50.8212 17.2243 50.8212 16.9482H49.8212ZM55.2498 11.5246C53.1156 11.5246 51.7334 12.424 50.9013 13.5784C50.0876 14.7073 49.8212 16.0471 49.8212 16.9477H50.8212C50.8212 16.2073 51.0477 15.0855 51.7125 14.1632C52.359 13.2663 53.4411 12.5246 55.2498 12.5246V11.5246ZM49.8212 7.10156C49.8212 9.23399 50.7219 10.6149 51.8775 11.4459C53.0075 12.2587 54.3484 12.5246 55.2498 12.5246V11.5246C54.5082 11.5246 53.3849 11.2983 52.4613 10.6341C51.5633 9.98824 50.8212 8.9076 50.8212 7.10156L49.8212 7.10156ZM50.8212 16.9477L50.8212 7.10156L49.8212 7.10156L49.8212 16.9477H50.8212ZM45.3926 12.5251C47.5267 12.5251 48.909 11.6257 49.741 10.4713C50.5548 9.34239 50.8212 8.00265 50.8212 7.10201H49.8212C49.8212 7.8424 49.5947 8.9642 48.9298 9.88657C48.2833 10.7835 47.2013 11.5251 45.3926 11.5251V12.5251ZM50.8212 16.9482C50.8212 14.8157 49.9204 13.4348 48.7649 12.6038C47.6349 11.7911 46.2939 11.5251 45.3926 11.5251V12.5251C46.1341 12.5251 47.2574 12.7514 48.181 13.4156C49.079 14.0615 49.8212 15.1421 49.8212 16.9482H50.8212ZM49.8212 7.10201V16.9482H50.8212V7.10201H49.8212Z"
        fill="#37B1E5"
      />
      <path
        clipRule="evenodd"
        d="M11.7144 44.125C11.7144 45.219 11.0573 47.4071 8.42871 47.4071C9.52395 47.4071 11.7144 48.0635 11.7144 50.6891V44.125ZM11.7145 50.6895C11.7145 49.5954 12.3717 47.4074 15.0002 47.4074C13.905 47.4074 11.7145 46.751 11.7145 44.1254L11.7145 50.6895Z"
        fill="#37B1E5"
        fillRule="evenodd"
      />
      <path
        d="M8.42871 46.9071C8.15257 46.9071 7.92871 47.1309 7.92871 47.4071C7.92871 47.6832 8.15257 47.9071 8.42871 47.9071V46.9071ZM12.2144 44.125C12.2144 43.8489 11.9906 43.625 11.7144 43.625C11.4383 43.625 11.2144 43.8489 11.2144 44.125H12.2144ZM11.2144 50.6891C11.2144 50.9652 11.4383 51.1891 11.7144 51.1891C11.9906 51.1891 12.2144 50.9652 12.2144 50.6891H11.2144ZM15.0002 47.9074C15.2764 47.9074 15.5002 47.6835 15.5002 47.4074C15.5002 47.1313 15.2764 46.9074 15.0002 46.9074V47.9074ZM11.2145 50.6895C11.2145 50.9656 11.4384 51.1895 11.7145 51.1895C11.9907 51.1895 12.2145 50.9656 12.2145 50.6895H11.2145ZM12.2145 44.1254C12.2145 43.8492 11.9907 43.6254 11.7145 43.6254C11.4384 43.6254 11.2145 43.8492 11.2145 44.1254L12.2145 44.1254ZM8.42871 47.9071C9.90572 47.9071 10.8772 47.2811 11.4629 46.4686C12.0302 45.6816 12.2144 44.7521 12.2144 44.125H11.2144C11.2144 44.5919 11.0701 45.3034 10.6517 45.8839C10.2516 46.4389 9.58027 46.9071 8.42871 46.9071V47.9071ZM12.2144 50.6891C12.2144 49.2131 11.5875 48.2424 10.7742 47.6575C9.98647 47.091 9.05623 46.9071 8.42871 46.9071V47.9071C8.89643 47.9071 9.60905 48.0513 10.1903 48.4694C10.7461 48.8691 11.2144 49.5395 11.2144 50.6891H12.2144ZM11.2144 44.125V50.6891H12.2144V44.125H11.2144ZM15.0002 46.9074C13.5232 46.9074 12.5517 47.5333 11.9661 48.3458C11.3988 49.1328 11.2145 50.0623 11.2145 50.6895H12.2145C12.2145 50.2226 12.3589 49.511 12.7773 48.9305C13.1773 48.3755 13.8487 47.9074 15.0002 47.9074V46.9074ZM11.2145 44.1254C11.2145 45.6014 11.8415 46.572 12.6547 47.1569C13.4425 47.7235 14.3727 47.9074 15.0002 47.9074V46.9074C14.5325 46.9074 13.8199 46.7631 13.2386 46.3451C12.6828 45.9454 12.2145 45.275 12.2145 44.1254L11.2145 44.1254ZM12.2145 50.6895L12.2145 44.1254L11.2145 44.1254L11.2145 50.6895H12.2145Z"
        fill="#37B1E5"
      />
    </SvgIcon>
  );
}

export default MembershipsIcon;
