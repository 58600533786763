import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

import Drawer, { closedDrawerWidth, smallScreenClosedDrawerWidth } from '../layouts/default/drawer';

function PrivacyPolicy(): JSX.Element {
  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Drawer />
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        sx={{
          paddingLeft: `${smallScreenClosedDrawerWidth}px`,
          '@media (min-width: 600px)': { paddingLeft: `${closedDrawerWidth}px` },
          '@media print': { paddingLeft: 0 },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={4}
          maxWidth="calc(100% - 32px)"
          paddingX={2}
          paddingY={4}
          width={1000}
        >
          <Typography color="primary" component="h1" variant="h5">
            Politique de confidentialité
          </Typography>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography>
              Chez Geovelo, la protection de vos données personnelles est une priorité.
            </Typography>
            <Typography>
              Lors de votre utilisation de la Plateforme Cycling Insights (ci-après la «&nbsp;
              <b>Plateforme</b>&nbsp;»), nous sommes amenés à recueillir des données à caractère
              personnel vous concernant.
            </Typography>
            <Typography>
              Le but de cette politique est de vous informer sur les modalités selon lesquelles nous
              traitons ces données en conformité avec le Règlement (UE) 2016/679 du 27 avril 2016
              relatif à la protection des personnes physiques à l'égard du traitement des données à
              caractère personnel et à la libre circulation de ces données (ci-après le «&nbsp;
              <b>RGPD</b>&nbsp;»).
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography component="h6" fontSize="1.1em" variant="h6">
              1. Qui est le responsable de traitement&nbsp;?
            </Typography>
            <Typography>
              Le responsable de traitement est la société La Compagnie des Mobilités, société par
              actions simplifiée, immatriculée au RCS de Tours sous le n° 521 563 742 et dont le
              siège social est situé au 1 impasse du Palais, 37 000 Tours (ci-après
              «&nbsp;Nous&nbsp;»).
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography component="h6" fontSize="1.1em" variant="h6">
              2. Quelles données collectons-nous&nbsp;?
            </Typography>
            <Typography>
              Une donnée à caractère personnel est une donnée qui permet d'identifier un individu
              directement ou par recoupement avec d'autres données.
            </Typography>
            <Typography>
              Nous collectons des données qui relèvent des catégories suivantes&nbsp;:
            </Typography>
            <Typography component="ul">
              <li>
                <b>Des données d'identification</b> (nom, prénom, adresse email professionnelle,
                numéro de téléphone professionnel)&nbsp;;
              </li>
              <li>
                <b>Des données relatives à votre vie professionnelle</b> (nom de la collectivité,
                poste / fonction, profession)&nbsp;;
              </li>
              <li>
                <b>Des données de connexion</b> (logs de connexion, mots de passe chiffrés)&nbsp;;
              </li>
              <li>
                <b>Des données de navigation</b> (adresse IP, date de la dernière connexion)&nbsp;;
              </li>
              <li>
                <b>
                  Toute information que vous souhaitez nous transmettre dans le cadre de votre
                  demande de contact.
                </b>
              </li>
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography component="h6" fontSize="1.1em" variant="h6">
              3. Sur quelles bases légales, pour quelles finalités et pendant combien de temps
              conservons-nous vos données personnelles&nbsp;?
            </Typography>
            <Table component="table" style={{ border: '1px solid #ddd', borderSpacing: 0 }}>
              <thead>
                <tr>
                  <th>Finalités</th>
                  <th>Bases légales</th>
                  <th>Durées de conservation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Fournir nos services disponibles sur notre Plateforme via la création de compte
                    utilisateurs
                  </td>
                  <td>
                    Exécution de mesures précontractuelles prises à votre demande et/ou exécution du
                    contrat que vous ou votre société avez souscrit avec Nous
                  </td>
                  <td>
                    <ul>
                      <li>
                        Toute la durée de la relation contractuelle et du compte sur la Plateforme
                        Cycling Insights.
                      </li>
                      <li>
                        Jusqu'à 5 ans au-delà de la relation commerciale ou de la suppression du
                        compte sur la Plateforme Cycling Insights à des fins de preuve, en archivage
                        intermédiaire.
                      </li>
                      <li>Les logs sont conservés 1 mois.</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    Effectuer les opérations relatives à la gestion de nos clients concernant les
                    contrats, factures, et suivi de la relation contractuelle avec nos clients
                  </td>
                  <td>Exécution du contrat que vous ou votre société avez souscrit avec Nous</td>
                  <td>
                    <ul>
                      <li>
                        Toute la durée de la relation contractuelle et du compte sur la Plateforme
                        Cycling Insights.
                      </li>
                      <li>
                        Jusqu'à 5 ans au-delà de la relation commerciale ou de la suppression du
                        compte sur la Plateforme Cycling Insights à des fins de preuve, en archivage
                        intermédiaire.
                      </li>
                      <li>Les factures sont archivées pendant une durée de 10 ans.</li>
                      <li>Les contrats sont conservés 5 ans à compter de la fin du contrat.</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Constituer un fichier de clients et prospects</td>
                  <td>Notre intérêt légitime à développer et promouvoir notre activité</td>
                  <td>
                    Pour les clients&nbsp;: les données sont conservées pendant toute la durée de la
                    relation contractuelle.
                    <br />
                    <br />
                    Pour les prospects&nbsp;: les données sont conservées pendant un délai de 3 ans
                    à compter de votre dernier contact, à des fins de prospection.
                  </td>
                </tr>
                <tr>
                  <td>Adresser des newsletters, sollicitations et messages promotionnels</td>
                  <td>
                    Notre intérêt légitime à fidéliser et informer nos clients et prospects de nos
                    dernières actualités
                  </td>
                  <td>
                    Les données sont conservées pendant 3 ans à compter de votre dernier contact
                    avec Nous
                  </td>
                </tr>
                <tr>
                  <td>Répondre à vos demandes d'information</td>
                  <td>Notre intérêt légitime à répondre à vos demandes</td>
                  <td>
                    Les données sont conservées pendant le temps nécessaire au traitement de votre
                    demande d'information et supprimées une fois la demande d'information traitée.
                  </td>
                </tr>
                <tr>
                  <td>Se conformer aux obligations légales applicables à notre activité</td>
                  <td>Se conformer à nos obligations légales et règlementaires</td>
                  <td>
                    Pour les factures&nbsp;: les factures sont archivées pendant une durée de 10
                    ans.
                  </td>
                </tr>
                <tr>
                  <td>Gérer les demandes d'exercice de droits</td>
                  <td>
                    Notre intérêt légitime à répondre à vos demandes et à conserver un suivi de
                    celles-ci
                  </td>
                  <td>
                    Si nous vous demandons un justificatif d'identité&nbsp;: nous le conservons
                    seulement pendant le temps nécessaire à la vérification d'identité. Une fois la
                    vérification effectuée, le justificatif est supprimé.
                    <br />
                    <br />
                    Si vous exercez votre droit d'opposition à recevoir de la prospection&nbsp;:
                    nous conservons cette information pendant 3 ans.
                    <br />
                    <br />
                    Les informations permettant la gestion de vos demandes d'exercice de droits en
                    application du RGPD seront conservées pendant 2 (deux) ans à compter de la
                    demande.
                  </td>
                </tr>
              </tbody>
            </Table>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography component="h6" fontSize="1.1em" variant="h6">
              4. Qui sont les destinataires de vos données&nbsp;?
            </Typography>
            <Typography>Auront accès à vos données à caractère personnel&nbsp;:</Typography>
            <Typography component="ol" type="i">
              <li>Le personnel de notre société&nbsp;;</li>
              <li>
                Nos sous-traitants&nbsp;: prestataire d'hébergement, outil CRM, suite bureautique et
                hébergement, outil de communication interne, outil de facturation et outil
                d'emailing&nbsp;;
              </li>
              <li>
                Le cas échéant&nbsp;: les organismes publics et privés, exclusivement pour répondre
                à nos obligations légales.
              </li>
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography component="h6" fontSize="1.1em" variant="h6">
              5. Vos données sont-elles susceptibles d'être transférées hors de l'Union
              européenne&nbsp;?
            </Typography>
            <Typography>
              Vos données sont conservées et stockées pendant toute la durée des traitements sur les
              serveurs de la société Scaleway (Online), situés en France, dans l'Union européenne.
            </Typography>
            <Typography>
              Dans le cadre des outils que nous utilisons (voir article sur les destinataires
              concernant nos sous-traitants), vos données sont susceptibles de faire l'objet de
              transferts hors de l'Union européenne. Le transfert de vos données dans ce cadre est
              sécurisé au moyen des outils suivants&nbsp;:
            </Typography>
            <Typography component="ul">
              <li>
                soit les données sont transférées dans un pays ayant fait l'objet d'une décision
                d'adéquation de la Commission européenne, conformément à l'article 45 du RGPD&nbsp;:
                dans ce cas, ce pays assure un niveau de protection jugé comme suffisant et adéquat
                aux dispositions du RGPD&nbsp;;
              </li>
              <li>
                soit les données sont transférées dans un pays dont le niveau de protection des
                données n'a pas été reconnu comme adéquat au RGPD&nbsp;: dans ce cas ces transferts
                sont fondés sur des garanties appropriées indiquées à l'article 46 du RGPD, adaptées
                à chaque prestataire, notamment de façon non exhaustive la conclusion de clauses
                contractuelles types approuvées par la Commission Européenne, l'application de
                règles d'entreprises contraignantes ou en vertu d'un mécanisme de certification
                approuvé&nbsp;;
              </li>
              <li>
                soit les données sont transférées sur le fondement de l'une des garanties
                appropriées décrites au Chapitre V du RGPD.
              </li>
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography component="h6" fontSize="1.1em" variant="h6">
              6. Quels sont vos droits sur vos données&nbsp;?
            </Typography>
            <Typography>
              Vous disposez des droits suivants s'agissant de vos données personnelles&nbsp;:
            </Typography>
            <Typography component="ul">
              <li>
                <b>Droit à l'information</b>&nbsp;: c'est justement la raison pour laquelle nous
                avons rédigé la présente politique. Ce droit est prévu par les articles 13 et 14 du
                RGPD.
              </li>
              <li>
                <b>Droit d'accès</b>&nbsp;: vous avez le droit d'accéder à tout moment à l'ensemble
                de vos données personnelles, en vertu de l'article 15 du RGPD.
              </li>
              <li>
                <b>Droit de rectification</b>&nbsp;: vous avez le droit de rectifier à tout moment
                vos données personnelles inexactes, incomplètes ou obsolètes conformément à
                l'article 16 du RGPD.
              </li>
              <li>
                <b>Droit à la limitation</b>&nbsp;: vous avez le droit d'obtenir la limitation du
                traitement de vos données personnelles dans certains cas définis à l'article 18 du
                RGPD.
              </li>
              <li>
                <b>Droit à l'effacement</b>&nbsp;: vous avez le droit d'exiger que vos données
                personnelles soient effacées, et d'en interdire toute collecte future pour les
                motifs énoncés à l'article 17 du RGPD.
              </li>
              <li>
                <b>
                  Droit d'introduire une réclamation auprès d'une autorité de contrôle compétente
                </b>{' '}
                (en France, la CNIL), si vous considérez que le traitement de vos données
                personnelles constitue une violation des textes applicables. (Article 77 du RGPD)
              </li>
              <li>
                <b>
                  Droit de définir des directives relatives à la conservation, à l'effacement et à
                  la communication de vos données personnelles après votre mort.
                </b>
              </li>
              <li>
                <b>Droit de retirer votre consentement à tout moment</b>&nbsp;: pour les finalités
                fondées sur le consentement, l'article 7 du RGPD dispose que vous pouvez retirer
                votre consentement à tout moment. Ce retrait ne remettra pas en cause la légalité du
                traitement effectué avant le retrait.
              </li>
              <li>
                <b>Droit à la portabilité</b>&nbsp;: selon certaines conditions précisées à
                l'article 20 du RGPD, vous avez le droit de recevoir les données personnelles que
                vous nous avez fournies dans un format standard lisible par machine et d'exiger leur
                transfert au destinataire de votre choix.
              </li>
              <li>
                <b>Droit d'opposition</b>&nbsp;: en vertu de l'article 21 du RGPD, vous avez le
                droit de vous opposer au traitement de vos données personnelles. Notez toutefois que
                nous pourrons maintenir leur traitement malgré cette opposition, pour des motifs
                légitimes ou la défense de droits en justice.
              </li>
            </Typography>
            <Typography>
              Vous pouvez exercer ces droits en nous écrivant aux coordonnées ci-dessous. Nous
              pourrons vous demander à cette occasion de nous fournir des informations ou documents
              complémentaires pour justifier votre identité.
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography component="h6" fontSize="1.1em" variant="h6">
              7. Délégué à la protection des données
            </Typography>
            <Typography>
              Email de contact&nbsp;:{' '}
              <Typography color="secondary" component="a" href="mailto:dpo@geovelo.fr">
                dpo@geovelo.fr
              </Typography>
            </Typography>
            <Typography>
              Adresse de contact&nbsp;: La Compagnie des Mobilités, 1 impasse du Palais, 37 000
              Tours
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography component="h6" fontSize="1.1em" variant="h6">
              8. Modifications
            </Typography>
            <Typography>
              Nous pouvons modifier à tout moment la présente politique, afin notamment de nous
              conformer à toutes évolutions réglementaires, jurisprudentielles, éditoriales ou
              techniques. Ces modifications s'appliqueront à la date d'entrée en vigueur de la
              version modifiée. Vous êtes donc invité à consulter régulièrement la dernière version
              de cette politique. Néanmoins, nous vous tiendrons informé(e) de toute modification
              significative de la présente politique de confidentialité.
            </Typography>
            <Typography>Entrée en vigueur&nbsp;: 01/09/2022</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const Table = styled(Typography)<{ component: 'table' }>`
  tbody td {
    border-top: 1px solid #ddd;
  }

  th,
  td {
    padding: 8px;
    width: calc(33% - 32px);

    &:not(:first-child) {
      border-left: 1px solid #ddd;
    }
  }
`;

export default PrivacyPolicy;
