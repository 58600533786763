import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function CyclingInsightsTextIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 183 25" {...props}>
      <path
        d="M1.81166 5.95922C2.485 4.76046 3.4757 3.77024 4.67505 3.09724C5.88469 2.41392 7.24079 2.07227 8.74333 2.07227C10.5021 2.07227 12.0427 2.52294 13.3651 3.42429C13.8151 3.72926 14.2283 4.08517 14.5965 4.48493C15.5353 5.50888 14.8004 7.16453 13.411 7.16453C13.163 7.16755 12.9178 7.11123 12.696 7.00028C12.4741 6.88933 12.282 6.72696 12.1358 6.52672C11.8339 6.09621 11.4415 5.73696 10.9859 5.47424C10.2937 5.09093 9.51211 4.89792 8.7209 4.91489C7.86172 4.89976 7.01507 5.12225 6.27443 5.55779C5.55637 5.99067 4.97732 6.61999 4.60573 7.37136C4.20682 8.15248 4.00771 9.06946 4.00839 10.1223C4.00839 11.1595 4.2075 12.0724 4.60573 12.861C4.97517 13.6167 5.55447 14.2502 6.27443 14.6858C7.01515 15.121 7.86181 15.3431 8.7209 15.3276C9.5144 15.3421 10.2971 15.1418 10.9859 14.7479C11.4403 14.4804 11.8317 14.1183 12.1337 13.6863C12.2801 13.4855 12.4724 13.3226 12.6946 13.2113C12.9168 13.1 13.1625 13.0435 13.411 13.0464H13.4324C13.7425 13.0465 14.0459 13.1365 14.3059 13.3055C14.5658 13.4746 14.7711 13.7154 14.8968 13.9988C15.0226 14.2821 15.0633 14.5958 15.0142 14.9019C14.965 15.2079 14.8281 15.4932 14.62 15.723C14.2505 16.1295 13.8337 16.4904 13.3784 16.7978C12.062 17.6924 10.517 18.1393 8.74333 18.1387C7.24011 18.1387 5.88367 17.797 4.67403 17.1137C3.47675 16.4421 2.48645 15.4554 1.81064 14.2609C1.11136 13.0444 0.761719 11.6621 0.761719 10.1141C0.761719 8.56614 1.1117 7.18117 1.81166 5.95922Z"
        fill="currentColor"
      />
      <path
        d="M29.9006 7.63783L23.4542 22.9676C23.3371 23.246 23.1404 23.4837 22.8887 23.6509C22.637 23.818 22.3415 23.9071 22.0393 23.907C21.7843 23.9083 21.533 23.8457 21.3084 23.7252C21.0837 23.6046 20.8928 23.4298 20.753 23.2166C20.6132 23.0035 20.5289 22.7588 20.5079 22.5047C20.4869 22.2507 20.5297 21.9955 20.6326 21.7623L22.4052 17.6868L18.2666 7.6256C18.1714 7.39356 18.1349 7.14168 18.1601 6.89217C18.1853 6.64266 18.2716 6.40319 18.4113 6.19488C18.551 5.98657 18.7399 5.81583 18.9612 5.69771C19.1826 5.5796 19.4296 5.51773 19.6805 5.51758H19.8752C20.1875 5.51779 20.4923 5.61359 20.7485 5.79209C21.0047 5.9706 21.2001 6.22325 21.3084 6.51607L24.1626 14.239L27.0596 6.50894C27.1687 6.21843 27.3637 5.96799 27.6186 5.7909C27.8735 5.61382 28.1763 5.51849 28.4868 5.51758C28.7392 5.5169 28.9879 5.5787 29.2107 5.69747C29.4334 5.81624 29.6232 5.9883 29.7633 6.19827C29.9033 6.40824 29.9891 6.64959 30.0131 6.90079C30.037 7.15198 29.9984 7.40519 29.9006 7.63783Z"
        fill="currentColor"
      />
      <path
        d="M32.461 8.36967C32.9619 7.4271 33.7201 6.64594 34.6475 6.11697C35.5792 5.58717 36.6465 5.32227 37.8493 5.32227C39.3967 5.32227 40.6781 5.70875 41.6934 6.48173C41.9744 6.69616 42.2332 6.93815 42.466 7.2041C42.6594 7.42522 42.7848 7.69733 42.8275 7.98787C42.8701 8.27841 42.8281 8.57508 42.7065 8.84238C42.5849 9.10969 42.3888 9.33631 42.1417 9.49514C41.8946 9.65398 41.6069 9.73829 41.3131 9.73801H41.2611C41.0058 9.73306 40.7555 9.66633 40.5317 9.54354C40.3078 9.42075 40.117 9.24555 39.9757 9.03295C39.8212 8.81367 39.631 8.62184 39.413 8.46545C38.9842 8.15775 38.4545 8.00356 37.8239 8.00289C36.9221 8.00289 36.2085 8.32994 35.6832 8.98405C35.1579 9.63816 34.8946 10.5657 34.8932 11.7666C34.8932 12.9532 35.1565 13.8732 35.6832 14.5266C36.2099 15.1801 36.9234 15.5071 37.8239 15.5078C38.8011 15.5078 39.5191 15.1729 39.9778 14.5032C40.1183 14.2892 40.3091 14.1127 40.5335 13.9893C40.7579 13.8659 41.0091 13.7992 41.2652 13.7951C42.5782 13.7951 43.2917 15.3519 42.4161 16.33C42.1914 16.5808 41.9443 16.8106 41.6781 17.0167C40.6587 17.8135 39.3814 18.2119 37.8463 18.2119C36.6434 18.2119 35.5762 17.9449 34.6445 17.411C33.7168 16.8823 32.9585 16.1011 32.4579 15.1583C31.9313 14.1897 31.6683 13.0591 31.6689 11.7666C31.6696 10.474 31.9336 9.34166 32.461 8.36967Z"
        fill="currentColor"
      />
      <path
        d="M49.2118 2.90533V16.4276C49.2118 16.8459 49.0455 17.2471 48.7496 17.5429C48.4537 17.8387 48.0523 18.0048 47.6338 18.0048C47.4262 18.0054 47.2206 17.965 47.0287 17.8859C46.8368 17.8069 46.6623 17.6908 46.5154 17.5443C46.3684 17.3978 46.2518 17.2238 46.1723 17.0321C46.0927 16.8405 46.0518 16.6351 46.0518 16.4276V2.90533C46.0518 2.48703 46.218 2.08586 46.5139 1.79008C46.8099 1.4943 47.2112 1.32813 47.6297 1.32813C47.8373 1.32759 48.0429 1.368 48.2348 1.44702C48.4268 1.52604 48.6012 1.64213 48.7482 1.78864C48.8951 1.93515 49.0117 2.1092 49.0913 2.30082C49.1708 2.49244 49.2118 2.69787 49.2118 2.90533Z"
        fill="currentColor"
      />
      <path
        d="M52.5377 3.50265C52.3628 3.33048 52.2238 3.12525 52.129 2.89891C52.0342 2.67256 51.9854 2.42963 51.9854 2.18424C51.9854 1.93886 52.0342 1.69592 52.129 1.46957C52.2238 1.24323 52.3628 1.03801 52.5377 0.865839C52.9191 0.52521 53.4126 0.336914 53.924 0.336914C54.4355 0.336914 54.929 0.52521 55.3104 0.865839C55.4853 1.03801 55.6242 1.24323 55.7191 1.46957C55.8139 1.69592 55.8627 1.93886 55.8627 2.18424C55.8627 2.42963 55.8139 2.67256 55.7191 2.89891C55.6242 3.12525 55.4853 3.33048 55.3104 3.50265C54.9293 3.84391 54.4357 4.03261 53.924 4.03261C53.4124 4.03261 52.9188 3.84391 52.5377 3.50265ZM55.4796 7.09616V16.4279C55.479 16.8458 55.3126 17.2465 55.0167 17.5418C54.7208 17.8372 54.3198 18.0031 53.9016 18.0031C53.4835 18.0031 53.0824 17.8372 52.7865 17.5418C52.4907 17.2465 52.3242 16.8458 52.3236 16.4279V7.09616C52.3236 6.67787 52.4899 6.2767 52.7858 5.98092C53.0817 5.68514 53.4831 5.51897 53.9016 5.51897C54.1088 5.51897 54.314 5.55977 54.5055 5.63903C54.6969 5.71829 54.8709 5.83446 55.0174 5.98092C55.1639 6.12738 55.2802 6.30125 55.3595 6.4926C55.4388 6.68396 55.4796 6.88904 55.4796 7.09616Z"
        fill="currentColor"
      />
      <path
        d="M69.1188 6.74825C70.0356 7.68764 70.494 8.99824 70.494 10.68V16.4274C70.494 16.6345 70.4531 16.8396 70.3738 17.031C70.2945 17.2223 70.1783 17.3962 70.0318 17.5427C69.8853 17.6891 69.7113 17.8053 69.5199 17.8846C69.3284 17.9638 69.1232 18.0046 68.916 18.0046C68.4974 18.0046 68.0959 17.8385 67.7998 17.5427C67.5037 17.247 67.3373 16.8458 67.337 16.4274V11.109C67.337 10.1173 67.0893 9.35517 66.5939 8.82265C66.0985 8.29012 65.422 8.02352 64.5643 8.02285C63.6924 8.02285 63.005 8.28944 62.5022 8.82265C61.9993 9.35585 61.7485 10.1173 61.7499 11.1069V16.4274C61.7499 16.8457 61.5836 17.2469 61.2877 17.5427C60.9918 17.8384 60.5904 18.0046 60.1719 18.0046C59.9643 18.0051 59.7587 17.9648 59.5668 17.8857C59.3749 17.8067 59.2004 17.6906 59.0535 17.5441C58.9065 17.3976 58.7899 17.2235 58.7104 17.0319C58.6308 16.8403 58.5898 16.6349 58.5898 16.4274V7.1181C58.5898 6.90803 58.6312 6.70003 58.7117 6.50595C58.7921 6.31188 58.91 6.13554 59.0586 5.987C59.2072 5.83847 59.3836 5.72064 59.5778 5.64025C59.772 5.55986 59.9801 5.51849 60.1902 5.51849C60.3949 5.51808 60.5977 5.55803 60.7869 5.63604C60.9761 5.71404 61.148 5.82858 61.2929 5.97309C61.4377 6.1176 61.5527 6.28924 61.6311 6.4782C61.7095 6.66716 61.7499 6.86972 61.7499 7.07429C62.1785 6.52769 62.7313 6.09109 63.3625 5.80071C64.0368 5.48872 64.7723 5.33103 65.5154 5.33917C67.0009 5.33917 68.2021 5.80886 69.1188 6.74825Z"
        fill="currentColor"
      />
      <path
        d="M80.8427 5.86837C81.5072 6.20001 82.0799 6.68986 82.5104 7.29478V7.10323C82.5104 6.68169 82.6779 6.27742 82.9761 5.97934C83.2744 5.68127 83.6788 5.51381 84.1006 5.51381C84.5222 5.51408 84.9264 5.68166 85.2244 5.97971C85.5224 6.27775 85.6898 6.68187 85.6898 7.10323V18.0937C85.6898 19.2484 85.4567 20.2812 84.9905 21.192C84.5215 22.1045 83.7921 22.8573 82.8947 23.3551C81.9616 23.8883 80.8342 24.1549 79.5124 24.1549C77.7387 24.1549 76.2844 23.7419 75.1496 22.9159C74.7887 22.6556 74.4653 22.3471 74.1883 21.999C73.4003 21.0035 74.1424 19.5343 75.4115 19.5343C75.6603 19.5356 75.9049 19.5971 76.1247 19.7135C76.3445 19.8299 76.5328 19.9977 76.6735 20.2027C76.8743 20.4835 77.1307 20.72 77.4268 20.8976C77.9752 21.2372 78.6402 21.407 79.4217 21.407C80.3378 21.407 81.0819 21.1329 81.6541 20.5848C82.2263 20.0366 82.5117 19.2066 82.5104 18.0947V16.1589C82.0736 16.772 81.4985 17.2736 80.8315 17.623C80.0792 18.0235 79.2372 18.2255 78.385 18.2098C77.3651 18.2179 76.364 17.9351 75.4992 17.3947C74.6123 16.8368 73.898 16.0435 73.436 15.1033C72.9325 14.1205 72.6807 12.9902 72.6807 11.7126C72.6807 10.4498 72.9325 9.3291 73.436 8.35031C73.8959 7.42221 74.6068 6.64146 75.488 6.0966C76.3613 5.56909 77.3647 5.29628 78.385 5.30902C79.3167 5.31785 80.1359 5.5043 80.8427 5.86837ZM82.0598 9.78996C81.7803 9.26366 81.3586 8.8263 80.8427 8.5276C80.3426 8.23803 79.7744 8.08648 79.1964 8.08847C78.6164 8.08267 78.046 8.23607 77.5472 8.53196C77.0484 8.82785 76.6404 9.25488 76.3677 9.76653C76.0585 10.3154 75.9042 10.9654 75.9049 11.7166C75.8892 12.4024 76.0484 13.0809 76.3677 13.6881C76.6479 14.2228 77.0687 14.6709 77.5848 14.9841C78.0704 15.2795 78.628 15.4353 79.1964 15.4344C79.7745 15.4369 80.3427 15.2853 80.8427 14.9953C81.3586 14.6966 81.7803 14.2593 82.0598 13.733C82.3602 13.1848 82.5104 12.5276 82.5104 11.7615C82.5104 10.9953 82.3602 10.3381 82.0598 9.78996Z"
        fill="currentColor"
      />
      <path
        d="M88.7636 17.6307C88.5886 17.4587 88.4496 17.2536 88.3547 17.0273C88.2598 16.8011 88.2109 16.5582 88.2109 16.3128C88.2109 16.0675 88.2598 15.8246 88.3547 15.5983C88.4496 15.3721 88.5886 15.1669 88.7636 14.9949C88.9517 14.8178 89.1731 14.6798 89.4149 14.5889C89.6567 14.4979 89.9142 14.4559 90.1724 14.4651C90.4254 14.4568 90.6776 14.4994 90.9138 14.5904C91.1501 14.6814 91.3656 14.819 91.5475 14.9949C91.8943 15.346 92.0888 15.8195 92.0888 16.3128C92.0888 16.8062 91.8943 17.2797 91.5475 17.6307C91.3657 17.8069 91.1502 17.9446 90.914 18.0356C90.6777 18.1266 90.4255 18.1691 90.1724 18.1605C89.6036 18.1599 89.134 17.9832 88.7636 17.6307Z"
        fill="#a6c836"
      />
      <path
        d="M97.7966 3.85161V16.4274C97.7966 16.8457 97.6304 17.2469 97.3344 17.5426C97.0385 17.8384 96.6371 18.0046 96.2186 18.0046C96.0113 18.0047 95.806 17.964 95.6145 17.8848C95.4229 17.8056 95.2488 17.6895 95.1022 17.543C94.9555 17.3965 94.8392 17.2226 94.7599 17.0312C94.6805 16.8398 94.6396 16.6346 94.6396 16.4274V3.85161C94.6396 3.43331 94.8059 3.03214 95.1018 2.73636C95.3977 2.44058 95.7991 2.27441 96.2176 2.27441C96.6362 2.27441 97.0377 2.44054 97.3338 2.7363C97.6299 3.03205 97.7963 3.43322 97.7966 3.85161Z"
        fill="currentColor"
      />
      <path
        d="M111.437 6.74827C112.353 7.68766 112.811 8.99825 112.811 10.6801V16.4274C112.811 16.8457 112.645 17.2469 112.349 17.5427C112.053 17.8385 111.652 18.0046 111.233 18.0046C110.816 18.0033 110.416 17.8365 110.121 17.5409C109.826 17.2452 109.66 16.8449 109.66 16.4274V11.109C109.66 10.1173 109.412 9.35519 108.916 8.82267C108.42 8.29014 107.744 8.02354 106.888 8.02286C106.017 8.02286 105.329 8.28946 104.825 8.82267C104.32 9.35587 104.069 10.118 104.069 11.109V16.4274C104.069 16.8457 103.903 17.2469 103.607 17.5427C103.311 17.8385 102.91 18.0046 102.491 18.0046C102.284 18.0053 102.078 17.965 101.886 17.886C101.694 17.8071 101.519 17.691 101.372 17.5445C101.225 17.398 101.108 17.2239 101.029 17.0322C100.949 16.8405 100.908 16.635 100.908 16.4274V7.0743C100.908 6.66186 101.072 6.26629 101.364 5.97455C101.655 5.68281 102.051 5.51877 102.464 5.5185H102.509C102.921 5.51877 103.317 5.68281 103.609 5.97455C103.9 6.26629 104.064 6.66186 104.064 7.0743C104.492 6.52726 105.045 6.09057 105.677 5.80073C106.351 5.4885 107.087 5.33081 107.83 5.33918C109.318 5.33918 110.52 5.80888 111.437 6.74827Z"
        fill="currentColor"
      />
      <path
        d="M117.838 17.6507C117.079 17.3237 116.416 16.8081 115.912 16.1529C115.907 16.1469 115.902 16.1405 115.898 16.1336C115.262 15.2767 115.929 14.0572 116.997 14.0572H117.465C117.918 14.0572 118.303 14.3272 118.535 14.7164C118.662 14.9244 118.827 15.106 119.023 15.2513C119.436 15.5671 119.951 15.7247 120.567 15.724C121.168 15.724 121.638 15.6041 121.976 15.3644C122.132 15.2659 122.261 15.1295 122.35 14.9679C122.439 14.8063 122.486 14.6248 122.486 14.4402C122.486 14.0327 122.279 13.727 121.866 13.5233C121.453 13.3195 120.794 13.0991 119.89 12.862C119.112 12.684 118.347 12.4504 117.602 12.1631C117.011 11.9228 116.487 11.5444 116.073 11.0597C115.645 10.5638 115.431 9.89511 115.431 9.05353C115.427 8.37556 115.636 7.71341 116.028 7.16049C116.426 6.58993 116.997 6.13926 117.742 5.80847C118.486 5.47768 119.36 5.31568 120.363 5.32247C121.85 5.32247 123.04 5.69402 123.931 6.4371C124.172 6.63855 124.389 6.8669 124.577 7.1177C124.739 7.33431 124.837 7.59159 124.861 7.86077C124.885 8.12996 124.833 8.40044 124.712 8.642C124.591 8.88355 124.405 9.08665 124.175 9.2286C123.945 9.37055 123.68 9.44575 123.409 9.44579C123.16 9.45146 122.915 9.39038 122.697 9.26889C122.48 9.14741 122.299 8.96996 122.174 8.75501C122.069 8.56423 121.927 8.39678 121.755 8.2629C121.387 7.96947 120.895 7.82275 120.278 7.82275C119.707 7.82275 119.267 7.92464 118.96 8.1386C118.813 8.23256 118.693 8.36299 118.612 8.51712C118.531 8.67125 118.491 8.84381 118.497 9.01788C118.497 9.43833 118.707 9.75757 119.128 9.9756C119.549 10.1936 120.203 10.4151 121.09 10.6399C121.849 10.8187 122.594 11.0505 123.32 11.3337C123.91 11.5784 124.433 11.9631 124.841 12.4545C125.27 12.9578 125.491 13.6224 125.507 14.4484C125.515 15.1404 125.306 15.8175 124.909 16.3842C124.511 16.9548 123.94 17.4017 123.195 17.725C122.45 18.0484 121.583 18.21 120.592 18.21C119.644 18.2239 118.705 18.0331 117.838 17.6507Z"
        fill="currentColor"
      />
      <path
        d="M128.315 3.50265C128.14 3.33048 128.001 3.12525 127.906 2.89891C127.812 2.67256 127.763 2.42963 127.763 2.18424C127.763 1.93886 127.812 1.69592 127.906 1.46957C128.001 1.24323 128.14 1.03801 128.315 0.865839C128.696 0.52521 129.19 0.336914 129.701 0.336914C130.213 0.336914 130.706 0.52521 131.088 0.865839C131.263 1.03801 131.402 1.24323 131.496 1.46957C131.591 1.69592 131.64 1.93886 131.64 2.18424C131.64 2.42963 131.591 2.67256 131.496 2.89891C131.402 3.12525 131.263 3.33048 131.088 3.50265C130.707 3.84391 130.213 4.03261 129.701 4.03261C129.19 4.03261 128.696 3.84391 128.315 3.50265ZM131.257 7.09616V16.4279C131.257 16.8462 131.091 17.2474 130.795 17.5431C130.499 17.8389 130.097 18.0051 129.679 18.0051C129.471 18.0055 129.266 17.965 129.074 17.8859C128.882 17.8068 128.708 17.6907 128.561 17.5442C128.414 17.3977 128.298 17.2237 128.218 17.0322C128.139 16.8406 128.098 16.6353 128.098 16.4279V7.09616C128.098 6.67787 128.264 6.2767 128.56 5.98092C128.856 5.68514 129.257 5.51897 129.676 5.51897C130.094 5.51897 130.496 5.68514 130.792 5.98092C131.088 6.2767 131.254 6.67787 131.254 7.09616H131.257Z"
        fill="currentColor"
      />
      <path
        d="M141.716 5.86837C142.38 6.20028 142.953 6.69008 143.383 7.29477V7.1022C143.383 6.68093 143.551 6.27692 143.849 5.97904C144.147 5.68116 144.551 5.5138 144.973 5.5138C145.181 5.51367 145.388 5.55466 145.581 5.63442C145.774 5.71419 145.949 5.83117 146.097 5.97868C146.245 6.12619 146.362 6.30134 146.442 6.49412C146.522 6.6869 146.563 6.89353 146.563 7.1022V18.0937C146.563 19.2484 146.33 20.2812 145.864 21.192C145.394 22.1046 144.664 22.8573 143.767 23.3551C142.835 23.8883 141.708 24.1549 140.385 24.1549C138.612 24.1549 137.157 23.7419 136.023 22.9159C135.662 22.6556 135.338 22.3471 135.061 21.999C134.273 21.0035 135.014 19.5343 136.285 19.5343C136.533 19.5356 136.778 19.5971 136.998 19.7135C137.218 19.8299 137.406 19.9977 137.547 20.2027C137.747 20.4838 138.004 20.7203 138.3 20.8976C138.848 21.2372 139.513 21.407 140.295 21.407C141.212 21.407 141.956 21.1329 142.527 20.5848C143.098 20.0366 143.383 19.2066 143.383 18.0947V16.1588C142.947 16.7723 142.371 17.2739 141.704 17.6229C140.951 18.0237 140.109 18.2257 139.257 18.2098C138.237 18.2179 137.236 17.9351 136.371 17.3947C135.484 16.8369 134.77 16.0435 134.309 15.1033C133.805 14.1205 133.554 12.9902 133.554 11.7125C133.554 10.4498 133.805 9.32909 134.309 8.35031C134.769 7.4222 135.48 6.64145 136.361 6.09659C137.234 5.56918 138.238 5.29638 139.258 5.30901C140.19 5.31784 141.009 5.50429 141.716 5.86837ZM142.933 9.78996C142.653 9.26365 142.232 8.8263 141.716 8.52759C141.216 8.23802 140.647 8.08648 140.069 8.08847C139.5 8.08399 138.94 8.23173 138.447 8.51638C137.938 8.81526 137.52 9.24758 137.24 9.76652C136.932 10.3154 136.778 10.9654 136.778 11.7166C136.762 12.4023 136.921 13.0806 137.24 13.6881C137.522 14.223 137.945 14.671 138.463 14.9841C138.949 15.2795 139.506 15.4353 140.075 15.4344C140.653 15.4369 141.221 15.2853 141.721 14.9953C142.237 14.6966 142.658 14.2592 142.938 13.7329C143.238 13.1848 143.389 12.5276 143.389 11.7615C143.389 10.9953 143.237 10.3381 142.933 9.78996Z"
        fill="currentColor"
      />
      <path
        d="M159.21 5.95884C159.955 6.38075 160.555 7.01648 160.934 7.78362C161.348 8.58716 161.554 9.55236 161.554 10.6792V16.4276C161.554 16.6347 161.513 16.8398 161.433 17.0312C161.354 17.2225 161.238 17.3964 161.091 17.5429C160.945 17.6893 160.771 17.8055 160.579 17.8848C160.388 17.964 160.183 18.0048 159.976 18.0048C159.557 18.0048 159.156 17.8386 158.86 17.5429C158.564 17.2471 158.398 16.8459 158.398 16.4276V11.1092C158.398 10.1175 158.15 9.35538 157.653 8.82286C157.157 8.29033 156.481 8.02373 155.625 8.02305C154.752 8.02305 154.065 8.28965 153.562 8.82286C153.059 9.35606 152.807 10.1182 152.806 11.1092V16.4276C152.806 16.8459 152.64 17.2471 152.344 17.5429C152.048 17.8386 151.647 18.0048 151.228 18.0048C151.021 18.005 150.816 17.9643 150.624 17.8851C150.433 17.8059 150.259 17.6897 150.112 17.5432C149.965 17.3968 149.849 17.2229 149.77 17.0314C149.69 16.84 149.649 16.6348 149.649 16.4276V2.90532C149.65 2.48693 149.816 2.08577 150.112 1.79001C150.408 1.49426 150.81 1.32812 151.228 1.32813C151.647 1.32813 152.048 1.49429 152.344 1.79008C152.64 2.08586 152.806 2.48703 152.806 2.90532V7.0745C153.23 6.51905 153.789 6.08074 154.429 5.80092C155.139 5.48637 155.908 5.32892 156.684 5.33938C157.566 5.32278 158.436 5.5363 159.21 5.95884Z"
        fill="currentColor"
      />
      <path
        d="M168.228 8.11072V14.1495C168.228 14.5703 168.33 14.8749 168.534 15.0665C168.738 15.258 169.079 15.3487 169.56 15.3487H169.696C170.047 15.3487 170.384 15.4882 170.633 15.7366C170.882 15.985 171.021 16.3219 171.021 16.6732C171.022 16.8476 170.988 17.0204 170.922 17.1817C170.855 17.3429 170.758 17.4896 170.635 17.6131C170.512 17.7366 170.365 17.8346 170.204 17.9015C170.043 17.9684 169.87 18.0028 169.696 18.0028H169.042C166.382 18.0028 165.052 16.7123 165.052 14.1312V8.11989H164.86C164.69 8.12003 164.521 8.0866 164.364 8.02151C164.206 7.95643 164.063 7.86097 163.943 7.7406C163.823 7.62023 163.727 7.47731 163.662 7.32002C163.597 7.16272 163.563 6.99413 163.563 6.8239C163.563 6.48018 163.7 6.15054 163.943 5.9075C164.186 5.66445 164.516 5.52791 164.86 5.52791H165.052V4.03019C165.051 3.82066 165.091 3.61295 165.17 3.41905C165.25 3.22514 165.367 3.04886 165.515 2.90036C165.663 2.75187 165.838 2.63409 166.032 2.55381C166.226 2.47354 166.433 2.43235 166.643 2.43262C167.064 2.43262 167.469 2.59996 167.767 2.89785C168.065 3.19573 168.232 3.59975 168.232 4.02102V5.51874H169.732C170.075 5.51955 170.404 5.65642 170.647 5.89935C170.89 6.14228 171.026 6.47145 171.026 6.81473C171.026 7.15845 170.89 7.48809 170.646 7.73114C170.403 7.97418 170.073 8.11072 169.73 8.11072H168.228Z"
        fill="currentColor"
      />
      <path
        d="M175.329 17.6507C174.569 17.3237 173.906 16.8081 173.402 16.1529C173.397 16.1469 173.392 16.1405 173.388 16.1336C172.752 15.2767 173.419 14.0572 174.488 14.0572H174.956C175.408 14.0572 175.795 14.3272 176.025 14.7164C176.152 14.9244 176.318 15.106 176.513 15.2513C176.926 15.5671 177.441 15.7247 178.058 15.724C178.658 15.724 179.127 15.6041 179.466 15.3644C179.622 15.2659 179.751 15.1295 179.84 14.9679C179.929 14.8063 179.976 14.6248 179.976 14.4402C179.976 14.0327 179.769 13.727 179.356 13.5233C178.943 13.3195 178.285 13.0991 177.381 12.862C176.602 12.684 175.837 12.4504 175.092 12.1631C174.502 11.9228 173.977 11.5444 173.563 11.0597C173.135 10.5638 172.921 9.89511 172.921 9.05353C172.917 8.37557 173.126 7.71341 173.518 7.16049C173.917 6.58993 174.488 6.13926 175.232 5.80847C175.976 5.47768 176.851 5.31568 177.856 5.32247C179.343 5.32247 180.533 5.69402 181.423 6.4371C181.754 6.7121 182.038 7.03859 182.264 7.404C182.388 7.60762 182.454 7.84031 182.458 8.07824C182.462 8.31617 182.402 8.55079 182.285 8.7581C182.168 8.96541 181.998 9.13796 181.793 9.25808C181.587 9.3782 181.354 9.44157 181.116 9.44172H180.876C180.388 9.44172 179.905 9.20636 179.678 8.77437C179.573 8.57393 179.426 8.3982 179.247 8.25883C178.879 7.96539 178.387 7.81868 177.77 7.81868C177.199 7.81868 176.759 7.92056 176.452 8.13452C176.305 8.22848 176.186 8.35892 176.104 8.51305C176.023 8.66718 175.984 8.83973 175.989 9.0138C175.989 9.43425 176.2 9.75349 176.62 9.97153C177.041 10.1896 177.695 10.411 178.582 10.6358C179.341 10.8159 180.086 11.0491 180.812 11.3337C181.403 11.5784 181.925 11.9631 182.334 12.4545C182.762 12.9578 182.984 13.6224 182.999 14.4484C183.008 15.1404 182.798 15.8175 182.401 16.3842C182.003 16.9548 181.432 17.4017 180.687 17.725C179.943 18.0484 179.075 18.21 178.084 18.21C177.136 18.2242 176.196 18.0334 175.329 17.6507Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
