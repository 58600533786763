import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface IProps {
  children?: ReactNode;
  descriptionKey?: string;
  titleKey: string;
}

function PageLayout({ titleKey, descriptionKey, children }: IProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t('cycling-insights.app_title')}&nbsp;|&nbsp;{t(titleKey)}
        </title>
        {descriptionKey && <meta content={t(descriptionKey) || ''} name="description" />}
      </Helmet>
      <Box display="flex" flexDirection="row" flexGrow={1} sx={{ overflow: 'hidden' }}>
        {children}
      </Box>
    </>
  );
}

export default PageLayout;
