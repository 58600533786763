import { Report, reportTypesMap, useClusters, usePopup } from '@geovelo-frontends/commons';
import { useContext, useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';

import { AppContext } from '../../app/context';
import ReportPopup, { TReportPopupRef } from '../../components/map/report-popup';

import { Map, Marker, Popup } from '!maplibre-gl';

const clustersSourceId = 'report-clusters';

function useCartographicReports(
  map: Map | null | undefined,
  {
    cartographicReports: _cartographicReports,
    clusterMaxZoom,
    detailedPopup,
    disableClusters,
    onDragEnd,
    onReportSelected,
    handleClick,
  }: {
    cartographicReports?: Report[];
    clusterMaxZoom?: number;
    detailedPopup?: boolean;
    disableClusters?: boolean;
    onDragEnd?: (point: GeoJSON.Point) => void;
    onReportSelected?: (id: number | null) => void;
    handleClick?: (point: GeoJSON.Point) => void;
  },
): {
  initialized: boolean;
  init: () => void;
  addMarker: (report: Report, options: { draggable?: boolean }) => void;
  selectReport: (report: Report | null) => void;
  clear: () => void;
} {
  const cartographicReports = useRef<Report[]>([]);
  const markers = useRef<{ [key: number]: { id: number; marker: Marker } }>({});
  const selectedReportIdRef = useRef<number | null>(null);
  const [initialized, setInitialized] = useState(false);
  const initializedRef = useRef(false);
  const {
    initialized: clustersInitialized,
    init: initClusters,
    update: _updateClusters,
    updateData: updateClustersData,
    clear: clearClusters,
    destroy: destroyClusters,
  } = useClusters(map, clustersSourceId, clusterMaxZoom);
  const { centerPopup } = usePopup(map);
  const reportPopupRef = useRef<TReportPopupRef | null>(null);
  const {
    report: { types },
  } = useContext(AppContext);

  useEffect(() => {
    if (map) {
      init();
      if (handleClick)
        map.on('click', ({ lngLat: { lat, lng } }) =>
          handleClick({ type: 'Point', coordinates: [lng, lat] }),
        );
    }

    return () => {
      destroy();
    };
  }, [map]);

  useEffect(() => {
    cartographicReports.current = _cartographicReports || [];
    if (_cartographicReports) {
      const updatedReport = _cartographicReports.find(
        ({ id }) => id === selectedReportIdRef.current,
      );
      if (updatedReport) reportPopupRef.current?.update(updatedReport.clone(), true, types);
    }
  }, [_cartographicReports]);

  useEffect(() => {
    function initSourceData() {
      if (!map?.getSource(clustersSourceId) || !map?.isSourceLoaded(clustersSourceId)) return;

      updateClusters();
      map?.off('render', initSourceData);
    }

    if (map && clustersInitialized) {
      map.on('render', initSourceData);
      map.on('moveend', () => updateClusters());
    }
  }, [clustersInitialized]);

  useEffect(() => {
    if (clustersInitialized) update();
  }, [_cartographicReports, clustersInitialized]);

  function init() {
    if (!map || initializedRef.current) return;

    if (!disableClusters) initClusters();

    initializedRef.current = true;
    setInitialized(true);
  }

  function closePrevSelectedPopup() {
    Object.values(markers.current).forEach(({ id, marker }) => {
      if (id !== selectedReportIdRef.current && marker.getPopup().isOpen()) marker.togglePopup();
    });
  }

  function openPopup(report: Report) {
    const {
      id,
      geoPoint: {
        coordinates: [lng, lat],
      },
    } = report;
    const selectedMarker =
      selectedReportIdRef.current && markers.current[selectedReportIdRef.current];
    if (!selectedMarker) {
      selectedReportIdRef.current = null;
      onReportSelected?.(null);
      return;
    }
    if (selectedMarker.marker.getPopup().isOpen()) return;

    selectedMarker.marker.togglePopup();

    const ele = document.getElementById(`report-${id}-popup`);
    if (ele) {
      const container = createRoot(ele);
      container.render(<ReportPopup detailedPopup={detailedPopup} report={report} types={types} />);
    }

    if (map)
      centerPopup({ lat, lng }, selectedMarker.marker.getPopup(), {
        offset: { x: detailedPopup ? 0 : -200 },
      });
  }

  function closePopup() {
    Object.values(markers.current).forEach(({ id, marker }) => {
      if (id === selectedReportIdRef.current && marker.getPopup().isOpen()) marker.togglePopup();
    });
  }

  function addMarker(report: Report, { draggable }: { draggable?: boolean } = {}) {
    const {
      id,
      typeCode,
      geoPoint: {
        coordinates: [lng, lat],
      },
    } = report;
    if (!map || markers.current[id] || typeCode === 'support' || typeCode === 'exclusionZone')
      return;

    const { icon: iconUrl, color } = reportTypesMap[typeCode];
    const el = document.createElement('div');
    el.className = 'marker';
    el.style.border = '2px solid #fff';
    el.style.borderRadius = '50%';
    el.style.backgroundColor = color;
    el.style.backgroundImage = `url(${iconUrl})`;
    el.style.backgroundPosition = 'center';
    el.style.backgroundRepeat = 'no-repeat';
    el.style.backgroundSize = '14px';
    el.style.width = `20px`;
    el.style.height = `20px`;
    el.style.cursor = draggable ? 'move' : 'pointer';

    el.addEventListener('click', (event) => {
      event.stopPropagation();

      if (selectedReportIdRef.current !== id) {
        selectedReportIdRef.current = id;
        onReportSelected?.(report.id);
        closePrevSelectedPopup();
        openPopup(report);
      }
    });

    const marker = new Marker({ element: el, draggable }).setLngLat({ lat, lng }).addTo(map);

    if (!draggable) {
      marker.setPopup(
        new Popup({
          maxWidth: '300px',
          closeButton: false,
          closeOnClick: true,
          className: 'map-custom-popup',
        })
          .setHTML(`<div id="report-${id}-popup"></div>`)
          .on('close', () => {
            if (selectedReportIdRef.current === id && markers.current[id]) {
              selectedReportIdRef.current = null;
              onReportSelected?.(null);
            }
          }),
      );
    } else {
      marker.on('dragend', ({ target }: { target: Marker }) => {
        if (onDragEnd) {
          const { lat, lng } = target.getLngLat();
          onDragEnd({
            type: 'Point',
            coordinates: [lng, lat],
          });
        }
      });
    }

    markers.current[id] = { id, marker };
    if (selectedReportIdRef.current === id && !draggable) {
      openPopup(report);
    }
  }

  function updateClusters() {
    if (!initializedRef.current || !clustersInitialized) return;

    clearMarkers();

    const unclusteredFeatures = _updateClusters();
    unclusteredFeatures.forEach(({ id }) => {
      const report = cartographicReports.current.find((report) => report.id === id);
      if (report) addMarker(report);
    });
  }

  function update() {
    clearMarkers();

    if (!map || !initializedRef.current) return;

    updateClustersData(
      cartographicReports.current.map(({ id, geoPoint: geometry }) => ({
        id,
        type: 'Feature',
        geometry,
        properties: {},
      })),
    );

    function init() {
      if (!map?.isSourceLoaded(clustersSourceId)) return;

      updateClusters();
      map?.off('render', init);
    }

    map.on('render', init);
  }

  function clearMarkers() {
    Object.values(markers.current).forEach(({ id, marker }) => {
      if (selectedReportIdRef.current !== id) {
        marker.remove();
        delete markers.current[id];
      }
    });

    clearClusters();
  }

  function clear() {
    reportPopupRef.current = null;
    selectedReportIdRef.current = null;
    clearMarkers();
  }

  function destroy() {
    clear();

    destroyClusters();

    initializedRef.current = false;
    setInitialized(false);
  }

  function selectReport(report: Report | null) {
    if (!report) {
      if (selectedReportIdRef.current) closePopup();
      return;
    }

    const {
      id,
      geoPoint: {
        coordinates: [lng, lat],
      },
    } = report;

    if (selectedReportIdRef.current && selectedReportIdRef.current !== id) {
      markers.current[selectedReportIdRef.current]?.marker.togglePopup();
    }

    selectedReportIdRef.current = id;
    onReportSelected?.(id);

    map?.flyTo({ center: { lat, lng }, zoom: 17, animate: false });
    update();
  }

  return { initialized, init, addMarker, selectReport, clear };
}

export default useCartographicReports;
