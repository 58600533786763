import { Tab, Typography } from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ReactJson from 'react-json-view';
import styled from 'styled-components';

import doc from '../../../../../assets/m2m-api-doc';
import SimpleTable, { TRow } from '../../../../../components/simple-table';

type TTab = 'json';

const tabs: Array<{
  key: TTab;
  label: string;
}> = [{ key: 'json', label: 'JSON' }];

type TKey = 'status' | 'description' | 'key' | 'type' | 'values' | 'description';

const responseKeys: TKey[] = ['status', 'description'];

function ResponseTab(): JSX.Element {
  const {
    response: {
      data: responseData,
      examples: {
        response: { url: exampleUrl, body: responseExample },
      },
    },
  } = doc;

  const [responseRows] = useState<TRow<number, TKey>[] | undefined>(
    [...responseData].map(({ status, description }, index) => {
      return {
        key: index,
        cells: {
          status: {
            value: status,
            format: (status: string) => {
              return (
                <>
                  {status.startsWith('200') && (
                    <StyledSpan className="tag_response goodRequest">{status}</StyledSpan>
                  )}
                  {status.startsWith('400') && (
                    <StyledSpan className="tag_response badRequest">{status}</StyledSpan>
                  )}
                </>
              );
            },
          },
          description: { value: description },
        },
      };
    }),
  );

  return (
    <>
      <Wrapper>
        <StyledTitle variant="h6">Response</StyledTitle>
        <SimpleTable
          headers={{
            status: { label: 'Status' },
            description: { label: 'Description' },
          }}
          keys={responseKeys}
          rows={responseRows}
          title={'response-table'}
        />

        <StyledSeparator />

        <StyledSectionTitle noWrap className="example" variant="h6">
          Example :
        </StyledSectionTitle>
        <StyledExampleFrame>
          <ExampleContent className="example">
            {tabs.map(({ key, label }) => (
              <StyledTab
                aria-controls={`tabpanel-${key}`}
                id={`tab-${key}`}
                key={key}
                label={label}
                style={{ textTransform: 'initial' }}
                value={key}
              />
            ))}
            <code>
              <span className="bold">POST</span>&nbsp;&nbsp;
              <span>{exampleUrl}</span>
            </code>
            <br />
            <ReactJson
              // FIXME : uncomment this when https://github.com/mac-s-g/react-json-view/pull/348 will be merged
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              displayDataTypes={false}
              displayObjectSize={false}
              name={false}
              src={responseExample}
            />
          </ExampleContent>
        </StyledExampleFrame>
      </Wrapper>
      <Helmet>
        <script src="https://cdn.jsdelivr.net/gh/google/code-prettify@master/loader/run_prettify.js" />
      </Helmet>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
`;

const StyledTitle = styled(Typography)`
  && {
    display: none;
    font-size: 1.5rem;

    @media print {
      display: block;
    }
  }
`;

const StyledSpan = styled.span`
  &&.tag_response {
    padding: 5px 10px;
    border-radius: 5px;
    color: white;

    &.goodRequest {
      background-color: #8bc34a;
    }
    &.badRequest {
      background-color: #ff5722;
    }
  }

  &&.tag_default {
    font-family: 'Courier New', Courier, monospace;
    background-color: rgba(196, 196, 196, 0.3);
    color: DimGray;
    border-radius: 5px;
    padding: 2px 5px;
  }

  .new::before {
    content: 'new';
    border-radius: 8%;
    padding: 1px 3px;
    background-color: #8bc34a;
    color: #fff;
    margin-right: 0.2em;
  }
`;

const StyledSectionTitle = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    border-radius: 10px;
    min-height: 36px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px 20px;
    font-size: 1.2rem;
    background-color: rgba(196, 196, 196, 0.5);
    font-weight: 500;

    &.nested_object {
      min-height: 30px;
      font-size: 1.1rem;
      background-color: rgba(196, 196, 196, 0.3);
    }

    &.example {
      background-color: inherit;
      font-size: 1.1rem;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

const StyledSeparator = styled.hr`
  width: 100%;
  border: solid thin rgba(196, 196, 196, 0.5);
  margin-top: 32px;
`;

const StyledExampleFrame = styled.div`
  border-radius: 10px;
  background-color: #f8f8f8;
  padding: 12px;
`;

const ExampleContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  font-size: 0.8rem;

  .bold {
    font-weight: 500;
  }
`;

const StyledTab = styled(Tab)`
  && {
    min-height: 30px;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 32px;
    border-bottom: solid 1px green;
  }
`;

export default ResponseTab;
