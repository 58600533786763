import { TPublicationStatus } from '@geovelo-frontends/commons';
import { green, red, yellow } from '@mui/material/colors';

export const publicationStatuses: TPublicationStatus[] = [
  'published',
  'unpublished',
  'waitingForApproval',
];

export const publicationStatus: {
  [key in TPublicationStatus]: { titleKey: string; color: string };
} = {
  published: { titleKey: 'commons.publication_status.published', color: green[300] },
  waitingForApproval: {
    titleKey: 'commons.publication_status.waiting_for_approval',
    color: yellow[300],
  },
  unpublished: { titleKey: 'commons.publication_status.unpublished', color: red[300] },
};
