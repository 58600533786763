import {
  Period,
  SectionStats,
  StatsService,
  TPeriod,
  currentMonth,
  prevMonth,
  prevWeek,
  roadTypesLabels,
  useAmplitudeTracker,
  useCancellablePromise,
  useFileSaver,
} from '@geovelo-frontends/commons';
import { Close, InfoOutlined, Place } from '@mui/icons-material';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import FacilitiesForm from '../../../../components/form/facilities';
import PeriodForm, { IPeriodFormValues } from '../../../../components/form/period';
import Paper from '../../../../components/paper';
import Ranking from '../../../../components/ranking';
import TabIntroduction from '../../../../components/tab-introduction';
import useRoadsQuality from '../../../../hooks/map/roads-quality';
import usePeriod from '../../../../hooks/period';
import { TOutletContext } from '../../../../layouts/page/container';
import {
  getFilteredSections as _getFilteredSections,
  splitRoadsMinZoom,
} from '../../../../utils/sections';
import { TCartographicDataPageContext } from '../../context';
import RoadsQualityChart from '../roads-quality/chart';

const minPeriod = new Period(
  'month',
  moment('2021-10').startOf('month'),
  moment('2021-10').endOf('month'),
);

const settingsMaxPeriod = moment().get('date') <= 7 ? prevMonth : undefined;

function RoadsQualityAnalysisForm(
  context: TCartographicDataPageContext & TOutletContext,
): JSX.Element {
  const {
    header: { setTitle, setPrevButtonClick, setActions },
    period,
    roadsQuality,
    loading,
    setLoading,
    defaultPeriods,
  } = context;
  const {
    data,
    bounds,
    currentRange,
    selectedFacilities,
    selectFacilities,
    setData,
    setBounds,
    setCurrentRange,
  } = roadsQuality;
  const [customPeriodTypes] = useState<{
    defaultPeriods: IPeriodFormValues;
    enabledTypes: TPeriod[];
  }>({ defaultPeriods, enabledTypes: ['month'] });
  const {
    map: { current: currentMap, zoom },
    partner: { current: currentPartner, cyclabilityZones, sections },
    actions: { getPartnerCyclabilityZones },
  } = useContext(AppContext);
  const [maxPeriod, setMaxPeriod] = useState(getMaxPeriod);
  const {
    initialized: layersInitialized,
    init: initLayers,
    update: updateLayers,
    destroy: destroyLayers,
  } = useRoadsQuality(currentMap, period);
  const [isSetting, setting] = useState<boolean>(true);
  const { downloadBlob } = useFileSaver();
  const { trackEvent: _trackEvent } = useAmplitudeTracker();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { getTitle: getPeriodTitle } = usePeriod();
  const navigate = useNavigate();
  const previousZoom = useRef<number>();
  const [stats, setStats] = useState<{
    [key: number]: { current?: SectionStats; prev?: SectionStats };
  }>();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      cancelPromises();
      setPrevButtonClick(undefined);
      setTitle(undefined);
      setActions(undefined);
      setData(undefined);
      setBounds(undefined);
      setCurrentRange(undefined);
      setLoading(false);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => setMaxPeriod(getMaxPeriod()), [currentPartner, period.values.current.type]);

  useEffect(() => {
    if (currentPartner && !cyclabilityZones) getPartnerCyclabilityZones(currentPartner);
  }, [currentPartner]);

  useEffect(() => {
    if (isSetting) {
      setPrevButtonClick(() => () => navigate('../roads-quality-new'));
      setTitle(
        <Trans i18nKey="cycling-insights.bicycle_observatory.navigation.roads_quality_analysis" />,
      );
      setActions(undefined);
    } else {
      setPrevButtonClick(undefined);
      setTitle(<Trans i18nKey="commons.result" />);
      setActions(
        <Tooltip title={<Trans i18nKey="commons.actions.go_back" />}>
          <IconButton color="primary" component={Link} size="small" to="./roads-quality-new">
            <Close />
          </IconButton>
        </Tooltip>,
      );
    }
  }, [isSetting]);

  useEffect(() => {
    if (currentMap) initLayers();

    return () => {
      destroyLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    if (layersInitialized && sections && stats) getFilteredSections();
  }, [layersInitialized, sections, stats, selectedFacilities]);

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (!layersInitialized) return;

    if (data && bounds && currentRange) {
      timeoutRef.current = setTimeout(() => {
        updateLayers(data, { currentRange, primaryBounds: bounds });
      }, 300);
    } else {
      updateLayers(
        { type: 'FeatureCollection', features: [] },
        {
          currentRange: [0, 1],
          primaryBounds: { min: 0, max: 1 },
        },
      );
    }
  }, [layersInitialized, data, currentRange]);

  useEffect(() => {
    if (!isSetting) getStats();
  }, [isSetting]);

  useEffect(() => {
    if (sections && stats) setLoading(false);
  }, [sections, stats]);

  useEffect(() => {
    if (zoom === undefined) return;
    if (
      (zoom >= splitRoadsMinZoom &&
        previousZoom.current &&
        previousZoom.current < splitRoadsMinZoom) ||
      (zoom < splitRoadsMinZoom &&
        previousZoom.current &&
        previousZoom.current >= splitRoadsMinZoom)
    )
      getFilteredSections();
    previousZoom.current = zoom;
  }, [zoom]);

  function getMaxPeriod() {
    return moment().get('date') <= 7
      ? period.values.current.type === 'month'
        ? prevMonth
        : period.values.current.type === 'week'
          ? prevWeek
          : undefined
      : undefined;
  }

  function resetFilters() {
    period.setValues({
      current: currentMonth,
      prev: prevMonth,
      timePeriod: 'all_day',
      dayPeriod: 'all',
    });
    period.enableComparison?.(false);
    selectFacilities([
      'cycleway',
      'lane',
      'greenway',
      'opposite',
      'sharebusway',
      'mixedfacilities',
      'other',
      'none',
    ]);
  }

  async function getStats() {
    cancelPromises();
    setData(undefined);
    setBounds(undefined);
    setCurrentRange(undefined);
    setStats(undefined);

    if (!currentPartner) return;

    const {
      values: { current: currentPeriod },
    } = period;

    setLoading(true);

    try {
      const [currentStats, currentFrequencyStats] = await cancellablePromise(
        Promise.all([
          StatsService.getSectionsQuality(currentPartner.id, currentPeriod.toIPeriod()),
          StatsService.getSectionsSpeedsAndFrequencies(
            currentPartner.id,
            currentPeriod.toIPeriod(),
            { timePeriod: 'all_day', dayPeriod: 'all' },
          ),
        ]),
      );

      const _stats: { [key: number]: { current?: SectionStats; prev?: SectionStats } } = {};
      currentStats?.forEach((element) => {
        const frequencyStats = currentFrequencyStats.find(
          ({ sectionId }) => sectionId === element.sectionId,
        );
        const sectionStats: SectionStats = {
          sectionId: element.sectionId,
          frequencyBackward: frequencyStats?.frequencyBackward || 0,
          frequencyOnward: frequencyStats?.frequencyOnward || 0,
          roughnessesBackward: element.roughnessesBackward,
          roughnessesOnward: element.roughnessesOnward,
        };
        _stats[element.sectionId] = { current: sectionStats };
      });

      setStats(_stats);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(
          t('commons.no_data', {
            date: getPeriodTitle(period.values.current),
          }),
          { variant: 'error' },
        );

        setBounds({ min: 0, max: 4 });
        setData(null);
      }
    }
  }

  function getFilteredSections() {
    if (!sections || !stats || zoom === undefined) return;

    const { filteredSections, bounds: _bounds } = _getFilteredSections({
      minFrequency: 1,
      maxBounds: 4,
      defaultMinRange: 0,
      sections,
      period,
      selectedFacilities,
      zoom,
      stats,
      primaryCriterion: 'roughness',
      bounds,
      currentRange,
    });

    setData(filteredSections);
    setBounds(_bounds);
    setCurrentRange([0, 3]);
  }

  function handleDownload() {
    if (!data || !currentRange) return;

    _trackEvent('File Downloaded', {
      pathname: `${window.location.host}${window.location.pathname}`,
      partner_id: currentPartner?.id,
      partner_code: currentPartner?.code,
      file: 'Roads Quality Analysis',
    });

    const {
      values: { current: currentPeriod, prev: prevPeriod },
      comparisonEnabled,
    } = period;
    const [min, max] = currentRange;

    downloadBlob(
      `stats-${t('cycling-insights.facilities.navigation.roads_quality')
        .replace(/ /g, '_')
        .toLowerCase()}-${
        comparisonEnabled
          ? `${prevPeriod.from.format('YYYY-MM-DD')}_${prevPeriod.to.format('YYYY-MM-DD')}-`
          : ''
      }${currentPeriod.from.format('YYYY-MM-DD')}_${currentPeriod.to.format('YYYY-MM-DD')}.geojson`,
      new Blob(
        [
          JSON.stringify(
            {
              ...data,
              features: data.features.filter(
                ({ properties: { frequency } }) =>
                  frequency && frequency >= min && frequency <= max,
              ),
            },
            null,
            2,
          ),
        ],
        { type: 'application/json' },
      ),
    );
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap={4}
        justifyContent="space-between"
        minHeight="100%"
      >
        {isSetting ? (
          <>
            <Box display="flex" flexDirection="column" gap={6}>
              <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.roads_quality_analysis" />
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography fontWeight={600} variant="subtitle1">
                  <Trans i18nKey="commons.period" />
                </Typography>
                <PeriodForm
                  disableComparison
                  disablePadding
                  maxPeriod={settingsMaxPeriod}
                  minPeriod={minPeriod}
                  {...period}
                />
              </Box>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography fontWeight={600} variant="subtitle1">
                  <Trans i18nKey="commons.facilities_types" />
                </Typography>
                <FacilitiesForm
                  selectedFacilities={selectedFacilities}
                  selectFacilities={selectFacilities}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={3}>
              <Box
                alignItems="center"
                bgcolor="#FFF4D8"
                borderRadius={2}
                display="flex"
                gap="12px"
                padding={2}
              >
                <InfoOutlined fontSize="small" sx={{ color: '#3F2813' }} />
                <Typography color="#3F2813" fontSize="0.75rem" variant="body2">
                  <Trans i18nKey="cycling-insights.bicycle_observatory.long_analysis" />
                </Typography>
              </Box>
              <Box display="flex" gap={2} justifyContent="flex-end">
                <StyledButton
                  disableElevation
                  color="primary"
                  onClick={resetFilters}
                  variant="outlined"
                >
                  <Trans i18nKey="commons.actions.reset" />
                </StyledButton>
                <StyledButton
                  disableElevation
                  color="primary"
                  onClick={() => {
                    setting(false);
                  }}
                  variant="contained"
                >
                  <Trans i18nKey="commons.actions.analyze" />
                </StyledButton>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box display="flex" flexDirection="column" gap={3}>
              <Paper
                header={
                  <PeriodForm
                    disableComparison
                    disablePadding
                    disablePeriodTypeChange
                    customPeriodTypes={customPeriodTypes}
                    maxPeriod={maxPeriod}
                    {...period}
                  />
                }
              >
                <RoadsQualityChart {...context} />
              </Paper>

              <Ranking
                disableProgression
                data={data?.features
                  .sort((a, b) =>
                    zoom && zoom >= splitRoadsMinZoom
                      ? (b.properties.sectionFrequency || 0) - (a.properties.sectionFrequency || 0)
                      : (b.properties.frequency || 0) - (a.properties.frequency || 0),
                  )
                  .filter(
                    ({ id }) =>
                      typeof id === 'number' ||
                      (typeof id === 'string' &&
                        (id.indexOf('-') < 0 ||
                          (id.indexOf('-0') >= 0 && id.indexOf('backward') < 0))),
                  )
                  .slice(0, 5)
                  .reduce<{ id?: number; title: string; subtitle?: string; value: number }[]>(
                    (
                      res,
                      {
                        properties: {
                          id: propertiesId,
                          wayName,
                          frequency,
                          sectionFrequency,
                          roadType,
                        },
                      },
                    ) => {
                      if (zoom && zoom >= splitRoadsMinZoom) {
                        res.push({
                          id: propertiesId,
                          title:
                            wayName ||
                            t(roadTypesLabels[roadType || 'unclassified']) +
                              ' - ' +
                              propertiesId.toString(),
                          value: sectionFrequency || 0,
                        });
                        return res;
                      } else {
                        res.push({
                          id: propertiesId,
                          title:
                            wayName ||
                            t(roadTypesLabels[roadType || 'unclassified']) +
                              ' - ' +
                              propertiesId.toString(),
                          value: frequency || 0,
                        });
                        return res;
                      }
                    },
                    [],
                  )}
                formatData={({ value }) => (
                  <Typography fontWeight={600} minWidth={50} textAlign="end" variant="body2">
                    <Trans
                      count={value}
                      i18nKey="commons.stats.passages"
                      values={{ count: value }}
                    />
                  </Typography>
                )}
                onClick={(id) => {
                  const feature = data?.features.find(({ properties }) => properties.id === id);
                  const coordinates = feature?.geometry.coordinates;

                  if (coordinates) {
                    const [lng, lat] = coordinates[Math.round(coordinates.length / 2)];
                    currentMap?.flyTo({
                      center: { lat, lng },
                      zoom: 18,
                      animate: false,
                    });
                  }
                }}
                startIcon={<Place sx={{ color: '#E76685' }} />}
                title="cycling-insights.facilities.roads_quality.ranking.title"
              />
            </Box>
            <Box display="flex" gap={2} justifyContent="flex-end">
              <StyledButton
                disableElevation
                color="primary"
                disabled={loading}
                onClick={() => handleDownload()}
                variant="outlined"
              >
                <Trans i18nKey="commons.actions.download" />
              </StyledButton>
              <StyledButton
                disableElevation
                color="primary"
                disabled={loading}
                onClick={() => {
                  setting(true);
                }}
                variant="contained"
              >
                <Trans i18nKey="commons.actions.restart_analyze" />
              </StyledButton>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

const StyledButton = styled(Button)`
  border-radius: 8px;
  height: 54px;
  && {
    padding: 0 24px;
    font-weight: 700;
  }
`;

export default RoadsQualityAnalysisForm;
