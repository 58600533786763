import { ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  children?: ReactNode;
  className?: string;
  disableScroll?: boolean;
  selectedValue?: string;
  value: string;
}

function TabPanel({
  disableScroll,
  value,
  selectedValue,
  children,
  ...props
}: IProps): JSX.Element {
  return (
    <Wrapper
      aria-labelledby={`tab-${value}`}
      className={disableScroll ? '' : 'scrollable'}
      hidden={selectedValue !== undefined && value !== selectedValue}
      id={`tabpanel-${value}`}
      role="tabpanel"
      {...props}
    >
      {(selectedValue === undefined || value === selectedValue) && (
        <StyledContent className={disableScroll ? '' : 'scrollable'}>{children}</StyledContent>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex-grow: 1;
  overflow-y: hidden;

  &.scrollable {
    overflow-y: auto;
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  &.scrollable {
    min-height: calc(100% - 32px);
  }

  &:not(.scrollable) {
    height: calc(100% - 32px);
  }
`;

export default TabPanel;
