import { useEffect, useState } from 'react';

// import VersionDialogV1 from './v1';

const currentVersion = 2;
const storageKey = `has_seen_version_dialog_v${currentVersion}`;

function VersionDialog(): JSX.Element {
  const [, openDialog] = useState(false);

  useEffect(() => {
    let value: string | null = null;
    try {
      value = localStorage.getItem(storageKey);
    } catch {
      console.error('localStorage access is denied');
    }

    openDialog(value !== 'read');

    try {
      localStorage.setItem(storageKey, 'read');
    } catch {
      console.error('localStorage access is denied');
    }
  }, []);

  // return <VersionDialogV1 onClose={() => openDialog(false)} open={dialogOpened} />;
  return <></>;
}

export default VersionDialog;
