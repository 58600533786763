import { Box, List } from '@mui/material';

import Card from '../../../../../components/card';
import { TOutletContext } from '../../../../../layouts/page/container';
import { TQAPageContext } from '../../../context';

import BikeRouteListItem from './item';

function BikeRouteList({ bikeRoutes: { list } }: TQAPageContext & TOutletContext): JSX.Element {
  const loadingItems = [1, 2, 3];

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        gap={1}
        padding={2}
        sx={{ backgroundColor: 'whitesmoke', overflowY: 'auto' }}
      >
        <Card>
          <List dense>
            {list
              ? list.map((bikeRoute) => (
                  <BikeRouteListItem bikeRoute={bikeRoute} key={bikeRoute.id} />
                ))
              : loadingItems.map((key) => <BikeRouteListItem key={key} />)}
          </List>
        </Card>
      </Box>
    </>
  );
}

export default BikeRouteList;
