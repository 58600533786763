import { SvgIcon, SvgIconProps } from '@mui/material';

export function ArchesIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 25" {...props}>
      <path
        clipRule="evenodd"
        d="M9.00026 9.67055C9.00001 9.6914 8.99988 9.71227 8.99988 9.73318V14.2862C8.84179 14.4631 8.74568 14.6965 8.74568 14.9524C8.74568 15.2083 8.84179 15.4417 8.99988 15.6186V15.9524H9.74568H10.2541H10.9999V15.6186C11.158 15.4417 11.2541 15.2083 11.2541 14.9524C11.2541 14.6965 11.158 14.4631 10.9999 14.2862V10.3598C11.6874 10.9215 12.1264 11.7761 12.1264 12.7332V18.0037C11.9683 18.1806 11.8722 18.414 11.8722 18.6699C11.8722 19.2222 12.3199 19.6699 12.8722 19.6699H13.3806C13.9329 19.6699 14.3806 19.2222 14.3806 18.6699C14.3806 18.414 14.2845 18.1806 14.1264 18.0037V12.7332C14.1264 10.7759 13.0158 9.07803 11.3904 8.23531C11.9151 7.30111 12.9154 6.66992 14.0631 6.66992C15.7549 6.66992 17.1264 8.04139 17.1264 9.73318V14.2862C16.9683 14.4631 16.8722 14.6965 16.8722 14.9524C16.8722 15.2083 16.9683 15.4417 17.1264 15.6186V15.9524H17.8722H18.3806H19.1264V15.6186C19.2845 15.4417 19.3806 15.2083 19.3806 14.9524C19.3806 14.6965 19.2845 14.4631 19.1264 14.2862V9.73318C19.1264 6.93682 16.8595 4.66992 14.0631 4.66992C11.9965 4.66992 10.219 5.90813 9.43207 7.68316C9.31023 7.67439 9.18721 7.66992 9.06314 7.66992C6.26678 7.66992 3.99988 9.93682 3.99988 12.7332V18.0053C3.84262 18.182 3.74707 18.4148 3.74707 18.6699C3.74707 19.2222 4.19479 19.6699 4.74707 19.6699H5.25548C5.80776 19.6699 6.25548 19.2222 6.25548 18.6699C6.25548 18.4133 6.1588 18.1792 5.99988 18.0022V12.7332C5.99988 11.0624 7.3375 9.70403 9.00026 9.67055Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
