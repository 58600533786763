import { News } from '@geovelo-frontends/commons';
import { Add, ArrowBackIosNew } from '@mui/icons-material';
import { Box, ButtonBase, Typography } from '@mui/material';
import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';

function NewsFeed(): JSX.Element {
  const {
    partner: { current: currentPartner, currentGeogroup },
    user: { current: currentUser },
  } = useContext(AppContext);

  return (
    <Box
      bgcolor="#F6F7FB"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      gap={5}
      paddingX={14}
      paddingY={8}
      sx={{ overflowY: 'auto' }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box alignItems="center" display="flex" gap={2}>
          <ButtonBase component={Link} to="../animation">
            <ArrowBackIosNew color="primary" fontSize="large" />
          </ButtonBase>
          <Typography
            color="primary"
            component="h2"
            flexDirection="column"
            fontSize="2rem"
            fontWeight="700"
          >
            <Trans i18nKey="cycling-insights.community.news.title" />
          </Typography>
        </Box>
        <Button
          disableElevation
          component={Link}
          size="large"
          startIcon={<Add />}
          to="../new-post"
          variant="contained"
        >
          <Trans i18nKey="commons.news.actions.create_long" />
        </Button>
      </Box>
      <News
        loadAllNews
        currentPartner={currentPartner}
        currentUser={currentUser}
        geogroup={currentGeogroup}
        newPostRedirection="../new-post"
        userType={'admin'}
      />
    </Box>
  );
}

export default NewsFeed;
