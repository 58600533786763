import { useOutletContext } from 'react-router-dom';

import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { TCartographicDataPageContext } from '../../context';

import RoadsUseNewForm from './form';

function RoadsQualityAnalysisTab(): JSX.Element {
  const context = useOutletContext<TCartographicDataPageContext & TOutletContext>();

  return <PageContentLayout leftPanel={<RoadsUseNewForm {...context} />} />;
}

export default RoadsQualityAnalysisTab;
