import { TReportTypeCodeWithSupport, TStatus } from '@geovelo-frontends/commons';
import { Box, Typography } from '@mui/material';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

function ReportTooltip({
  object: {
    properties: { date, reportData },
  },
}: {
  object: {
    properties: {
      date: Moment;
      reportData?: { id: number; typeCode: TReportTypeCodeWithSupport; status: TStatus };
    };
  };
}): JSX.Element {
  const { t } = useTranslation();

  if (!reportData) return <></>;

  const { typeCode, status } = reportData;

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography color="textSecondary" variant="caption">
          {date?.format('LL')}
        </Typography>
      </Box>
      <Box>
        <Typography fontWeight={600} variant="body2">
          {t(`commons.report_types.${typeCode}`)}
        </Typography>
      </Box>
      <Box>
        <Typography fontWeight={600} variant="body2">
          <b>{t('commons.status')} :</b>{' '}
          {t(`commons.statuses.${status === 'OPEN' ? 'open' : 'closed'}`)}
        </Typography>
      </Box>
    </Box>
  );
}

export default ReportTooltip;
