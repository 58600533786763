import { ExpandLess, ExpandMore, InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { ReactNode, useState } from 'react';

export function Toggle({
  disabled,
  showed,
  label,
  info,
  children,
  toggle,
}: {
  children?: ReactNode;
  disabled?: boolean;
  info?: ReactNode;
  label: ReactNode;
  showed: boolean;
  toggle: (showed: boolean) => void;
}): JSX.Element {
  const [expanded, expand] = useState(false);

  return (
    <Box bgcolor="white" borderRadius={2} paddingX={2} paddingY={1}>
      <Box alignItems="center" display="flex" gap={2}>
        {children && (
          <IconButton onClick={() => expand(!expanded)} size="small" sx={{ marginY: -1 }}>
            {expanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
          </IconButton>
        )}
        <FormControlLabel
          aria-label="Enable"
          control={<Switch checked={showed} color="primary" size="small" />}
          disabled={disabled}
          label={
            <Box alignItems="center" display="flex" gap={1} marginRight={1}>
              <Typography>{label}</Typography>
              {info && (
                <Tooltip title={info}>
                  <InfoOutlined color="action" fontSize="small" />
                </Tooltip>
              )}
            </Box>
          }
          labelPlacement="start"
          onChange={(_, showed) => toggle(showed)}
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          sx={{ margin: 0 }}
          value={showed}
        />
      </Box>
      {children && (
        <Collapse in={expanded}>
          <Box display="flex" flexDirection="column" gap={1} marginTop={1}>
            <Divider />
            {children}
          </Box>
        </Collapse>
      )}
    </Box>
  );
}
