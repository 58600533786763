import {
  Counter,
  CounterService,
  Period,
  VirtualCounterService,
  useCancellablePromise,
  useFileSaver,
} from '@geovelo-frontends/commons';
import { Add, FileDownloadOutlined } from '@mui/icons-material';
import { Box, IconButton, Tooltip, useTheme } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import ConfirmDialog from '../../../../components/confirm-dialog';
import { ICurrentStat, IPrevStat } from '../../../../components/dual-stats';
import PeriodForm from '../../../../components/form/period';
import TabIntroduction from '../../../../components/tab-introduction';
import useCounters from '../../../../hooks/map/counters';
import useRoadsUses, { colors as _colors } from '../../../../hooks/map/roads-uses';
import usePeriod from '../../../../hooks/period';
import useSectionsStats from '../../../../hooks/sections-stats';
import { TOutletContext } from '../../../../layouts/page/container';
import { getProgression } from '../../../../utils/stats';
import { IBicycleObservatoryPageContext, TCounterStats } from '../../context';

import CountersTable from './table';

function CountersListForm(context: IBicycleObservatoryPageContext & TOutletContext): JSX.Element {
  const {
    setLoading,
    period,
    counters: {
      list: counters,
      stats,
      selectedKey,
      setEditingCounter,
      setList,
      selectKey,
      setStats,
    },
    header: { setPrevButtonClick, setActions },
    roadsUse,
  } = context;
  const { bounds, currentRange, data, setQuartiles } = roadsUse;
  const {
    map: { baseLayer, current: currentMap },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const [initialized, setInitialized] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [virtualCounterToRemove, setVirtualCounterToRemove] = useState<number | null>(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const { downloadCSV } = useFileSaver();
  const {
    initialized: layersInitialized,
    init: initLayers,
    update: updateLayers,
    clear: clearLayers,
    destroy: destroyLayers,
    selectCounter,
  } = useCounters(currentMap, counters, theme, { onClick: handleMarkerClick });
  const {
    initialized: roadsUsesLayersInitialized,
    init: initRoadsUsesLayers,
    update: updateRoadsUsesLayers,
    destroy: destroyRoadsUsesLayers,
  } = useRoadsUses(currentMap, period);
  const { timeoutRef } = useSectionsStats({
    hasChartComparison: true,
    primaryCriterion: 'frequency',
    initialized: roadsUsesLayersInitialized,
    period,
    ...roadsUse,
    setLoading,
  });
  const { getPrevPeriod } = usePeriod();

  useEffect(() => {
    setPrevButtonClick(() => () => navigate('../roads-use'));
    setInitialized(true);

    return () => {
      setPrevButtonClick(undefined);
    };
  }, []);

  useEffect(() => {
    if (initialized) getCounters();

    return () => {
      cancelPromises();
      clearLayers();
      setList(undefined);
      selectKey(null);
      setStats(undefined);
    };
  }, [initialized]);

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (!layersInitialized) return;

    const { comparisonEnabled } = period;

    if (data && bounds && currentRange) {
      timeoutRef.current = setTimeout(() => {
        let _quartiles: number[] | undefined;

        if (!comparisonEnabled && data.features.length > 0) {
          const { min, max } = bounds;
          const speeds = data.features
            .map(({ properties: { frequency } }) => frequency)
            .filter((frequency) => frequency && frequency >= min && frequency <= max)
            .sort((a, b) => (a && b ? a - b : 1));

          _quartiles = [
            ...new Set([
              min,
              ..._colors
                .slice(0, Math.min(_colors.length - 1, speeds.length - 1))
                .map((_, index) =>
                  Math.round(
                    speeds[Math.round(speeds.length * ((index + 1) / _colors.length))] || 0,
                  ),
                ),
              max,
            ]),
          ];

          setQuartiles?.(_quartiles);
        }

        const colors = baseLayer === 'dark' ? _colors : [..._colors].reverse();
        updateRoadsUsesLayers(data, {
          colors: _quartiles
            ? colors.slice(0, _quartiles.length - 1).map(({ value }, index) => ({
                value,
                min: _quartiles?.[index],
                max: _quartiles?.[index + 1],
              }))
            : colors,
          comparisonEnabled,
          currentRange,
          primaryBounds: bounds,
          secondaryBounds: bounds,
        });
      }, 300);
    } else {
      updateRoadsUsesLayers(
        { type: 'FeatureCollection', features: [] },
        {
          comparisonEnabled: period.comparisonEnabled,
          currentRange: [0, 1],
          primaryBounds: { min: 0, max: 1 },
          secondaryBounds: { min: 0, max: 1 },
        },
      );
    }
  }, [baseLayer, roadsUsesLayersInitialized, data, currentRange]);

  useEffect(() => {
    setActions(
      <>
        {currentPartner && (
          <Tooltip title={<Trans i18nKey="cycling-insights.usage.point_attendance.form.title" />}>
            <IconButton color="primary" component={Link} size="small" to="./counter-form">
              <Add />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={<Trans i18nKey="cycling-insights.usage.point_attendance.download" />}>
          <span>
            <IconButton
              color="primary"
              disabled={!counters || !stats}
              onClick={() => handleDownload()}
              size="small"
            >
              <FileDownloadOutlined />
            </IconButton>
          </span>
        </Tooltip>
      </>,
    );

    return () => {
      setActions(undefined);
    };
  }, [counters, stats]);

  useEffect(() => {
    if (currentMap) {
      initLayers();
      initRoadsUsesLayers();
    }

    return () => {
      destroyLayers();
      destroyRoadsUsesLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    if (layersInitialized && counters) updateLayers(selectedKey, { draggable: false });
  }, [layersInitialized, counters, selectedKey]);

  useEffect(() => {
    if (counters && selectedKey !== null) {
      const [type, _id] = selectedKey.split('_');
      const isVirtual = type === 'virtual';
      const id = parseInt(_id);

      selectCounter(
        counters.find((counter) => counter.id === id && counter.isVirtual === isVirtual),
      );
    }
  }, [selectedKey]);

  useEffect(() => {
    getStats();
  }, [counters, period.values, period.comparisonEnabled]);

  async function getCounters() {
    try {
      const virtualCounters = await VirtualCounterService.getVirtualCounters();

      virtualCounters.sort((a, b) => b.id - a.id);

      const _counters: Counter[] = [];

      if (
        currentPartner?.dashboardTabsPermissions.usagePointAttendance === 'extrapolated' &&
        currentPartner?.hasRealCounters
      ) {
        const realCounters = await CounterService.getCounters();

        realCounters.sort((a, b) => a.id - b.id);

        _counters.push(...realCounters);
      }

      setList([..._counters, ...virtualCounters]);
    } catch {
      enqueueSnackbar(t('cycling-insights.usage.point_attendance.server_error'), {
        variant: 'error',
      });
      setList([]);
    }
  }

  async function getStats() {
    cancelPromises();
    setStats(undefined);

    if (!counters || counters.length === 0) return;

    const {
      values: { current: currentPeriod },
    } = period;

    const prevPeriod = getPrevPeriod(period.values);
    const lastYear = new Period(
      'month',
      period.values.current.from.clone().add(-1, 'year').startOf('month'),
      period.values.current.from.clone().add(-1, 'year').endOf('month'),
    );

    const isExtrapolated =
      currentPartner?.dashboardTabsPermissions.usagePointAttendance === 'extrapolated';
    const promises: Array<Promise<Array<{ counterId: number; count: number }>>> = isExtrapolated
      ? [
          VirtualCounterService.getVirtualCountersExtrapolatedStats(currentPeriod.toIPeriod()),
          VirtualCounterService.getVirtualCountersExtrapolatedStats(prevPeriod.toIPeriod()),
          VirtualCounterService.getVirtualCountersExtrapolatedStats(lastYear.toIPeriod()),
        ]
      : [
          VirtualCounterService.getVirtualCountersStats(currentPeriod.toIPeriod()),
          VirtualCounterService.getVirtualCountersStats(prevPeriod.toIPeriod()),
          VirtualCounterService.getVirtualCountersStats(lastYear.toIPeriod()),
        ];

    if (
      currentPartner?.dashboardTabsPermissions.usagePointAttendance === 'extrapolated' &&
      currentPartner?.hasRealCounters
    ) {
      promises.push(
        CounterService.getCountersStats(currentPeriod.toIPeriod()),
        CounterService.getCountersStats(prevPeriod.toIPeriod()),
        CounterService.getCountersStats(lastYear.toIPeriod()),
      );
    }

    try {
      const [
        currentPeriodVirtualCountersStats,
        prevPeriodVirtualCountersStats,
        lastYearVirtualCountersStats,
        currentPeriodCountersStats,
        prevPeriodCountersStats,
        lastYearCountersStats,
      ] = await cancellablePromise(Promise.all(promises));

      const currentPeriodCounts = (currentPeriodCountersStats || []).reduce<{
        [key: number]: number;
      }>((res, { counterId, count }) => {
        res[counterId] = count;

        return res;
      }, {});
      const prevPeriodCounts = (prevPeriodCountersStats || []).reduce<{ [key: number]: number }>(
        (res, { counterId, count }) => {
          res[counterId] = count;

          return res;
        },
        {},
      );
      const lastYearCounts = (lastYearCountersStats || []).reduce<{ [key: number]: number }>(
        (res, { counterId, count }) => {
          res[counterId] = count;

          return res;
        },
        {},
      );

      const currentPeriodVirtualCountersCounts = currentPeriodVirtualCountersStats.reduce<{
        [key: number]: number;
      }>((res, { counterId, count }) => {
        res[counterId] = count;

        return res;
      }, {});
      const prevPeriodVirtualCountersCounts = prevPeriodVirtualCountersStats.reduce<{
        [key: number]: number;
      }>((res, { counterId, count }) => {
        res[counterId] = count;

        return res;
      }, {});
      const lastYearVirtualCountersCounts = lastYearVirtualCountersStats.reduce<{
        [key: number]: number;
      }>((res, { counterId, count }) => {
        res[counterId] = count;

        return res;
      }, {});

      const stats: TCounterStats[] = [];
      counters.map(({ id, isVirtual }) => {
        const currentCount =
          (isVirtual ? currentPeriodVirtualCountersCounts[id] : currentPeriodCounts[id]) || 0;
        const prevCount =
          (isVirtual ? prevPeriodVirtualCountersCounts[id] : prevPeriodCounts[id]) || 0;
        const progression = getProgression(currentCount, prevCount);
        const lastYearCount =
          (isVirtual ? lastYearVirtualCountersCounts[id] : lastYearCounts[id]) || 0;
        const lastYearProgression = getProgression(currentCount, lastYearCount);

        const currentPeriodData: ICurrentStat = {
          count: currentCount,
          values: [],
        };

        const prevPeriodData: IPrevStat = {
          count: prevCount,
          values: [],
        };

        const lastYearData: IPrevStat = {
          count: lastYearCount,
          values: [],
        };

        stats.push({
          id,
          isVirtual,
          current: currentPeriodData,
          prev: prevPeriodData,
          progression,
          lastYear: lastYearData,
          yearProgression: lastYearProgression,
        });
      });

      setStats(stats);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.point_attendance.server_error_stats'), {
          variant: 'error',
        });
      }
    }
  }

  function handleDownload() {
    if (!counters || !stats) return;

    downloadCSV(
      `${t('cycling-insights.bicycle_observatory.navigation.point_attendance')
        .replace(/ /g, '_')
        .toLowerCase()}-${period.values.current.from.format(
        'YYYY-MM-DD',
      )}_${period.values.current.to.format('YYYY-MM-DD')}.csv`,
      [
        t('commons.stats.type_label'),
        t('commons.name'),
        t('cycling-insights.usage.point_attendance.table.traffic'),
        t('cycling-insights.usage.point_attendance.table.progression'),
        `${t('cycling-insights.usage.point_attendance.table.progression')} vs ${moment()
          .add(-1, 'year')
          .format('YYYY')}`,
      ],
      counters.map(({ title, isVirtual }, index) => {
        const traffic = stats[index]?.current.count;
        const progression = stats[index]?.progression;
        const yearProgression = stats[index]?.yearProgression;

        return [
          t(
            `cycling-insights.usage.point_attendance.form.types.${isVirtual ? 'virtual' : 'real'}`,
          ) || '',
          title,
          traffic !== undefined ? traffic : '-',
          progression != undefined
            ? `${progression < 0 ? '- ' : '+ '}${Math.abs(progression)} %`
            : '-',
          yearProgression != undefined
            ? `${yearProgression < 0 ? '- ' : '+ '}${Math.abs(yearProgression)} %`
            : '-',
        ];
      }),
    );
  }

  function handleMarkerClick(key?: string) {
    selectKey(key || null);
  }

  async function handleRemove() {
    if (virtualCounterToRemove === null) return;

    setRemoving(true);

    try {
      await VirtualCounterService.removeVirtualCounter(virtualCounterToRemove);

      if (counters) {
        const index = counters.findIndex(
          ({ id, isVirtual }) => isVirtual && id === virtualCounterToRemove,
        );
        if (index >= 0) {
          const countersUpdated = [...counters];
          countersUpdated.splice(index, 1);

          setList(countersUpdated);
          selectKey(null);
        }
      }

      setVirtualCounterToRemove(null);
    } catch {
      enqueueSnackbar(t('cycling-insights.usage.point_attendance.remove_dialog.server_error'));
    }

    setRemoving(false);
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={5} minHeight="100%">
        <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.point_attendance" />
        <Box display="flex" flexDirection="column" gap={3}>
          <PeriodForm disableComparison disablePadding {...period} />
          <Box flexGrow={1} marginX={-3} sx={{ overflowY: 'auto' }}>
            <CountersTable
              {...context}
              onEdit={(counter) => {
                setEditingCounter(counter);
                currentMap?.flyTo({
                  center: {
                    lat: counter.place.point.coordinates[1],
                    lng: counter.place.point.coordinates[0],
                  },
                  zoom: 16,
                  animate: false,
                });
                navigate('../counter-form');
              }}
              onRemove={(id) => setVirtualCounterToRemove(id)}
              updateCounters={getCounters}
            />
          </Box>
        </Box>
      </Box>
      <ConfirmDialog
        dialogTitle="point-attendance-remove-dialog"
        loading={removing}
        onCancel={() => setVirtualCounterToRemove(null)}
        onConfirm={handleRemove}
        open={Boolean(virtualCounterToRemove)}
        title={<Trans i18nKey="cycling-insights.usage.point_attendance.remove_dialog.title" />}
      />
    </>
  );
}

export default CountersListForm;
