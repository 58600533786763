import { SvgIcon, SvgIconProps } from '@mui/material';

function PlottyBikeIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 57 52" {...props}>
      <path
        d="M34.2117 10.9205C32.9182 5.29826 28.1159 1.60839 22.6179 2.87614C17.12 4.14389 13.0068 9.89143 14.2999 15.5119C14.2999 15.5119 16.4628 27.0072 27.9446 34.3422C28.8715 34.8337 30.3314 34.4505 30.801 33.6835C37.9212 22.061 34.2135 10.9201 34.2135 10.9201L34.2117 10.9205ZM25.7267 19.6092C22.2723 20.4057 18.8142 18.1878 18.0022 14.6582C17.1901 11.1286 19.3323 7.61933 22.785 6.82319C26.2376 6.02705 29.6974 8.24457 30.5095 11.7742C31.3215 15.3038 29.1794 18.8131 25.7267 19.6092Z"
        fill="#005A44"
      />
      <path
        d="M35.3354 10.5777C34.043 4.96035 28.5464 1.43475 23.0591 2.70006C17.5717 3.96537 14.1699 9.54468 15.4619 15.1602C15.4619 15.1602 17.6234 26.6453 29.0853 33.9762C29.8415 34.4599 30.8397 34.2298 31.3083 33.4636C38.4124 21.8535 35.3371 10.5773 35.3371 10.5773L35.3354 10.5777ZM26.8682 19.2564C23.4205 20.0514 19.9684 17.8347 19.157 14.3082C18.3457 10.7816 20.4831 7.27614 23.9291 6.48153C27.3751 5.68692 30.8289 7.90318 31.6403 11.4297C32.4516 14.9563 30.3143 18.4618 26.8682 19.2564Z"
        fill="#2AC682"
      />
      <path
        d="M26.9998 19.7709C30.6805 18.9223 32.9618 15.1814 32.0951 11.4152C31.2285 7.64904 27.5421 5.28388 23.8614 6.13246C20.1806 6.98104 17.8993 10.722 18.766 14.4882C19.6326 18.2543 23.319 20.6195 26.9998 19.7709Z"
        fill="white"
      />
      <path
        d="M21.4836 12.6332C21.4584 11.3741 22.7794 10.6969 23.5536 11.4908"
        stroke="#005A44"
        strokeLinecap="round"
        strokeWidth="0.636558"
      />
      <path
        d="M26.6215 10.698C26.8192 9.45629 28.2391 9.03747 28.861 9.96186"
        stroke="#005A44"
        strokeLinecap="round"
        strokeWidth="0.636558"
      />
      <path
        d="M28.3573 12.4647C27.6336 13.8278 24.6063 14.7531 23.2665 14.003"
        stroke="#005A44"
        strokeLinecap="round"
        strokeWidth="0.636558"
      />
      <path
        clipRule="evenodd"
        d="M43.2178 22.1561C43.4487 21.2241 43.4483 21.224 43.4479 21.2239L43.4468 21.2237L43.4441 21.223L43.4361 21.221L43.4104 21.2149C43.3889 21.2099 43.3591 21.203 43.3215 21.1948C43.2464 21.1783 43.1403 21.156 43.0092 21.1315C42.7481 21.0827 42.3832 21.024 41.9648 20.9841C41.1627 20.9076 40.025 20.8849 39.0503 21.2417C38.5469 21.4259 38.0362 21.7298 37.6562 22.2275C37.2677 22.7365 37.0781 23.3668 37.0897 24.0845C37.109 25.2735 37.6796 26.7579 38.8756 28.6305C34.8809 30.6051 32.1337 34.7211 32.1337 39.4788C32.1337 46.1581 37.5484 51.5728 44.2278 51.5728C50.9072 51.5728 56.3219 46.1581 56.3219 39.4788C56.3219 32.7994 50.9072 27.3847 44.2278 27.3847C43.0285 27.3847 41.8701 27.5592 40.7764 27.8843C39.4869 25.9402 39.1006 24.7384 39.0895 24.0521C39.0841 23.7184 39.1676 23.5437 39.2459 23.4411C39.3327 23.3274 39.4827 23.2131 39.7377 23.1198C40.2796 22.9215 41.049 22.9058 41.7749 22.9751C42.1205 23.0081 42.4248 23.0569 42.6418 23.0975C42.7499 23.1177 42.8351 23.1356 42.8916 23.148C42.9198 23.1542 42.9408 23.1591 42.9538 23.1621L42.9671 23.1653L42.9678 23.1654L42.9684 23.1656L42.9688 23.1657L42.969 23.1657C43.5047 23.2978 44.0462 22.9709 44.1789 22.4352C44.3117 21.8991 43.9848 21.3569 43.4487 21.2241L43.2178 22.1561ZM12.679 29.3847C7.10423 29.3847 2.58496 33.904 2.58496 39.4788C2.58496 45.0536 7.10423 49.5728 12.679 49.5728C18.2538 49.5728 22.7731 45.0536 22.7731 39.4788C22.7731 33.904 18.2538 29.3847 12.679 29.3847ZM0.584961 39.4788C0.584961 32.7994 5.99966 27.3847 12.679 27.3847C19.3584 27.3847 24.7731 32.7994 24.7731 39.4788C24.7731 46.1581 19.3584 51.5728 12.679 51.5728C5.99966 51.5728 0.584961 46.1581 0.584961 39.4788ZM44.2278 29.3847C38.653 29.3847 34.1337 33.904 34.1337 39.4788C34.1337 45.0536 38.653 49.5728 44.2278 49.5728C49.8026 49.5728 54.3219 45.0536 54.3219 39.4788C54.3219 33.904 49.8026 29.3847 44.2278 29.3847Z"
        fill="#005A44"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}

export default PlottyBikeIcon;
