import { Report, ReportType, useFileSaver } from '@geovelo-frontends/commons';
import { useTranslation } from 'react-i18next';

import { IPeriodFormProps } from '../components/form/period';
import { environment } from '../environment';

function useReports({
  period,
  reportTypes,
  reports,
}: {
  period: IPeriodFormProps;
  reports?: Report[];
  reportTypes?: ReportType[];
}) {
  const { downloadCSV } = useFileSaver();
  const { t } = useTranslation();

  function handleDownload() {
    if (!reports) return;

    downloadCSV(
      `${t('cycling-insights.reports.osm_cartographic_reports.table.title')
        .replace(/ /g, '_')
        .toLowerCase()}-${period.values.current.from.format(
        'YYYY-MM-DD',
      )}_${period.values.current.to.format('YYYY-MM-DD')}.csv`,
      [
        'X',
        'Y',
        t('commons.administrative_levels.city_one'),
        'INSEE',
        t('commons.stats.date_label'),
        t('commons.stats.creator_label'),
        t('commons.stats.update_label'),
        t('commons.stats.type_label'),
        t('commons.stats.source_label'),
        t('commons.description'),
        t('commons.status'),
        t('commons.stats.links', { count: 1 }),
      ],
      reports.map(
        ({
          id,
          geoPoint,
          created,
          creator,
          updated,
          typeCode,
          source,
          description,
          status,
          city,
        }) => [
          geoPoint.coordinates[0],
          geoPoint.coordinates[1],
          city?.name || '',
          city?.reference || '',
          created.toISOString(),
          creator,
          updated.toISOString(),
          t(reportTypes?.find((type) => type.code === typeCode)?.titleKey || '').toString(),
          t(source?.title || '').toString(),
          `"${(description || '').replace(/"/g, "'")}"`,
          status,
          `${environment.bicycleFacilitiesUrl}fr/contributions/${id}`,
        ],
      ),
    );
  }

  return { handleDownload };
}

export default useReports;
