import { InfoOutlined } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { Trans } from 'react-i18next';

function TabIntroduction({
  title: titleKey,
  components,
  tooltipLabel,
}: {
  components?: ReactElement[];
  title: string;
  tooltipLabel?: string;
}): JSX.Element {
  return (
    <Box>
      <Typography color="textSecondary" variant="body2">
        <Trans components={components} i18nKey={titleKey} />
        {tooltipLabel && (
          <Tooltip
            placement="bottom"
            sx={{ marginLeft: '4px' }}
            title={<Trans components={[<br key={0} />]} i18nKey={tooltipLabel} />}
          >
            <InfoOutlined style={{ fontSize: '0.875rem', height: 'auto' }} />
          </Tooltip>
        )}
      </Typography>
    </Box>
  );
}

export default TabIntroduction;
