import { Deck, Layer } from '@deck.gl/core';
import FlowMapLayer, { FlowLayerPickingInfo, PickingType } from '@flowmap.gl/core';
import { CyclabilityZone, Flow, useUnits } from '@geovelo-frontends/commons';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TSliderRange } from '../../components/form/slider';

import { Map, Popup } from '!maplibre-gl';

export type TOriginDestinationFilters = {
  count: [number, number];
};

type TLocation = GeoJSON.Feature<
  GeoJSON.Point,
  {
    id: string;
    name: string;
    flows: { from: { count: number }; to: { count: number }; intern: { count: number } };
  }
>;

type TFlow = { index: number; origin: string; destination: string; count: number };

function useOriginDestinationFlows(
  map: Map | undefined | null,
  {
    disableController,
    setCyclabilityZoneId,
  }: {
    disableController?: boolean;
    setCyclabilityZoneId?: (cyclabilityZoneId: number) => void;
  },
): {
  initialized: boolean;
  init: (canvas: HTMLCanvasElement) => void;
  update: (
    cyclabilityZones: CyclabilityZone[] | undefined,
    externalCyclabilityZones: CyclabilityZone[] | undefined,
    flows: Flow[] | undefined,
    currentRange: TSliderRange | undefined,
    cyclabilityZoneId: number | null,
  ) => void;
  clear: () => void;
  destroy: () => void;
} {
  const [initialized, setInitialized] = useState(false);
  const initializedRef = useRef(false);
  const highlightedFlowTooltip = useRef<Popup>();
  const deckRef = useRef<Deck>();
  const { t } = useTranslation();
  const { formatNumber } = useUnits();

  useEffect(() => {
    function handleMoveEnd() {
      if (!map) return;

      const { lat: latitude, lng: longitude } = map.getCenter();

      deckRef.current?.setProps({
        viewState: {
          longitude,
          latitude,
          zoom: map.getZoom(),
          bearing: map.getBearing(),
          pitch: map.getPitch(),
        },
      });
    }

    if (initialized) map?.on('move', handleMoveEnd);

    return () => {
      map?.off('moveend', handleMoveEnd);
    };
  }, [initialized]);

  function init(canvas: HTMLCanvasElement) {
    if (!map || !canvas || initializedRef.current) return;
    if (deckRef.current) {
      initializedRef.current = true;
      setInitialized(true);
      return;
    }

    const { lat: latitude, lng: longitude } = map.getCenter();

    deckRef.current = new Deck({
      glOptions: disableController ? { preserveDrawingBuffer: true } : undefined,
      canvas,
      width: '100%',
      height: '100%',
      controller: disableController
        ? {
            dragPan: false,
            dragRotate: false,
            doubleClickZoom: false,
            keyboard: false,
            inertia: false,
            scrollZoom: false,
            touchRotate: false,
            touchZoom: false,
          }
        : true,
      initialViewState: {
        longitude,
        latitude,
        zoom: map.getZoom(),
        bearing: map.getBearing(),
        pitch: map.getPitch(),
      },
      onViewStateChange: ({ viewState }) => {
        map.jumpTo({
          center: [viewState.longitude, viewState.latitude],
          zoom: viewState.zoom,
          bearing: viewState.bearing,
          pitch: viewState.pitch,
        });
      },
      layers: [],
      effects: [],
    });

    highlightedFlowTooltip.current = new Popup({
      className: 'map-tooltip flowmap-tooltip',
      closeButton: false,
    });

    initializedRef.current = true;
    setInitialized(true);
  }

  function update(
    cyclabilityZones: CyclabilityZone[] | undefined,
    externalCyclabilityZones: CyclabilityZone[] | undefined,
    flows: Flow[] | undefined,
    currentRange: TSliderRange | undefined,
    cyclabilityZoneId: number | null,
  ) {
    if (!deckRef.current) return;

    if (!flows || !cyclabilityZones || !currentRange || !externalCyclabilityZones) {
      deckRef.current?.setProps({ layers: [] });

      return;
    }

    const flowsMap = cyclabilityZones.reduce<{
      [key: number]: { from: { count: number }; to: { count: number }; intern: { count: number } };
    }>((res, { id }) => {
      res[id] = { from: { count: 0 }, to: { count: 0 }, intern: { count: 0 } };
      return res;
    }, {});
    const externalFlowsMap = externalCyclabilityZones.reduce<{
      [key: number]: { from: { count: number }; to: { count: number }; intern: { count: number } };
    }>((res, { id }) => {
      res[id] = { from: { count: 0 }, to: { count: 0 }, intern: { count: 0 } };
      return res;
    }, {});
    flows.forEach(({ origin, destination, count }) => {
      if (origin !== destination) {
        if (flowsMap[origin]) flowsMap[origin].from.count += count;
        if (flowsMap[destination]) flowsMap[destination].to.count += count;
        if (externalFlowsMap[origin]) externalFlowsMap[origin].from.count += count;
        if (externalFlowsMap[destination]) externalFlowsMap[destination].to.count += count;
      } else {
        if (flowsMap[origin]) flowsMap[origin].intern.count += count;
        if (externalFlowsMap[origin]) externalFlowsMap[origin].intern.count += count;
      }
    });

    deckRef.current?.setProps({
      layers: [
        new FlowMapLayer({
          id: 'origin-destination-flows-layer',
          colors: {
            locationAreas: {
              outline: '#3e7bdf',
              normal: '#3e7bdf',
              selected: '#3e7bdf',
              highlighted: '#3e7bdf',
              connected: '#3e7bdf',
            },
          },
          locations: {
            type: 'FeatureCollection',
            features: [...cyclabilityZones, ...externalCyclabilityZones].map<TLocation>(
              ({ id, name, center: geometry }) => ({
                type: 'Feature',
                geometry,
                properties: { id: `${id}`, name, flows: flowsMap[id] || externalFlowsMap[id] },
              }),
            ),
          },
          flows: flows
            .filter(
              ({ origin, destination }) =>
                (flowsMap[origin] || externalFlowsMap[origin]) &&
                (flowsMap[destination] || externalFlowsMap[destination]) &&
                (cyclabilityZoneId === null ||
                  cyclabilityZoneId === origin ||
                  cyclabilityZoneId === destination),
            )
            .sort((a, b) => b.count - a.count)
            .slice(0, currentRange[1])
            .map<TFlow>(({ origin, destination, count }, index) => ({
              index,
              origin: `${origin}`,
              destination: `${destination}`,
              count,
            })),
          autoHighlight: false,
          pickable: true,
          getFlowColor: ({ origin, destination }) => {
            if (flowsMap[origin] && flowsMap[destination])
              return cyclabilityZoneId ? '#9BC2FF' : '#3E7BDF';
            else return '#FFD12F';
          },
          getFlowMagnitude: ({ count }: TFlow) => count,
          getFlowOriginId: ({ origin }: TFlow) => origin,
          getFlowDestId: ({ destination }: TFlow) => destination,
          getLocationId: ({ properties: { id } }: TLocation) => id,
          getLocationCentroid: ({
            geometry: {
              coordinates: [lng, lat],
            },
          }: TLocation) => [lng, lat],
          onHover: handleHover,
          onClick: (event) => handleClick(event, cyclabilityZones),
        }) as unknown as Layer<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
      ],
    });
  }

  function handleHover({ index, ...props }: FlowLayerPickingInfo) {
    highlightedFlowTooltip.current?.remove();

    if (!map || index === -1) return;

    if (props.type === PickingType.FLOW) {
      const origin: TLocation = props.origin;
      const destination: TLocation = props.dest;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const [lng, lat]: [number, number] = (props as any).coordinate;
      const count: number = props.object.count;

      highlightedFlowTooltip.current
        ?.setHTML(
          `<div>${origin.properties.name || ''}</div><div>&#x21b3; ${
            destination.properties.name || ''
          }</div><h3>${t('commons.stats.journeys', {
            count,
            countFormatted: formatNumber(count),
          })}<h3>`,
        )
        .setLngLat({ lng, lat })
        .addTo(map);
    } else if (props.type === PickingType.LOCATION) {
      const {
        geometry: {
          coordinates: [lng, lat],
        },
        properties: {
          name,
          flows: { from: fromFlows, to: toFlows, intern: internFlows },
        },
      }: TLocation = props.object;

      highlightedFlowTooltip.current
        ?.setHTML(
          `<h3>${name || ''}</h3><div>${t(
            'cycling-insights.usage.origin_destination.outgoing_flows',
            {
              count: fromFlows.count,
            },
          )}<br />${t('cycling-insights.usage.origin_destination.incoming_flows', {
            count: toFlows.count,
          })}<br />${t('cycling-insights.usage.origin_destination.internal_flows', {
            count: internFlows.count,
          })}</div>`,
        )
        .setLngLat({ lat, lng })
        .addTo(map);
    }
  }

  function handleClick({ ...props }: FlowLayerPickingInfo, zones: CyclabilityZone[]) {
    if (props.type === PickingType.LOCATION) {
      const {
        properties: { id },
      }: TLocation = props.object;
      if (zones.find(({ id: zoneId }) => parseInt(id) === zoneId))
        setCyclabilityZoneId?.(parseInt(id));
    }
  }

  function clear() {
    highlightedFlowTooltip.current = undefined;
    deckRef.current = undefined;
    initializedRef.current = false;
    setInitialized(false);
  }

  function destroy() {
    clear();

    initializedRef.current = false;
    setInitialized(false);
  }

  return { initialized, init, update, clear, destroy };
}

export default useOriginDestinationFlows;
