import { Box } from '@mui/material';
import { Trans } from 'react-i18next';

import illuFlux from '../../../../assets/images/illu-flux.png';
import LinkCard from '../../../../components/link-card';
import TabIntroduction from '../../../../components/tab-introduction';
import { TOutletContext } from '../../../../layouts/page/container';
import { TCartographicDataPageContext } from '../../context';

import MostImportantDiscontinuities from './most-important';
import Resolutions from './resolutions';

function DiscontinuityContent(context: TCartographicDataPageContext & TOutletContext): JSX.Element {
  const {
    discontinuity: { selectedSection },
  } = context;
  return (
    <Box display="flex" flexDirection="column" gap={3} minHeight="100%">
      {selectedSection ? (
        <Resolutions {...context} />
      ) : (
        <>
          <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.discontinuity" />
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            flexGrow={1}
            gap={2}
            justifyContent="space-between"
          >
            <MostImportantDiscontinuities {...context} />
            <LinkCard
              description={
                <Trans i18nKey="cycling-insights.facilities.discontinuity.targeted_analysis.subtitle" />
              }
              icon={illuFlux}
              title={
                <Trans i18nKey="cycling-insights.facilities.discontinuity.targeted_analysis.title" />
              }
              to="../discontinuity-analysis"
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default DiscontinuityContent;
