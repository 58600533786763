import { TPublicationStatus } from '@geovelo-frontends/commons';
import {
  Box,
  Checkbox,
  Chip,
  DialogProps,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  MenuProps,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '../../../../components/dialog';
import { publicationStatus } from '../../../../models/publication-status';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const menuProps: Partial<MenuProps> = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const statuses: TPublicationStatus[] = ['published', 'unpublished', 'waitingForApproval'];

interface IErrors {
  publicationStatuses?: boolean;
}

function FilterDialog({
  open,
  selectedPublicationStatuses: _selectedPublicationStatuses,
  onConfirm,
  ...props
}: Omit<DialogProps, 'onClose' | 'title'> & {
  dialogTitle: string;
  onCancel: () => void;
  onConfirm: (props?: { selectedPublicationStatuses: TPublicationStatus[] }) => void;
  selectedPublicationStatuses: TPublicationStatus[];
}): JSX.Element {
  const [selectedPublicationStatuses, selectPublicationStatuses] = useState(
    _selectedPublicationStatuses,
  );
  const [errors, setErrors] = useState<IErrors>({});
  const { t } = useTranslation();

  function handleStatusChange({ target: { value } }: SelectChangeEvent<TPublicationStatus[]>) {
    if (typeof value !== 'string') selectPublicationStatuses(value);
  }

  function handleConfirm() {
    const errors: IErrors = {};

    if (selectedPublicationStatuses.length === 0) errors.publicationStatuses = true;

    setErrors(errors);

    if (Object.values(errors).find((error) => error)) return;

    onConfirm({ selectedPublicationStatuses });
  }

  return (
    <Dialog
      cancelTitle={t('commons.actions.cancel')}
      maxWidth="xs"
      onConfirm={handleConfirm}
      open={open}
      scroll="paper"
      title={t('cycling-insights.tourism.rides.filter_dialog.title')}
      {...props}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <FormControl
          error={errors.publicationStatuses}
          margin="dense"
          size="small"
          variant="outlined"
        >
          <InputLabel id="status-chip-label">{t('commons.status')}</InputLabel>
          <Select
            multiple
            id="status-chip"
            input={<OutlinedInput id="select-status-chip" label={t('commons.status')} />}
            labelId="status-chip-label"
            MenuProps={menuProps}
            onChange={handleStatusChange}
            renderValue={(selected) => (
              <Box display="flex" flexWrap="wrap" gap="4px">
                {selected.map((key) => {
                  const { titleKey } = publicationStatus[key];

                  return <Chip key={key} label={t(titleKey)} size="small" />;
                })}
              </Box>
            )}
            value={selectedPublicationStatuses}
          >
            {statuses.map((key) => {
              const { titleKey } = publicationStatus[key];

              return (
                <MenuItem dense key={key} value={key}>
                  <Checkbox
                    checked={selectedPublicationStatuses.indexOf(key) > -1}
                    style={{ padding: 4, marginRight: 8 }}
                  />
                  <ListItemText primary={t(titleKey)} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </Dialog>
  );
}

export default FilterDialog;
