import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TFubFeature } from '../../models/accidentology';

function AccidentTooltip({
  object: {
    properties: { date, accidentData },
  },
}: {
  object: TFubFeature;
}): JSX.Element {
  const { t } = useTranslation();

  if (!accidentData) return <></>;

  const { roadType, vehicles, type } = accidentData;

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography color="textSecondary" variant="caption">
          {date?.format('LL')}
        </Typography>
      </Box>
      <Box>
        <Typography fontWeight={600} variant="body2">
          {t('cycling-insights.usage.accidentology.accident')}
        </Typography>
      </Box>
      {roadType && (
        <Box>
          <Typography variant="body2">
            <b>Voie :</b> {roadType.replaceAll('_', ' ')}
          </Typography>
        </Box>
      )}
      {vehicles.length > 0 && (
        <Box>
          <Typography variant="body2">
            <b>Véhicules impliqués :</b> {vehicles.join(', ')}
          </Typography>
        </Box>
      )}
      <Box>
        <Typography variant="body2">
          <b>État de la victime :</b>{' '}
          {type === 'deadly' ? 'Décédée' : type === 'hospitalized' ? 'Hospitalisée' : 'Blessée'}
        </Typography>
      </Box>
    </Box>
  );
}

export default AccidentTooltip;
