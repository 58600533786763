import {
  GISFile,
  GISService,
  TGISFileStatus,
  gisFileStatuses,
  useAmplitudeTracker,
  useCancellablePromise,
  useFileSaver,
} from '@geovelo-frontends/commons';
import { AddCircleOutline } from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';
import { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import Card from '../../../../components/card';
import Table, { TRow } from '../../../../components/table';
import { environment } from '../../../../environment';
import usePaginatedTable from '../../../../hooks/table/paginated';
import useSortableTable from '../../../../hooks/table/sortable';
import { TGISPageContext } from '../../context';

import ChatDialog from './chat-dialog';
import NewFileDialog from './new-file-dialog';

type TKey = 'status' | 'title' | 'date';

const keys: TKey[] = ['status', 'title', 'date'];

function SendDataTable({ sendData: { files, setFiles } }: TGISPageContext): JSX.Element {
  const [rows, setRows] = useState<TRow<number, TKey>[] | undefined>();
  const [newFileDialogOpen, openNewFileDialog] = useState(false);
  const [selectedFile, selectFile] = useState<GISFile | null>(null);
  const [chatDialogOpen, openChatDialog] = useState(false);
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { transitions } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { page, rowsPerPage, setPage, paginate, onPageChange, onRowsPerPageChange } =
    usePaginatedTable<number, TKey>();
  const { orderBy, order, sort, onSortRequest } = useSortableTable<number, TKey>('date', 'desc', {
    setPage,
  });
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { download } = useFileSaver();
  const { trackEvent } = useAmplitudeTracker();
  const { search } = useLocation();

  useEffect(() => {
    getFiles();

    return () => {
      cancelPromises();
      setFiles(undefined);
    };
  }, []);

  useEffect(() => {
    const id = parseInt(search.substring(search.indexOf('?') + 1, search.indexOf('&utm_source')));
    if (id) handleChatOpen(id);
  }, [files]);

  useEffect(() => {
    if (files) {
      const _rows: TRow<number, TKey>[] = [...files].map(
        ({ id: key, status, title, created, messages }) => ({
          key,
          cells: {
            status: {
              value: status,
              format: (_status: TGISFileStatus) => t(gisFileStatuses[_status].titleKey),
            },
            title: { value: title || '' },
            date: { value: created, format: (date: Moment) => date.format('L') },
          },
          inkBarColor: gisFileStatuses[status].color,
          messagesCount: messages.length,
        }),
      );

      paginate(sort(_rows, 'title'));

      setRows(paginate(sort(_rows, 'title')));
    } else {
      setRows(undefined);
    }
  }, [files, orderBy, order, page, rowsPerPage]);

  async function getFiles() {
    cancelPromises();
    setFiles(undefined);

    try {
      const files = await cancellablePromise(GISService.getFiles());

      setFiles(files);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.facilities.gis_files.server_error'), {
          variant: 'error',
        });
      }
    }
  }

  function handleChatOpen(fileId: number) {
    const file = files?.find(({ id }) => id === fileId);
    selectFile(file || null);
    if (file) openChatDialog(true);
  }

  function handleDownload(id: number) {
    const file = files?.find((file) => file.id === id);
    if (!file) return;

    const { documentUrl } = file;

    download(
      `${environment.backendUrl}${documentUrl}`,
      documentUrl.slice(documentUrl.lastIndexOf('/') + 1),
    );
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        gap={2}
        padding={2}
        sx={{ backgroundColor: 'whitesmoke', overflowY: 'auto' }}
      >
        <Card
          actions={[
            {
              children: <Trans i18nKey="cycling-insights.facilities.gis_files.form.title" />,
              color: 'primary',
              disabled: !files,
              key: 'add',
              onClick: () => {
                trackEvent('Button Clicked', {
                  pathname: `${window.location.host}${window.location.pathname}`,
                  partner_id: currentPartner?.id,
                  partner_code: currentPartner?.code,
                  cta: 'Facilities Upload File Button',
                });
                openNewFileDialog(true);
              },
              needWritePermission: true,
              startIcon: <AddCircleOutline />,
              variant: 'contained',
            },
          ]}
          permission={'write'}
          subtitle={t('cycling-insights.cartographic_data.introduction.gis_files')}
          title={<Trans i18nKey="cycling-insights.facilities.gis_files.table.title" />}
        >
          <Table
            hasInkBars
            count={files?.length}
            headers={{
              status: {
                label: <Trans i18nKey="commons.status" />,
                sortable: true,
              },
              title: {
                label: <Trans i18nKey="commons.title" />,
                sortable: true,
              },
              date: {
                label: <Trans i18nKey="cycling-insights.facilities.gis_files.table.date" />,
                sortable: true,
              },
            }}
            keys={keys}
            onChatOpen={handleChatOpen}
            onDownload={handleDownload}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            onSortRequest={onSortRequest}
            order={order}
            orderBy={orderBy}
            page={page}
            rows={rows}
            rowsPerPage={rowsPerPage}
            size="small"
            title="GIS files table"
          />
        </Card>
      </Box>
      <NewFileDialog
        onAdd={(file: GISFile) => {
          openNewFileDialog(false);
          if (files) setFiles([file, ...files]);
        }}
        onCancel={() => openNewFileDialog(false)}
        open={newFileDialogOpen}
      />
      <ChatDialog
        onClose={() => {
          openChatDialog(false);
          setTimeout(() => selectFile(null), transitions.duration.leavingScreen);
        }}
        open={chatDialogOpen}
        selectedFile={selectedFile}
      />
    </>
  );
}

export default SendDataTable;
