import { Report, useClipboard } from '@geovelo-frontends/commons';
import { Menu, MenuItem, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';

import { environment } from '../environment';

function ReportRedirectionMenu({
  anchorEl,
  selectedReport,
  setAnchorEl,
}: {
  anchorEl: HTMLButtonElement | null;
  selectedReport: Report | null;
  setAnchorEl: (el: HTMLButtonElement | null) => void;
}): JSX.Element {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { copy: copyToClipboard } = useClipboard(
    () => enqueueSnackbar(t('commons.copied_to_clipboard')),
    () => enqueueSnackbar(t('commons.not_copied_to_clipboard')),
  );

  function handleOSMOpen({ osmNoteId }: Report): void {
    if (osmNoteId) {
      window.open(`${environment.osm.apiUrl}/note/${osmNoteId}`, '_blank');
      setAnchorEl(null);
    }
  }

  function handleJOSMOpen({ JOSMParams }: Report): void {
    window.open('http://127.0.0.1:8111/load_and_zoom?' + JOSMParams, '_blank');
    setAnchorEl(null);
  }

  function handleGeoveloOpen({ id }: Report): void {
    window.open(id ? `${environment.frontendUrl}/reports/${id}` : '', '_blank');
    setAnchorEl(null);
  }

  return (
    <Menu
      keepMounted
      anchorEl={anchorEl}
      onClose={() => {
        setAnchorEl(null);
      }}
      open={Boolean(anchorEl)}
    >
      {selectedReport?.osmNoteId && (
        <MenuItem key="see_on_osm" onClick={() => handleOSMOpen(selectedReport)}>
          <Typography>
            <Trans i18nKey="commons.report.actions.see_on_osm" />
          </Typography>
        </MenuItem>
      )}
      {selectedReport?.JOSMParams && (
        <MenuItem key="see_on_josm" onClick={() => handleJOSMOpen(selectedReport)}>
          <Typography>
            <Trans i18nKey="commons.report.actions.see_on_josm" />
          </Typography>
        </MenuItem>
      )}
      <MenuItem
        key="see_on_geovelo"
        onClick={() => {
          if (selectedReport) handleGeoveloOpen(selectedReport);
          else setAnchorEl(null);
        }}
      >
        <Typography>
          <Trans i18nKey="commons.report.actions.see_on_geovelo" />
        </Typography>
      </MenuItem>
      <MenuItem
        key="copy_to_clipboard"
        onClick={() => {
          if (selectedReport)
            copyToClipboard(
              `${environment.bicycleFacilitiesUrl}fr/contributions/${selectedReport.id}`,
            );
          setAnchorEl(null);
        }}
      >
        <Typography>
          <Trans i18nKey="commons.report.actions.copy_to_clipboard" />
        </Typography>
      </MenuItem>
    </Menu>
  );
}

export default ReportRedirectionMenu;
