import { CyclabilityZone, CyclabilityZoneBNSCStats, Report } from '@geovelo-frontends/commons';
import { ReactNode, useRef, useState } from 'react';

import { IPeriodFormValues } from '../../components/form/period';
import { ITableRef } from '../../components/table';
import useQueryParams from '../../hooks/query-params';
import PageContainerLayout from '../../layouts/page/container';
import { IDashboardPage } from '../dashboard-page';

import ParkingsTab from './components/parkings';
import { TParkingsPageContext } from './context';

function ParkingsPage(page: IDashboardPage): JSX.Element {
  const { searchParams, getPeriods } = useQueryParams();

  // header context
  const [prevButtonClick, setPrevButtonClick] = useState<() => () => void>();
  const [actions, setActions] = useState<ReactNode>();
  const [title, setTitle] = useState<ReactNode>();

  // parkings context
  const [cyclabilityZone, setCyclabilityZone] = useState<CyclabilityZone | null>();
  const [prevCyclabilityZone, setPrevCyclabilityZone] = useState<CyclabilityZone | null>();
  const [cyclabilityZones, setCyclabilityZones] = useState<CyclabilityZone[]>();
  const [prevCyclabilityZones, setPrevCyclabilityZones] = useState<CyclabilityZone[]>();
  const [zonesMap, setZonesMap] = useState<{ [id: number]: CyclabilityZone }>({});
  const [selectedZone, selectZone] = useState<CyclabilityZone | null>(null);
  const [stats, setStats] = useState<CyclabilityZoneBNSCStats | undefined>();
  const [prevStats, setPrevStats] = useState<
    { [key: number]: CyclabilityZoneBNSCStats; global: CyclabilityZoneBNSCStats } | undefined
  >();
  const [defaultPeriods] = useState(() => getPeriods());
  const [periods, setPeriods] = useState<IPeriodFormValues>(defaultPeriods);
  const [periodsComparisonEnabled, enablePeriodsComparison] = useState(
    searchParams.get('compare') === 'true',
  );
  const [reports, setReports] = useState<Report[]>();
  const [selectedReportId, selectReportId] = useState<number | null>(null);
  const reportsTableRef = useRef<ITableRef>(null);

  return (
    <PageContainerLayout<TParkingsPageContext>
      context={{
        header: { prevButtonClick, title, actions, setPrevButtonClick, setTitle, setActions },
        defaultPeriods,
        period: {
          values: periods,
          comparisonEnabled: periodsComparisonEnabled,
          setValues: setPeriods,
          enableComparison: enablePeriodsComparison,
        },
        parkings: {
          cyclabilityZone,
          prevCyclabilityZone,
          cyclabilityZones,
          prevCyclabilityZones,
          prevStats,
          selectedZone,
          stats,
          zonesMap,
          reports,
          selectedReportId,
          reportsTableRef,
          selectZone,
          setCyclabilityZone,
          setPrevCyclabilityZone,
          setCyclabilityZones,
          setPrevCyclabilityZones,
          setPrevStats,
          setStats,
          setZonesMap,
          setReports,
          selectReportId,
        },
      }}
      page={page}
    />
  );
}

export { ParkingsTab };
export default ParkingsPage;
