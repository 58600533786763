import { Box, DialogProps, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import Dialog from '../../../../components/dialog';

function InfoDialog({
  onClose,
  ...props
}: Omit<DialogProps, 'onClose'> & { onClose: () => void }): JSX.Element {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      cancelTitle={t('commons.actions.close')}
      dialogTitle="accidentology-info-dialog"
      maxWidth="sm"
      onCancel={() => onClose()}
      title={t('cycling-insights.usage.accidentology.info_dialog.title')}
      {...props}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography>{t('cycling-insights.usage.accidentology.info_dialog.description')}</Typography>
        <Typography>
          <Trans
            components={[
              <b key={0} />,
              <a
                href="https://www.data.gouv.fr/fr/datasets/bases-de-donnees-annuelles-des-accidents-corporels-de-la-circulation-routiere-annees-de-2005-a-2022/"
                key={1}
                rel="noreferrer"
                target="_blank"
              />,
            ]}
            i18nKey="cycling-insights.usage.accidentology.info_dialog.description_accidents"
          />
        </Typography>
        <Typography>
          <Trans
            components={[<b key={0} />]}
            i18nKey="cycling-insights.usage.accidentology.info_dialog.description_reports"
          />
        </Typography>
        <Typography>
          <Trans
            components={[<b key={0} />]}
            i18nKey="cycling-insights.usage.accidentology.info_dialog.description_sudden_brakings"
          />
        </Typography>
        <Typography>
          <Trans
            components={[
              <b key={0} />,
              <a
                href="https://opendata.parlons-velo.fr/"
                key={1}
                rel="noreferrer"
                target="_blank"
              />,
            ]}
            i18nKey="cycling-insights.usage.accidentology.info_dialog.description_black_spots"
          />
        </Typography>
      </Box>
    </Dialog>
  );
}

export default InfoDialog;
