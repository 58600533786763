import { useOutletContext } from 'react-router-dom';

import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { TCartographicDataPageContext } from '../../context';

import DiscontinuityContent from './content';

function DiscontinuityTab(): JSX.Element {
  const context = useOutletContext<TCartographicDataPageContext & TOutletContext>();

  return (
    <>
      <PageContentLayout leftPanel={<DiscontinuityContent {...context} />} />
    </>
  );
}

export default DiscontinuityTab;
