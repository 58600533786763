import { GeogroupMember, GeogroupService } from '@geovelo-frontends/commons';
import { DialogProps, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import Dialog from '../../../../components/dialog';

type TProps = Omit<DialogProps, 'onClose'> & {
  members: GeogroupMember[];
  onClose: (reload?: boolean) => void;
};

function RemoveDialog({ members, onClose, ...props }: TProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  async function handleConfirm() {
    if (!currentPartner) return;

    setLoading(true);

    const succeeded = await Promise.all(
      members.map((member) =>
        GeogroupService.removeMember(currentPartner, member).catch(() => false),
      ),
    );

    const removedMembers = members.filter((_, index) => succeeded[index]);
    const notRemovedMembers = members.filter((_, index) => !succeeded[index]);

    if (notRemovedMembers.length > 0) {
      enqueueSnackbar(
        t('cycling-insights.community.members.remove_dialog.not_removed', {
          count: notRemovedMembers.length,
          members: notRemovedMembers.map(({ username }) => username).join(', '),
        }),
        { variant: 'error' },
      );
    }

    if (removedMembers.length > 0) {
      enqueueSnackbar(
        t('cycling-insights.community.members.remove_dialog.removed', {
          count: removedMembers.length,
          members: removedMembers.map(({ username }) => username).join(', '),
        }),
        { variant: 'success' },
      );

      onClose(true);
    }

    setLoading(false);
  }

  return (
    <Dialog
      dangerous
      confirmTitle={<Trans i18nKey="commons.actions.remove" />}
      dialogTitle="remove-members-dialog"
      loading={loading}
      maxWidth="sm"
      onCancel={onClose}
      onConfirm={handleConfirm}
      title={<Trans i18nKey="cycling-insights.community.members.remove_dialog.title" />}
      {...props}
    >
      <Typography color="error">
        <Trans i18nKey="cycling-insights.community.members.remove_dialog.description" />
      </Typography>
      <ul>
        {members.map(({ id, username }) => (
          <li key={id}>
            <Typography>{username}</Typography>
          </li>
        ))}
      </ul>
    </Dialog>
  );
}

export default RemoveDialog;
