import { UserService } from '@geovelo-frontends/commons';
import { Backdrop, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../../app/context';
import Button from '../../components/button';
import CreatePartnerDialog from '../../components/create-partner-dialog';
import EditPartnerDialog from '../../components/edit-partner-dialog';

import Drawer from './drawer';
import Main from './main';

interface IProps {
  children: JSX.Element;
}

function Layout({ children }: IProps): JSX.Element {
  const [drawerOpen, openDrawer] = useState(false);
  const {
    user: { current: currentUser },
    partner: {
      createDialogOpen: createPartnerDialogOpen,
      editDialogOpen: editPartnerDialogOpen,
      partnerToEdit,
      list: userPartners,
      contractTemplates,
    },
    actions: { setUserPartners, openCreatePartnerDialog, openEditPartnerDialog, setPartnerToEdit },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { transitions } = useTheme();

  async function handleCreatePartnerDialogClose(newPartnerId?: number) {
    openCreatePartnerDialog(false);

    if (newPartnerId !== undefined) {
      try {
        const partners = (
          await UserService.getPartners({
            isAdmin: currentUser?.isGeovelo,
            isSupport: currentUser?.isSupport,
          })
        )
          .filter(({ contracts }) => {
            return !!contracts?.find(
              ({ contractTemplate: { code } }) => code.indexOf('entreprise') === -1,
            );
          })
          .sort((a, b) => a.title.localeCompare(b.title));
        const newPartner = partners.find(({ id }) => id === newPartnerId);

        if (newPartner) {
          enqueueSnackbar(t('commons.partner.created'), {
            action: (key) => (
              <>
                <Button
                  color="inherit"
                  href={`/${newPartner.code}`}
                  onClick={() => {
                    closeSnackbar(key);
                  }}
                  variant="outlined"
                >
                  <Trans i18nKey="commons.actions.open" />
                </Button>
              </>
            ),
          });
        }

        setUserPartners(partners);
      } catch {
        //
      }
    }
  }

  async function handleEditPartnerDialogClose() {
    openEditPartnerDialog(false);
    setTimeout(() => setPartnerToEdit(null), transitions.duration.leavingScreen);

    if (partnerToEdit && userPartners) {
      const partners = (
        await UserService.getPartners({
          isAdmin: currentUser?.isGeovelo,
          isSupport: currentUser?.isSupport,
        })
      )
        .filter(({ contracts }) => {
          return !!contracts?.find(
            ({ contractTemplate: { code } }) => code.indexOf('entreprise') === -1,
          );
        })
        .sort((a, b) => a.title.localeCompare(b.title));

      setUserPartners(partners);
    }
  }

  return (
    <>
      <Wrapper>
        <Drawer onDrawerToggle={openDrawer} open={drawerOpen} />
        <Main>{children}</Main>
        <StyledBackdrop onClick={() => openDrawer(false)} open={drawerOpen} />
      </Wrapper>
      {currentUser?.isGeovelo && contractTemplates && contractTemplates.length > 0 && (
        <CreatePartnerDialog
          onClose={handleCreatePartnerDialogClose}
          open={createPartnerDialogOpen}
        />
      )}
      {partnerToEdit && (
        <EditPartnerDialog
          onClose={handleEditPartnerDialogClose}
          open={editPartnerDialogOpen}
          partnerToEdit={partnerToEdit}
        />
      )}
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;
`;

const StyledBackdrop = styled(Backdrop)`
  && {
    z-index: ${({ theme: { zIndex } }) => zIndex.drawer - 1};
  }
`;

export default Layout;
