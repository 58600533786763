import { SvgIcon, SvgIconProps } from '@mui/material';

export function HexagonIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 22 25" {...props}>
      <path
        d="M2.1077 7.62774L11 2.49377L19.8923 7.62774V17.8957L11 23.0297L2.1077 17.8957V7.62774Z"
        fill="none"
        stroke="#E76685"
        strokeWidth="3"
      />
    </SvgIcon>
  );
}
