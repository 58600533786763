import {
  Autocomplete,
  FileInput,
  GeocoderService,
  Period,
  Place,
  Report,
  ReportService,
  TFile,
  TReportTypeCode,
  reportTypesMap,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Close, Event as EventIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../../../app/context';
import DatePickerDialog from '../../../../components/form/date-picker-dialog';
import useCartographicReports from '../../../../hooks/map/cartographic-reports';
import { TCartographicDataPageContext } from '../../context';

interface IValues {
  type: TReportTypeCode | '';
  description: string;
  scheduleDates: [Moment | null, Moment | null];
}

function FacilitiesNewReportForm({
  header: { setActions },
}: TCartographicDataPageContext): JSX.Element {
  const {
    map: { current: currentMap },
    report: { types: reportTypes },
    actions: { getReportTypes },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancelPromises } = useCancellablePromise();
  const [file, setFile] = useState<TFile | null | undefined>(null);
  const [datePickerOpened, openDatePicker] = useState(false);
  const [selectedPlace, selectPlace] = useState<Place | null>(null);
  const [placeError, setPlaceError] = useState(false);
  const { isSubmitting, values, errors, setFieldValue, setValues, handleChange, handleSubmit } =
    useFormik<IValues>({
      initialValues: {
        type: '',
        description: '',
        scheduleDates: [null, null],
      },
      onSubmit,
      enableReinitialize: true,
    });
  const { addMarker, clear: clearMarker } = useCartographicReports(currentMap, {
    disableClusters: true,
    onDragEnd: handlePlaceDrag,
    handleClick: handleMapClick,
  });
  const isBindToOSM = useRef<boolean>();
  const navigate = useNavigate();

  useEffect(() => {
    setActions(
      <Tooltip title={<Trans i18nKey="commons.actions.go_back" />}>
        <span>
          <IconButton color="primary" component={Link} size="small" to="./facilities-reports">
            <Close />
          </IconButton>
        </span>
      </Tooltip>,
    );
    try {
      getReportTypes();
    } catch (err) {
      enqueueSnackbar(t('cycling-insights.reports.types.server_error'), { variant: 'error' });
    }

    return () => {
      setActions(undefined);
      cancelPromises();
    };
  }, []);

  useEffect(() => {
    const selectedType = reportTypes?.find(({ code }) => code === values.type);
    isBindToOSM.current = selectedType?.isBindToOSM || undefined;
  }, [values.type]);

  useEffect(() => {
    if (reportTypes) {
      setValues({
        type: '',
        description: '',
        scheduleDates: [null, null],
      });
      setFile(null);
    } else {
      selectPlace(null);
    }
  }, [reportTypes]);

  useEffect(() => {
    if (selectedPlace && !selectedPlace.addressDetail) {
      try {
        GeocoderService.reverseGeocode(undefined, selectedPlace.point, (place) => {
          selectPlace(place);
        });
      } catch {}
    }
  }, [selectedPlace]);

  useEffect(() => {
    if (!currentMap) return;

    if (selectedPlace && values.type) {
      const { point } = selectedPlace;
      const {
        coordinates: [lng, lat],
      } = point;
      clearMarker();
      addMarker(
        new Report(
          0,
          moment(),
          moment(),
          '',
          '',
          point,
          '',
          undefined,
          undefined,
          undefined,
          'OPEN',
          values.type,
          [],
          null,
          null,
          false,
          null,
          null,
          null,
          false,
          undefined,
          [],
        ),
        { draggable: true },
      );
      currentMap.flyTo({ center: { lat, lng }, zoom: 15 });
    } else {
      clearMarker();
    }
  }, [currentMap, selectedPlace, values.type]);

  function handleCustomPeriodClose(period?: Period) {
    if (period) {
      setFieldValue('scheduleDates', [period.from, period.to]);
    }

    openDatePicker(false);
  }

  async function onSubmit(
    { type: typeCode, description, scheduleDates }: IValues,
    { setSubmitting, setErrors }: FormikHelpers<IValues>,
  ) {
    const type = reportTypes?.find(({ code }) => code === typeCode);

    if (!type) {
      setErrors({ type: 'Type is required' });
      return;
    } else if (!description) {
      setErrors({ description: 'Description is required' });
      return;
    } else if (!selectedPlace) {
      setErrors({});
      setPlaceError(true);
      return;
    }

    setErrors({});
    setPlaceError(false);
    setSubmitting(true);

    try {
      await ReportService.addReport({
        type: type.id,
        place: selectedPlace,
        description,
        scheduleDates,
        photo: file instanceof File ? file : undefined,
      });
      enqueueSnackbar(
        t(
          isBindToOSM.current
            ? 'cycling-insights.reports.osm_cartographic_reports.added'
            : 'cycling-insights.reports.cartographic_reports.added',
        ),
        { variant: 'success' },
      );
      navigate('../facilities-reports');
    } catch (err) {
      enqueueSnackbar(t('cycling-insights.reports.cartographic_reports.error'), {
        variant: 'error',
      });
      setSubmitting(false);
    }
  }

  async function handlePlaceDrag(point: GeoJSON.Point) {
    const place = new Place(undefined, point);

    selectPlace(place);
  }

  function handleMapClick(point: GeoJSON.Point) {
    const place = new Place(undefined, point);
    selectPlace(place);
  }

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('description', event.target.value);
  };

  if (!reportTypes) return <></>;

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        justifyContent="space-between"
        paddingTop={4}
      >
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" flexDirection="column" gap={2} justifyContent="space-between">
            <Typography fontSize="1.125rem" fontWeight={700}>
              <Trans i18nKey="cycling-insights.reports.osm_cartographic_reports.form_dialog.type" />
            </Typography>
            <FormControl
              error={Boolean(errors.type)}
              margin="dense"
              size="small"
              variant="outlined"
            >
              <InputLabel htmlFor="report-type-label">
                <Trans
                  i18nKey={
                    isBindToOSM.current
                      ? 'cycling-insights.reports.osm_cartographic_reports.form_dialog.type'
                      : 'cycling-insights.reports.cartographic_reports.form_dialog.type'
                  }
                />
              </InputLabel>
              <StyledSelect
                inputProps={{
                  id: 'report-type-label',
                }}
                label={t(
                  isBindToOSM.current
                    ? 'cycling-insights.reports.osm_cartographic_reports.form_dialog.type'
                    : 'cycling-insights.reports.cartographic_reports.form_dialog.type',
                )}
                name="type"
                onChange={handleChange}
                value={values.type}
                variant="outlined"
              >
                {reportTypes.map(({ code, titleKey }) => {
                  if (code === 'support' || code === 'exclusionZone') return <div key={code} />;

                  const { Icon, color } = reportTypesMap[code];

                  return (
                    <MenuItem key={code} value={code}>
                      <ListItemIcon>
                        <Icon fontSize="small" style={{ color }} />
                      </ListItemIcon>
                      <ListItemText primary={<Trans i18nKey={titleKey} />} />
                    </MenuItem>
                  );
                })}
              </StyledSelect>
            </FormControl>
            {isBindToOSM.current !== undefined && !isBindToOSM.current && (
              <Box flexShrink={0}>
                <Button
                  color="secondary"
                  endIcon={values.scheduleDates[0] && <Close />}
                  onClick={() => {
                    if (values.scheduleDates[0]) {
                      setFieldValue('scheduleDates', [null, null]);
                    } else openDatePicker(true);
                  }}
                  startIcon={<EventIcon />}
                  variant="contained"
                >
                  {values.scheduleDates[1] !== null && values.scheduleDates[0] !== null
                    ? values.scheduleDates[0]?.format('DD/MM/YY') +
                      ' - ' +
                      values.scheduleDates[1].format('DD/MM/YY')
                    : values.scheduleDates[0] !== null
                      ? values.scheduleDates[0]?.format('DD/MM/YY')
                      : t('cycling-insights.reports.cartographic_reports.form_dialog.schedule')}
                </Button>
              </Box>
            )}
          </Box>
          <TextField
            multiline
            error={Boolean(errors.description)}
            id="outlined-multiline-static"
            label={
              <Trans i18nKey="cycling-insights.reports.cartographic_reports.form_dialog.description" />
            }
            onChange={handleDescriptionChange}
            rows={6}
            variant="outlined"
          />
          <Typography fontSize="1.125rem" fontWeight={700} marginTop={3}>
            <Trans i18nKey="cycling-insights.reports.osm_cartographic_reports.form_dialog.location" />
          </Typography>
          <Typography variant="body1">
            <Trans i18nKey="cycling-insights.reports.osm_cartographic_reports.form_dialog.location_subtitle" />
          </Typography>
          <Autocomplete
            defaultValue={selectedPlace}
            disabled={!values.type}
            error={placeError}
            onSelect={selectPlace}
            size="small"
          />
          {isBindToOSM.current && (
            <>
              <Typography fontSize="1.125rem" fontWeight={700} marginTop={3}>
                <Trans i18nKey="cycling-insights.reports.osm_cartographic_reports.form_dialog.photo" />
              </Typography>
              <FileInput file={file} onChange={setFile} type="image" />
            </>
          )}
        </Box>
        <Box display="flex" gap={3} justifyContent="flex-end">
          <Button
            component={Link}
            disabled={isSubmitting}
            size="large"
            to="../facilities-reports"
            variant="outlined"
          >
            <Trans i18nKey="commons.actions.cancel" />
          </Button>
          <Button
            disableElevation
            disabled={isSubmitting}
            onClick={() => handleSubmit()}
            size="large"
            variant="contained"
          >
            <Trans i18nKey="commons.actions.validate" />
          </Button>
        </Box>
      </Box>
      <DatePickerDialog onClose={handleCustomPeriodClose} open={datePickerOpened} />
    </>
  );
}

const StyledSelect = styled(Select)`
  && .MuiSelect-select {
    align-items: center;
    display: flex;
  }

  && .MuiListItemIcon-root {
    min-width: 36px;
  }
`;

export default FacilitiesNewReportForm;
