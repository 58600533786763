import {
  Period,
  Report,
  ReportService,
  TReportSource,
  TReportTypeCode,
  TReportTypeCodeWithSupport,
  TReportsOrdering,
  TStatus,
  reportTypesMap,
  useCancellablePromise,
  useFileSaver,
} from '@geovelo-frontends/commons';
import { Box, Chip, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../app/context';
import { TRow } from '../components/table';
import { environment } from '../environment';

import usePaginatedTable from './table/paginated';
import useSortableTable from './table/sortable';

function useReportsTable<TKey extends string>({
  isBindToOSM,
  defaultOrderBy,
  selectedReviewsCount,
  selectedStatuses,
  selectedTypeCodes,
  selectedSources,
  currentPeriod,
  selectedId,
  errorMessageKey,
  setLoading,
  selectId,
}: {
  currentPeriod: Period;
  defaultOrderBy: TKey;
  errorMessageKey: string;
  isBindToOSM?: boolean;
  reports: Report[] | undefined;
  selectId: (id: number | null) => void;
  selectedId: number | null;
  selectedReviewsCount?: number;
  selectedSources?: TReportSource[];
  selectedStatuses: TStatus[];
  selectedTypeCodes: TReportTypeCode[];
  setLoading: (loading: boolean) => void;
}) {
  const [reports, setReports] = useState<Report[] | undefined>();
  const [rows, setRows] = useState<TRow<number, TKey>[] | undefined>();
  const [initialized, setInitialized] = useState(false);
  const { page, rowsPerPage, setPage, onPageChange, onRowsPerPageChange } = usePaginatedTable(10);
  const { orderBy, order, setOrderBy, setOrder, onSortRequest } = useSortableTable<number, TKey>(
    defaultOrderBy,
    'desc',
    {
      setPage,
    },
  );
  const [selectedReport, selectReport] = useState<Report | null>(null);
  const {
    report: { types: reportTypes },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { downloadCSV } = useFileSaver();

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    const selectedReport = reports?.find(({ id }) => id === selectedId);

    selectReport(selectedReport || null);
  }, [reports, selectedId]);

  useEffect(() => {
    setRows(reports ? reports.map(parseReport) : undefined);
  }, [reports, selectedReport]);

  useEffect(() => {
    if (initialized && reportTypes) getReports();
  }, [
    initialized,
    reportTypes,
    orderBy,
    order,
    page,
    rowsPerPage,
    currentPeriod,
    selectedReviewsCount,
    selectedStatuses,
    selectedTypeCodes,
    selectedSources,
  ]);

  async function getReports() {
    setReports(undefined);
    cancelPromises();

    if (
      selectedStatuses.length === 0 ||
      selectedTypeCodes.length === 0 ||
      (selectedSources && selectedSources.length === 0)
    )
      return;

    let ordering: TReportsOrdering | undefined;
    if (orderBy === 'status') ordering = order === 'asc' ? 'status' : '-status';
    else if (orderBy === 'type') ordering = order === 'asc' ? 'type__code' : '-type__code';
    else if (orderBy === 'updated') ordering = order === 'asc' ? 'updated' : '-updated';
    else if (orderBy === 'created') ordering = order === 'asc' ? 'created' : '-created';

    try {
      const { reports } = await cancellablePromise(
        ReportService.getReports({
          period: currentPeriod.toIPeriod(),
          typeCodes: selectedTypeCodes,
          status: selectedStatuses.flatMap((status) =>
            status === 'OPEN' || status === 'ONGOING'
              ? 'OPEN'
              : ['CLOSED', 'ARCHIVED', 'CLOSED_BY_OSM'],
          ),
          ordering,
          page: page + 1,
          rowsPerPage,
          reviewCountMin: selectedReviewsCount === -1 ? undefined : selectedReviewsCount,
          reviewCountMax:
            selectedReviewsCount === -1 || selectedReviewsCount === 3
              ? undefined
              : selectedReviewsCount,
          query:
            '{id, geo_point, creator{username}, created, updated, source, report_type_code, status, description, photo, reviews, report_source, osm_note_id}',
        }),
      );

      if (selectedId !== null && !reports.find(({ id }) => selectedId === id)) selectId(null);
      setReports(
        reports.filter(({ nbReviews }) => {
          if (!selectedStatuses.includes('OPEN') && nbReviews === 0) return false;
          else return true;
        }),
      );
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t(errorMessageKey), { variant: 'error' });
        selectId(null);
      }
    }

    setLoading(false);
  }

  function parseReport(report: Report) {
    const { id: key, isClosed, typeCode, updated, created, nbReviews } = report;

    return {
      key,
      cells: {
        type: {
          value: typeCode,
          format: (code: TReportTypeCodeWithSupport) => {
            const selectedType = reportTypes?.find((value) => value.code === code);
            if (!selectedType || code === 'support' || code === 'exclusionZone') return <></>;

            const { titleKey } = selectedType;
            const { Icon, color } = reportTypesMap[code];

            return (
              <Tooltip title={<Trans i18nKey={titleKey} />}>
                <Box alignItems="center" display="flex" gap={1}>
                  <Icon style={{ color }} />
                  <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" width="72">
                    <Trans i18nKey={titleKey} />
                  </Box>
                </Box>
              </Tooltip>
            );
          },
        },
        reviews: { value: nbReviews },
        created: { value: created.format('L') },
        updated: { value: updated.format('L') },
        status: {
          value: isClosed,
          format: (isClosed: boolean) => {
            return isClosed ? (
              <Chip
                label={
                  <Trans
                    i18nKey={isBindToOSM ? 'commons.statuses.processed' : 'commons.statuses.closed'}
                  />
                }
                size="small"
                sx={{ backgroundColor: '#EEF8F4', color: '#038B63' }}
              />
            ) : nbReviews > 0 ? (
              <Chip
                label={<Trans i18nKey="commons.statuses.ongoing" />}
                size="small"
                sx={{ backgroundColor: '#FFEEE0', color: '#FA823E' }}
              />
            ) : (
              <Chip
                label={
                  <Trans
                    i18nKey={isBindToOSM ? 'commons.statuses.unprocessed' : 'commons.statuses.open'}
                  />
                }
                size="small"
                sx={{ backgroundColor: '#FFEBEE', color: '#A42C49' }}
              />
            );
          },
        },
      },
    };
  }

  function reorder(): void {
    if (orderBy === 'updated' && order === 'desc' && page === 0) {
      getReports();
    } else {
      setOrderBy('updated' as TKey);
      setOrder('desc');
      setPage(0);
    }
  }

  function reloadPage(removed?: boolean): void {
    if (removed && rows?.length === 1 && page > 0) setPage(page - 1);
    else getReports();
  }

  function reloadReport(report: Report) {
    if (!rows || !reports) return;

    const reportIndex = reports?.findIndex(({ id }) => id === report.id);
    if (reportIndex !== undefined && reportIndex > -1) {
      const updatedReports = [...reports];
      updatedReports.splice(reportIndex, 1, report);

      setReports(updatedReports);
    }

    const index = rows.findIndex(({ key }) => key === report.id);
    if (index > -1) {
      const updatedRows = [...rows];
      updatedRows.splice(index, 1, parseReport(report));

      setRows(updatedRows);
    }
  }

  function handleDownload(period: Period, reports?: Report[]) {
    if (!reports) return;

    downloadCSV(
      `${t('cycling-insights.reports.osm_cartographic_reports.table.title')
        .replace(/ /g, '_')
        .toLowerCase()}-${period.from.format('YYYY-MM-DD')}_${period.to.format('YYYY-MM-DD')}.csv`,
      [
        'X',
        'Y',
        t('commons.stats.date_label'),
        t('commons.stats.creator_label'),
        t('commons.stats.update_label'),
        t('commons.stats.type_label'),
        t('commons.stats.source_label'),
        t('commons.description'),
        t('commons.status'),
        t('commons.stats.links', { count: 1 }),
      ],
      reports.map(
        ({ id, geoPoint, created, creator, updated, typeCode, source, description, status }) => [
          geoPoint.coordinates[0],
          geoPoint.coordinates[1],
          created.toISOString(),
          creator,
          updated.toISOString(),
          t(reportTypes?.find((type) => type.code === typeCode)?.titleKey || '').toString(),
          t(source?.title || '').toString(),
          `"${(description || '').replace(/"/g, "'")}"`,
          status,
          `${environment.bicycleFacilitiesUrl}fr/contributions/${id}`,
        ],
      ),
    );
  }

  return {
    orderBy,
    order,
    page,
    rowsPerPage,
    rows,
    selectedReport,
    reorder,
    reloadPage,
    reloadReport,
    onPageChange,
    onRowsPerPageChange,
    onSortRequest,
    handleDownload,
  };
}

export default useReportsTable;
