import { Ride, RideService, rideThemesMap } from '@geovelo-frontends/commons';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import Card from '../../../../components/card';

interface IProps {
  canWrite: boolean;
  onChange: (ride: Ride) => void;
  ride: Ride;
}

function ThemeForm({ canWrite, ride, onChange }: IProps): JSX.Element {
  const [selectedThemes, setSelectedThemes] = useState<{ [key: number]: boolean }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    ride: { themes },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const loadingItems = [1, 2, 3];

  useEffect(() => {
    init();
  }, [ride]);

  function init() {
    const { themeIds } = ride;
    setSelectedThemes(
      themeIds.reduce<{ [key: number]: boolean }>((res, id) => {
        res[id] = true;

        return res;
      }, {}),
    );
  }

  async function update(_selectedThemes: { [key: number]: boolean }) {
    if (!themes || !Object.values(_selectedThemes).find((checked) => checked)) return;

    setSelectedThemes(_selectedThemes);

    const { id: rideId } = ride;
    if (!rideId) return;

    const newThemeIds = themes.filter(({ id }) => _selectedThemes[id]).map(({ id }) => id);

    setIsSubmitting(true);

    try {
      await RideService.updateRideThemes(rideId, newThemeIds);

      const updatedRide = ride.clone();
      updatedRide.themeIds = newThemeIds;

      enqueueSnackbar(t('cycling-insights.ride.updated'), { variant: 'success' });
      onChange(updatedRide);
    } catch {
      setSelectedThemes(
        ride.themeIds.reduce<{ [key: number]: boolean }>((res, id) => {
          res[id] = true;

          return res;
        }, {}),
      );
      enqueueSnackbar(t('cycling-insights.ride.not_updated'), { variant: 'error' });
    }

    setIsSubmitting(false);
  }

  const error = canWrite && !Object.values(selectedThemes).find((checked) => checked);

  return (
    <Card title={<Trans i18nKey="cycling-insights.ride.theme_form.title" />}>
      <Box display="flex" flexDirection="column" gap={1} padding={2}>
        {error ? (
          <Typography color="error" variant="caption">
            <Trans i18nKey="cycling-insights.ride.theme_form.required" />
          </Typography>
        ) : (
          <Typography color="textSecondary" variant="caption">
            <Trans i18nKey="cycling-insights.ride.theme_form.info" />
          </Typography>
        )}
        {themes ? (
          <FormControl component="fieldset">
            <FormGroup row>
              {themes.map(({ key, id }) => {
                const theme = rideThemesMap[key];
                if (!theme) return <Fragment key={key} />;

                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedThemes[id] || false}
                        onChange={(event, checked) => {
                          update({ ...selectedThemes, [id]: checked });
                        }}
                      />
                    }
                    disabled={
                      !canWrite ||
                      isSubmitting ||
                      (!selectedThemes[id] &&
                        Object.values(selectedThemes).filter((selected) => selected).length >= 3)
                    }
                    key={id}
                    label={<Trans i18nKey={theme.labelKey} />}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        ) : (
          <FormControl component="fieldset">
            <FormGroup row>
              {loadingItems.map((key) => (
                <FormControlLabel
                  disabled
                  control={<Checkbox checked={false} />}
                  key={key}
                  label={<Skeleton variant="text" width={100} />}
                />
              ))}
            </FormGroup>
          </FormControl>
        )}
      </Box>
    </Card>
  );
}

export default ThemeForm;
