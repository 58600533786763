import { GeogroupService, useCancellablePromise } from '@geovelo-frontends/commons';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import PeriodForm from '../../../../components/form/period';
import TabIntroduction from '../../../../components/tab-introduction';
import { getProgression, getRelativeProgression } from '../../../../utils/stats';
import { IBicycleObservatoryPageContext } from '../../context';

import { TStatKey } from './data';

function GeoveloActivityForm({
  period,
  activityStats: { setData },
}: IBicycleObservatoryPageContext): JSX.Element {
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    return () => {
      cancelPromises();
      setData(undefined);
    };
  }, []);

  useEffect(() => {
    updateData();
  }, [period.values]);

  async function updateData() {
    cancelPromises();
    setData(undefined);

    if (!currentPartner || currentPartner.geoGroupId === null) return;

    const {
      values: { current: currentPeriod, prev: prevPeriod },
    } = period;

    try {
      const [prev, current] = await cancellablePromise(
        Promise.all([
          GeogroupService.getActivityStats(currentPartner, prevPeriod.toIPeriod()),
          GeogroupService.getActivityStats(currentPartner, currentPeriod.toIPeriod()),
        ]),
      );

      let prevNbComputedRoutes = 0,
        prevDistance = 0,
        prevNbRoutes = 0,
        currentNbComputedRoutes = 0,
        currentDistance = 0,
        currentNbRoutes = 0;

      prev.data.forEach(({ nbComputedRoutes, distance, nbRoutes }) => {
        prevNbComputedRoutes += nbComputedRoutes;
        prevDistance += distance;
        prevNbRoutes += nbRoutes;
      });

      current.data.forEach(({ nbComputedRoutes, distance, nbRoutes }) => {
        currentNbComputedRoutes += nbComputedRoutes;
        currentDistance += distance;
        currentNbRoutes += nbRoutes;
      });

      const progressions: { [key in TStatKey]: number } = {
        nbComputedRoutes:
          period.values.current.type === 'custom'
            ? getProgression(currentNbComputedRoutes, prevNbComputedRoutes)
            : getRelativeProgression(
                currentNbComputedRoutes,
                prev.data,
                period.values,
                'nbComputedRoutes',
              ),
        distance:
          period.values.current.type === 'custom'
            ? getProgression(currentDistance, prevDistance)
            : getRelativeProgression(currentDistance, prev.data, period.values, 'distance'),
        nbRoutes:
          period.values.current.type === 'custom'
            ? getProgression(currentNbRoutes, prevNbRoutes)
            : getRelativeProgression(currentNbRoutes, prev.data, period.values, 'nbRoutes'),
      };

      setData({ current, prev, progressions });
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.activity_statistics.server_error'), {
          variant: 'error',
        });
        setData({
          current: { data: [], nbComputedRoutes: 0, distance: 0, duration: 0, nbRoutes: 0 },
          prev: { data: [], nbComputedRoutes: 0, distance: 0, duration: 0, nbRoutes: 0 },
          progressions: { nbComputedRoutes: 0, distance: 0, nbRoutes: 0 },
        });
      }
    }
  }

  return (
    <Box display="flex" flexDirection="column" minHeight="100%">
      <TabIntroduction title="cycling-insights.monitoring_indicators.introduction.geovelo_activity" />
      <PeriodForm forceComparison {...period} comparisonEnabled />
    </Box>
  );
}

export default GeoveloActivityForm;
