import {
  TRoadType,
  TSectionFacility,
  roadTypesLabels,
  useSource,
} from '@geovelo-frontends/commons';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import arrowImage from '../../assets/images/map-arrow-black.png';
import { getFacilityLabelKey } from '../../components/form/facilities';

import { stoppingAreasLayerId } from './stopping-areas';

import {
  DataDrivenPropertyValueSpecification,
  Map,
  Popup,
  SymbolLayerSpecification,
} from '!maplibre-gl';

export type TSuggestionSectionProperties = {
  direction?: 'onward' | 'backward';
  facility?: TSectionFacility;
  facilityLeft?: TSectionFacility;
  facilityRight?: TSectionFacility;
  facilitySuggestion?: TSectionFacility;
  facilityLeftSuggestion?: TSectionFacility;
  facilityRightSuggestion?: TSectionFacility;
  id: number;
  roadType?: TRoadType | null;
  wayName?: string | null;
  color?: string;
};

type TSuggestionSectionFeatureCollection = GeoJSON.FeatureCollection<
  GeoJSON.LineString,
  TSuggestionSectionProperties
>;

const sourceId = 'facilities-suggestions';
const layerId = 'facilities-suggestions';

function useFacilitiesSuggestions(map: Map | null | undefined): {
  initialized: boolean;
  init: () => void;
  update: (collection: TSuggestionSectionFeatureCollection) => void;
  clear: () => void;
  destroy: () => void;
} {
  const [initialized, setInitialized] = useState(false);
  const initializedRef = useRef(false);
  const highlightedRoadTooltip = useRef<Popup>();
  const {
    addGeoJSONSource: addSuggestionsSource,
    getGeoJSONSource: getSuggestionsSource,
    updateGeoJSONSource: updateSuggestionsSource,
    clearGeoJSONSource: clearSuggestionsSource,
  } = useSource(map, sourceId);
  const { addGeoJSONSource: addHighlightedRoadSource, getGeoJSONSource: getHighlightedRoadSource } =
    useSource(map, `${sourceId}-highlight`);
  const { t } = useTranslation();

  let hoveredRoadId: number | string | undefined;
  let hoveredRoadDirection: 'onward' | 'backward' | undefined;

  function init() {
    async function addArrowImage() {
      if (!map) return;

      try {
        const image = await map.loadImage(arrowImage);
        if (image && !map.hasImage('arrow')) map.addImage('arrow', image.data);
      } catch (err) {
        console.error('err image', err);
      }
    }

    if (!map || initializedRef.current) return;
    if (getSuggestionsSource()) {
      initializedRef.current = true;
      setInitialized(true);
      return;
    }

    addSuggestionsSource();
    addHighlightedRoadSource();

    highlightedRoadTooltip.current = new Popup({
      className: 'map-tooltip',
      closeButton: false,
    });

    map.addLayer(
      {
        id: layerId,
        type: 'line',
        source: sourceId,
        paint: {
          'line-width': [
            'interpolate',
            ['exponential', 2],
            ['zoom'],
            10,
            ['*', ['get', 'width'], ['^', 2, -2]],
            24,
            ['*', ['get', 'width'], ['^', 2, 8]],
          ] as DataDrivenPropertyValueSpecification<number>,
          'line-offset': [
            'interpolate',
            ['exponential', 2],
            ['zoom'],
            10,
            ['*', ['get', 'width'], ['^', 2, -2]],
            24,
            ['*', ['get', 'width'], ['^', 2, 8]],
          ] as DataDrivenPropertyValueSpecification<number>,
          'line-color': ['get', 'color'],
          'line-opacity': 0.8,
        },
      },
      'labels',
    );

    map.addLayer(
      {
        id: `${layerId}-highlight`,
        type: 'line',
        source: `${sourceId}-highlight`,
        paint: {
          'line-width': [
            'interpolate',
            ['exponential', 2],
            ['zoom'],
            10,
            ['*', ['get', 'width'], ['^', 2, -2]],
            24,
            ['*', ['get', 'width'], ['^', 2, 8]],
          ] as DataDrivenPropertyValueSpecification<number>,
          'line-offset': [
            'interpolate',
            ['exponential', 2],
            ['zoom'],
            10,
            ['*', ['get', 'width'], ['^', 2, -2]],
            24,
            ['*', ['get', 'width'], ['^', 2, 8]],
          ] as DataDrivenPropertyValueSpecification<number>,
          'line-color': '#ffeb3b',
          'line-opacity': 1,
        },
      },
      'labels',
    );

    const arrowLayout: SymbolLayerSpecification['layout'] = {
      'symbol-placement': 'line',
      'symbol-spacing': 100,
      'icon-allow-overlap': true,
      'icon-image': 'arrow',
      'icon-size': 1,
      visibility: 'none',
    };

    map?.addLayer({
      id: `${layerId}-highlight-arrows`,
      type: 'symbol',
      source: `${sourceId}-highlight`,
      layout: arrowLayout,
    });

    addArrowImage();

    map.on('mousemove', layerId, ({ lngLat, features, point }) => {
      if (map.getZoom() < 14) return;

      if (
        map
          .queryRenderedFeatures(point)
          .find(({ layer: { id: layerId } }) => [stoppingAreasLayerId].indexOf(layerId) !== -1) ||
        !features ||
        !features[0]
      ) {
        map.getCanvas().style.cursor = '';
        clearHighlight();
        return;
      }

      if (features && features.length > 0) {
        const { geometry, properties } = features[0];
        const {
          id,
          direction,
          wayName,
          facility,
          facilityLeft,
          facilityRight,
          facilityLeftSuggestion,
          facilityRightSuggestion,
          facilitySuggestion,
          roadType,
        } = properties;

        highlightedRoadTooltip.current
          ?.setHTML(
            `<div style="padding: 8px"><h3>${
              wayName ? wayName : t(roadTypesLabels[(roadType as TRoadType) || 'unclassified'])
            }</h3>` +
              (facility
                ? `<div>${
                    t('commons.facility') + ' : ' + t(getFacilityLabelKey(facility || 'unknown'))
                  } &#8594; ${t(getFacilityLabelKey(facilitySuggestion || 'unknown'))}</div>`
                : '') +
              (facilityRight
                ? `<div>${
                    t('commons.facility_right') +
                    ' : ' +
                    t(getFacilityLabelKey(facilityRight || 'unknown'))
                  } &#8594; ${t(getFacilityLabelKey(facilityRightSuggestion || 'unknown'))}</div>`
                : '') +
              (facilityLeft
                ? `<div>${
                    t('commons.facility_left') +
                    ' : ' +
                    t(getFacilityLabelKey(facilityLeft || 'unknown'))
                  } &#8594; ${t(getFacilityLabelKey(facilityLeftSuggestion || 'unknown'))}</div>`
                : ''),
          )
          .setLngLat(lngLat)
          .addTo(map);

        if (hoveredRoadId !== id || hoveredRoadDirection !== direction) {
          const highlightedRoadSource = getHighlightedRoadSource();
          if (!highlightedRoadSource) return;

          hoveredRoadId = id;
          hoveredRoadDirection = direction;
          if (hoveredRoadId) {
            map.getCanvas().style.cursor = 'pointer';

            highlightedRoadSource.setData({
              type: 'Feature',
              geometry,
              properties,
            });

            map.setLayoutProperty(`${layerId}-highlight-arrows`, 'icon-offset', [
              'interpolate',
              ['exponential', 0.5],
              ['zoom'],
              16,
              ['literal', [0, 5]],
              24,
              ['literal', [0, 35]],
            ] as DataDrivenPropertyValueSpecification<number>);
          } else {
            clearHighlight();
          }
        }
      }
    });

    map.on('mouseleave', layerId, () => {
      map.getCanvas().style.cursor = '';
      clearHighlight();
    });

    map.on('zoomend', () => {
      if (map.getZoom() >= 16)
        map.setLayoutProperty(`${layerId}-highlight-arrows`, 'visibility', 'visible');
      else map.setLayoutProperty(`${layerId}-highlight-arrows`, 'visibility', 'none');
    });

    initializedRef.current = true;
    setInitialized(true);
  }

  function update({ features }: TSuggestionSectionFeatureCollection) {
    if (!map) return;

    updateSuggestionsSource({
      type: 'FeatureCollection',
      features: features.map(({ properties, ...feature }) => {
        return {
          ...feature,
          properties: {
            ...properties,
            width: 5,
          },
        };
      }),
    });
  }

  function clearHighlight() {
    highlightedRoadTooltip.current?.remove();

    const highlightedRoadSource = getHighlightedRoadSource();
    if (highlightedRoadSource) {
      highlightedRoadSource.setData({ type: 'FeatureCollection', features: [] });
    }

    hoveredRoadId = undefined;
    hoveredRoadDirection = undefined;
  }

  function clear() {
    clearSuggestionsSource();
    clearHighlight();
  }

  function destroy() {
    clear();

    initializedRef.current = false;
    setInitialized(false);
  }

  return { initialized, init, update, clear, destroy };
}

export default useFacilitiesSuggestions;
