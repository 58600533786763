import { SvgIcon, SvgIconProps } from '@mui/material';

function ContributionsIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 80 80" {...props}>
      <path
        d="M53.9225 14.283L59.3688 17.0499L65.8729 5.80486"
        fill="none"
        stroke="#2AC682"
        strokeLinecap="round"
        strokeWidth="3"
      />
      <ellipse cx="40.336" cy="74.7939" fill="#E1EDFF" rx="13.8594" ry="5.13761" />
      <path
        d="M47.4884 22.8168C44.7141 12.6739 35.9221 6.4633 26.3384 9.40381C16.7548 12.3443 10.006 23.3267 12.7794 33.4664C12.7794 33.4664 17.6021 54.259 38.4923 66.3865C40.1699 67.1815 42.7106 66.3153 43.4715 64.8588C55.0071 42.7856 47.4914 22.8159 47.4914 22.8159L47.4884 22.8168ZM33.2885 39.675C27.267 41.5225 20.9746 37.8539 19.2329 31.4863C17.4912 25.1186 20.9608 18.4548 26.9793 16.6082C32.9978 14.7616 39.2932 18.4292 41.0349 24.7969C42.7766 31.1646 39.307 37.8284 33.2885 39.675Z"
        fill="#0A429A"
      />
      <path
        d="M49.436 22.0675C46.6641 11.9334 36.6621 6.10179 27.0969 9.03665C17.5317 11.9715 12.0226 22.5658 14.7936 32.6966C14.7936 32.6966 19.6129 53.4708 40.4679 65.593C41.8438 66.3929 43.5837 65.859 44.3428 64.4041C55.8513 42.3554 49.439 22.0665 49.439 22.0665L49.436 22.0675ZM35.2667 38.9054C29.2567 40.7494 22.9749 37.0824 21.2347 30.7203C19.4946 24.3582 22.956 17.7018 28.9629 15.8587C34.9698 14.0156 41.2546 17.6817 42.9948 24.0438C44.735 30.4059 41.2736 37.0623 35.2667 38.9054Z"
        fill="#528BE8"
      />
      <path
        d="M35.5281 39.8152C41.94 37.8479 45.632 30.7478 43.7745 23.9568C41.917 17.1657 35.2134 13.2553 28.8015 15.2227C22.3896 17.19 18.6975 24.2901 20.555 31.0811C22.4125 37.8721 29.1162 41.7825 35.5281 39.8152Z"
        fill="white"
      />
      <path
        d="M29.286 27.1176C29.2674 26.9383 29.2329 26.7525 29.1816 26.5649C28.9018 25.5418 28.2267 24.85 27.6738 25.0196C27.1208 25.1893 26.8995 26.1562 27.1793 27.1792C27.2306 27.3668 27.2952 27.5433 27.3699 27.7053C27.5736 27.3463 27.8586 27.0879 28.2099 26.9801C28.5612 26.8723 28.9326 26.9294 29.286 27.1176Z"
        fill="#0A429A"
      />
      <path
        d="M36.2939 25.0604C36.2784 24.8535 36.2414 24.6368 36.1814 24.4174C35.9016 23.3944 35.2265 22.7025 34.6736 22.8722C34.1206 23.0418 33.8993 24.0087 34.1791 25.0318C34.2389 25.2504 34.3167 25.4538 34.4075 25.637C34.6104 25.2887 34.8912 25.038 35.2357 24.9322C35.5811 24.8263 35.9459 24.8797 36.2939 25.0604Z"
        fill="#0A429A"
      />
      <path
        d="M35.6706 21.2582C35.3095 21.0538 34.7988 20.8862 34.2905 20.9497C33.767 21.0152 33.2641 21.3249 32.9523 22.033C32.9035 22.1436 32.9487 22.2752 33.0532 22.3268C33.1577 22.3784 33.2819 22.3306 33.3306 22.2199C33.5798 21.6541 33.956 21.4368 34.3394 21.3889C34.7381 21.339 35.1622 21.4718 35.4738 21.6481C35.5754 21.7057 35.7019 21.6651 35.7563 21.5574C35.8106 21.4497 35.7722 21.3157 35.6706 21.2582Z"
        fill="#0A429A"
      />
      <path
        d="M26.0424 24.2127C26.2444 23.8356 26.5914 23.4048 27.0531 23.1708C27.5286 22.9297 28.1099 22.9068 28.7308 23.3286C28.8279 23.3946 28.8561 23.5313 28.7938 23.6341C28.7316 23.7369 28.6024 23.7667 28.5054 23.7008C28.0092 23.3637 27.5816 23.393 27.2334 23.5696C26.8713 23.7532 26.5798 24.1055 26.4056 24.4309C26.3487 24.5371 26.2213 24.5744 26.121 24.5141C26.0207 24.4539 25.9855 24.3189 26.0424 24.2127Z"
        fill="#0A429A"
      />
    </SvgIcon>
  );
}

export default ContributionsIcon;
