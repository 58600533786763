import { BikeRoute, BikeRouteService, IPoint } from '@geovelo-frontends/commons';
import { Paper } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Map from '../../../components/map';
import useBikeRoute from '../../../hooks/map/bike-route';

import { LngLatBounds, Map as MaplibreMap } from '!maplibre-gl';

interface IProps {
  canWrite: boolean;
  bikeRoute: BikeRoute;
  onChange: (bikeRoute: BikeRoute) => void;
}

function BikeRouteMap({ canWrite, bikeRoute, onChange }: IProps): JSX.Element {
  const [map, setMap] = useState<MaplibreMap>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { initialized, init, update } = useBikeRoute(map, canWrite, {
    onCenterUpdate: updateCenter,
  });

  useEffect(() => {
    if (map) {
      init();
    }
  }, [map]);

  useEffect(() => {
    if (map && bikeRoute.bounds) {
      const { north, east, south, west } = bikeRoute.bounds;
      map?.fitBounds(new LngLatBounds({ lat: south, lng: west }, { lat: north, lng: east }));
    }
  }, [initialized]);

  useEffect(() => {
    if (map) update(bikeRoute);
  }, [initialized, bikeRoute]);

  async function updateCenter(point: IPoint) {
    try {
      const { lat, lng } = point;

      const updatedBikeRoute = await BikeRouteService.updateBikeRoute(bikeRoute.id, {
        geo_point_icon: { type: 'Point', coordinates: [lng, lat] },
      });

      enqueueSnackbar(t('cycling-insights.bike_route.updated'), { variant: 'success' });
      onChange(updatedBikeRoute);
    } catch {
      enqueueSnackbar(t('cycling-insights.bike_route.not_updated'), { variant: 'error' });
    }
  }

  return (
    <StyledPaper>
      <StyledMap mapId="bike-route-map" onInit={setMap} />
    </StyledPaper>
  );
}

const StyledPaper = styled(Paper)`
  height: 300px;
  margin: 2px 0;
`;

const StyledMap = styled(Map)`
  && {
    border-radius: 4px;
    height: 100%;
  }
`;

export default BikeRouteMap;
