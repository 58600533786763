import { InfoOutlined } from '@mui/icons-material';
import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import { Chart } from 'chart.js';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { TCartographicDataPageContext } from '../../context';

const roadsTypesChartId = 'roads-types-chart';

function RoadsQualityChart({ roadsQuality: { data } }: TCartographicDataPageContext): JSX.Element {
  const { t } = useTranslation();
  const roadsTypesChartRef = useRef<Chart<'doughnut'>>();
  const [badRoadsCount, setBadRoadsCount] = useState<number>(0);
  const [mediumRoadsCount, setMediumRoadsCount] = useState<number>(0);
  const [goodRoadsCount, setGoodRoadsCount] = useState<number>(0);

  useEffect(() => {
    let badRoadsCountTemp = 0,
      mediumRoadsCountTemp = 0,
      goodRoadsCountTemp = 0;
    data?.features.forEach(({ properties: { roughness } }) => {
      if (roughness === null || roughness === undefined) return;
      if (roughness < 2.5) badRoadsCountTemp += 1;
      else if (roughness > 2.75) goodRoadsCountTemp += 1;
      else mediumRoadsCountTemp += 1;
    });
    setBadRoadsCount(badRoadsCountTemp);
    setGoodRoadsCount(goodRoadsCountTemp);
    setMediumRoadsCount(mediumRoadsCountTemp);
    const roadsTypesChartCtx = document.getElementById(roadsTypesChartId);
    if (roadsTypesChartCtx && roadsTypesChartCtx instanceof HTMLCanvasElement) {
      roadsTypesChartRef.current?.destroy();

      roadsTypesChartRef.current = new Chart(roadsTypesChartCtx, {
        type: 'doughnut',
        data: {
          labels: [
            t('cycling-insights.facilities.roads_quality.form.roughnesses.good_roads'),
            t('cycling-insights.facilities.roads_quality.form.roughnesses.medium_roads'),
            t('cycling-insights.facilities.roads_quality.form.roughnesses.bad_roads'),
          ],
          datasets: data
            ? [
                {
                  data: [goodRoadsCountTemp, mediumRoadsCountTemp, badRoadsCountTemp],
                  backgroundColor: ['#46CE9D', '#FFD159', '#F56B84'],
                },
              ]
            : [
                {
                  data: [70, 30],
                  backgroundColor: ['#E3E7EE', '#F6F7FB'],
                },
              ],
        },
        options: {
          cutout: '80%',
          events: data ? ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'] : [],
          responsive: true,
          maintainAspectRatio: false,
          plugins: data
            ? {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: ({ parsed }) =>
                      `${
                        Math.round(
                          (parsed /
                            (badRoadsCountTemp + mediumRoadsCountTemp + goodRoadsCountTemp)) *
                            1000,
                        ) / 10
                      } %`,
                  },
                },
                title: {
                  display: false,
                },
              }
            : {
                legend: {
                  display: false,
                },
                title: {
                  display: false,
                },
              },
        },
      });
    }
  }, [data]);

  return (
    <Box display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" gap={1} marginBottom={3}>
        <Typography fontSize="1.125rem" fontWeight={700}>
          <Trans i18nKey="cycling-insights.facilities.roads_quality.chart.title" />
        </Typography>
        <Tooltip
          title={<Trans i18nKey="cycling-insights.facilities.roads_quality.chart.tooltip" />}
        >
          <InfoOutlined fontSize="small" sx={{ color: '#5D687E' }} />
        </Tooltip>
      </Box>
      {data && data.features.length === 0 ? (
        <Typography overflow="hidden" textOverflow="ellipsis" variant="body2" whiteSpace="nowrap">
          <Trans i18nKey="commons.no_filtered_data" />
        </Typography>
      ) : (
        <Box
          display="flex"
          flexDirection="row"
          gap={4}
          justifyContent="space-between"
          paddingRight={2}
        >
          <Box
            alignSelf="center"
            height={200}
            maxWidth="100%"
            minWidth={200}
            overflow="hidden"
            position="relative"
            sx={{ overflow: 'hidden' }}
            width={200}
          >
            <canvas id={roadsTypesChartId} style={{ position: 'relative', zIndex: 2 }} />
          </Box>
          <Box display="flex" flexDirection="column" gap={2} justifyContent="center">
            {data ? (
              <>
                <Box display="flex" flexDirection="column">
                  <Box
                    bgcolor="#46CE9D"
                    borderRadius="5px"
                    height="10px"
                    marginBottom="2px"
                    minWidth="10px"
                    width="10px"
                  />
                  <Typography variant="caption">
                    <Trans i18nKey="cycling-insights.facilities.roads_quality.form.roughnesses.good_roads" />
                  </Typography>
                  <Typography variant="caption">
                    {Math.round(
                      (goodRoadsCount / (goodRoadsCount + mediumRoadsCount + badRoadsCount)) * 1000,
                    ) / 10}
                    %
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box
                    bgcolor="#FFD159"
                    borderRadius="5px"
                    height="10px"
                    marginBottom="2px"
                    minWidth="10px"
                    width="10px"
                  />
                  <Typography variant="caption">
                    <Trans i18nKey="cycling-insights.facilities.roads_quality.form.roughnesses.medium_roads" />
                  </Typography>
                  <Typography variant="caption">
                    {Math.round(
                      (mediumRoadsCount / (goodRoadsCount + mediumRoadsCount + badRoadsCount)) *
                        1000,
                    ) / 10}
                    %
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box
                    bgcolor="#F56B84"
                    borderRadius="5px"
                    height="10px"
                    marginBottom="2px"
                    minWidth="10px"
                    width="10px"
                  />
                  <Typography variant="caption">
                    <Trans i18nKey="cycling-insights.facilities.roads_quality.form.roughnesses.bad_roads" />
                  </Typography>
                  <Typography variant="caption">
                    {Math.round(
                      (badRoadsCount / (goodRoadsCount + mediumRoadsCount + badRoadsCount)) * 1000,
                    ) / 10}
                    %
                  </Typography>
                </Box>
              </>
            ) : (
              [0, 1, 2].map((key) => <Skeleton key={key} variant="text" width={128} />)
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default RoadsQualityChart;
