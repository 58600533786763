import { Counter } from '@geovelo-frontends/commons';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import counterIcon from '../../../../assets/images/counter.svg';
import Table from '../../../../components/table';
import useCountersTable from '../../../../hooks/counters-table';
import { IBicycleObservatoryPageContext, TCounterStats } from '../../context';

type TKey = 'type' | 'name' | 'traffic' | 'progression' | 'actions';

const keys: TKey[] = ['type', 'name', 'traffic', 'progression', 'actions'];

function CountersTable({
  counters: { list, selectedKey, selectKey, stats: _stats },
  period,
  onRemove,
  onEdit,
}: IBicycleObservatoryPageContext & {
  updateCounters: () => void;
  onRemove: (index: number) => void;
  onEdit: (counter: Counter) => void;
}): JSX.Element {
  const [stats, setStats] = useState<TCounterStats[]>();
  const [virtualCountersStats, setVirtualCountersStats] = useState<TCounterStats[]>();
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    rows,
    onPageChange,
    onRowsPerPageChange,
    onSortRequest,
  } = useCountersTable<TKey>({
    period,
    defaultOrderBy: 'progression',
    counters: list,
    stats,
    virtualCountersStats,
    onRemove,
    onEdit,
  });

  useEffect(() => {
    setStats(_stats?.filter((stat) => stat && !stat.isVirtual));
    setVirtualCountersStats(_stats?.filter((stat) => stat?.isVirtual));
  }, [_stats]);

  return (
    <>
      <Table
        count={list?.length}
        headers={{
          type: { label: <img src={counterIcon} />, width: 30 },
          name: { label: <Trans i18nKey="commons.name" /> },
          traffic: {
            label: (
              <Trans i18nKey="cycling-insights.usage.point_attendance.table.traffic_per_day" />
            ),
            width: 60,
            sortable: true,
          },
          progression: {
            label: <Trans i18nKey="cycling-insights.usage.point_attendance.table.progression" />,
            width: 100,
            sortable: true,
          },
          actions: {
            label: <Trans i18nKey="cycling-insights.usage.point_attendance.table.actions" />,
            width: 84,
          },
        }}
        keys={keys}
        onClick={selectKey}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        onSortRequest={onSortRequest}
        order={order}
        orderBy={orderBy}
        page={page}
        rows={rows}
        rowsPerPage={rowsPerPage}
        selectedKey={selectedKey !== null ? selectedKey : undefined}
        tableProps={{ sx: { tableLayout: 'fixed' } }}
        title="Cartographic contributions table"
      />
    </>
  );
}

export default CountersTable;
