import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { ComponentType } from 'react';

import DocumentationTab from '../tab';

import RequestTab from './request';
import ResponseTab from './response';

type TTab = 'route-computer-request' | 'route-computer-response';

const tabs: Array<{
  key: TTab;
  icon: JSX.Element;
  label: string;
  Tab: ComponentType<Record<string, never>>;
}> = [
  { key: 'route-computer-request', icon: <ArrowUpward />, label: 'Request', Tab: RequestTab },
  { key: 'route-computer-response', icon: <ArrowDownward />, label: 'Response', Tab: ResponseTab },
];

function RouteComputerDocumentation(): JSX.Element {
  return (
    <DocumentationTab
      defaultTab="route-computer-request"
      tabs={tabs}
      titleKey="cycling-insights.api.doc.title"
    />
  );
}

export default RouteComputerDocumentation;
