import { GeoveloInlineIcon } from '@geovelo-frontends/commons';
import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import background from '../../assets/images/macbook-login-background.svg';
import { CyclingInsightsIcon } from '../../components/icons/cycling-insights';
import { CyclingInsightsTextIcon } from '../../components/icons/cycling-insights-text';
import { environment } from '../../environment';

interface IProps {
  children: ReactNode;
}

function LoginLayout({ children }: IProps): JSX.Element {
  return (
    <Wrapper>
      <StyledBackground>
        <StyledTitle variant="h1">
          <Trans i18nKey="cycling-insights.login.description" />
        </StyledTitle>
        <ImageWrapper />
      </StyledBackground>
      <StyledContainer>
        <Box flexGrow={1} />
        <StyledHeader>
          <CyclingInsightsIcon color="inherit" sx={{ fontSize: '4em' }} />
          <CyclingInsightsTextIcon color="inherit" sx={{ fontSize: '2em', width: 'auto' }} />
        </StyledHeader>
        <StyledMain>
          <StyledContent>{children}</StyledContent>
        </StyledMain>
        <Box flexGrow={1} />
        <Box alignItems="center" display="flex" flexDirection="column" gap={1} paddingBottom={2}>
          <Box alignItems="center" display="flex" gap={1}>
            <Typography color="#6b7280" variant="body2">
              <Trans i18nKey="commons.offered_by" />
            </Typography>
            <a
              href={environment.frontendUrl}
              rel="noreferrer"
              style={{ display: 'block', height: 20 }}
              target="_blank"
            >
              <GeoveloInlineIcon style={{ height: '20px', width: 'auto' }} />
            </a>
          </Box>
          <Typography color="#6b7280" component={Link} to="/privacy-policy" variant="caption">
            <Trans i18nKey="commons.privacy_policy" />
          </Typography>
        </Box>
      </StyledContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: stretch;
  overflow-y: hidden;
`;

const StyledBackground = styled.div`
  background: linear-gradient(29.25deg, #326ac2 -33.1%, #68a549 105.52%);
  display: none;
  z-index: 1;
  flex-grow: 1;
  overflow: hidden;
  gap: 48px;

  @media (min-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;

const StyledTitle = styled(Typography)`
  && {
    font-size: 1.8rem;
    font-weight: bold;
    color: white;
    line-height: 2.5rem;
    margin: 0 max(10%, 48px);
  }
`;

const ImageWrapper = styled(Box)`
  align-self: flex-end;
  background-image: ${`url(${background})`};
  background-repeat: no-repeat;
  background-position: center left 24px;
  background-size: auto 100%;
  height: calc(100vh - 302px);
  width: calc(100vh - 172px);

  @media (min-height: 700px) {
    background-position: center left 10vw;
    background-size: 100% auto;
    width: 100%;
  }
`;

const StyledContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow-y: auto;
  width: 100%;
  z-index: 2;

  @media (min-width: 960px) {
    width: 50%;
  }
`;

const StyledHeader = styled.header`
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  gap: 16px;
  padding: 24px 24px 0;
`;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 48px 24px 24px;
`;

const StyledContent = styled.div`
  flex-shrink: 0;
`;

export default LoginLayout;
