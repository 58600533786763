import {
  Facilities,
  SectionStats,
  TSectionFacility,
  TSectionFeatureCollection,
} from '@geovelo-frontends/commons';

import { IPeriodFormProps } from '../components/form/period';
import { ISliderBounds, TSliderRange } from '../components/form/slider';
import { TCriterion } from '../hooks/sections-stats';
import { TSectionProperties, cyclabilityIndex } from '../models/sections';

export const splitRoadsMinZoom = 16;

export function getFilteredSections({
  sections,
  period,
  selectedFacilities,
  zoom,
  stats,
  primaryCriterion,
  bounds,
  currentRange,
  maxBounds,
  minFrequency = 0,
  defaultMinRange = 0,
}: {
  bounds?: ISliderBounds;
  currentRange?: TSliderRange;
  defaultMinRange?: number;
  maxBounds?: number;
  minFrequency?: number;
  period?: IPeriodFormProps;
  primaryCriterion?: TCriterion;
  sections: TSectionFeatureCollection;
  selectedFacilities?: TSectionFacility[];
  stats?: {
    [key: number]: {
      current?: SectionStats;
      prev?: SectionStats;
      lastMonthStats?: SectionStats;
      lastYearStats?: SectionStats;
    };
  };
  zoom?: number;
}): {
  bounds: ISliderBounds;
  currentRange: TSliderRange;
  filteredSections: GeoJSON.FeatureCollection<GeoJSON.LineString, TSectionProperties>;
  secondaryBounds: ISliderBounds;
  distancesByFacilities: { [key in Facilities | 'all' | 'none']: number };
  prevDistancesByFacilities: { [key in Facilities | 'all' | 'none']: number };
  averageSpeed: number;
  prevAverageSpeed: number;
  sectionsCountBySpeed: number[];
  sectionsComparedToAverage: number[];
} {
  const filteredSections: GeoJSON.FeatureCollection<GeoJSON.LineString, TSectionProperties> = {
    type: 'FeatureCollection',
    features: [],
  };
  const distancesByFacilities = {
    [Facilities.Cycleways]: 0,
    [Facilities.Greenways]: 0,
    [Facilities.Lanes]: 0,
    [Facilities.MixedFacilities]: 0,
    [Facilities.Opposites]: 0,
    [Facilities.SharedBusways]: 0,
    all: 0,
    none: 0,
  };
  const prevDistancesByFacilities = {
    [Facilities.Cycleways]: 0,
    [Facilities.Greenways]: 0,
    [Facilities.Lanes]: 0,
    [Facilities.MixedFacilities]: 0,
    [Facilities.Opposites]: 0,
    [Facilities.SharedBusways]: 0,
    all: 0,
    none: 0,
  };
  let totalSpeed = 0;
  let totalDistance = 0;
  let prevTotalSpeed = 0;
  let prevTotalDistance = 0;
  const sectionsCountBySpeed = period?.comparisonEnabled
    ? [0, 0, 0]
    : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  sections.features.forEach((section) => {
    if (typeof section.id === 'number') {
      let sectionStats = stats?.[section.id];
      if (primaryCriterion === 'cyclability' && !sectionStats)
        sectionStats = { current: new SectionStats(section.id) };
      if (!sectionStats) return;

      const accessibleOnward =
        !selectedFacilities ||
        selectedFacilities.includes(section.properties.facilityRight) ||
        selectedFacilities.includes(section.properties.closeFacility);
      const accessibleBackward =
        !selectedFacilities ||
        selectedFacilities.includes(section.properties.facilityLeft) ||
        selectedFacilities.includes(section.properties.closeFacility);
      if (
        !accessibleOnward &&
        !accessibleBackward &&
        primaryCriterion &&
        primaryCriterion !== 'discontinuity'
      )
        return;

      const {
        current: currentSectionStats,
        prev: prevSectionStats,
        lastYearStats,
        lastMonthStats,
      } = sectionStats;

      const sectionId =
        (currentSectionStats && currentSectionStats.sectionId) || prevSectionStats?.sectionId || -1;

      const {
        wayName,
        osmWayId,
        roadType,
        facilityLeft,
        facilityRight,
        bnacFacilityLeft,
        bnacFacilityRight,
      } = section.properties;
      const sectionProperties = { wayName, osmWayId, roadType, facilityLeft, facilityRight };

      let averageSpeedOnward: number | undefined = 0,
        currentAverageSpeedOnward,
        prevAverageSpeedOnward,
        averageSpeedBackward: number | undefined = 0,
        currentAverageSpeedBackward,
        prevAverageSpeedBackward,
        frequencyOnward: number | undefined = 0,
        currentFrequencyOnward,
        prevFrequencyOnward,
        frequencyBackward: number | undefined = 0,
        currentFrequencyBackward,
        prevFrequencyBackward,
        _roughnessesOnward: (number | null)[] | undefined,
        _roughnessesBackward: (number | null)[] | undefined,
        discontinuityOnwardId: number | undefined,
        discontinuityBackwardId: number | undefined,
        discontinuityOnwardIndex: number | undefined,
        discontinuityBackwardIndex: number | undefined,
        contactFacilityOnward: TSectionFacility | undefined,
        contactFacilityBackward: TSectionFacility | undefined,
        beforeFacilityOnward: boolean | undefined,
        beforeFacilityBackward: boolean | undefined,
        cyclabilityOnward: number | undefined,
        cyclabilityBackward: number | undefined,
        tracesCountOnward: number | undefined,
        tracesCountBackward: number | undefined,
        discontinuityTracesCountOnward: number | undefined,
        discontinuityTracesCountBackward: number | undefined,
        isOnward: boolean | undefined,
        isBackward: boolean | undefined,
        customColor: string | undefined;
      if (period?.comparisonEnabled) {
        currentAverageSpeedOnward = currentSectionStats?.averageSpeedOnward || 0;
        prevAverageSpeedOnward = prevSectionStats?.averageSpeedOnward || 0;
        averageSpeedOnward = currentAverageSpeedOnward - prevAverageSpeedOnward;
        currentAverageSpeedBackward = currentSectionStats?.averageSpeedBackward || 0;
        prevAverageSpeedBackward = prevSectionStats?.averageSpeedBackward || 0;
        averageSpeedBackward = currentAverageSpeedBackward - prevAverageSpeedBackward;
        currentFrequencyOnward = currentSectionStats?.frequencyOnward || 0;
        prevFrequencyOnward = prevSectionStats?.frequencyOnward || 0;
        frequencyOnward = currentFrequencyOnward - prevFrequencyOnward;
        currentFrequencyBackward = currentSectionStats?.frequencyBackward || 0;
        prevFrequencyBackward = prevSectionStats?.frequencyBackward || 0;
        frequencyBackward = currentFrequencyBackward - prevFrequencyBackward;
      } else if (currentSectionStats) {
        ({
          averageSpeedOnward,
          averageSpeedBackward,
          frequencyOnward,
          frequencyBackward,
          roughnessesOnward: _roughnessesOnward,
          roughnessesBackward: _roughnessesBackward,
          discontinuityOnwardId,
          discontinuityBackwardId,
          discontinuityOnwardIndex,
          discontinuityBackwardIndex,
          contactFacilityOnward,
          contactFacilityBackward,
          beforeFacilityOnward,
          beforeFacilityBackward,
          cyclabilityOnward,
          cyclabilityBackward,
          tracesCountOnward,
          tracesCountBackward,
          discontinuityTracesCountOnward,
          discontinuityTracesCountBackward,
          isOnward,
          isBackward,
          customColor,
        } = currentSectionStats);
      }

      const roughnessesOnward: Array<{ index: number; value: number | null }> = [];
      let lastRoughnessesOnwardValue: number | null = -1;
      _roughnessesOnward?.forEach((value, index) => {
        if (value !== lastRoughnessesOnwardValue) {
          roughnessesOnward.push({ index, value });
          lastRoughnessesOnwardValue = value;
        }
      });

      const roughnessesBackward: Array<{ index: number; value: number | null }> = [];
      let lastRoughnessesBackwardValue: number | null = -1;
      _roughnessesBackward?.forEach((value, index) => {
        if (value !== lastRoughnessesBackwardValue) {
          roughnessesBackward.push({ index, value });
          lastRoughnessesBackwardValue = value;
        }
      });

      if (!primaryCriterion) {
        if (isOnward) {
          filteredSections.features.push({
            ...section,
            properties: {
              id: sectionId,
              ...sectionProperties,
              direction: 'onward',
              customColor,
            },
          });
        }
        if (isBackward) {
          filteredSections.features.push({
            ...section,
            geometry: {
              ...section.geometry,
              coordinates: [...section.geometry.coordinates].reverse(),
            },
            properties: {
              id: sectionId,
              ...sectionProperties,
              direction: 'backward',
              customColor,
            },
          });
        }
      } else if (primaryCriterion === 'discontinuity') {
        if (discontinuityOnwardIndex !== undefined) {
          filteredSections.features.push({
            ...section,
            properties: {
              id: sectionId,
              discontinuityId: discontinuityOnwardId,
              discontinuityIndex: discontinuityOnwardIndex,
              contactFacility: contactFacilityOnward,
              beforeFacility: beforeFacilityOnward,
              cyclability: cyclabilityOnward,
              ...sectionProperties,
              facility:
                section.properties.facilityRight === section.properties.facilityLeft
                  ? section.properties.facilityRight
                  : undefined,
              direction: 'onward',
              tracesCount: tracesCountOnward,
              discontinuityTracesCount: discontinuityTracesCountOnward,
            },
          });
        }
        if (discontinuityBackwardIndex !== undefined) {
          filteredSections.features.push({
            ...section,
            id: `${section.id}-backward`,
            geometry: {
              ...section.geometry,
              coordinates: [...section.geometry.coordinates].reverse(),
            },
            properties: {
              id: sectionId,
              discontinuityId: discontinuityBackwardId,
              discontinuityIndex: discontinuityBackwardIndex,
              contactFacility: contactFacilityBackward,
              beforeFacility: beforeFacilityBackward,
              cyclability: cyclabilityBackward,
              ...sectionProperties,
              facility:
                section.properties.facilityRight === section.properties.facilityLeft
                  ? section.properties.facilityRight
                  : undefined,
              direction: 'backward',
              tracesCount: tracesCountBackward,
              discontinuityTracesCount: discontinuityTracesCountBackward,
            },
          });
        }
      } else if (primaryCriterion === 'extrapolation' && !!period) {
        const lastYear = period.values.current.clone();
        lastYear.from.add(-1, 'year');
        lastYear.to.add(-1, 'year');
        const currentExtrapolation = currentSectionStats?.extrapolation || 0;
        const prevExtrapolation = period.comparisonEnabled
          ? prevSectionStats?.extrapolation || 0
          : undefined;
        const lastMonthExtrapolation = lastMonthStats?.extrapolation || 0;
        const extrapolation = currentExtrapolation - (prevExtrapolation || 0);
        const facilities =
          getFacilities(section.properties.facilityRight) ===
          getFacilities(section.properties.facilityLeft)
            ? getFacilities(section.properties.facilityRight)
            : null;
        const distance = (section.properties.sectionLength || 0) * (currentExtrapolation || 0);
        const prevDistance = (section.properties.sectionLength || 0) * (prevExtrapolation || 0);
        if (facilities !== null) {
          distancesByFacilities[facilities] += distance;
          distancesByFacilities.all += distance;
          prevDistancesByFacilities[facilities] += prevDistance;
          prevDistancesByFacilities.all += prevDistance;
        }

        filteredSections.features.push({
          ...section,
          properties: {
            id: sectionId,
            extrapolation,
            sectionDistanceTraveled: distance,
            currentExtrapolation,
            prevExtrapolation: period.comparisonEnabled
              ? prevExtrapolation
              : lastMonthExtrapolation,
            ...sectionProperties,
            facility:
              section.properties.facilityRight === section.properties.facilityLeft
                ? section.properties.facilityRight
                : undefined,
          },
        });
      } else {
        if (zoom && zoom >= splitRoadsMinZoom) {
          if (primaryCriterion === 'cyclability') {
            if (bnacFacilityRight !== 'bicycle_no') {
              filteredSections.features.push({
                ...section,
                properties: {
                  id: sectionId,
                  cyclability: cyclabilityIndex
                    .get(roadType || 'unclassified')
                    ?.get(bnacFacilityRight || 'other'),
                  frequency: frequencyOnward || 0,
                  sectionFrequency: (frequencyOnward || 0) + (frequencyBackward || 0),
                  facility: section.properties.facilityRight,
                  ...sectionProperties,
                  direction: 'onward',
                },
              });
            }
            if (bnacFacilityLeft !== 'bicycle_no') {
              filteredSections.features.push({
                ...section,
                id: `${section.id}-backward`,
                geometry: {
                  ...section.geometry,
                  coordinates: [...section.geometry.coordinates].reverse(),
                },
                properties: {
                  id: sectionId,
                  cyclability: cyclabilityIndex
                    .get(roadType || 'unclassified')
                    ?.get(bnacFacilityLeft || 'other'),
                  frequency: frequencyBackward || 0,
                  sectionFrequency: (frequencyOnward || 0) + (frequencyBackward || 0),
                  facility: section.properties.facilityLeft,
                  ...sectionProperties,
                  direction: 'backward',
                },
              });
            }
          } else if (
            period?.comparisonEnabled
              ? ((accessibleOnward && currentFrequencyOnward) || 0) +
                  ((accessibleBackward && currentFrequencyBackward) || 0) >=
                  minFrequency ||
                ((accessibleOnward && prevFrequencyOnward) || 0) +
                  ((accessibleBackward && prevFrequencyBackward) || 0) >=
                  minFrequency
              : ((accessibleOnward && frequencyOnward) || 0) +
                  ((accessibleBackward && frequencyBackward) || 0) >=
                minFrequency
          ) {
            if (accessibleOnward) {
              if (primaryCriterion === 'roughness') {
                roughnessesOnward?.forEach(({ index, value: roughness }, _index) => {
                  filteredSections.features.push({
                    ...section,
                    id: `${section.id}-${_index}`,
                    geometry: {
                      ...section.geometry,
                      coordinates: section.geometry.coordinates.slice(
                        index,
                        roughnessesOnward[_index + 1]
                          ? roughnessesOnward[_index + 1].index + 1
                          : undefined,
                      ),
                    },
                    properties: {
                      id: sectionId,
                      roughness: roughness !== null ? Math.round(roughness * 100) / 100 : null,
                      frequency: frequencyOnward || 0,
                      sectionFrequency:
                        (frequencyOnward || 0) + ((accessibleBackward && frequencyBackward) || 0),
                      facility: section.properties.facilityRight,
                      ...sectionProperties,
                      direction: 'onward',
                    },
                  });
                });
              } else {
                const facilitiesRight = getFacilities(section.properties.facilityRight);
                const distance =
                  (section.properties.sectionLength || 0) *
                  (period?.comparisonEnabled ? currentFrequencyOnward || 0 : frequencyOnward || 0);
                const prevDistance =
                  (section.properties.sectionLength || 0) * (prevFrequencyOnward || 0);
                if (facilitiesRight !== null) {
                  distancesByFacilities[facilitiesRight] += distance;
                  distancesByFacilities.all += distance;
                  prevDistancesByFacilities[facilitiesRight] += prevDistance;
                  prevDistancesByFacilities.all += prevDistance;
                }
                totalSpeed +=
                  (section.properties.sectionLength || 0) *
                  ((period?.comparisonEnabled ? currentFrequencyOnward : frequencyOnward) || 0) *
                  ((period?.comparisonEnabled ? currentAverageSpeedOnward : averageSpeedOnward) ||
                    0);
                prevTotalSpeed +=
                  (section.properties.sectionLength || 0) *
                  ((period?.comparisonEnabled
                    ? prevFrequencyOnward
                    : lastYearStats?.frequencyOnward) || 0) *
                  ((period?.comparisonEnabled
                    ? prevAverageSpeedOnward
                    : lastYearStats?.averageSpeedOnward) || 0);
                totalDistance += distance;
                prevTotalDistance += period?.comparisonEnabled
                  ? prevDistance
                  : (section.properties.sectionLength || 0) * (lastYearStats?.frequencyOnward || 0);
                filteredSections.features.push({
                  ...section,
                  properties: {
                    id: sectionId,
                    averageSpeed: averageSpeedOnward,
                    currentAverageSpeed: currentAverageSpeedOnward,
                    distanceTraveled:
                      (section.properties.sectionLength || 0) * (frequencyOnward || 0),
                    sectionDistanceTraveled:
                      (section.properties.sectionLength || 0) * (frequencyOnward || 0) +
                      (section.properties.sectionLength || 0) *
                        ((accessibleBackward && frequencyBackward) || 0),
                    prevAverageSpeed: prevAverageSpeedOnward,
                    frequency: frequencyOnward,
                    currentFrequency: currentFrequencyOnward,
                    prevFrequency: prevFrequencyOnward,
                    lastYearFrequency: lastYearStats ? lastYearStats.frequencyOnward : undefined,
                    facility: section.properties.facilityRight,
                    ...sectionProperties,
                    direction: 'onward',
                  },
                });
              }
            }

            if (accessibleBackward) {
              if (primaryCriterion === 'roughness') {
                const coordinates = [...section.geometry.coordinates].reverse();
                roughnessesBackward?.forEach(({ index, value: roughness }, _index) => {
                  filteredSections.features.push({
                    ...section,
                    id: `${section.id}-${_index}-backward`,
                    geometry: {
                      ...section.geometry,
                      coordinates: coordinates.slice(
                        index,
                        roughnessesBackward[_index + 1]
                          ? roughnessesBackward[_index + 1].index + 1
                          : undefined,
                      ),
                    },
                    properties: {
                      id: sectionId,
                      roughness: roughness !== null ? Math.round(roughness * 100) / 100 : null,
                      frequency: frequencyBackward || 0,
                      sectionFrequency:
                        (frequencyBackward || 0) + ((accessibleOnward && frequencyOnward) || 0),
                      facility: section.properties.facilityLeft,
                      ...sectionProperties,
                      direction: 'backward',
                    },
                  });
                });
              } else {
                const facilitiesLeft = getFacilities(section.properties.facilityLeft);
                const distance =
                  (section.properties.sectionLength || 0) *
                  (period?.comparisonEnabled
                    ? currentFrequencyBackward || 0
                    : frequencyBackward || 0);
                const prevDistance =
                  (section.properties.sectionLength || 0) * (prevFrequencyBackward || 0);
                if (facilitiesLeft !== null) {
                  distancesByFacilities[facilitiesLeft] += distance;
                  distancesByFacilities.all += distance;
                  prevDistancesByFacilities[facilitiesLeft] += prevDistance;
                  prevDistancesByFacilities.all += prevDistance;
                }
                totalSpeed +=
                  (section.properties.sectionLength || 0) *
                  ((period?.comparisonEnabled ? currentFrequencyBackward : frequencyBackward) ||
                    0) *
                  ((period?.comparisonEnabled
                    ? currentAverageSpeedBackward
                    : averageSpeedBackward) || 0);
                prevTotalSpeed +=
                  (section.properties.sectionLength || 0) *
                  ((period?.comparisonEnabled
                    ? prevFrequencyBackward
                    : lastYearStats?.frequencyBackward) || 0) *
                  ((period?.comparisonEnabled
                    ? prevAverageSpeedBackward
                    : lastYearStats?.averageSpeedBackward) || 0);
                totalDistance += distance;
                prevTotalDistance += period?.comparisonEnabled
                  ? prevDistance
                  : (section.properties.sectionLength || 0) *
                    (lastYearStats?.frequencyBackward || 0);
                filteredSections.features.push({
                  ...section,
                  id: `${section.id}-backward`,
                  geometry: {
                    ...section.geometry,
                    coordinates: [...section.geometry.coordinates].reverse(),
                  },
                  properties: {
                    id: sectionId,
                    averageSpeed: averageSpeedBackward,
                    currentAverageSpeed: currentAverageSpeedBackward,
                    distanceTraveled:
                      (section.properties.sectionLength || 0) * (frequencyBackward || 0),
                    sectionDistanceTraveled:
                      (section.properties.sectionLength || 0) * (frequencyBackward || 0) +
                      (section.properties.sectionLength || 0) *
                        ((accessibleOnward && frequencyOnward) || 0),
                    prevAverageSpeed: prevAverageSpeedBackward,
                    frequency: frequencyBackward,
                    currentFrequency: currentFrequencyBackward,
                    prevFrequency: prevFrequencyBackward,
                    lastYearFrequency: lastYearStats?.frequencyBackward || 0,
                    facility: section.properties.facilityLeft,
                    ...sectionProperties,
                    direction: 'backward',
                  },
                });
              }
            }

            if (primaryCriterion !== 'roughness') {
              if (period?.comparisonEnabled) {
                const averageSpeed =
                  ((section.properties.sectionLength || 0) *
                    (((accessibleOnward && currentFrequencyOnward) || 0) *
                      (currentAverageSpeedOnward || 0) +
                      ((accessibleBackward && currentFrequencyBackward) || 0) *
                        (currentAverageSpeedBackward || 0))) /
                    ((section.properties.sectionLength || 0) *
                      (((accessibleOnward && currentFrequencyOnward) || 0) +
                        ((accessibleBackward && currentFrequencyBackward) || 0))) -
                  ((section.properties.sectionLength || 0) *
                    (((accessibleOnward && prevFrequencyOnward) || 0) *
                      (prevAverageSpeedOnward || 0) +
                      ((accessibleBackward && prevFrequencyBackward) || 0) *
                        (prevAverageSpeedBackward || 0))) /
                    ((section.properties.sectionLength || 0) *
                      (((accessibleOnward && prevFrequencyOnward) || 0) +
                        ((accessibleBackward && prevFrequencyBackward) || 0)));
                if (averageSpeed < -2) sectionsCountBySpeed[0] += 1;
                else if (averageSpeed > 2) sectionsCountBySpeed[2] += 1;
                else sectionsCountBySpeed[1] += 1;
              } else {
                const averageSpeed =
                  ((section.properties.sectionLength || 0) *
                    (((accessibleOnward && frequencyOnward) || 0) * (averageSpeedOnward || 0) +
                      ((accessibleBackward && frequencyBackward) || 0) *
                        (averageSpeedBackward || 0))) /
                  ((section.properties.sectionLength || 0) *
                    (((accessibleOnward && frequencyOnward) || 0) +
                      ((accessibleBackward && frequencyBackward) || 0)));
                getSpeedIntervalle(averageSpeed, sectionsCountBySpeed);
              }
            }
          }
        } else if (primaryCriterion === 'cyclability') {
          if (bnacFacilityLeft !== 'bicycle_no' || bnacFacilityRight !== 'bicycle_no') {
            const cyclabilityValue =
              bnacFacilityLeft !== 'bicycle_no'
                ? bnacFacilityRight !== 'bicycle_no'
                  ? Math.min(
                      cyclabilityIndex
                        .get(roadType || 'unclassified')
                        ?.get(bnacFacilityRight || 'other') || 1,
                      cyclabilityIndex
                        .get(roadType || 'unclassified')
                        ?.get(bnacFacilityLeft || 'other') || 1,
                    )
                  : cyclabilityIndex
                      .get(roadType || 'unclassified')
                      ?.get(bnacFacilityLeft || 'other')
                : cyclabilityIndex
                    .get(roadType || 'unclassified')
                    ?.get(bnacFacilityRight || 'other');
            filteredSections.features.push({
              ...section,
              properties: {
                id: sectionId,
                cyclability: cyclabilityValue,
                frequency: (frequencyOnward || 0) + (frequencyBackward || 0),
                sectionFrequency: (frequencyOnward || 0) + (frequencyBackward || 0),
                facility:
                  section.properties.facilityLeft === section.properties.facilityRight
                    ? section.properties.facilityRight
                    : undefined,
                ...sectionProperties,
              },
            });
          }
        } else if (
          period?.comparisonEnabled
            ? (currentFrequencyOnward || 0) + (currentFrequencyBackward || 0) >= minFrequency ||
              (prevFrequencyOnward || 0) + (prevFrequencyBackward || 0) >= minFrequency
            : ((accessibleOnward && frequencyOnward) || 0) +
                ((accessibleBackward && frequencyBackward) || 0) >=
              minFrequency
        ) {
          if (primaryCriterion === 'roughness') {
            const roughnesses: Array<{ index: number; value: number | null }> = [];
            let lastRoughnessesValue: number | null = -1;
            _roughnessesOnward?.forEach((roughnessOnward, index) => {
              const roughnessBackward =
                _roughnessesBackward?.[_roughnessesBackward.length - 1 - index] || null;
              const roughness =
                roughnessOnward !== null && roughnessBackward !== null
                  ? Math.round(((roughnessOnward + roughnessBackward) / 2) * 100) / 100
                  : roughnessOnward !== null
                    ? roughnessOnward
                    : roughnessBackward;
              if (roughness !== lastRoughnessesValue) {
                roughnesses.push({ index, value: roughness });
                lastRoughnessesValue = roughness;
              }
            });

            roughnesses?.forEach(({ index, value: roughness }, _index) => {
              if (roughness !== null) {
                filteredSections.features.push({
                  ...section,
                  id: `${section.id}-${_index}`,
                  geometry: {
                    ...section.geometry,
                    coordinates: section.geometry.coordinates.slice(
                      index,
                      roughnessesOnward[_index + 1]
                        ? roughnessesOnward[_index + 1].index + 1
                        : undefined,
                    ),
                  },
                  properties: {
                    id: sectionId,
                    roughness,
                    ...sectionProperties,
                  },
                });
              }
            });

            filteredSections.features.push({
              ...section,
              properties: {
                id: sectionId,
                roughness:
                  Math.round(
                    (roughnesses.reduce<number>((res, { value }) => res + (value || 0), 0) /
                      roughnesses.filter(({ value }) => value !== null).length) *
                      100,
                  ) / 100,
                frequency:
                  ((accessibleOnward && frequencyOnward) || 0) +
                  ((accessibleBackward && frequencyBackward) || 0),
                ...sectionProperties,
              },
            });
          } else {
            const facilitiesRight = getFacilities(section.properties.facilityRight);
            const facilitiesLeft = getFacilities(section.properties.facilityLeft);
            const distanceRight =
              (section.properties.sectionLength || 0) *
              ((accessibleOnward &&
                (period?.comparisonEnabled ? currentFrequencyOnward : frequencyOnward)) ||
                0);
            const distanceLeft =
              (section.properties.sectionLength || 0) *
              ((accessibleBackward &&
                (period?.comparisonEnabled ? currentFrequencyBackward : frequencyBackward)) ||
                0);
            const prevDistanceRight =
              (section.properties.sectionLength || 0) *
              ((accessibleOnward && prevFrequencyOnward) || 0);
            const prevDistanceLeft =
              (section.properties.sectionLength || 0) *
              ((accessibleBackward && prevFrequencyBackward) || 0);
            if (facilitiesRight !== null) {
              distancesByFacilities[facilitiesRight] += distanceRight;
              distancesByFacilities.all += distanceRight;
              prevDistancesByFacilities[facilitiesRight] += prevDistanceRight;
              prevDistancesByFacilities.all += prevDistanceRight;
            }
            if (facilitiesLeft !== null) {
              distancesByFacilities[facilitiesLeft] += distanceLeft;
              distancesByFacilities.all += distanceLeft;
              prevDistancesByFacilities[facilitiesLeft] += prevDistanceLeft;
              prevDistancesByFacilities.all += prevDistanceLeft;
            }
            const averageSpeed =
              (section.properties.sectionLength || 0) *
              (((accessibleOnward &&
                (period?.comparisonEnabled ? currentFrequencyOnward : frequencyOnward)) ||
                0) *
                ((period?.comparisonEnabled ? currentAverageSpeedOnward : averageSpeedOnward) ||
                  0) +
                ((accessibleBackward &&
                  (period?.comparisonEnabled ? currentFrequencyBackward : frequencyBackward)) ||
                  0) *
                  ((period?.comparisonEnabled
                    ? currentAverageSpeedBackward
                    : averageSpeedBackward) || 0));
            const averageSpeedDiff =
              (section.properties.sectionLength || 0) *
              (((accessibleOnward && frequencyOnward) || 0) * (averageSpeedOnward || 0) +
                ((accessibleBackward && frequencyBackward) || 0) * (averageSpeedBackward || 0));

            if (
              (accessibleOnward && frequencyOnward) ||
              (accessibleBackward && frequencyBackward)
            ) {
              if (period?.comparisonEnabled) {
                const comparedAverageSpeed =
                  averageSpeed /
                    ((section.properties.sectionLength || 0) *
                      (((accessibleOnward && currentFrequencyOnward) || 0) +
                        ((accessibleBackward && currentFrequencyBackward) || 0))) -
                  ((section.properties.sectionLength || 0) *
                    (((accessibleOnward && prevFrequencyOnward) || 0) *
                      (prevAverageSpeedOnward || 0) +
                      ((accessibleBackward && prevFrequencyBackward) || 0) *
                        (prevAverageSpeedBackward || 0))) /
                    ((section.properties.sectionLength || 0) *
                      (((accessibleOnward && prevFrequencyOnward) || 0) +
                        ((accessibleBackward && prevFrequencyBackward) || 0)));
                if (comparedAverageSpeed < -2) sectionsCountBySpeed[0] += 1;
                else if (comparedAverageSpeed > 2) sectionsCountBySpeed[2] += 1;
                else sectionsCountBySpeed[1] += 1;
              } else
                getSpeedIntervalle(
                  averageSpeed /
                    ((section.properties.sectionLength || 0) *
                      (((accessibleOnward && frequencyOnward) || 0) +
                        ((accessibleBackward && frequencyBackward) || 0))),
                  sectionsCountBySpeed,
                );
            }
            totalSpeed += averageSpeed;
            prevTotalSpeed +=
              (section.properties.sectionLength || 0) *
              (((accessibleOnward &&
                (period?.comparisonEnabled
                  ? prevFrequencyOnward
                  : lastYearStats?.frequencyOnward)) ||
                0) *
                ((period?.comparisonEnabled
                  ? prevAverageSpeedOnward
                  : lastYearStats?.averageSpeedOnward) || 0) +
                ((accessibleBackward &&
                  (period?.comparisonEnabled
                    ? prevFrequencyBackward
                    : lastYearStats?.frequencyBackward)) ||
                  0) *
                  ((period?.comparisonEnabled
                    ? prevAverageSpeedBackward
                    : lastYearStats?.averageSpeedBackward) || 0));
            totalDistance += distanceRight + distanceLeft;
            prevTotalDistance += period?.comparisonEnabled
              ? prevDistanceLeft + prevDistanceRight
              : (section.properties.sectionLength || 0) *
                (((accessibleOnward && lastYearStats?.frequencyOnward) || 0) +
                  ((accessibleBackward && lastYearStats?.frequencyBackward) || 0));
            filteredSections.features.push({
              ...section,
              properties: {
                id: sectionId,
                averageSpeed:
                  averageSpeedDiff /
                    ((section.properties.sectionLength || 0) *
                      (((accessibleOnward && frequencyOnward) || 0) +
                        ((accessibleBackward && frequencyBackward) || 0))) || 0,
                currentAverageSpeed:
                  (((accessibleOnward && currentAverageSpeedOnward) || 0) +
                    ((accessibleBackward && currentAverageSpeedBackward) || 0)) /
                  (accessibleOnward &&
                  accessibleBackward &&
                  currentFrequencyOnward &&
                  currentFrequencyBackward
                    ? 2
                    : 1),
                prevAverageSpeed:
                  (((accessibleOnward && prevAverageSpeedOnward) || 0) +
                    ((accessibleBackward && prevAverageSpeedBackward) || 0)) /
                  (accessibleOnward &&
                  accessibleBackward &&
                  prevFrequencyOnward &&
                  prevFrequencyBackward
                    ? 2
                    : 1),
                distanceTraveled:
                  (section.properties.sectionLength || 0) *
                  (((accessibleOnward && frequencyOnward) || 0) +
                    ((accessibleBackward && frequencyBackward) || 0)),
                facility:
                  section.properties.facilityLeft === section.properties.facilityRight
                    ? section.properties.facilityRight
                    : undefined,
                frequency:
                  ((accessibleOnward && frequencyOnward) || 0) +
                  ((accessibleBackward && frequencyBackward) || 0),
                currentFrequency:
                  ((accessibleOnward && currentFrequencyOnward) || 0) +
                  ((accessibleBackward && currentFrequencyBackward) || 0),
                prevFrequency:
                  ((accessibleOnward &&
                    (period?.comparisonEnabled
                      ? prevFrequencyOnward
                      : lastMonthStats?.frequencyOnward)) ||
                    0) +
                  ((accessibleBackward &&
                    (period?.comparisonEnabled
                      ? prevFrequencyBackward
                      : lastMonthStats?.frequencyBackward)) ||
                    0),
                lastYearFrequency:
                  ((accessibleOnward && lastYearStats?.frequencyOnward) || 0) +
                  ((accessibleBackward && lastYearStats?.frequencyBackward) || 0),
                ...sectionProperties,
              },
            });
          }
        }
      }
    }
  });

  const secondaryCriterion: TCriterion | null =
    !primaryCriterion ||
    primaryCriterion === 'discontinuity' ||
    primaryCriterion === 'roughness' ||
    primaryCriterion === 'extrapolation'
      ? null
      : primaryCriterion === 'averageSpeed' || primaryCriterion === 'cyclability'
        ? 'frequency'
        : 'averageSpeed';
  let max = maxBounds !== undefined ? maxBounds : primaryCriterion === 'averageSpeed' ? 30 : 1;
  let min = 0;
  let secondaryMax = 0;
  let secondaryMin = Infinity;

  const sectionsComparedToAverage = [0, 0, 0];

  if (primaryCriterion === 'discontinuity') max = filteredSections.features.length;
  else if (primaryCriterion) {
    filteredSections.features.forEach(({ properties }) => {
      const { [primaryCriterion]: primaryCriterionValue, averageSpeed } = properties;
      if (averageSpeed) {
        if (averageSpeed < 13) sectionsComparedToAverage[0] += 1;
        else if (averageSpeed > 15) sectionsComparedToAverage[2] += 1;
        else sectionsComparedToAverage[1] += 1;
      }

      if (primaryCriterionValue || primaryCriterionValue === 0) {
        max = Math.max(max, primaryCriterionValue);
        min = Math.min(min, primaryCriterionValue);
      }
      if (secondaryCriterion) {
        const { [secondaryCriterion]: secondaryCriterionValue } = properties;
        if (secondaryCriterionValue || secondaryCriterionValue === 0) {
          secondaryMax = Math.max(secondaryMax, secondaryCriterionValue);
          secondaryMin = Math.min(secondaryMin, secondaryCriterionValue);
        }
      }
    });
  }

  filteredSections.features.sort(
    (a, b) => (b.properties.distanceTraveled || 0) - (a.properties.distanceTraveled || 0),
  );

  max = Math.ceil(max);
  min = Math.floor(min);
  secondaryMax = Math.ceil(secondaryMax);
  secondaryMin = Math.floor(secondaryMin);

  const hadCustomPrevRange = bounds &&
    currentRange && {
      min: currentRange[0] !== bounds.min,
      max: currentRange[1] !== bounds.max && currentRange[1] !== 1,
    };

  if (period?.comparisonEnabled) {
    return {
      filteredSections,
      bounds: { min, max },
      secondaryBounds: { min: secondaryMin, max: secondaryMax },
      currentRange: [
        Math.min(
          hadCustomPrevRange?.min && currentRange ? Math.max(currentRange[0], min) : min,
          max,
        ),
        hadCustomPrevRange?.max && currentRange ? Math.min(currentRange[1], max) : max,
      ],
      averageSpeed: totalDistance === 0 ? 0 : totalSpeed / totalDistance,
      prevAverageSpeed: prevTotalDistance === 0 ? 0 : prevTotalSpeed / prevTotalDistance,
      distancesByFacilities,
      prevDistancesByFacilities,
      sectionsCountBySpeed,
      sectionsComparedToAverage,
    };
  }
  return {
    filteredSections,
    bounds: { min: primaryCriterion === 'cyclability' ? 1 : 0, max },
    secondaryBounds: { min: secondaryMin, max: secondaryMax },
    currentRange: [
      Math.min(hadCustomPrevRange?.min && currentRange ? currentRange[0] : defaultMinRange, max),
      hadCustomPrevRange?.max && currentRange ? Math.min(currentRange[1], max) : max,
    ],
    averageSpeed: totalDistance === 0 ? 0 : totalSpeed / totalDistance,
    prevAverageSpeed: prevTotalDistance === 0 ? 0 : prevTotalSpeed / prevTotalDistance,
    distancesByFacilities,
    prevDistancesByFacilities,
    sectionsCountBySpeed,
    sectionsComparedToAverage,
  };
}

function getFacilities(facility: TSectionFacility): Facilities | 'none' | null {
  switch (facility) {
    case 'cycleway':
      return Facilities.Cycleways;
    case 'greenway':
      return Facilities.Greenways;
    case 'lane':
      return Facilities.Lanes;
    case 'mixedfacilities':
      return Facilities.MixedFacilities;
    case 'none':
      return 'none';
    case 'opposite':
      return Facilities.Opposites;
    case 'sharebusway':
      return Facilities.SharedBusways;
  }
  return null;
}

function getSpeedIntervalle(speed: number, sectionsCountBySpeed: number[]) {
  switch (true) {
    case speed < 11:
      sectionsCountBySpeed[0] += 1;
      break;
    case speed >= 11 && speed < 13:
      sectionsCountBySpeed[1] += 1;
      break;
    case speed >= 13 && speed < 15:
      sectionsCountBySpeed[2] += 1;
      break;
    case speed >= 15 && speed < 17:
      sectionsCountBySpeed[3] += 1;
      break;
    case speed >= 17 && speed < 19:
      sectionsCountBySpeed[4] += 1;
      break;
    case speed >= 19 && speed < 21:
      sectionsCountBySpeed[5] += 1;
      break;
    case speed >= 21 && speed < 23:
      sectionsCountBySpeed[6] += 1;
      break;
    case speed >= 23 && speed < 25:
      sectionsCountBySpeed[7] += 1;
      break;
    case speed >= 25 && speed < 27:
      sectionsCountBySpeed[8] += 1;
      break;
    case speed >= 27 && speed < 29:
      sectionsCountBySpeed[9] += 1;
      break;
    case speed >= 29:
      sectionsCountBySpeed[10] += 1;
      break;
  }
}
