import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function BlackSpotTooltip(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Typography fontWeight={600} variant="body2">
      {t('cycling-insights.usage.accidentology.black_spot')}
    </Typography>
  );
}

export default BlackSpotTooltip;
