import {
  GeogroupService,
  IPartnerFlowsStats,
  PartnerService,
  Period,
  prevMonth,
  useAmplitudeTracker,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import {
  ChevronLeft,
  ChevronRight,
  Done,
  Event as EventIcon,
  MapOutlined,
  SvgIconComponent,
} from '@mui/icons-material';
import { Avatar, Box, Hidden, IconButton, SvgIconProps, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppContext } from '../../app/context';
import Button from '../../components/button';
import { ArchesIcon } from '../../components/icons/arches';
import DownloadIcon from '../../components/icons/download';
import EnvironmentIcon from '../../components/icons/environment';
import IndicatorsIcon from '../../components/icons/indicators';
import { environment } from '../../environment';
import usePeriod from '../../hooks/period';
import { getRelativeProgression } from '../../utils/stats';

import Cyclability from './components/cyclability';
import Environment from './components/environment';
import Journeys from './components/journeys';
import Parkings from './components/parkings';
import Security from './components/security';
import { IActivityStats, TStatKey } from './components/types';

const now = moment();

type TItemKey = 'journeys' | 'security' | 'environment' | 'parkings' | 'cyclability';

interface IItem {
  Component: (props: {
    activityStatistics?: IActivityStats;
    flowsStatistics?: {
      current: IPartnerFlowsStats | null;
      prev: IPartnerFlowsStats | null;
    } | null;
    isCompany?: boolean;
    period: Period;
  }) => JSX.Element;
  Icon: ((props: SvgIconProps) => JSX.Element) | SvgIconComponent;
  key: TItemKey;
  titleKey: string;
}

const keyBase = 'cycling-insights.home.items';
const items: IItem[] = [
  {
    key: 'environment',
    Icon: EnvironmentIcon,
    titleKey: `${keyBase}.environment.title`,
    Component: Environment,
  },
  {
    key: 'parkings',
    Icon: ArchesIcon,
    titleKey: `${keyBase}.parkings.title`,
    Component: Parkings,
  },
  {
    key: 'security',
    Icon: MapOutlined,
    titleKey: `${keyBase}.security.title`,
    Component: Security,
  },
  {
    key: 'journeys',
    Icon: IndicatorsIcon,
    titleKey: `${keyBase}.journeys.title`,
    Component: Journeys,
  },
  {
    key: 'cyclability',
    Icon: IndicatorsIcon,
    titleKey: `${keyBase}.cyclability.title`,
    Component: Cyclability,
  },
];

const defaultPeriod = moment().get('date') < 8 ? prevMonth.getPrevPeriod() : prevMonth.clone();

function HomePage(): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const [period, setPeriod] = useState(defaultPeriod);
  const [activityStatistics, setActivityStatistics] = useState<IActivityStats>();
  const [flowsStatistics, setFlowsStatistics] = useState<{
    current: IPartnerFlowsStats | null;
    prev: IPartnerFlowsStats | null;
  } | null>();
  const [isCompany, setIsCompany] = useState<boolean>();
  const [hasJoinedHighlightedEvent, setHasJoinedHighlightedEvent] = useState<boolean>();
  const {
    app: { highlightedEvent },
    user: { current: currentUser },
    partner: { current: currentPartner, activeContracts, contractTemplates },
    actions: { setCurrentTab },
  } = useContext(AppContext);
  const { getTitle } = usePeriod();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { trackEvent } = useAmplitudeTracker();
  const {
    cancellablePromise: cancellableChallengePromise,
    cancelPromises: cancelChallengePromise,
  } = useCancellablePromise();
  const {
    cancellablePromise: cancellableActivityStatisticsPromise,
    cancelPromises: cancelActivityStatisticsPromise,
  } = useCancellablePromise();
  const {
    cancellablePromise: cancellableFlowsStatisticsPromise,
    cancelPromises: cancelFlowsStatisticsPromise,
  } = useCancellablePromise();

  useEffect(() => {
    setCurrentTab(undefined);
    setInitialized(true);
  }, []);

  useEffect(() => {
    period.locale(moment.locale());
  }, [moment.locale()]);

  useEffect(() => {
    if (currentUser && currentPartner) {
      trackEvent('Page Visited', {
        pathname: '/homepage',
        partner_id: currentPartner.id,
        partner_code: currentPartner.code,
      });
    }
  }, [currentUser, currentPartner]);

  useEffect(() => {
    if (!initialized) return;

    if (currentPartner?.hasActivityStatistics) getActivityStatistics();
    if (currentPartner?.dashboardTabsPermissions.usageRoadsUse !== 'none') getFlowsStatistics();

    return () => {
      cancelActivityStatisticsPromise();
      cancelFlowsStatisticsPromise();
    };
  }, [initialized, currentPartner, period]);

  useEffect(() => {
    if (!initialized) return;

    if (contractTemplates) {
      const companyContractTemplate = contractTemplates?.find(({ code }) => code === 'entreprise');

      setIsCompany(
        (companyContractTemplate &&
          Boolean(
            activeContracts?.find(
              ({ contractTemplateId }) => contractTemplateId === companyContractTemplate.id,
            ),
          )) ||
          false,
      );
    } else if (currentUser && !currentUser.isGeovelo) {
      setIsCompany(false);
    }

    return () => setIsCompany(undefined);
  }, [initialized, contractTemplates, activeContracts]);

  useEffect(() => {
    async function getChallenge() {
      if (!currentPartner?.geoGroupId || !highlightedEvent) return;

      try {
        const { challenges } = await cancellableChallengePromise(
          GeogroupService.getChallenges(currentPartner.geoGroupId, {
            page: 1,
            pageSize: 100,
            period: new Period('custom', highlightedEvent.startDate, highlightedEvent.endDate),
          }),
        );

        setHasJoinedHighlightedEvent(
          Boolean(challenges.find(({ eventId }) => eventId === highlightedEvent.id)),
        );
      } catch (err) {
        if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
          console.error(err);
        }
      }
    }

    if (!initialized) return;

    if (highlightedEvent) getChallenge();

    return () => {
      setHasJoinedHighlightedEvent(undefined);
      cancelChallengePromise();
    };
  }, [initialized, highlightedEvent]);

  async function getActivityStatistics() {
    setActivityStatistics(undefined);

    if (!currentPartner?.geoGroupId) return;

    try {
      const [prev, current] = await cancellableActivityStatisticsPromise(
        Promise.all([
          GeogroupService.getActivityStats(currentPartner, period.getPrevPeriod().toIPeriod()),
          GeogroupService.getActivityStats(currentPartner, period.toIPeriod()),
        ]),
      );

      let currentNbComputedRoutes = 0,
        currentDistance = 0,
        currentDuration = 0,
        currentNbRoutes = 0;

      current.data.forEach(({ nbComputedRoutes, distance, duration, nbRoutes }) => {
        currentNbComputedRoutes += nbComputedRoutes;
        currentDistance += distance;
        currentDuration += duration;
        currentNbRoutes += nbRoutes;
      });

      const progressions: { [key in TStatKey]: number } = {
        nbComputedRoutes: getRelativeProgression(
          currentNbComputedRoutes,
          prev.data,
          { current: period, prev: period.getPrevPeriod() },
          'nbComputedRoutes',
        ),
        distance: getRelativeProgression(
          currentDistance,
          prev.data,
          { current: period, prev: period.getPrevPeriod() },
          'distance',
        ),
        duration: getRelativeProgression(
          currentDuration,
          prev.data,
          { current: period, prev: period.getPrevPeriod() },
          'duration',
        ),
        nbRoutes: getRelativeProgression(
          currentNbRoutes,
          prev.data,
          { current: period, prev: period.getPrevPeriod() },
          'nbRoutes',
        ),
      };

      setActivityStatistics({ current, prev, progressions });
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.activity_statistics.server_error'), {
          variant: 'error',
        });
      }
    }
  }

  async function getFlowsStatistics() {
    setFlowsStatistics(undefined);

    if (!currentPartner) return;

    try {
      const [prevStats, currentStats] = await cancellableFlowsStatisticsPromise(
        Promise.all([
          PartnerService.getFlowsStats(currentPartner.id, { period: period.getPrevPeriod() }),
          PartnerService.getFlowsStats(currentPartner.id, { period }),
        ]),
      );

      setFlowsStatistics({ prev: prevStats, current: currentStats });
    } catch (err) {
      if (
        (err instanceof Error && err?.name !== 'CancelledPromiseError') ||
        (typeof err === 'object' && err && 'detail' in err)
      ) {
        setFlowsStatistics(null);
        enqueueSnackbar(t('cycling-insights.usage.roads_use.server_error'), {
          variant: 'error',
        });
      }
    }
  }

  if (!currentPartner || isCompany === undefined) return <></>;

  return (
    <Box flexGrow={1} sx={{ backgroundColor: { xs: '#fff', md: 'whitesmoke' }, overflowY: 'auto' }}>
      <Box
        display="flex"
        flexDirection="column"
        gap={{ xs: 3, md: 5 }}
        minHeight="calc(100% - 80px)"
        padding={{ xs: 3, md: 5 }}
      >
        {isCompany && (
          <Box
            alignItems="center"
            borderRadius={6}
            display="flex"
            flexDirection="row"
            gap={{ xs: 3, md: 5 }}
            sx={{ backgroundColor: '#03825c', color: '#fff', padding: { xs: 3, md: '24px 40px' } }}
          >
            {currentPartner.icon && (
              <Hidden mdDown>
                <img
                  src={currentPartner.icon}
                  style={{ flexShrink: 0, width: 102, borderRadius: 51 }}
                />
              </Hidden>
            )}
            <Box display="flex" flexDirection="column" flexGrow={1} gap={1}>
              <Typography fontSize="1.5em" fontWeight="700" variant="h6">
                Bienvenue {currentPartner.title} !
              </Typography>
              <Typography fontSize="1em">
                Geovelo vous accompagne pour développer la pratique du vélo et réduire l’impact
                carbone des déplacements domicile-travail de vos collaborateurs, en toute sécurité !
              </Typography>
            </Box>
          </Box>
        )}
        <Box
          alignItems="center"
          borderRadius={6}
          display="flex"
          flexDirection="column"
          flexGrow={1}
          flexShrink={0}
          gap={6}
          paddingX={{ xs: 0, md: 8 }}
          paddingY={{ xs: 0, md: 5 }}
          sx={{ backgroundColor: '#fff' }}
        >
          <Box alignItems="center" display="flex" flexDirection="column" flexShrink={0} gap={1}>
            <Typography fontSize="1.5em" variant="h6">
              <Trans i18nKey="cycling-insights.home.title" />
            </Typography>
            <Box alignItems="center" display="flex" gap={2}>
              <IconButton onClick={() => setPeriod(period.getPrevPeriod())} size="small">
                <ChevronLeft />
              </IconButton>
              <Typography
                fontSize="1em"
                sx={{
                  '&::first-letter': {
                    textTransform: 'capitalize',
                  },
                }}
              >
                {getTitle(period)}
              </Typography>
              <IconButton
                disabled={period.from.isSame(defaultPeriod.from, 'date')}
                onClick={() => setPeriod(period.getNextPeriod())}
                size="small"
              >
                <ChevronRight />
              </IconButton>
            </Box>
          </Box>
          {currentPartner.dashboardTabsPermissions.communityChallenges !== 'none' &&
          environment.highlightedEventId ? (
            highlightedEvent &&
            highlightedEvent.endDate.isAfter(now) &&
            hasJoinedHighlightedEvent !== undefined && (
              <Box
                alignItems="center"
                borderRadius={1}
                display="flex"
                gap={2}
                paddingX={3}
                paddingY={2}
                sx={{
                  backgroundColor: hasJoinedHighlightedEvent
                    ? 'rgba(142, 234, 194, 0.2)'
                    : 'rgba(255, 214, 0, 0.29)',
                }}
              >
                <Avatar>
                  <EventIcon />
                </Avatar>
                <Typography>
                  <Trans
                    i18nKey={
                      hasJoinedHighlightedEvent
                        ? 'cycling-insights.community.challenges.list.highlighted_event.title_joined'
                        : 'cycling-insights.home.participate_in_the_challenge'
                    }
                    values={{ title: highlightedEvent.title }}
                  />
                </Typography>
                {hasJoinedHighlightedEvent ? (
                  <Button disabled size="medium" startIcon={<Done />} variant="outlined">
                    <Trans i18nKey="cycling-insights.community.challenges.list.highlighted_event.joined" />
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    component={Link}
                    size="medium"
                    to={`/${currentPartner.code}/promotion/challenges`}
                    variant="contained"
                  >
                    <Trans i18nKey="cycling-insights.home.actions.join_challenge" />
                  </Button>
                )}
              </Box>
            )
          ) : (
            <Box
              alignItems="center"
              borderRadius={1}
              display="flex"
              gap={2}
              paddingX={3}
              paddingY={2}
              sx={{ backgroundColor: '#e0ecdb' }}
            >
              <Typography>
                <Trans i18nKey="cycling-insights.home.find_out_more" />
              </Typography>
              <Button
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                download
                color="primary"
                component="a"
                href={environment.helpPDF.url}
                onClick={() =>
                  trackEvent('File Downloaded', {
                    partner_id: currentPartner?.id,
                    partner_code: currentPartner?.code,
                    file: 'Help file',
                    cta: 'Home Page Button',
                  })
                }
                size="medium"
                startIcon={<DownloadIcon />}
                target="_blank"
                variant="contained"
              >
                <p style={{ margin: 0, textAlign: 'center' }}>
                  <Trans i18nKey="cycling-insights.help.download" />
                </p>
              </Button>
            </Box>
          )}
          <Box
            display="flex"
            flexWrap="wrap"
            gap={{ xs: 2, lg: 4 }}
            justifyContent="center"
            width="100%"
          >
            {items.map((item) => {
              const { key } = item;

              if (key === 'journeys' && !currentPartner.hasActivityStatistics)
                return <Fragment key={key} />;

              if (key === 'security' && (!currentPartner.area || isCompany))
                return <Fragment key={key} />;

              if (key === 'environment' && !currentPartner.hasActivityStatistics)
                return <Fragment key={key} />;

              if (
                key === 'cyclability' &&
                (!!currentPartner.hasActivityStatistics || !currentPartner.hasCyclabilityData)
              )
                return <Fragment key={key} />;

              if (
                key === 'parkings' &&
                (!!currentPartner.hasActivityStatistics || !currentPartner.hasParkingsStatistics)
              )
                return <Fragment key={key} />;

              return (
                <Item
                  activityStatistics={activityStatistics}
                  flowsStatistics={flowsStatistics}
                  isCompany={isCompany}
                  item={item}
                  key={item.key}
                  period={period}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function Item({
  period,
  isCompany,
  activityStatistics,
  flowsStatistics,
  item: { Icon, titleKey, Component },
}: {
  activityStatistics?: IActivityStats;
  flowsStatistics?: { current: IPartnerFlowsStats | null; prev: IPartnerFlowsStats | null } | null;
  isCompany: boolean;
  item: IItem;
  period: Period;
}): JSX.Element {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      gap={3}
      width={{
        xs: '100%',
        sm: 'calc((100% - 16px) / 2)',
        md: 'calc((100% - 32px) / 3)',
        lg: 'calc((100% - 64px) / 3)',
      }}
    >
      <Box alignItems="center" display="flex" gap={2}>
        <Icon fontSize="large" sx={{ color: '#03825c', fill: '#03825c' }} />
        <Typography fontSize="1.125em" variant="h6">
          <Trans i18nKey={titleKey} />
        </Typography>
      </Box>
      <Box alignItems="stretch" alignSelf="stretch" display="flex" flexDirection="column" gap={2}>
        <Component
          activityStatistics={activityStatistics}
          flowsStatistics={flowsStatistics}
          isCompany={isCompany}
          period={period}
        />
      </Box>
    </Box>
  );
}

export default HomePage;
