import {
  ParkingService,
  Report,
  ReportService,
  TReportTypeCode,
  useCancellablePromise,
  useParkingLots,
} from '@geovelo-frontends/commons';
import { Box, Skeleton, Tooltip, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import PeriodForm from '../../../../components/form/period';
import ReportsFilters from '../../../../components/form/reports-filters';
import TabIntroduction from '../../../../components/tab-introduction';
import useCartographicReports from '../../../../hooks/map/cartographic-reports';
import useQueryParams from '../../../../hooks/query-params';
import { TOutletContext } from '../../../../layouts/page/container';
import { TQAPageContext } from '../../context';

import ReportsTable from './table';

function ReportsForm(context: TQAPageContext & TOutletContext): JSX.Element {
  const {
    period,
    osmReports: {
      tableRef,
      selectedReviewsCount,
      selectedStatuses,
      selectedTypeCodes,
      data,
      selectedId,
      selectReviewsCount,
      selectStatuses,
      selectTypeCodes,
      setData,
      selectId,
    },
  } = context;
  const [initialized, setInitialized] = useState(false);
  const {
    partner: { current: currentPartner },
    map: { current: currentMap, parkingsShowed },
    report: { types },
    actions: { enableParkingsToggle, getReportTypes },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { get: getQueryParams, update: updateQueryParams } = useQueryParams();
  const { initialized: layersInitialized, selectReport: selectReportOnMap } =
    useCartographicReports(currentMap, {
      cartographicReports: data || [],
      onReportSelected: selectId,
    });
  const {
    initialized: parkingsInitialized,
    init: initMarkers,
    update: updateMarkers,
    clear: clearMarkers,
  } = useParkingLots(currentMap, theme, smallDevice);

  useEffect(() => {
    setInitialized(true);
    enableParkingsToggle(true);

    return () => enableParkingsToggle(false);
  }, []);

  useEffect(() => {
    if (currentMap) initMarkers();
  }, [currentMap]);

  useEffect(() => {
    if (!initialized) return;

    try {
      getReportTypes();
    } catch (err) {
      enqueueSnackbar(t('cycling-insights.reports.types.server_error'), { variant: 'error' });
    }

    return () => {
      selectStatuses(['OPEN', 'CLOSED']);
      selectTypeCodes(
        (types
          ?.filter(({ code, isBindToOSM }) => isBindToOSM && code !== 'support')
          .map(({ code }) => code) || []) as TReportTypeCode[],
      );
      selectReviewsCount(-1);
    };
  }, [initialized]);

  useEffect(() => {
    if (parkingsInitialized && parkingsShowed.arch === true) {
      updateParkings();

      currentMap?.on('moveend', updateParkings);
    } else clearMarkers();

    return () => {
      clearMarkers();
      currentMap?.off('moveend', updateParkings);
    };
  }, [parkingsInitialized, parkingsShowed]);

  useEffect(() => {
    if (!types) return;

    const _typeCodes = (types
      .filter(({ code, isBindToOSM }) => isBindToOSM && code !== 'support')
      .map(({ code }) => code) || []) as TReportTypeCode[];
    const typeCodes = getQueryParams('types', _typeCodes) || _typeCodes;

    selectTypeCodes(typeCodes);
  }, [types]);

  useEffect(() => {
    if (types) update();

    return () => {
      cancelPromises();
      setData(undefined);
    };
  }, [period.values, types, selectedStatuses, selectedTypeCodes, selectedReviewsCount]);

  useEffect(() => {
    const report = data?.find(({ id }) => selectedId === id);
    if (layersInitialized) selectReportOnMap(report || null);
  }, [layersInitialized, selectedId]);

  async function update() {
    if (selectedStatuses.length > 0 && selectedTypeCodes.length > 0) {
      try {
        const _reports: Report[] = [];
        let hasNext = true;
        let page = 1;

        while (hasNext) {
          const { reports, next } = await cancellablePromise(
            ReportService.getReports({
              cyclabilityZoneId: currentPartner?.cyclabilityZoneId || undefined,
              period: period.values.current.toIPeriod(),
              typeCodes: selectedTypeCodes,
              status: selectedStatuses.flatMap((status) =>
                status === 'OPEN' ? 'OPEN' : ['CLOSED', 'ARCHIVED', 'CLOSED_BY_OSM'],
              ),
              page: page++,
              reviewCountMin: selectedReviewsCount === -1 ? undefined : selectedReviewsCount,
              reviewCountMax:
                selectedReviewsCount === -1 || selectedReviewsCount === 3
                  ? undefined
                  : selectedReviewsCount,
              rowsPerPage: 500,
              query:
                '{id, geo_point, creator{username}, created, updated, description, source, report_type_code, status, reviews, report_source, osm_note_id, photo, is_valid, city_name, city_reference}',
            }),
          );

          _reports.push(...reports);
          hasNext = Boolean(next);
        }

        setData(_reports);

        updateQueryParams({
          currentPeriod: period.values.current,
          timePeriod: period.values.timePeriod,
          dayPeriod: period.values.dayPeriod,
          statuses: selectedStatuses,
          typeCodes: selectedTypeCodes,
          reviewsCount: selectedReviewsCount,
        });
      } catch (err) {
        if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
          enqueueSnackbar(t('cycling-insights.reports.cartographic_reports.server_error'), {
            variant: 'error',
          });
        }
      }
    }
  }

  async function updateParkings() {
    cancelPromises();
    if (!currentMap) return;

    try {
      const [[west, south], [east, north]] = currentMap.getBounds().toArray();
      const parkingLots = await cancellablePromise(
        ParkingService.getParkingLots({
          zoom: currentMap.getZoom(),
          bounds: { north, east, south, west },
        }),
      );

      updateMarkers(parkingLots);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') console.error(err);
    }
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={5} minHeight="100%">
        <TabIntroduction title="cycling-insights.cartographic_data.introduction.cartographic_reports" />
        <Box display="flex" flexDirection="column" gap={3}>
          <PeriodForm disableComparison disablePadding {...period} />
          <ReportsFilters isBindToOSM {...context.osmReports} />
          <Box borderRadius={4} display="flex" height={24} overflow="hidden" width="100%">
            {data ? (
              <>
                <Tooltip
                  title={
                    <Trans
                      count={
                        data.filter(({ status, nbReviews }) => status === 'OPEN' && nbReviews === 0)
                          .length
                      }
                      i18nKey="cycling-insights.reports.osm_cartographic_reports.chart.unprocessed_report"
                      values={{
                        count: data.filter(
                          ({ status, nbReviews }) => status === 'OPEN' && nbReviews === 0,
                        ).length,
                      }}
                    />
                  }
                >
                  <Box
                    bgcolor="#A42C49"
                    height="100%"
                    width={`calc(${
                      (100 *
                        data.filter(({ status, nbReviews }) => status === 'OPEN' && nbReviews === 0)
                          .length) /
                      data.length
                    }%)`}
                  />
                </Tooltip>
                <Tooltip
                  title={
                    <Trans
                      count={
                        data.filter(({ status, nbReviews }) => status === 'OPEN' && nbReviews > 0)
                          .length
                      }
                      i18nKey="cycling-insights.reports.osm_cartographic_reports.chart.ongoing_report"
                      values={{
                        count: data.filter(
                          ({ status, nbReviews }) => status === 'OPEN' && nbReviews > 0,
                        ).length,
                      }}
                    />
                  }
                >
                  <Box
                    bgcolor="#FA823E"
                    height="100%"
                    width={`calc(${
                      (100 *
                        data.filter(({ status, nbReviews }) => status === 'OPEN' && nbReviews > 0)
                          .length) /
                      data.length
                    }%)`}
                  />
                </Tooltip>
                <Tooltip
                  title={
                    <Trans
                      count={data.filter(({ status }) => status !== 'OPEN').length}
                      i18nKey="cycling-insights.reports.osm_cartographic_reports.chart.processed_report"
                      values={{
                        count: data.filter(({ status }) => status !== 'OPEN').length,
                      }}
                    />
                  }
                >
                  <Box
                    bgcolor="#038B63"
                    height="100%"
                    width={`calc(${
                      (100 * data.filter(({ status }) => status !== 'OPEN').length) / data.length
                    }%)`}
                  />
                </Tooltip>
              </>
            ) : (
              <Skeleton height={24} variant="rectangular" width="100%" />
            )}
          </Box>
          <Box flexGrow={1} marginX={-3} sx={{ overflowY: 'auto' }}>
            <ReportsTable ref={tableRef} {...context} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ReportsForm;
