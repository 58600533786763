import { SvgIcon, SvgIconProps } from '@mui/material';

function OpenSheltersIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 34 38" {...props}>
      <path
        d="M18.0149 0.618749C17.3038 0.085416 16.326 0.0854167 15.6149 0.61875L0.963817 11.607C0.460205 11.9848 0.163818 12.5775 0.163818 13.207V35.1836C0.163818 36.2882 1.05925 37.1836 2.16382 37.1836H8.57073C9.6753 37.1836 10.5707 36.2882 10.5707 35.1836V22.668C10.5707 21.5634 11.4662 20.668 12.5707 20.668H21.059C22.1636 20.668 23.059 21.5634 23.059 22.668V35.1836C23.059 36.2882 23.9545 37.1836 25.059 37.1836H31.4659C32.5705 37.1836 33.4659 36.2882 33.4659 35.1836V13.207C33.4659 12.5775 33.1695 11.9848 32.6659 11.607L18.0149 0.618749Z"
        fill="#6B6C6E"
      />
    </SvgIcon>
  );
}

export default OpenSheltersIcon;
