import { Autocomplete, FileInput, Place, TFile } from '@geovelo-frontends/commons';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';
import csv from 'csv-parser';
import fileReaderStream from 'filereader-stream';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

export type TPointType = 'single' | 'multiple';

interface IProps {
  departures: Place[];
  onDeparturesChange: (places: Place[]) => void;
  onTypeChange: (type: TPointType) => void;
  type: TPointType;
}

function IsochronesPointsForm({
  type,
  departures,
  onTypeChange,
  onDeparturesChange,
}: IProps): JSX.Element {
  const [file, setFile] = useState<TFile>();
  const [headers, setHeaders] = useState<string[]>();
  const [latFieldValue, setLatFieldValue] = useState<string>('');
  const [lngFieldValue, setLngFieldValue] = useState<string>('');
  const [data, setData] = useState<Array<Record<string, unknown>>>();

  useEffect(() => {
    if (type === 'single') setFile(null);
  }, [type]);

  useEffect(() => {
    if (file) {
      if (file instanceof File) {
        const _data: Array<Record<string, unknown>> = [];

        fileReaderStream(file)
          .pipe(csv({ separator: ';' }))
          .on('headers', setHeaders)
          .on('data', (rowData: Record<string, unknown>) => _data.push(rowData))
          .on('end', () => setData(_data));
      }
    } else {
      setHeaders(undefined);
      setLatFieldValue('');
      setLngFieldValue('');
    }
  }, [file]);

  useEffect(() => {
    if (latFieldValue !== '' && lngFieldValue !== '') {
      const _departures: Place[] = [];

      data?.forEach((rowData) => {
        const lat = parseFloat(((rowData[latFieldValue] as string) || '').replace(',', '.'));
        const lng = parseFloat(((rowData[lngFieldValue] as string) || '').replace(',', '.'));

        if (!isNaN(lat) && !isNaN(lng) && lat <= 90 && lat >= -90 && lng <= 180 && lng >= -180)
          _departures.push(new Place(undefined, { type: 'Point', coordinates: [lng, lat] }));
      });

      onDeparturesChange(_departures);
    } else if (type === 'multiple' && departures.length > 0) {
      onDeparturesChange([]);
    }
  }, [latFieldValue, lngFieldValue]);

  return (
    <>
      <RadioGroup
        aria-label="points type"
        onChange={({ target: { value } }) => onTypeChange(value as TPointType)}
        value={type}
      >
        <FormControlLabel
          control={<Radio size="small" />}
          label={<Trans i18nKey="cycling-insights.facilities.isochrones.form.single_point" />}
          value="single"
        />
        {type === 'single' && (
          <>
            <Autocomplete
              defaultValue={departures[0]}
              onSelect={(departure) => onDeparturesChange(departure ? [departure] : [])}
              size="small"
            />
            <Typography color="textSecondary" variant="caption">
              <Trans i18nKey="cycling-insights.facilities.isochrones.form.click_on_map" />
            </Typography>
          </>
        )}
        <FormControlLabel
          control={<Radio size="small" />}
          label={<Trans i18nKey="cycling-insights.facilities.isochrones.form.multiple_points" />}
          value="multiple"
        />
        {type === 'multiple' && (
          <>
            <Box marginY={1}>
              <Typography color="textSecondary" variant="caption">
                <Trans i18nKey="cycling-insights.facilities.isochrones.form.multiple_points_info" />
              </Typography>
            </Box>
            <FileInput file={file} onChange={setFile} size="small" type="csv" />
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  disabled={!headers}
                  margin="dense"
                  size="small"
                  variant="outlined"
                >
                  <InputLabel htmlFor="lng-field-input">
                    <Trans i18nKey="cycling-insights.facilities.isochrones.form.lng_field" />
                  </InputLabel>
                  <Select
                    inputProps={{
                      id: 'lng-field-input',
                    }}
                    label={
                      <Trans i18nKey="cycling-insights.facilities.isochrones.form.lng_field" />
                    }
                    onChange={({ target: { value } }) => setLngFieldValue(value as string)}
                    value={lngFieldValue}
                  >
                    <MenuItem value="">
                      <Trans i18nKey="commons.actions.reset" />
                    </MenuItem>
                    {headers?.map((header) => (
                      <MenuItem disabled={latFieldValue === header} key={header} value={header}>
                        {header}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  disabled={!headers}
                  margin="dense"
                  size="small"
                  variant="outlined"
                >
                  <InputLabel htmlFor="lat-field-input">
                    <Trans i18nKey="cycling-insights.facilities.isochrones.form.lat_field" />
                  </InputLabel>
                  <Select
                    inputProps={{
                      id: 'lat-field-input',
                    }}
                    label={
                      <Trans i18nKey="cycling-insights.facilities.isochrones.form.lat_field" />
                    }
                    onChange={({ target: { value } }) => setLatFieldValue(value as string)}
                    value={latFieldValue}
                  >
                    <MenuItem value="">
                      <Trans i18nKey="commons.actions.reset" />
                    </MenuItem>
                    {headers?.map((header) => (
                      <MenuItem disabled={lngFieldValue === header} key={header} value={header}>
                        {header}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}
      </RadioGroup>
    </>
  );
}

export default IsochronesPointsForm;
