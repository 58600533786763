import { SvgIcon, SvgIconProps } from '@mui/material';

function CounterIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <rect fill="#D3D545" height="24" rx="12" width="24" x="0.939453" />
      <path
        clipRule="evenodd"
        d="M7.41797 7.95817C7.41797 6.00216 9.00363 4.4165 10.9596 4.4165H14.918C16.874 4.4165 18.4596 6.00216 18.4596 7.95817V18.8748V19.5832H17.7513H8.1263H7.41797V18.8748V7.95817ZM10.9596 5.83317C9.78603 5.83317 8.83464 6.78457 8.83464 7.95817V18.1665H17.043V7.95817C17.043 6.78457 16.0916 5.83317 14.918 5.83317H10.9596ZM9.82422 7.87484C9.82422 7.48364 10.1413 7.1665 10.5326 7.1665H15.3451C15.7363 7.1665 16.0534 7.48364 16.0534 7.87484C16.0534 8.26604 15.7363 8.58317 15.3451 8.58317H10.5326C10.1413 8.58317 9.82422 8.26604 9.82422 7.87484ZM11.2409 9.9165C10.4585 9.9165 9.82422 10.5508 9.82422 11.3332V15.4165C9.82422 16.1989 10.4585 16.8332 11.2409 16.8332H14.6367C15.4191 16.8332 16.0534 16.1989 16.0534 15.4165V11.3332C16.0534 10.5508 15.4191 9.9165 14.6367 9.9165H11.2409ZM11.2409 11.3332H14.6367V15.4165H11.2409V11.3332Z"
        fill="#283859"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}

export default CounterIcon;
