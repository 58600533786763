import { Suggestion } from '@geovelo-frontends/commons';
import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import { Fragment, useState } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ConfirmDialog from '../../../../components/confirm-dialog';
import Paper from '../../../../components/paper';

function FacilitiesSuggestionsList({
  selectedSuggestion,
  data,
  loading,
  onClick,
  onDelete,
  selectSuggestion,
}: {
  data?: Suggestion[];
  loading: boolean;
  onClick: (suggestion: Suggestion) => void;
  onDelete: (id: number | null) => void;
  selectedSuggestion: Suggestion | null;
  selectSuggestion: (suggestion: Suggestion | null) => void;
}): JSX.Element {
  const [suggestionToRemove, setSuggestionToRemove] = useState<number | null>(null);
  const navigate = useNavigate();

  return (
    <>
      <Paper disablePadding>
        <Box display="flex" flexDirection="column">
          {data && data.length === 0 ? (
            <Typography
              overflow="hidden"
              paddingX={3}
              paddingY={2}
              textOverflow="ellipsis"
              variant="body2"
              whiteSpace="nowrap"
            >
              <Trans i18nKey="commons.no_filtered_data" />
            </Typography>
          ) : (
            <>
              {(data || [1, 2, 3]).map((element, index) => {
                const active =
                  selectedSuggestion &&
                  typeof element !== 'number' &&
                  selectedSuggestion.id === element.id;

                return (
                  <Fragment key={index}>
                    {index > 0 && <Divider />}
                    <ListItemButton
                      onClick={() => typeof element !== 'number' && onClick(element)}
                      sx={({ palette }) => ({
                        backgroundColor: active ? lighten(palette.secondary.main, 0.7) : 'initial',
                        paddingX: 3,
                        paddingY: 2,
                      })}
                    >
                      <ListItemText
                        primary={
                          typeof element !== 'number' ? (
                            element.title
                          ) : (
                            <Skeleton variant="text" width={200} />
                          )
                        }
                        secondary={
                          typeof element !== 'number' ? (
                            `${element.budget} km - ${element.resolution_date ? `Dernière résolution : ${element.resolution_date.format('LLL')}` : 'En cours de résolution'}`
                          ) : (
                            <Skeleton variant="text" width={100} />
                          )
                        }
                        sx={{ paddingRight: 10 }}
                      />
                      {typeof element !== 'number' && (
                        <ListItemSecondaryAction>
                          <Box display="flex" gap={1}>
                            <IconButton
                              color="inherit"
                              onClick={(event) => {
                                event.stopPropagation();
                                selectSuggestion(element);
                                navigate('../facilities-suggestions-form');
                              }}
                              size="small"
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                            <IconButton
                              color="inherit"
                              onClick={(event) => {
                                event.stopPropagation();
                                setSuggestionToRemove(element.id);
                              }}
                              size="small"
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </Box>
                        </ListItemSecondaryAction>
                      )}
                    </ListItemButton>
                  </Fragment>
                );
              })}
            </>
          )}
        </Box>
      </Paper>
      <ConfirmDialog
        dialogTitle="facilities-suggestions-remove-dialog"
        loading={loading}
        onCancel={() => setSuggestionToRemove(null)}
        onConfirm={() => {
          onDelete(suggestionToRemove);
          setSuggestionToRemove(null);
        }}
        open={Boolean(suggestionToRemove)}
        title={<Trans i18nKey="cycling-insights.qa.facilities_suggestions.remove_dialog.title" />}
      />
    </>
  );
}

export default FacilitiesSuggestionsList;
