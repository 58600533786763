import {
  Scenario,
  ScenarioDetails,
  ScenarioService,
  TFacilitiesType,
  THighwayType,
  TSurfaceType,
  facilitiesTypes,
  highways,
  surfaces,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import TabIntroduction from '../../../../components/tab-introduction';
import useScenario from '../../../../hooks/map/scenario';
import { TOutletContext } from '../../../../layouts/page/container';
import { IBicycleObservatoryPageContext } from '../../context';

import ChangesList from './changes-list';
import NewScenarioDialog from './new-scenario-dialog';
import ScenariosList from './scenarios-list';

function ScenariosForm({
  loading,
  setLoading,
  scenario: { nominalGraph, roadIdMap, setNominalGraph, setRoadIdMap },
}: IBicycleObservatoryPageContext & TOutletContext): JSX.Element {
  const {
    partner: { current: currentPartner },
    map: { current: currentMap },
  } = useContext(AppContext);
  const [scenarioTitle, setScenarioTitle] = useState('');
  const [creating, setCreating] = useState(false);
  const [newScenarioOpen, openNewScenario] = useState(false);
  const [selectedScenarioIndex, selectScenarioIndex] = useState<number | null>(null);
  const [selectedChangeIndex, selectChangeIndex] = useState<number | null>(null);
  const [scenarios, setScnearios] = useState<Scenario[]>();
  const [scenarioDetails, setScnearioDetails] = useState<ScenarioDetails>();
  const [facilitiesType, setFacilitiesType] = useState<TFacilitiesType>();
  const [highwayType, setHighwayType] = useState<THighwayType>();
  const [surfaceType, setSurfaceType] = useState<TSurfaceType>();
  const [modifications, setModifications] = useState<{
    [id: number]: {
      amenagement: TFacilitiesType | undefined;
      blocked: boolean | undefined;
      highway: THighwayType | undefined;
      surface: TSurfaceType | undefined;
    };
  }>([]);
  const [modifiedRoads, setModifiedRoads] = useState<{
    [id: number]: {
      geometry: GeoJSON.LineString;
      properties: {
        amenagement: TFacilitiesType;
        blocked: boolean | undefined;
        highway: THighwayType;
        newAmenagement: TFacilitiesType | undefined;
        newHighway: THighwayType | undefined;
        newSurface: TSurfaceType | undefined;
        surface: TSurfaceType;
      };
    };
  }>([]);
  const [editingSection, setEditingSection] = useState<{
    id: number;
    geometry: GeoJSON.LineString;
    amenagement: TFacilitiesType;
    highway: THighwayType;
    surface: TSurfaceType;
  }>();
  const {
    initialized: layersInitialized,
    init: initLayers,
    update: updateLayers,
    updateModifications,
    updateTooltip,
    selectRoad,
    clear: clearLayers,
  } = useScenario(
    currentMap,
    (value?: {
      id: number;
      geometry: GeoJSON.LineString;
      amenagement: TFacilitiesType;
      highway: THighwayType;
      surface: TSurfaceType;
    }) => {
      setEditingSection(value);
      selectChangeIndex(value?.id || null);
    },
  );
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getScenarios();

    return () => {
      cancelPromises();
    };
  }, []);

  useEffect(() => {
    if (selectedScenarioIndex !== null) {
      if (scenarios?.find(({ scenarioId }) => scenarioId === selectedScenarioIndex)?.computed)
        getScenarioDetails();
      else
        enqueueSnackbar(t('cycling-insights.usage.travel_simulations.not_available_error'), {
          variant: 'error',
        });
    }
  }, [selectedScenarioIndex]);

  useEffect(() => {
    if (currentMap) initLayers();

    return () => {
      clearLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    if (layersInitialized && scenarioDetails) {
      updateLayers(scenarioDetails.geometry);
      currentMap?.flyTo({
        center: [scenarioDetails.mapState.longitude, scenarioDetails.mapState.latitude],
        zoom: scenarioDetails.mapState.zoom,
      });
    }
  }, [layersInitialized, scenarioDetails]);

  useEffect(() => {
    if (layersInitialized && creating && nominalGraph) {
      updateLayers(nominalGraph.geometry, true);
      currentMap?.flyTo({
        center: [nominalGraph.mapState.longitude, nominalGraph.mapState.latitude],
        zoom: nominalGraph.mapState.zoom,
      });
    }
  }, [layersInitialized, creating, nominalGraph]);

  useEffect(() => {
    updateModifications(
      Object.keys(modifiedRoads).map((id) => {
        return {
          id: parseInt(id),
          road: modifiedRoads[parseInt(id)],
        };
      }),
    );
  }, [modifiedRoads]);

  useEffect(() => {
    setFacilitiesType(
      modifications[editingSection?.id || -1]?.amenagement || editingSection?.amenagement,
    );
    setHighwayType(modifications[editingSection?.id || -1]?.highway || editingSection?.highway);
    setSurfaceType(modifications[editingSection?.id || -1]?.surface || editingSection?.surface);
  }, [editingSection]);

  async function getScenarios() {
    if (!currentPartner) return;
    setLoading(true);
    cancelPromises();
    try {
      const result = await cancellablePromise(ScenarioService.getScenarios(currentPartner.code));
      setScnearios(result);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.travel_simulations.server_error'), {
          variant: 'error',
        });
      }
    }
    setLoading(false);
  }

  async function getScenarioDetails() {
    if (!selectedScenarioIndex) return;
    setLoading(true);
    cancelPromises();
    try {
      const result = await cancellablePromise(ScenarioService.getScenario(selectedScenarioIndex));
      setScnearioDetails(result);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.travel_simulations.server_error'), {
          variant: 'error',
        });
      }
    }
    setLoading(false);
  }

  function handleCancel() {
    setScenarioTitle('');
    setModifications({});
    setEditingSection(undefined);
    setModifiedRoads([]);
    openNewScenario(false);
    setCreating(false);
  }

  async function handleConfirm() {
    openNewScenario(false);
    setCreating(true);
    cancelPromises();
    if (!nominalGraph) {
      setLoading(true);
      try {
        const result = await cancellablePromise(ScenarioService.getNominalGraph(4));
        setNominalGraph(result);
        result.geometry.features.forEach(
          (feature, index) => (roadIdMap[feature.properties?.routelink_id] = index),
        );
        setRoadIdMap({ ...roadIdMap });
      } catch (err) {
        if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
          enqueueSnackbar(t('cycling-insights.usage.travel_simulations.graph_server_error'), {
            variant: 'error',
          });
        }
      }
      setLoading(false);
    }
  }

  async function handleCreateScenario() {
    if (!currentPartner) return;
    setLoading(true);
    cancelPromises();
    try {
      await cancellablePromise(
        ScenarioService.createScenario(4, currentPartner.code, scenarioTitle, modifications),
      );
      enqueueSnackbar(t('cycling-insights.usage.travel_simulations.scenario_created'));
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.travel_simulations.create_server_error'), {
          variant: 'error',
        });
      }
    }
    setScenarioTitle('');
    setModifications({});
    setEditingSection(undefined);
    setModifiedRoads([]);
    setCreating(false);
    getScenarios();
    setLoading(false);
  }

  function handleBlockRoad(block: boolean) {
    if (!editingSection) return;
    if (modifications[editingSection.id]) {
      if (
        !block &&
        !modifications[editingSection.id].amenagement &&
        !modifications[editingSection.id].highway &&
        !modifications[editingSection.id].surface
      ) {
        delete modifiedRoads[editingSection.id];
        setModifiedRoads({ ...modifiedRoads });
        delete modifications[editingSection.id];
        setModifications({ ...modifications });
        return;
      } else {
        modifications[editingSection.id].blocked = block;
      }
    } else
      modifications[editingSection.id] = {
        amenagement: undefined,
        blocked: block,
        highway: undefined,
        surface: undefined,
      };
    setModifications({ ...modifications });
    if (!modifiedRoads[editingSection.id])
      modifiedRoads[editingSection.id] = {
        geometry: editingSection.geometry,
        properties: {
          amenagement: editingSection.amenagement,
          blocked: block,
          highway: editingSection.highway,
          newAmenagement: modifications[editingSection.id].amenagement,
          newHighway: modifications[editingSection.id].highway,
          newSurface: modifications[editingSection.id].surface,
          surface: editingSection.surface,
        },
      };
    else modifiedRoads[editingSection.id].properties.blocked = block;
    setModifiedRoads({ ...modifiedRoads });
  }

  return (
    <>
      <Box display="flex" flexDirection="column" minHeight="100%">
        {creating ? (
          <Box display="flex" flexDirection="column" flexGrow={1} paddingTop={2} paddingX={1}>
            <Typography variant="h6">{scenarioTitle}</Typography>
            <Typography color="textSecondary" variant="body2">
              <Trans i18nKey="cycling-insights.usage.travel_simulations.edit_description" />
            </Typography>
            {editingSection && (
              <Box marginY={3}>
                <Typography variant="subtitle2">{editingSection?.id}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!modifications[editingSection.id]?.blocked}
                      name="blockRoad"
                      onChange={(_, checked) => handleBlockRoad(checked)}
                    />
                  }
                  label={<Trans i18nKey="cycling-insights.usage.travel_simulations.block_road" />}
                  labelPlacement="start"
                />
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Aménagement</TableCell>
                      <TableCell>
                        <FormControl margin="none" style={{ width: '210px' }}>
                          <Select
                            disabled={!!modifications[editingSection.id]?.blocked}
                            labelId="facilities-type-label"
                            name="facilitiesType"
                            onChange={({ target: { value } }) => {
                              setFacilitiesType(value as TFacilitiesType);
                              if (modifications[editingSection.id])
                                modifications[editingSection.id].amenagement =
                                  value as TFacilitiesType;
                              else
                                modifications[editingSection.id] = {
                                  amenagement: value as TFacilitiesType,
                                  blocked: false,
                                  highway: undefined,
                                  surface: undefined,
                                };
                              setModifications({ ...modifications });
                              if (!modifiedRoads[editingSection.id]) {
                                modifiedRoads[editingSection.id] = {
                                  geometry: editingSection.geometry,
                                  properties: {
                                    amenagement: editingSection.amenagement,
                                    blocked: false,
                                    highway: editingSection.highway,
                                    newAmenagement: modifications[editingSection.id].amenagement,
                                    newHighway: modifications[editingSection.id].highway,
                                    newSurface: modifications[editingSection.id].surface,
                                    surface: editingSection.surface,
                                  },
                                };
                              } else {
                                modifiedRoads[editingSection.id].properties.newAmenagement =
                                  modifications[editingSection.id].amenagement;
                              }
                              setModifiedRoads({ ...modifiedRoads });
                              updateTooltip(editingSection, modifications[editingSection.id]);
                            }}
                            size="small"
                            value={facilitiesType || ''}
                          >
                            {facilitiesTypes.map((value) => (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Type de voie</TableCell>
                      <TableCell>
                        <FormControl margin="none" style={{ width: '210px' }}>
                          <Select
                            disabled={!!modifications[editingSection.id]?.blocked}
                            labelId="highway-type-label"
                            name="highwayType"
                            onChange={({ target: { value } }) => {
                              setHighwayType(value as THighwayType);
                              if (modifications[editingSection.id])
                                modifications[editingSection.id].highway = value as THighwayType;
                              else
                                modifications[editingSection.id] = {
                                  amenagement: undefined,
                                  blocked: false,
                                  highway: value as THighwayType,
                                  surface: undefined,
                                };
                              setModifications({ ...modifications });
                              if (!modifiedRoads[editingSection.id]) {
                                modifiedRoads[editingSection.id] = {
                                  geometry: editingSection.geometry,
                                  properties: {
                                    amenagement: editingSection.amenagement,
                                    blocked: false,
                                    highway: editingSection.highway,
                                    newAmenagement: modifications[editingSection.id].amenagement,
                                    newHighway: modifications[editingSection.id].highway,
                                    newSurface: modifications[editingSection.id].surface,
                                    surface: editingSection.surface,
                                  },
                                };
                              } else {
                                modifiedRoads[editingSection.id].properties.newHighway =
                                  modifications[editingSection.id].highway;
                              }
                              setModifiedRoads({ ...modifiedRoads });
                              updateTooltip(editingSection, modifications[editingSection.id]);
                            }}
                            size="small"
                            value={highwayType || ''}
                          >
                            {highways.map((value) => (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Revêtement</TableCell>
                      <TableCell>
                        <FormControl margin="none" style={{ width: '210px' }}>
                          <Select
                            disabled={!!modifications[editingSection.id]?.blocked}
                            labelId="surface-type-label"
                            name="surfaceType"
                            onChange={({ target: { value } }) => {
                              setSurfaceType(value as TSurfaceType);
                              if (modifications[editingSection.id])
                                modifications[editingSection.id].surface = value as TSurfaceType;
                              else
                                modifications[editingSection.id] = {
                                  amenagement: undefined,
                                  blocked: false,
                                  highway: undefined,
                                  surface: value as TSurfaceType,
                                };
                              setModifications({ ...modifications });
                              if (!modifiedRoads[editingSection.id]) {
                                modifiedRoads[editingSection.id] = {
                                  geometry: editingSection.geometry,
                                  properties: {
                                    amenagement: editingSection.amenagement,
                                    blocked: false,
                                    highway: editingSection.highway,
                                    newAmenagement: modifications[editingSection.id].amenagement,
                                    newHighway: modifications[editingSection.id].highway,
                                    newSurface: modifications[editingSection.id].surface,
                                    surface: editingSection.surface,
                                  },
                                };
                              } else {
                                modifiedRoads[editingSection.id].properties.newSurface =
                                  modifications[editingSection.id].surface;
                              }
                              setModifiedRoads({ ...modifiedRoads });
                              updateTooltip(editingSection, modifications[editingSection.id]);
                            }}
                            size="small"
                            value={surfaceType || ''}
                          >
                            {surfaces.map((value) => (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            )}
            <Box flexGrow={1} sx={{ overflowY: 'auto' }}>
              <ChangesList
                modifications={modifications}
                onRemove={(value) => {
                  const newId = parseInt(
                    Object.keys(modifications)[
                      Object.keys(modifications).findIndex((key) => parseInt(key) === value) + 1
                    ],
                  );
                  selectChangeIndex(newId);
                  delete modifiedRoads[value];
                  setModifiedRoads({ ...modifiedRoads });
                  delete modifications[value];
                  setModifications({ ...modifications });
                  if (newId) {
                    selectRoad(newId, modifiedRoads[newId]);
                    const {
                      geometry,
                      properties: { amenagement, highway, surface },
                    } = modifiedRoads[newId];
                    setEditingSection({
                      id: newId,
                      geometry,
                      amenagement,
                      highway,
                      surface,
                    });
                  } else {
                    selectRoad();
                    setEditingSection(undefined);
                  }
                }}
                selectedIndex={selectedChangeIndex}
                selectIndex={(id) => {
                  selectChangeIndex(id);
                  selectRoad(id, modifiedRoads[id]);
                  const {
                    geometry,
                    properties: { amenagement, highway, surface },
                  } = modifiedRoads[id];
                  setEditingSection({ id, geometry, amenagement, highway, surface });
                }}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              gap={3}
              justifyContent="center"
              marginBottom={2}
            >
              <Button disabled={loading} onClick={handleCancel} size="medium">
                <Trans i18nKey="commons.actions.cancel" />
              </Button>
              <Button
                disabled={loading || Object.keys(modifications).length === 0}
                onClick={handleCreateScenario}
                size="medium"
                variant="contained"
              >
                <Trans i18nKey="cycling-insights.usage.travel_simulations.validate" />
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.travel_simulations" />
            <Box flexGrow={1} paddingX={1} sx={{ overflowY: 'auto' }}>
              <ScenariosList
                onAdd={() => openNewScenario(true)}
                scenarios={scenarios}
                selectedIndex={selectedScenarioIndex}
                selectIndex={selectScenarioIndex}
              />
            </Box>
          </>
        )}
      </Box>
      <NewScenarioDialog
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        open={newScenarioOpen}
        scenarioTitle={scenarioTitle}
        setScenarioTitle={setScenarioTitle}
      />
    </>
  );
}

export default ScenariosForm;
