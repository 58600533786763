import { ChallengesCustomForm, ChallengesModelForm } from '@geovelo-frontends/commons';
import { ArrowDownward, ArrowForward, Close } from '@mui/icons-material';
import { Box, ButtonBase, IconButton, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import customIcon from '../../../../assets/images/new-custom-challenge.svg';
import modelIcon from '../../../../assets/images/new-model-challenge.svg';
import { closedDrawerWidth } from '../../../../layouts/default/drawer';

export type TPageType = 'custom' | 'model';

function ChallengesFormContent(): JSX.Element {
  const [searchParams] = useSearchParams();
  const [challengeType, setChallengeType] = useState<TPageType>(() => {
    const searchParam = searchParams.get('type');
    if (searchParam === 'custom') return 'custom';

    return 'model';
  });
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      bgcolor="#F6F7FB"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      gap={5}
      marginBottom="58px"
      paddingX={14}
      paddingY={8}
      sx={{ overflowY: 'auto' }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography
          color="primary"
          component="h2"
          flexDirection="column"
          fontSize="2rem"
          fontWeight="700"
        >
          <Trans i18nKey="commons.challenges.actions.create" />
        </Typography>
        <Box display="flex" gap={3}>
          <IconButton component={Link} size="large" to="../challenges">
            <Close />
          </IconButton>
        </Box>
      </Box>
      <Box alignSelf="stretch" display="flex" gap={3} width="100%">
        <ButtonBase
          onClick={() => setChallengeType('model')}
          sx={{
            borderRadius: 4,
            flexShrink: 0,
            width: 'calc((100% - 24px) / 2)',
            maxWidth: '408px',
          }}
        >
          <Box
            alignItems="center"
            bgcolor={challengeType === 'model' ? '#528BE8' : '#FFF'}
            borderRadius={4}
            display="flex"
            flexGrow={1}
            gap={3}
            padding="12px"
          >
            <img height="44px" src={modelIcon} />
            <Typography
              color={challengeType === 'model' ? 'white' : 'black'}
              fontSize="1rem"
              fontWeight={700}
            >
              {t('commons.challenge_post.actions.use_model')}
            </Typography>
            <Box flexGrow={1} />
            {challengeType === 'model' ? (
              <ArrowDownward sx={{ color: 'white' }} />
            ) : (
              <ArrowForward />
            )}
          </Box>
        </ButtonBase>
        <ButtonBase
          onClick={() => setChallengeType('custom')}
          sx={{
            borderRadius: 4,
            flexShrink: 0,
            width: 'calc((100% - 24px) / 2)',
            maxWidth: '408px',
          }}
        >
          <Box
            alignItems="center"
            bgcolor={challengeType === 'custom' ? '#528BE8' : '#FFF'}
            borderRadius={4}
            display="flex"
            flexGrow={1}
            gap={3}
            padding="12px"
          >
            <img height="44px" src={customIcon} />
            <Typography
              color={challengeType === 'custom' ? 'white' : 'black'}
              fontSize="1rem"
              fontWeight={700}
            >
              {t('commons.challenge_post.form.title_creation')}
            </Typography>
            <Box flexGrow={1} />
            {challengeType === 'custom' ? (
              <ArrowDownward sx={{ color: 'white' }} />
            ) : (
              <ArrowForward />
            )}
          </Box>
        </ButtonBase>
      </Box>
      <Box>
        {challengeType === 'custom' ? (
          <ChallengesCustomForm
            currentPartner={currentPartner}
            drawerWidth={closedDrawerWidth}
            onCreated={(newChallenge) =>
              currentPartner &&
              navigate(`/${currentPartner.code}/promotion/challenges/${newChallenge.id}`)
            }
            prevPath="../animation"
          />
        ) : (
          <ChallengesModelForm
            currentPartner={currentPartner}
            drawerWidth={closedDrawerWidth}
            prevPath="../animation"
            redirectUrl={currentPartner ? `/${currentPartner.code}/promotion/challenges` : '/'}
          />
        )}
      </Box>
    </Box>
  );
}

export default ChallengesFormContent;
