import { KeyboardArrowRight } from '@mui/icons-material';
import { Box, ButtonBase, DialogProps, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import addChallengeIcon from '../assets/images/challenge.svg';
import addNewsPostIcon from '../assets/images/news.svg';

import Dialog from './dialog';

type TProps = Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
};

function CreateContentDialog({ onClose, ...props }: TProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Dialog
      closeHidden
      hasCloseIcon
      dialogTitle="invitation-link-dialog"
      maxWidth="sm"
      onCancel={onClose}
      title={<Trans i18nKey="cycling-insights.community.create_dialog.title" />}
      {...props}
    >
      <Box display="flex" flexGrow={1} flexWrap="wrap" justifyContent="space-between">
        <StyledButton
          alignItems="center"
          bgcolor="#F6F7FB"
          borderRadius={4}
          component={ButtonBase}
          display="flex"
          gap={2}
          maxWidth="100%"
          onClick={() => {
            navigate('../new-post');
          }}
          padding={3}
        >
          <img height="56px" src={addNewsPostIcon} width="56px" />
          <Typography fontWeight={600}>
            {t('cycling-insights.community.create_dialog.news')}
          </Typography>
          <KeyboardArrowRight color="primary" fontSize="large" />
        </StyledButton>
        <StyledButton
          alignItems="center"
          bgcolor="#F6F7FB"
          borderRadius={4}
          component={ButtonBase}
          display="flex"
          gap={2}
          maxWidth="100%"
          onClick={() => {
            navigate('../new-challenge');
          }}
          padding={3}
        >
          <img height="56px" src={addChallengeIcon} width="56px" />
          <Typography fontWeight={600}>
            {t('cycling-insights.community.create_dialog.challenge')}
          </Typography>
          <KeyboardArrowRight color="primary" fontSize="large" />
        </StyledButton>
      </Box>
    </Dialog>
  );
}

const StyledButton = styled(Box)`
  &:hover {
    background-color: #3e7bdf;
    color: #fff;

    > svg {
      color: #fff;
    }
  }
`;

export default CreateContentDialog;
