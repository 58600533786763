import {
  BikeRoute,
  BikeRouteService,
  CancelledPromiseError,
  Ride,
  RideService,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Autocomplete, DialogProps, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Dialog from '../../../components/dialog';

export interface IValues {
  inputValue: string;
}

type TProps = Omit<DialogProps, 'onClose'> & {
  bikeRoute: BikeRoute;
  onClose: (bikeRoute?: BikeRoute) => void;
  onRidesChange: (rides: Ride[]) => void;
  rides?: Ride[];
};

function StepFormDialog({
  bikeRoute,
  rides,
  onClose,
  onRidesChange,
  ...props
}: TProps): JSX.Element {
  const [options, setOptions] = useState<Ride[]>([]);
  const [ride, setRide] = useState<Ride | null>(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { isSubmitting, values, setValues, setSubmitting, handleChange, handleSubmit } =
    useFormik<IValues>({
      initialValues: {
        inputValue: '',
      },
      onSubmit,
      enableReinitialize: true,
    });
  const classes = useStyles();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    if (props.open) {
      setValues({ inputValue: '' });
      setRide(null);
    }
  }, [props.open]);

  useEffect(() => {
    cancelPromises();
    setLoading(true);

    const timeout = setTimeout(() => {
      getRides();
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [values.inputValue]);

  async function getRides() {
    if (!values.inputValue) {
      setOptions([]);
      setLoading(false);
      return;
    }

    try {
      const { rides: _rides } = await cancellablePromise(
        RideService.getRides({
          page: 1,
          pageSize: 20,
          search: values.inputValue,
          query: '{id, title}',
        }),
      );

      setOptions(_rides);
      setLoading(false);
    } catch (err) {
      if (!(err instanceof CancelledPromiseError)) setLoading(false);
    }
  }

  async function onSubmit() {
    if (!ride || !ride.id) return;

    setSubmitting(true);

    const { rides: bikeRouteRides } = bikeRoute;
    const prevRide = bikeRouteRides[bikeRouteRides.length - 1];

    try {
      const newRide = await BikeRouteService.addRideToBikeRoute(bikeRoute.id, {
        ride_id: ride.id,
        order_atob: prevRide ? prevRide.order + 1 : 1,
        ride_atob_previous: prevRide ? prevRide.rideId : undefined,
        order_btoa: 1,
        ride_btoa_next: prevRide ? prevRide.rideId : undefined,
      });

      await Promise.all(
        bikeRouteRides.map(({ rideId: id }, index) => {
          const prevRide = bikeRouteRides[index - 1];
          const nextRide = bikeRouteRides[index + 1] || newRide;

          return BikeRouteService.updateBikeRouteRide(bikeRoute.id, id, {
            order_atob: index + 1,
            ride_atob_previous: prevRide?.rideId || null,
            ride_atob_next: nextRide?.rideId || null,
            order_btoa: bikeRouteRides.length - index + 1,
            ride_btoa_previous: nextRide?.rideId || null,
            ride_btoa_next: prevRide?.rideId || null,
          });
        }),
      );

      const updatedBikeRoute = await BikeRouteService.getBikeRoute(bikeRoute.id);

      enqueueSnackbar(t('cycling-insights.bike_route.updated'), { variant: 'success' });
      if (rides) onRidesChange([...rides, ride]);
      onClose(updatedBikeRoute);
    } catch {
      enqueueSnackbar(t('cycling-insights.bike_route.not_updated'), { variant: 'error' });
    }

    setSubmitting(false);
  }

  return (
    <Dialog
      isForm
      confirmDisabled={!ride}
      confirmTitle={<Trans i18nKey="commons.actions.add" />}
      dialogTitle="bike-route-step-form-dialog"
      loading={isSubmitting}
      maxWidth="xs"
      onCancel={onClose}
      onConfirm={handleSubmit}
      title={<Trans i18nKey="cycling-insights.bike_route.steps.form_dialog.title" />}
      {...props}
    >
      <Autocomplete
        autoComplete
        classes={ride || !values.inputValue ? { noOptions: classes.noOptionsHidden } : {}}
        disabled={isSubmitting}
        filterOptions={(x) => x}
        forcePopupIcon={Boolean(values.inputValue)}
        getOptionDisabled={(option) =>
          Boolean(bikeRoute.rides.find(({ rideId }) => rideId === option.id))
        }
        getOptionKey={(option) => option.id || -1}
        getOptionLabel={(option) => option.title || ''}
        id="bike-route-steps-autocomplete"
        loading={loading}
        loadingText={<Trans i18nKey="commons.loading" />}
        noOptionsText={!ride && values.inputValue && <Trans i18nKey="commons.no_result_found" />}
        onChange={(event, _ride) => setRide(_ride)}
        onInputChange={() => setOptions([])}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label={t('cycling-insights.bike_route.steps.actions.search')}
            margin="dense"
            name="inputValue"
            onChange={handleChange}
            value={values.inputValue}
            variant="outlined"
          />
        )}
        style={{ width: '100%' }}
        value={ride}
      />
    </Dialog>
  );
}

const useStyles = makeStyles({
  noOptionsHidden: {
    display: 'none',
  },
});

export default StepFormDialog;
