import {
  Report,
  ReportService,
  TReportTypeCode,
  TStatus,
  reportTypesMap,
} from '@geovelo-frontends/commons';
import { DeleteOutline } from '@mui/icons-material';
import {
  Box,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../app/context';
import RightPanelLayout from '../layouts/right-panel';

import Button from './button';

interface IValues {
  isValid?: boolean;
  type: TReportTypeCode | '';
  status: TStatus | '';
}

function ReportEdit({
  isBindToOSM,
  reloadTablePage,
  reloadTableReport,
  reports,
  selectedId,
  selectId,
  setEditing,
  setLoading,
  setReports,
}: {
  isBindToOSM?: boolean;
  reloadTablePage?: (removed?: boolean) => void;
  reloadTableReport?: (report: Report) => void;
  reports?: Report[];
  selectedId: number | null;
  selectId: (id: number | null) => void;
  setEditing: (isEditing: boolean) => void;
  setLoading: (loading: boolean) => void;
  setReports: (reports?: Report[]) => void;
}): JSX.Element {
  const [report, setReport] = useState<Report | null>();
  const {
    user: { current: currentUser },
    report: { types },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, expand] = useState(true);
  const { isSubmitting, values, errors, setValues, setFieldValue, handleChange, handleSubmit } =
    useFormik<IValues>({
      initialValues: {
        type: '',
        status: '',
      },
      onSubmit,
      enableReinitialize: true,
    });

  useEffect(() => {
    setReport(reports?.find(({ id }) => id === selectedId) || null);
  }, [reports, selectedId]);

  useEffect(() => {
    setValues({
      type:
        report?.typeCode === 'support' || report?.typeCode === 'exclusionZone'
          ? ''
          : report?.typeCode || '',
      status: report?.status === 'OPEN' ? 'OPEN' : 'CLOSED',
    });
  }, [report]);

  async function onSubmit(
    { isValid, type, status }: IValues,
    { setSubmitting, setErrors }: FormikHelpers<IValues>,
  ) {
    try {
      if (selectedId === null) return;

      if (!type) {
        setErrors({ type: 'Type is required' });

        return;
      } else if (!status) {
        setErrors({ status: 'Status is required' });
        return;
      }

      setErrors({});

      const selectedType = types?.find(({ code }) => code === type);
      setSubmitting(true);

      const updatedReport = await ReportService.updateReport(selectedId, {
        type: selectedType,
        status,
        isValid,
      });

      if (reports) {
        const index = reports.findIndex((report) => report.id === selectedId);
        if (index > -1) {
          // hack to prevent table reorder
          updatedReport.updated = reports[index].updated.clone();

          const newReports = [...reports];
          newReports.splice(index, 1, updatedReport);

          setReports(newReports);
        }
      }

      if (type && isBindToOSM !== types?.find((value) => value.code === type)?.isBindToOSM) {
        if (reports) {
          const newReports = [...reports];
          newReports.splice(
            newReports.findIndex(({ id }) => updatedReport.id === id),
            1,
          );

          setReports(newReports);
        }

        selectId(null);
        reloadTablePage?.(true);
      } else reloadTableReport?.(updatedReport);
      setLoading(false);
    } catch {
      enqueueSnackbar(t('commons.report.not_updated'));
      setSubmitting(false);
    }
  }

  async function handleRemove(): Promise<void> {
    if (!report) return;

    try {
      await ReportService.deleteReport(report.id);

      if (reports) {
        const newReports = [...reports];
        newReports.splice(
          newReports.findIndex(({ id }) => report.id === id),
          1,
        );

        setReports(newReports);
      }

      selectId(null);
      reloadTablePage?.(true);
    } catch {
      enqueueSnackbar(t('commons.report.not_deleted'));
    }
  }

  if (!report) return <></>;

  const { id, typeCode } = report;

  return (
    <>
      <RightPanelLayout
        actions={
          <Box alignItems="start" display="flex" flexGrow={1} justifyContent="space-between">
            <Button
              disabled={isSubmitting}
              onClick={() => {
                setEditing(false);
              }}
              size="large"
              variant="outlined"
            >
              <Trans i18nKey="commons.actions.cancel" />
            </Button>
            {values.status !== 'OPEN' && currentUser?.isGeovelo && (
              <Button
                disableElevation
                disabled={isSubmitting}
                onClick={() => {
                  setFieldValue('isValid', true);
                  handleSubmit();
                }}
                size="large"
                sx={{ maxWidth: '140px' }}
                variant="contained"
              >
                <Trans i18nKey="commons.report.actions.validate_and_reward" />
              </Button>
            )}
            <Button
              disableElevation
              disabled={isSubmitting}
              onClick={() => handleSubmit()}
              size="large"
              variant="contained"
            >
              <Trans i18nKey="commons.actions.validate" />
            </Button>
          </Box>
        }
        expand={expand}
        expanded={expanded}
        header={
          <Box alignItems="center" display="flex" gap={2}>
            <Typography fontSize="1.125rem" fontWeight={700} variant="h6">
              <Trans i18nKey="commons.actions.update" />{' '}
              <span style={{ textTransform: 'lowercase' }}>
                <Trans
                  i18nKey={
                    types?.find((value) => value.code === typeCode)?.titleKey ||
                    'commons.report.default_title'
                  }
                />
              </span>
              <>&nbsp;#{id}</>
            </Typography>
          </Box>
        }
      >
        <Box display="flex" flexDirection="column" paddingTop={3}>
          <Typography color="textSecondary" fontWeight={600} variant="body2">
            <Trans i18nKey="commons.stats.type_label" />
          </Typography>
          <FormControl error={Boolean(errors.type)} margin="dense" size="small" variant="outlined">
            <StyledSelect
              inputProps={{
                id: 'report-type-label',
              }}
              name="type"
              onChange={handleChange}
              value={values.type}
              variant="outlined"
            >
              {types
                ?.sort((a, b) =>
                  a.code === 'support'
                    ? 1
                    : b.code === 'support'
                      ? -1
                      : t(a.titleKey).localeCompare(t(b.titleKey)),
                )
                .map(({ code, titleKey }) => {
                  if (code === 'exclusionZone') return;
                  if (code === 'support')
                    return (
                      <MenuItem key={code} value={code}>
                        <ListItemText primary={<Trans i18nKey={titleKey} />} />
                      </MenuItem>
                    );

                  const { Icon, color } = reportTypesMap[code];

                  return (
                    <MenuItem key={code} value={code}>
                      <ListItemIcon>
                        <Icon fontSize="small" style={{ color }} />
                      </ListItemIcon>
                      <ListItemText primary={<Trans i18nKey={titleKey} />} />
                    </MenuItem>
                  );
                })}
            </StyledSelect>
          </FormControl>
          <Typography color="textSecondary" fontWeight={600} marginTop={3} variant="body2">
            <Trans i18nKey="commons.status" />
          </Typography>
          <FormControl
            error={Boolean(errors.status)}
            margin="dense"
            size="small"
            variant="outlined"
          >
            <StyledSelect
              inputProps={{
                id: 'report-status-label',
              }}
              name="status"
              onChange={handleChange}
              value={values.status}
              variant="outlined"
            >
              <MenuItem value="OPEN">
                <ListItemText primary={<Trans i18nKey="commons.statuses.unprocessed" />} />
              </MenuItem>
              <MenuItem value="CLOSED">
                <ListItemText primary={<Trans i18nKey="commons.statuses.processed" />} />
              </MenuItem>
            </StyledSelect>
          </FormControl>
          <Box display="flex" marginBottom={1} marginTop={3}>
            <Button
              color="error"
              onClick={() => handleRemove()}
              startIcon={<DeleteOutline />}
              variant="text"
            >
              <Trans i18nKey="commons.actions.remove" />
            </Button>
          </Box>
        </Box>
      </RightPanelLayout>
    </>
  );
}

const StyledSelect = styled(Select)`
  && .MuiSelect-select {
    align-items: center;
    display: flex;
  }

  && .MuiListItemIcon-root {
    min-width: 36px;
  }
`;

export default ReportEdit;
