import { Delete, Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { ThemeProvider as MuiThemeProvider, Theme } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';

import { IControl, Map } from '!maplibre-gl';

export class CustomControl implements IControl {
  private theme?: Theme;
  private map?: Map;
  private container?: HTMLDivElement;

  constructor(private id: string) {}

  onAdd(_map: Map): HTMLDivElement {
    this.map = _map;
    this.container = document.createElement('div');
    this.container.className = 'maplibregl-ctrl-group maplibregl-ctrl';

    const content = document.createElement('div');
    content.id = this.id;
    this.container.appendChild(content);

    return this.container;
  }

  init(theme: Theme, onEdit: () => void, onDelete: () => void): void {
    this.theme = theme;

    const ele = document.getElementById(this.id);
    if (!ele) return;

    const container = createRoot(ele);
    container.render(
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <IconButton onClick={onEdit} sx={{ borderRadius: 0 }}>
            <Edit fontSize="small" sx={{ color: 'black' }} />
          </IconButton>
          <IconButton onClick={onDelete} sx={{ borderRadius: 0 }}>
            <Delete fontSize="small" sx={{ color: 'black' }} />
          </IconButton>
        </ThemeProvider>
      </MuiThemeProvider>,
    );
  }

  onRemove(): void {
    this.container?.parentNode?.removeChild(this.container);
    this.map = undefined;
  }
}
