import { useState } from 'react';

import { TRow } from '../../components/table';

function useSelectableTable<TKey extends string | number, TCellKey extends string>(): {
  selectedKeys: TKey[];
  onToggleKey: (key: TKey) => void;
  onSelectAllKeys: (rows: TRow<TKey, TCellKey>[]) => void;
  onUnselectAllKeys: () => void;
} {
  const [selectedKeys, selectKeys] = useState<TKey[]>([]);

  function onToggleKey(key: TKey) {
    const index = selectedKeys.indexOf(key);
    if (index === -1) selectKeys([...selectedKeys, key]);
    else {
      selectedKeys.splice(index, 1);
      selectKeys([...selectedKeys]);
    }
  }

  function onSelectAllKeys(rows: TRow<TKey, TCellKey>[]) {
    selectKeys([...rows.map(({ key }) => key)]);
  }

  function onUnselectAllKeys() {
    selectKeys([]);
  }

  return { selectedKeys, onToggleKey, onSelectAllKeys, onUnselectAllKeys };
}

export default useSelectableTable;
