import { Close, InfoOutlined } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

type TProps = Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
};

function BNACDialog({ onClose, ...props }: TProps): JSX.Element {
  const theme = useTheme();
  return (
    <StyledDialog
      color="primary"
      onClose={onClose}
      {...props}
      PaperProps={{
        style: {
          backgroundColor: '#326ac2',
          color: theme.palette.primary.contrastText,
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.primary.contrastText,
        }}
      >
        <Close />
      </IconButton>
      <StyledDialogContent>
        <InfoOutlined />
        <Typography variant="body1">
          <Trans
            components={[<br key={0} />]}
            i18nKey="cycling-insights.facilities.bnac_dialog.content"
          />
        </Typography>
      </StyledDialogContent>
    </StyledDialog>
  );
}

const StyledDialog = styled(Dialog)`
  && > .MuiBackdrop-root {
    background-color: transparent;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export default BNACDialog;
