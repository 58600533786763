import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import List, { IItem } from '../../../../components/list';

interface IProps {
  modifications?: { [id: number]: { amenagement?: string; highway?: string; surface?: string } };
  onRemove: (id: number) => void;
  selectIndex: (index: number) => void;
  selectedIndex: number | null;
}

function ChangesList({ modifications, selectedIndex, selectIndex, onRemove }: IProps): JSX.Element {
  const [items, setItems] = useState<IItem<number>[]>();

  useEffect(() => {
    if (modifications)
      setItems(
        Object.keys(modifications).map((id) => ({
          key: parseInt(id),
          title: id,
        })),
      );
    else setItems(undefined);
  }, [modifications]);

  return (
    <List
      dense
      items={items}
      onClick={(value) => {
        if (!items) return;
        selectIndex(items[value].key);
      }}
      onRemove={onRemove}
      permission="write"
      selectedIndex={items?.findIndex(({ key }) => key === selectedIndex)}
      title={<Trans i18nKey="cycling-insights.usage.travel_simulations.changes_list" />}
    />
  );
}

export default ChangesList;
