import {
  CyclewayIcon,
  DedicatedRoadsLegendIcon,
  Facilities,
  GreenwayIcon,
  LaneIcon,
  MixedFacilitiesIcon,
  OppositeIcon,
  OppositesLegendIcon,
  SharedBuswayIcon,
  SharedRoadsLegendIcon,
  TrafficCalmingZonesLegendIcon,
} from '@geovelo-frontends/commons';
import { SvgIconProps } from '@mui/material';

export const facilities: {
  Icon: (props: SvgIconProps) => JSX.Element;
  key: Facilities;
  LegendIcon: (props: SvgIconProps) => JSX.Element;
  titleKey: string;
}[] = [
  {
    key: Facilities.Cycleways,
    Icon: CyclewayIcon,
    titleKey: 'commons.facilities.cycleway_other',
    LegendIcon: DedicatedRoadsLegendIcon,
  },
  {
    key: Facilities.Greenways,
    Icon: GreenwayIcon,
    titleKey: 'commons.facilities.greenway_other',
    LegendIcon: DedicatedRoadsLegendIcon,
  },
  {
    key: Facilities.Lanes,
    Icon: LaneIcon,
    titleKey: 'commons.facilities.lane_other',
    LegendIcon: SharedRoadsLegendIcon,
  },
  {
    key: Facilities.Opposites,
    Icon: OppositeIcon,
    titleKey: 'commons.facilities.opposite_other',
    LegendIcon: OppositesLegendIcon,
  },
  {
    key: Facilities.SharedBusways,
    Icon: SharedBuswayIcon,
    titleKey: 'commons.facilities.shared_busway_other',
    LegendIcon: SharedRoadsLegendIcon,
  },
  {
    key: Facilities.MixedFacilities,
    Icon: MixedFacilitiesIcon,
    titleKey: 'commons.facilities.mixed_facilities_other',
    LegendIcon: TrafficCalmingZonesLegendIcon,
  },
];
