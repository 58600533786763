import { TReportTypeCode, currentMonth, currentYear } from '@geovelo-frontends/commons';
import { Add, FileDownloadOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';

import { AppContext } from '../../../../../app/context';
import ReportDetails from '../../../../../components/report-details';
import ReportsForm from '../../../../../components/reports/form';
import useReports from '../../../../../hooks/reports';
import { TOutletContext } from '../../../../../layouts/page/container';
import PageContentLayout from '../../../../../layouts/page/content';
import { TCartographicDataPageContext } from '../../../context';

function FacilitiesReportsTab(): JSX.Element {
  const context = useOutletContext<TCartographicDataPageContext & TOutletContext>();
  const [initialized, setInitialized] = useState(false);
  const [typeCodes] = useState<TReportTypeCode[]>(['bikeFacility', 'cartographicIssue']);
  const {
    report: { types: reportTypes },
  } = useContext(AppContext);
  const {
    period,
    header: { setPrevButtonClick, setTitle, setActions },
    facilities,
    setLoading,
  } = context;
  const { reports, selectedReportId, reportsTableRef, setReports } = facilities;
  const navigate = useNavigate();
  const { handleDownload } = useReports({ period, reportTypes, reports });

  useEffect(() => {
    period.setValues({ current: currentYear, prev: currentYear.getPrevPeriod() });
    setPrevButtonClick(() => () => navigate('../facilities'));
    setTitle(<Trans i18nKey="cycling-insights.reports.navigation.osm_cartographic_reports" />);
    setInitialized(true);

    return () => {
      period.setValues({ current: currentMonth, prev: currentMonth.getPrevPeriod() });
      setPrevButtonClick(undefined);
      setTitle(undefined);
      setActions(undefined);
    };
  }, []);

  useEffect(() => {
    setActions(
      <>
        <Tooltip title={<Trans i18nKey="cycling-insights.reports.navigation.new_report" />}>
          <IconButton color="primary" component={Link} size="small" to="./new-report">
            <Add />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={<Trans i18nKey="cycling-insights.reports.osm_cartographic_reports.download" />}
        >
          <span>
            <IconButton
              color="primary"
              disabled={!reports}
              onClick={() => handleDownload()}
              size="small"
            >
              <FileDownloadOutlined />
            </IconButton>
          </span>
        </Tooltip>
      </>,
    );
  }, [reports]);

  return (
    <PageContentLayout
      leftPanel={
        <>
          {initialized && (
            <ReportsForm
              context={facilities}
              period={period}
              setLoading={setLoading}
              typeCodes={typeCodes}
            />
          )}
        </>
      }
      rightPanel={
        <ReportDetails
          isBindToOSM
          reloadTableReport={reportsTableRef.current?.reloadTableReport}
          reports={reports}
          selectedId={selectedReportId}
          setReports={setReports}
        />
      }
    />
  );
}

export default FacilitiesReportsTab;
