import { Skeleton } from '@mui/material';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import Card from '../../../../components/card';
import DualBarStats from '../../../../components/dual-bar-stats';

interface IProps {
  failedCount?: number;
  succeededCount?: number;
}

function RefRoutesChart({ succeededCount, failedCount }: IProps): JSX.Element {
  const totalCount =
    succeededCount !== undefined && failedCount !== undefined
      ? succeededCount + failedCount
      : undefined;

  return (
    <StyledCard
      title={
        totalCount !== undefined ? (
          <Trans
            count={totalCount}
            i18nKey="cycling-insights.qa.ref_routes.title"
            values={{ count: totalCount }}
          />
        ) : (
          <Skeleton variant="text" width={200} />
        )
      }
    >
      <StyledContent>
        <DualBarStats
          errorCount={failedCount || 0}
          errorLabelKey="cycling-insights.qa.ref_routes.failed"
          loading={totalCount === undefined}
          successCount={succeededCount || 0}
          successLabelKey="cycling-insights.qa.ref_routes.succeeded"
        />
      </StyledContent>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  flex: 1;
  height: fit-content;
`;

const StyledContent = styled.div`
  padding: 24px;
`;

export default RefRoutesChart;
