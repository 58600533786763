import {
  ComputedRoute,
  FacilitiesToggle,
  Place,
  Ride,
  RideTrace,
  Route,
  Search,
  TWayPoint,
  useFacilities,
} from '@geovelo-frontends/commons';
import { Paper } from '@mui/material';
import { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { AppContext } from '../../../../app/context';
import Map from '../../../../components/map';
import useRideAdmin from '../../../../hooks/map/ride-admin';

import { LngLatBounds, Map as MaplibreMap } from '!maplibre-gl';

interface IProps {
  canWrite: boolean;
  computedReturnRoute: ComputedRoute | null;
  computedRoute: ComputedRoute | null;
  computedReturnRouteUpdated: boolean;
  computedRouteUpdated: boolean;
  isDeletedRoute: boolean;
  isReturnRoute: boolean;
  editing: boolean;
  map?: MaplibreMap;
  onInit: (map: MaplibreMap) => void;
  onWayPointsUpdate: (wayPoints: TWayPoint[]) => void;
  onWayPointClicked: (wayPoints: TWayPoint[], index: number) => void;
  ride: Ride;
  route?: Route | null;
  returnRoute?: Route | null;
  search: Search;
  searchedPlace: Place | null;
  setReturnRoute: (isReturnRoute: boolean) => void;
  setComputedRouteUpdated: (value: boolean) => void;
  setComputedReturnRouteUpdated: (value: boolean) => void;
  traces?: RideTrace[];
}

function RouteMap({
  canWrite,
  editing,
  map,
  ride,
  search,
  route,
  returnRoute,
  traces,
  computedReturnRoute,
  computedRoute,
  computedReturnRouteUpdated,
  computedRouteUpdated,
  isDeletedRoute,
  isReturnRoute,
  searchedPlace,
  onInit,
  onWayPointsUpdate,
  onWayPointClicked,
  setComputedReturnRouteUpdated,
  setComputedRouteUpdated,
}: IProps): JSX.Element {
  const {
    init,
    enableEdition,
    enableEditionReturnRoute,
    updatePreview,
    updateRoute,
    updateTraces,
    updateSteps,
  } = useRideAdmin(map, canWrite, {
    onWayPointsUpdate,
    onWayPointClicked,
  });
  const {
    map: { facilitiesShowed },
    actions: { toggleFacilities: toggleFacilitiesOnMap },
  } = useContext(AppContext);
  const {
    initialized: facilitiesInitialized,
    init: initFacilities,
    toggle: toggleFacilities,
  } = useFacilities(map);

  useEffect(() => {
    if (map) {
      init();
      initFacilities(facilitiesShowed);
    }
  }, [map]);

  useEffect(() => {
    if (facilitiesInitialized) toggleFacilities(facilitiesShowed);
  }, [facilitiesShowed]);

  useEffect(() => {
    if (isDeletedRoute === true && editing === true) {
      updatePreview(ride, null, null, true);
    }
  }, [isDeletedRoute]);

  useEffect(() => {
    if (map && ride) {
      updateSteps(ride.steps, false);
    }
  }, [map, ride]);

  useEffect(() => {
    if (!map) return;

    if (editing) {
      enableEditionReturnRoute(isReturnRoute);
      enableEdition(true);
      if (isReturnRoute === true) {
        updatePreview(ride, route, null, true);
      } else {
        updatePreview(ride, null, returnRoute, true);
      }
      updateRoute((isReturnRoute === true ? returnRoute : route) || null, { hasDirection: true });
    } else {
      const { bounds: _bounds } = ride;

      enableEdition(false);
      updateRoute(null);
      updatePreview(ride, route, returnRoute);

      if (_bounds) {
        const { north, east, south, west } = _bounds;
        map.fitBounds(new LngLatBounds({ lat: south, lng: west }, { lat: north, lng: east }));
      }
    }
  }, [map, ride, editing]);

  useEffect(() => {
    if (map && traces) {
      updateTraces(traces);

      if (!editing && !ride.condensedGeometry && traces.length > 0) {
        let { north, east, south, west } = traces[0].bounds;
        const bounds = new LngLatBounds({ lat: south, lng: west }, { lat: north, lng: east });

        traces.slice(1).forEach(({ bounds: _bounds }) => {
          ({ north, east, south, west } = _bounds);
          const traceBounds = new LngLatBounds(
            { lat: south, lng: west },
            { lat: north, lng: east },
          );

          bounds?.extend(traceBounds);
        });

        map.fitBounds(bounds);
      }
    }
  }, [map, traces]);

  useEffect(() => {
    if (computedReturnRouteUpdated) {
      if (editing === true) {
        if (isReturnRoute) {
          updateRoute(computedReturnRoute, {
            allowWayPointsSnapping: true,
            search,
            hasDirection: true,
          });
        } else {
          updatePreview(ride, null, isDeletedRoute ? null : computedReturnRoute, true);
        }
      }
      setComputedReturnRouteUpdated(false);
    }
  }, [computedReturnRouteUpdated]);

  useEffect(() => {
    if (computedRouteUpdated) {
      if (editing === true) {
        if (isReturnRoute) {
          updatePreview(ride, computedRoute, null, true);
        } else {
          updateRoute(computedRoute, { allowWayPointsSnapping: true, search, hasDirection: true });
        }
      }
      setComputedRouteUpdated(false);
    }
  }, [computedRouteUpdated]);

  useEffect(() => {
    if (map && searchedPlace) {
      const {
        point: {
          coordinates: [lng, lat],
        },
      } = searchedPlace;
      map.flyTo({ center: { lat, lng }, zoom: 17 });
    }
  }, [searchedPlace]);

  return (
    <Wrapper>
      <StyledMap mapId="ride-route-map" onInit={onInit}>
        <FacilitiesToggleWrapper>
          <FacilitiesToggle
            facilitiesShowed={facilitiesShowed}
            sx={{ maxHeight: '100%', overflow: 'hidden' }}
            toggleFacilities={toggleFacilitiesOnMap}
          />
        </FacilitiesToggleWrapper>
      </StyledMap>
    </Wrapper>
  );
}

const Wrapper = styled(Paper)`
  flex-grow: 1;
  position: relative;
`;

const StyledMap = styled(Map)`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const FacilitiesToggleWrapper = styled.div`
  bottom: 44px;
  left: 10px;
  pointer-events: none;
  position: absolute;
  top: 10px;
  z-index: 2;
`;

export default RouteMap;
