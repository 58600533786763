import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function SuddenBrakingTooltip({
  object: {
    properties: { count },
  },
}: {
  object: GeoJSON.Feature<GeoJSON.Point, { count: number }>;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Typography fontWeight={600} variant="body2">
      {t('cycling-insights.usage.accidentology.sudden_brakings', { count })}
    </Typography>
  );
}

export default SuddenBrakingTooltip;
