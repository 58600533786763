import { ParkingTypes } from '@geovelo-frontends/commons';
import { SvgIconProps } from '@mui/material';

import { ArchesIcon } from '../components/icons';
import FreeAccessIcon from '../components/icons/free-access';
import OpenSheltersIcon from '../components/icons/open-shelters';
import PrivateAccessIcon from '../components/icons/private-access';
import RacksIcon from '../components/icons/racks';
import SecureSheltersIcon from '../components/icons/secure-shelters';

export const parkingsTypes: {
  Icon: (props: SvgIconProps) => JSX.Element;
  key: ParkingTypes;
  LegendIcon: (props: SvgIconProps) => JSX.Element;
  titleKey: string;
}[] = [
  {
    key: ParkingTypes.Arch,
    Icon: ArchesIcon,
    titleKey: 'commons.parking_types.arches',
    LegendIcon: ArchesIcon,
  },
  {
    key: ParkingTypes.Sheltered,
    Icon: OpenSheltersIcon,
    titleKey: 'commons.parking_types.sheltered',
    LegendIcon: OpenSheltersIcon,
  },
  {
    key: ParkingTypes.Free,
    Icon: FreeAccessIcon,
    titleKey: 'commons.parking_types.free',
    LegendIcon: FreeAccessIcon,
  },
  {
    key: ParkingTypes.Rack,
    Icon: RacksIcon,
    titleKey: 'commons.parking_types.racks',
    LegendIcon: RacksIcon,
  },
  {
    key: ParkingTypes.Secure,
    Icon: SecureSheltersIcon,
    titleKey: 'commons.parking_types.secure',
    LegendIcon: SecureSheltersIcon,
  },
  {
    key: ParkingTypes.Private,
    Icon: PrivateAccessIcon,
    titleKey: 'commons.parking_types.private',
    LegendIcon: PrivateAccessIcon,
  },
  {
    key: ParkingTypes.Locked,
    Icon: SecureSheltersIcon, // TODO: change me
    titleKey: 'commons.parking_types.locked',
    LegendIcon: SecureSheltersIcon, // TODO: change me
  },
];
