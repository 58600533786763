import { useOutletContext } from 'react-router-dom';

import PageContentLayout from '../../../../layouts/page/content';
import { TParkingsPageContext } from '../../context';

import ParkingsStatsTable from './table';

function ParkingsStatsTab(): JSX.Element {
  const context = useOutletContext<TParkingsPageContext>();

  return <PageContentLayout main={<ParkingsStatsTable {...context} />} />;
}

export default ParkingsStatsTab;
