import { useUnits } from '@geovelo-frontends/commons';
import { Box, Skeleton, Typography } from '@mui/material';
import { ReactNode } from 'react';

function Stat({
  label,
  value,
  progression,
  progressionUnit,
}: {
  label: ReactNode;
  progression?: number;
  progressionUnit?: 'distance' | 'duration' | 'speed';
  value?: ReactNode;
}): JSX.Element {
  const { toDistance, toTime, toSpeed } = useUnits();

  return (
    <Box display="flex" flexDirection="column">
      <Typography color="textSecondary">{label}</Typography>
      <Box alignItems="center" columnGap={2} display="flex" flexWrap="wrap" minHeight={32}>
        <Typography color="#528be8" variant="h6">
          {value || value === 0 ? value : <Skeleton variant="text" width={150} />}
        </Typography>
        {progression !== undefined && progression > 0 && (
          <Box
            borderRadius={1}
            paddingX={1}
            paddingY={0.5}
            sx={{ backgroundColor: '#2ac682', color: '#fff' }}
          >
            <Typography noWrap>
              {progression !== undefined ? (
                `+${
                  progressionUnit === 'distance'
                    ? toDistance(progression)
                    : progressionUnit === 'duration'
                      ? toTime(progression)
                      : progressionUnit === 'speed'
                        ? toSpeed(progression)
                        : `${progression} %`
                }`
              ) : (
                <Skeleton variant="text" width={50} />
              )}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Stat;
