import { useUnits } from '@geovelo-frontends/commons';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import type { TColorCollection, TThicknessCollection } from '../../components/color-legend';
import { getFacilityLabelKey } from '../../components/form/facilities';
import { IPeriodFormProps } from '../../components/form/period';
import { ISliderBounds, TSliderRange } from '../../components/form/slider';
import { TSectionFeatureCollection } from '../../models/sections';
import usePeriod from '../period';

import useHeatmap from './heatmap';

import { Map } from '!maplibre-gl';

export const colors: TColorCollection = [
  { value: '#F56B84', min: 0, max: 16 },
  { value: '#FFD978', min: 16, max: 20 },
  { value: '#46CE9D', min: 20, max: Infinity },
];

export const colorsComparison: TColorCollection = [
  { value: '#a50026' },
  { value: '#d73027' },
  { value: '#f46d43' },
  { value: '#fdae61' },
  { value: '#fee08b' },
  { value: '#ffffbf' },
  { value: '#d9ef8b' },
  { value: '#a6d96a' },
  { value: '#66bd63' },
  { value: '#1a9850' },
  { value: '#006837' },
];

const widths: TThicknessCollection = [
  { value: 2 },
  { value: 4 },
  { value: 6 },
  { value: 8 },
  { value: 10 },
];

function useAverageSpeeds(
  map: Map | null | undefined,
  period: IPeriodFormProps,
): {
  initialized: boolean;
  init: () => void;
  update: (
    { features }: TSectionFeatureCollection,
    props: {
      colors?: TColorCollection;
      comparisonEnabled?: boolean;
      currentRange?: TSliderRange;
      primaryBounds: ISliderBounds;
      secondaryBounds?: ISliderBounds;
    },
  ) => void;
  destroy: () => void;
} {
  const { t } = useTranslation();
  const { toSpeed } = useUnits();
  const { getTitle: getPeriod } = usePeriod();
  const periodRef = useRef(period);

  useEffect(() => {
    periodRef.current = period;
  }, [period]);

  return useHeatmap(
    map,
    {
      sourceId: 'average-speeds',
      layerId: 'average-speeds',
      primaryCriterion: 'averageSpeed',
      secondaryCriterion: 'frequency',
      colors: period.comparisonEnabled ? colorsComparison : colors,
      widths,
    },
    function averageSpeedsTooltipContent({
      averageSpeed,
      frequency,
      currentAverageSpeed,
      prevAverageSpeed,
      facility,
      wayName,
    }) {
      if (!averageSpeed && averageSpeed !== 0) return '';
      const {
        values: { current, prev },
        comparisonEnabled,
      } = periodRef.current;
      const rounding = 10;
      const roundedSpeed = Math.round(averageSpeed * rounding) / rounding;

      if (comparisonEnabled) {
        if (currentAverageSpeed === undefined || prevAverageSpeed === undefined) return '';

        const roundedPrevSpeed = Math.round(prevAverageSpeed * rounding) / rounding;
        const roundedCurrentSpeed = Math.round(currentAverageSpeed * rounding) / rounding;
        return (
          `<h3>${t('cycling-insights.usage.average_speeds.tooltip.title', {
            period: getPeriod(prev) + ' - ' + getPeriod(current),
          })}</h3>` +
          (wayName ? `<div>${wayName}</div>` : '') +
          (facility && facility !== 'none'
            ? `<div>${t(getFacilityLabelKey(facility))}</div>`
            : '') +
          `<div>${(roundedSpeed < 0 ? '' : '+') + toSpeed(roundedSpeed)}</div>` +
          `<div>${getPeriod(prev)}: ${toSpeed(roundedPrevSpeed)}<div/>` +
          `<div>${getPeriod(current)}: ${toSpeed(roundedCurrentSpeed)}<div/>` +
          `<div>${t('commons.stats.passages', {
            count: frequency,
          })}</div>`
        );
      } else {
        return (
          `<h3>${t('cycling-insights.usage.average_speeds.tooltip.title', {
            period: getPeriod(current),
          })}</h3>` +
          (wayName ? `<div>${wayName}</div>` : '') +
          (facility && facility !== 'none'
            ? `<div>${t(getFacilityLabelKey(facility))}</div>`
            : '') +
          `<div>${toSpeed(roundedSpeed)}</div>` +
          `<div style="font-size: 0.65rem;">${t('commons.stats.passages', {
            count: frequency,
          })}</div>`
        );
      }
    },
  );
}

export default useAverageSpeeds;
