import {
  FileInput,
  Geogroup,
  GeogroupService,
  LinkIcon,
  Partner,
  PartnerService,
  TFile,
} from '@geovelo-frontends/commons';
import { Box, DialogProps, InputAdornment, TextField, Typography } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { AppContext } from '../app/context';

import Dialog from './dialog';

const dialogTitle = 'edit-partner-dialog';

type TValues = {
  description: string;
  linkLabel: string;
  linkUrl: string;
  title: string;
};

type TProps = Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
  partnerToEdit: Partner;
};

function EditPartnerDialog({ partnerToEdit, onClose, ...props }: TProps): JSX.Element {
  const [geogroup, setGeogroup] = useState<Geogroup | null>();
  const [icon, setIcon] = useState<TFile>();
  const [bannerPicture, setBannerPicture] = useState<TFile>();
  const {
    user: { current: currentUser },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isSubmitting,
    values,
    touched,
    errors,
    setValues,
    setFieldValue,
    setTouched,
    handleChange,
    handleSubmit,
  } = useFormik<TValues>({
    initialValues: {
      title: '',
      description: '',
      linkUrl: '',
      linkLabel: '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(),
    }),
    onSubmit,
    enableReinitialize: true,
    validateOnMount: true,
  });

  useEffect(() => {
    if (props.open) {
      const { title, icon } = partnerToEdit;

      setValues({ title, description: '', linkUrl: '', linkLabel: '' });
      setTouched({ title: false });
      if (icon) setIcon({ url: icon, name: icon.substring(icon.lastIndexOf('/') + 1) });
      getGeogroup();
    }
  }, [props.open]);

  async function getGeogroup() {
    if (!partnerToEdit.geoGroupId) {
      setGeogroup(null);
      return;
    }

    try {
      const geogroup = await GeogroupService.getGeogroup({ geoGroupId: partnerToEdit.geoGroupId });
      if (geogroup.bannerPicture)
        setBannerPicture({
          url: `${geogroup.bannerPicture}`,
          name: geogroup.bannerPicture.substring(geogroup.bannerPicture.lastIndexOf('/') + 1),
        });
      setFieldValue('description', geogroup.description || '');
      setFieldValue('linkUrl', geogroup.link?.url || '');
      setFieldValue('linkLabel', geogroup.link?.label || '');
      setGeogroup(geogroup);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t('cycling-insights.edit_partner_dialog.geogroup_not_retrieved'), {
        variant: 'error',
      });
    }
  }

  async function onSubmit(
    { title, description, linkUrl, linkLabel }: TValues,
    { setSubmitting }: FormikHelpers<TValues>,
  ) {
    if (!currentUser) return;

    setSubmitting(true);

    try {
      await Promise.all([
        PartnerService.editPartner(
          partnerToEdit,
          {
            title,
            icon: icon instanceof File || icon === null ? icon : undefined,
          },
          currentUser.isGeovelo,
        ),
        partnerToEdit.geoGroupId
          ? GeogroupService.updateGeogroup(
              partnerToEdit.geoGroupId,
              {
                name: title,
                icon: icon instanceof File || icon === null ? icon : undefined,
                bannerPicture:
                  bannerPicture instanceof File || bannerPicture === null
                    ? bannerPicture
                    : undefined,
                description,
                linkUrl,
                linkLabel: linkUrl ? linkLabel : '',
              },
              partnerToEdit,
            )
          : null,
      ]);

      onClose();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t('cycling-insights.edit_partner_dialog.not_updated'), {
        variant: 'error',
      });
    }

    setSubmitting(false);
  }

  return (
    <Dialog
      disableEscapeKeyDown
      isForm
      confirmTitle={<Trans i18nKey="commons.actions.update" />}
      dialogTitle={dialogTitle}
      loading={isSubmitting || geogroup === undefined}
      maxWidth="sm"
      onCancel={() => onClose()}
      onConfirm={handleSubmit}
      scroll="paper"
      title={<Trans i18nKey="cycling-insights.edit_partner_dialog.title" />}
      {...props}
    >
      <Box display="flex" flexDirection="column" gap={3} marginTop={1}>
        <TextField
          fullWidth
          required
          disabled={isSubmitting}
          error={touched.title && Boolean(errors.title)}
          label={t('commons.title')}
          margin="none"
          name="title"
          onChange={handleChange}
          size="small"
          value={values.title}
          variant="outlined"
        />
        <TextField
          fullWidth
          multiline
          disabled={isSubmitting}
          error={touched.description && Boolean(errors.description)}
          id="description"
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 500 }}
          label={t('commons.description')}
          margin="none"
          name="description"
          onChange={handleChange}
          placeholder={t(
            'companies.pages.admin.company.data.general_data_form.placeholders_description',
          )}
          rows={2}
          value={values.description}
          variant="outlined"
        />
        <Box marginTop={-1}>
          <Typography color="textSecondary" variant="caption">
            {t('cycling-insights.edit_partner_dialog.logo')}
          </Typography>
          <FileInput
            disabled={isSubmitting}
            file={icon}
            onChange={setIcon}
            size="small"
            type="image"
          />
        </Box>
        <Box marginTop={-1}>
          <Typography color="textSecondary" variant="caption">
            {t('cycling-insights.edit_partner_dialog.banner')}
          </Typography>
          <FileInput
            disabled={isSubmitting}
            file={bannerPicture}
            onChange={setBannerPicture}
            size="small"
            type="image"
          />
        </Box>
        <TextField
          fullWidth
          disabled={isSubmitting}
          error={touched.linkUrl && Boolean(errors.linkUrl)}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LinkIcon color="primary" />
              </InputAdornment>
            ),
          }}
          label={t('cycling-insights.edit_partner_dialog.link')}
          margin="none"
          name="linkUrl"
          onChange={handleChange}
          size="small"
          type="url"
          value={values.linkUrl}
          variant="outlined"
        />
        <TextField
          fullWidth
          disabled={isSubmitting}
          InputLabelProps={{ shrink: true }}
          label={t('cycling-insights.edit_partner_dialog.link_text')}
          margin="none"
          name="linkLabel"
          onChange={handleChange}
          size="small"
          value={values.linkLabel}
          variant="outlined"
        />
      </Box>
    </Dialog>
  );
}

export default EditPartnerDialog;
