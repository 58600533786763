import { Box, CircularProgress, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';

type TLabel =
  | 'mapping'
  | 'lubrication'
  | 'passages'
  | 'clamping'
  | 'frequency'
  | 'brakes'
  | 'speed'
  | 'inflation';

const labels: TLabel[] = [
  'mapping',
  'lubrication',
  'passages',
  'clamping',
  'frequency',
  'brakes',
  'speed',
  'inflation',
];

function MapLoader(): JSX.Element {
  const index = useRef(Math.floor(Math.random() * labels.length));
  const [label, setLabel] = useState<TLabel>(labels[index.current]);

  useEffect(() => {
    const interval = setInterval(() => {
      index.current = (index.current + 1) % 8;
      setLabel(labels[index.current]);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      position="absolute"
      right={0}
      sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
      top={0}
      width="100%"
      zIndex={1002}
    >
      <Box
        alignItems="center"
        border="1px solid rgba(0, 0, 0, 0.23)"
        borderRadius={3}
        display="flex"
        flexDirection="column"
        gap={2}
        paddingX={4}
        paddingY={2}
        sx={{ backgroundColor: '#fff' }}
      >
        <CircularProgress color="primary" />
        <Typography>
          <Trans i18nKey={`commons.loading_text.${label}`} />
        </Typography>
      </Box>
    </Box>
  );
}

export default MapLoader;
