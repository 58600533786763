import { Period } from '@geovelo-frontends/commons';
import { KeyboardArrowRight } from '@mui/icons-material';
import { cellToLatLng } from 'h3-js';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import { HexagonIcon } from '../../../../components/icons';
import Ranking from '../../../../components/ranking';
import { TH3CellData } from '../../models/accidentology';

export function sortData(
  h3Map: { [key: string]: { id: number } },
  _data: { [h3Index: string]: TH3CellData },
) {
  const data = Object.values(_data).filter(({ h3Index, score }) => h3Map[h3Index] && score > 0);

  const { frequencies, scores } = data.reduce<{ frequencies: number[]; scores: number[] }>(
    (res, { frequency, score }) => {
      res.frequencies.push(frequency);
      res.scores.push(score);

      return res;
    },
    { frequencies: [], scores: [] },
  );
  const nbPoints = data.length;

  let x = 0,
    x2 = 0,
    y = 0,
    xy = 0;

  for (let i = 0; i < nbPoints; ++i) {
    const xi = frequencies[i];
    const yi = scores[i];

    x += xi;
    x2 += xi ** 2;
    y += yi;
    xy += xi * yi;
  }

  const a = (nbPoints * xy - x * y) / (nbPoints * x2 - x ** 2);
  const b = (x2 * y - x * xy) / (nbPoints * x2 - x ** 2);

  const dataWithLinearRegression: Array<TH3CellData & { delta: number }> = [...data].map(
    (cellData) => ({
      ...cellData,
      delta: cellData.score - (cellData.frequency * a + b),
    }),
  );

  return dataWithLinearRegression.sort((c1, c2) => c2.delta - c1.delta);
}

function AccidentZonesRanking({
  period,
  h3Map,
  data,
  enableTitle,
  selectedH3Index,
  selectH3Index,
}: {
  data?: TH3CellData[];
  enableTitle?: boolean;
  h3Map?: { [key: string]: { id: number } };
  period: Period;
  selectH3Index: (h3Index: string | null) => void;
  selectedH3Index?: string | null;
}): JSX.Element {
  const {
    map: { current: currentMap },
  } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Ranking
      enableSubtitles
      action={
        enableTitle && (
          <Button
            component={Link}
            endIcon={<KeyboardArrowRight />}
            to="../accident-zones"
            variant="text"
          >
            {t('cycling-insights.usage.accidentology.main_accident_zones.actions.all')}
          </Button>
        )
      }
      data={
        data && h3Map
          ? data.map(({ h3Index, accidents, reports, suddenBrakings, blackSpots, frequency }) => ({
              id: h3Index,
              title: t('cycling-insights.usage.accidentology.h3_cell_tooltip.title', {
                id: h3Map[h3Index]?.id || h3Index,
              }),
              subtitle: (
                <>
                  {t('commons.stats.passages_per_day', {
                    count: frequency,
                    trafficPerDay: Math.round(frequency / period.nbDays),
                  })}
                  <br />
                  {[
                    accidents
                      ? t('cycling-insights.usage.accidentology.accidents', {
                          count: accidents,
                        })
                      : '',
                    reports
                      ? t('cycling-insights.usage.accidentology.reports', {
                          count: reports,
                        })
                      : '',
                    suddenBrakings
                      ? t('cycling-insights.usage.accidentology.sudden_brakings', {
                          count: suddenBrakings,
                        })
                      : '',
                    blackSpots
                      ? t('cycling-insights.usage.accidentology.black_spots', {
                          count: blackSpots,
                        })
                      : '',
                  ]
                    .filter(Boolean)
                    .join(', ')}
                </>
              ),
            }))
          : undefined
      }
      onClick={(key) => {
        const res = data?.find(({ h3Index }) => h3Index === key);
        if (!res) return;

        const { h3Index } = res;

        const [lat, lng] = cellToLatLng(h3Index);
        currentMap?.flyTo({ center: { lat, lng }, zoom: 17, maxDuration: 500 });
        setTimeout(() => selectH3Index(h3Index), 500);
      }}
      selectedKey={selectedH3Index}
      startIcon={<HexagonIcon />}
      subtitlesLinesCount={2}
      title={
        enableTitle ? 'cycling-insights.usage.accidentology.main_accident_zones.title' : undefined
      }
    />
  );
}

export default AccidentZonesRanking;
