import {
  CyclabilityZoneService,
  OriginDestinationService,
  TPeriod,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import illuFlux from '../../../../assets/images/illu-flux.png';
import PeriodForm, { IPeriodFormValues } from '../../../../components/form/period';
import LinkCard from '../../../../components/link-card';
import Paper from '../../../../components/paper';
import TabIntroduction from '../../../../components/tab-introduction';
import useOriginDestinationFlows from '../../../../hooks/map/old-origin-destination-flows';
import { TOutletContext } from '../../../../layouts/page/container';
import { toOriginDestinationInput } from '../../../../models/origin-destination-form';
import { IBicycleObservatoryPageContext } from '../../context';
import JourneysType, { TJourneysType } from '../origin-destination/journeys-type';
import MainFlows from '../origin-destination/main-flows';

import OriginDestinationChart from './chart';

function OriginDestinationForm(
  context: IBicycleObservatoryPageContext & TOutletContext,
): JSX.Element {
  const {
    defaultPeriods,
    period,
    header: { setPrevButtonClick, setTitle },
    oldOriginDestination: {
      canvasRef,
      zones,
      externalZones,
      flows,
      currentRange,
      selectedZoneId,
      setCurrentRange,
      setZones,
      setExternalZones,
      setFlows,
      setBounds,
      selectZoneId,
    },
    setLoading,
  } = context;
  const [customPeriodTypes] = useState<{
    defaultPeriods: IPeriodFormValues;
    enabledTypes: TPeriod[];
  }>({ defaultPeriods, enabledTypes: ['month'] });
  const [journeysType, setJourneysType] = useState<TJourneysType>('all');
  const {
    map: { current: currentMap },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const {
    initialized: layersInitialized,
    init: initLayers,
    update: updateLayers,
    clear: clearLayers,
  } = useOriginDestinationFlows(currentMap, {
    setCyclabilityZoneId: (id) => {
      selectZoneId(selectedZoneId !== id ? id : null);
    },
  });

  useEffect(() => {
    if (
      period.comparisonEnabled ||
      period.values.timePeriod !== 'all_day' ||
      period.values.dayPeriod !== 'all'
    ) {
      period.enableComparison?.(false);
      period.setValues({ ...period.values, timePeriod: 'all_day', dayPeriod: 'all' });
    }

    selectZoneId(null);
    getZones();

    return () => {
      cancelPromises();
      setZones(undefined);
      setExternalZones(undefined);
      setFlows(undefined);
      setBounds(undefined);
      setCurrentRange(undefined);
      setLoading(false);
      setPrevButtonClick(undefined);
      setTitle(undefined);
    };
  }, []);

  useEffect(() => {
    if (selectedZoneId !== null) {
      const zone = zones?.find(({ id }) => id === selectedZoneId);
      if (zone) {
        setPrevButtonClick(() => () => selectZoneId(null));
        setTitle(zone.name);
      }
    } else {
      setPrevButtonClick(undefined);
      setTitle(undefined);
    }
  }, [selectedZoneId]);

  useEffect(() => {
    if (zones) getData();
  }, [period.values, zones]);

  useEffect(() => {
    return () => {
      clearLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    if (currentMap && canvasRef.current) {
      initLayers(canvasRef.current);
    }
  }, [currentMap, canvasRef.current]);

  useEffect(() => {
    if (layersInitialized) {
      updateLayers(zones, externalZones, flows, currentRange, selectedZoneId);
    }
  }, [layersInitialized, zones, externalZones, flows, currentRange, selectedZoneId]);

  async function getZones() {
    if (!currentPartner) return;
    setLoading(true);
    try {
      const { zones } = await CyclabilityZoneService.getZones({
        administrativeLevel: currentPartner.code === 'geovelo' ? 'REGION' : 'CITY',
        partnerCode: currentPartner.code,
        considerLivingStreets: true,
        rowsPerPage: 100,
        query: '{ id, code, reference, name, administrative_level, geo_polygon_simplified }',
      });
      setZones(zones);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.origin_destination.server_error_zones'), {
          variant: 'error',
        });
      }
    }
  }

  async function getData() {
    cancelPromises();
    setFlows(undefined);
    setBounds(undefined);
    setCurrentRange(undefined);

    if (!currentPartner || !zones) {
      setLoading(false);
      return;
    }

    const {
      values: { current: currentPeriod, timePeriod, dayPeriod },
    } = period;

    if (!timePeriod || !dayPeriod) return;

    let cancelled = false;

    try {
      const zoneIds = zones.map(({ id }) => id);
      const props = { timePeriod, dayPeriod };

      const flows = await cancellablePromise(
        OriginDestinationService.getFlows({
          period: currentPeriod.toIPeriod(),
          departureCyclabilityZoneIds: zoneIds,
          arrivalCyclabilityZoneIds: zoneIds,
          ...toOriginDestinationInput(props),
        }),
      );

      const externalZonesIds = flows.reduce<number[]>((res, { origin, destination }) => {
        if (!zoneIds.includes(origin) && !res.includes(origin)) res.push(origin);
        if (!zoneIds.includes(destination) && !res.includes(destination)) res.push(destination);
        return res;
      }, []);

      const nbPages = Math.ceil(externalZonesIds.length / 100);
      const _externalZones = (
        await cancellablePromise(
          Promise.all(
            new Array(nbPages).fill(null).map((_, pageIndex) =>
              CyclabilityZoneService.getZones({
                administrativeLevel: currentPartner.code === 'geovelo' ? 'REGION' : 'CITY',
                considerLivingStreets: true,
                ids: externalZonesIds.slice(pageIndex * 100, (pageIndex + 1) * 100),
                rowsPerPage: 100,
                query:
                  '{ id, code, reference, name, administrative_level, geo_polygon_simplified }',
              }),
            ),
          ),
        )
      ).flatMap(({ zones }) => zones);

      setExternalZones(_externalZones);
      setBounds({ min: 0, max: flows.length });
      setCurrentRange([0, flows.length]);
      setFlows(flows.filter(({ count }) => count > 1));
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.origin_destination.server_error_zones'), {
          variant: 'error',
        });

        setBounds({ min: 0, max: 1 });
        setCurrentRange([0, 1]);
        setFlows([]);
      } else cancelled = true;
    }

    if (!cancelled) setLoading(false);
  }

  return (
    <Box display="flex" flexDirection="column" gap={3} minHeight="100%">
      <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.origin_destination" />
      <Paper
        header={
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" justifyContent="flex-end">
              <PeriodForm
                disableComparison
                disablePadding
                disablePeriodTypeChange
                customPeriodTypes={customPeriodTypes}
                {...period}
              />
            </Box>
            <JourneysType journeysType={journeysType} setJourneysType={setJourneysType} />
          </Box>
        }
      >
        <OriginDestinationChart {...context} />
      </Paper>
      <MainFlows
        externalZones={externalZones}
        flows={flows}
        journeysType={journeysType}
        period={period}
        zones={zones}
      />
      <LinkCard
        description={
          <Trans i18nKey="cycling-insights.usage.origin_destination.targeted_analysis.title" />
        }
        icon={illuFlux}
        title={
          <Trans i18nKey="cycling-insights.usage.origin_destination.targeted_analysis.subtitle" />
        }
        to="../origin-destination-analysis"
      />
    </Box>
  );
}

export default OriginDestinationForm;
