import {
  BikeRoute,
  BikeRouteService,
  DashboardPages,
  Loading,
  Ride,
  RideService,
  TBackendPublicationStatus,
  useAmplitudeTracker,
} from '@geovelo-frontends/commons';
import { ArrowBack, Delete } from '@mui/icons-material';
import { IconButton, Skeleton, Toolbar, Tooltip, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { Link, Navigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../app/context';
import Button from '../../components/button';
import ConfirmDialog from '../../components/confirm-dialog';
import { bikeRoutesTab } from '../dashboard-routes';
import PublicationStatus from '../ride/publication-status';
import { TPublicationDialogStatus } from '../ride/publication-status-dialog';

import DataTab from './components';
import PublicationStatusDialog from './publication-status-dialog';

function BikeRouteDetail(): JSX.Element {
  const [bikeRoute, setBikeRoute] = useState<BikeRoute | null>();
  const [rides, setRides] = useState<Ride[]>();
  const [publicationDialogStatus, setPublicationDialogStatus] =
    useState<TPublicationDialogStatus>(null);
  const [publicationDialogOpen, openPublicationDialog] = useState(false);
  const [removeDialogOpen, openRemoveDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    user: { current: currentUser },
    partner: { current: currentPartner },
    actions: { setCurrentPage, setCurrentTab },
  } = useContext(AppContext);
  const params = useParams<{ id: string }>();
  const { transitions } = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { trackEvent } = useAmplitudeTracker();

  useEffect(() => {
    init();

    setCurrentPage(DashboardPages.Promotion);
    setCurrentTab(bikeRoutesTab);
  }, []);

  useEffect(() => {
    if (currentUser && currentPartner) {
      let pathname = window.location.pathname;
      const index = window.location.pathname.indexOf(currentPartner.code);
      if (index > -1) {
        pathname = pathname.substring(index + currentPartner.code.length);
      }

      trackEvent('Page Visited', {
        pathname,
        partner_id: currentPartner.id,
        partner_code: currentPartner.code,
      });
    }
  }, [currentUser, currentPartner]);

  async function init() {
    const { id: _id } = params;
    const id = parseInt(_id || '');

    try {
      if (isNaN(id)) throw new Error('');

      const _bikeRoute = await BikeRouteService.getBikeRoute(id);

      setBikeRoute(_bikeRoute);
      getRides(_bikeRoute);
    } catch {
      setBikeRoute(null);
      setRides(undefined);
      enqueueSnackbar(t('cycling-insights.bike_route.server_error'), { variant: 'error' });
    }
  }

  async function getRides({ rides: bikeRouteRides }: BikeRoute) {
    const ids = bikeRouteRides.map(({ rideId }) => rideId);

    try {
      const nbBatches = Math.ceil(ids.length / 50);
      const data = await Promise.all(
        new Array(nbBatches).fill(null).map((_, index) =>
          RideService.getRides({
            page: 1,
            pageSize: 50,
            order: 'asc',
            orderBy: 'id',
            ids: ids.slice(index * 50, (index + 1) * 50),
            query: '{id, title, publication_status}',
          }),
        ),
      );

      setRides(
        data.reduce<Ride[]>((res, { rides: _rides }) => {
          res.push(..._rides);

          return res;
        }, []),
      );
    } catch {
      //
    }
  }

  function handlePublicationDialogOpen(status: TPublicationDialogStatus) {
    setPublicationDialogStatus(status);
    openPublicationDialog(true);
  }

  function handlePublicationDialogClose() {
    openPublicationDialog(false);

    setTimeout(() => {
      setPublicationDialogStatus(null);
    }, transitions.duration.leavingScreen);
  }

  async function handlePublicationStatusUpdate() {
    if (!bikeRoute || !publicationDialogStatus) return;

    setLoading(true);

    let newStatus: TBackendPublicationStatus;
    if (publicationDialogStatus === 'publish') newStatus = 'PUBLISHED';
    else if (publicationDialogStatus === 'requestPublication') newStatus = 'WAITING_FOR_APPROVAL';
    else newStatus = 'UNPUBLISHED';

    try {
      const updatedBikeRoute = await BikeRouteService.updateBikeRoute(bikeRoute.id, {
        publication_status: newStatus,
      });

      enqueueSnackbar(t('cycling-insights.bike_route.updated'), { variant: 'success' });
      setBikeRoute(updatedBikeRoute);
    } catch {
      enqueueSnackbar(t('cycling-insights.bike_route.not_updated'), { variant: 'error' });
    }

    handlePublicationDialogClose();
    setLoading(false);
  }

  async function handleRemove() {
    if (!bikeRoute) return;

    setLoading(true);

    try {
      await BikeRouteService.removeBikeRoute(bikeRoute.id);

      enqueueSnackbar(t('cycling-insights.bike_route.remove_dialog.success'), {
        variant: 'success',
      });
      setBikeRoute(null);
      setRides(undefined);
    } catch {
      enqueueSnackbar(t('cycling-insights.bike_route.remove_dialog.server_error'), {
        variant: 'error',
      });
    }

    openRemoveDialog(false);
    setLoading(false);
  }

  if (bikeRoute === null)
    return <Navigate to={`/${currentPartner?.code}/promotion?tab=tourismBikeRoutes`} />;

  return (
    <>
      <Helmet>
        {bikeRoute ? (
          <title>
            {t('cycling-insights.app_title')}&nbsp;|&nbsp;{t('commons.navigation.tourism')}
            &nbsp;|&nbsp;
            {t('cycling-insights.tourism.navigation.bike_routes')}&nbsp;|&nbsp;{bikeRoute.title}
          </title>
        ) : (
          <title>
            {t('cycling-insights.app_title')}&nbsp;|&nbsp;{t('commons.navigation.tourism')}
            &nbsp;|&nbsp;
            {t('cycling-insights.tourism.navigation.bike_routes')}
          </title>
        )}
        {bikeRoute ? (
          <meta content={bikeRoute.description || ''} name="description" />
        ) : (
          <meta content={t('commons.navigation.tourism_description') || ''} name="description" />
        )}
      </Helmet>
      <Wrapper>
        <StyledToolbar>
          <Tooltip title={<Trans i18nKey="cycling-insights.bike_route.actions.back_to_list" />}>
            <IconButton
              component={StyledBackButton}
              size="medium"
              to={`/${currentPartner?.code}/promotion/bike-routes`}
            >
              <ArrowBack />
            </IconButton>
          </Tooltip>
          {bikeRoute ? (
            <>
              <Typography variant="h6">{bikeRoute.title}</Typography>
              {bikeRoute.creator && (
                <Typography color="textSecondary" variant="caption">
                  <Trans
                    i18nKey="cycling-insights.bike_route.created_by"
                    values={{ creator: bikeRoute.creator }}
                  />
                </Typography>
              )}
            </>
          ) : (
            <Typography component="div" variant="h6">
              <Skeleton variant="text" width={200} />
            </Typography>
          )}
          <StyledSpacer />
          {bikeRoute && (
            <>
              <Button
                onClick={() => openRemoveDialog(true)}
                startIcon={<Delete />}
                style={{ borderColor: red[500], color: red[500] }}
                variant="outlined"
              >
                <Trans i18nKey="commons.actions.remove" />
              </Button>
              <PublicationStatus
                onChange={handlePublicationDialogOpen}
                status={bikeRoute.publicationStatus}
              />
            </>
          )}
        </StyledToolbar>
        <StyledContent>
          {bikeRoute ? (
            <DataTab
              bikeRoute={bikeRoute}
              canWrite={true}
              onChange={setBikeRoute}
              onRidesChange={setRides}
              rides={rides}
            />
          ) : (
            <Loading text={<Trans i18nKey="cycling-insights.bike_route.loading" />} />
          )}
        </StyledContent>
      </Wrapper>
      {bikeRoute && (
        <>
          <PublicationStatusDialog
            loading={loading}
            onCancel={handlePublicationDialogClose}
            onConfirm={handlePublicationStatusUpdate}
            open={publicationDialogOpen}
            status={publicationDialogStatus}
          />
          <ConfirmDialog
            dialogTitle="bike-route-remove-dialog"
            loading={loading}
            onCancel={() => openRemoveDialog(false)}
            onConfirm={handleRemove}
            open={removeDialogOpen}
            title={<Trans i18nKey="cycling-insights.bike_route.remove_dialog.title" />}
          />
        </>
      )}
    </>
  );
}

const Wrapper = styled.div`
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
  position: relative;
`;

const StyledToolbar = styled(Toolbar)`
  h6 {
    margin-right: 8px;
  }

  button {
    margin-left: 8px;
  }
`;

const StyledBackButton = styled(Link)`
  && {
    margin-right: 16px;
  }
`;

const StyledSpacer = styled.div`
  flex-grow: 1;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;
`;

export default BikeRouteDetail;
