import { Tab, Typography } from '@mui/material';
import { Fragment, ReactNode, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ReactJson from 'react-json-view';
import styled from 'styled-components';

import doc from '../../../../../assets/route-computer-api-doc';
import SimpleTable, { TRow } from '../../../../../components/simple-table';

type TTab = 'json';

const tabs: Array<{
  key: TTab;
  label: string;
}> = [{ key: 'json', label: 'JSON' }];

type TKey = 'status' | 'description' | 'key' | 'type' | 'values' | 'description';

const responseKeys: TKey[] = ['status', 'description'];
const fullKeys: TKey[] = ['key', 'type', 'values', 'description'];
const noValuesKeys: TKey[] = ['key', 'type', 'description'];

function formatCellFontNotConsole(description: string): ReactNode {
  return (
    <span
      dangerouslySetInnerHTML={{ __html: description }}
      style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif' }}
    />
  );
}

function ResponseTab(): JSX.Element {
  const {
    response: {
      data: responseData,
      objects: {
        route: routeObject,
        distances: distancesObject,
        waypoint: waypointObject,
        sections: sectionsObject,
        details_bike: detailsBikeObject,
        details_pedestrian: detailsPedestrianObject,
        instruction: instructionObject,
        geometry: geometryObject,
        elevation: elevationObject,
        BikeStations: bikeStationsObject,
        NearBikeStations: nearBikeStationsObject,
      },
      examples: {
        response: { url: exampleUrl, body: responseExample },
        instructions_format: instructionsFormat,
        instructions_example: instructionsExample,
      },
    },
  } = doc;

  const [responseRows] = useState<TRow<number, TKey>[] | undefined>(
    [...responseData].map(({ status, description }, index) => {
      return {
        key: index,
        cells: {
          status: {
            value: status,
            format: (status: string) => {
              return (
                <>
                  {status.startsWith('200') && (
                    <StyledSpan className="tag_response goodRequest">{status}</StyledSpan>
                  )}
                  {status.startsWith('400') && (
                    <StyledSpan className="tag_response badRequest">{status}</StyledSpan>
                  )}
                </>
              );
            },
          },
          description: { value: description },
        },
      };
    }),
  );
  const [routeObjectRows] = useState<TRow<number, TKey>[] | undefined>(
    [...routeObject].map(({ key, type, values, description }, index) => {
      return {
        key: index,
        cells: {
          key: { value: key },
          type: { value: type },
          values: {
            value: values,
            format: (values: string[]) => {
              return values
                ? values.map((value, index) => {
                    return (
                      <Fragment key={value}>
                        {index > 0 && (
                          <>
                            ,<br />
                          </>
                        )}
                        <span>{value}</span>
                      </Fragment>
                    );
                  })
                : '';
            },
          },
          description: {
            value: description,
            format: formatCellFontNotConsole,
          },
        },
      };
    }),
  );
  const [distancesObjectRows] = useState<TRow<number, TKey>[] | undefined>(
    [...distancesObject].map(({ key, type, description }, index) => {
      return {
        key: index,
        cells: {
          key: { value: key },
          type: { value: type },
          description: {
            value: description,
            format: formatCellFontNotConsole,
          },
        },
      };
    }),
  );
  const [waypointObjectRows] = useState<TRow<number, TKey>[] | undefined>(
    [...waypointObject].map(({ key, type, description }, index) => {
      return {
        key: index,
        cells: {
          key: { value: key },
          type: { value: type },
          description: {
            value: description,
            format: formatCellFontNotConsole,
          },
        },
      };
    }),
  );
  const [sectionsObjectRows] = useState<TRow<number, TKey>[] | undefined>(
    [...sectionsObject].map(({ key, type, values, description, defaultValueIndex }, index) => {
      return {
        key: index,
        cells: {
          key: { value: key },
          type: { value: type },
          values: {
            value: values,
            format: (values: string[]) => {
              return values
                ? values.map((value, index) => {
                    const isDefault = index === defaultValueIndex;
                    return (
                      <Fragment key={value}>
                        {index > 0 && (
                          <>
                            ,<br />
                          </>
                        )}
                        <span>{value}</span>
                        {isDefault && (
                          <>
                            <br />
                            <StyledSpan className="tag_default">Default</StyledSpan>
                          </>
                        )}
                      </Fragment>
                    );
                  })
                : '';
            },
          },
          description: {
            value: description,
            format: formatCellFontNotConsole,
          },
        },
      };
    }),
  );
  const [detailsBikeObjectRows] = useState<TRow<number, TKey>[] | undefined>(
    [...detailsBikeObject].map(({ key, type, description }, index) => {
      return {
        key: index,
        cells: {
          key: { value: key },
          type: { value: type },
          description: {
            value: description,
            format: formatCellFontNotConsole,
          },
        },
      };
    }),
  );
  const [detailsPedestrianObjectRows] = useState<TRow<number, TKey>[] | undefined>(
    [...detailsPedestrianObject].map(({ key, type, description }, index) => {
      return {
        key: index,
        cells: {
          key: { value: key },
          type: { value: type },
          description: {
            value: description,
            format: formatCellFontNotConsole,
          },
        },
      };
    }),
  );
  const [instructionObjectRows] = useState<TRow<number, TKey>[] | undefined>(
    [...instructionObject].map(({ key, type, values, description }, index) => {
      return {
        key: index,
        cells: {
          key: { value: key },
          type: { value: type },
          values: {
            value: values,
            format: (values: string[]) => {
              return values
                ? values.map((value, index) => {
                    return (
                      <Fragment key={value}>
                        {index > 0 && (
                          <>
                            ,<br />
                          </>
                        )}
                        <StyledSpan dangerouslySetInnerHTML={{ __html: value }} />
                      </Fragment>
                    );
                  })
                : '';
            },
          },
          description: {
            value: description,
            format: formatCellFontNotConsole,
          },
        },
      };
    }),
  );
  const [geometryObjectRows] = useState<TRow<number, TKey>[] | undefined>(
    [...geometryObject].map(({ key, type, description }, index) => {
      return {
        key: index,
        cells: {
          key: { value: key },
          type: { value: type },
          description: {
            value: description,
            format: formatCellFontNotConsole,
          },
        },
      };
    }),
  );
  const [elevationObjectRows] = useState<TRow<number, TKey>[] | undefined>(
    [...elevationObject].map(({ key, type, description }, index) => {
      return {
        key: index,
        cells: {
          key: { value: key },
          type: { value: type },
          description: {
            value: description,
            format: formatCellFontNotConsole,
          },
        },
      };
    }),
  );
  const [bikeStationsObjectRows] = useState<TRow<number, TKey>[] | undefined>(
    [...bikeStationsObject].map(({ key, type, description }, index) => {
      return {
        key: index,
        cells: {
          key: { value: key },
          type: { value: type },
          description: {
            value: description,
            format: formatCellFontNotConsole,
          },
        },
      };
    }),
  );
  const [nearBikeStationsObjectRows] = useState<TRow<number, TKey>[] | undefined>(
    [...nearBikeStationsObject].map(({ key, type, description }, index) => {
      return {
        key: index,
        cells: {
          key: { value: key },
          type: { value: type },
          description: {
            value: description,
            format: formatCellFontNotConsole,
          },
        },
      };
    }),
  );

  return (
    <>
      <Wrapper>
        <StyledTitle variant="h6">Response</StyledTitle>
        <SimpleTable
          headers={{
            status: { label: 'Status' },
            description: { label: 'Description' },
          }}
          keys={responseKeys}
          rows={responseRows}
          title={'response-table'}
        />
        <StyledSectionTitle noWrap variant="h6">
          TRoute
        </StyledSectionTitle>
        <SimpleTable
          headers={{
            key: { label: 'Key' },
            type: { label: 'Type' },
            values: { label: 'Values' },
            description: { label: 'Description' },
          }}
          keys={fullKeys}
          rows={routeObjectRows}
          title={'route-object'}
        />
        <StyledSectionTitle noWrap className="nested_object" variant="h6">
          TDistances
        </StyledSectionTitle>
        <SimpleTable
          headers={{
            key: { label: 'Key' },
            type: { label: 'Type' },
            description: { label: 'Description' },
          }}
          keys={noValuesKeys}
          rows={distancesObjectRows}
          title={'distances-object'}
        />
        <StyledSectionTitle noWrap className="nested_object" variant="h6">
          TWayPoint
        </StyledSectionTitle>
        <SimpleTable
          headers={{
            key: { label: 'Key' },
            type: { label: 'Type' },
            description: { label: 'Description' },
          }}
          keys={noValuesKeys}
          rows={waypointObjectRows}
          title={'waypoint-object'}
        />
        <StyledSectionTitle noWrap className="nested_object" variant="h6">
          TSection
        </StyledSectionTitle>
        <SimpleTable
          headers={{
            key: { label: 'Key' },
            type: { label: 'Type' },
            values: { label: 'Values' },
            description: { label: 'Description' },
          }}
          keys={fullKeys}
          rows={sectionsObjectRows}
          title={'sections-object'}
        />
        <StyledSectionTitle noWrap className="nested_object" variant="h6">
          TBikeDetails
        </StyledSectionTitle>
        <SimpleTable
          headers={{
            key: { label: 'Key' },
            type: { label: 'Type' },
            description: { label: 'Description' },
          }}
          keys={noValuesKeys}
          rows={detailsBikeObjectRows}
          title={'details-bike-object'}
        />
        <StyledSectionTitle noWrap className="nested_object" variant="h6">
          TPedestrianDetails
        </StyledSectionTitle>
        <SimpleTable
          headers={{
            key: { label: 'Key' },
            type: { label: 'Type' },
            description: { label: 'Description' },
          }}
          keys={noValuesKeys}
          rows={detailsPedestrianObjectRows}
          title={'details-pedestrian-object'}
        />
        <StyledSectionTitle noWrap className="nested_object" variant="h6">
          TInstruction
        </StyledSectionTitle>
        <SimpleTable
          headers={{
            key: { label: 'Key' },
            type: { label: 'Type' },
            description: { label: 'Description' },
          }}
          keys={fullKeys}
          rows={instructionObjectRows}
          title={'instruction-object'}
        />

        <>
          <StyledParagraph>
            In order to optimize network load and parsing, the instructions object follows the{' '}
            <a
              href="http://repository.utm.md/bitstream/handle/5014/6418/ICMCS_2011_1_pg_244_247.pdf?sequence=1&isAllowed=y"
              rel="noreferrer"
              target="_blank"
            >
              JSON HPack format
            </a>{' '}
            :
          </StyledParagraph>
          <StyledExampleFrame>
            <ReactJson
              // FIXME : uncomment this when https://github.com/mac-s-g/react-json-view/pull/348 will be merged
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              displayArrayKey={false}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              name={'instructions'}
              src={instructionsFormat}
            />
          </StyledExampleFrame>
          <StyledSectionTitle noWrap className="example" variant="h6">
            Example :
          </StyledSectionTitle>
          <StyledExampleFrame>
            <ReactJson
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              name={'instructions'}
              src={instructionsExample}
            />
          </StyledExampleFrame>
        </>

        <StyledSectionTitle noWrap className="nested_object" variant="h6">
          TGeometry
        </StyledSectionTitle>
        <SimpleTable
          headers={{
            key: { label: 'Key' },
            type: { label: 'Type' },
            description: { label: 'Description' },
          }}
          keys={noValuesKeys}
          rows={geometryObjectRows}
          title={'geometry-object'}
        />
        <StyledExampleFrame>
          <>
            <pre className="prettyprint lang-js" style={{ border: 0 }}>
              <code>
                {/* eslint-disable prettier/prettier */}
&#x2f;&#x2f; parse geometry polyline<br />
import polyline from '@mapbox/polyline';<br />
const geometry = polyline.toGeoJSON(_geometry, 6);
                {/* eslint-enable prettier/prettier */}
              </code>
            </pre>
          </>
        </StyledExampleFrame>
        <StyledSectionTitle noWrap className="nested_object" variant="h6">
          TElevation
        </StyledSectionTitle>
        <SimpleTable
          headers={{
            key: { label: 'Key' },
            type: { label: 'Type' },
            description: { label: 'Description' },
          }}
          keys={noValuesKeys}
          rows={elevationObjectRows}
          title={'elevation-object'}
        />
        <StyledSectionTitle noWrap className="nested_object" variant="h6">
          TBikeStations
        </StyledSectionTitle>
        <SimpleTable
          headers={{
            key: { label: 'Key' },
            type: { label: 'Type' },
            description: { label: 'Description' },
          }}
          keys={noValuesKeys}
          rows={bikeStationsObjectRows}
          title={'bikeStations-object'}
        />
        <StyledSectionTitle noWrap className="nested_object" variant="h6">
          TNearBikeStations
        </StyledSectionTitle>
        <SimpleTable
          headers={{
            key: { label: 'Key' },
            type: { label: 'Type' },
            description: { label: 'Description' },
          }}
          keys={noValuesKeys}
          rows={nearBikeStationsObjectRows}
          title={'nearBikeStations-object'}
        />

        <StyledSeparator />

        <StyledSectionTitle noWrap className="example" variant="h6">
          Example :
        </StyledSectionTitle>
        <StyledExampleFrame>
          <ExampleContent className="example">
            {tabs.map(({ key, label }) => (
              <StyledTab
                aria-controls={`tabpanel-${key}`}
                id={`tab-${key}`}
                key={key}
                label={label}
                style={{ textTransform: 'initial' }}
                value={key}
              />
            ))}
            <code>
              <span className="bold">POST</span>&nbsp;&nbsp;
              <span>{exampleUrl}</span>
            </code>
            <br />
            <ReactJson
              // FIXME : uncomment this when https://github.com/mac-s-g/react-json-view/pull/348 will be merged
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              displayDataTypes={false}
              displayObjectSize={false}
              name={false}
              src={responseExample}
            />
          </ExampleContent>
        </StyledExampleFrame>
      </Wrapper>
      <Helmet>
        <script src="https://cdn.jsdelivr.net/gh/google/code-prettify@master/loader/run_prettify.js" />
      </Helmet>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
`;

const StyledTitle = styled(Typography)`
  && {
    display: none;
    font-size: 1.5rem;

    @media print {
      display: block;
    }
  }
`;

const StyledSpan = styled.span`
  &&.tag_response {
    padding: 5px 10px;
    border-radius: 5px;
    color: white;

    &.goodRequest {
      background-color: #8bc34a;
    }
    &.badRequest {
      background-color: #ff5722;
    }
  }

  &&.tag_default {
    font-family: 'Courier New', Courier, monospace;
    background-color: rgba(196, 196, 196, 0.3);
    color: DimGray;
    border-radius: 5px;
    padding: 2px 5px;
  }

  .new::before {
    content: 'new';
    border-radius: 8%;
    padding: 1px 3px;
    background-color: #8bc34a;
    color: #fff;
    margin-right: 0.2em;
  }
`;

const StyledSectionTitle = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    border-radius: 10px;
    min-height: 36px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px 20px;
    font-size: 1.2rem;
    background-color: rgba(196, 196, 196, 0.5);
    font-weight: 500;

    &.nested_object {
      min-height: 30px;
      font-size: 1.1rem;
      background-color: rgba(196, 196, 196, 0.3);
    }

    &.example {
      background-color: inherit;
      font-size: 1.1rem;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

const StyledSeparator = styled.hr`
  width: 100%;
  border: solid thin rgba(196, 196, 196, 0.5);
  margin-top: 32px;
`;

const StyledExampleFrame = styled.div`
  border-radius: 10px;
  background-color: #f8f8f8;
  padding: 12px;
`;

const ExampleContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  font-size: 0.8rem;

  .bold {
    font-weight: 500;
  }
`;

const StyledTab = styled(Tab)`
  && {
    min-height: 30px;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 32px;
    border-bottom: solid 1px green;
  }
`;

const StyledParagraph = styled.p`
  && {
    margin-top: 16px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
  }
`;

export default ResponseTab;
