import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import { TOutletContext } from '../../../../layouts/page/container';
import { TCartographicDataPageContext } from '../../context';
import Resolutions from '../discontinuity/resolutions';

import Result from './result';
import Settings from './settings';

function DiscontinuityAnalysisContent(
  context: TCartographicDataPageContext & TOutletContext,
): JSX.Element {
  const {
    header: { setActions, setPrevButtonClick, setTitle },
    discontinuity: { selectedSection, selectFacilities },
  } = context;
  const [resultsDisplayed, displayResults] = useState(false);

  useEffect(() => {
    return () => {
      setPrevButtonClick(undefined);
      setTitle(undefined);
      setActions(undefined);
      selectFacilities(['cycleway', 'lane', 'greenway']);
    };
  }, []);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap={4}
        justifyContent="space-between"
        minHeight="100%"
      >
        {selectedSection ? (
          <Resolutions {...context} />
        ) : resultsDisplayed ? (
          <Result {...context} onClose={() => displayResults(false)} />
        ) : (
          <Settings {...context} displayResults={displayResults} />
        )}
      </Box>
    </>
  );
}

export default DiscontinuityAnalysisContent;
