import { ThemeProvider } from '@mui/material/styles';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import theme from '../../app/theme';
import Button from '../button';

interface IProps {
  onAdd: () => void;
  onClose: () => void;
}

function NewRideStepPopup({ onAdd, onClose }: IProps): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Button onClick={() => onClose()} variant="outlined">
          <Trans i18nKey="commons.actions.close" />
        </Button>
        <Button color="primary" onClick={() => onAdd()} variant="outlined">
          <Trans i18nKey="cycling-insights.ride.steps.actions.add" />
        </Button>
      </Wrapper>
    </ThemeProvider>
  );
}

const Wrapper = styled.div`
  button:not(:first-of-type) {
    margin-left: 8px;
  }
`;

export default NewRideStepPopup;
