import { Period, User, UserService, useCancellablePromise } from '@geovelo-frontends/commons';
import { green, red } from '@mui/material/colors';
import moment from 'moment';
import { useEffect, useState } from 'react';

import Card from '../../../../components/card';
import Table, { TRow } from '../../../../components/table';
import usePaginatedTable from '../../../../hooks/table/paginated';
import { TQAPageContext } from '../../context';

type TKey = 'id' | 'date';

const keys: TKey[] = ['id', 'date'];

function UsersLogsTable({
  selectedUser,
  usersLogs: { selectedTraceId, selectTraceId },
}: TQAPageContext & { selectedUser: User | null }): JSX.Element {
  const [rows, setRows] = useState<TRow<number, TKey>[] | undefined>();
  const [count, setCount] = useState<number>();
  const { page, rowsPerPage, onPageChange, onRowsPerPageChange } = usePaginatedTable(10);
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    return () => cancelPromises();
  }, []);

  useEffect(() => {
    getTraces();
  }, [selectedUser, page, rowsPerPage]);

  async function getTraces() {
    cancelPromises();
    setRows(undefined);
    selectTraceId(null);

    if (!selectedUser) return;

    try {
      const { count, results: traces } = await cancellablePromise(
        UserService.getTraces({
          user: selectedUser,
          ordering: '-start_datetime',
          period: new Period('custom', moment('2010-01-01'), moment()),
          page: page + 1,
          rowsPerPage,
          query: '{id, start_datetime, has_problem}',
        }),
      );

      setRows(
        traces.map(({ id, startDate, hasProblems }) => ({
          key: id,
          inkBarColor: hasProblems ? red[500] : green[500],
          cells: {
            id: { value: id },
            date: { value: startDate, format: (date) => date.format('LLL') },
          },
        })),
      );

      setCount(count);
    } catch {
      //
    }
  }

  return (
    <>
      <Card>
        <Table
          hasInkBars
          count={count}
          headers={{
            id: { label: '#', width: 50 },
            date: { label: 'Date' },
          }}
          keys={keys}
          onClick={(key) => selectTraceId(key)}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page}
          rows={rows}
          rowsPerPage={rowsPerPage}
          selectedKey={selectedTraceId || undefined}
          tableProps={{ sx: { tableLayout: 'fixed' } }}
          title="User traces table"
        />
      </Card>
    </>
  );
}

export default UsersLogsTable;
