import { TSectionFacility } from '@geovelo-frontends/commons';
import { Box, Card, CardContent, Skeleton, Typography } from '@mui/material';
import { Chart } from 'chart.js';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TFubFeature } from '../../models/accidentology';

const chartId = 'distribution-chart';

export const types = ['improved', 'slightlySecure', 'unsecured'] as const;
export type TTypes = (typeof types)[number];

const typesMap: { [key in TTypes]: { color: string; labelKey: string } } = {
  improved: {
    color: '#46CE9D',
    labelKey: 'cycling-insights.usage.accidentology.distribution.labels.improved_roads',
  },
  slightlySecure: {
    color: '#FFD12F',
    labelKey: 'cycling-insights.usage.accidentology.distribution.labels.slightly_secure_roads',
  },
  unsecured: {
    color: '#F56B84',
    labelKey: 'cycling-insights.usage.accidentology.distribution.labels.unsecured_roads',
  },
};

const facilitiesMap: { [key in TSectionFacility]: TTypes } = {
  cycleway: 'improved',
  forbidden: 'unsecured',
  greenway: 'improved',
  lane: 'slightlySecure',
  mixedfacilities: 'slightlySecure',
  none: 'unsecured',
  opposite: 'slightlySecure',
  other: 'unsecured',
  sharebusway: 'slightlySecure',
};

function Distribution({ features }: { features?: TFubFeature[] }): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const [chartInitialized, setChartInitialized] = useState(false);
  const [count, setCount] = useState<number>();
  const [countMap, setCountMap] = useState<{ [key in TTypes]: number }>();
  const { t } = useTranslation();
  const roadsTypesChartRef = useRef<Chart<'doughnut'>>();

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    const chartCtx = document.getElementById(chartId);
    if (chartCtx && chartCtx instanceof HTMLCanvasElement) {
      roadsTypesChartRef.current?.destroy();

      roadsTypesChartRef.current = new Chart(chartCtx, {
        type: 'doughnut',
        data: {
          labels: types.map((key) => t(typesMap[key].labelKey)),
          datasets: [
            {
              data: types.map(() => 0),
              backgroundColor: types.map((key) => typesMap[key].color),
            },
          ],
        },
        options: {
          cutout: '80%',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: ({ parsed, dataset }) =>
                  ` ${Math.round(
                    (parsed / dataset.data.reduce((res, count) => res + count, 0)) * 100,
                  )} %`,
              },
            },
            title: {
              display: false,
            },
          },
        },
      });

      setChartInitialized(true);
    }

    return () => setChartInitialized(false);
  }, [initialized]);

  useEffect(() => {
    if (features) {
      let _count = 0;

      setCountMap(
        features.reduce<{ [key in TTypes]: number }>(
          (res, { properties: { accidentData } }) => {
            if (accidentData && accidentData.facility) {
              ++res[facilitiesMap[accidentData.facility]];
              ++_count;
            }

            return res;
          },
          { improved: 0, slightlySecure: 0, unsecured: 0 },
        ),
      );
      setCount(_count);
    }

    return () => {
      setCount(undefined);
      setCountMap(undefined);
    };
  }, [features]);

  useEffect(() => {
    if (chartInitialized && countMap && roadsTypesChartRef.current) {
      roadsTypesChartRef.current.data.datasets[0].data = types.map((key) => countMap[key]);
      roadsTypesChartRef.current.update();
    }

    return () => {
      if (roadsTypesChartRef.current) {
        roadsTypesChartRef.current.data.datasets[0].data = types.map(() => 0);
        roadsTypesChartRef.current.update();
      }
    };
  }, [chartInitialized, countMap]);

  return (
    <Card elevation={0} sx={{ borderRadius: 4 }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 3, '&&': { padding: 3 } }}>
        <Box display="flex" justifyContent="space-between">
          <Typography fontSize="1.125rem" fontWeight={700}>
            {t('cycling-insights.usage.accidentology.distribution.title')}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap={5}>
          <Box height={200} position="relative" width={200}>
            <Box
              bgcolor="whitesmoke"
              borderRadius={100}
              height={200}
              left={0}
              position="absolute"
              top={0}
              width={200}
            />
            <Box
              bgcolor="#fff"
              borderRadius={80}
              height={160}
              left={20}
              position="absolute"
              top={20}
              width={160}
            />
            <canvas id={chartId} style={{ position: 'relative', zIndex: 2 }} />
          </Box>
          <Box display="flex" flexDirection="column" gap={3}>
            {types.map((key) => {
              const { color, labelKey } = typesMap[key];

              return (
                <Box display="flex" flexDirection="column" gap={1} key={key}>
                  <Box bgcolor={color} borderRadius={5} height={10} width={10} />
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body2">{t(labelKey)}</Typography>
                    <Typography>
                      {count !== undefined && countMap ? (
                        <>{count ? `${Math.round((countMap[key] / count) * 100)}%` : 0}</>
                      ) : (
                        <Skeleton variant="text" width={50} />
                      )}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default Distribution;
