import {
  IPoint,
  IRideStep,
  PoiCategoryService,
  Ride,
  RideService,
  Route,
} from '@geovelo-frontends/commons';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../../../../app/context';
import ConfirmDialog from '../../../../components/confirm-dialog';
import { TTab } from '../../tab';

import RideStepEditDialog from './edit-dialog';
import RideStepFormDialog from './form-dialog';
import RideStepsList from './list';
import RideStepsMap from './map';

type TFormDialog = 'data' | 'photos' | 'medias' | null;

interface IProps {
  canWrite: boolean;
  onChange: (ride: Ride) => void;
  onTabChange: (tab: TTab) => void;
  ride: Ride;
  returnRoute?: Route | null;
  route?: Route | null;
}

function StepsTab({
  canWrite,
  returnRoute,
  route,
  ride,
  onTabChange,
  onChange,
}: IProps): JSX.Element {
  const [newStep, setNewStep] = useState<{ location: GeoJSON.Point } | null>(null);
  const [stepToUpdate, setStepToUpdate] = useState<IRideStep | null>(null);
  const [stepToRemove, setStepToRemove] = useState<IRideStep | null>(null);
  const [newStepDialogOpen, openNewStepDialog] = useState<boolean>(false);
  const [editStepDialogOpen, openEditStepDialog] = useState<TFormDialog>(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { transitions } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const {
    poi: { categories: poiCategories },
    actions: { setPoiCategories },
  } = useContext(AppContext);

  useEffect(() => {
    onTabChange('steps');
    initPoiCategories();
  }, []);

  function handleFormDialogClose(step?: IRideStep) {
    if (step) {
      const updatedRide = ride.clone();
      if (stepToUpdate) {
        const index = ride.steps.findIndex(({ id }) => id === stepToUpdate.id);

        updatedRide.steps.splice(index, 1, step);
        updatedRide.steps.sort((a, b) => (a.order && b.order ? a.order - b.order : 0));

        onChange(updatedRide);
      } else {
        updatedRide.steps.push(step);
        updatedRide.steps.sort((a, b) => (a.order && b.order ? a.order - b.order : 0));
        onChange(updatedRide);
      }
    }

    openNewStepDialog(false);
    openEditStepDialog(null);
    setTimeout(() => {
      setNewStep(null);
      setStepToUpdate(null);
    }, transitions.duration.leavingScreen);
  }

  function handleStepAdd(step: IRideStep) {
    const updatedRide = ride.clone();
    updatedRide.steps.push(step);
    updatedRide.steps.sort((a, b) => (a.order && b.order ? a.order - b.order : 0));
    onChange(updatedRide);
  }

  async function handleStepDragged(step: IRideStep, { lat, lng }: IPoint) {
    if (!ride.id) return;

    try {
      const { id: stepId } = step;
      if (!stepId) return;

      const index = ride.steps.findIndex(({ id }) => id === stepId);

      const updatedStep = await RideService.updateRideStep(ride.id, stepId, {
        geo_point: { type: 'Point', coordinates: [lng, lat] },
      });

      const updatedRide = ride.clone();
      updatedRide.steps.splice(index, 1, updatedStep);

      enqueueSnackbar(t('cycling-insights.ride.updated'), { variant: 'success' });
      onChange(updatedRide);
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.not_updated'), { variant: 'error' });
      onChange(ride.clone());
    }
  }

  async function initPoiCategories() {
    if (!poiCategories) {
      try {
        const categories = await PoiCategoryService.getPoiCategories();

        setPoiCategories(categories);
      } catch (err) {
        enqueueSnackbar(t('commons.poi_categories.server_error'), { variant: 'error' });
      }
    }
  }

  function handleEditDialogOpen(step: IRideStep, dialog: TFormDialog) {
    setStepToUpdate(step);
    openEditStepDialog(dialog);
  }

  function handleStepsReordered(reorderedSteps: IRideStep[]) {
    const updatedRide = ride.clone();
    updatedRide.steps = [...reorderedSteps];
    onChange(updatedRide);
  }

  async function handleStepRemove() {
    const rideId = ride.id;
    if (!rideId || stepToRemove === null) return;

    setLoading(true);

    try {
      const { id: stepId, order: stepOrder } = stepToRemove;
      if (!stepId || stepOrder === undefined) return;

      await RideService.removeRideStep(rideId, stepId);
      await Promise.all(
        ride.steps
          .filter(({ order }) => order && order > stepOrder)
          .map(({ id, order }) => {
            if (!id || order === undefined) return null;

            return RideService.updateRideStep(rideId, id, { order: order - 1 });
          }),
      );
      const updatedRide = await RideService.getRide(rideId);

      enqueueSnackbar(t('cycling-insights.ride.updated'), { variant: 'success' });
      onChange(updatedRide);
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.not_updated'), { variant: 'error' });
    }

    setStepToRemove(null);
    setLoading(false);
  }

  return (
    <>
      <Wrapper>
        <Grid container spacing={2} style={{ height: '100%' }}>
          <Grid item lg={4} md={6} style={{ height: '100%', overflow: 'hidden' }} xs={12}>
            <RideStepsList
              canWrite={canWrite}
              onClick={(step) => handleEditDialogOpen(step, 'data')}
              onMediasManaged={(step) => handleEditDialogOpen(step, 'medias')}
              onPhotosManaged={(step) => handleEditDialogOpen(step, 'photos')}
              onRemove={setStepToRemove}
              onReordered={handleStepsReordered}
              ride={ride}
            />
          </Grid>
          <Grid item lg={8} md={6} style={{ height: '100%' }} xs={12}>
            <RideStepsMap
              canWrite={canWrite}
              onMapClick={({ lat, lng }) => {
                setNewStep({ location: { type: 'Point', coordinates: [lng, lat] } });
                openNewStepDialog(true);
              }}
              onStepAdd={handleStepAdd}
              onStepDragged={handleStepDragged}
              onStepUpdate={(step) => {
                setStepToUpdate(step);
                openEditStepDialog('data');
              }}
              returnRoute={returnRoute}
              ride={ride}
              route={route}
            />
          </Grid>
        </Grid>
      </Wrapper>
      <RideStepFormDialog
        canWrite={canWrite}
        onClose={handleFormDialogClose}
        open={newStepDialogOpen}
        ride={ride}
        step={newStep || stepToUpdate}
      />
      <RideStepEditDialog
        canWrite={canWrite}
        initialTab={editStepDialogOpen || 'data'}
        onChange={onChange}
        onClose={handleFormDialogClose}
        open={editStepDialogOpen !== null}
        ride={ride}
        step={stepToUpdate}
      />
      <ConfirmDialog
        dialogTitle="ride-step-remove-dialog"
        loading={loading}
        onCancel={() => setStepToRemove(null)}
        onConfirm={handleStepRemove}
        open={Boolean(stepToRemove)}
        title={<Trans i18nKey="cycling-insights.ride.steps.remove_dialog.title" />}
      />
    </>
  );
}

const Wrapper = styled.div`
  height: 100%;

  > div {
    height: 100%;
  }
`;

export default StepsTab;
