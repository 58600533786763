import { WidgetConfig } from '@geovelo-frontends/commons';
import { SentimentDissatisfied } from '@mui/icons-material';
import { Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import Card from '../../../../components/card';
import { environment } from '../../../../environment';

function WidgetDetails({
  config,
  ...props
}: {
  config: WidgetConfig | null | undefined;
}): JSX.Element {
  const [iframeUrl, setIframeUrl] = useState<string>();
  const [key, setKey] = useState(0);

  useEffect(() => {
    if (config) setIframeUrl(`${environment.widgetUrl}?id=${config.id}&api-key=${config.apiKey}`);
    setKey(key + 1);
  }, [config]);

  if (config === null)
    return (
      <EmptyStateWrapper>
        <SentimentDissatisfied color="action" />
        <Typography color="textSecondary">
          <Trans i18nKey="cycling-insights.widgets.details.empty_state" />
        </Typography>
      </EmptyStateWrapper>
    );

  return (
    <Card title={config ? config.title : <Skeleton variant="text" width={200} />} {...props}>
      <StyledContent>
        <div>
          <Typography color="textSecondary">
            <Trans i18nKey="cycling-insights.widgets.details.preview" />
          </Typography>
          <div>
            {iframeUrl ? (
              <StyledIframe height="500px" key={key} src={iframeUrl} title="preview" width="100%" />
            ) : (
              <Skeleton height={500} variant="rectangular" />
            )}
          </div>
        </div>
        {config && (
          <div>
            <Typography color="textSecondary">
              <Trans i18nKey="cycling-insights.widgets.details.integration" />
            </Typography>
            <div>
              <Typography>
                <Trans i18nKey="cycling-insights.widgets.details.integration_description" />
              </Typography>
              {iframeUrl ? (
                <StyledCode className="prettyprint">
                  <code className="lang-html">
                    {/* eslint-disable prettier/prettier */}
                  &lt;iframe<br />
&nbsp;&nbsp;allowfullscreen="true"<br />
&nbsp;&nbsp;height="100%"<br />
&nbsp;&nbsp;src="{iframeUrl}"<br />
&nbsp;&nbsp;width="100%"<br />
&gt;&lt;/iframe&gt;
                    {/* eslint-enable prettier/prettier */}
                  </code>
                </StyledCode>
              ) : (
                <Skeleton height={106} variant="rectangular" width="100%" />
              )}
            </div>
            <Helmet>
              <script src="https://cdn.jsdelivr.net/gh/google/code-prettify@master/loader/run_prettify.js" />
            </Helmet>
          </div>
        )}
      </StyledContent>
    </Card>
  );
}

const EmptyStateWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  justify-content: center;

  svg {
    height: 96px;
    width: 96px;
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 16px;

    > div {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 16px;
      padding: 0 24px;
    }
  }
`;

const StyledIframe = styled.iframe`
  border: 0;
  outline: none;
`;

const StyledCode = styled.pre`
  && {
    background-color: whitesmoke;
    border: 0;
    margin: 0;
    overflow-x: auto;
    padding: 8px;
  }
`;

export default WidgetDetails;
