import styled from 'styled-components';

import { closedDrawerWidth, smallScreenClosedDrawerWidth } from './drawer';

interface IProps {
  children: JSX.Element;
}

function Main({ children }: IProps): JSX.Element {
  return (
    <Wrapper>
      <StyledContent>{children}</StyledContent>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  padding-left: ${smallScreenClosedDrawerWidth}px;
  transition: ${({ theme: { transitions } }) =>
    transitions.create(['padding'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    })};

  @media (min-width: 600px) {
    padding-left: ${closedDrawerWidth}px;
  }

  @media print {
    padding-left: 0;
  }
`;

const StyledContent = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;
`;

export default Main;
