import {
  DiscontinuityResolution,
  SectionStats,
  StatsService,
  roadTypesLabels,
  useCancellablePromise,
  useUnits,
} from '@geovelo-frontends/commons';
import { Close, EditRoad } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import Ranking from '../../../../components/ranking';
import useHeatmap from '../../../../hooks/map/heatmap';
import { TOutletContext } from '../../../../layouts/page/container';
import { TSectionFeatureCollection, TSectionProperties } from '../../../../models/sections';
import { getFilteredSections as _getFilteredSections } from '../../../../utils/sections';
import { TCartographicDataPageContext } from '../../context';

import { LngLatBounds } from '!maplibre-gl';

function Resolutions(context: TCartographicDataPageContext & TOutletContext): JSX.Element {
  const {
    header: { setActions, setPrevButtonClick, setTitle },
    discontinuity: { selectedSection, selectSection },
    setLoading,
  } = context;
  const [initialized, setInitialized] = useState(false);
  const [discontinuityResolutions, setDiscontinuityResolutions] =
    useState<DiscontinuityResolution[]>();
  const [selectedResolutionIndex, selectResolutionIndex] = useState(0);
  const [data, setData] = useState<TSectionFeatureCollection | null | undefined>();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const {
    map: { current: currentMap, zoom },
    partner: { current: currentPartner, sections },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { toDistance } = useUnits();
  const {
    initialized: layersInitialized,
    init: initLayers,
    update: updateLayers,
    destroy: destroyLayers,
  } = useHeatmap(
    currentMap,
    {
      sourceId: 'resolution',
      layerId: 'resolution',
      colors: [{ value: '#50315e' }],
    },
    function discontinuityTooltipContent({ id, wayName, roadType }: TSectionProperties) {
      return (
        `<div style="padding: 8px"><h3>${
          wayName ? wayName : t(roadTypesLabels[roadType || 'unclassified']) + ' - ' + id
        }</h3>` +
        (roadType &&
          `<div>${
            t('cycling-insights.facilities.cyclability.road_type') +
            t(roadTypesLabels[roadType || 'unclassified'])
          }</div>`)
      );
    },
  );

  useEffect(() => {
    setPrevButtonClick(undefined);
    setTitle(t('cycling-insights.facilities.discontinuity.resolutions.title'));
    setActions(
      <Tooltip title={t('commons.actions.close')}>
        <IconButton color="primary" onClick={() => selectSection?.(undefined)} size="small">
          <Close />
        </IconButton>
      </Tooltip>,
    );
    setInitialized(true);

    return () => {
      setTitle(undefined);
      setActions(undefined);
    };
  }, []);

  useEffect(() => {
    if (initialized) getResolutions();

    return () => cancelPromises();
  }, [initialized]);

  useEffect(() => {
    if (currentMap) {
      initLayers();
    }

    return () => {
      destroyLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    if (!sections || !data) setLoading(true);

    return () => {
      setLoading(false);
    };
  }, [sections, data]);

  useEffect(() => {
    const resolution = discontinuityResolutions?.[selectedResolutionIndex];
    if (layersInitialized && sections && resolution) getFilteredSections(resolution);

    return () => {
      setData(undefined);
    };
  }, [layersInitialized, sections, discontinuityResolutions, selectedResolutionIndex]);

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (!layersInitialized) return;

    if (data) {
      timeoutRef.current = setTimeout(() => {
        updateLayers(data);
      }, 300);
    } else {
      updateLayers(
        { type: 'FeatureCollection', features: [] },
        {
          currentRange: [0, 1],
          primaryBounds: { min: 0, max: 1 },
        },
      );
    }
  }, [layersInitialized, data]);

  async function getResolutions() {
    if (!currentPartner || !selectedSection?.discontinuityId) return;

    try {
      const resolutions = await cancellablePromise(
        StatsService.getDiscontinuityResolutions(currentPartner, selectedSection.discontinuityId),
      );

      setDiscontinuityResolutions(resolutions);
      selectResolutionIndex(0);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('commons.stats.no_data'), { variant: 'error' });
      }
    }
  }

  function getFilteredSections(resolution: DiscontinuityResolution) {
    if (!sections || zoom === undefined) return;

    const stats: { [key: number]: { current?: SectionStats; prev?: SectionStats } } = {};

    resolution.sections.forEach(({ id: sectionId, isOnward }) => {
      let discontinuityStats = stats[sectionId]?.current;
      if (discontinuityStats) {
        if (isOnward) {
          discontinuityStats = { ...discontinuityStats, isOnward: true };
        } else {
          discontinuityStats = { ...discontinuityStats, isBackward: true };
        }
      } else {
        stats[sectionId] = {
          current: {
            sectionId,
            customColor: sectionId === selectedSection?.id ? '#d73027' : undefined,
            isOnward,
            isBackward: !isOnward,
          },
        };
      }
    });

    const { filteredSections } = _getFilteredSections({
      sections,
      zoom,
      stats,
    });

    const bounds = new LngLatBounds();
    filteredSections.features.forEach(({ geometry: { coordinates } }) => {
      coordinates.forEach(([lng, lat]) => bounds.extend([lng, lat]));
    });

    currentMap?.fitBounds(bounds, { padding: 50 });

    setData(filteredSections);
  }

  return (
    <Ranking
      disableValue
      enableSubtitles
      data={discontinuityResolutions?.reduce<{ id?: number; subtitle: string; title: string }[]>(
        (res, { score, distance }, index) => {
          res.push({
            id: index,
            title: t('cycling-insights.facilities.discontinuity.resolutions.item_title', {
              index: index + 1,
            }),
            subtitle: `Score : ${Math.round(score * 100) / 100} • ${toDistance(distance || 0)}`,
          });

          return res;
        },
        [],
      )}
      onClick={selectResolutionIndex}
      selectedKey={selectedResolutionIndex}
      startIcon={<EditRoad sx={{ color: '#E76685' }} />}
    />
  );
}

export default Resolutions;
