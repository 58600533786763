import { TCyclingProfile, cyclingProfiles } from '@geovelo-frontends/commons';
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch } from '@mui/material';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

const profiles: TCyclingProfile[] = ['safe', 'daily', 'direct', 'touristic', 'hybridBike', 'cargo'];

interface IProps {
  disabled: boolean;
  eBikeEnabled: boolean;
  onEBikeToggle: (enabled: boolean) => void;
  onProfileChange: (profile: TCyclingProfile) => void;
  profile: TCyclingProfile;
}

function IsochronesSettingsForm({
  disabled,
  profile,
  eBikeEnabled,
  onEBikeToggle,
  onProfileChange,
}: IProps): JSX.Element {
  return (
    <Wrapper>
      <FormControl fullWidth disabled={disabled}>
        <InputLabel htmlFor="cycling-profile-label">
          <Trans i18nKey="cycling-insights.facilities.isochrones.form.cycling_profile" />
        </InputLabel>
        <Select
          id="cycling-profile-select"
          label={<Trans i18nKey="cycling-insights.facilities.isochrones.form.cycling_profile" />}
          labelId="cycling-profile-label"
          onChange={({ target: { value } }) => onProfileChange(value as TCyclingProfile)}
          size="small"
          value={profile}
        >
          {profiles.map((key) => (
            <MenuItem key={key} value={key}>
              <Trans i18nKey={cyclingProfiles[key]?.labelKey} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        control={<Switch checked={eBikeEnabled} color="primary" size="small" />}
        disabled={disabled}
        label={<Trans i18nKey="cycling-insights.facilities.isochrones.form.e_bike" />}
        onChange={(_, enabled) => onEBikeToggle(enabled)}
        value={eBikeEnabled}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

export default IsochronesSettingsForm;
