import { BikeRoute, BikeRouteService, useCancellablePromise } from '@geovelo-frontends/commons';
import { AddCircleOutline, ArrowDownward, ArrowUpward, Close, Search } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Radio,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Button from '../../../../components/button';
import { TOutletContext } from '../../../../layouts/page/container';
import { TQAPageContext } from '../../context';

import BikeRouteFormDialog from './form-dialog';

type TSort = 'creation_asc' | 'creation_desc' | 'title_asc' | 'title_desc';

const sorts: Array<{
  key: TSort;
  orderBy: 'id' | 'title';
  order: 'asc' | 'desc';
  labelKey: string;
}> = [
  {
    key: 'creation_asc',
    orderBy: 'id',
    order: 'asc',
    labelKey: 'cycling-insights.tourism.bike_routes.sorts.creation_date',
  },
  {
    key: 'creation_desc',
    orderBy: 'id',
    order: 'desc',
    labelKey: 'cycling-insights.tourism.bike_routes.sorts.creation_date',
  },
  {
    key: 'title_asc',
    orderBy: 'title',
    order: 'asc',
    labelKey: 'cycling-insights.tourism.bike_routes.sorts.title',
  },
  {
    key: 'title_desc',
    orderBy: 'title',
    order: 'desc',
    labelKey: 'cycling-insights.tourism.bike_routes.sorts.title',
  },
];

function BikeRoutesForm({
  bikeRoutes: { list, setList },
}: TQAPageContext & TOutletContext): JSX.Element {
  const [bikeRoutes, setBikeRoutes] = useState<BikeRoute[]>();
  const [search, setSearch] = useState('');
  const [selectedSort, selectSort] = useState<TSort>('creation_desc');
  const [initialized, setInitialized] = useState(false);
  const [formDialogOpen, openFormDialog] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    getBikeRoutes();

    return () => {
      cancelPromises();
      setList(undefined);
    };
  }, []);

  useEffect(() => {
    if (!initialized) return;

    const timeout = setTimeout(() => filter(), 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [search]);

  useEffect(() => {
    filter();
  }, [bikeRoutes, selectedSort]);

  async function getBikeRoutes() {
    try {
      const bikeRoutes = await cancellablePromise(
        BikeRouteService.getBikeRoutes({ edition: true }),
      );

      setBikeRoutes(bikeRoutes);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.tourism.bike_routes.list.server_error'), {
          variant: 'error',
        });
      }
    }

    setInitialized(true);
  }

  function filter() {
    const sort = sorts.find(({ key }) => key === selectedSort);
    if (!sort || !bikeRoutes) return;

    const { order, orderBy } = sort;

    setList(
      [...bikeRoutes]
        .filter(({ title }) => !search || title.toLowerCase().indexOf(search.toLowerCase()) > -1)
        .sort((a, b) => {
          if (orderBy === 'id') {
            return order === 'asc' ? a.id - b.id : b.id - a.id;
          } else if (orderBy === 'title') {
            return order === 'asc'
              ? a.title.localeCompare(b.title)
              : b.title.localeCompare(a.title);
          }

          return 0;
        }),
    );
  }

  function handleSearchChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    setSearch(value);
  }

  return (
    <>
      <Box display="flex" flexDirection="column" minHeight="100%">
        <Box display="flex" flexDirection="column" flexShrink={0} gap={2} padding={2}>
          <Box>
            <TextField
              fullWidth
              disabled={!initialized}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearch('')} size="small">
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleSearchChange}
              placeholder={t('commons.actions.search') || ''}
              size="small"
              value={search}
              variant="outlined"
            />
          </Box>
          <Box>
            <Typography color="textSecondary" variant="caption">
              <Trans i18nKey="commons.form.sort_by" />
            </Typography>
            <FormControl component="fieldset">
              <FormGroup row>
                {sorts.map(({ key, labelKey, order }) => (
                  <FormControlLabel
                    disableTypography
                    control={
                      <Radio
                        checked={selectedSort === key}
                        name={key}
                        onChange={(_, checked) => checked && selectSort(key)}
                        size="small"
                      />
                    }
                    key={key}
                    label={
                      <Box display="flex" gap={1}>
                        <Typography variant="body2">
                          <Trans i18nKey={labelKey} />
                        </Typography>
                        {order === 'asc' ? (
                          <ArrowDownward color="action" fontSize="small" />
                        ) : (
                          <ArrowUpward color="action" fontSize="small" />
                        )}
                      </Box>
                    }
                    style={{ width: '100%' }}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
        <Box flexGrow={1} />
        <Box display="flex" flexShrink={0} justifyContent="center" padding={1}>
          <Button
            color="primary"
            disabled={!list}
            onClick={() => openFormDialog(true)}
            size="medium"
            startIcon={<AddCircleOutline />}
            variant="contained"
          >
            <Trans i18nKey="cycling-insights.tourism.bike_routes.actions.add" />
          </Button>
        </Box>
      </Box>
      <BikeRouteFormDialog onClose={() => openFormDialog(false)} open={formDialogOpen} />
    </>
  );
}

export default BikeRoutesForm;
