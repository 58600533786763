import { Partner, PartnerService, UserService } from '@geovelo-frontends/commons';
import { Edit, Login, Logout } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Fragment, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../app/context';
import Button from '../../components/button';

type TProps = Omit<DialogProps, 'onClose'> & { onClose: () => void };

function PartnersDialog({ onClose, ...props }: TProps): JSX.Element {
  const [joining, setJoining] = useState<{ [key: number]: boolean }>({});
  const [leaving, setLeaving] = useState<{ [key: number]: boolean }>({});
  const {
    user: { current: currentUser },
    partner: { lastVisited: lastVisitedPartner, list: partners, all: allPartners },
    actions: { openCreatePartnerDialog, openEditPartnerDialog, setPartnerToEdit, setUserPartners },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { transitions } = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  async function handlePartnerLeave(partner: Partner) {
    if (!currentUser || !partners) return;

    const { id } = partner;

    setLeaving({ [id]: true });

    try {
      await PartnerService.removePartnerUser(partner, currentUser.id);

      setTimeout(async () => {
        const partners = (
          await UserService.getPartners({
            isAdmin: currentUser?.isGeovelo,
            isSupport: currentUser?.isSupport,
          })
        )
          .filter(({ contracts }) => {
            return !!contracts?.find(
              ({ contractTemplate: { code } }) => code.indexOf('entreprise') === -1,
            );
          })
          .sort((a, b) => a.title.localeCompare(b.title));

        setUserPartners([...partners]);
        enqueueSnackbar(t('commons.user_dialog.success_leave'), { variant: 'success' });
      }, 500);
    } catch {
      enqueueSnackbar(t('commons.user_dialog.server_error_leave'), { variant: 'error' });
    }

    setLeaving({ [id]: false });
  }

  async function handlePartnerJoin(partner: Partner) {
    if (!currentUser || !partners) return;

    const { id, code } = partner;

    setJoining({ [id]: true });

    try {
      await PartnerService.addPartnerUser(partner, currentUser.id, true);

      setTimeout(async () => {
        const partners = (
          await UserService.getPartners({
            isAdmin: currentUser?.isGeovelo,
            isSupport: currentUser?.isSupport,
          })
        )
          .filter(({ contracts }) => {
            return !!contracts?.find(
              ({ contractTemplate: { code } }) => code.indexOf('entreprise') === -1,
            );
          })
          .sort((a, b) => a.title.localeCompare(b.title));

        setUserPartners([...partners]);
        navigate(`/${code}`);
      }, 500);
    } catch (err) {
      enqueueSnackbar(t('commons.user_dialog.server_error_join'), { variant: 'error' });

      setJoining({ [id]: false });
    }
  }

  if (!currentUser || !lastVisitedPartner) return <></>;

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} {...props}>
      <Box alignItems="center" display="flex" flexDirection="column" padding={2}>
        {lastVisitedPartner.icon ? (
          <StyledAvatar src={lastVisitedPartner.icon} />
        ) : (
          <StyledAvatar>{lastVisitedPartner.code[0].toUpperCase()}</StyledAvatar>
        )}
        <Typography variant="h6">{lastVisitedPartner.title}</Typography>
        <Typography color="textSecondary" variant="subtitle1">
          {currentUser.email}
        </Typography>
      </Box>
      {partners && partners.length > 0 && (
        <DialogContent dividers>
          <List
            dense
            component="nav"
            subheader={
              <ListSubheader disableSticky>
                <Trans i18nKey="commons.user_dialog.dashboards" />
              </ListSubheader>
            }
            sx={{ margin: '0 -24px', padding: 0 }}
          >
            {partners.map((partner, index) => {
              const { id, code, title, icon } = partner;
              const active = code === lastVisitedPartner.code;

              return (
                <Box
                  component={active ? ListItem : ListItemButton}
                  key={`${code}-${index}`}
                  onClick={() => {
                    onClose();
                    navigate(`/${code}`);
                  }}
                  sx={active ? { backgroundColor: 'whitesmoke' } : {}}
                >
                  <ListItemAvatar>
                    {icon ? (
                      <StyledPartnerAvatar src={icon} />
                    ) : (
                      <StyledPartnerAvatar>{code[0].toUpperCase()}</StyledPartnerAvatar>
                    )}
                  </ListItemAvatar>
                  <ListItemText primary={title} />
                  {active ? (
                    <ListItemSecondaryAction>
                      <Tooltip placement="left" title={<Trans i18nKey="commons.actions.update" />}>
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            onClose();
                            setTimeout(() => {
                              openEditPartnerDialog(true);
                              setPartnerToEdit(partner);
                            }, transitions.duration.leavingScreen);
                          }}
                          size="small"
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  ) : (
                    currentUser.isGeovelo && (
                      <ListItemSecondaryAction>
                        <Tooltip
                          placement="left"
                          title={<Trans i18nKey="commons.user_dialog.actions.leave_dashboard" />}
                        >
                          <div>
                            <IconButton
                              color="error"
                              disabled={leaving[id]}
                              onClick={(event) => {
                                event.stopPropagation();
                                handlePartnerLeave(partner);
                              }}
                              size="small"
                            >
                              <Logout fontSize="small" />
                            </IconButton>
                          </div>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    )
                  )}
                </Box>
              );
            })}
          </List>
          {currentUser.isGeovelo && (
            <List
              dense
              subheader={
                <ListSubheader disableSticky>
                  <Trans i18nKey="commons.user_dialog.other_dashboards" />
                </ListSubheader>
              }
              sx={{ margin: '0 -24px', padding: 0 }}
            >
              {allPartners
                ?.filter(({ code }) => !partners.find((partner) => partner.code === code))
                .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
                .map((partner, index) => {
                  const { id, code, title, icon } = partner;

                  return (
                    <Fragment key={`${code}-${index}`}>
                      {index > 0 && <Divider />}
                      <ListItem>
                        <ListItemAvatar>
                          {icon ? (
                            <StyledPartnerAvatar src={icon} />
                          ) : (
                            <StyledPartnerAvatar>{code[0].toUpperCase()}</StyledPartnerAvatar>
                          )}
                        </ListItemAvatar>
                        <ListItemText primary={title} />
                        <ListItemSecondaryAction>
                          <Tooltip
                            placement="left"
                            title={<Trans i18nKey="commons.user_dialog.actions.join_dashboard" />}
                          >
                            <div>
                              <IconButton
                                disabled={joining[id]}
                                onClick={() => handlePartnerJoin(partner)}
                                size="small"
                              >
                                <Login fontSize="small" />
                              </IconButton>
                            </div>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Fragment>
                  );
                })}
            </List>
          )}
        </DialogContent>
      )}
      {currentUser.isGeovelo && (
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              onClose();
              setTimeout(() => openCreatePartnerDialog(true), transitions.duration.leavingScreen);
            }}
            variant="outlined"
          >
            <Trans i18nKey="commons.actions.create_partner" />
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

const StyledAvatar = styled(Avatar)`
  && {
    background-color: ${({ theme }) => theme.palette.secondary.light};
    border: 1px solid whitesmoke;
    color: ${({ theme }) => theme.palette.primary.dark};
    font-size: 2em;
    font-weight: 700;
    height: 64px;
    width: 64px;
  }
`;

const StyledPartnerAvatar = styled(Avatar)`
  && {
    background-color: ${({ theme }) => theme.palette.secondary.light};
    border: 1px solid whitesmoke;
    color: ${({ theme }) => theme.palette.primary.dark};
    font-size: 0.9em;
    font-weight: 700;
    height: 24px;
    width: 24px;
  }
`;

export default PartnersDialog;
