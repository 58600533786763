import { RouteReportService, useCancellablePromise } from '@geovelo-frontends/commons';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import PeriodForm from '../../../../components/form/period';
import useItineraryReport from '../../../../hooks/map/itinerary-reports';
import { TQAPageContext } from '../../context';

import ItineraryReportsTable from './table';

function ItineraryReportsForm(context: TQAPageContext): JSX.Element {
  const {
    period,
    reports: { selectedReport, setReports, selectReport },
  } = context;
  const {
    map: { current: currentMap },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { update: updateOnMap } = useItineraryReport(currentMap || undefined);
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    return () => {
      cancelPromises();
      setReports(undefined);
      selectReport(undefined);
    };
  }, []);

  useEffect(() => {
    update();
  }, [period.values.current]);

  useEffect(() => {
    if (currentMap && currentPartner) updateOnMap(selectedReport);
  }, [currentMap, selectedReport]);

  async function update() {
    setReports(undefined);
    cancelPromises();

    if (!currentPartner) {
      setReports([]);

      return;
    }

    try {
      const reports = await cancellablePromise(
        RouteReportService.getRouteReports({ period: period.values.current.toIPeriod() }),
      );
      setReports(reports);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.reports.itinerary_reports.server_error'), {
          variant: 'error',
        });
      }
    }
  }

  return (
    <Box display="flex" flexDirection="column" minHeight="100%">
      <Box display="flex" flexDirection="column" gap={3}>
        <PeriodForm disableComparison disablePadding {...period} />
        <Box flexGrow={1} marginX={-3} sx={{ overflowY: 'auto' }}>
          <ItineraryReportsTable {...context} />
        </Box>
      </Box>
    </Box>
  );
}

export default ItineraryReportsForm;
