import { IRideStep, Ride } from '@geovelo-frontends/commons';
import { Dialog, DialogProps, DialogTitle, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import RideStepDataContent from './data-content';
import RideStepImagesContent from './images-content';
import RideStepMediasContent from './medias-content';

type TProps = Omit<DialogProps, 'onClose' | 'onChange'> & {
  canWrite: boolean;
  initialTab: 'data' | 'photos' | 'medias';
  onChange: (ride: Ride) => void;
  onClose: (step?: IRideStep) => void;
  ride: Ride;
  step: IRideStep | null;
};

function RideStepEditDialog({
  canWrite,
  initialTab,
  ride,
  step,
  onChange,
  onClose,
  ...props
}: TProps): JSX.Element {
  const [selectedTab, selectTab] = useState<'data' | 'photos' | 'medias'>(initialTab);
  const { t } = useTranslation();

  useEffect(() => {
    selectTab(initialTab);
  }, [initialTab]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      sx={{
        '.MuiDialog-container': { alignItems: 'flex-start' },
        '.MuiPaper-root': { borderRadius: 4 },
      }}
      {...props}
    >
      <DialogTitle color="#283859" fontSize="1.125rem" fontWeight="700">
        {t('cycling-insights.ride.steps.edit_dialog.title', {
          context: canWrite ? (step && 'id' in step ? undefined : 'new') : 'view',
        })}
      </DialogTitle>
      <Tabs
        aria-label="Ride edition tabs"
        onChange={(_, value) => selectTab(value)}
        value={selectedTab}
      >
        <Tab
          label={<Trans i18nKey="cycling-insights.ride.steps.data_content.title" />}
          value="data"
        />
        <Tab
          label={<Trans i18nKey="cycling-insights.ride.steps.images_content.title" />}
          value="photos"
        />
        <Tab
          label={<Trans i18nKey="cycling-insights.ride.steps.medias_content.title" />}
          value="medias"
        />
      </Tabs>
      <>
        {selectedTab === 'data' && (
          <RideStepDataContent canWrite={canWrite} onClose={onClose} ride={ride} step={step} />
        )}
        {selectedTab === 'photos' && (
          <RideStepImagesContent
            canWrite={canWrite}
            onChange={onChange}
            onClose={onClose}
            ride={ride}
            step={step}
          />
        )}
        {selectedTab === 'medias' && (
          <RideStepMediasContent
            canWrite={canWrite}
            onChange={onChange}
            onClose={onClose}
            ride={ride}
            step={step}
          />
        )}
      </>
    </Dialog>
  );
}

export default RideStepEditDialog;
