import { IBounds, useGeoveloMap, useSource } from '@geovelo-frontends/commons';
import { purple } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { MapLayerMouseEvent, MapMouseEvent } from '!maplibre-gl';

export const defaultBounds: IBounds = {
  north: 51.39124445,
  east: 8.56187094,
  south: 42.23367292,
  west: -5.56077119,
};

function BoundsMap({
  id,
  visible,
  baseBounds,
  initialBounds,
  onBoundsUpdated,
}: {
  baseBounds?: IBounds;
  id: string;
  initialBounds?: IBounds;
  onBoundsUpdated: (bounds: IBounds) => void;
  visible: boolean;
}): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const boundsPoints = useRef<[[number, number], [number, number]]>();
  const draggedPointIndex = useRef<number | null>(null);
  const {
    transitions: { duration },
  } = useTheme();
  const {
    initialized: mapInitialized,
    map,
    init: initMap,
  } = useGeoveloMap({
    smallDevice: true,
    baseLayer: 'geovelo',
  });
  const sourceId = `${id}-bounds-layer-source`;
  const baseSourceId = `${id}-base-bounds-layer-source`;
  const { addGeoJSONSource, updateGeoJSONSource, clearGeoJSONSource } = useSource(map, sourceId);
  const {
    addGeoJSONSource: addBaseGeoJSONSource,
    updateGeoJSONSource: updateBaseGeoJSONSource,
    clearGeoJSONSource: clearBaseGeoJSONSource,
  } = useSource(map, baseSourceId);

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        const { north, east, south, west } = defaultBounds;

        initMap({
          container: id,
          bounds: [east, south, west, north],
          customZoomControls: true,
          baseLayersControl: false,
          attributionControl: false,
          scaleControl: false,
          scrollZoom: false,
        });
      }, duration.enteringScreen);
    }
  }, [visible]);

  useEffect(() => {
    const cursorLayerId = `${id}-cursors-layer`;

    function handleMouseMove() {
      if (!map) return;

      map.getCanvas().style.cursor = 'move';
    }

    function handleMouseLeave() {
      if (!map) return;

      map.getCanvas().style.cursor = '';
    }

    function handleDrag({ lngLat: { lat, lng } }: MapMouseEvent) {
      if (draggedPointIndex.current === null || !boundsPoints.current) return;

      boundsPoints.current[draggedPointIndex.current] = [lng, lat];

      updateGeoJSONSource(getGeoJSON(boundsPoints.current));
    }

    function handleDrop() {
      if (!map) return;

      draggedPointIndex.current = null;

      map.getCanvas().style.cursor = '';

      map.off('mouseup', handleDrop);
      map.off('mousemove', handleDrag);

      if (!boundsPoints.current) return;

      const [[west, north], [east, south]] = boundsPoints.current;
      onBoundsUpdated({ north, east, south, west });
    }

    function handleMouseDown(event: MapLayerMouseEvent) {
      event.preventDefault();

      const index = event.features?.[0].properties?.index;

      if (!map || typeof index !== 'number') return;

      draggedPointIndex.current = index;

      map.getCanvas().style.cursor = 'grab';

      map.on('mousemove', handleDrag);
      map.on('mouseup', handleDrop);
    }

    if (visible && map && mapInitialized) {
      addBaseGeoJSONSource();
      addGeoJSONSource();

      map?.setPadding({ top: 50, right: 50, bottom: 50, left: 50 });

      const baseBoundsLayerId = `${id}-base-bounds-layer`;
      if (!map.getLayer(baseBoundsLayerId)) {
        map.addLayer(
          {
            id: baseBoundsLayerId,
            type: 'line',
            source: baseSourceId,
            paint: {
              'line-color': '#000',
              'line-opacity': 0.5,
              'line-width': 1,
            },
          },
          'labels',
        );
      }

      const boundsLayerId = `${id}-bounds-layer`;
      if (!map.getLayer(boundsLayerId)) {
        map.addLayer(
          {
            id: boundsLayerId,
            type: 'line',
            source: sourceId,
            paint: {
              'line-color': purple[500],
              'line-width': 2,
              'line-dasharray': [1, 1],
            },
          },
          'labels',
        );
      }

      if (!map.getLayer(cursorLayerId)) {
        map.addLayer(
          {
            id: cursorLayerId,
            type: 'circle',
            source: sourceId,
            paint: {
              'circle-color': '#fff',
              'circle-radius': 4,
              'circle-stroke-color': purple[500],
              'circle-stroke-width': 2,
            },
            filter: ['==', 'cursor', true],
          },
          'labels',
        );
      }

      setInitialized(true);
    }

    map?.on('mousemove', cursorLayerId, handleMouseMove);
    map?.on('mouseleave', cursorLayerId, handleMouseLeave);
    map?.on('mousedown', cursorLayerId, handleMouseDown);

    return () => {
      map?.off('mousemove', cursorLayerId, handleMouseMove);
      map?.off('mouseleave', cursorLayerId, handleMouseLeave);
      map?.off('mousedown', cursorLayerId, handleMouseDown);

      setInitialized(false);
    };
  }, [mapInitialized, visible]);

  useEffect(() => {
    if (initialized && initialBounds) {
      const { north, east, south, west } = initialBounds;
      map?.fitBounds([east, south, west, north]);

      boundsPoints.current = [
        [west, north],
        [east, south],
      ];

      updateGeoJSONSource(getGeoJSON(boundsPoints.current));
    }

    return () => {
      clearGeoJSONSource();
    };
  }, [initialBounds, initialized]);

  useEffect(() => {
    if (initialized && baseBounds) {
      const { north, west, south, east } = baseBounds;

      updateBaseGeoJSONSource({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [west, north],
                  [east, north],
                  [east, south],
                  [west, south],
                  [west, north],
                ],
              ],
            },
            properties: {},
          },
        ],
      });
    }

    return () => {
      clearBaseGeoJSONSource();
    };
  }, [baseBounds, initialized]);

  return <Wrapper id={id} />;
}

function getGeoJSON([p1, p2]: [[number, number], [number, number]]): GeoJSON.FeatureCollection {
  const north = Math.max(p1[1], p2[1]);
  const east = Math.max(p1[0], p2[0]);
  const south = Math.min(p1[1], p2[1]);
  const west = Math.min(p1[0], p2[0]);

  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [west, north],
              [east, north],
              [east, south],
              [west, south],
              [west, north],
            ],
          ],
        },
        properties: {},
      },
      {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: p1 },
        properties: { cursor: true, index: 0 },
      },
      {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: p2 },
        properties: { cursor: true, index: 1 },
      },
    ],
  };
}

const Wrapper = styled.div`
  height: 300px;
  position: relative;
`;

export default BoundsMap;
