import {
  ComputedRoute,
  IPoint,
  IRideStep,
  Place,
  Ride,
  RideTrace,
  Route,
  Search,
  TSectionFeature,
  TWayPoint,
  getDraggedRoutePreview,
  getRoutePreview,
  useSource,
} from '@geovelo-frontends/commons';
import { blueGrey, green, purple, red } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { useRef } from 'react';
import { createRoot } from 'react-dom/client';

import arrowImage from '../../assets/images/map-arrow.png';
import newStepMarkerUrl from '../../assets/images/new-step-marker.svg';
import NewRideStepPopup from '../../components/map/new-ride-step-popup';
import RideStepPopup from '../../components/map/ride-step-popup';

import { Map, MapMouseEvent, Marker, Popup, SymbolLayerSpecification } from '!maplibre-gl';

const arrowPreviewLayerId = 'arrow-preview';
const arrowRouteLayerId = 'arrow-route';
const routeSourceId = 'route';
const wayPointsLayerId = 'way-points';
const routeLayerId = 'route';
const draggedRouteLayerId = 'dragged-route';
const previewSourceId = 'preview';
const previewWayPointsLayerId = 'preview-way-points';
const previewLayerId = 'preview';
const tracesSourceId = 'traces';
const tracesLayerId = 'traces';

// TODO: refactor to use useRouting hook
function useRideAdmin(
  map: Map | undefined,
  canWrite: boolean,
  {
    onClick,
    onWayPointsUpdate,
    onWayPointClicked,
    onStepAdd,
    onStepUpdate,
    onStepDragged,
  }: {
    onClick?: (location: IPoint) => void;
    onWayPointsUpdate?: (wayPoints: TWayPoint[]) => void;
    onWayPointClicked?: (wayPoints: TWayPoint[], index: number) => void;
    onStepAdd?: (location: IPoint) => void;
    onStepUpdate?: (step: IRideStep) => void;
    onStepDragged?: (step: IRideStep, location: IPoint) => void;
  } = {},
): {
  init: () => void;
  enableEdition: (enabled: boolean) => void;
  enableEditionReturnRoute: (enabled: boolean) => void;
  updatePreview: (
    ride: Ride | null,
    route?: Route | ComputedRoute | null,
    returnRoute?: Route | ComputedRoute | null,
    hidePoints?: boolean,
  ) => void;
  updateRoute: (
    route: Route | ComputedRoute | { wayPoints: TWayPoint[]; preview: TSectionFeature } | null,
    options?: { allowWayPointsSnapping?: boolean; search?: Search; hasDirection?: boolean },
  ) => void;
  updateTraces: (traces: RideTrace[]) => void;
  updateSteps: (steps: IRideStep[], editable?: boolean) => void;
  updateNewStep: (location: IPoint | null) => void;
} {
  const returnRouteColor = blueGrey[300];
  const editing = useRef(false);
  const wayPointsRef = useRef<TWayPoint[] | null>(null);
  const draggedWayPointIndex = useRef<number | null>(null);
  const stepMarkers = useRef<Marker[]>([]);
  const newStepMarker = useRef<Marker | null>(null);
  const { palette } = useTheme();
  const {
    addGeoJSONSource: addRouteSource,
    getGeoJSONSource: getRouteSource,
    updateGeoJSONSource: updateRouteSource,
    clearGeoJSONSource: clearRouteSource,
  } = useSource(map, routeSourceId);
  const {
    addGeoJSONSource: addPreviewSource,
    updateGeoJSONSource: updatePreviewSource,
    clearGeoJSONSource: clearPreviewSource,
  } = useSource(map, previewSourceId);
  const { addGeoJSONSource: addTracesSource, updateGeoJSONSource: updateTracesSource } = useSource(
    map,
    tracesSourceId,
  );
  const returnRoute = useRef(false);

  function handleClick(event: MapMouseEvent) {
    if (
      map
        ?.queryRenderedFeatures(event.point)
        .filter((feature) => feature.layer.id === wayPointsLayerId).length !== 0 ||
      event.originalEvent.defaultPrevented
    )
      return;

    event.preventDefault();

    const { lngLat } = event;
    let { lat, lng } = lngLat;
    lat = Math.round(lat * 1000000) / 1000000;
    lng = Math.round(lng * 1000000) / 1000000;

    if (onClick) onClick(lngLat);

    if (editing.current && wayPointsRef.current) {
      const wayPoints = [...wayPointsRef.current];
      wayPoints.push(new Place(undefined, { type: 'Point', coordinates: [lng, lat] }));

      const preview = getRoutePreview(wayPoints);

      updateRoute({ wayPoints, preview });
      if (onWayPointsUpdate) onWayPointsUpdate(wayPoints);
    }
  }

  function handleMove({ lngLat }: MapMouseEvent) {
    if (!map || !wayPointsRef.current || draggedWayPointIndex.current === null) return;

    map.getCanvas().style.cursor = 'grabbing';

    const { wayPoints, feature: preview } = getDraggedRoutePreview(
      wayPointsRef.current,
      draggedWayPointIndex.current,
      lngLat,
    );

    updateRoute({ wayPoints, preview });
  }

  function handleDrop(event: MapMouseEvent) {
    event.preventDefault();

    if (!map) return;

    // map.getCanvas().style.cursor = 'pointer';

    if (wayPointsRef.current && onWayPointsUpdate) onWayPointsUpdate(wayPointsRef.current);

    map.off('mousemove', handleMove);
    setTimeout(() => map.on('click', handleClick), 200);
  }

  function initNewStepMarker() {
    const element = document.createElement('div');
    element.className = 'marker';
    element.style.backgroundImage = `url(${newStepMarkerUrl})`;
    element.style.backgroundPosition = 'center';
    element.style.backgroundSize = 'cover';
    element.style.width = '32px';
    element.style.height = '32px';
    element.style.cursor = 'move';

    element.addEventListener('click', (event) => {
      event.stopPropagation();
    });

    newStepMarker.current = new Marker({ element, anchor: 'bottom', draggable: true }).setPopup(
      new Popup({
        anchor: 'bottom',
        offset: [0, -24],
        closeButton: false,
        closeOnClick: false,
      }).setHTML('<div id="new-ride-step-popup"></div>'),
    );
  }

  function init() {
    async function addArrowImage() {
      if (!map) return;

      try {
        const image = await map.loadImage(arrowImage);
        if (image && !map.hasImage('arrow')) map.addImage('arrow', image.data);
      } catch (err) {
        console.error('err image', err);
      }
    }

    if (getRouteSource()) return;

    addRouteSource();
    addPreviewSource();
    addTracesSource();

    initNewStepMarker();

    addArrowImage();

    const arrowLayout: SymbolLayerSpecification['layout'] = {
      'symbol-placement': 'line',
      'symbol-spacing': 100,
      'icon-allow-overlap': true,
      'icon-image': 'arrow',
      'icon-size': 1,
      visibility: 'none',
    };

    map?.addLayer({
      id: arrowPreviewLayerId,
      type: 'symbol',
      source: previewSourceId,
      layout: arrowLayout,
    });

    map?.addLayer({
      id: arrowRouteLayerId,
      type: 'symbol',
      source: routeSourceId,
      layout: arrowLayout,
    });

    map?.addLayer({
      id: previewWayPointsLayerId,
      type: 'circle',
      source: previewSourceId,
      paint: {
        'circle-radius': 6,
        'circle-stroke-width': 2,
        'circle-stroke-opacity': 1,
        'circle-color': ['get', 'color'],
        'circle-stroke-color': '#fff',
      },
      filter: ['==', 'wayPoint', true],
    });

    map?.addLayer(
      {
        id: previewLayerId,
        type: 'line',
        source: previewSourceId,
        paint: {
          'line-color': ['get', 'color'],
          'line-width': ['get', 'width'],
        },
      },
      'labels',
    );

    map?.addLayer(
      {
        id: routeLayerId,
        type: 'line',
        source: routeSourceId,
        paint: {
          'line-color': ['get', 'color'],
          'line-width': ['get', 'width'],
        },
        filter: ['!=', 'dragged', true],
      },
      'labels',
    );

    map?.addLayer(
      {
        id: draggedRouteLayerId,
        type: 'line',
        source: routeSourceId,
        paint: {
          'line-color': ['get', 'color'],
          'line-width': ['get', 'width'],
          'line-dasharray': [1, 1],
        },
        filter: ['==', 'dragged', true],
      },
      'labels',
    );

    map?.addLayer({
      id: wayPointsLayerId,
      type: 'circle',
      source: routeSourceId,
      paint: {
        'circle-radius': 6,
        'circle-stroke-width': 2,
        'circle-stroke-opacity': 1,
        'circle-color': ['get', 'color'],
        'circle-stroke-color': '#fff',
      },
      filter: ['==', 'wayPoint', true],
    });

    map?.addLayer(
      {
        id: tracesLayerId,
        type: 'line',
        source: tracesSourceId,
        paint: {
          'line-color': purple[500],
          'line-width': 2,
        },
      },
      'labels',
    );

    map?.on('mousemove', routeLayerId, () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    map?.on('mouseleave', routeLayerId, () => {
      map.getCanvas().style.cursor = '';
    });

    map?.on('click', wayPointsLayerId, (event) => {
      if (event.defaultPrevented || returnRoute.current === true) return;

      event.preventDefault();
      if (onWayPointClicked && wayPointsRef.current) {
        if (event.features?.[0].properties?.index === 0) {
          const wayPoints = [...wayPointsRef.current];
          wayPoints.push(wayPointsRef.current[0]);

          const preview = getRoutePreview(wayPoints);

          updateRoute({ wayPoints, preview });
        }
        onWayPointClicked(wayPointsRef.current, event.features?.[0].properties?.index);
      }
    });

    map?.on('mousemove', wayPointsLayerId, ({ features }) => {
      if (features && features.length > 0) {
        map.getCanvas().style.cursor = 'move';
      }
    });

    map?.on('mouseleave', wayPointsLayerId, () => {
      map.getCanvas().style.cursor = '';
    });

    map?.on('mousedown', wayPointsLayerId, (event) => {
      if (!event.originalEvent.button) {
        event.preventDefault();

        map.getCanvas().style.cursor = 'grab';

        draggedWayPointIndex.current = event.features?.[0].properties?.index;

        map.off('click', handleClick);
        map.on('mousemove', handleMove);
        map.once('mouseup', handleDrop);
      }
    });

    map?.on('contextmenu', wayPointsLayerId, (event) => {
      event.preventDefault();

      const index: number | undefined = event.features?.[0].properties?.index;
      if (wayPointsRef.current && index !== undefined) {
        const wayPoints = [...wayPointsRef.current];
        wayPoints.splice(index, 1);

        const preview = getRoutePreview(wayPoints);

        updateRoute({ wayPoints, preview });
        if (onWayPointsUpdate) onWayPointsUpdate(wayPoints);
      }
    });

    map?.on('mousedown', routeLayerId, (event) => {
      if (!event.originalEvent.button) {
        const {
          lngLat: { lat, lng },
          features,
        } = event;

        if (event.defaultPrevented) return;

        event.preventDefault();

        const prevWayPointIndex: number | undefined = features?.[0].properties?.prevWayPointIndex;
        if (wayPointsRef.current && prevWayPointIndex !== undefined) {
          const index = prevWayPointIndex + 1;
          const wayPoints = [...wayPointsRef.current];
          wayPoints.splice(
            index,
            0,
            new Place(undefined, { type: 'Point', coordinates: [lng, lat] }),
          );

          wayPointsRef.current = wayPoints;
          draggedWayPointIndex.current = index;

          handleMove(event);

          map.off('click', handleClick);
          map.on('mousemove', handleMove);
          map.once('mouseup', handleDrop);
        }
      }
    });

    map?.on('click', handleClick);
  }

  function enableEdition(enabled: boolean) {
    editing.current = enabled;
  }

  function enableEditionReturnRoute(enabled: boolean) {
    returnRoute.current = enabled;
  }

  function updatePreview(
    ride: Ride | null,
    route?: Route | ComputedRoute | null,
    returnRoute?: Route | ComputedRoute | null,
    hidePoints?: boolean,
  ) {
    if (!ride) {
      clearPreviewSource();

      return;
    }

    if (ride.isLoop) {
      map?.setLayoutProperty(arrowPreviewLayerId, 'visibility', 'visible');
    } else {
      map?.setLayoutProperty(arrowPreviewLayerId, 'visibility', 'none');
    }

    const { from, to } = ride;
    const features: GeoJSON.Feature[] = [];

    if (to && !hidePoints)
      features.push({
        type: 'Feature',
        geometry: to.point,
        properties: { color: red[500], wayPoint: true },
      });
    if (from && !hidePoints)
      features.push({
        type: 'Feature',
        geometry: from.point,
        properties: { color: green[500], wayPoint: true },
      });

    if (returnRoute?.geometry) {
      const geometry = returnRoute.geometry;
      features.push(
        { type: 'Feature', geometry, properties: { color: '#fff', width: 7 } },
        {
          type: 'Feature',
          geometry,
          properties: { color: hidePoints ? '#a7a7a7' : returnRouteColor, width: 5 },
        },
      );
    }

    if (route?.geometry) {
      const geometry = route.geometry;
      features.push(
        { type: 'Feature', geometry, properties: { color: '#fff', width: 7 } },
        {
          type: 'Feature',
          geometry,
          properties: { color: hidePoints ? '#a7a7a7' : palette.secondary.main, width: 5 },
        },
      );
    }

    updatePreviewSource({ type: 'FeatureCollection', features });
  }

  function updateRoute(
    route: Route | ComputedRoute | { wayPoints: TWayPoint[]; preview: TSectionFeature } | null,
    {
      allowWayPointsSnapping,
      search,
      hasDirection,
    }: { allowWayPointsSnapping?: boolean; search?: Search; hasDirection?: boolean } = {},
  ) {
    const features: GeoJSON.Feature[] = [];

    if (!route) {
      wayPointsRef.current = [];

      clearRouteSource();

      search?.wayPoints.forEach((wayPoint: Place | TWayPoint, index: number) => {
        if (wayPoint) {
          features.push({
            type: 'Feature',
            geometry: wayPoint.point,
            properties: {
              index,
              color:
                index === 0
                  ? green[500]
                  : index === search.wayPoints.length - 1
                    ? red[500]
                    : returnRoute.current
                      ? returnRouteColor
                      : palette.secondary.main,
              wayPoint: true,
            },
          });
        }
      });
      updateRouteSource({ type: 'FeatureCollection', features });
      if (search?.computable) wayPointsRef.current = search?.wayPoints || null;

      return;
    }

    const { wayPoints } = route;

    if (wayPoints[0]?.strPoint === wayPoints[wayPoints.length - 1]?.strPoint || hasDirection) {
      map?.setLayoutProperty(arrowRouteLayerId, 'visibility', 'visible');
    } else {
      map?.setLayoutProperty(arrowRouteLayerId, 'visibility', 'none');
    }

    (allowWayPointsSnapping && search ? search.wayPoints : wayPoints).forEach(
      (wayPoint: Place | TWayPoint, index: number) => {
        if (wayPoint) {
          features.push({
            type: 'Feature',
            geometry: wayPoint.point,
            properties: {
              index,
              color:
                index === 0
                  ? green[500]
                  : index === wayPoints.length - 1
                    ? red[500]
                    : returnRoute.current
                      ? returnRouteColor
                      : palette.secondary.main,
              wayPoint: true,
            },
          });
        }
      },
    );

    features.reverse();

    if ('sections' in route) {
      route.sections.forEach(({ features: sectionFeatures }) => {
        sectionFeatures.forEach((feature) => {
          features.push({
            ...feature,
            properties: { ...feature.properties, color: '#fff', width: 7 },
          });

          features.push({
            ...feature,
            properties: {
              ...feature.properties,
              color: returnRoute.current ? returnRouteColor : palette.secondary.main,
              width: 5,
            },
          });
        });
      });
    }

    if ('preview' in route) {
      const { preview } = route;

      features.push({
        ...preview,
        properties: { ...preview.properties, color: '#fff', width: 7 },
      });

      features.push({
        ...preview,
        properties: {
          ...preview.properties,
          dragged: true,
          color: returnRoute.current ? returnRouteColor : palette.secondary.main,
          width: 5,
        },
      });
    }

    updateRouteSource({ type: 'FeatureCollection', features });

    wayPointsRef.current = wayPoints;
  }

  function updateTraces(traces: RideTrace[]) {
    updateTracesSource({
      type: 'FeatureCollection',
      features: traces.map(({ geometry }) => ({ type: 'Feature', geometry, properties: {} })),
    });
  }

  function updateSteps(steps: IRideStep[], editable = canWrite) {
    if (!map) return;

    stepMarkers.current.forEach((marker) => marker.remove());

    stepMarkers.current = steps
      .filter(({ location, poi }) => location || poi)
      .map((step) => {
        const { order, location, poi } = step;
        const [lng, lat] = location
          ? location.coordinates
          : poi
            ? poi.location.point.coordinates
            : [];

        const element = document.createElement('div');
        element.className = 'marker';
        element.style.backgroundColor = '#ff9800';
        element.style.border = '2px solid white';
        element.style.borderRadius = '50%';
        element.style.width = '24px';
        element.style.height = '24px';
        element.style.color = 'white';
        element.style.lineHeight = '24px';
        element.style.textAlign = 'center';
        if (editable && !poi) element.style.cursor = 'move';

        element.innerText = `${order}`;

        element.addEventListener('click', (event) => {
          event.preventDefault();
        });

        const marker = new Marker({ element, anchor: 'center', draggable: editable && !poi })
          .setPopup(
            new Popup({
              anchor: 'bottom',
              offset: [0, -10],
              maxWidth: '320px',
              closeButton: false,
            })
              .setHTML('<div id="ride-step-popup">yo</div>')
              .on('open', ({ target }: { target: Popup }) => {
                map.flyTo({ center: target.getLngLat() });

                const ele = document.getElementById('ride-step-popup');
                if (!ele) return;

                const container = createRoot(ele);
                container.render(
                  <RideStepPopup
                    canWrite={editable}
                    onClose={() => {
                      target.remove();
                      if (container) container.unmount();
                    }}
                    onUpdate={
                      onStepUpdate &&
                      ((step) => {
                        onStepUpdate(step as IRideStep);
                        target.remove();
                      })
                    }
                    step={step}
                  />,
                );
              }),
          )
          .setLngLat({ lat, lng })
          .addTo(map);

        marker.on('dragend', ({ target }: { target: Marker }) => {
          target.setDraggable(false);
          if (onStepDragged) onStepDragged(step, target.getLngLat());
        });

        return marker;
      });
  }

  function updateNewStep(location: IPoint | null) {
    if (!map || !newStepMarker.current) return;

    newStepMarker.current.remove();

    if (location) {
      map.flyTo({ center: location, zoom: 18 });

      newStepMarker.current.setLngLat(location).addTo(map);
      if (!newStepMarker.current.getPopup().isOpen()) newStepMarker.current.togglePopup();

      const ele = document.getElementById('new-ride-step-popup');
      if (!ele) return;

      const container = createRoot(ele);
      container.render(
        <NewRideStepPopup
          onAdd={() => {
            if (onStepAdd && newStepMarker.current) {
              onStepAdd(newStepMarker.current.getLngLat());
              newStepMarker.current.remove();
            }
          }}
          onClose={() => {
            newStepMarker.current?.remove();
            if (container) container.unmount();
          }}
        />,
      );
    }
  }

  return {
    init,
    enableEdition,
    enableEditionReturnRoute,
    updatePreview,
    updateRoute,
    updateTraces,
    updateSteps,
    updateNewStep,
  };
}

export default useRideAdmin;
