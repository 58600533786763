import { createTheme, lighten } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: ["'Nunito'", "'Roboto'", 'sans-serif'].join(', '),
  },
  palette: {
    primary: {
      main: '#2b3c5f',
    },
    secondary: {
      main: '#68a549',
      contrastText: '#fff',
    },
    orange: { main: '#EC7830', contrastText: '#FFF' },
    purple: { main: '#905EFA', contrastText: '#FFF' },
    red: { main: '#DD428D', contrastText: '#fff' },
    yellow: { main: '#FFD600', contrastText: '#FFF' },
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          backgroundColor: '#cfcfcf',
        },
        bar: {
          borderRadius: '8px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        textPrimary: {
          color: '#326ac2',
        },
        containedPrimary: {
          backgroundColor: '#326ac2',
          color: '#fff',
          ':hover': {
            backgroundColor: lighten('#326ac2', 0.1),
            color: '#fff',
          },
        },
        outlinedPrimary: {
          borderColor: '#326ac2',
          color: '#326ac2',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          color: '#326ac2',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          '&.Mui-checked': {
            color: '#326ac2',
          },
        },
      },
    },
  },
});

export default theme;
