import {
  BikeRoute,
  IBounds,
  TCyclingProfile,
  WidgetConfig,
  WidgetConfigService,
  cyclingProfiles as cyclingProfilesMap,
} from '@geovelo-frontends/commons';
import { ExpandLess, ExpandMore, Settings } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  MenuProps,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import Dialog from '../../../../components/dialog';

import BoundsMap, { defaultBounds } from './bounds-map';

interface IValues {
  bikeRouteIds: number[];
  cyclingProfiles: TCyclingProfile[];
  defaultCyclingProfile: TCyclingProfile;
  disableScrollZoom: boolean;
  disableSmallDeviceScrollZoom: boolean;
  enableBSS: boolean;
  enableFacilities: boolean;
  enablePublicTransports: boolean;
  enableRides: boolean;
  enableRouting: boolean;
  enableSimpleSearch: boolean;
  enableTrips: boolean;
  enableViewer: boolean;
  primaryColor: string;
  secondaryColor: string;
  showPartnerRidesOnly: boolean;
  showPartnerArea: boolean;
  title: string;
  useCustomBoundsForRides: boolean;
}

const allCyclingProfiles: TCyclingProfile[] = ['safe', 'daily', 'direct', 'touristic'];

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const menuProps: Partial<MenuProps> = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function WidgetFormDialog({
  open,
  config,
  bikeRoutes,
  onClose,
}: {
  bikeRoutes?: BikeRoute[];
  config: WidgetConfig | null;
  onClose: (newConfig?: WidgetConfig) => void;
  open: boolean;
}): JSX.Element {
  const [advancedSettingsOpen, openAdvancedSettings] = useState(false);
  const [bounds, setBounds] = useState<IBounds>();
  const [rideBounds, setRideBounds] = useState<IBounds>();
  const {
    user: { current: currentUser },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const {
    isSubmitting,
    values,
    touched,
    errors,
    setFieldValue,
    setValues,
    resetForm,
    handleChange,
    handleSubmit,
  } = useFormik<IValues>({
    initialValues: {
      title: '',
      enableSimpleSearch: false,
      enableRouting: true,
      cyclingProfiles: ['safe'],
      defaultCyclingProfile: 'safe',
      enablePublicTransports: false,
      enableBSS: false,
      enableRides: true,
      showPartnerRidesOnly: true,
      showPartnerArea: false,
      useCustomBoundsForRides: false,
      bikeRouteIds: [],
      enableTrips: false,
      enableFacilities: true,
      enableViewer: true,
      disableScrollZoom: false,
      disableSmallDeviceScrollZoom: true,
      primaryColor: '',
      secondaryColor: '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(),
      defaultCyclingProfile: Yup.string().required(),
      primaryColor: Yup.string().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i),
      secondaryColor: Yup.string().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i),
    }),
    onSubmit,
    enableReinitialize: true,
  });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (open) {
      resetForm();

      openAdvancedSettings(false);
      setBounds(config?.bounds || defaultBounds);
      setRideBounds(config?.rideBounds || config?.bounds || defaultBounds);

      if (config) {
        const {
          title,
          enableSimpleSearch,
          enableRouting,
          cyclingProfiles,
          defaultCyclingProfile,
          enablePublicTransports,
          enableBSS,
          enableRides,
          showPartnerRidesOnly,
          showPartnerArea,
          rideBounds,
          bikeRouteIds,
          enableTrips,
          enableFacilities,
          enableViewer,
          disableScrollZoom,
          disableSmallDeviceScrollZoom,
          primaryColor,
          secondaryColor,
        } = config;

        setValues({
          title,
          enableSimpleSearch,
          enableRouting,
          cyclingProfiles,
          defaultCyclingProfile,
          enablePublicTransports,
          enableBSS,
          enableRides,
          showPartnerRidesOnly,
          showPartnerArea,
          useCustomBoundsForRides: Boolean(rideBounds),
          bikeRouteIds: bikeRouteIds || [],
          enableTrips,
          enableFacilities,
          enableViewer,
          disableScrollZoom,
          disableSmallDeviceScrollZoom,
          primaryColor,
          secondaryColor,
        });
      } else {
        setValues({
          title: '',
          enableSimpleSearch: false,
          enableRouting: true,
          cyclingProfiles: allCyclingProfiles,
          defaultCyclingProfile: 'safe',
          enablePublicTransports: false,
          enableBSS: false,
          enableRides: true,
          showPartnerRidesOnly: true,
          showPartnerArea: false,
          useCustomBoundsForRides: false,
          bikeRouteIds: [],
          enableTrips: false,
          enableFacilities: true,
          enableViewer: false,
          disableScrollZoom: false,
          disableSmallDeviceScrollZoom: true,
          primaryColor: '#326ac2',
          secondaryColor: '#2ac682',
        });
      }
    } else {
      setBounds(undefined);
    }
  }, [open]);

  async function onSubmit(
    {
      title,
      defaultCyclingProfile,
      cyclingProfiles,
      enableSimpleSearch,
      enableRouting,
      enablePublicTransports,
      enableBSS,
      enableRides,
      showPartnerRidesOnly,
      showPartnerArea,
      bikeRouteIds,
      enableFacilities,
      enableViewer,
      enableTrips,
      disableScrollZoom,
      disableSmallDeviceScrollZoom,
      primaryColor,
      secondaryColor,
    }: IValues,
    { setSubmitting }: FormikHelpers<IValues>,
  ) {
    if (!currentPartner || !bounds) return;

    setSubmitting(true);

    const props = {
      partnerId: currentPartner.id,
      title,
      bounds,
      defaultCyclingProfile,
      cyclingProfiles,
      enableSimpleSearch,
      enableRouting,
      enablePublicTransports,
      enableBSS,
      enableRides,
      showPartnerRidesOnly,
      showPartnerArea,
      rideBounds: rideBounds || null,
      bikeRouteIds,
      enableFacilities,
      enableViewer,
      enableScrollZoom: !disableScrollZoom,
      enableSmallDeviceScrollZoom: !disableSmallDeviceScrollZoom,
      enableTrips,
      primaryColor: primaryColor || null,
      secondaryColor: secondaryColor || null,
    };

    try {
      if (config) {
        const newConfig = await WidgetConfigService.updateWidgetConfig(config.id, props);

        onClose(newConfig);
      } else {
        const newConfig = await WidgetConfigService.createWidgetConfig(props);

        onClose(newConfig);
      }
    } catch (err) {
      enqueueSnackbar(
        t('cycling-insights.widgets.form_dialog.server_error', {
          context: config ? '' : 'creation',
        }),
      );
    }

    setSubmitting(false);
  }

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      isForm
      keepMounted
      confirmTitle={<Trans i18nKey={config ? 'commons.actions.update' : 'commons.actions.add'} />}
      dialogTitle="widget-config-form-dialog"
      loading={isSubmitting}
      maxWidth="sm"
      onCancel={onClose}
      onConfirm={handleSubmit}
      open={open}
      scroll="paper"
      title={
        <Trans
          i18nKey="cycling-insights.widgets.form_dialog.title"
          values={{ context: config ? '' : 'new' }}
        />
      }
    >
      <StyledContent>
        <FormGroup>
          <TextField
            fullWidth
            required
            disabled={isSubmitting}
            error={touched.title && Boolean(errors.title)}
            label={<Trans i18nKey="commons.title" />}
            margin="none"
            name="title"
            onChange={handleChange}
            size="small"
            type="text"
            value={values.title}
            variant="outlined"
          />
        </FormGroup>
        <Box display="flex" flexDirection="column" gap="8px" marginTop="8px">
          <Typography color="textSecondary" variant="subtitle2">
            <Trans i18nKey="cycling-insights.widgets.form_dialog.bounds_label" />
          </Typography>
          <BoundsMap
            id="bounds-map"
            initialBounds={bounds}
            onBoundsUpdated={setBounds}
            visible={open}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap="16px" marginTop="8px">
          <Typography color="textSecondary" variant="subtitle2">
            <Trans i18nKey="cycling-insights.widgets.form_dialog.colors_label" />
          </Typography>
          <Box display="flex" gap="16px" padding="0 16px">
            <TextField
              fullWidth
              disabled={isSubmitting}
              error={Boolean(errors.primaryColor)}
              helperText="Ex: #000000"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      height={16}
                      style={{
                        backgroundColor: values.primaryColor || 'transparent',
                        borderRadius: 4,
                        marginLeft: 8,
                      }}
                      width={16}
                    />
                  </InputAdornment>
                ),
              }}
              inputProps={{ pattern: '^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$' }}
              label={<Trans i18nKey="cycling-insights.widgets.form_dialog.primary_color" />}
              margin="none"
              name="primaryColor"
              onChange={handleChange}
              size="small"
              type="text"
              value={values.primaryColor}
              variant="outlined"
            />
            <TextField
              fullWidth
              disabled={isSubmitting}
              error={Boolean(errors.secondaryColor)}
              helperText="Ex: #000000"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      height={16}
                      style={{
                        backgroundColor: values.secondaryColor || 'transparent',
                        borderRadius: 4,
                        marginLeft: 8,
                      }}
                      width={16}
                    />
                  </InputAdornment>
                ),
              }}
              inputProps={{ pattern: '^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$' }}
              label={<Trans i18nKey="cycling-insights.widgets.form_dialog.secondary_color" />}
              margin="none"
              name="secondaryColor"
              onChange={handleChange}
              size="small"
              type="text"
              value={values.secondaryColor}
              variant="outlined"
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap="8px" marginTop="8px">
          <Typography color="textSecondary" variant="subtitle2">
            <Trans i18nKey="cycling-insights.widgets.form_dialog.default_cycling_profile" />
          </Typography>
          <Box padding="0 16px">
            <FormControl
              required
              disabled={isSubmitting}
              margin="none"
              size="small"
              style={{ flexShrink: 0, width: 180 }}
              variant="outlined"
            >
              <Select
                input={<OutlinedInput />}
                MenuProps={menuProps}
                name="defaultCyclingProfile"
                onChange={handleChange}
                value={values.defaultCyclingProfile}
              >
                {values.cyclingProfiles.map((key) => (
                  <MenuItem dense key={key} value={key}>
                    <Trans i18nKey={cyclingProfilesMap[key]?.labelKey} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            endIcon={advancedSettingsOpen ? <ExpandLess /> : <ExpandMore />}
            onClick={() => openAdvancedSettings(!advancedSettingsOpen)}
            size="small"
            startIcon={<Settings />}
            variant="outlined"
          >
            <Trans i18nKey="cycling-insights.widgets.form_dialog.advanced_settings" />
          </Button>
        </Box>
        <Collapse in={advancedSettingsOpen}>
          <Box display="flex" flexDirection="column" gap="8px" marginTop="8px">
            <Typography color="textSecondary" variant="subtitle2">
              <Trans i18nKey="cycling-insights.widgets.form_dialog.routing_settings" />
            </Typography>
            <Box display="flex" flexDirection="column" padding="0 16px">
              {currentUser?.isGeovelo && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.enableRouting}
                      name="enableRouting"
                      onChange={handleChange}
                    />
                  }
                  disabled={isSubmitting}
                  label={<Trans i18nKey="cycling-insights.widgets.form_dialog.enable_routing" />}
                />
              )}
              <FormControl
                fullWidth
                disabled={!values.enableRouting || isSubmitting}
                margin="dense"
                size="small"
                variant="outlined"
              >
                <InputLabel id="cycling-profile-select-label">
                  <Trans i18nKey="cycling-insights.widgets.form_dialog.cycling_profiles" />
                </InputLabel>
                <Select
                  multiple
                  required
                  input={
                    <OutlinedInput
                      label={
                        <Trans i18nKey="cycling-insights.widgets.form_dialog.cycling_profiles" />
                      }
                    />
                  }
                  labelId="cycling-profile-select-label"
                  MenuProps={menuProps}
                  name="cyclingProfiles"
                  onChange={(event) => {
                    const value = event.target.value;
                    if (typeof value !== 'string') {
                      if (!value.includes(values.defaultCyclingProfile))
                        setFieldValue('defaultCyclingProfile', '');
                    }

                    handleChange(event);
                  }}
                  renderValue={(selected) => (
                    <Box display="flex" flexWrap="wrap" gap="4px">
                      {selected.map((key) => (
                        <Chip
                          key={key}
                          label={<Trans i18nKey={cyclingProfilesMap[key]?.labelKey} />}
                          size="small"
                        />
                      ))}
                    </Box>
                  )}
                  value={values.cyclingProfiles}
                >
                  {allCyclingProfiles.map((key) => (
                    <MenuItem dense key={key} value={key}>
                      <Checkbox
                        checked={values.cyclingProfiles.indexOf(key) > -1}
                        style={{ padding: 4, marginRight: 8 }}
                      />
                      <ListItemText
                        primary={<Trans i18nKey={cyclingProfilesMap[key]?.labelKey} />}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {currentUser?.isGeovelo && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.enablePublicTransports}
                      name="enablePublicTransports"
                      onChange={handleChange}
                    />
                  }
                  disabled={!values.enableRouting || isSubmitting}
                  label={
                    <Trans i18nKey="cycling-insights.widgets.form_dialog.enable_public_transports" />
                  }
                />
              )}
              <FormControlLabel
                control={
                  <Checkbox checked={values.enableBSS} name="enableBSS" onChange={handleChange} />
                }
                disabled={!values.enableRouting || isSubmitting}
                label={<Trans i18nKey="cycling-insights.widgets.form_dialog.enable_bss" />}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="8px" marginTop="8px">
            <Typography color="textSecondary" variant="subtitle2">
              <Trans i18nKey="cycling-insights.widgets.form_dialog.cartographic_settings" />
            </Typography>
            <Box display="flex" flexDirection="column" padding="0 16px">
              {currentUser?.isGeovelo && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.enableFacilities}
                      name="enableFacilities"
                      onChange={handleChange}
                    />
                  }
                  disabled={isSubmitting}
                  label={<Trans i18nKey="cycling-insights.widgets.form_dialog.enable_facilities" />}
                />
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.showPartnerArea}
                    name="showPartnerArea"
                    onChange={handleChange}
                  />
                }
                disabled={isSubmitting}
                label={<Trans i18nKey="cycling-insights.widgets.form_dialog.show_partner_area" />}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="8px" marginTop="8px">
            <Typography color="textSecondary" variant="subtitle2">
              <Trans i18nKey="cycling-insights.widgets.form_dialog.touristic_settings" />
            </Typography>
            <Box display="flex" flexDirection="column" padding="0 16px">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.enableSimpleSearch}
                    name="enableSimpleSearch"
                    onChange={handleChange}
                  />
                }
                disabled={isSubmitting}
                label={
                  <Trans i18nKey="cycling-insights.widgets.form_dialog.enable_simple_search" />
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.enableRides}
                    name="enableRides"
                    onChange={handleChange}
                  />
                }
                disabled={isSubmitting}
                label={<Trans i18nKey="cycling-insights.widgets.form_dialog.enable_rides" />}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.showPartnerRidesOnly}
                    name="showPartnerRidesOnly"
                    onChange={handleChange}
                  />
                }
                disabled={isSubmitting}
                label={
                  <Trans
                    i18nKey="cycling-insights.widgets.form_dialog.show_partner_rides_only"
                    values={{ partner: currentPartner?.title || '' }}
                  />
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.useCustomBoundsForRides}
                    name="useCustomBoundsForRides"
                    onChange={handleChange}
                  />
                }
                disabled={!values.enableRides || isSubmitting}
                label={
                  <Trans i18nKey="cycling-insights.widgets.form_dialog.use_custom_bounds_for_rides" />
                }
              />
              {values.enableRides && values.useCustomBoundsForRides && (
                <BoundsMap
                  baseBounds={bounds}
                  id="rides-bounds-map"
                  initialBounds={rideBounds}
                  onBoundsUpdated={setRideBounds}
                  visible={open}
                />
              )}
              {currentUser?.isGeovelo && (
                <>
                  <FormControl
                    disabled={isSubmitting || !bikeRoutes}
                    margin="dense"
                    size="small"
                    style={{ flexGrow: 1 }}
                    variant="outlined"
                  >
                    <InputLabel id="bike-routes-select-label">
                      <Trans i18nKey="cycling-insights.widgets.form_dialog.bike_routes" />
                    </InputLabel>
                    <Select
                      multiple
                      IconComponent={
                        bikeRoutes
                          ? undefined
                          : () => (
                              <Box height={24} marginRight={1} width={24}>
                                <CircularProgress color="inherit" size={24} thickness={4} />
                              </Box>
                            )
                      }
                      input={
                        <OutlinedInput
                          label={
                            <Trans i18nKey="cycling-insights.widgets.form_dialog.bike_routes" />
                          }
                        />
                      }
                      labelId="bike-routes-select-label"
                      MenuProps={menuProps}
                      name="bikeRouteIds"
                      onChange={handleChange}
                      renderValue={(selected) => (
                        <Box display="flex" flexWrap="wrap" gap="4px">
                          {selected.map((id) => {
                            const bikeRoute = bikeRoutes?.find((bikeRoute) => bikeRoute.id === id);
                            if (!bikeRoute) return <Fragment key={id} />;

                            return <Chip key={id} label={bikeRoute.title} size="small" />;
                          })}
                        </Box>
                      )}
                      value={values.bikeRouteIds}
                    >
                      {bikeRoutes?.map(({ id, title }) => (
                        <MenuItem dense key={id} value={id}>
                          <Checkbox
                            checked={values.bikeRouteIds.indexOf(id) > -1}
                            style={{ padding: 4, marginRight: 8 }}
                          />
                          <ListItemText primary={title} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.enableTrips}
                        name="enableTrips"
                        onChange={handleChange}
                      />
                    }
                    disabled={isSubmitting}
                    label={<Trans i18nKey="cycling-insights.widgets.form_dialog.enable_trips" />}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.enableViewer}
                        name="enableViewer"
                        onChange={handleChange}
                      />
                    }
                    disabled={isSubmitting}
                    label={<Trans i18nKey="cycling-insights.widgets.form_dialog.enable_viewer" />}
                  />
                </>
              )}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="8px" marginTop="8px">
            <Typography color="textSecondary" variant="subtitle2">
              <Trans i18nKey="cycling-insights.widgets.form_dialog.interactivity_settings" />
            </Typography>
            <Box display="flex" flexDirection="column" padding="0 16px">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.disableScrollZoom}
                    name="disableScrollZoom"
                    onChange={handleChange}
                  />
                }
                disabled={isSubmitting}
                label={<Trans i18nKey="cycling-insights.widgets.form_dialog.disable_scroll_zoom" />}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.disableSmallDeviceScrollZoom}
                    name="disableSmallDeviceScrollZoom"
                    onChange={handleChange}
                  />
                }
                disabled={isSubmitting}
                label={
                  <Trans i18nKey="cycling-insights.widgets.form_dialog.disable_small_device_scroll_zoom" />
                }
              />
            </Box>
          </Box>
        </Collapse>
      </StyledContent>
    </Dialog>
  );
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0;
`;

export default WidgetFormDialog;
