import { TDayPeriod, TTimePeriod } from '@geovelo-frontends/commons';
import { InfoOutlined } from '@mui/icons-material';
import { ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type TJourneysType = 'all' | 'work' | 'leisure';

export const journeysTypePeriodProps: {
  [key in TJourneysType]: Array<{ timePeriod: TTimePeriod | 'peak'; dayPeriod: TDayPeriod }>;
} = {
  all: [{ timePeriod: 'all_day', dayPeriod: 'all' }],
  work: [{ timePeriod: 'peak', dayPeriod: 'week' }],
  leisure: [
    { timePeriod: 'off_peak', dayPeriod: 'week' },
    { timePeriod: 'all_day', dayPeriod: 'weekend' },
  ],
};

function JourneysType({
  journeysType,
  setJourneysType,
}: {
  journeysType: TJourneysType;
  setJourneysType: (journeysType: TJourneysType) => void;
}) {
  const { t } = useTranslation();

  return (
    <ToggleButtonGroup
      exclusive
      color="primary"
      onChange={(_, value) => value && setJourneysType(value)}
      size="small"
      value={journeysType}
    >
      <ToggleButton value="all">
        <Typography fontSize="0.875rem" textTransform="none">
          {t('cycling-insights.usage.origin_destination.ranking.trips_types.all')}
        </Typography>
      </ToggleButton>
      <ToggleButton value="work">
        <Typography fontSize="0.875rem" marginRight={1} textTransform="none">
          {t('cycling-insights.usage.origin_destination.ranking.trips_types.work')}
        </Typography>
        <Tooltip
          title={t('cycling-insights.usage.origin_destination.ranking.trips_types.work_tooltip')}
        >
          <InfoOutlined fontSize="small" />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="leisure">
        <Typography fontSize="0.875rem" marginRight={1} textTransform="none">
          {t('cycling-insights.usage.origin_destination.ranking.trips_types.leisure')}
        </Typography>
        <Tooltip
          title={t('cycling-insights.usage.origin_destination.ranking.trips_types.leisure_tooltip')}
        >
          <InfoOutlined fontSize="small" />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default JourneysType;
