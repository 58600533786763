import { Ride } from '@geovelo-frontends/commons';
import { BugReport } from '@mui/icons-material';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { Trans } from 'react-i18next';

interface IProps {
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  onAddingToTests: (isReturnRoute: boolean) => void;
  ride: Ride;
}

function AddToTestsMenu({ ride, anchorEl, onClose, onAddingToTests }: IProps): JSX.Element {
  const returnRouteColor = '#b16689';

  function handleAddingToTests(isReturnRoute: boolean) {
    onAddingToTests(isReturnRoute);
    onClose();
  }

  return (
    <Menu
      keepMounted
      anchorEl={anchorEl}
      id="ride-add-to-tests-menu"
      MenuListProps={{ style: { width: 260 } }}
      onClose={onClose}
      open={Boolean(anchorEl)}
    >
      <MenuItem dense onClick={() => handleAddingToTests(false)}>
        <ListItemIcon>
          <BugReport color="secondary" />
        </ListItemIcon>
        <ListItemText primary={<Trans i18nKey="cycling-insights.ride.add_to_tests.add_route" />} />
      </MenuItem>
      {ride.returnRouteId && !ride.isLoop && (
        <MenuItem
          dense
          onClick={() => handleAddingToTests(true)}
          style={{ borderTop: '1px solid #ddd' }}
        >
          <ListItemIcon>
            <BugReport style={{ color: returnRouteColor }} />
          </ListItemIcon>
          <ListItemText
            primary={<Trans i18nKey="cycling-insights.ride.add_to_tests.add_return_route" />}
          />
        </MenuItem>
      )}
    </Menu>
  );
}

export default AddToTestsMenu;
