import { InfoOutlined } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import FacilitiesForm from '../../../../components/form/facilities';
import TabIntroduction from '../../../../components/tab-introduction';
import { TOutletContext } from '../../../../layouts/page/container';
import { TCartographicDataPageContext } from '../../context';

function Settings({
  displayResults,
  ...context
}: TCartographicDataPageContext &
  TOutletContext & { displayResults: (displayed: boolean) => void }): JSX.Element {
  const {
    header: { setActions, setPrevButtonClick, setTitle },
    discontinuity: { selectedFacilities, selectFacilities },
    loading,
  } = context;
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setPrevButtonClick(() => () => navigate('../discontinuity'));
    setTitle(t('cycling-insights.facilities.discontinuity.targeted_analysis.title'));
    setActions(undefined);
  }, []);

  function resetFilters() {
    selectFacilities(['cycleway', 'lane', 'greenway']);
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={6}>
        <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.discontinuity" />
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography fontWeight={600} variant="subtitle1">
            {t('commons.facilities_types')}
          </Typography>
          <Typography color="textSecondary" marginBottom={1} variant="caption">
            {t('cycling-insights.facilities.discontinuity.targeted_analysis.select_facilities')}
          </Typography>
          <FacilitiesForm
            selectedFacilities={selectedFacilities}
            selectFacilities={selectFacilities}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={3}>
        <Box
          alignItems="center"
          bgcolor="#FFF4D8"
          borderRadius={2}
          display="flex"
          gap="12px"
          padding={2}
        >
          <InfoOutlined fontSize="small" sx={{ color: '#3F2813' }} />
          <Typography color="#3F2813" fontSize="0.75rem" variant="body2">
            {t('cycling-insights.bicycle_observatory.long_analysis')}
          </Typography>
        </Box>
        <Box display="flex" gap={2} justifyContent="flex-end">
          <StyledButton disableElevation color="primary" onClick={resetFilters} variant="outlined">
            {t('commons.actions.reset')}
          </StyledButton>
          <StyledButton
            disableElevation
            color="primary"
            disabled={loading}
            onClick={() => displayResults(true)}
            variant="contained"
          >
            {t('commons.actions.analyze')}
          </StyledButton>
        </Box>
      </Box>
    </>
  );
}

const StyledButton = styled(Button)`
  border-radius: 8px;
  height: 54px;
  && {
    padding: 0 24px;
    font-weight: 700;
  }
`;

export default Settings;
