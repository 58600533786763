import { IRideMedia, Ride, RideService, mediaTypesMap } from '@geovelo-frontends/commons';
import { AddCircleOutline, Delete } from '@mui/icons-material';
import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Card from '../../../../components/card';
import ConfirmDialog from '../../../../components/confirm-dialog';

import MediaFormDialog from './media-form-dialog';

interface IProps {
  canWrite: boolean;
  onChange: (ride: Ride) => void;
  ride: Ride;
}

function MediaList({ canWrite, ride, onChange }: IProps): JSX.Element {
  const [mediaToUpdate, setMediaToUpdate] = useState<IRideMedia | null>(null);
  const [formDialogOpen, openFormDialog] = useState(false);
  const [mediaToRemove, setMediaToRemove] = useState<number | null>(null);
  const [removeConfirmOpen, openRemoveConfirm] = useState(false);
  const [removing, setRemoving] = useState(false);
  const { t } = useTranslation();
  const { transitions } = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  function handleFormDialogClose(media?: IRideMedia) {
    const updatedRide = ride.clone();
    if (media) {
      if (mediaToUpdate) {
        updatedRide.medias.splice(
          ride.medias.findIndex(({ id }) => id === mediaToUpdate.id),
          1,
          media,
        );

        onChange(updatedRide);
      } else {
        updatedRide.medias.push(media);
        onChange(updatedRide);
      }
    }

    openFormDialog(false);
    setTimeout(() => setMediaToUpdate(null), transitions.duration.leavingScreen);
  }

  async function handleRemove() {
    if (!ride.id || !mediaToRemove) return;

    setRemoving(true);

    try {
      await RideService.removeRideMedia(ride.id, mediaToRemove);

      const updatedRide = ride.clone();
      updatedRide.medias.splice(
        ride.medias.findIndex((media) => mediaToRemove === media.id),
        1,
      );

      openRemoveConfirm(false);
      setMediaToRemove(null);
      onChange(updatedRide);
      enqueueSnackbar(t('cycling-insights.ride.updated'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.not_updated'), { variant: 'error' });
    }

    setRemoving(false);
  }

  return (
    <>
      <Card
        actions={
          canWrite
            ? [
                {
                  children: <Trans i18nKey="cycling-insights.ride.media_list.actions.add" />,
                  color: 'primary',
                  key: 'add',
                  onClick: () => openFormDialog(true),
                  startIcon: <AddCircleOutline />,
                  variant: 'contained',
                },
              ]
            : []
        }
        title={<Trans i18nKey="cycling-insights.ride.media_list.title" />}
      >
        {ride.medias.length > 0 ? (
          <List dense>
            {ride.medias.map((media, index) => (
              <Fragment key={media.id}>
                {index > 0 && <Divider />}
                {canWrite ? (
                  <ListItemButton
                    onClick={() => {
                      setMediaToUpdate(media);
                      openFormDialog(true);
                    }}
                  >
                    <MediaListItemContent
                      canWrite={canWrite}
                      handleRemove={(id) => {
                        setMediaToRemove(id);
                        openRemoveConfirm(true);
                      }}
                      index={index}
                      media={media}
                    />
                  </ListItemButton>
                ) : (
                  <ListItem>
                    <MediaListItemContent
                      canWrite={canWrite}
                      handleRemove={handleRemove}
                      index={index}
                      media={media}
                    />
                  </ListItem>
                )}
              </Fragment>
            ))}
          </List>
        ) : (
          <Wrapper>
            <Typography color="textSecondary" variant="caption">
              <Trans i18nKey="cycling-insights.ride.media_list.empty" />
            </Typography>
          </Wrapper>
        )}
      </Card>
      <ConfirmDialog
        dialogTitle="ride-media-remove-confirm"
        loading={removing}
        onCancel={() => {
          openRemoveConfirm(false);
          setMediaToRemove(null);
        }}
        onConfirm={handleRemove}
        open={removeConfirmOpen}
        title={<Trans i18nKey="cycling-insights.ride.media_list.remove_dialog.title" />}
      />
      <MediaFormDialog
        media={mediaToUpdate}
        onClose={handleFormDialogClose}
        open={formDialogOpen}
        ride={ride}
      />
    </>
  );
}

function MediaListItemContent({
  canWrite,
  index,
  media: { id, title, copyright, type },
  handleRemove,
}: {
  canWrite?: boolean;
  handleRemove?: (id: number) => void;
  index: number;
  media: IRideMedia;
}): JSX.Element {
  const { palette } = useTheme();
  const mediaType = mediaTypesMap.find(({ key }) => type === key);

  return (
    <>
      <ListItemAvatar>
        <Avatar style={{ backgroundColor: palette.primary.main }}>
          {mediaType ? <mediaType.Icon /> : <></>}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          title || (
            <Trans
              i18nKey="cycling-insights.ride.media_list.item_title"
              values={{ index: index + 1 }}
            />
          )
        }
        secondary={copyright && <>&copy; {copyright}</>}
      />
      {canWrite && handleRemove && (
        <ListItemSecondaryAction>
          <Tooltip placement="left" title={<Trans i18nKey="commons.actions.remove" />}>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                if (id) handleRemove(id);
              }}
              size="small"
            >
              <Delete color="error" />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      )}
    </>
  );
}

const Wrapper = styled.div`
  padding: 16px;
`;

export default MediaList;
