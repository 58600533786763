import {
  TSectionFacility,
  facilitiesLabels,
  useAmplitudeTracker,
} from '@geovelo-frontends/commons';
import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  MenuProps,
  OutlinedInput,
  Select,
  Tooltip,
} from '@mui/material';
import { Fragment, useContext } from 'react';
import { Trans } from 'react-i18next';

import { AppContext } from '../../app/context';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const menuProps: Partial<MenuProps> = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const facilities: TSectionFacility[] = [
  'cycleway',
  'lane',
  'greenway',
  'opposite',
  'sharebusway',
  'other',
  'forbidden',
  'mixedfacilities',
  'none',
];

export function getFacilityLabelKey(key: TSectionFacility) {
  return key === 'other'
    ? 'commons.facilities.other'
    : key === 'none'
      ? 'commons.facilities.none'
      : key === 'forbidden'
        ? 'commons.facilities.forbidden'
        : facilitiesLabels[key];
}

export type TFacilitiesFormProps = {
  disableEdit?: boolean;
  disableNonCyclingLanes?: boolean;
  selectedFacilities: TSectionFacility[];
  selectFacilities: (facilities: TSectionFacility[]) => void;
};

function FacilitiesForm({
  disableEdit,
  disableNonCyclingLanes,
  selectedFacilities,
  selectFacilities,
}: TFacilitiesFormProps): JSX.Element {
  const {
    user: { current: currentUser },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { trackEvent } = useAmplitudeTracker();

  function handleFacilitiesSelect(facilities: TSectionFacility[]) {
    selectFacilities(facilities);
    trackEvent('Filter Selected', {
      pathname: `${window.location.host}${window.location.pathname}`,
      partner_id: currentPartner?.id,
      partner_code: currentPartner?.code,
      filter: 'Facilities',
    });
  }

  return (
    <Box flexShrink={0}>
      <FormControl fullWidth margin="dense" size="small" variant="outlined">
        <InputLabel id="facility-chip-label">
          <Box display="flex" gap={1}>
            <Trans i18nKey={'commons.facilities_label'} />
            <Tooltip
              title={
                <Trans
                  components={[
                    <br key={0} />,
                    <div
                      key={1}
                      style={{
                        marginLeft: '8px',
                        display: 'list-item',
                        listStyleType: 'disc',
                        listStylePosition: 'inside',
                      }}
                    />,
                  ]}
                  i18nKey="commons.facilities.tooltip"
                />
              }
            >
              <InfoOutlined />
            </Tooltip>
          </Box>
        </InputLabel>
        <Select
          multiple
          disabled={disableEdit}
          id="facility-chip"
          input={
            <OutlinedInput
              id="select-facility-chip"
              label={
                <Box display="flex" gap={1}>
                  <Trans i18nKey={'commons.facilities_label'} />
                  <Tooltip
                    title={
                      <Trans
                        components={[
                          <br key={0} />,
                          <div
                            key={1}
                            style={{
                              marginLeft: '8px',
                              display: 'list-item',
                              listStyleType: 'disc',
                              listStylePosition: 'inside',
                            }}
                          />,
                        ]}
                        i18nKey="commons.facilities.tooltip"
                      />
                    }
                  >
                    <InfoOutlined />
                  </Tooltip>
                </Box>
              }
            />
          }
          labelId="facility-chip-label"
          MenuProps={menuProps}
          renderValue={(facilityKeys) => (
            <Box display="flex" flexWrap="wrap" gap="4px" paddingTop={1}>
              {facilityKeys.map((key) => (
                <Chip key={key} label={<Trans i18nKey={getFacilityLabelKey(key)} />} size="small" />
              ))}
            </Box>
          )}
          value={selectedFacilities}
        >
          <MenuItem
            dense
            onClick={() =>
              handleFacilitiesSelect(
                selectedFacilities.length === facilities.length ? [] : [...facilities],
              )
            }
          >
            <Checkbox
              checked={selectedFacilities.length === facilities.length}
              indeterminate={
                selectedFacilities.length > 0 && selectedFacilities.length !== facilities.length
              }
              style={{ padding: 4, marginRight: 8 }}
            />
            <ListItemText primary={<Trans i18nKey="commons.all" />} />
          </MenuItem>
          {facilities
            .filter(
              (facility) =>
                !disableNonCyclingLanes || (facility !== 'forbidden' && facility !== 'none'),
            )
            .map((key) => {
              if (key === 'forbidden' && !currentUser?.isGeovelo) return <Fragment key={key} />;
              return (
                <MenuItem
                  dense
                  key={key}
                  onClick={() => {
                    if (!selectedFacilities.includes(key))
                      handleFacilitiesSelect([...selectedFacilities, key]);
                    else {
                      handleFacilitiesSelect(
                        [...selectedFacilities].filter((facility) => facility !== key),
                      );
                    }
                  }}
                  value={key}
                >
                  <Checkbox
                    checked={selectedFacilities.includes(key)}
                    style={{ padding: 4, marginRight: 8 }}
                  />
                  <ListItemText primary={<Trans i18nKey={getFacilityLabelKey(key)} />} />
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default FacilitiesForm;
