import { useUnits } from '@geovelo-frontends/commons';
import { ChevronRight, InfoOutlined } from '@mui/icons-material';
import {
  Box,
  ButtonBase,
  Divider,
  Skeleton,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Link, LinkProps } from 'react-router-dom';

function Card({
  children,
  to,
  ...props
}: {
  children?: ReactNode;
  currentData?: number | null | string;
  progression?: number;
  progressionUnit?: 'distance';
  titleKey?: string;
  titleInfo?: ReactNode;
} & Partial<LinkProps>): JSX.Element {
  if (to) {
    return (
      <ButtonBase
        component={Link}
        sx={{
          alignItems: 'stretch',
          border: '1px solid #d0d3da',
          borderRadius: 4,
          color: 'inherit',
          flexDirection: 'column',
          paddingX: 3,
          paddingY: 2,
          textDecoration: 'none',
          gap: 2,
        }}
        to={to}
      >
        <Box alignItems="center" display="flex" gap={2} paddingX={3} position="relative">
          <CardContent {...props} />
          <Box position="absolute" right={0} top={0}>
            <ChevronRight color="action" />
          </Box>
        </Box>
        {children && (
          <>
            <Divider />
            {children}
          </>
        )}
      </ButtonBase>
    );
  }

  return (
    <Box
      border="1px solid #d0d3da"
      borderRadius={4}
      display="flex"
      flexDirection="column"
      gap={2}
      paddingX={3}
      paddingY={2}
    >
      <CardContent {...props} />
      {children && (
        <>
          <Divider />
          {children}
        </>
      )}
    </Box>
  );
}

export function CardContent({
  titleKey,
  titleInfo,
  dataTypographyProps,
  currentData,
  progression,
  progressionUnit,
}: {
  children?: ReactNode;
  currentData?: number | string | null;
  dataTypographyProps?: TypographyProps;
  progression?: number;
  progressionUnit?: 'distance';
  titleKey?: string;
  titleInfo?: ReactNode;
}): JSX.Element {
  const { toDistance } = useUnits();

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {titleKey && (
        <Box alignItems="center" display="flex" gap={1} justifyContent="center" paddingBottom={2}>
          <Typography color="textSecondary" variant="body2">
            <Trans i18nKey={titleKey} />
          </Typography>
          {titleInfo && (
            <Tooltip title={titleInfo}>
              <InfoOutlined color="action" fontSize="small" />
            </Tooltip>
          )}
        </Box>
      )}
      {currentData !== null && (
        <Box
          alignItems="center"
          columnGap={2}
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
        >
          <Typography fontSize="2em" fontWeight={700} {...dataTypographyProps}>
            {currentData !== undefined ? currentData : <Skeleton variant="text" width={100} />}
          </Typography>
          {progression !== undefined && progression > 0 && (
            <Box
              borderRadius={1}
              paddingX={1}
              paddingY={0.5}
              sx={{ backgroundColor: '#2ac682', color: '#fff' }}
            >
              <Typography noWrap>
                {progression !== undefined ? (
                  `+${
                    progressionUnit === 'distance' ? toDistance(progression) : `${progression} %`
                  }`
                ) : (
                  <Skeleton variant="text" width={50} />
                )}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default Card;
