import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Paper, SvgIconProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

function LinkCard({
  to,
  Icon,
  icon,
  title,
  description,
}: {
  description: ReactNode;
  Icon?: (props: SvgIconProps) => JSX.Element;
  icon?: string;
  title: ReactNode;
  to: string;
}): JSX.Element {
  return (
    <Link style={{ textDecoration: 'none' }} to={to}>
      <Paper
        elevation={0}
        sx={{ alignItems: 'center', borderRadius: 4, display: 'flex', gap: 2, padding: 3 }}
      >
        {Icon ? (
          <Icon sx={{ height: 'auto', width: 80 }} />
        ) : (
          icon && <img src={icon} style={{ flexShrink: 0 }} width={80} />
        )}
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Typography fontSize="1.125rem" fontWeight={700}>
            {title}
          </Typography>
          <Typography variant="body2">{description}</Typography>
        </Box>
        <ArrowForwardIos sx={{ color: '#3e7bdf' }} />
      </Paper>
    </Link>
  );
}

export default LinkCard;
