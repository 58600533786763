import { Ride, RideService } from '@geovelo-frontends/commons';
import { Checkbox, DialogProps, FormControl, FormControlLabel, FormGroup } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import Dialog from '../../../../components/dialog';

interface IValues {
  selectedIds: { [key: number]: boolean };
}

type TProps = Omit<DialogProps, 'onClose'> & {
  onClose: (ride?: Ride) => void;
  ride: Ride;
};

function PartnerFormDialog({ ride, onClose, ...props }: TProps): JSX.Element {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isSubmitting, values, setValues, handleSubmit } = useFormik<IValues>({
    initialValues: {
      selectedIds: {},
    },
    onSubmit,
    enableReinitialize: true,
  });
  const {
    partner: { list: partners, all: allPartners },
  } = useContext(AppContext);

  useEffect(() => {
    if (props.open) {
      setValues({
        selectedIds:
          ride.partners?.reduce<{ [key: number]: boolean }>((res, { id }) => {
            if (id) res[id] = true;

            return res;
          }, {}) || [],
      });
    }
  }, [props.open]);

  async function onSubmit({ selectedIds }: IValues) {
    if (!ride.id || (!allPartners && !partners)) return;

    try {
      const updatedRide = await RideService.updateRideV1(ride.id, {
        partners: (allPartners || partners || [])
          .filter(({ id }) => selectedIds[id])
          .map(({ id }) => id),
      });

      onClose(updatedRide);
      enqueueSnackbar(t('cycling-insights.ride.updated'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.not_updated'), { variant: 'error' });
    }
  }

  return (
    <Dialog
      isForm
      confirmTitle={<Trans i18nKey="commons.actions.save" />}
      dialogTitle="ride-partner-form-dialog"
      loading={isSubmitting}
      maxWidth="sm"
      onCancel={() => onClose()}
      onConfirm={handleSubmit}
      scroll="paper"
      title={<Trans i18nKey="cycling-insights.ride.partner_form.title" />}
      {...props}
    >
      <FormControl component="fieldset">
        <FormGroup>
          {(allPartners || partners || [])
            .sort((a, b) => a.title.localeCompare(b.title))
            .map(({ id, code, title }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.selectedIds[id] || false}
                    disabled={code === 'geovelo'}
                    name={`selectedIds.${id}`}
                    onChange={(_, checked) =>
                      setValues({ selectedIds: { ...values.selectedIds, [id]: checked } })
                    }
                  />
                }
                key={id}
                label={title}
              />
            ))}
        </FormGroup>
      </FormControl>
    </Dialog>
  );
}

export default PartnerFormDialog;
