import { IPhoto, PhotosDialog, Report, ReportService } from '@geovelo-frontends/commons';
import { AccountCircle } from '@mui/icons-material';
import {
  Box,
  CardActionArea,
  CardMedia,
  Chip,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../app/context';
import RightPanelLayout from '../layouts/right-panel';

import Button from './button';
import ReportRedirectionMenu from './report-redirection-menu';

function ReportDetails({
  hasActions,
  isBindToOSM,
  reports,
  selectedId,
  setEditing,
  setReports,
  reloadTableReport,
}: {
  hasActions?: boolean;
  isBindToOSM?: boolean;
  reloadTableReport?: (report: Report) => void;
  reports?: Report[];
  selectedId: number | null;
  setEditing?: (isEditing: boolean) => void;
  setReports?: (reports?: Report[]) => void;
}): JSX.Element {
  const [report, setReport] = useState<Report | null>();
  const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showedPhotos, showPhotos] = useState<IPhoto[] | null>(null);
  const [photosDialogOpen, openPhotosDialog] = useState(false);
  const {
    user: { current: currentUser },
    report: { types },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { transitions } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, expand] = useState(true);
  const [newComment, setNewComment] = useState<string>('');
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [isResponding, setResponding] = useState<boolean>(false);

  useEffect(() => {
    setReport(reports?.find(({ id }) => id === selectedId) || null);
  }, [reports, selectedId]);

  function handlePhotosDialogOpen() {
    const report = reports?.find(({ id }) => id === selectedId);
    if (!report?.photoUrl) return;

    openPhotosDialog(true);
    showPhotos([{ url: report.photoUrl }]);
  }

  function handlePhotosDialogClose() {
    openPhotosDialog(false);
    setTimeout(() => showPhotos(null), transitions.duration.leavingScreen);
  }

  function handleNewReviewSent(updatedReport?: Report) {
    if (reports && updatedReport) {
      const newReports = [...reports];
      newReports.splice(
        newReports.findIndex(({ id }) => id === selectedId),
        1,
        updatedReport,
      );

      setReport(updatedReport);
      setReports?.(newReports);
    }
    if (updatedReport) reloadTableReport?.(updatedReport);
    setResponding(false);
  }

  async function sendReview(close?: boolean, isValid?: boolean) {
    if (!report) return;
    setSubmitting(true);

    try {
      let updatedReport = report.clone();

      const newReview = await ReportService.addReview({
        reportId: report.id,
        comment: newComment,
        rating: undefined,
      });

      if (close)
        updatedReport = await ReportService.updateReport(report.id, {
          status: 'CLOSED',
          isValid,
        });
      else updatedReport.reviews.push(newReview);

      handleNewReviewSent(updatedReport);
    } catch {
      enqueueSnackbar?.(t('commons.report.reviews.not_added'));
    }

    setSubmitting(false);
  }

  if (!report) return <></>;

  const {
    id,
    isClosed,
    typeCode,
    source,
    creator,
    created,
    updated,
    description,
    photoUrl,
    reviews,
  } = report;

  return (
    <>
      <RightPanelLayout
        actions={
          expanded ? (
            <Box display="flex" flexGrow={1} justifyContent="space-between">
              {hasActions ? (
                <>
                  {isResponding ? (
                    <>
                      <Box
                        alignItems="start"
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        maxWidth={132}
                      >
                        <Button
                          onClick={() => {
                            setResponding(false);
                            setNewComment('');
                          }}
                          size="large"
                          variant="outlined"
                        >
                          <Trans i18nKey="commons.actions.cancel" />
                        </Button>
                        {(!isBindToOSM || currentUser?.isGeovelo) && !report.isClosed && (
                          <Button onClick={() => sendReview(true)} size="large" variant="outlined">
                            <Trans i18nKey="commons.report.review_dialog.actions.send_and_close" />
                          </Button>
                        )}
                      </Box>
                      <Box
                        alignItems="end"
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        maxWidth={132}
                      >
                        <Button
                          disableElevation
                          onClick={() => sendReview()}
                          size="large"
                          variant="contained"
                        >
                          <Trans i18nKey="commons.actions.send" />
                        </Button>
                        {currentUser?.isGeovelo && !report.isClosed && (
                          <Button
                            onClick={() => sendReview(true, true)}
                            size="large"
                            variant="outlined"
                          >
                            <Trans i18nKey="commons.report.review_dialog.actions.send_reward_and_close" />
                          </Button>
                        )}
                      </Box>
                    </>
                  ) : (
                    <>
                      {expanded ? (
                        <>
                          {setEditing && (
                            <Button
                              onClick={() => setEditing(true)}
                              size="large"
                              variant="outlined"
                            >
                              <Trans i18nKey="commons.actions.update" />
                            </Button>
                          )}
                          <Button
                            onClick={(event) => setSettingsMenuAnchorEl(event.currentTarget)}
                            size="large"
                            variant="outlined"
                          >
                            <Trans i18nKey="commons.actions.see_on" />
                          </Button>
                        </>
                      ) : (
                        <Button onClick={() => expand(true)} size="large" variant="text">
                          <Trans
                            count={reviews.length}
                            i18nKey="cycling-insights.reports.osm_cartographic_reports.table.reviews"
                            values={{ count: reviews.length }}
                          />
                        </Button>
                      )}
                      <Button
                        disableElevation
                        onClick={() => setResponding(true)}
                        size="large"
                        variant="contained"
                      >
                        <Trans i18nKey="commons.actions.respond" />
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {!expanded && (
                    <Button
                      disabled={reviews.length === 0}
                      onClick={() => expand(true)}
                      size="large"
                      variant="text"
                    >
                      <Trans
                        count={reviews.length}
                        i18nKey="cycling-insights.reports.osm_cartographic_reports.table.reviews"
                        values={{ count: reviews.length }}
                      />
                    </Button>
                  )}
                </>
              )}
            </Box>
          ) : undefined
        }
        content={
          expanded ? (
            <Box
              borderBottom={hasActions ? '1px solid #E3E7EE' : 'none'}
              display="flex"
              flexDirection="column"
              gap={2}
              paddingBottom={hasActions ? 3 : 0}
              paddingTop={2}
            >
              <Box display="flex" flexDirection="column" marginBottom={expanded ? 2 : 0}>
                <Typography color="textSecondary" variant="caption">
                  <Trans
                    i18nKey="cycling-insights.reports.cartographic_reports.detail.updated_on"
                    values={{ date: updated.format('LL') }}
                  />
                </Typography>
              </Box>
              <Box display="flex" gap={2}>
                <AccountCircle color="primary" fontSize="large" />
                <Box display="flex" flexDirection="column">
                  <Typography variant="caption">{creator || 'unknown'}</Typography>
                  <Typography variant="caption">
                    {created.format('LLL')}
                    {source && (
                      <>
                        {' '}
                        (<Trans i18nKey={source.title} />)
                      </>
                    )}
                  </Typography>
                </Box>
              </Box>
              {description && (
                <Typography maxWidth="340px" variant="body1">
                  {description}
                </Typography>
              )}
              {photoUrl && (
                <Box flexShrink={0} padding={1} width={150}>
                  <CardActionArea onClick={handlePhotosDialogOpen}>
                    <CardMedia component="img" image={photoUrl} />
                  </CardActionArea>
                </Box>
              )}
            </Box>
          ) : (
            <></>
          )
        }
        expand={expand}
        expanded={expanded}
        footer={
          <>
            {isResponding && (
              <TextField
                fullWidth
                multiline
                disabled={isSubmitting}
                id="description"
                inputProps={{ min: 10 }}
                label={
                  <Trans
                    i18nKey={`commons.${
                      report instanceof Report ? 'report.reviews' : 'route_report.response_dialog'
                    }.tooltip`}
                  />
                }
                margin="dense"
                name="description"
                onChange={({ target: { value } }) => setNewComment(value)}
                rows={3}
                value={newComment}
                variant="outlined"
              />
            )}
          </>
        }
        header={
          <Box display="flex" flexDirection="column">
            <Typography fontSize="1.125rem" fontWeight={700} variant="h6">
              <Trans
                i18nKey={
                  types?.find((value) => value.code === typeCode)?.titleKey ||
                  'commons.report.default_title'
                }
              />
            </Typography>
            <Box alignItems="center" display="flex" gap={2}>
              <Typography fontSize="1.125rem" fontWeight={700} variant="h6">
                #{id}
              </Typography>
              <Chip
                label={
                  <Trans
                    i18nKey={
                      isBindToOSM
                        ? `commons.statuses.${isClosed ? 'processed' : 'unprocessed'}`
                        : `commons.statuses.${isClosed ? 'closed' : 'open'}`
                    }
                  />
                }
                size="small"
                sx={
                  isClosed
                    ? { backgroundColor: '#EEF8F4', color: '#038B63' }
                    : { backgroundColor: '#FFEBEE', color: '#A42C49' }
                }
              />
            </Box>
          </Box>
        }
      >
        <Box display="flex" flexDirection="column">
          {reviews
            .filter(({ comment }) => !!comment)
            .map((review) => (
              <Box
                borderBottom="1px solid #E3E7EE"
                display="flex"
                flexDirection="column"
                gap={2}
                key={review.id}
                paddingBottom={3}
                paddingTop={2}
              >
                <Typography color="textSecondary" variant="caption">
                  <Trans
                    i18nKey="cycling-insights.reports.cartographic_reports.detail.updated_on"
                    values={{ date: updated.format('LL') }}
                  />
                </Typography>
                <Box display="flex" gap={2}>
                  <AccountCircle color="primary" fontSize="large" />
                  <Box display="flex" flexDirection="column">
                    <Typography variant="caption">
                      {review.creator?.username || 'unknown'}
                    </Typography>
                    <Typography variant="caption">{review.created.format('LLL')}</Typography>
                  </Box>
                </Box>
                <Typography maxWidth="340px" variant="body1">
                  {review.comment}
                </Typography>
              </Box>
            ))}
        </Box>
      </RightPanelLayout>
      <ReportRedirectionMenu
        anchorEl={settingsMenuAnchorEl}
        selectedReport={report}
        setAnchorEl={setSettingsMenuAnchorEl}
      />
      <PhotosDialog
        onClose={handlePhotosDialogClose}
        open={photosDialogOpen}
        photos={showedPhotos}
      />
    </>
  );
}

export default ReportDetails;
