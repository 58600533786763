import {
  CyclabilityZone,
  Period,
  SectionStats,
  StatsService,
  TSectionFacility,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../app/context';
import { IPeriodFormProps } from '../components/form/period';
import { ISliderBounds, TSliderRange } from '../components/form/slider';
import {
  TAverageSpeedsTabContext,
  TRoadsUseTabContext,
  TTrafficExtrapolationTabContext,
} from '../pages/bicycle-observatory/context';
import { TAccidentologyTabOldContext } from '../pages/cartographic-data/context';
import { getFilteredSections as _getFilteredSections, splitRoadsMinZoom } from '../utils/sections';

import usePeriod from './period';

type TTabContext =
  | TAverageSpeedsTabContext
  | TRoadsUseTabContext
  | TTrafficExtrapolationTabContext
  | TAccidentologyTabOldContext;

export type TCriterion =
  | 'frequency'
  | 'averageSpeed'
  | 'roughness'
  | 'extrapolation'
  | 'cyclability'
  | 'discontinuity';

function useSectionsStats({
  automaticReloadDisabled,
  hasChartComparison,
  isExtrapolated,
  primaryCriterion,
  initialized: layersInitialized,
  period,
  selectedFacilities,
  departureZone,
  arrivalZone,
  data,
  bounds,
  currentRange,
  setData,
  setBounds,
  setCurrentRange,
  setQuartiles,
  setLoading,
  setGlobalAverageSpeed,
  setPrevGlobalAverageSpeed,
  setSectionsCountBySpeed,
  setSectionsComparedToAverage,
}: {
  automaticReloadDisabled?: boolean;
  initialized: boolean;
  isExtrapolated?: boolean;
  hasChartComparison?: boolean;
  period: IPeriodFormProps;
  primaryCriterion: TCriterion;
  setLoading: (loading: boolean) => void;
  selectedFacilities?: TSectionFacility[];
  arrivalZone?: CyclabilityZone | undefined;
  departureZone?: CyclabilityZone | undefined;
  currentRange?: TSliderRange;
  setCurrentRange?: (range?: TSliderRange) => void;
  setGlobalAverageSpeed?: (speed?: number) => void;
  setPrevGlobalAverageSpeed?: (speed?: number) => void;
  setSectionsCountBySpeed?: (value?: number[]) => void;
  setSectionsComparedToAverage?: (value?: number[]) => void;
} & Omit<TTabContext, 'selectedFacilities' | 'currentRange' | 'setCurrentRange'>) {
  const [initialized, setInitialized] = useState(false);
  const [stats, setStats] = useState<{
    [key: number]: {
      current?: SectionStats;
      prev?: SectionStats;
      lastMonthStats?: SectionStats;
      lastYearStats?: SectionStats;
    };
  }>();
  const [secondaryBounds, setSecondaryBounds] = useState<ISliderBounds>();
  const {
    map: { zoom },
    partner: { current: currentPartner, sections },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { getTitle: getPeriodTitle } = usePeriod();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const previousZoom = useRef<number>();

  const lastYear = useRef<Period>(period.values.current.clone());
  const lastMonth = useRef<Period>(period.values.current.clone());

  useEffect(() => {
    if (!sections && !automaticReloadDisabled) setLoading(true);

    setInitialized(true);

    return () => {
      cancelPromises();
      setData(undefined);
      setBounds(undefined);
      setSecondaryBounds(undefined);
      setCurrentRange?.(undefined);
      setQuartiles?.(undefined);
      setLoading(false);
      setGlobalAverageSpeed?.(undefined);
      setPrevGlobalAverageSpeed?.(undefined);
      setSectionsCountBySpeed?.(undefined);
      setSectionsComparedToAverage?.(undefined);

      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (!initialized) return;

    if (!automaticReloadDisabled) {
      lastYear.current = period.values.current.clone();
      lastYear.current.from.add(-1, 'year');
      lastYear.current.to.add(-1, 'year');
      lastMonth.current = period.values.current.clone();
      lastMonth.current.from.add(-1, 'month');
      lastMonth.current.to.add(-1, 'month').endOf('month');
      getStats();
    } else {
      cancelPromises();
      setData(undefined);
      setBounds(undefined);
      setSecondaryBounds(undefined);
      setCurrentRange?.(undefined);
      setStats(undefined);
      setGlobalAverageSpeed?.(undefined);
      setPrevGlobalAverageSpeed?.(undefined);
      setSectionsCountBySpeed?.(undefined);
      setSectionsComparedToAverage?.(undefined);
    }
  }, [
    initialized,
    period.values,
    period.comparisonEnabled,
    departureZone,
    arrivalZone,
    automaticReloadDisabled,
  ]);

  useEffect(() => {
    if (period.comparisonEnabled || !data || data.features.length === 0 || !currentRange)
      setQuartiles?.(undefined);
  }, [data, currentRange]);

  useEffect(() => {
    if (zoom === undefined) return;
    if (
      (zoom >= splitRoadsMinZoom &&
        previousZoom.current &&
        previousZoom.current < splitRoadsMinZoom) ||
      (zoom < splitRoadsMinZoom &&
        previousZoom.current &&
        previousZoom.current >= splitRoadsMinZoom)
    )
      getFilteredSections();
    previousZoom.current = zoom;
  }, [zoom]);

  useEffect(() => {
    if (sections && stats) setLoading(false);
  }, [sections, stats]);

  useEffect(() => {
    if (layersInitialized && sections && stats) getFilteredSections();
  }, [layersInitialized, sections, stats, selectedFacilities]);

  async function getStats() {
    cancelPromises();
    setData(undefined);
    setBounds(undefined);
    setSecondaryBounds(undefined);
    setCurrentRange?.(undefined);
    setStats(undefined);
    setGlobalAverageSpeed?.(undefined);
    setPrevGlobalAverageSpeed?.(undefined);
    setSectionsCountBySpeed?.(undefined);
    setSectionsComparedToAverage?.(undefined);

    if (!currentPartner) return;

    const {
      values: { current: currentPeriod, prev: prevPeriod, timePeriod, dayPeriod },
      comparisonEnabled,
    } = period;

    if (!timePeriod || !dayPeriod) return;

    setLoading(true);

    const props = { timePeriod, dayPeriod, departureZone, arrivalZone };

    try {
      if (comparisonEnabled) {
        const [currentStats, prevStats] = await cancellablePromise(
          Promise.all(
            isExtrapolated
              ? [
                  StatsService.getTrafficExtrapolation(
                    currentPartner.id,
                    currentPeriod.toIPeriod(),
                    props.dayPeriod,
                    props.timePeriod,
                  ),
                  StatsService.getTrafficExtrapolation(
                    currentPartner.id,
                    prevPeriod.toIPeriod(),
                    props.dayPeriod,
                    props.timePeriod,
                  ),
                ]
              : [
                  StatsService.getSectionsSpeedsAndFrequencies(
                    currentPartner.id,
                    currentPeriod.toIPeriod(),
                    props,
                  ),
                  StatsService.getSectionsSpeedsAndFrequencies(
                    currentPartner.id,
                    prevPeriod.toIPeriod(),
                    props,
                  ),
                ],
          ),
        );

        const _stats: { [key: number]: { current?: SectionStats; prev?: SectionStats } } = {};
        currentStats?.forEach((element) => {
          _stats[element.sectionId] = { current: element };
        });
        prevStats.forEach((element) => {
          const { sectionId } = element;
          if (_stats[sectionId]) _stats[sectionId].prev = element;
          else _stats[sectionId] = { prev: element };
        });

        setStats(_stats);
      } else {
        const [currentStats, lastMonthStats, lastYearStats] = await cancellablePromise(
          Promise.all(
            isExtrapolated
              ? hasChartComparison
                ? [
                    StatsService.getTrafficExtrapolation(
                      currentPartner.id,
                      currentPeriod.toIPeriod(),
                      props.dayPeriod,
                      props.timePeriod,
                    ),
                    StatsService.getTrafficExtrapolation(
                      currentPartner.id,
                      lastMonth.current.toIPeriod(),
                      props.dayPeriod,
                      props.timePeriod,
                    ),
                    StatsService.getTrafficExtrapolation(
                      currentPartner.id,
                      lastYear.current.toIPeriod(),
                      props.dayPeriod,
                      props.timePeriod,
                    ),
                  ]
                : [
                    StatsService.getTrafficExtrapolation(
                      currentPartner.id,
                      currentPeriod.toIPeriod(),
                      props.dayPeriod,
                      props.timePeriod,
                    ),
                  ]
              : hasChartComparison
                ? [
                    StatsService.getSectionsSpeedsAndFrequencies(
                      currentPartner.id,
                      currentPeriod.toIPeriod(),
                      props,
                    ),
                    StatsService.getSectionsSpeedsAndFrequencies(
                      currentPartner.id,
                      lastMonth.current.toIPeriod(),
                      props,
                    ),
                    StatsService.getSectionsSpeedsAndFrequencies(
                      currentPartner.id,
                      lastYear.current.toIPeriod(),
                      props,
                    ),
                  ]
                : [
                    StatsService.getSectionsSpeedsAndFrequencies(
                      currentPartner.id,
                      currentPeriod.toIPeriod(),
                      props,
                    ),
                  ],
          ),
        );

        const _stats: {
          [key: number]: {
            current?: SectionStats;
            prev?: SectionStats;
            lastMonthStats?: SectionStats;
            lastYearStats?: SectionStats;
          };
        } = {};
        currentStats?.forEach((element) => {
          _stats[element.sectionId] = { current: element };
        });
        lastMonthStats?.forEach((element) => {
          const { sectionId } = element;
          if (_stats[sectionId]) _stats[sectionId].lastMonthStats = element;
          else _stats[sectionId] = { lastMonthStats: element };
        });
        lastYearStats?.forEach((element) => {
          const { sectionId } = element;
          if (_stats[sectionId]) _stats[sectionId].lastYearStats = element;
          else _stats[sectionId] = { lastYearStats: element };
        });

        setStats(_stats);
      }
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(
          t('commons.no_data', {
            date: getPeriodTitle(period.values.current),
          }),
          { variant: 'error' },
        );

        setBounds({ min: 0, max: 1 });
        setSecondaryBounds(undefined);
        setData(null);
        setGlobalAverageSpeed?.(undefined);
        setPrevGlobalAverageSpeed?.(undefined);
        setSectionsCountBySpeed?.(undefined);
        setSectionsComparedToAverage?.(undefined);
      }
    }
  }

  function getFilteredSections() {
    if (!sections || !stats || zoom === undefined) return;

    const {
      filteredSections,
      bounds: _bounds,
      secondaryBounds,
      currentRange: _currentRange,
      averageSpeed,
      prevAverageSpeed,
      sectionsCountBySpeed,
      sectionsComparedToAverage,
    } = _getFilteredSections({
      sections,
      period,
      selectedFacilities,
      zoom,
      stats,
      primaryCriterion,
      bounds,
      currentRange,
    });

    setData(filteredSections);
    setBounds(_bounds);
    setSecondaryBounds(secondaryBounds);
    setCurrentRange?.(_currentRange);
    setGlobalAverageSpeed?.(averageSpeed);
    setPrevGlobalAverageSpeed?.(prevAverageSpeed);
    setSectionsCountBySpeed?.(sectionsCountBySpeed);
    setSectionsComparedToAverage?.(sectionsComparedToAverage);
  }

  return { timeoutRef, stats, secondaryBounds };
}

export default useSectionsStats;
