import { User, UserService, useCancellablePromise } from '@geovelo-frontends/commons';
import { Autocomplete, Box, TextField } from '@mui/material';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TQAPageContext } from '../../context';

import UsersLogsTable from './table';

function UsersLogsForm(context: TQAPageContext): JSX.Element {
  const {
    usersLogs: { selectedUser, selectUser, setLogs },
  } = context;
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState<User[]>();
  const locked = useRef(false);
  const fetch = useMemo(
    () =>
      debounce(async (search: string, callback: (cyclabilityZones: User[]) => void) => {
        if (locked.current) {
          callback([]);
          locked.current = false;
          return;
        }

        const users = await UserService.searchUsers({
          page: 1,
          pageSize: 5,
          search,
          query: '{id, email}',
        });

        callback(users);
      }, 700),
    [],
  );
  const { t } = useTranslation();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    return () => cancelPromises();
  }, []);

  useEffect(() => {
    let active = true;

    setSearchResults(undefined);
    if (!search) return;

    fetch(search, (users: User[]) => {
      if (active) setSearchResults(users);
    });

    return () => {
      active = false;
    };
  }, [search, fetch]);

  useEffect(() => {
    getLogs();
  }, [selectedUser]);

  async function getLogs() {
    cancelPromises();
    setLogs(null);

    if (!selectedUser) return;

    try {
      const logs = await cancellablePromise(UserService.getLogs(selectedUser.id));

      setLogs(logs);
    } catch {
      //
    }
  }

  return (
    <>
      <Box display="flex" flexDirection="column" minHeight="100%">
        <Box display="flex" flexDirection="column" flexShrink={0} gap={2} padding={2}>
          <Box>
            <Autocomplete
              disablePortal
              includeInputInList
              forcePopupIcon={Boolean(search && !selectedUser)}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.email}
              loading={Boolean(search) && !selectedUser}
              loadingText={t('commons.loading')}
              noOptionsText={search && t('commons.no_result_found')}
              onChange={(_, value) => {
                locked.current = true;
                selectUser(value);
              }}
              onInputChange={(_, value) => {
                setTimeout(() => {
                  setSearch(value);
                }, 10);
              }}
              options={searchResults || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label={"Rechercher l'email d'un utilisateur"}
                  margin="dense"
                  size="small"
                  variant="outlined"
                />
              )}
              value={selectedUser}
            />
          </Box>
          {selectedUser && <UsersLogsTable selectedUser={selectedUser} {...context} />}
        </Box>
      </Box>
    </>
  );
}

export default UsersLogsForm;
