import { Box, Skeleton, Typography } from '@mui/material';
import { Chart } from 'chart.js';
import { useEffect, useRef } from 'react';
import { Trans } from 'react-i18next';

import { IBicycleObservatoryPageContext } from '../../context';

const roadsTypesChartId = 'speeds-comparison-chart';

function SpeedsComparisonChart({
  averageSpeeds: { sectionsCountBySpeed },
}: IBicycleObservatoryPageContext & {
  lastMonthDistance?: number;
  lastYearDistance?: number;
}): JSX.Element {
  const roadsTypesChartRef = useRef<Chart<'doughnut'>>();

  useEffect(() => {
    const roadsTypesChartCtx = document.getElementById(roadsTypesChartId);
    if (roadsTypesChartCtx && roadsTypesChartCtx instanceof HTMLCanvasElement) {
      roadsTypesChartRef.current?.destroy();

      roadsTypesChartRef.current = new Chart(roadsTypesChartCtx, {
        type: 'doughnut',
        data: {
          datasets: sectionsCountBySpeed
            ? [
                {
                  data: sectionsCountBySpeed,
                  backgroundColor: ['#F56B84', '#FFD978', '#46CE9D'],
                },
              ]
            : [
                {
                  data: [70, 30],
                  backgroundColor: ['#E3E7EE', '#F6F7FB'],
                },
              ],
        },
        options: {
          cutout: '80%',
          events: [],
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
          },
        },
      });
    }
  }, [sectionsCountBySpeed]);

  return (
    <Box display="flex" flexDirection="column">
      <Typography fontSize="1.125rem" fontWeight={700} marginBottom={3}>
        <Trans i18nKey="cycling-insights.usage.fluidity.chart.comparison_title" />
      </Typography>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box
          alignSelf="center"
          height={200}
          maxWidth="100%"
          overflow="hidden"
          position="relative"
          sx={{ overflow: 'hidden' }}
          width={200}
        >
          <canvas id={roadsTypesChartId} style={{ position: 'relative', zIndex: 2 }} />
        </Box>
        <Box display="flex" flexDirection="column" gap={2} justifyContent="center">
          {sectionsCountBySpeed ? (
            <>
              <Box display="flex" flexDirection="column">
                <Box
                  bgcolor="#46CE9D"
                  borderRadius="5px"
                  height="10px"
                  marginBottom="2px"
                  minWidth="10px"
                  width="10px"
                />
                <Typography variant="caption">
                  <Trans i18nKey="cycling-insights.usage.fluidity.chart.speed_increase" />
                </Typography>
                <Typography variant="caption">
                  {Math.round(
                    (sectionsCountBySpeed[2] * 1000) /
                      (sectionsCountBySpeed[0] + sectionsCountBySpeed[1] + sectionsCountBySpeed[2]),
                  ) / 10}
                  %
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box
                  bgcolor="#FFD978"
                  borderRadius="5px"
                  height="10px"
                  marginBottom="2px"
                  minWidth="10px"
                  width="10px"
                />
                <Typography variant="caption">
                  <Trans i18nKey="cycling-insights.usage.fluidity.chart.speed_stability" />
                </Typography>
                <Typography variant="caption">
                  {Math.round(
                    (sectionsCountBySpeed[1] * 1000) /
                      (sectionsCountBySpeed[0] + sectionsCountBySpeed[1] + sectionsCountBySpeed[2]),
                  ) / 10}
                  %
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box
                  bgcolor="#F56B84"
                  borderRadius="5px"
                  height="10px"
                  marginBottom="2px"
                  minWidth="10px"
                  width="10px"
                />
                <Typography variant="caption">
                  <Trans i18nKey="cycling-insights.usage.fluidity.chart.speed_decrease" />
                </Typography>
                <Typography variant="caption">
                  {Math.round(
                    (sectionsCountBySpeed[0] * 1000) /
                      (sectionsCountBySpeed[0] + sectionsCountBySpeed[1] + sectionsCountBySpeed[2]),
                  ) / 10}
                  %
                </Typography>
              </Box>
            </>
          ) : (
            [0, 1, 2].map((key) => <Skeleton key={key} variant="text" width={128} />)
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default SpeedsComparisonChart;
