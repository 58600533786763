import { FileInput, IPhoto, Ride, RideService, TFile } from '@geovelo-frontends/commons';
import { InfoOutlined } from '@mui/icons-material';
import { Box, DialogProps, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import Dialog from '../../../../components/dialog';

interface IValues {
  title: string;
  description: string;
  copyright: string;
}

type TProps = Omit<DialogProps, 'onClose'> & {
  photo: IPhoto | null;
  onClose: (photo?: IPhoto) => void;
  ride: Ride;
};

function ImageFormDialog({ ride, photo, onClose, ...props }: TProps): JSX.Element {
  const [file, setFile] = useState<TFile>();
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const { transitions } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { isSubmitting, values, touched, errors, setValues, handleChange, handleSubmit } =
    useFormik<IValues>({
      initialValues: {
        title: '',
        description: '',
        copyright: '',
      },
      onSubmit,
      enableReinitialize: true,
    });
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);

  useEffect(() => {
    if (props.open) {
      if (photo) {
        const { url, title, description, copyright } = photo;

        setValues({
          title: title || '',
          description: description || '',
          copyright: copyright || '',
        });
        setFile(
          url
            ? {
                url,
                name: url.slice(url.lastIndexOf('/') + 1),
              }
            : undefined,
        );
      }
    } else {
      setTimeout(() => {
        setValues({
          title: '',
          description: '',
          copyright: '',
        });
        setFile(undefined);
        setError(false);
      }, transitions.duration.leavingScreen);
    }
  }, [props.open]);

  async function onSubmit({ title, description, copyright }: IValues) {
    if (!ride.id || !currentPartner) return;

    if (!photo && !(file instanceof File)) {
      setError(true);

      return;
    }

    setError(false);

    try {
      if (photo) {
        if (!photo.id) throw new Error('id is undefined');

        const updatedImage = await RideService.updateRidePhoto(ride.id, photo.id, {
          title,
          description,
          copyright,
        });

        onClose(updatedImage);
      } else {
        if (!(file instanceof File)) return;

        const newImage = await RideService.addRidePhoto(ride.id, {
          title,
          description,
          copyright,
          file,
        });

        onClose(newImage);
      }
      enqueueSnackbar(t('cycling-insights.ride.updated'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.not_updated'), { variant: 'error' });
    }
  }

  return (
    <Dialog
      isForm
      confirmTitle={
        photo ? <Trans i18nKey="commons.actions.save" /> : <Trans i18nKey="commons.actions.add" />
      }
      dialogTitle="ride-image-form-dialog"
      loading={isSubmitting}
      maxWidth="sm"
      onCancel={() => onClose()}
      onConfirm={handleSubmit}
      title={
        <Trans
          i18nKey="cycling-insights.ride.image_form.title"
          values={{ context: !photo ? 'new' : '' }}
        />
      }
      {...props}
    >
      {!photo && (
        <>
          <FileInput
            disabled={isSubmitting}
            error={error}
            file={file}
            onChange={setFile}
            size="small"
            type="image"
          />
          <Box alignItems="center" display="flex" gap={0.5} marginX={2} marginY={0.5}>
            <InfoOutlined color="action" sx={{ height: 14, width: 14 }} />
            <Typography color="textSecondary" variant="caption">
              {t('cycling-insights.ride.image_form.helper')}
            </Typography>
          </Box>
        </>
      )}
      <TextField
        fullWidth
        disabled={isSubmitting}
        error={touched.title && Boolean(errors.title)}
        id="title"
        label={t('cycling-insights.ride.image_form.image_title')}
        margin="dense"
        name="title"
        onChange={handleChange}
        size="small"
        value={values.title}
        variant="outlined"
      />
      <TextField
        fullWidth
        multiline
        disabled={isSubmitting}
        error={touched.description && Boolean(errors.description)}
        id="description"
        label={t('commons.description')}
        margin="dense"
        name="description"
        onChange={handleChange}
        rows={3}
        value={values.description}
        variant="outlined"
      />
      <TextField
        fullWidth
        disabled={isSubmitting}
        error={touched.copyright && Boolean(errors.copyright)}
        id="copyright"
        label={t('commons.copyright')}
        margin="dense"
        name="copyright"
        onChange={handleChange}
        size="small"
        value={values.copyright}
        variant="outlined"
      />
    </Dialog>
  );
}

export default ImageFormDialog;
