import { AuthService, DashboardPages, dashboardTabsStatus } from '@geovelo-frontends/commons';
import {
  HelpOutline,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LogoutOutlined,
  SettingsOutlined,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  ButtonBase,
  Chip,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Drawer as MuiDrawer,
  Toolbar,
  Tooltip,
  alpha,
} from '@mui/material';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../app/context';
import { CyclingInsightsIcon } from '../../components/icons/cycling-insights';
import { CyclingInsightsTextIcon } from '../../components/icons/cycling-insights-text';
import { IDashboardPage } from '../../pages/dashboard-page';
import pages from '../../pages/dashboard-routes';

import PartnersDialog from './partners-dialog';

export const closedDrawerWidth = 72;
export const smallScreenClosedDrawerWidth = 72;
export const openDrawerWidth = 350;

interface IProps {
  onDrawerToggle?: (open: boolean) => void;
  open?: boolean;
}

function Drawer({ open, onDrawerToggle }: IProps): JSX.Element {
  const {
    navigation: { currentPage, currentTab },
    user: { current: currentUser },
    partner: { lastVisited: lastVisitedPartner, list: partners },
    actions: { setCurrentUser },
  } = useContext(AppContext);
  const [partnersDialogOpen, openPartnersDialog] = useState(false);
  const [navMenuPage, setNavMenuPage] = useState<Partial<IDashboardPage> | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!open) setNavMenuPage(null);
  }, [open]);

  function handlePartnersButtonClick() {
    openPartnersDialog(true);
  }

  function handleClose() {
    onDrawerToggle?.(false);
  }

  async function handleSignOut() {
    try {
      await AuthService.signOut();
      setCurrentUser(null);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <Wrapper className={open ? '' : 'closed'} variant="permanent">
        <StyledToolbar>
          <Link to={`/${lastVisitedPartner?.code}`}>
            <StyledIcon color="primary" />
            {open && <StyledTextIcon color="primary" />}
          </Link>
        </StyledToolbar>
        {partners && lastVisitedPartner && (
          <>
            {currentPage && (
              <Tooltip
                placement="right"
                PopperProps={{ style: open ? { display: 'none' } : {} }}
                title={lastVisitedPartner.title}
              >
                <PartnersButton onClick={handlePartnersButtonClick}>
                  <Box flexShrink={0}>
                    {lastVisitedPartner.icon ? (
                      <Avatar src={lastVisitedPartner.icon} />
                    ) : (
                      <Avatar>{lastVisitedPartner.code[0].toUpperCase()}</Avatar>
                    )}
                  </Box>
                  <Box
                    flexGrow={1}
                    marginLeft="25px"
                    marginRight={2}
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {lastVisitedPartner.title}
                  </Box>
                  <Box flexShrink={0}>
                    <KeyboardArrowDown fontSize="small" />
                  </Box>
                </PartnersButton>
              </Tooltip>
            )}
            <StyledList sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
              {pages
                .filter(({ pageKey }) => lastVisitedPartner.enableDashboardPageAccess[pageKey])
                .map((page) => {
                  const { pageKey, path, titleKey, icon, tabs } = page;
                  const allowedTabs =
                    tabs?.filter(
                      ({ key, hideInDrawer }) =>
                        lastVisitedPartner?.dashboardTabsPermissions[key] !== 'none' &&
                        !hideInDrawer,
                    ) || [];

                  // if (allowedTabs.length < 1) return <></>;

                  const props =
                    allowedTabs.length < 2
                      ? {
                          component: Link,
                          to:
                            pageKey === DashboardPages.Home
                              ? `/${lastVisitedPartner.code}`
                              : `/${lastVisitedPartner.code}/${path}${
                                  allowedTabs.length === 1 ? `/${allowedTabs[0].path}` : ''
                                }`,
                          onClick: handleClose,
                        }
                      : {
                          component: ButtonBase,
                          onClick: () => {
                            onDrawerToggle?.(true);
                            setNavMenuPage(navMenuPage?.pageKey !== pageKey ? page : null);
                          },
                        };

                  return (
                    <Fragment key={pageKey}>
                      <Tooltip placement="right" title={open ? '' : <Trans i18nKey={titleKey} />}>
                        <ListItemButton
                          className={pageKey === currentPage ? 'active' : ''}
                          sx={{
                            paddingRight: ['alpha', 'beta', 'old'].includes(
                              dashboardTabsStatus[pageKey] || '',
                            )
                              ? '72px !important'
                              : undefined,
                          }}
                          {...props}
                        >
                          <ListItemIcon sx={{ color: 'inherit' }}>{icon}</ListItemIcon>
                          <ListItemText
                            primary={<Trans i18nKey={titleKey} />}
                            primaryTypographyProps={{ fontWeight: 500 }}
                          />
                          {open && allowedTabs.length === 1 && (
                            <>
                              {dashboardTabsStatus[allowedTabs[0].key] === 'alpha' ? (
                                <ListItemSecondaryAction>
                                  <Chip color="secondary" label="Alpha" size="small" />
                                </ListItemSecondaryAction>
                              ) : dashboardTabsStatus[allowedTabs[0].key] === 'beta' ? (
                                <ListItemSecondaryAction>
                                  <Chip color="secondary" label="Beta" size="small" />
                                </ListItemSecondaryAction>
                              ) : (
                                dashboardTabsStatus[allowedTabs[0].key] === 'old' && (
                                  <ListItemSecondaryAction>
                                    <Chip color="secondary" label="Old" size="small" />
                                  </ListItemSecondaryAction>
                                )
                              )}
                            </>
                          )}
                          {open && allowedTabs.length > 1 && (
                            <Box flexShrink={0} marginLeft={1}>
                              {navMenuPage?.pageKey === pageKey ? (
                                <KeyboardArrowUp fontSize="small" />
                              ) : (
                                <KeyboardArrowDown fontSize="small" />
                              )}
                            </Box>
                          )}
                        </ListItemButton>
                      </Tooltip>
                      {allowedTabs.length > 1 && (
                        <Collapse in={navMenuPage?.pageKey === pageKey}>
                          <List>
                            {allowedTabs.map(
                              ({ key: tabKey, path: tabPath, titleKey: tabTitleKey }) => {
                                const props =
                                  currentTab?.key !== tabKey
                                    ? {
                                        component: Link,
                                        to: `/${lastVisitedPartner.code}/${path}/${tabPath}`,
                                        onClick: handleClose,
                                      }
                                    : {};

                                return (
                                  <ListItem
                                    key={tabKey}
                                    sx={(theme) => ({
                                      backgroundColor:
                                        currentTab?.key === tabKey
                                          ? alpha(theme.palette.primary.main, 0.05)
                                          : 'transparent',
                                      color: 'inherit',
                                      paddingLeft: '80px !important',
                                      paddingRight: ['alpha', 'beta', 'old'].includes(
                                        dashboardTabsStatus[tabKey] || '',
                                      )
                                        ? '72px !important'
                                        : undefined,
                                    })}
                                    {...props}
                                  >
                                    <ListItemText
                                      primary={<Trans i18nKey={tabTitleKey} />}
                                      primaryTypographyProps={{ fontSize: '0.9em' }}
                                    />
                                    {dashboardTabsStatus[tabKey] === 'alpha' ? (
                                      <ListItemSecondaryAction>
                                        <Chip color="secondary" label="Alpha" size="small" />
                                      </ListItemSecondaryAction>
                                    ) : dashboardTabsStatus[tabKey] === 'beta' ? (
                                      <ListItemSecondaryAction>
                                        <Chip color="secondary" label="Beta" size="small" />
                                      </ListItemSecondaryAction>
                                    ) : (
                                      dashboardTabsStatus[tabKey] === 'old' && (
                                        <ListItemSecondaryAction>
                                          <Chip color="secondary" label="Old" size="small" />
                                        </ListItemSecondaryAction>
                                      )
                                    )}
                                  </ListItem>
                                );
                              },
                            )}
                          </List>
                        </Collapse>
                      )}
                    </Fragment>
                  );
                })}
            </StyledList>
          </>
        )}
        <StyledSpacer />
        <Divider />
        <StyledList>
          {currentUser && (
            <Box position="relative">
              <Tooltip
                placement="right"
                PopperProps={{ style: open ? { display: 'none' } : {} }}
                title={<Trans i18nKey="commons.settings" />}
              >
                <ListItemButton
                  className={[currentPage === DashboardPages.Settings ? 'active' : ''].join(' ')}
                  component={ButtonBase}
                  onClick={() => navigate(`/${lastVisitedPartner?.code}/settings`)}
                  sx={{ paddingRight: '56px !important' }}
                >
                  <ListItemIcon sx={{ color: 'inherit' }}>
                    <SettingsOutlined />
                  </ListItemIcon>
                  <ListItemText primary={<Trans i18nKey="commons.settings" />} />
                </ListItemButton>
              </Tooltip>
              {open && (
                <ListItemSecondaryAction>
                  <Tooltip title={<Trans i18nKey="commons.actions.sign_out" />}>
                    <IconButton
                      className="signout-button"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleSignOut();
                      }}
                      size="small"
                    >
                      <LogoutOutlined fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              )}
            </Box>
          )}
          <Tooltip
            placement="right"
            PopperProps={{ style: open ? { display: 'none' } : {} }}
            title={<Trans i18nKey="commons.navigation.help" />}
          >
            <ListItemButton
              className={[currentPage === DashboardPages.Help ? 'active' : ''].join(' ')}
              component={Link}
              onClick={handleClose}
              to="/help"
            >
              <ListItemIcon sx={{ color: 'inherit' }}>
                <HelpOutline />
              </ListItemIcon>
              <ListItemText primary={<Trans i18nKey="commons.navigation.help" />} />
            </ListItemButton>
          </Tooltip>
        </StyledList>
      </Wrapper>
      <PartnersDialog onClose={() => openPartnersDialog(false)} open={partnersDialogOpen} />
    </>
  );
}

const Wrapper = styled(MuiDrawer)`
  flex-shrink: 0;
  white-space: nowrap;

  &,
  > div {
    background-color: #fff;
    color: ${({ theme }) => theme.palette.primary.main};
    overflow-x: hidden;
    transition: ${({ theme: { transitions } }) =>
      transitions.create('width', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      })};
    width: ${openDrawerWidth}px;
  }

  > div {
    border: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &.closed,
  &.closed > div {
    overflow-y: hidden;
    overflow-x: hidden;
    transition: ${({ theme: { transitions } }) =>
      transitions.create('width', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      })};
    width: ${smallScreenClosedDrawerWidth}px;

    @media (min-width: 600px) {
      width: ${closedDrawerWidth}px;
    }
  }

  @media print {
    display: none;
  }
`;

const StyledToolbar = styled(Toolbar)`
  && {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;

    a {
      align-items: center;
      display: inline-flex;
      gap: 24px;
      height: 48px;
    }
  }
`;

const StyledIcon = styled(CyclingInsightsIcon)`
  && {
    height: 48px;
    width: 48px;
  }
`;

const StyledTextIcon = styled(CyclingInsightsTextIcon)`
  && {
    height: 24px;
    width: auto;
  }
`;

const StyledList = styled(List)`
  && {
    width: 100%;

    a,
    button:not(.signout-button) {
      font-size: inherit;
      padding-left: 24px;
      padding-right: 24px;
      width: 100%;

      .MuiListItemText-root span {
        font-size: 0.9em;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.02)};
      }

      &.active {
        background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.05)};
        color: ${({ theme }) => theme.palette.secondary.main};
      }
    }
  }
`;

const PartnersButton = styled(ButtonBase)`
  &&& {
    align-self: center;
    border: 1px solid #e3e7ee;
    border-radius: 8px;
    font-size: 0.9em;
    flex-shrink: 0;
    height: 54px;
    justify-content: flex-start;
    margin: 24px 0;
    padding: 0 16px 0 9px;
    text-align: left;
    width: calc(100% - 18px);

    .MuiAvatar-root {
      background-color: ${({ theme }) => theme.palette.secondary.light};
      color: ${({ theme }) => theme.palette.primary.dark};
      font-weight: 700;
      height: 36px;
      width: 36px;
    }
  }
`;

const StyledSpacer = styled.div`
  flex-grow: 1;
`;

export default Drawer;
