import { Box, DialogProps, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import Dialog from '../../../../components/dialog';

type TProps = Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
};

function CyclabilityDialog({ onClose, open, ...props }: TProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Dialog
      closeHidden
      hasCloseIcon
      dialogTitle={t('cycling-insights.facilities.cyclability.dialog.title')}
      maxWidth="sm"
      onCancel={onClose}
      open={open}
      scroll="paper"
      title={<Trans i18nKey="cycling-insights.facilities.cyclability.dialog.title" />}
      {...props}
    >
      <Box display="flex" flexDirection="column" gap={4} sx={{ color: '#283859' }}>
        <Typography>
          <Trans i18nKey="cycling-insights.facilities.cyclability.dialog.description" />
        </Typography>
        <Box display="flex" flexDirection="column" gap="4px">
          <Box alignItems="center" display="flex" gap={2}>
            <Box bgcolor="#1C9267" borderRadius={10} height={10} width={10} />
            <Typography color="#1C9267" fontWeight={700}>
              <Trans i18nKey="cycling-insights.facilities.cyclability.very_cycleable" />
            </Typography>
          </Box>
          <Typography marginLeft="26px">
            <Trans i18nKey="commons.facilities.greenway" />,{' '}
            <Trans i18nKey="commons.facilities.cycleway" />
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap="4px">
          <Box alignItems="center" display="flex" gap={2}>
            <Box bgcolor="#2AC682" borderRadius={10} height={10} width={10} />
            <Typography color="#2AC682" fontWeight={700}>
              <Trans i18nKey="cycling-insights.facilities.cyclability.cycleable" />
            </Typography>
          </Box>
          <Typography marginLeft="26px">
            <Trans i18nKey="commons.facilities.lane" />,{' '}
            <Trans i18nKey="commons.facilities.shared_busway" />,{' '}
            <Trans i18nKey="commons.facilities.chaucidou" />,{' '}
            <Trans i18nKey="commons.road_types.residential" />
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap="4px">
          <Box alignItems="center" display="flex" gap={2}>
            <Box bgcolor="#F36565" borderRadius={10} height={10} width={10} />
            <Typography color="#F36565" fontWeight={700}>
              <Trans i18nKey="cycling-insights.facilities.cyclability.not_very_cycleable" />
            </Typography>
          </Box>
          <Typography marginLeft="26px">
            <Trans i18nKey="commons.road_types.path" />,{' '}
            <Trans i18nKey="commons.road_types.tertiary" />
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap="4px">
          <Box alignItems="center" display="flex" gap={2}>
            <Box bgcolor="#D34949" borderRadius={10} height={10} width={10} />
            <Typography color="#D34949" fontWeight={700}>
              <Trans i18nKey="cycling-insights.facilities.cyclability.difficult_to_cycle" />
            </Typography>
          </Box>
          <Typography marginLeft="26px">
            <Trans i18nKey="commons.facilities.opposite" />,{' '}
            <Trans i18nKey="commons.road_types.trunk" />,{' '}
            <Trans i18nKey="commons.road_types.secondary" />,{' '}
            <Trans i18nKey="commons.road_types.primary" />
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
}

export default CyclabilityDialog;
