import { SvgIcon, SvgIconProps } from '@mui/material';

function PlottyUsersIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 93 84" {...props}>
      <path
        d="M39.8911 34.3631C37.5784 26.0191 29.9217 20.756 21.453 22.9496C12.9843 25.1432 6.90812 33.9662 9.22006 42.3076C9.22006 42.3076 13.2003 59.3938 31.426 69.7518C32.892 70.4375 35.1382 69.7852 35.826 68.6121C46.2536 50.8351 39.8937 34.3624 39.8937 34.3624L39.8911 34.3631ZM27.1853 47.8233C21.8643 49.2015 16.3747 46.0688 14.9228 40.8304C13.4709 35.5921 16.6075 30.225 21.9258 28.8474C27.2441 27.4699 32.7364 30.6019 34.1883 35.8402C35.6402 41.0786 32.5036 46.4457 27.1853 47.8233Z"
        fill="#005A44"
      />
      <path
        d="M41.6241 33.7851C39.3134 25.4484 30.5876 20.4686 22.1353 22.658C13.6829 24.8473 8.70235 33.3802 11.0123 41.7143C11.0123 41.7143 14.9898 58.7853 33.1844 69.1381C34.3848 69.8213 35.9223 69.423 36.6085 68.2512C47.0119 50.4936 41.6267 33.7844 41.6267 33.7844L41.6241 33.7851ZM28.9456 47.2293C23.6349 48.6049 18.1546 45.4737 16.704 40.24C15.2534 35.0062 18.3827 29.645 23.6907 28.2701C28.9988 26.8952 34.4817 30.0256 35.9323 35.2594C37.3829 40.4931 34.2536 45.8544 28.9456 47.2293Z"
        fill="#528BE8"
      />
      <path
        d="M29.1996 48.0135C34.8747 46.5441 38.218 40.8184 36.6671 35.2248C35.1162 29.6312 29.2583 26.2879 23.5833 27.7573C17.9082 29.2268 14.5649 34.9525 16.1158 40.5461C17.6667 46.1397 23.5245 49.483 29.1996 48.0135Z"
        fill="white"
      />
      <path
        d="M23.3047 38.8119C23.793 38.6854 23.9999 37.9008 23.7668 37.0594C23.5336 36.218 22.9487 35.6385 22.4604 35.7651C21.9721 35.8916 21.7652 36.6762 21.9983 37.5176C22.2315 38.359 22.8164 38.9385 23.3047 38.8119Z"
        fill="#005A44"
      />
      <path
        d="M29.5249 37.183C30.0132 37.0565 30.2201 36.2719 29.987 35.4305C29.7538 34.5891 29.1689 34.0096 28.6806 34.1362C28.1923 34.2627 27.9854 35.0473 28.2185 35.8887C28.4517 36.7301 29.0366 37.3096 29.5249 37.183Z"
        fill="#005A44"
      />
      <path
        d="M29.5056 33.0043C28.916 32.6764 27.8608 32.4694 27.3488 33.5051"
        stroke="#005A44"
        strokeLinecap="round"
      />
      <path
        d="M21.1987 35.1754C21.5401 34.6056 22.3471 33.9149 23.3248 34.5637"
        stroke="#005A44"
        strokeLinecap="round"
      />
      <path
        d="M74.8663 35.1023C76.972 27.7239 73.5731 20.3917 66.0969 18.4093C58.6207 16.4269 49.8948 20.5467 47.7898 27.9228C47.7898 27.9228 42.9017 42.7053 51.9986 58.4997C52.7987 59.6573 54.8143 60.1292 55.8829 59.5296C72.08 50.4447 74.8686 35.1029 74.8686 35.1029L74.8663 35.1023ZM58.9336 39.9025C54.2363 38.657 51.5022 33.8898 52.8242 29.2577C54.1461 24.6255 59.0274 21.8776 63.7224 23.1226C68.4174 24.3675 71.1538 29.1352 69.8318 33.7674C68.5099 38.3995 63.6287 41.1474 58.9336 39.9025Z"
        fill="#0A429A"
      />
      <path
        d="M76.4448 35.4166C78.5486 28.0446 74.203 20.467 66.7412 18.4885C59.2794 16.5099 51.5237 20.8813 49.4206 28.2509C49.4206 28.2509 44.5375 43.0207 53.6131 58.7976C54.2118 59.8386 55.5691 60.1985 56.6359 59.5992C72.8054 50.5187 76.4471 35.4172 76.4471 35.4172L76.4448 35.4166ZM60.5404 40.2164C55.8521 38.9732 53.1245 34.2113 54.4453 29.5832C55.766 24.9551 60.639 22.2086 65.3249 23.4511C70.0109 24.6936 72.7409 29.4561 71.4201 34.0842C70.0993 38.7123 65.2263 41.4589 60.5404 40.2164Z"
        fill="#528BE8"
      />
      <path
        d="M60.33 40.9205C65.3401 42.2484 70.5465 39.3149 71.9587 34.3682C73.371 29.4216 70.4543 24.335 65.4442 23.007C60.4341 21.6791 55.2277 24.6126 53.8155 29.5593C52.4032 34.5059 55.3199 39.5925 60.33 40.9205Z"
        fill="white"
      />
      <path
        d="M60.1022 31.3202C60.5333 31.4346 61.0549 30.9241 61.2672 30.1801C61.4794 29.4361 61.302 28.7403 60.8709 28.626C60.4398 28.5116 59.9182 29.0221 59.706 29.7661C59.4937 30.5101 59.6711 31.2059 60.1022 31.3202Z"
        fill="#0A429A"
      />
      <path
        d="M65.6007 32.7929C66.0318 32.9072 66.5534 32.3968 66.7657 31.6528C66.978 30.9088 66.8006 30.213 66.3695 30.0986C65.9383 29.9843 65.4168 30.4947 65.2045 31.2387C64.9922 31.9827 65.1696 32.6785 65.6007 32.7929Z"
        fill="#0A429A"
      />
      <path
        d="M67.5098 29.589C67.2117 29.0828 66.5024 28.4673 65.6327 29.0371"
        stroke="#0A429A"
        strokeLinecap="round"
      />
      <path
        d="M60.1706 27.6273C60.6946 27.3397 61.6298 27.1615 62.0756 28.0811"
        stroke="#0A429A"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}

export default PlottyUsersIcon;
