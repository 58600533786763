import { Add } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { TQAPageContext } from '../../context';

import ExclusionZonesContent from './content';
import ImportZoneDialog from './import-zone-dialog';

function ExclusionZonesTab(): JSX.Element {
  const context = useOutletContext<TQAPageContext & TOutletContext>();
  const [importZoneDialogOpen, openImportZoneDialog] = useState<boolean>(false);
  const {
    header: { setActions },
    exclusionZones: { data, setImportedFile },
  } = context;

  useEffect(() => {
    return () => {
      setActions(undefined);
    };
  }, []);

  useEffect(() => {
    setActions(
      <Tooltip title={<Trans i18nKey="cycling-insights.qa.exclusion_zones.actions.add" />}>
        <span>
          <IconButton
            color="primary"
            disabled={!data}
            onClick={() => openImportZoneDialog(true)}
            size="small"
          >
            <Add />
          </IconButton>
        </span>
      </Tooltip>,
    );
  }, [data]);

  return (
    <>
      <PageContentLayout leftPanel={<ExclusionZonesContent {...context} />} />
      <ImportZoneDialog
        onCancel={() => openImportZoneDialog(false)}
        open={importZoneDialogOpen}
        setImportedFile={setImportedFile}
      />
    </>
  );
}

export default ExclusionZonesTab;
