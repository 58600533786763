import { Box, Skeleton, Typography } from '@mui/material';
import { ReactNode } from 'react';

function Progression({
  disableHighlight,
  prevStat: _prevStat,
  stat,
  label,
  formatDiff,
}: {
  disableHighlight?: boolean;
  formatDiff?: (value: number) => string;
  label?: ReactNode;
  prevStat?: number;
  stat?: number;
}): JSX.Element {
  const prevStat = _prevStat || 0;
  const diff = Math.abs((stat || 0) - prevStat);
  const isWorse = (stat !== undefined && prevStat > stat) || false;

  return (
    <Box alignItems="center" display="flex" gap={1}>
      {disableHighlight ? (
        stat ? (
          <Typography color={isWorse ? '#a42c49' : '#038b63'} variant="body2">
            {isWorse ? '- ' : '+ '}
            {formatDiff?.(diff) || diff}
          </Typography>
        ) : (
          <Skeleton variant="text" width={100} />
        )
      ) : stat !== undefined ? (
        <Box
          bgcolor={isWorse ? '#ffebee' : '#eef8f4'}
          borderRadius={1}
          color={isWorse ? '#a42c49' : '#038b63'}
          padding="4px"
          whiteSpace="nowrap"
        >
          <Typography fontWeight={600} variant="body2">
            {isWorse ? '- ' : '+ '}
            {formatDiff?.(diff) || diff}
          </Typography>
        </Box>
      ) : (
        <Skeleton height={32} variant="text" width={100} />
      )}
      {label && <Typography variant="body2">vs {label}</Typography>}
    </Box>
  );
}

export default Progression;
