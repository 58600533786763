import { FileInput, TFile } from '@geovelo-frontends/commons';
import {
  Box,
  DialogProps,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Dialog from '../../../../components/dialog';

type TProps = Omit<DialogProps, 'onClose' | 'title'> & {
  onCancel: () => void;
  open: boolean;
  setImportedFile: (file?: TFile) => void;
};

type TFileType = { key: 'geojson' | 'shp' | 'gpx' | 'none'; label: string };

const fileTypes: TFileType[] = [
  { key: 'geojson', label: 'commons.files.formats.geojson' },
  { key: 'gpx', label: 'commons.files.formats.gpx' },
  { key: 'none', label: 'commons.no_file' },
];

function ImportZoneDialog({ open, setImportedFile, ...props }: TProps): JSX.Element {
  const [file, setFile] = useState<TFile>();
  const [fileType, setFileType] = useState<TFileType>(fileTypes[0]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  async function handleFileChange(newFile?: TFile) {
    if (newFile === undefined || newFile instanceof File) {
      if (newFile) {
        const text = await newFile.text();
        if (!text.includes('LineString') && !text.includes('MultiLineString')) {
          if (!text.includes('Polygon')) {
            setFile(undefined);
            enqueueSnackbar(t('cycling-insights.qa.exclusion_zones.errors.bad_form'), {
              variant: 'error',
            });
          } else if ((text.match(/Polygon/g)?.length || 0) > 1) {
            setFile(undefined);
            enqueueSnackbar(t('cycling-insights.qa.exclusion_zones.errors.multiple_polygons'), {
              variant: 'error',
            });
          } else setFile(newFile);
        } else if (text.includes('Polygon')) {
          setFile(undefined);
          enqueueSnackbar(t('cycling-insights.qa.exclusion_zones.errors.multiple_form_types'), {
            variant: 'error',
          });
        } else setFile(newFile);
      } else setFile(undefined);
    }
  }

  return (
    <Dialog
      isForm
      cancelTitle={<Trans i18nKey="commons.actions.cancel" />}
      confirmDisabled={fileType.key !== 'none' && !file}
      confirmTitle={
        fileType.key !== 'none' ? (
          <Trans i18nKey="cycling-insights.qa.exclusion_zones.actions.use_file" />
        ) : (
          <Trans i18nKey="commons.actions.confirm" />
        )
      }
      dialogTitle="import-file-dialog"
      maxWidth="sm"
      onConfirm={() => {
        setImportedFile(file);
        navigate('../exclusion-zones-form');
      }}
      open={open}
      scroll="paper"
      title={<Trans i18nKey="cycling-insights.qa.exclusion_zones.import_dialog.title" />}
      {...props}
    >
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography gutterBottom component="p" variant="subtitle1">
          <Trans i18nKey="cycling-insights.qa.exclusion_zones.import_dialog.description" />
        </Typography>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel htmlFor="file-type">
            <Trans i18nKey="cycling-insights.qa.exclusion_zones.import_dialog.file_type" />
          </InputLabel>
          <Select
            inputProps={{ id: 'file-type' }}
            label={<Trans i18nKey="cycling-insights.qa.exclusion_zones.import_dialog.file_type" />}
            name="file_type"
            onChange={(event) => {
              setFile(undefined);
              const selectedType = fileTypes.find(({ key }) => key === event.target.value);
              if (selectedType) setFileType(selectedType);
            }}
            renderValue={(type) => t(type.label)}
            value={fileType}
          >
            {fileTypes.map(({ key, label }) => (
              <MenuItem key={key} value={key}>
                <Trans i18nKey={label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {fileType.key !== 'none' && (
          <FileInput
            buttonLabel={<Trans i18nKey="commons.actions.browse" />}
            file={file}
            onChange={handleFileChange}
            type={fileType.key}
          />
        )}
      </Box>
    </Dialog>
  );
}

export default ImportZoneDialog;
