import { isMoment } from 'moment';
import { useState } from 'react';

import { TOrder, TRow, TValue } from '../../components/table';

interface IActions {
  setPage?: (page: number) => void;
}

function useSortableTable<TKey extends string | number, TCellKey extends string>(
  initialOrderBy: TCellKey,
  initialOrder: TOrder,
  { setPage }: IActions = {},
): {
  orderBy: TCellKey;
  order: TOrder;
  setOrderBy: (orderBy: TCellKey) => void;
  setOrder: (order: TOrder) => void;
  sort: (rows: TRow<TKey, TCellKey>[], defaultKey: TCellKey) => TRow<TKey, TCellKey>[];
  onSortRequest: (property: TCellKey) => void;
} {
  const [orderBy, setOrderBy] = useState<TCellKey>(initialOrderBy);
  const [order, setOrder] = useState<TOrder>(initialOrder);

  function onSortRequest(property: TCellKey) {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    if (setPage) setPage(0);
  }

  function compare(aValue?: TValue, bValue?: TValue): number {
    if (isMoment(aValue) && isMoment(bValue)) {
      return order === 'asc' ? aValue.unix() - bValue.unix() : bValue.unix() - aValue.unix();
    }

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    }

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return order === 'asc'
        ? aValue.toLowerCase().localeCompare(bValue)
        : bValue.toLowerCase().localeCompare(aValue);
    }

    return 0;
  }

  function sort(rows: TRow<TKey, TCellKey>[], defaultKey: TCellKey) {
    return rows.sort(({ cells: aCells }, { cells: bCells }) => {
      const aValue = aCells[orderBy]?.value;
      const aDefaultValue = `${aCells[defaultKey]?.value}`;
      const bValue = bCells[orderBy]?.value;
      const bDefaultValue = `${bCells[defaultKey]?.value}`;

      if (aValue === bValue) return compare(aDefaultValue, bDefaultValue);

      return compare(aValue, bValue);
    });
  }

  return { orderBy, order, setOrderBy, setOrder, sort, onSortRequest };
}

export default useSortableTable;
