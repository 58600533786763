import { useOutletContext } from 'react-router-dom';

import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { TCartographicDataPageContext } from '../../context';

import FacilitiesNewReportForm from './form';

function FacilitiesNewReportTab(): JSX.Element {
  const context = useOutletContext<TCartographicDataPageContext & TOutletContext>();

  return <PageContentLayout leftPanel={<FacilitiesNewReportForm {...context} />} />;
}

export default FacilitiesNewReportTab;
