import {
  BikeRoute,
  Report,
  RouteReport,
  TFile,
  TReportTypeCode,
  TStatus,
  User,
} from '@geovelo-frontends/commons';
import { ReactNode, useContext, useRef, useState } from 'react';

import { AppContext } from '../../app/context';
import { IPeriodFormValues } from '../../components/form/period';
import { ITableRef } from '../../components/table';
import useQueryParams from '../../hooks/query-params';
import PageContainerLayout from '../../layouts/page/container';
import { IDashboardPage } from '../dashboard-page';

import BikeRoutesTab from './components/bike-routes';
import ExclusionZonesTab from './components/exclusion-zones';
import ExclusionZonesFormTab from './components/exclusion-zones-form';
import ItinerartReportsTab from './components/itinerary-reports';
import NewReportTab from './components/new-report';
import ReportsTab from './components/osm-reports';
import RefRoutesTab from './components/ref-routes';
import { TQAPageContext } from './context';

const statuses: TStatus[] = ['OPEN', 'CLOSED', 'ONGOING'];
const reviewsCount: number[] = [-1, 0, 1, 2, 3];

function QAPage(page: IDashboardPage): JSX.Element {
  const {
    report: { types },
  } = useContext(AppContext);
  const { searchParams, get: getQueryParams, getPeriods } = useQueryParams();

  // bike routes context
  const [bikeRoutes, setBikeRoutes] = useState<BikeRoute[]>();

  // header context
  const [prevButtonClick, setPrevButtonClick] = useState<() => () => void>();
  const [actions, setActions] = useState<ReactNode>();
  const [title, setTitle] = useState<ReactNode>();

  // period context
  const [periods, setPeriods] = useState<IPeriodFormValues>(() => getPeriods());
  const [periodsComparisonEnabled, enablePeriodsComparison] = useState(
    searchParams.get('compare') === 'true',
  );

  // itinerary reports context
  const [itineraryReports, setItineraryReports] = useState<RouteReport[]>();
  const [selectedReport, selectReport] = useState<RouteReport | undefined>(undefined);

  // ref routes context
  const [refRoutesSearch, setRefRoutesSearch] = useState('');
  const [refRoutesSucceededCount, setRefRoutesSucceededCount] = useState<number>();
  const [refRoutesFailedCount, setRefRoutesFailedCount] = useState<number>();

  // users logs context
  const [usersLogsSelectedUser, selectUsersLogsUser] = useState<User | null>(null);
  const [usersLogs, setUsersLogs] = useState<string | null>(null);
  const [usersLogsSelectedTraceId, selectUsersLogsTraceId] = useState<number | null>(null);

  // OSM reports context
  const [osmReportSelectedStatuses, selectOSMReportStatuses] = useState<TStatus[]>(
    getQueryParams('statuses', statuses) || statuses,
  );
  const [osmReportSelectedReviewsCount, selectOSMReportReviewsCount] = useState<number>(
    getQueryParams(
      'reviews',
      reviewsCount.map((count) => count.toString()),
    )?.map((value) => parseInt(value, 10))[0] || -1,
  );
  const [osmReportSelectedTypeCodes, selectOSMReportTypeCodes] = useState<TReportTypeCode[]>(
    types
      ? getQueryParams(
          'types',
          types
            .filter(
              ({ code, isBindToOSM }) =>
                isBindToOSM && code !== 'support' && code !== 'exclusionZone',
            )
            .map(({ code }) => code) as TReportTypeCode[],
        ) ||
          (types
            .filter(
              ({ code, isBindToOSM }) =>
                isBindToOSM && code !== 'support' && code !== 'exclusionZone',
            )
            .map(({ code }) => code) as TReportTypeCode[])
      : [],
  );
  const [osmReports, setOSMReports] = useState<Report[]>();
  const [osmReportSelectedId, selectOSMReportId] = useState<number | null>(null);
  const osmReportsTableRef = useRef<ITableRef>(null);

  // exclusion zones context
  const [exclusionZonesData, setExclusionZonesData] = useState<Report[]>();
  const [selectedZone, selectZone] = useState<Report>();
  const [importedFile, setImportedFile] = useState<TFile>();

  return (
    <PageContainerLayout<TQAPageContext>
      context={{
        bikeRoutes: {
          list: bikeRoutes,
          setList: setBikeRoutes,
        },
        header: {
          prevButtonClick,
          title,
          actions,
          setPrevButtonClick,
          setTitle,
          setActions,
        },
        period: {
          values: periods,
          comparisonEnabled: periodsComparisonEnabled,
          setValues: setPeriods,
          enableComparison: enablePeriodsComparison,
        },
        reports: {
          reports: itineraryReports,
          selectedReport,
          setReports: setItineraryReports,
          selectReport,
        },
        refRoutes: {
          search: refRoutesSearch,
          succeededCount: refRoutesSucceededCount,
          failedCount: refRoutesFailedCount,
          setSearch: setRefRoutesSearch,
          setSucceededCount: setRefRoutesSucceededCount,
          setFailedCount: setRefRoutesFailedCount,
        },
        usersLogs: {
          selectedUser: usersLogsSelectedUser,
          logs: usersLogs,
          selectedTraceId: usersLogsSelectedTraceId,
          selectUser: selectUsersLogsUser,
          setLogs: setUsersLogs,
          selectTraceId: selectUsersLogsTraceId,
        },
        osmReports: {
          tableRef: osmReportsTableRef,
          selectedReviewsCount: osmReportSelectedReviewsCount,
          selectedStatuses: osmReportSelectedStatuses,
          selectedTypeCodes: osmReportSelectedTypeCodes,
          data: osmReports,
          selectedId: osmReportSelectedId,
          selectReviewsCount: selectOSMReportReviewsCount,
          selectStatuses: selectOSMReportStatuses,
          selectTypeCodes: selectOSMReportTypeCodes,
          setData: setOSMReports,
          selectId: selectOSMReportId,
        },
        exclusionZones: {
          data: exclusionZonesData,
          importedFile,
          selectedZone,
          selectZone,
          setData: setExclusionZonesData,
          setImportedFile,
        },
      }}
      page={page}
    />
  );
}

export {
  BikeRoutesTab,
  ExclusionZonesFormTab,
  ExclusionZonesTab,
  ItinerartReportsTab,
  RefRoutesTab,
  ReportsTab,
  NewReportTab,
};
export default QAPage;
