import {
  TStoppingAreasFeatureCollection,
  useLayers,
  useSource,
  useUnits,
} from '@geovelo-frontends/commons';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TColorCollection,
  TThicknessCollection,
  findIndexInIntervals,
  getColors,
} from '../../components/color-legend';
import { ISliderBounds, TSliderRange } from '../../components/form/slider';

import { Map, Popup } from '!maplibre-gl';

const sourceId = 'stopping-areas';
export const stoppingAreasLayerId = 'stopping-areas';

export const defaultColors: TColorCollection = [
  { value: '#50315e' },
  { value: '#643d68' },
  { value: '#956485' },
  { value: '#c1939d' },
  { value: '#edd1ca' },
];

const radiuses: TThicknessCollection = [
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 10 },
  { value: 11 },
  { value: 12 },
  { value: 13 },
  { value: 14 },
];

function useStoppingAreas(map: Map | null | undefined): {
  clear: () => void;
  destroy: () => void;
  init: () => void;
  initialized: boolean;
  update: (
    { features }: TStoppingAreasFeatureCollection,
    props: {
      colors?: TColorCollection;
      currentRange: TSliderRange;
      primaryBounds: ISliderBounds;
      secondaryBounds: ISliderBounds;
      secondaryRange: TSliderRange;
    },
  ) => void;
} {
  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation();
  const initializedRef = useRef(false);
  const highlightedAreaTooltip = useRef<Popup>();
  const { addGeoJSONSource, getGeoJSONSource, updateGeoJSONSource, clearGeoJSONSource } = useSource(
    map,
    sourceId,
  );
  const { addCircleLayer } = useLayers(map);
  const { toTime } = useUnits();
  let hoveredAreaId: number | string | undefined;

  function init() {
    if (!map || initializedRef.current) return;
    if (getGeoJSONSource()) {
      initializedRef.current = true;
      setInitialized(true);
      return;
    }

    addGeoJSONSource();

    highlightedAreaTooltip.current = new Popup({
      className: 'map-tooltip',
      closeButton: false,
    });

    addCircleLayer(stoppingAreasLayerId, sourceId, {
      'circle-radius': ['get', 'radius'],
      'circle-color': ['get', 'color'],
    });

    map.on('mousemove', stoppingAreasLayerId, ({ features }) => {
      if (features && features.length > 0) {
        const { id, geometry, properties } = features[0];
        if (geometry.type !== 'Point' || !properties) return;

        highlightedAreaTooltip.current
          ?.setHTML(
            `<h3>${t('cycling-insights.usage.stopping_areas.stop_title', { id })}</h3><div>${t(
              'cycling-insights.usage.stopping_areas.median_duration',
              {
                duration: toTime(properties.medianDuration),
              },
            )}</div><div>${t('cycling-insights.usage.stopping_areas.stops', {
              count: properties.totalNbStops,
            })}</div>`,
          )
          .setLngLat({ lat: geometry.coordinates[1], lng: geometry.coordinates[0] })
          .addTo(map);

        if (hoveredAreaId !== properties?.id) {
          hoveredAreaId = properties?.id;
          if (hoveredAreaId) {
            map.getCanvas().style.cursor = 'pointer';
          } else {
            clearHighlight();
          }
        }
      }
    });

    map.on('mouseleave', stoppingAreasLayerId, () => {
      map.getCanvas().style.cursor = '';
      clearHighlight();
    });

    initializedRef.current = true;
    setInitialized(true);
  }

  function update(
    collection: TStoppingAreasFeatureCollection,
    {
      colors: customColors,
      primaryBounds,
      secondaryBounds,
      currentRange,
      secondaryRange,
    }: {
      colors?: TColorCollection;
      currentRange: TSliderRange;
      primaryBounds: ISliderBounds;
      secondaryBounds: ISliderBounds;
      secondaryRange: TSliderRange;
    },
  ) {
    const colors = getColors({ colors: customColors || defaultColors, bounds: primaryBounds });

    updateGeoJSONSource({
      ...collection,
      features: collection.features
        .filter(({ properties }) => {
          const { totalNbStops, medianDuration: duration } = properties;

          return (
            totalNbStops >= currentRange[0] &&
            totalNbStops <= currentRange[1] &&
            duration >= secondaryRange[0] &&
            duration <= secondaryRange[1]
          );
        })
        .map(({ properties, ...feature }) => {
          const colorIndex = findIndexInIntervals<string>(
            properties.medianDuration,
            secondaryBounds,
            colors,
          );
          const radiusIndex = primaryBounds
            ? findIndexInIntervals<number>(properties.totalNbStops, primaryBounds, radiuses)
            : null;

          return {
            ...feature,
            properties: {
              ...properties,
              color: colorIndex !== null ? colors[colorIndex].value : undefined,
              radius:
                radiuses[radiusIndex !== null ? radiusIndex : Math.floor(radiuses.length / 2)]
                  .value,
            },
          };
        }),
    });
  }

  function clearHighlight() {
    highlightedAreaTooltip.current?.remove();

    hoveredAreaId = undefined;
  }

  function clear() {
    clearGeoJSONSource();
    clearHighlight();
  }

  function destroy() {
    clear();

    initializedRef.current = false;
    setInitialized(false);
  }

  return { initialized, init, update, clear, destroy };
}

export default useStoppingAreas;
