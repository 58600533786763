import { Period } from '@geovelo-frontends/commons';

export function getPeriodsDiff(
  currentPeriod: Period,
  prevPeriod: Period,
): { type: 'year' | 'month' | 'week' | 'days'; value: number } {
  switch (currentPeriod.type) {
    case 'year':
    case 'month':
    case 'week':
      return {
        type: currentPeriod.type,
        value: currentPeriod.from.diff(prevPeriod.from, currentPeriod.type),
      };
    case 'custom':
      return currentPeriod.unit === 'month'
        ? { type: 'month', value: currentPeriod.from.diff(prevPeriod.from, 'month') + 1 }
        : { type: 'days', value: currentPeriod.from.diff(prevPeriod.from, 'days') };
    default:
      return { type: 'days', value: currentPeriod.from.diff(prevPeriod.from, 'days') };
  }
}
