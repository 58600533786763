import { useUnits } from '@geovelo-frontends/commons';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Skeleton, SvgIconProps, Tooltip, Typography } from '@mui/material';
import { Chart } from 'chart.js';
import { ReactNode, useEffect, useRef, useState } from 'react';

const colors = ['#905EFA', '#46CE9D'];

function Distribution({
  Icon,
  label,
  labels,
  descriptions,
  chartId,
  data,
}: {
  chartId: string;
  data?: number[];
  descriptions?: ReactNode[];
  Icon: (props: SvgIconProps) => JSX.Element;
  label: ReactNode;
  labels?: string[];
}): JSX.Element {
  const [areDataEmpty, setAreDataEmpty] = useState(false);
  const chartRef = useRef<Chart<'doughnut', number[], string>>();
  const { formatNumber } = useUnits();

  useEffect(() => {
    if (data) {
      const _areDataEmpty = data.reduce((res, value) => res + value, 0) === 0;
      setAreDataEmpty(_areDataEmpty);

      const ctx = document.getElementById(chartId);
      if (!_areDataEmpty && ctx && ctx instanceof HTMLCanvasElement) {
        chartRef.current = new Chart(ctx, {
          type: 'doughnut',
          data: { labels, datasets: [{ data, backgroundColor: colors }] },
          options: {
            responsive: true,
            cutout: 30,
            maintainAspectRatio: false,
            plugins: {
              legend: { display: false },
              tooltip: {
                displayColors: false,
                padding: 8,
                backgroundColor: '#283859',
                callbacks: {
                  label: ({ label }) => label,
                  afterLabel: ({ parsed }) => `${parsed}`,
                },
              },
            },
          },
        });
      }
    }

    return () => {
      if (chartRef.current) chartRef.current.destroy();
    };
  }, [data]);

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      flexShrink={0}
      gap={2}
      width="33%"
    >
      <Box alignItems="center" display="flex" flexDirection="column">
        <Icon color="action" />
        <Typography variant="body2">{label}</Typography>
      </Box>
      {data && !areDataEmpty ? (
        <Box height={80}>
          <canvas id={chartId} width="100%" />
        </Box>
      ) : (
        <Skeleton
          animation={areDataEmpty ? false : 'pulse'}
          height={64}
          sx={{ backgroundColor: 'transparent', border: '8px solid rgba(0, 0, 0, 0.11)' }}
          variant="circular"
          width={64}
        />
      )}
      {!!labels && (
        <Box display="flex" flexDirection="column" gap={1} width="100%">
          {labels.map((label, index) => (
            <Box alignItems="center" display="flex" gap={1} key={`${chartId}-label-${index}`}>
              <Box
                borderRadius="5px"
                flexShrink={0}
                height={10}
                sx={{ backgroundColor: colors[index] }}
                width={10}
              />
              <Box display="flex" flexDirection="column">
                <Box alignItems="center" display="flex" gap={1}>
                  <Typography variant="caption">{label}</Typography>
                  {descriptions?.[index] && (
                    <Tooltip placement="bottom" title={descriptions[index]}>
                      <InfoOutlined color="action" fontSize="small" />
                    </Tooltip>
                  )}
                </Box>
                <Typography variant="caption">
                  {data ? formatNumber(data[index]) : <Skeleton variant="text" width={50} />}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default Distribution;
