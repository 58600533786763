import {
  GeogroupService,
  Period,
  useCancellablePromise,
  useUnits,
} from '@geovelo-frontends/commons';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../app/context';
import { environment } from '../../../environment';

import Card from './card';
import Stat from './stat';
import { IActivityStats } from './types';

export interface IMembersStats {
  current: { count: number };
}

function Environment({
  period,
  activityStatistics,
}: {
  activityStatistics?: IActivityStats;
  period: Period;
}): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const [membersStatistics, setMembersStatistics] = useState<IMembersStats>();
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise: cancellableMembersPromise, cancelPromises: cancelMembersPromise } =
    useCancellablePromise();
  const { formatNumber, toDistance } = useUnits();

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) getCyclistsStatistics();

    return () => cancelMembersPromise();
  }, [initialized, currentPartner, period]);

  async function getCyclistsStatistics() {
    setMembersStatistics(undefined);

    if (!currentPartner?.geoGroupId) return;

    try {
      const { count } = await cancellableMembersPromise(
        GeogroupService.getMembersCount(currentPartner.geoGroupId, {
          partner: currentPartner,
          registrationEndDate: period.to,
        }),
      );

      setMembersStatistics({ current: { count } });
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.community.members.server_error'), { variant: 'error' });
      }
    }
  }

  const savedKgOfCO2 =
    activityStatistics &&
    (activityStatistics.current.distance / 1000) * environment.savedKgOfCO2PerKilometer;

  return (
    <>
      <Card
        currentData={
          savedKgOfCO2 || savedKgOfCO2 === 0
            ? `${formatNumber(Math.round(savedKgOfCO2))} kg`
            : undefined
        }
        progression={activityStatistics?.progressions.distance}
        titleInfo={
          <Trans
            components={[
              <p key={0} />,
              <p key={1} />,
              <a href="https://impactco2.fr/transport" key={2} rel="noreferrer" target="_blank" />,
            ]}
            i18nKey="cycling-insights.home.items.environment.co2_saved_description"
          />
        }
        titleKey="cycling-insights.home.items.environment.co2_saved"
        to={`/${currentPartner?.code}/bicycle-observatory/geovelo-activity?period=month&from=${period.from.format(
          'YYYY-MM-DD',
        )}&prev-from=${period.getPrevPeriod().from.format('YYYY-MM-DD')}&compare=true`}
      >
        <Box display="flex" flexDirection="column" gap={2}>
          {currentPartner?.hasCyclistsStatistics && (
            <Stat
              label={t('cycling-insights.community.members.stats.count_other')}
              progression={0}
              value={membersStatistics?.current.count}
            />
          )}
          <Stat
            label={t('commons.stats.distance_label')}
            progression={activityStatistics?.progressions.distance}
            value={activityStatistics && toDistance(activityStatistics.current.distance)}
          />
          <Stat
            label={t('commons.stats.journeys_label')}
            progression={activityStatistics?.progressions.nbRoutes}
            value={activityStatistics && activityStatistics.current.nbRoutes}
          />
        </Box>
      </Card>
    </>
  );
}

export default Environment;
