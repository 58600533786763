import { Report, TReportTypeCode, TStatus } from '@geovelo-frontends/commons';
import { ChatBubbleOutline } from '@mui/icons-material';
import { Box } from '@mui/material';
import { RefObject, useImperativeHandle, useState } from 'react';
import { Trans } from 'react-i18next';

import useReportsTable from '../../hooks/reports-table';
import { IPeriodFormProps } from '../form/period';
import Table, { ITableRef } from '../table';

type TKey = 'status' | 'type' | 'updated' | 'reviews';

const keys: TKey[] = ['type', 'reviews', 'updated', 'status'];

export type TReportsTableProps = {
  context: {
    reports?: Report[];
    selectReportId: (id: number | null) => void;
    selectedReportId: number | null;
    setReports: (reports?: Report[]) => void;
    statuses?: TStatus[];
    reportsTableRef: RefObject<ITableRef>;
  };
  period: IPeriodFormProps;
  setLoading: (loading: boolean) => void;
  typeCodes: TReportTypeCode[];
};

function ReportsTable({
  typeCodes,
  period: {
    values: { current: currentPeriod },
  },
  context: { statuses, reports, selectedReportId, reportsTableRef, selectReportId },
  setLoading,
}: TReportsTableProps): JSX.Element {
  const [defaultStatuses] = useState<TStatus[]>(['CLOSED', 'ARCHIVED', 'CLOSED_BY_OSM']);
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    rows,
    reorder: reorderTable,
    reloadPage: reloadTablePage,
    reloadReport: reloadTableReport,
    onPageChange,
    onRowsPerPageChange,
    onSortRequest,
  } = useReportsTable<TKey>({
    isBindToOSM: true,
    defaultOrderBy: 'updated',
    reports,
    selectedTypeCodes: typeCodes,
    selectedStatuses: statuses || defaultStatuses,
    currentPeriod,
    selectedId: selectedReportId,
    errorMessageKey: 'cycling-insights.reports.osm_cartographic_reports.server_error',
    setLoading,
    selectId: selectReportId,
  });

  useImperativeHandle(reportsTableRef, () => ({
    reorder: reorderTable,
    reloadTablePage,
    reloadTableReport,
  }));

  return (
    <>
      <Table
        count={reports?.length}
        headers={{
          type: { label: <Trans i18nKey="commons.stats.type_label" />, sortable: true, width: 180 },
          reviews: {
            label: (
              <Box alignItems="center" display="flex">
                <ChatBubbleOutline fontSize="small" />
              </Box>
            ),
          },
          updated: {
            label: <Trans i18nKey="commons.stats.update_label" />,
            sortable: true,
            width: 100,
          },
          status: { label: <Trans i18nKey="commons.status" />, sortable: true, width: 85 },
        }}
        keys={keys}
        onClick={(key) => selectReportId(key)}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        onSortRequest={onSortRequest}
        order={order}
        orderBy={orderBy}
        page={page}
        rows={rows}
        rowsPerPage={rowsPerPage}
        selectedKey={selectedReportId || undefined}
        tableProps={{ sx: { tableLayout: 'fixed' } }}
        title="Cartographic contributions table"
      />
    </>
  );
}

export default ReportsTable;
