import { AuthService, HttpService, Partner } from '@geovelo-frontends/commons';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../app/context';

function usePartner(partnerCode: string): {
  loading: boolean;
  currentPartner?: Partner | null;
  partnerToRedirect: Partner | null;
} {
  const [partnerToRedirect, redirectToPartner] = useState<Partner | null>(null);
  const {
    partner: { list: partners, current: currentPartner },
    actions: { setCurrentUser, setCurrentPartner, setLastVisitedPartner, getPartnerSections },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (partners && partners.length === 0) signOut();
  }, []);

  useEffect(() => {
    if (partners) {
      const allowedPartner =
        (partnerCode && partners.find(({ code }) => code === partnerCode)) || null;
      if (!allowedPartner) {
        const defaultPartner = partners.find(({ isDefault }) => isDefault) || partners[0];

        redirectToPartner(defaultPartner);
      } else {
        redirectToPartner(null);
        HttpService.partner = allowedPartner.code;
        setCurrentPartner(allowedPartner);
        setLastVisitedPartner(allowedPartner);

        try {
          getPartnerSections(allowedPartner);
        } catch (err) {
          console.error(err);
          enqueueSnackbar(t('cycling-insights.bicycle_observatory.sections.error'), {
            variant: 'error',
          });
        }
      }
    }

    return () => {
      setCurrentPartner(undefined);
      getPartnerSections(undefined);
    };
  }, [partners, partnerCode]);

  async function signOut() {
    await AuthService.signOut();
    setCurrentUser(null);
  }

  return { loading: partners === undefined, currentPartner, partnerToRedirect };
}

export default usePartner;
