import { Counter } from '@geovelo-frontends/commons';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { Box, Chip, IconButton, Tooltip, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { TStats } from '../components/dual-stats';
import { IPeriodFormProps } from '../components/form/period';
import CounterIcon from '../components/icons/counter';
import VirtualCounterIcon from '../components/icons/virtual-counter';
import { TRow } from '../components/table';

import usePaginatedTable from './table/paginated';
import useSortableTable from './table/sortable';

function useCountersTable<TKey extends string>({
  period: {
    values: { current: currentPeriod },
  },
  counters,
  defaultOrderBy,
  stats,
  virtualCountersStats,
  onRemove,
  onEdit,
}: {
  counters?: Counter[];
  defaultOrderBy: TKey;
  onEdit: (counter: Counter) => void;
  onRemove: (index: number) => void;
  period: IPeriodFormProps;
  stats?: TStats[];
  virtualCountersStats?: TStats[];
}) {
  const [rows, setRows] = useState<TRow<string, TKey>[] | undefined>();
  const { t } = useTranslation();
  const { page, rowsPerPage, setPage, onPageChange, onRowsPerPageChange } = usePaginatedTable(10);
  const { orderBy, order, sort, onSortRequest } = useSortableTable<string, TKey>(
    defaultOrderBy,
    'desc',
    {
      setPage,
    },
  );
  const theme = useTheme();

  useEffect(() => {
    if (counters && stats && virtualCountersStats) {
      const data = counters.map(parseCounter);
      const _rows = sort(data, 'name' as TKey);
      setRows(_rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    }

    return () => setRows(undefined);
  }, [counters, stats, virtualCountersStats, orderBy, order, page, rowsPerPage]);

  function parseCounter(counter: Counter) {
    const { id, title, isVirtual } = counter;
    const counterStats = (isVirtual ? virtualCountersStats : stats)?.find(
      (stat) => stat?.id === id && stat,
    );
    const traffic = counterStats?.current.count;
    const progression = counterStats?.progression;

    return {
      key: `${isVirtual ? 'virtual' : 'real'}_${id}`,
      cells: {
        type: {
          value: isVirtual,
          format: (virtual: boolean) => {
            return (
              <Tooltip
                title={
                  <Trans
                    i18nKey={`cycling-insights.usage.point_attendance.form.types.${
                      virtual ? 'virtual' : 'real'
                    }`}
                  />
                }
              >
                <span>{virtual ? <VirtualCounterIcon /> : <CounterIcon />}</span>
              </Tooltip>
            );
          },
        },
        name: {
          value: title || t('cycling-insights.usage.point_attendance.default_title', { id }),
        },
        traffic: {
          value: traffic !== undefined ? traffic : '-',
          format: (value: number | string) => {
            return typeof value === 'number'
              ? `${value} (${Math.round(value / currentPeriod.nbSpentDays)})`
              : value;
          },
        },
        progression: {
          value: progression !== undefined ? progression : '-',
          format: (value: number | string) => {
            return typeof value === 'number' ? (
              <Chip
                label={`${value < 0 ? '- ' : '+ '}${Math.abs(value)} %`}
                sx={
                  value < 0
                    ? { backgroundColor: '#FFEBEE', color: '#A42C49' }
                    : { backgroundColor: '#EEF8F4', color: '#038B63' }
                }
              />
            ) : (
              value
            );
          },
        },
        actions: {
          value: 'actions',
          format: () => {
            return (
              isVirtual && (
                <Box display="flex" gap={2}>
                  <Tooltip placement="left" title={<Trans i18nKey="commons.actions.edit" />}>
                    <IconButton
                      onClick={() => onEdit(counter)}
                      size="small"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      <EditOutlined />
                    </IconButton>
                  </Tooltip>
                  <Tooltip placement="left" title={<Trans i18nKey="commons.actions.remove" />}>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        onRemove(id);
                      }}
                      size="small"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Tooltip>
                </Box>
              )
            );
          },
        },
      },
    };
  }

  return {
    orderBy,
    order,
    page,
    rowsPerPage,
    rows,
    onPageChange,
    onRowsPerPageChange,
    onSortRequest,
  };
}

export default useCountersTable;
