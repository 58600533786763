const doc = {
  request: {
    data: [
      {
        method: 'POST',
        url: 'https://backend.geovelo.fr/api/v2/computedroutes',
      },
    ],
    parameters: {
      query: [
        {
          key: 'light',
          keyIsOptional: true,
          type: 'boolean',
          values: ['true', 'false'],
          defaultValueIndex: 1,
          description: 'If true, ignore all other query params. The result will be simplified.',
        },
        {
          key: 'instructions',
          keyIsOptional: true,
          type: 'boolean',
          values: ['true', 'false'],
          defaultValueIndex: 1,
          description: 'Include the instructions in the response',
        },
        {
          key: 'elevations',
          keyIsOptional: true,
          type: 'boolean',
          values: ['true', 'false'],
          defaultValueIndex: 1,
          description: 'Include the elevations in the response',
        },
        {
          key: 'geometry',
          keyIsOptional: true,
          type: 'boolean',
          values: ['true', 'false'],
          defaultValueIndex: 1,
          description: 'Include the geometry in the response',
        },
        {
          key: 'single_result',
          keyIsOptional: true,
          type: 'boolean',
          values: ['true', 'false'],
          defaultValueIndex: 1,
          description: 'Expect only one route in the response instead of an array of route',
        },
        {
          key: 'bike_stations',
          keyIsOptional: true,
          type: 'boolean',
          values: ['true', 'false'],
          defaultValueIndex: 0,
          description: 'Include the bike stations near the start / end',
        },
        {
          key: 'objects_as_ids',
          keyIsOptional: true,
          type: 'boolean',
          values: ['true', 'false'],
          defaultValueIndex: 0,
          description:
            'If true, returns only the ids of the objects. If false, returns the full objects.',
        },
        {
          key: 'merge_instructions',
          keyIsOptional: true,
          type: 'boolean',
          values: ['false', 'true'],
          defaultValueIndex: 0,
          description:
            'If true, returns merge go straight forward instructions (This removes all instructions like "continue on road ..." due to intersections)',
        },
        {
          key: 'show_pushing_bike_instructions',
          keyIsOptional: true,
          type: 'boolean',
          values: ['false', 'true'],
          defaultValueIndex: 0,
          description:
            '<span>If true, it enable 2 news instructions to appear in instructions list : <b>GET_OFF_THE_BIKE</b> and <b>GET_ON_THE_BIKE</b> when the cyclist has to push its bike.</span>',
        },
      ],
      header: [
        {
          key: 'Content-Type',
          keyIsOptional: false,
          value: 'application/json',
        },
        {
          key: 'Source',
          keyIsOptional: true,
          value: 'The source name given by Geovelo (when given)',
        },
        {
          key: 'Api-Key',
          keyIsOptional: false,
          value: 'The API key given by Geovelo',
        },
      ],
      body: {
        data: [
          {
            key: 'datetimeOfArrival',
            keyIsOptional: true,
            type: 'string',
            values: null,
            defaultValueIndex: null,
            description: 'Preferred datetime of arrival (format:Y-M-DTHH:MM:SS.zzz)',
          },
          {
            key: 'datetimeOfDeparture',
            keyIsOptional: true,
            type: 'string',
            values: null,
            defaultValueIndex: null,
            description: 'Prefered datetime of departure (format:Y-M-DTHH:MM:SS.zzz)',
          },
          {
            key: 'waypoints',
            keyIsOptional: false,
            type: 'Array<TWayPoint>',
            values: null,
            defaultValueIndex: null,
            description: 'Ordered list of waypoints (see object description bellow)',
          },
          {
            key: 'bikeDetails',
            keyIsOptional: true,
            type: 'TBikeDetails',
            values: null,
            defaultValueIndex: null,
            description: 'See object description bellow',
          },
          {
            key: 'transportModes',
            keyIsOptional: true,
            type: "Array<'BIKE' | 'PEDESTRIAN'>",
            values: ['BIKE', 'PEDESTRIAN'],
            defaultValueIndex: 0,
            description: 'Allowed transport modes',
          },
        ],
        objects: {
          wayPoints: [
            { key: 'latitude', type: 'number' },
            { key: 'longitude', type: 'number' },
            { key: 'title', type: 'string' },
          ],
          bikeDetails: [
            {
              key: 'profile',
              keyIsOptional: true,
              type: 'string',
              values: ['BEGINNER', 'MEDIAN', 'EXPERT', 'VTC', 'CARGO'],
              defaultValueIndex: 1,
              description:
                'The bike profile to use to compute the itinerary. It sets the average speed accordingly',
            },
            {
              key: 'bikeType',
              keyIsOptional: true,
              type: 'string',
              values: ['TRADITIONAL', 'BSS'],
              defaultValueIndex: 1,
              description: 'The type of bike of the user',
            },
            {
              key: 'averageSpeed',
              keyIsOptional: true,
              type: 'number',
              values: [
                '13 km/h for BEGINNER',
                '16 km/h for MEDIAN',
                '20 km/h for EXPERT',
                '20 km/h for VTC',
                '15 km/h for CARGO',
              ],
              defaultValueIndex: 0,
              description: 'The average speed value must be between 5 and 45 km/h',
            },
            {
              key: 'bikeStations',
              keyIsOptional: true,
              type: 'Array<TBikeStation>',
              values: null,
              defaultValueIndex: null,
              description: 'Bike stations parameters',
            },
            {
              key: 'eBike',
              keyIsOptional: true,
              type: 'boolean',
              values: ['true', 'false'],
              defaultValueIndex: 1,
              description: 'Use an electric bike',
            },
          ],
          bikeStations: [
            {
              key: 'from',
              keyIsOptional: true,
              type: 'number',
              values: null,
              defaultValueIndex: null,
              description: 'The id of start bike station of departure',
            },
            {
              key: 'to',
              keyIsOptional: true,
              type: 'number',
              values: null,
              defaultValueIndex: null,
              description: 'The id of the bike station of arrival',
            },
          ],
        },
      },
    },
    example: {
      url: 'https://backend.geovelo.fr/api/v2/computedroutes?instructions=false&elevations=false&geometry=true&singleResult=false',
      body_parameters: {
        waypoints: [
          {
            latitude: 48.84102,
            longitude: 2.320378,
            title: 'Gare Montparnasse',
          },
          {
            latitude: 48.858214,
            longitude: 2.292516,
            title: 'Tour Eiffel',
          },
        ],
        bikeDetails: {
          profile: 'MEDIAN',
          bikeType: 'TRADITIONAL',
          averageSpeed: 15,
          bikeStations: {
            to: 2894,
            from: 3156,
          },
        },
        transportModes: ['BIKE', 'PEDESTRIAN'],
      },
    },
  },
  response: {
    data: [
      {
        status: '200 Ok',
        description: 'Returns an array of routes',
      },
      {
        status: '400 Bad Request',
        description: 'Parameters sent are not valid',
      },
    ],
    objects: {
      route: [
        {
          key: 'id',
          type: 'string',
          values: null,
          description: 'The route id (can be used to retrieve more details)',
        },
        {
          key: 'title',
          type: 'string',
          values: ['RECOMMENDED', 'SAFER', 'FASTER', 'BIS'],
          description: 'An enum based title that the client has to localize',
        },
        {
          key: 'distances',
          type: 'TDistance',
          values: null,
          description: 'Contains all computed distances',
        },
        {
          key: 'duration',
          type: 'number',
          values: null,
          description: 'The duration in seconds',
        },
        {
          key: 'waypoints',
          type: 'Array<TWayPoint>',
          values: null,
          description:
            'An ordered list of the points of the itinerary (start, step1, step2, ..., end)',
        },
        {
          key: 'estimatedDatetimeOfDeparture',
          type: 'string',
          values: null,
          description: 'Estimated datetime of departure',
        },
        {
          key: 'estimatedDatetimeOfArrival',
          type: 'string',
          values: null,
          description: 'Estimated datetime of arrival',
        },
        {
          key: 'sections',
          type: 'Array<TSection>',
          values: null,
          description: 'Contains all sections of the route',
        },
        {
          key: 'initialWaypoints',
          type: 'Array<TWayPoint>',
          values: null,
          description:
            'An optional ordered list of the points of the initial itinerary (start, step1, step2, ..., end) in case of deviation',
        },
        {
          key: 'deviationType',
          type: 'string',
          values: ['ToDedicatedParking', 'ToNearest'],
          description: 'In case of deviation, indicate the reason of the deviation',
        },
      ],
      distances: [
        {
          key: 'normalRoads',
          type: 'number',
          description: 'Distance of normal roads in meters',
        },
        {
          key: 'recommendedRoads',
          type: 'number',
          description: 'Distance of recommended roads in meters',
        },
        {
          key: 'discouragedRoads',
          type: 'number',
          description: 'Distance of discouraged roads in meters',
        },
        {
          key: 'total',
          type: 'number',
          description: 'Distance of all roads in meters',
        },
      ],
      waypoint: [
        {
          key: 'latitude',
          type: 'number',
          description: 'Latitude of the point',
        },
        {
          key: 'longitude',
          type: 'number',
          description: 'Longitude of the point',
        },
        {
          key: 'title',
          type: 'string',
          description:
            'Title of the point (usually the address or some client-specific string : WORK, HOME, etc)',
        },
      ],
      sections: [
        {
          key: 'duration',
          type: 'number',
          values: null,
          description: 'The duration in seconds',
        },
        {
          key: 'waypoints',
          type: 'Array<TWayPoint>',
          values: null,
          description: 'Ordered list of waypoints',
        },
        {
          key: 'estimatedDatetimeOfDeparture',
          type: 'string',
          values: null,
          description: 'Estimated datetime of departure (format:Y-M-DTHH:MM:SS.zzz)',
        },
        {
          key: 'estimatedDatetimeOfArrival',
          type: 'string',
          values: null,
          description: 'Estimated datetime of arrival (format:Y-M-DTHH:MM:SS.zzz)',
        },
        {
          key: 'geometry',
          type: 'TGeometry',
          values: null,
          description: 'The encoded geometry of the section',
        },
        {
          key: 'waypointsIndices',
          type: 'number[]',
          values: null,
          description: 'Waypoints indices in the geometry',
        },
        {
          key: 'transportMode',
          type: 'string',
          values: ['BIKE', 'PEDESTRIAN'],
          defaultValueIndex: 0,
          description: 'Transport mode of the section',
        },
        {
          key: 'details',
          type: 'TBikeDetails | TPedestrianDetails',
          values: null,
          description:
            'The details of the section. This object can either be a bikeDetails object or a pedestrianDetails object depending on the transport mode of the section',
        },
        {
          key: 'initialWaypoints',
          type: 'Array<TWayPoint>',
          values: null,
          description:
            'An optional ordered list of the points of the initial itinerary (start, step1, step2, ..., end) in case of deviation',
        },
        {
          key: 'deviationType',
          type: 'string',
          values: ['ToDedicatedParking', 'ToNearest'],
          description: 'In case of deviation, indicate the reason of the deviation',
        },
      ],
      details_bike: [
        {
          key: 'profile',
          type: 'string',
          description: 'Route profile for the section',
        },
        {
          key: 'distances',
          type: 'TDistances',
          description: 'Contains all computed distances for the section',
        },
        {
          key: 'electric_adapted_itinerary',
          type: 'boolean',
          description: 'True if the itinerary has been calculated of an electric bike, else False',
        },
        {
          key: 'averageSpeed',
          type: 'number',
          description: 'average speed for the section',
        },
        {
          key: 'direction',
          type: 'string',
          description: 'Main direction of the section',
        },
        {
          key: 'bikeType',
          type: 'string',
          description: 'Bike type of the section',
        },
        {
          key: 'verticalGain',
          type: 'number',
          description: 'Vertical gain (positive elevation) for the section',
        },
        {
          key: 'instructions',
          type: 'Array<TInstruction>',
          description: 'Contains all instructions',
        },
        {
          key: 'elevations',
          type: 'Array<TElevation>',
          description: 'Contains all elevations',
        },
        {
          key: 'stepIndices',
          type: 'number[]',
          description: 'Indices of itinerary steps in the geometry',
        },
        {
          key: 'bikeStations',
          type: 'TBikeStations',
          description: 'List of bike stations on the section',
        },
      ],
      details_pedestrian: [
        {
          key: 'distances',
          type: 'TDistances',
          description: 'Contains all computed distances for the section',
        },
        {
          key: 'averageSpeed',
          type: 'number',
          description: 'average speed for the section',
        },
        {
          key: 'direction',
          type: 'string',
          description: 'Main direction of the section',
        },
        {
          key: 'verticalGain',
          type: 'number',
          description: 'Vertical gain (positive elevation) for the section',
        },
        {
          key: 'instructions',
          type: 'Array<TInstruction>',
          description: 'Contains all instructions',
        },
        {
          key: 'elevations',
          type: 'Array<TElevation>',
          description: 'Contains all elevations',
        },
        {
          key: 'stepIndices',
          type: 'number[]',
          description: 'Indices of itinerary steps in the geometry',
        },
      ],
      instruction: [
        {
          key: 'direction',
          type: 'string',
          values: [
            'CROSSING',
            'ELEVATOR',
            'ENTER_AGAINST_ALLOWED_DIRECTION',
            'ENTER_ROUND_ABOUT',
            "<span class='new'>GET_OFF_THE_BIKE</span>",
            "<span class='new'>GET_ON_THE_BIKE</span>",
            'GO_STRAIGHT',
            'HEAD_ON',
            'LEAVE_AGAINST_ALLOWED_DIRECTION',
            'LEAVE_ROUND_ABOUT',
            'STAY_ON_ROUND_ABOUT',
            'ROUND_ABOUT_EXIT_1',
            'ROUND_ABOUT_EXIT_2',
            'ROUND_ABOUT_EXIT_3',
            'ROUND_ABOUT_EXIT_4',
            'ROUND_ABOUT_EXIT_5',
            'ROUND_ABOUT_EXIT_6',
            'ROUND_ABOUT_EXIT_7',
            'ROUND_ABOUT_EXIT_8',
            'ROUND_ABOUT_EXIT_9',
            'START_AT_END_OF_STREET',
            'TAKE_SHARED_BIKE',
            'DROP_SHARED_BIKE',
            'TAKE_PUBLIC_TRANSPORT',
            'LEAVE_PUBLIC_TRANSPORT',
            'TURN_SLIGHT_RIGHT',
            'TURN_RIGHT',
            'TURN_SHARP_RIGHT',
            'TURN_SHARP_LEFT',
            'TURN_LEFT',
            'TURN_SLIGHT_LEFT',
            'REACHED_YOUR_DESTINATION',
            'REACH_VIA_LOCATION',
            'U_TURN',
            'UNKNOWN',
          ],
          description: 'Instruction to follow (turn left, continue, etc.)',
        },
        {
          key: 'roadName',
          type: 'string',
          values: null,
          description: 'Name of the road to which the instruction leads',
        },
        {
          key: 'roadLength',
          type: 'number',
          values: null,
          description: 'Length of the road in meters',
        },
        {
          key: 'facility',
          type: 'string',
          values: [
            'CYCLEWAY',
            'LANE',
            'GREENWAY',
            'OPPOSITE',
            'SHAREBUSWAY',
            'PEDESTRIAN',
            'FOOTWAY',
            'LIVINGSTREET',
            'ZONE30',
            'STEPS',
            'FERRY',
            'NONE',
            'PRIMARY',
            'SECONDARY',
            'TERTIARY',
            'RESIDENTIAL',
          ],
          description: 'Type of the road facililty',
        },
        {
          key: 'cyclability',
          type: 'number',
          values: null,
          description: 'Cyclability rating of the road (between 1 - 5)',
        },
        {
          key: 'geometryIndex',
          type: 'number',
          values: null,
          description: 'Index of the related point in the geometry field',
        },
        {
          key: 'orientation',
          type: 'string',
          values: ['E', 'N', 'NE', 'NW', 'S', 'SE', 'SW', 'W'],
          description: 'Orientation to take to follow the instruction',
        },
      ],
      geometry: [
        {
          key: 'latitude',
          type: 'number',
          description: 'Latitude of the point',
        },
        {
          key: 'longitude',
          type: 'number',
          description: 'Longitude of the point',
        },
      ],
      elevation: [
        {
          key: 'distanceFromStart',
          type: 'number',
          description: 'Total distance from section start',
        },
        {
          key: 'elevation',
          type: 'number',
          description: 'z-index on earth',
        },
        {
          key: 'geometryIndex',
          type: 'number',
          description: 'Index of the elevation in the section geometry',
        },
      ],
      BikeStations: [
        {
          key: 'to',
          type: 'TNearBikeStations',
          description: 'The TNearBikeStations related to the itinerary start',
        },
        {
          key: 'from',
          type: 'TNearBikeStations',
          description: 'The TNearBikeStations related to the itinerary end',
        },
      ],
      NearBikeStations: [
        {
          key: 'near',
          type: 'number[]',
          description: 'The list of BikeStations objects related to the itinerary start / end',
        },
        {
          key: 'selected',
          type: 'number',
          description: 'The id of the bike station used by this itinerary',
        },
      ],
    },
    examples: {
      instructions_format: [
        ['field name #1', 'field name #2', '...'],
        ['instruction #1 - value of field #1', 'instruction #1 - value of field #2', '...'],
        ['instruction #2 - value of field #1', 'instruction #2 - value of field #2', '...'],
        '...',
      ],
      instructions_example: [
        [
          'direction',
          'roadName',
          'roadLength',
          'facility',
          'cyclability',
          'geometryIndex',
          'orientation',
          'cityNames',
        ],
        ['HEAD_ON', 'Rue de Sambre et Meuse', 48, 'NONE', 3, 0, 'SE', 'Paris'],
        ['...'],
        ['REACHED_YOUR_DESTINATION', '', 0, 'NONE', 3, 12, 'N', ''],
      ],
      response: {
        url: 'https://backend.geovelo.fr/api/v2/computedroutes?instructions=true&elevations=false&geometry=true&single_result=false&bike_stations=true&objects_as_ids=false',
        body: [
          {
            distances: {
              normalRoads: 2044,
              recommendedRoads: 838,
              total: 2882,
              discouragedRoads: 0,
            },
            estimatedDatetimeOfArrival: '2016-09-12T18:23:29.127',
            title: 'RECOMMENDED',
            duration: 858,
            sections: [
              {
                estimatedDatetimeOfArrival: '2016-09-12T18:10:17.127',
                geometry: 'aeif|AudtnCwAuF^[xJmIoKoCgQiH',
                waypointsIndices: [0, 6],
                details: {
                  profile: 'WALKING',
                  distances: {
                    opposite: 0,
                    normalRoads: 93,
                    recommendedRoads: 0,
                    discouragedRoads: 0,
                    greenway: 0,
                    total: 93,
                    lane: 0,
                    footway: 10,
                    livingstreet: 0,
                    pedestrian: 57,
                    residential: 0,
                    cycleway: 0,
                    steps: 0,
                    zone30: 0,
                    sharebusway: 0,
                  },
                  direction: 'Boulevard de Magenta',
                  title: 'RECOMMENDED',
                  verticalGain: 0,
                  elevations: [
                    ['distanceFromStart', 'elevation', 'geometryIndex'],
                    [0, 61.497202, 0],
                    ['...'],
                    [93, 61.912394, 5],
                  ],
                  instructions: [
                    [
                      'direction',
                      'roadName',
                      'roadLength',
                      'facility',
                      'cyclability',
                      'geometryIndex',
                      'orientation',
                      'cityNames',
                    ],
                    ['HEAD_ON', 'voie piétonne', 10, 'FOOTWAY', 4, 0, 'NE', 'Paris'],
                    ['...'],
                    ['REACHED_YOUR_DESTINATION', '', 0, 'NONE', 3, 5, 'N', ''],
                  ],
                },
                duration: 66,
                transportMode: 'PEDESTRIAN',
                id: 'bG9jPTQ4Ljg3ODc0Mzc5ODUsMi4zNTMzOTE2NDczNCZsb2M9NDguODc5MDQ5MzEwOCwyLjM1NDE1OTA5NTk2I1dBTEtJTkcjRmFsc2UjV0FMS0lORyM1',
                estimatedDatetimeOfDeparture: '2016-09-12T18:09:11.127',
                waypoints: [
                  {
                    latitude: 48.87874379854197,
                    longitude: 2.3533916473388676,
                    title: null,
                  },
                  {
                    latitude: 48.87904931076023,
                    longitude: 2.354159095958248,
                    title: '10152 - GARE DU NORD 2',
                  },
                ],
              },
              {
                estimatedDatetimeOfArrival: '2016-09-12T18:20:25.127',
                geometry:
                  'yyif|AoeunC{q@cYtDobAWyInFx@rUpDtJzA`Fv@`Er@te@nIbBXpBZbk@|IbBbErCdH~AzDpi@yd@lDuBhO_NjD}CxKyJ~IcIlIsHp^{\\dRwN~DuDjBqBxEkEzh@md@xHwG~AqAhGgFzMeLrM_LqA_FgAoEaDrCo@eCt@sh@j@}`@j@igA@wDB}I^qiBtAgArAw@bSnF~AkHsLqQuEqHqHuLgBqC_]sk@mv@wuAeQs[_IwNsFsJ{M{UmHsM}W{]gAwAmHsIXwIAyzAAcH?yHEueBjw@q_AlSaVdi@on@w@hDaDbPaApE',
                waypointsIndices: [0, 73],
                details: {
                  profile: 'MEDIAN',
                  distances: {
                    opposite: 0,
                    normalRoads: 1695,
                    recommendedRoads: 838,
                    discouragedRoads: 0,
                    greenway: 0,
                    total: 2533,
                    lane: 0,
                    footway: 0,
                    livingstreet: 0,
                    pedestrian: 188,
                    residential: 0,
                    cycleway: 838,
                    steps: 0,
                    zone30: 1007,
                    sharebusway: 0,
                  },
                  direction: 'Boulevard de Magenta',
                  bikeType: 'BSS',
                  bikeStations: {
                    to: {
                      near: [794],
                      selected: 794,
                    },
                    from: {
                      near: [501],
                      selected: 501,
                    },
                  },
                  verticalGain: 19,
                  averageSpeed: 15,
                  elevations: [
                    ['distanceFromStart', 'elevation', 'geometryIndex'],
                    [0, 61.912394, 0],
                    ['...'],
                    [2534, 63.317868, 72],
                  ],
                  instructions: [
                    [
                      'direction',
                      'roadName',
                      'roadLength',
                      'facility',
                      'cyclability',
                      'geometryIndex',
                      'orientation',
                      'cityNames',
                    ],
                    ['TAKE_SHARED_BIKE', '', 0, 'NONE', 0, 0, 'N', ''],
                    ['...'],
                    ['DROP_SHARED_BIKE', '', 0, 'NONE', 0, 72, 'N', ''],
                  ],
                },
                duration: 608,
                transportMode: 'BIKE',
                estimatedDatetimeOfDeparture: '2016-09-12T18:10:17.127',
                waypoints: [
                  {
                    latitude: 48.87904931076023,
                    longitude: 2.354159095958248,
                    title: '10152 - GARE DU NORD 2',
                  },
                  {
                    latitude: 48.87440566621717,
                    longitude: 2.373807237995119,
                    title: '10039 - SAMBRE ET MEUSE',
                  },
                ],
              },
              {
                estimatedDatetimeOfArrival: '2016-09-12T18:23:29.127',
                geometry: 'iw`f|Aih{oCdAqE`DcPv@iDrAyFuMzOk]`b@wJ{EqBy@mDmBiLyFaFyBiNkG',
                waypointsIndices: [0, 13],
                details: {
                  profile: 'WALKING',
                  distances: {
                    opposite: 0,
                    normalRoads: 256,
                    recommendedRoads: 0,
                    discouragedRoads: 0,
                    greenway: 0,
                    total: 256,
                    lane: 0,
                    footway: 0,
                    livingstreet: 0,
                    pedestrian: 0,
                    residential: 0,
                    cycleway: 0,
                    steps: 0,
                    zone30: 0,
                    sharebusway: 0,
                  },
                  direction: 'Boulevard de la Villette',
                  title: 'RECOMMENDED',
                  verticalGain: 4,
                  elevations: [
                    ['distanceFromStart', 'elevation', 'geometryIndex'],
                    [0, 63.315181, 0],
                    ['...'],
                    [256, 70.925203, 12],
                  ],
                  instructions: [
                    [
                      'direction',
                      'roadName',
                      'roadLength',
                      'facility',
                      'cyclability',
                      'geometryIndex',
                      'orientation',
                      'cityNames',
                    ],
                    ['HEAD_ON', 'Rue de Sambre et Meuse', 48, 'NONE', 3, 0, 'SE', 'Paris'],
                    ['...'],
                    ['REACHED_YOUR_DESTINATION', '', 0, 'NONE', 3, 12, 'N', ''],
                  ],
                },
                duration: 184,
                transportMode: 'PEDESTRIAN',
                id: 'bG9jPTQ4Ljg3NDQwNTY2NjIsMi4zNzM4MDcyMzgmbG9jPTQ4Ljg3NTYzOTExOTMsMi4zNzQ3NjM0ODg3NyNXQUxLSU5HI0ZhbHNlI1dBTEtJTkcjNQ==',
                estimatedDatetimeOfDeparture: '2016-09-12T18:20:25.127',
                waypoints: [
                  {
                    latitude: 48.87440566621717,
                    longitude: 2.373807237995119,
                    title: '10039 - SAMBRE ET MEUSE',
                  },
                  {
                    latitude: 48.87563911932326,
                    longitude: 2.3747634887695317,
                    title: null,
                  },
                ],
              },
            ],
            waypoints: [
              {
                latitude: 48.87874379854197,
                longitude: 2.3533916473388676,
                title: null,
              },
              {
                latitude: 48.87563911932326,
                longitude: 2.3747634887695317,
                title: null,
              },
            ],
            estimatedDatetimeOfDeparture: '2016-09-12T18:09:11.127',
            id: 'bG9jPTQ4Ljg3ODc0Mzc5ODUsMi4zNTMzOTE2NDczNCZsb2M9NDguODc1NjM5MTE5MywyLjM3NDc2MzQ4ODc3I01FRElBTiNGYWxzZSNNRURJQU4jMTUjRmFsc2UjRmFsc2UjMjAxNi0wOS0xMiAxODoxMDoxNy4xMjc5MzYjQlNTIzAjMCNSRUNPTU1FTkRFRCNUcnVl',
          },
        ],
      },
    },
  },
};

export default doc;
