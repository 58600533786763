import {
  GeogroupNewsPost,
  GeogroupService,
  NewsCustomForm,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import { environment } from '../../../../environment';
import { closedDrawerWidth } from '../../../../layouts/default/drawer';

function NewsUpdate(): JSX.Element {
  const {
    user: { current: currentUser },
    partner: { current: currentPartner, currentGeogroup },
  } = useContext(AppContext);
  const [newsPost, setNewsPost] = useState<GeogroupNewsPost>();
  const { t } = useTranslation();
  const { id: _id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    if (_id) getNewsPost(parseInt(_id));

    return () => cancelPromises();
  }, [currentPartner, _id]);

  async function getNewsPost(id: number) {
    if (!currentPartner || !currentGeogroup) return;

    try {
      const newsPost = await cancellablePromise(
        GeogroupService.getNewsPost({
          partner: currentPartner,
          geoGroupId: currentGeogroup.id,
          newsPostId: id,
        }),
      );

      setNewsPost(newsPost);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('commons.news_form.server_error_retrieval'), {
          variant: 'error',
        });
        navigate(`/${currentPartner?.code}/promotion/animation`);
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {t('cycling-insights.app_title')}&nbsp;|&nbsp;
          {t('cycling-insights.community.navigation.news')}
        </title>
        <meta content={t('commons.navigation.community_description') || ''} name="description" />
      </Helmet>
      <Box
        bgcolor="#F6F7FB"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        gap={5}
        marginBottom="58px"
        paddingX={14}
        paddingY={8}
        sx={{ overflowY: 'auto' }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography
            color="primary"
            component="h2"
            flexDirection="column"
            fontSize="2rem"
            fontWeight="700"
          >
            <Trans i18nKey="commons.news_form.title_update" />
          </Typography>
        </Box>
        <NewsCustomForm
          backendUrl={environment.backendUrl}
          currentPartner={currentPartner}
          currentUser={currentUser}
          drawerWidth={closedDrawerWidth}
          geogroup={currentGeogroup}
          newsPost={newsPost}
          prevPath={currentPartner ? `/${currentPartner.code}/promotion/animation` : '/'}
          redirectUrl={currentPartner ? `/${currentPartner.code}/promotion/news-feed` : '/'}
          userType={'admin'}
        />
      </Box>
    </>
  );
}

export default NewsUpdate;
