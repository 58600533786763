import { BikeRoute, BikeRouteService, IPhoto } from '@geovelo-frontends/commons';
import { AddCircleOutline, Delete } from '@mui/icons-material';
import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Card from '../../../components/card';

import ImageFormDialog from './image-form-dialog';

interface IProps {
  bikeRoute: BikeRoute;
  canWrite: boolean;
  onChange: (bikeRoute: BikeRoute) => void;
}

function ImageList({ canWrite, bikeRoute, onChange }: IProps): JSX.Element {
  const [photoToUpdate, setPhotoToUpdate] = useState<IPhoto | null>(null);
  const [formDialogOpen, openFormDialog] = useState(false);
  const { t } = useTranslation();
  const { transitions } = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  function handleFormDialogClose(photo?: IPhoto) {
    if (photo) {
      if (photoToUpdate) {
        const photos = [...bikeRoute.photos];
        photos.splice(
          bikeRoute.photos.findIndex(({ id }) => id === photoToUpdate.id),
          1,
          photo,
        );

        onChange({ ...bikeRoute, photos });
      } else {
        onChange({ ...bikeRoute, photos: [...bikeRoute.photos, photo] });
      }
    }

    openFormDialog(false);
    setTimeout(() => setPhotoToUpdate(null), transitions.duration.leavingScreen);
  }

  async function handleRemove(id: number) {
    try {
      await BikeRouteService.removeBikeRoutePhoto(bikeRoute.id, id);

      const photos = [...bikeRoute.photos];
      photos.splice(
        bikeRoute.photos.findIndex((photo) => id === photo.id),
        1,
      );

      onChange({ ...bikeRoute, photos });
      enqueueSnackbar(t('cycling-insights.bike_route.updated'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('cycling-insights.bike_route.not_updated'), { variant: 'error' });
    }
  }

  return (
    <>
      <Card
        actions={
          canWrite
            ? [
                {
                  children: <Trans i18nKey="cycling-insights.bike_route.image_list.actions.add" />,
                  color: 'primary',
                  key: 'add',
                  onClick: () => openFormDialog(true),
                  startIcon: <AddCircleOutline />,
                  variant: 'contained',
                },
              ]
            : []
        }
        title={<Trans i18nKey="cycling-insights.bike_route.image_list.title" />}
      >
        {bikeRoute.photos.length > 0 ? (
          <List dense>
            {bikeRoute.photos.map((photo, index) => (
              <Fragment key={photo.id}>
                {index > 0 && <Divider />}
                {canWrite ? (
                  <ListItemButton
                    onClick={() => {
                      setPhotoToUpdate(photo);
                      openFormDialog(true);
                    }}
                  >
                    <ImageListItemContent
                      canWrite={canWrite}
                      handleRemove={handleRemove}
                      index={index}
                      photo={photo}
                    />
                  </ListItemButton>
                ) : (
                  <ListItem>
                    <ImageListItemContent
                      canWrite={canWrite}
                      handleRemove={handleRemove}
                      index={index}
                      photo={photo}
                    />
                  </ListItem>
                )}
              </Fragment>
            ))}
          </List>
        ) : (
          <Wrapper>
            <Typography color="textSecondary" variant="caption">
              <Trans i18nKey="cycling-insights.bike_route.image_list.empty" />
            </Typography>
          </Wrapper>
        )}
      </Card>
      <ImageFormDialog
        bikeRoute={bikeRoute}
        onClose={handleFormDialogClose}
        open={formDialogOpen}
        photo={photoToUpdate}
      />
    </>
  );
}

function ImageListItemContent({
  canWrite,
  index,
  photo: { id, title, copyright, squaredThumbnailUrl },
  handleRemove,
}: {
  canWrite: boolean;
  handleRemove: (id: number) => void;
  index: number;
  photo: IPhoto;
}): JSX.Element {
  return (
    <>
      <ListItemAvatar>
        <Avatar src={squaredThumbnailUrl} />
      </ListItemAvatar>
      <ListItemText
        primary={
          title || (
            <Trans
              i18nKey="cycling-insights.bike_route.image_list.item_title"
              values={{ index: index + 1 }}
            />
          )
        }
        secondary={copyright && <>&copy; {copyright}</>}
      />
      {canWrite && (
        <ListItemSecondaryAction>
          <Tooltip placement="left" title={<Trans i18nKey="commons.actions.remove" />}>
            <IconButton onClick={() => id && handleRemove(id)} size="small">
              <Delete color="error" />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      )}
    </>
  );
}

const Wrapper = styled.div`
  padding: 16px;
`;

export default ImageList;
