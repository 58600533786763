import { useOutletContext } from 'react-router-dom';

import PageContentLayout from '../../../../../layouts/page/content';
import { TCartographicDataPageContext } from '../../../context';

import FacilitiesStatsTable from './table';

function FacilitiesStatsTab(): JSX.Element {
  const context = useOutletContext<TCartographicDataPageContext>();

  return <PageContentLayout main={<FacilitiesStatsTable {...context} />} />;
}

export default FacilitiesStatsTab;
