import { useSource } from '@geovelo-frontends/commons';
import { useRef, useState } from 'react';

import arrowImage from '../../assets/images/map-arrow-black.png';
import {
  TColorCollection,
  TThicknessCollection,
  findIndexInIntervals,
  getColors,
} from '../../components/color-legend';
import { ISliderBounds, TSliderRange } from '../../components/form/slider';
import { TSectionFeatureCollection, TSectionProperties } from '../../models/sections';

import { stoppingAreasLayerId } from './stopping-areas';

import {
  DataDrivenPropertyValueSpecification,
  Map,
  Popup,
  SymbolLayerSpecification,
} from '!maplibre-gl';

export type THeatmapCriterion =
  | 'frequency'
  | 'averageSpeed'
  | 'roughness'
  | 'extrapolation'
  | 'cyclability'
  | 'discontinuity';

function useHeatmap(
  map: Map | null | undefined,
  {
    sourceId,
    layerId,
    colors: defaultColors,
    widths,
    interpolateWidths,
    primaryCriterion,
    secondaryCriterion,
    disableDirections,
  }: {
    colors: TColorCollection;
    interpolateWidths?: boolean;
    layerId: string;
    primaryCriterion?: THeatmapCriterion;
    secondaryCriterion?: THeatmapCriterion;
    sourceId: string;
    widths?: TThicknessCollection;
    disableDirections?: boolean;
  },
  getTooltipContent: (props: TSectionProperties) => string,
  onClick?: (section?: TSectionProperties) => void,
): {
  initialized: boolean;
  init: () => void;
  update: (
    collection: TSectionFeatureCollection,
    props?: {
      comparisonEnabled?: boolean;
      currentRange?: TSliderRange;
      primaryBounds?: ISliderBounds;
      secondaryBounds?: ISliderBounds;
    },
  ) => void;
  clear: () => void;
  destroy: () => void;
} {
  const [initialized, setInitialized] = useState(false);
  const initializedRef = useRef(false);
  const highlightedRoadTooltip = useRef<Popup>();
  const {
    addGeoJSONSource: addHeatmapSource,
    getGeoJSONSource: getHeatmapSource,
    updateGeoJSONSource: updateHeatmapSource,
    clearGeoJSONSource: clearHeatmapSource,
  } = useSource(map, sourceId);
  const { addGeoJSONSource: addHighlightedRoadSource, getGeoJSONSource: getHighlightedRoadSource } =
    useSource(map, `${sourceId}-highlight`);

  let hoveredRoadId: number | string | undefined;
  let hoveredRoadDirection: 'onward' | 'backward' | undefined;

  function init() {
    async function addArrowImage() {
      if (!map) return;

      try {
        const image = await map.loadImage(arrowImage);
        if (image && !map.hasImage('arrow')) map.addImage('arrow', image.data);
      } catch (err) {
        console.error('err image', err);
      }
    }

    if (!map || initializedRef.current) return;
    if (getHeatmapSource()) {
      initializedRef.current = true;
      setInitialized(true);
      return;
    }

    addHeatmapSource();
    addHighlightedRoadSource();

    highlightedRoadTooltip.current = new Popup({
      className: 'map-tooltip',
      closeButton: false,
    });

    map.addLayer(
      {
        id: layerId,
        type: 'line',
        source: sourceId,
        paint: {
          'line-width': interpolateWidths
            ? ([
                'interpolate',
                ['exponential', 2],
                ['zoom'],
                10,
                ['*', ['get', 'width'], ['^', 2, -2]],
                24,
                ['*', ['get', 'width'], ['^', 2, 8]],
              ] as DataDrivenPropertyValueSpecification<number>)
            : ['get', 'width'],
          'line-offset': interpolateWidths
            ? ([
                'interpolate',
                ['exponential', 2],
                ['zoom'],
                10,
                ['*', ['get', 'width'], ['^', 2, -2]],
                24,
                ['*', ['get', 'width'], ['^', 2, 8]],
              ] as DataDrivenPropertyValueSpecification<number>)
            : ([
                'interpolate',
                ['exponential', 0.5],
                ['zoom'],
                16,
                0,
                18,
                ['+', ['get', 'width'], 2],
              ] as DataDrivenPropertyValueSpecification<number>),
          'line-color': ['get', 'color'],
          'line-opacity': 0.8,
        },
      },
      'labels',
    );

    map.addLayer(
      {
        id: `${layerId}-highlight`,
        type: 'line',
        source: `${sourceId}-highlight`,
        paint: {
          'line-width': interpolateWidths
            ? ([
                'interpolate',
                ['exponential', 2],
                ['zoom'],
                10,
                ['*', ['get', 'width'], ['^', 2, -2]],
                24,
                ['*', ['get', 'width'], ['^', 2, 8]],
              ] as DataDrivenPropertyValueSpecification<number>)
            : ['get', 'width'],
          'line-offset': interpolateWidths
            ? ([
                'interpolate',
                ['exponential', 2],
                ['zoom'],
                10,
                ['*', ['get', 'width'], ['^', 2, -2]],
                24,
                ['*', ['get', 'width'], ['^', 2, 8]],
              ] as DataDrivenPropertyValueSpecification<number>)
            : ([
                'interpolate',
                ['exponential', 0.5],
                ['zoom'],
                16,
                0,
                18,
                ['+', ['get', 'width'], 2],
              ] as DataDrivenPropertyValueSpecification<number>),
          'line-color': '#ffeb3b',
          'line-opacity': 1,
        },
      },
      'labels',
    );

    if (!disableDirections) {
      const arrowLayout: SymbolLayerSpecification['layout'] = {
        'symbol-placement': 'line',
        'symbol-spacing': 100,
        'icon-allow-overlap': true,
        'icon-image': 'arrow',
        'icon-size': 1,
        visibility: 'none',
      };

      map?.addLayer({
        id: `${layerId}-highlight-arrows`,
        type: 'symbol',
        source: `${sourceId}-highlight`,
        layout: arrowLayout,
      });

      addArrowImage();
    }

    map.on('mousemove', layerId, ({ lngLat, features, point }) => {
      if (map.getZoom() < 14) return;

      if (
        map
          .queryRenderedFeatures(point)
          .find(({ layer: { id: layerId } }) => [stoppingAreasLayerId].indexOf(layerId) !== -1) ||
        !features ||
        !features[0]
      ) {
        map.getCanvas().style.cursor = '';
        clearHighlight();
        return;
      }

      if (features && features.length > 0) {
        const { geometry, properties } = features[0];

        highlightedRoadTooltip.current
          ?.setHTML(getTooltipContent(properties as TSectionProperties))
          .setLngLat(lngLat)
          .addTo(map);

        if (hoveredRoadId !== properties?.id || hoveredRoadDirection !== properties?.direction) {
          const highlightedRoadSource = getHighlightedRoadSource();
          if (!highlightedRoadSource) return;

          hoveredRoadId = properties?.id;
          hoveredRoadDirection = properties?.direction;
          if (hoveredRoadId) {
            map.getCanvas().style.cursor = 'pointer';

            highlightedRoadSource.setData({
              type: 'Feature',
              geometry,
              properties,
            });

            if (!disableDirections)
              map.setLayoutProperty(
                `${layerId}-highlight-arrows`,
                'icon-offset',
                interpolateWidths
                  ? ([
                      'interpolate',
                      ['exponential', 0.5],
                      ['zoom'],
                      16,
                      ['literal', [0, 5]],
                      24,
                      ['literal', [0, 35]],
                    ] as DataDrivenPropertyValueSpecification<number>)
                  : ([
                      'interpolate',
                      ['exponential', 0.5],
                      ['zoom'],
                      16,
                      ['literal', [0, properties?.width * 0.6]],
                      18,
                      ['literal', [0, properties?.width * 1.4]],
                    ] as DataDrivenPropertyValueSpecification<number>),
              );
          } else {
            clearHighlight();
          }
        }
      }
    });

    map.on('mouseleave', layerId, () => {
      map.getCanvas().style.cursor = '';
      clearHighlight();
    });

    map.on('click', `${layerId}-highlight`, (event) => {
      if (event.features) onClick?.(event.features[0].properties as TSectionProperties);
    });

    map.on('zoomend', () => {
      if (map.getZoom() >= 16) {
        if (!disableDirections)
          map.setLayoutProperty(`${layerId}-highlight-arrows`, 'visibility', 'visible');
      } else {
        if (!disableDirections)
          map.setLayoutProperty(`${layerId}-highlight-arrows`, 'visibility', 'none');
      }
    });

    initializedRef.current = true;
    setInitialized(true);
  }

  function update(
    { features }: TSectionFeatureCollection,
    {
      colors: customColors,
      comparisonEnabled,
      primaryBounds,
      secondaryBounds,
      currentRange,
    }: {
      colors?: TColorCollection;
      comparisonEnabled?: boolean;
      currentRange?: TSliderRange;
      primaryBounds?: ISliderBounds;
      secondaryBounds?: ISliderBounds;
    } = {},
  ) {
    if (!map) return;

    const _colors = customColors || defaultColors;
    const colors = getColors({ colors: _colors, bounds: primaryBounds });

    updateHeatmapSource({
      type: 'FeatureCollection',
      features: features
        .filter(({ properties }) => {
          if (!primaryCriterion || !currentRange) return true;

          if (primaryCriterion === 'discontinuity')
            return (
              properties.discontinuityIndex !== undefined &&
              properties.discontinuityIndex >= currentRange[0] &&
              properties.discontinuityIndex <= currentRange[1]
            );

          const value = properties[primaryCriterion];
          const secondaryValue =
            secondaryCriterion &&
            secondaryCriterion !== 'discontinuity' &&
            properties[secondaryCriterion];

          if (primaryCriterion === 'roughness') {
            const minRange =
              currentRange[0] === 1
                ? 2.5
                : currentRange[0] === 2
                  ? 2.75
                  : currentRange[0] === 3
                    ? 4
                    : 0;
            const maxRange =
              currentRange[1] === 1
                ? 2.5
                : currentRange[1] === 2
                  ? 2.75
                  : currentRange[1] === 3
                    ? 4
                    : 0;
            return (
              value &&
              value >= minRange &&
              (primaryBounds?.max === maxRange ? value <= maxRange : value < maxRange) &&
              (!secondaryCriterion ||
                !secondaryBounds ||
                ((secondaryValue || secondaryValue === 0) &&
                  secondaryValue >= secondaryBounds.min &&
                  secondaryValue <= secondaryBounds.max))
            );
          } else
            return (
              value &&
              value >= currentRange[0] &&
              (primaryBounds?.max === currentRange[1] || primaryCriterion === 'cyclability'
                ? value <= currentRange[1]
                : value < currentRange[1]) &&
              (!secondaryCriterion ||
                !secondaryBounds ||
                ((secondaryValue || secondaryValue === 0) &&
                  secondaryValue >= secondaryBounds.min &&
                  secondaryValue <= secondaryBounds.max))
            );
        })
        .map(({ properties, ...feature }) => {
          const primaryData =
            primaryCriterion && primaryCriterion !== 'discontinuity'
              ? properties[primaryCriterion]
              : null; // data used for color
          const secondaryData =
            secondaryCriterion && secondaryCriterion !== 'discontinuity'
              ? properties[secondaryCriterion]
              : null; // data used for width

          const colorIndex = primaryBounds
            ? findIndexInIntervals<string>(primaryData || 0, primaryBounds, colors)
            : 0;
          const widthIndex =
            widths && secondaryBounds && (secondaryData || secondaryData === 0)
              ? findIndexInIntervals<number>(
                  secondaryData,
                  secondaryBounds,
                  widths,
                  comparisonEnabled,
                )
              : null;

          return {
            ...feature,
            properties: {
              ...properties,
              color:
                properties.customColor ||
                (colorIndex !== null ? colors[colorIndex].value : undefined),
              width: widths
                ? widths[widthIndex !== null ? widthIndex : Math.floor(widths.length / 2)].value
                : primaryCriterion === 'discontinuity'
                  ? 7
                  : 5,
              labelKey: colorIndex !== null ? colors[colorIndex].labelKey : undefined,
            },
          };
        }),
    });
  }

  function clearHighlight() {
    highlightedRoadTooltip.current?.remove();

    const highlightedRoadSource = getHighlightedRoadSource();
    if (highlightedRoadSource) {
      highlightedRoadSource.setData({ type: 'FeatureCollection', features: [] });
    }

    hoveredRoadId = undefined;
    hoveredRoadDirection = undefined;
  }

  function clear() {
    clearHeatmapSource();
    clearHighlight();
  }

  function destroy() {
    clear();

    initializedRef.current = false;
    setInitialized(false);
  }

  return { initialized, init, update, clear, destroy };
}

export default useHeatmap;
