import {
  TColorScale,
  Trace,
  UserService,
  getSlopePercentages,
  toBounds,
  useCancellablePromise,
  useGeoveloMap,
  useTraces,
  useUnits,
} from '@geovelo-frontends/commons';
import { Search, TableRows } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import distance from '@turf/distance';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { TQAPageContext } from '../../context';

import ElevationsSpeedsChart from './elevations-speeds-chart';
import TraceToggle from './trace-toggle';

import { LngLatBounds } from '!maplibre-gl';

const mapId = 'trace-map';

function UsersLogsData({
  usersLogs: { selectedUser, logs, selectedTraceId },
}: TQAPageContext): JSX.Element {
  const [trace, setTrace] = useState<Trace | null>(null);
  const [rawTraceId, setRawTraceId] = useState<number | null>(null);
  const [distances, setDistances] = useState<number[]>();
  const [slopePercentages, setSlopePercentages] = useState<number[]>();
  const [colorScale, setColorScale] = useState<TColorScale>('elevations');
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { toDistance, toTime, toSpeed } = useUnits();
  const {
    initialized: traceMapInitialized,
    map: traceMap,
    init: initTraceMap,
    destroy: destroyTraceMap,
  } = useGeoveloMap({
    smallDevice: true,
    baseLayer: 'geovelo',
  });
  const {
    initialized: traceInitializedOnMap,
    init: initTraceOnMap,
    update: updateTraceOnMap,
    clear: clearTraceOnMap,
    destroy: destroyTraceOnMap,
  } = useTraces(traceMap);

  useEffect(() => {
    return () => cancelPromises();
  }, []);

  useEffect(() => {
    return () => {
      destroyTraceOnMap();
      destroyTraceMap();
    };
  }, [selectedUser]);

  useEffect(() => {
    clearTraceOnMap();
    getTrace();
  }, [selectedTraceId]);

  useEffect(() => {
    if (trace) {
      const {
        geometry: { coordinates },
        elevations,
      } = trace;
      let totalDistance = 0;
      const _distances = coordinates.slice(0, -1).map((position, index) => {
        totalDistance += distance(position, coordinates[index + 1], { units: 'meters' });

        return totalDistance;
      });
      _distances.unshift(0);

      setDistances(_distances);
      setSlopePercentages(getSlopePercentages(coordinates, elevations, _distances, 2));
    } else {
      setDistances(undefined);
      setSlopePercentages(undefined);
    }
  }, [trace]);

  useEffect(() => {
    if (traceMapInitialized) initTraceOnMap();
  }, [traceMapInitialized]);

  useEffect(() => {
    if (traceInitializedOnMap && traceMap && trace) {
      const { north, east, south, west } = toBounds(trace.geometry);
      const bounds = new LngLatBounds({ lat: south, lng: west }, { lat: north, lng: east });
      traceMap.fitBounds(bounds, {
        padding: { top: 84, right: 50, bottom: 50, left: 50 },
        animate: false,
      });
    }
  }, [traceInitializedOnMap, trace]);

  useEffect(() => {
    if (traceInitializedOnMap && trace && distances && slopePercentages) {
      updateTraceOnMap(trace, distances, slopePercentages, colorScale);
    }
  }, [traceInitializedOnMap, trace, distances, slopePercentages, colorScale]);

  async function getTrace() {
    cancelPromises();
    setTrace(null);
    setRawTraceId(null);

    if (!selectedUser || !selectedTraceId) return;

    initTraceMap({
      container: mapId,
      scrollZoom: true,
      customZoomControls: true,
      baseLayersControl: true,
    });

    try {
      const [trace, rawTraceId] = await cancellablePromise(
        Promise.all([
          UserService.getTrace(selectedUser.id, selectedTraceId),
          UserService.getRawTrace(selectedUser.id, selectedTraceId),
        ]),
      );

      setTrace(trace);
      setRawTraceId(rawTraceId);
    } catch {
      //
    }
  }

  if (!selectedUser)
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        justifyContent="center"
        padding={2}
      >
        <Search color="action" fontSize="large" />
        <Typography color="textSecondary" variant="h6">
          Recherchez un utilisateur
        </Typography>
      </Box>
    );

  return (
    <Box flexGrow={1} sx={{ overflowY: 'auto' }}>
      <Box display="flex" gap={2} padding={2}>
        {selectedTraceId ? (
          <Box display="flex" flexDirection="column" flexGrow={1} gap={2}>
            <Card variant="outlined">
              <CardHeader title="Analyse globale" />
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" width={200}>
                      Heure de début
                    </TableCell>
                    <TableCell>
                      {trace ? (
                        trace.startDate.format('LLL')
                      ) : (
                        <Skeleton variant="text" width={100} />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th">Heure de fin</TableCell>
                    <TableCell>
                      {trace ? (
                        trace.endDate.format('LLL')
                      ) : (
                        <Skeleton variant="text" width={100} />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th">Distance</TableCell>
                    <TableCell>
                      {trace ? toDistance(trace.distance) : <Skeleton variant="text" width={100} />}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th">Durée</TableCell>
                    <TableCell>
                      {trace ? toTime(trace.duration) : <Skeleton variant="text" width={100} />}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th">Vitesse moyenne</TableCell>
                    <TableCell>
                      {trace ? (
                        toSpeed(trace.averageSpeed)
                      ) : (
                        <Skeleton variant="text" width={100} />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th">Nombre de points</TableCell>
                    <TableCell>
                      {trace ? (
                        trace.geometry.coordinates.length
                      ) : (
                        <Skeleton variant="text" width={100} />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th">Erreurs détectées</TableCell>
                    <TableCell>
                      {trace ? (
                        trace.problems.map(({ problemType }, index) => (
                          <Chip color="error" key={index} label={problemType} size="small" />
                        ))
                      ) : (
                        <Skeleton
                          height={24}
                          sx={{ borderRadius: 4 }}
                          variant="rectangular"
                          width={100}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                  component="a"
                  disabled={!rawTraceId}
                  href={
                    rawTraceId
                      ? `https://backend-admin.geovelo.fr/admin/archive/rawusertrace/${rawTraceId}/`
                      : '/'
                  }
                  size="small"
                  target="_blank"
                  variant="outlined"
                >
                  Raw trace
                </Button>
                <Button
                  component="a"
                  disabled={!trace}
                  href={
                    trace
                      ? `https://backend-admin.geovelo.fr/admin/gvItinerary/usertrace/${trace.id}/`
                      : '/'
                  }
                  size="small"
                  target="_blank"
                  variant="outlined"
                >
                  User trace
                </Button>
                <Button
                  component="a"
                  disabled={!selectedUser}
                  href={`https://backend-admin.geovelo.fr/admin/gvUser/gvuser/${selectedUser.id}/`}
                  size="small"
                  target="_blank"
                  variant="outlined"
                >
                  User
                </Button>
              </CardActions>
            </Card>
            <Card variant="outlined">
              <CardHeader title="Profil altimétrique et vitesse" />
              <CardContent>
                <ElevationsSpeedsChart distances={distances} trace={trace} />
              </CardContent>
            </Card>
            <Card variant="outlined">
              <CardHeader title="Trace" />
              <MapWrapper id={mapId}>
                <ToggleWrapper>
                  <TraceToggle colorScale={colorScale} setColorScale={setColorScale} />
                </ToggleWrapper>
              </MapWrapper>
            </Card>
          </Box>
        ) : (
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            flexGrow={1}
            justifyContent="center"
            padding={2}
          >
            <TableRows color="action" fontSize="large" />
            <Typography color="textSecondary" variant="h6">
              Sélectionnez une trace dans le tableau
            </Typography>
          </Box>
        )}
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,
            height: 'calc(100vh - 34px)',
            width: 400,
          }}
          variant="outlined"
        >
          <CardHeader title="Logs de l'utilisateur" />
          <CardContent sx={{ overflowY: 'auto' }}>
            <Typography fontSize="0.9em" sx={{ whiteSpace: 'pre-wrap' }}>
              {logs || (
                <>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </>
              )}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

const MapWrapper = styled.div`
  height: 400px;
`;

const ToggleWrapper = styled.div`
  left: 10px;
  position: absolute;
  top: 10px;
  z-index: 2;
`;

export default UsersLogsData;
