import {
  RouteReportService,
  cyclingProfiles,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { CheckCircle, Error as ErrorIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { green, red } from '@mui/material/colors';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Table, { TRow } from '../../../../components/table';
import usePaginatedTable from '../../../../hooks/table/paginated';
import useSortableTable from '../../../../hooks/table/sortable';
import { TQAPageContext } from '../../context';

type TKey = 'status' | 'profile' | 'created';

const keys: TKey[] = ['status', 'profile', 'created'];

function ItineraryReportsTable({
  reports: { reports, selectedReport, selectReport },
}: TQAPageContext): JSX.Element {
  const [rows, setRows] = useState<TRow<number, TKey>[] | undefined>();
  const { page, rowsPerPage, setPage, onPageChange, onRowsPerPageChange } = usePaginatedTable();
  const { orderBy, order, onSortRequest } = useSortableTable<number, TKey>('created', 'desc', {
    setPage,
  });
  const { t } = useTranslation();
  const { cancellablePromise } = useCancellablePromise();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!!reports) {
      setRows(
        [...reports]
          .map((report) => {
            const { id: key, isClosed, profile: profileKey, created } = report;
            const profile = cyclingProfiles[profileKey];

            return {
              key,
              inkBarColor: isClosed ? green[500] : red[500],
              cells: {
                status: {
                  value: isClosed,
                  format: (isClosed: boolean) => {
                    return isClosed ? (
                      <Tooltip
                        placement="right"
                        title={<Trans i18nKey="commons.statuses.processed" />}
                      >
                        <CheckCircle style={{ color: green[500] }} />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        placement="right"
                        title={<Trans i18nKey="commons.statuses.unprocessed" />}
                      >
                        <ErrorIcon style={{ color: red[500] }} />
                      </Tooltip>
                    );
                  },
                },
                profile: { value: profile ? t(profile.labelKey) : '' },
                created: { value: moment(created).format('L') },
              },
            };
          })
          .sort(({ cells: aCells }, { cells: bCells }) => {
            const aValue = aCells[orderBy]?.value;
            const aCreated = `${aCells.created}`;
            const bValue = bCells[orderBy]?.value;
            const bCreated = `${bCells.created}`;

            if (aValue === bValue) return aCreated.localeCompare(bCreated);

            if (typeof aValue === 'number' && typeof bValue === 'number') {
              return order === 'asc' ? aValue - bValue : bValue - aValue;
            }

            if (typeof aValue === 'string' && typeof bValue === 'string') {
              return order === 'asc'
                ? aCreated.localeCompare(bCreated)
                : bCreated.localeCompare(aCreated);
            }

            return 0;
          })
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
      );
    } else {
      setRows(undefined);
    }
  }, [reports, orderBy, order, page, rowsPerPage]);

  async function handleRowClicked(key: number) {
    const selection = reports?.find((value) => value.id === key);
    if (selection) {
      try {
        const report = await cancellablePromise(RouteReportService.getRouteReport(selection.id));
        selectReport(report);
      } catch (err) {
        if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
          enqueueSnackbar(t('cycling-insights.reports.itinerary_reports.server_error'), {
            variant: 'error',
          });
        }
      }
    } else {
      selectReport(undefined);
    }
  }

  return (
    <Table
      hasInkBars
      count={reports?.length}
      detailedKey={selectedReport?.id}
      headers={{
        status: { label: <Trans i18nKey="commons.status" />, sortable: true, width: 50 },
        profile: { label: <Trans i18nKey="commons.stats.profile_label" />, sortable: true },
        created: { label: <Trans i18nKey="commons.stats.date_label" />, sortable: true },
      }}
      keys={keys}
      onClick={(key) => handleRowClicked(key)}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      onSortRequest={onSortRequest}
      order={order}
      orderBy={orderBy}
      page={page}
      rows={rows}
      rowsPerPage={rowsPerPage}
      selectedKey={selectedReport?.id || undefined}
      title="Cartographic contributions table"
    />
  );
}

export default ItineraryReportsTable;
