import { Paper as MuiPaper } from '@mui/material';
import { ReactNode } from 'react';

function ColoredStats({
  color,
  content,
  header,
}: {
  color: string;
  content: ReactNode;
  header: ReactNode;
}): JSX.Element {
  return (
    <MuiPaper
      elevation={0}
      sx={{
        backgroundColor: color,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        padding: 2,
        flex: '1 1 0px',
      }}
    >
      {header}
      {content}
    </MuiPaper>
  );
}

export default ColoredStats;
