import { SvgIcon, SvgIconProps } from '@mui/material';

function EnvironmentIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="-6 -6 43 43" {...props}>
      <path
        d="M4.73248 26.667C3.42896 25.2987 2.41429 23.7175 1.68847 21.9235C0.962643 20.1295 0.599731 18.2898 0.599731 16.4046C0.599731 14.1849 0.977456 12.1552 1.73291 10.3156C2.48835 8.47597 3.62153 6.78078 5.13243 5.23001C6.16932 4.16577 7.45802 3.26876 8.99855 2.53899C10.5391 1.80922 12.3536 1.23909 14.4422 0.828593C16.5308 0.418097 18.8934 0.159637 21.5301 0.0532123C24.1668 -0.0532124 27.0997 1.22337e-07 30.3289 0.21285C30.5659 3.49681 30.6473 6.49951 30.5733 9.22094C30.4992 11.9424 30.2622 14.3825 29.8623 16.5414C29.4623 18.7003 28.8994 20.5932 28.1736 22.22C27.4478 23.8467 26.5516 25.2075 25.4851 26.3021C23.9742 27.8529 22.3448 29.0235 20.5969 29.8141C18.849 30.6047 16.9826 31 14.9977 31C12.9535 31 11.0797 30.6427 9.37627 29.9282C7.67281 29.2136 6.12488 28.1265 4.73248 26.667ZM9.30961 26.667C10.0503 27.1839 10.9094 27.5792 11.887 27.8529C12.8647 28.1265 13.9016 28.2634 14.9977 28.2634C16.5679 28.2634 18.0936 27.9289 19.5748 27.2599C21.0561 26.591 22.4041 25.618 23.6187 24.3409C24.4186 23.5199 25.1 22.448 25.6629 21.1253C26.2257 19.8026 26.6849 18.2366 27.0404 16.4274C27.396 14.6182 27.633 12.5733 27.7515 10.2928C27.87 8.01226 27.87 5.50368 27.7515 2.76704C24.9667 2.70623 22.4707 2.74424 20.2636 2.88107C18.0565 3.0179 16.1087 3.26876 14.42 3.63364C12.7314 3.99853 11.2797 4.46224 10.0651 5.02477C8.85042 5.5873 7.85797 6.27906 7.08771 7.10005C5.84344 8.40755 4.89542 9.79107 4.24366 11.2506C3.5919 12.7102 3.26602 14.2001 3.26602 15.7205C3.26602 17.18 3.56968 18.7079 4.177 20.3043C4.78433 21.9007 5.53237 23.1854 6.42113 24.1584C7.99128 21.1785 9.8725 18.5027 12.0648 16.1309C14.2571 13.7592 16.5827 11.8892 19.0416 10.5208C16.4642 12.8014 14.3756 15.2719 12.7758 17.9326C11.176 20.5932 10.0206 23.5047 9.30961 26.667Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default EnvironmentIcon;
