import { useOutletContext } from 'react-router-dom';

import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { TCartographicDataPageContext } from '../../context';

import DiscontinuityAnalysisContent from './content';

function DiscontinuityAnalysisTab(): JSX.Element {
  const context = useOutletContext<TCartographicDataPageContext & TOutletContext>();

  return (
    <>
      <PageContentLayout leftPanel={<DiscontinuityAnalysisContent {...context} />} />
    </>
  );
}

export default DiscontinuityAnalysisTab;
