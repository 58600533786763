import { SvgIcon, SvgIconProps } from '@mui/material';

function VirtualCounterIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <rect fill="#9BC2FF" height="24" rx="12" width="24" x="0.445312" />
      <path
        clipRule="evenodd"
        d="M6.92383 7.95817C6.92383 6.00216 8.50949 4.4165 10.4655 4.4165H14.4238C16.3798 4.4165 17.9655 6.00216 17.9655 7.95817V18.8748V19.5832H17.2572H7.63216H6.92383V18.8748V7.95817ZM10.4655 5.83317C9.29189 5.83317 8.34049 6.78457 8.34049 7.95817V18.1665H16.5488V7.95817C16.5488 6.78457 15.5974 5.83317 14.4238 5.83317H10.4655ZM9.33008 7.87484C9.33008 7.48364 9.64721 7.1665 10.0384 7.1665H14.8509C15.2421 7.1665 15.5592 7.48364 15.5592 7.87484C15.5592 8.26604 15.2421 8.58317 14.8509 8.58317H10.0384C9.64721 8.58317 9.33008 8.26604 9.33008 7.87484ZM10.7467 9.9165C9.96434 9.9165 9.33008 10.5508 9.33008 11.3332V15.4165C9.33008 16.1989 9.96434 16.8332 10.7467 16.8332H14.1426C14.925 16.8332 15.5592 16.1989 15.5592 15.4165V11.3332C15.5592 10.5508 14.925 9.9165 14.1426 9.9165H10.7467ZM10.7467 11.3332H14.1426V15.4165H10.7467V11.3332Z"
        fill="#283859"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}

export default VirtualCounterIcon;
