import { ApiKey, ApiStats, TApiStatType } from '@geovelo-frontends/commons';
import { useState } from 'react';

import { TStats } from '../../components/dual-chart';
import { IPeriodFormValues } from '../../components/form/period';
import useQueryParams from '../../hooks/query-params';
import PageContainerLayout from '../../layouts/page/container';
import { IDashboardPage } from '../dashboard-page';

import ApiKeysTab from './components/api-keys';
import AvailabilityTab from './components/availability';
import DocumentationTab from './components/documentation';
import { IApiPageContext } from './context';

function ApiPage(page: IDashboardPage): JSX.Element {
  const { searchParams, getPeriods } = useQueryParams();

  // period context
  const [periods, setPeriods] = useState<IPeriodFormValues>(getPeriods());
  const [periodsComparisonEnabled, enablePeriodsComparison] = useState(
    searchParams.get('compare') === 'true',
  );

  // Api keys context
  const [apiKeys, setApiKeys] = useState<ApiKey[]>();
  const [selectedApiKeyStatTypes, selectApiKeyStatTypes] = useState<TApiStatType[]>([
    'routeComputer',
    'routeComputedSimplified',
    'm2m',
  ]);
  const [currentStats, setCurrentStats] = useState<ApiStats[]>();
  const [selectedApiKeyReponseType, selectApiKeyResponseType] =
    useState<TApiStatType>('routeComputer');
  const [selectedApiKeyIndex, selectApiKeyIndex] = useState<number | null>(null);
  const [apiKeyRequestsStats, setApiKeyRequestsStats] = useState<TStats<TApiStatType>>();
  const [apiKeyResponsesStats, setApiKeyResponsesStats] = useState<TStats<TApiStatType>>();

  return (
    <PageContainerLayout<IApiPageContext>
      context={{
        period: {
          values: periods,
          comparisonEnabled: periodsComparisonEnabled,
          setValues: setPeriods,
          enableComparison: enablePeriodsComparison,
        },
        apiKeys: {
          currentStats,
          list: apiKeys,
          selectedIndex: selectedApiKeyIndex,
          selectedTypes: selectedApiKeyStatTypes,
          selectedResponseType: selectedApiKeyReponseType,
          requestsStats: apiKeyRequestsStats,
          responsesStats: apiKeyResponsesStats,
          setCurrentStats,
          setList: setApiKeys,
          selectIndex: selectApiKeyIndex,
          selectTypes: selectApiKeyStatTypes,
          selectResponseType: selectApiKeyResponseType,
          setRequestsStats: setApiKeyRequestsStats,
          setResponsesStats: setApiKeyResponsesStats,
        },
      }}
      page={page}
    />
  );
}

export { ApiKeysTab, DocumentationTab, AvailabilityTab };
export default ApiPage;
