import { RouteReport, useSource } from '@geovelo-frontends/commons';
import { useRef } from 'react';

import ArrivalIcon from '../../assets/images/arrival_way_point_marker.svg';
import DepartureIcon from '../../assets/images/departure_way_point_marker.svg';

import { LngLatBounds, Map, Marker } from '!maplibre-gl';

const routeSourceId = 'route';
const routeLayerId = 'route';
const drawnRouteSourceId = 'drawn-route';
const drawnRouteLayerId = 'drawn-route';
const routeColors = {
  favorite: '#286199',
  mapillary: '#8bc34a',
  selected: '#7E57C2',
  unselected: '#78909C',
  josm: '#039be5',
  expected: '#4caf50',
  error: '#ff5722',
};

function useItineraryReports(map: Map | undefined): {
  update: (report: RouteReport | undefined) => void;
} {
  const markers = useRef<Marker[]>([]);
  const { addGeoJSONSource: addRouteSource } = useSource(map, routeSourceId);
  const { addGeoJSONSource: addDrawnRouteSource } = useSource(map, drawnRouteSourceId);

  function fitPositions(positions: GeoJSON.Position[]): void {
    const bounds = new LngLatBounds();
    for (const position of positions) {
      bounds.extend(position as [number, number]);
    }
    if (!bounds.isEmpty()) {
      map?.fitBounds(bounds, { padding: { top: 100, right: 466, bottom: 100, left: 50 } });
    }
  }

  function createWayPoint(point: GeoJSON.Point, iconUrl: string) {
    if (map) {
      const el = document.createElement('div');
      el.className = 'marker';
      el.style.backgroundImage = `url(${iconUrl})`;
      el.style.backgroundPosition = 'center';
      el.style.backgroundSize = 'cover';
      el.style.width = `16px`;
      el.style.height = `16px`;

      // make a marker for each feature and add it to the map
      const [lng, lat] = point.coordinates;
      const newMarker = new Marker({ element: el }).setLngLat({ lat, lng }).addTo(map);
      markers.current.push(newMarker);
    }
  }

  function update(report: RouteReport | undefined) {
    if (markers.current.length > 0) {
      markers.current.forEach((marker) => {
        marker.remove();
      });
      markers.current = [];
    }

    if (map?.getLayer(routeLayerId) && map?.getSource(routeSourceId)) {
      map?.removeLayer(routeLayerId);
      map?.removeSource(routeSourceId);
    }
    if (map?.getLayer(drawnRouteLayerId) && map?.getSource(drawnRouteSourceId)) {
      map?.removeLayer(drawnRouteLayerId);
      map?.removeSource(drawnRouteSourceId);
    }

    if (report) {
      addRouteSource({
        type: 'FeatureCollection',
        features: [{ type: 'Feature', geometry: report.geometry, properties: {} }],
      });

      map?.addLayer(
        {
          id: routeLayerId,
          type: 'line',
          source: routeSourceId,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': report.geometryDrawn ? routeColors.error : routeColors.selected,
            'line-width': 4,
          },
        },
        'labels',
      );
      const positions = [...(report?.geometry.coordinates || [])];

      if (report.wayPoints) {
        if (report.wayPoints[0]) {
          createWayPoint(report.wayPoints[0], DepartureIcon);
          positions.push(report.wayPoints[0].coordinates);
        }
        if (report.wayPoints[report.wayPoints.length - 1]) {
          createWayPoint(report.wayPoints[report.wayPoints.length - 1], ArrivalIcon);
          positions.push(report.wayPoints[report.wayPoints.length - 1].coordinates);
        }
      }

      if (report.geometryDrawn) {
        addDrawnRouteSource({
          type: 'FeatureCollection',
          features: [{ type: 'Feature', geometry: report.geometryDrawn, properties: {} }],
        });

        map?.addLayer(
          {
            id: drawnRouteLayerId,
            type: 'line',
            source: drawnRouteSourceId,
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': routeColors.expected,
              'line-width': 4,
            },
          },
          'labels',
        );
      }

      fitPositions(positions);
    }
  }

  return { update };
}

export default useItineraryReports;
