import {
  Period,
  TDayPeriod,
  TReportSource,
  TReportTypeCode,
  TStatus,
  TTimePeriod,
  currentMonth,
} from '@geovelo-frontends/commons';
import moment, { Moment } from 'moment';
import { useSearchParams } from 'react-router-dom';

import { dayPeriods, timePeriods } from '../components/form/period';

function useQueryParams(): {
  searchParams: URLSearchParams;
  get: <T extends string>(key: string, values: T[]) => T[] | undefined;
  getPeriods: (defaultPeriod?: Period) => { current: Period; prev: Period };
  update: (props: {
    currentPeriod?: Period;
    dayPeriod?: TDayPeriod;
    prevPeriod?: Period | null;
    periodsComparisonEnabled?: boolean;
    sources?: TReportSource[];
    statuses?: TStatus[];
    tab?: string;
    timePeriod?: TTimePeriod;
    typeCodes?: TReportTypeCode[];
    reviewsCount?: number;
  }) => void;
} {
  const [searchParams, setSearchParams] = useSearchParams();

  function get<T extends string>(key: string, values: T[]): T[] | undefined {
    const parts = searchParams.get(key)?.split(',');
    if (!parts) return;

    return values.filter((value) => parts.indexOf(value) > -1);
  }

  function getPeriods(defaultPeriod: Period = currentMonth.clone()): {
    current: Period;
    prev: Period;
  } {
    const type = searchParams.get('period');
    const _from = searchParams.get('from');
    const from = _from ? moment(_from) : undefined;
    const _prevFrom = searchParams.get('prev-from');
    let prevFrom = _prevFrom ? moment(_prevFrom) : undefined;
    const _dayPeriod = searchParams.get('day-period');
    const _timePeriod = searchParams.get('time-period');
    const props: { dayPeriod?: TDayPeriod; timePeriod?: TTimePeriod } = {};

    if (
      _dayPeriod &&
      dayPeriods.find(({ key }) => key.toLowerCase().localeCompare(_dayPeriod.toLowerCase()) === 0)
    )
      props.dayPeriod = _dayPeriod as TDayPeriod;
    else props.dayPeriod = 'all';

    if (
      _timePeriod &&
      timePeriods.find(
        ({ key }) => key.toLowerCase().localeCompare(_timePeriod.toLowerCase()) === 0,
      )
    )
      props.timePeriod = _timePeriod as TTimePeriod;
    else props.timePeriod = 'all_day';

    if (
      !type ||
      (type !== 'week' && type !== 'month' && type !== 'year' && type !== 'custom') ||
      !from ||
      !from.isValid()
    ) {
      return { ...props, current: defaultPeriod, prev: defaultPeriod.getPrevPeriod() };
    }

    from.startOf('day');

    let currentPeriod: Period;
    if (type === 'custom') {
      const _to = searchParams.get('to');
      const to = _to ? moment(_to) : undefined;
      if (to && to.isValid() && to.endOf('day') && from.isBefore(to)) {
        currentPeriod = new Period(type, from, to);

        let prevTo: Moment;
        if (prevFrom && prevFrom.isValid() && prevFrom.startOf('day') && prevFrom.isBefore(from)) {
          prevTo = prevFrom.clone().add(currentPeriod.nbDays, 'days').endOf('day');
        } else {
          prevFrom = from.clone().add(-currentPeriod.nbDays, 'days');
          prevTo = prevFrom.clone().add(currentPeriod.nbDays, 'days').endOf('day');
        }

        return { ...props, current: currentPeriod, prev: new Period('custom', prevFrom, prevTo) };
      }

      return { ...props, current: defaultPeriod, prev: defaultPeriod.getPrevPeriod() };
    }

    const to = from.clone().endOf(type);

    currentPeriod = new Period(type, from, to);

    let prevTo: Moment;
    if (prevFrom && prevFrom.isValid() && prevFrom.startOf('day') && prevFrom.isBefore(from)) {
      prevTo = prevFrom.clone().endOf(type);
    } else {
      prevFrom = from.clone().add(-1, 'day').startOf(type);
      prevTo = prevFrom.clone().endOf(type);
    }

    return { ...props, current: currentPeriod, prev: new Period(type, prevFrom, prevTo) };
  }

  function update({
    tab,
    currentPeriod,
    prevPeriod,
    periodsComparisonEnabled,
    dayPeriod,
    timePeriod,
    sources,
    statuses,
    typeCodes,
    reviewsCount,
  }: {
    currentPeriod?: Period;
    dayPeriod?: TDayPeriod;
    prevPeriod?: Period | null;
    periodsComparisonEnabled?: boolean;
    sources?: TReportSource[];
    statuses?: TStatus[];
    tab?: string;
    timePeriod?: TTimePeriod;
    typeCodes?: TReportTypeCode[];
    reviewsCount?: number;
  }) {
    let params: Record<string, string> = {};
    for (const [key, value] of searchParams) {
      params[key] = value;
    }

    if (tab) params.tab = tab;

    if (currentPeriod) {
      params = {
        ...params,
        period: currentPeriod.type,
        from: currentPeriod.from.format('YYYY-MM-DD'),
      };

      if (currentPeriod.type === 'custom') params.to = currentPeriod.to.format('YYYY-MM-DD');
    }

    if (prevPeriod === null) {
      delete params['prev-from'];
    } else if (prevPeriod) {
      params = {
        ...params,
        'prev-from': prevPeriod.from.format('YYYY-MM-DD'),
      };
    }

    if (periodsComparisonEnabled !== undefined)
      params.compare = periodsComparisonEnabled ? 'true' : 'false';

    if (dayPeriod) params['day-period'] = dayPeriod;

    if (timePeriod) params['time-period'] = timePeriod;

    if (sources) params['sources'] = sources.join(',');
    if (statuses) params['statuses'] = statuses.join(',');
    if (typeCodes) params['types'] = typeCodes.join(',');
    if (reviewsCount) params['reviews'] = reviewsCount.toString();

    setSearchParams(params, { replace: true });
  }

  return { searchParams, get, getPeriods, update };
}

export default useQueryParams;
