import {
  Report,
  ReportService,
  TReportTypeCode,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import PeriodForm from '../../../../components/form/period';
import ReportsFilters from '../../../../components/form/reports-filters';
import TabIntroduction from '../../../../components/tab-introduction';
import useCartographicReports from '../../../../hooks/map/cartographic-reports';
import useQueryParams from '../../../../hooks/query-params';
import { TOutletContext } from '../../../../layouts/page/container';
import { TCartographicDataPageContext } from '../../context';

import ReportsTable from './table';

function ReportsForm(context: TCartographicDataPageContext & TOutletContext): JSX.Element {
  const {
    period,
    reports: {
      tableRef,
      selectedStatuses,
      selectedTypeCodes,
      selectedSources,
      data,
      selectedId,
      selectStatuses,
      selectTypeCodes,
      selectSources,
      setData,
      selectId,
    },
  } = context;
  const [initialized, setInitialized] = useState(false);
  const {
    partner: { current: currentPartner },
    map: { current: currentMap },
    report: { types },
    actions: { getReportTypes },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { get: getQueryParams, update: updateQueryParams } = useQueryParams();
  const { initialized: layersInitialized, selectReport: selectReportOnMap } =
    useCartographicReports(currentMap, {
      cartographicReports: data || [],
      onReportSelected: selectId,
    });

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (!initialized) return;

    try {
      getReportTypes();
    } catch (err) {
      enqueueSnackbar(t('cycling-insights.reports.types.server_error'), { variant: 'error' });
    }

    return () => {
      selectStatuses(['OPEN', 'CLOSED']);
      selectTypeCodes(
        (types
          ?.filter(({ code, isBindToOSM }) => !isBindToOSM && code !== 'support')
          .map(({ code }) => code) || []) as TReportTypeCode[],
      );
      selectSources(['geovelo', 'openData']);
    };
  }, [initialized]);

  useEffect(() => {
    if (!types) return;

    const _typeCodes = (types
      .filter(({ code, isBindToOSM }) => !isBindToOSM && code !== 'support')
      .map(({ code }) => code) || []) as TReportTypeCode[];
    const typeCodes = getQueryParams('types', _typeCodes) || _typeCodes;

    selectTypeCodes(typeCodes);
  }, [types]);

  useEffect(() => {
    if (types) update();

    return () => {
      setData(undefined);
      cancelPromises();
    };
  }, [period.values, selectedStatuses, selectedTypeCodes, selectedSources]);

  useEffect(() => {
    const report = data?.find(({ id }) => selectedId === id);
    if (layersInitialized) selectReportOnMap(report || null);
  }, [layersInitialized, selectedId]);

  async function update() {
    if (selectedStatuses.length > 0 && selectedTypeCodes.length > 0 && selectedSources.length > 0) {
      try {
        const _reports: Report[] = [];
        let hasNext = true;
        let page = 1;

        while (hasNext) {
          const { reports, next } = await cancellablePromise(
            ReportService.getReports({
              cyclabilityZoneId: currentPartner?.cyclabilityZoneId || undefined,
              period: period.values.current.toIPeriod(),
              typeCodes: selectedTypeCodes,
              status: selectedStatuses.flatMap((status) =>
                status === 'OPEN' ? 'OPEN' : ['CLOSED', 'ARCHIVED', 'CLOSED_BY_OSM'],
              ),
              page: page++,
              rowsPerPage: 500,
              query:
                '{id, geo_point, creator{username}, created, updated, description, source, report_type_code, status, reviews, report_source, photo, is_valid, city_name, city_reference}',
            }),
          );

          _reports.push(...reports);
          hasNext = Boolean(next);
        }

        setData(_reports);

        updateQueryParams({
          currentPeriod: period.values.current,
          timePeriod: period.values.timePeriod,
          dayPeriod: period.values.dayPeriod,
          statuses: selectedStatuses,
          typeCodes: selectedTypeCodes,
          sources: selectedSources,
        });
      } catch (err) {
        if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
          enqueueSnackbar(t('cycling-insights.reports.cartographic_reports.server_error'), {
            variant: 'error',
          });
        }
      }
    }
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={5} minHeight="100%">
        <TabIntroduction title="cycling-insights.cartographic_data.introduction.reports" />
        <Box display="flex" flexDirection="column" gap={3}>
          <PeriodForm disableComparison disablePadding {...period} />
          <ReportsFilters {...context.reports} />
          <Box flexGrow={1} marginX={-3} sx={{ overflowY: 'auto' }}>
            <ReportsTable ref={tableRef} {...context} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ReportsForm;
