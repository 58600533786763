import { RefRoute, RefRouteService } from '@geovelo-frontends/commons';
import { DialogProps, TextField } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Dialog from '../../../../components/dialog';

interface IValues {
  description: string;
  computedRouteId: string;
}

type TProps = Omit<DialogProps, 'onClose'> & {
  onClose: (refRoute?: RefRoute) => void;
};

function RefRouteFormDialog({ onClose, ...props }: TProps): JSX.Element {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isSubmitting, values, touched, errors, resetForm, handleChange, handleSubmit } =
    useFormik<IValues>({
      initialValues: {
        description: '',
        computedRouteId: '',
      },
      validationSchema: Yup.object().shape({
        description: Yup.string().required(),
        computedRouteId: Yup.string().required(),
      }),
      onSubmit,
      enableReinitialize: true,
    });

  useEffect(() => {
    if (props.open) {
      resetForm({ values: { description: '', computedRouteId: '' } });
    }
  }, [props.open]);

  async function onSubmit(
    { description, computedRouteId }: IValues,
    { setSubmitting }: FormikHelpers<IValues>,
  ) {
    setSubmitting(true);

    try {
      const refRoute = await RefRouteService.create({ computedRouteId, description });

      enqueueSnackbar(t('cycling-insights.qa.ref_routes.form_dialog.success'), {
        variant: 'success',
      });
      onClose(refRoute);
    } catch (err) {
      enqueueSnackbar(t('cycling-insights.qa.ref_routes.form_dialog.server_error'), {
        variant: 'error',
      });
    }

    setSubmitting(false);
  }

  return (
    <Dialog
      fullWidth
      isForm
      confirmTitle={<Trans i18nKey="commons.actions.add" />}
      dialogTitle="ref-route-form-dialog"
      loading={isSubmitting}
      maxWidth="sm"
      onCancel={() => onClose()}
      onConfirm={handleSubmit}
      title={<Trans i18nKey="cycling-insights.qa.ref_routes.form_dialog.title" />}
      {...props}
    >
      <TextField
        fullWidth
        required
        disabled={isSubmitting}
        error={touched.computedRouteId && Boolean(errors.computedRouteId)}
        InputLabelProps={{ shrink: true }}
        label={t('cycling-insights.qa.ref_routes.form_dialog.computed_route_id')}
        margin="dense"
        name="computedRouteId"
        onChange={handleChange}
        placeholder="bG9jPTQ3LjM5MDM3LDAuNjk0MDgmbG9jPTQ3LjM5MDM2MzAwODQwMTEsMC42ODc0OTIyNDg4MzU2NjcjQkVHSU5ORVIjRmFsc2UjTUVESUFOIzE1I0ZhbHNlI05vbmUjMjAyMS0wNy0wNSAxNzozMzoyMC42NTc5NTkjVFJBRElUSU9OQUwjMCMwI1NBRkVSI0ZhbHNlI0ZhbHNl"
        size="small"
        value={values.computedRouteId}
        variant="outlined"
      />
      <TextField
        fullWidth
        multiline
        required
        disabled={isSubmitting}
        error={touched.description && Boolean(errors.description)}
        label={t('commons.description')}
        margin="dense"
        name="description"
        onChange={handleChange}
        rows={3}
        value={values.description}
        variant="outlined"
      />
    </Dialog>
  );
}

export default RefRouteFormDialog;
