import { ApiKey, ApiService } from '@geovelo-frontends/commons';
import { DialogProps, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { AppContext } from '../../../../app/context';
import Dialog from '../../../../components/dialog';

const dialogTitle = 'new-key-dialog';

interface IValues {
  label: string;
}

type TProps = Omit<DialogProps, 'onClose'> & {
  apiKeys?: ApiKey[];
  onClose: (apiKey?: ApiKey | null) => void;
};

function ApiKeyFormDialog({ apiKeys, open, onClose, ...props }: TProps): JSX.Element {
  const [initialKeyLabel, setInitialKeyLabel] = useState('');
  const [confirmMessageShowed, showConfirmMessage] = useState(false);
  const {
    user: { current: currentUser },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const {
    transitions: { duration },
  } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isSubmitting,
    values,
    touched,
    errors,
    setErrors,
    setValues,
    handleChange,
    handleSubmit,
  } = useFormik<IValues>({
    initialValues: {
      label: '',
    },
    validationSchema: Yup.object().shape({
      label: Yup.string().required(),
    }),
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (open) {
      setValues({ label: '' });
      setInitialKeyLabel(initialKeyLabel);
    }
  }, [open]);

  async function onSubmit({ label }: IValues, { setSubmitting }: FormikHelpers<IValues>) {
    if (!currentUser || !currentPartner) return;

    setSubmitting(true);

    try {
      const apiKey = await ApiService.createApiKey(currentPartner, label);

      onClose(apiKey);

      setTimeout(() => showConfirmMessage(false), duration.leavingScreen);
    } catch (err) {
      enqueueSnackbar(t('cycling-insights.api.keys.new_key_dialog.not_created'), {
        variant: 'error',
      });
      setSubmitting(false);
    }
  }

  function handleConfirm(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    if (!values.label) {
      setErrors({ label: 'error' });
      enqueueSnackbar(t('cycling-insights.api.keys.new_key_dialog.label_empty'), {
        variant: 'error',
      });
      return;
    }

    if (apiKeys) {
      if (
        apiKeys
          .map((apiKey) => apiKey.label)
          .find((existingLabel) => existingLabel === values.label)
      ) {
        setErrors({ label: 'error' });
        enqueueSnackbar(t('cycling-insights.api.keys.new_key_dialog.label_already_taken'), {
          variant: 'error',
        });

        return;
      }
    }

    showConfirmMessage(true);
  }

  return (
    <Dialog
      disableEscapeKeyDown
      isForm
      cancelTitle={
        !confirmMessageShowed && <Trans i18nKey="cycling-insights.api.keys.new_key_dialog.cancel" />
      }
      confirmTitle={!confirmMessageShowed && <Trans i18nKey="commons.actions.add" />}
      dialogTitle={dialogTitle}
      loading={isSubmitting}
      maxWidth="sm"
      onCancel={
        confirmMessageShowed
          ? () => {
              setInitialKeyLabel(values.label);
              showConfirmMessage(false);
            }
          : () => onClose()
      }
      onConfirm={confirmMessageShowed ? handleSubmit : handleConfirm}
      open={open}
      scroll="paper"
      title={<Trans i18nKey="cycling-insights.api.keys.new_key_dialog.title" />}
      {...props}
    >
      {confirmMessageShowed ? (
        <Typography color="error">
          <Trans
            components={[<br key={0} />]}
            i18nKey="cycling-insights.api.keys.new_key_dialog.confirm"
            values={{ label: values.label }}
          />
        </Typography>
      ) : (
        <TextField
          fullWidth
          disabled={isSubmitting}
          error={touched.label && Boolean(errors.label)}
          id="label"
          label={t('cycling-insights.api.keys.new_key_dialog.label')}
          margin="none"
          name="label"
          onChange={handleChange}
          size="small"
          type="text"
          value={values.label}
          variant="outlined"
        />
      )}
    </Dialog>
  );
}

export default ApiKeyFormDialog;
