import { SvgIcon, SvgIconProps } from '@mui/material';

function SecureSheltersIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 38 42" {...props}>
      <path
        d="M18.2125 1.8375C17.5014 1.30417 16.5236 1.30417 15.8125 1.8375L1.18501 12.8081C0.681399 13.1858 0.38501 13.7786 0.38501 14.4081V36.3494C0.38501 37.454 1.28044 38.3494 2.38501 38.3494H8.7772C9.88177 38.3494 10.7772 37.454 10.7772 36.3494V23.8571C10.7772 22.7525 11.6726 21.8571 12.7772 21.8571H21.2478C22.3524 21.8571 23.2478 22.7525 23.2478 23.8571V36.3494C23.2478 37.454 24.1433 38.3494 25.2478 38.3494H31.64C32.7446 38.3494 33.64 37.454 33.64 36.3494V14.4081C33.64 13.7786 33.3436 13.1858 32.84 12.8081L18.2125 1.8375Z"
        fill="#6B6C6E"
      />
      <circle cx="29.0987" cy="33.8683" fill="white" r="8.09685" />
      <path
        d="M29.0986 34.4095C28.9342 34.4076 28.7731 34.4561 28.6371 34.5484C28.5011 34.6408 28.3966 34.7727 28.3378 34.9262C28.2789 35.0798 28.2686 35.2477 28.308 35.4073C28.3475 35.5669 28.4349 35.7106 28.5585 35.8191V36.5697C28.5585 36.713 28.6154 36.8503 28.7167 36.9516C28.818 37.0529 28.9554 37.1098 29.0986 37.1098C29.2418 37.1098 29.3792 37.0529 29.4805 36.9516C29.5818 36.8503 29.6387 36.713 29.6387 36.5697V35.8191C29.7623 35.7106 29.8497 35.5669 29.8892 35.4073C29.9287 35.2477 29.9183 35.0798 29.8594 34.9262C29.8006 34.7727 29.6962 34.6408 29.5601 34.5484C29.4241 34.4561 29.263 34.4076 29.0986 34.4095ZM31.7989 32.2492V31.1691C31.7989 30.4529 31.5144 29.7661 31.008 29.2597C30.5016 28.7532 29.8148 28.4688 29.0986 28.4688C28.3824 28.4688 27.6956 28.7532 27.1892 29.2597C26.6828 29.7661 26.3983 30.4529 26.3983 31.1691V32.2492C25.9686 32.2492 25.5565 32.4199 25.2526 32.7238C24.9488 33.0276 24.7781 33.4397 24.7781 33.8694V37.6499C24.7781 38.0796 24.9488 38.4917 25.2526 38.7955C25.5565 39.0994 25.9686 39.2701 26.3983 39.2701H31.7989C32.2286 39.2701 32.6407 39.0994 32.9446 38.7955C33.2484 38.4917 33.4191 38.0796 33.4191 37.6499V33.8694C33.4191 33.4397 33.2484 33.0276 32.9446 32.7238C32.6407 32.4199 32.2286 32.2492 31.7989 32.2492ZM27.4784 31.1691C27.4784 30.7394 27.6491 30.3273 27.953 30.0234C28.2568 29.7196 28.6689 29.5489 29.0986 29.5489C29.5283 29.5489 29.9404 29.7196 30.2443 30.0234C30.5481 30.3273 30.7188 30.7394 30.7188 31.1691V32.2492H27.4784V31.1691ZM32.339 37.6499C32.339 37.7931 32.2821 37.9305 32.1808 38.0318C32.0795 38.133 31.9422 38.1899 31.7989 38.1899H26.3983C26.255 38.1899 26.1177 38.133 26.0164 38.0318C25.9151 37.9305 25.8582 37.7931 25.8582 37.6499V33.8694C25.8582 33.7262 25.9151 33.5888 26.0164 33.4875C26.1177 33.3862 26.255 33.3293 26.3983 33.3293H31.7989C31.9422 33.3293 32.0795 33.3862 32.1808 33.4875C32.2821 33.5888 32.339 33.7262 32.339 33.8694V37.6499Z"
        fill="#6B6C6E"
      />
    </SvgIcon>
  );
}

export default SecureSheltersIcon;
