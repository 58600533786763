import { facilitiesLabels, roadTypesLabels } from '@geovelo-frontends/commons';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../app/context';
import type { TColorCollection, TThicknessCollection } from '../../components/color-legend';
import { getFacilityLabelKey } from '../../components/form/facilities';
import { ISliderBounds, TSliderRange } from '../../components/form/slider';
import { TSectionFeatureCollection, TSectionProperties } from '../../models/sections';

import useHeatmap from './heatmap';

import { Map } from '!maplibre-gl';

export const colors: TColorCollection = [
  { value: '#A42C49' },
  { value: '#E76685' },
  { value: '#46CE9D' },
  { value: '#1C9267' },
];

const widths: TThicknessCollection = [
  { value: 2 },
  { value: 4 },
  { value: 6 },
  { value: 8 },
  { value: 10 },
];

function useCyclability(
  map: Map | null | undefined,
  onClick?: (section?: TSectionProperties) => void,
): {
  initialized: boolean;
  init: () => void;
  update: (
    { features }: TSectionFeatureCollection,
    props: {
      colors?: TColorCollection;
      comparisonEnabled?: boolean;
      currentRange?: TSliderRange;
      primaryBounds: ISliderBounds;
      secondaryBounds?: ISliderBounds;
    },
  ) => void;
  destroy: () => void;
} {
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();

  return useHeatmap(
    map,
    {
      sourceId: 'cyclability',
      layerId: 'cyclability',
      primaryCriterion: 'cyclability',
      secondaryCriterion: 'frequency',
      colors: colors,
      widths,
      interpolateWidths: true,
    },
    function getTooltipContent({ cyclability, frequency, facility, wayName, roadType }) {
      if (!cyclability && cyclability !== 0) return '';
      return (
        `<div style="padding: 8px"><h3>${
          wayName ? wayName : t(roadTypesLabels[roadType || 'unclassified'])
        }</h3>` +
        (cyclability === 4
          ? `<div style="background-color: #C2E4BF; color: #1B6515; border: 1px solid #5ABA52; border-radius: 4px; width: fit-content; padding: 2px 6px; margin: 6px 0;">
          ${t(`cycling-insights.facilities.cyclability.very_cycleable`)}</div>`
          : cyclability === 3
            ? `<div style="background-color: #E4F2DE; color: #268B1D; border: 1px solid #97D391; border-radius: 4px; width: fit-content; padding: 2px 6px; margin: 6px 0;">
          ${t(`cycling-insights.facilities.cyclability.cycleable`)}</div>`
            : cyclability === 2
              ? `<div style="background-color: #FFF3F3; color: #D34949; border: 1px solid #FFA3A3; border-radius: 4px; width: fit-content; padding: 2px 6px; margin: 6px 0;">
          ${t(`cycling-insights.facilities.cyclability.not_very_cycleable`)}</div>`
              : `<div style="background-color: #FFD7D7; color: #A32828; border: 1px solid #F36565; border-radius: 4px; width: fit-content; padding: 2px 6px; margin: 6px 0;">
          ${t(`cycling-insights.facilities.cyclability.difficult_to_cycle`)}</div>`) +
        (currentPartner?.dashboardTabsPermissions.usageRoadsUse === 'none'
          ? ''
          : `<div style="color: #283859">${t('commons.stats.passages', {
              count: frequency,
            })}</div>`) +
        `<div>${
          t('cycling-insights.facilities.cyclability.road_type') +
          t(roadTypesLabels[roadType || 'unclassified'])
        }</div>` +
        `<div>${
          t('cycling-insights.facilities.cyclability.facility_type') +
          t(facility ? getFacilityLabelKey(facility) : facilitiesLabels['unknown'])
        }</div>`
      );
    },
    onClick,
  );
}

export default useCyclability;
