import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../app/context';
import { parkingsTypes } from '../../models/parkings-types';

import { Toggle } from './toggle';

function ParkingsToggles({ simplified }: { simplified?: boolean }): JSX.Element {
  const [expended, expend] = useState(false);
  const {
    map: { parkingsShowed },
    actions: { toggleParkings },
  } = useContext(AppContext);
  const { t } = useTranslation();

  const allShowed = Object.values(parkingsShowed).every(Boolean);

  return (
    <>
      {simplified ? (
        <Box bgcolor="white" borderRadius={2}>
          <Toggle
            label={t('commons.poi.categories.parking_other')}
            showed={allShowed}
            toggle={(showed) =>
              toggleParkings({
                arch: showed,
                free: showed,
                private: showed,
                rack: showed,
                secure: showed,
                sheltered: showed,
                locked: showed,
              })
            }
          />
        </Box>
      ) : (
        <Accordion
          elevation={0}
          expanded={expended}
          onChange={() => expend(!expended)}
          sx={{ '&&': { borderRadius: 2 } }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{
              paddingLeft: 0,
              '&&.Mui-expanded': { minHeight: 48 },
              '.MuiAccordionSummary-content': { margin: 0 },
            }}
          >
            <Toggle
              info={t('cycling-insights.cartographic_data.parkings.caption.all_types_info')}
              label={t('cycling-insights.cartographic_data.parkings.caption.all_types')}
              showed={allShowed}
              toggle={(showed) =>
                toggleParkings({
                  arch: showed,
                  free: showed,
                  private: showed,
                  rack: showed,
                  secure: showed,
                  sheltered: showed,
                  locked: showed,
                })
              }
            />
          </AccordionSummary>
          <AccordionDetails
            sx={{
              alignItems: 'stretch',
              display: 'flex',
              flexDirection: 'column',
              label: { width: '100%', '.MuiFormControlLabel-label': { flexGrow: 1 } },
            }}
          >
            {parkingsTypes.map(({ key, titleKey }) => (
              <Toggle
                key={key}
                label={t(titleKey)}
                showed={parkingsShowed[key]}
                toggle={(showed) =>
                  toggleParkings({
                    ...parkingsShowed,
                    [key]: showed,
                  })
                }
              />
            ))}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}

export default ParkingsToggles;
