import { useOutletContext } from 'react-router-dom';

import PageContentLayout from '../../../../layouts/page/content';
import { IApiPageContext } from '../../context';

import ApiKeysData from './data';
import ApiKeysForm from './form';

function ApiKeysTab(): JSX.Element {
  const context = useOutletContext<IApiPageContext>();

  return (
    <PageContentLayout
      leftPanel={<ApiKeysForm {...context} />}
      main={<ApiKeysData {...context} />}
    />
  );
}

export default ApiKeysTab;
