import {
  Challenge,
  ChallengeCard,
  GeogroupService,
  TChallengePeriod,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Add, ArrowBackIosNew } from '@mui/icons-material';
import { Box, ButtonBase, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';

function ChallengeData(): JSX.Element {
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const [challenges, setChallenges] = useState<Challenge[]>();
  const [filteredChallenges, filterChallenges] = useState<Challenge[]>();
  const [selectedPeriod, selectPeriod] = useState<TChallengePeriod>('current');
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getChallenges();

    return () => {
      cancelPromises();
      setChallenges(undefined);
      filterChallenges(undefined);
    };
  }, [currentPartner, selectedPeriod]);

  async function getChallenges() {
    if (!currentPartner || !currentPartner.geoGroupId) return;

    try {
      const { challenges: result } = await cancellablePromise(
        GeogroupService.getChallenges(currentPartner.geoGroupId, {
          page: 1,
          pageSize: 100,
          order: selectedPeriod === 'past' ? '-start_datetime' : 'start_datetime',
          state:
            selectedPeriod === 'current'
              ? ['running']
              : selectedPeriod === 'future'
                ? ['future']
                : ['finished'],
        }),
      );

      setChallenges(result);
      filterChallenges(result.slice(0, 6));
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.community.challenges.server_error'), {
          variant: 'error',
        });
      }
    }
  }

  return (
    <Box
      bgcolor="#F6F7FB"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      gap={5}
      paddingX={14}
      paddingY={8}
      sx={{ overflowY: 'auto' }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box alignItems="center" display="flex" gap={2}>
          <ButtonBase component={Link} to="../animation">
            <ArrowBackIosNew color="primary" fontSize="large" />
          </ButtonBase>
          <Typography
            color="primary"
            component="h2"
            flexDirection="column"
            fontSize="2rem"
            fontWeight="700"
          >
            <Trans i18nKey="cycling-insights.community.challenges.list.title" />
          </Typography>
        </Box>
        <Box display="flex" gap={3}>
          <Button
            disableElevation
            component={Link}
            size="large"
            startIcon={<Add />}
            to="../new-challenge"
            variant="contained"
          >
            <Trans i18nKey="commons.challenges.actions.create" />
          </Button>
        </Box>
      </Box>
      <Box display="flex" gap={5}>
        {['current', 'future', 'past'].map((period) => (
          <ButtonBase
            key={period}
            onClick={() => selectPeriod(period as TChallengePeriod)}
            sx={{ borderBottom: period === selectedPeriod ? '2px solid #326AC2' : undefined }}
          >
            <Typography color={period === selectedPeriod ? '#326AC2' : '#666666'} fontWeight={600}>
              <Trans i18nKey={`cycling-insights.community.challenges.list.periods.${period}`} />
            </Typography>
          </ButtonBase>
        ))}
      </Box>
      <Box display="flex" flexWrap="wrap" gap={3}>
        {filteredChallenges?.map((challenge) => (
          <Box
            key={challenge.id}
            width={{ xs: '100%', md: 'calc((100% - 24px) / 2)', lg: 'calc((100% - 48px) / 3)' }}
          >
            <ChallengeCard
              challenge={challenge}
              redirectLink={
                currentPartner
                  ? `/${currentPartner.code}/promotion/challenges/${challenge.id}`
                  : '/'
              }
            />
          </Box>
        ))}
      </Box>
      {(!challenges || !filteredChallenges || filteredChallenges.length < challenges.length) && (
        <Button
          disabled={!challenges || !filteredChallenges}
          onClick={() =>
            filterChallenges(challenges?.slice(0, (filteredChallenges?.length || 0) + 6))
          }
          size="large"
          sx={{ alignSelf: 'center' }}
          variant="outlined"
        >
          {t('commons.actions.next')}
        </Button>
      )}
    </Box>
  );
}

export default ChallengeData;
