import {
  Table as MuiTable,
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { Moment } from 'moment';
import { ReactNode } from 'react';
import styled from 'styled-components';

export type THeader = { label: ReactNode; sortable?: boolean };

export type TValue = string | number | Moment | boolean | string[] | null;

export type TCells<TCellKey extends string> = {
  [key in TCellKey]?: {
    value: TValue;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    format?: (value: any) => ReactNode;
  };
};

export type TRow<TKey extends string | number, TCellKey extends string> = {
  key: TKey;
  cells: TCells<TCellKey>;
};

export type TOrder = 'asc' | 'desc';

interface IProps<TKey extends string | number, TCellKey extends string> {
  headers: { [key in TCellKey]?: THeader };
  keys: TCellKey[];
  onCellClick?: (key: TKey, cellKey: TCellKey) => void;
  order?: TOrder;
  orderBy?: TCellKey;
  rows?: TRow<TKey, TCellKey>[];
  size?: 'small' | 'medium';
  title: string;
}

function SimpleTable<TKey extends string | number, TCellKey extends string>({
  title,
  keys,
  size,
  headers,
  rows,
  orderBy,
  order,
  onCellClick,
}: IProps<TKey, TCellKey>): JSX.Element {
  const loadingItems = [1, 2, 3];

  return (
    <Wrapper>
      <TableWrapper>
        <StyledMuiTable stickyHeader aria-label={title}>
          <TableHead>
            <TableRow>
              {keys.map((key) => {
                const header = headers[key];

                return (
                  <TableCell key={key}>
                    {header?.sortable && orderBy && order ? (
                      <TableSortLabel
                        active={orderBy === key}
                        direction={orderBy === key ? order : 'asc'}
                      >
                        {header.label}
                        {orderBy === key ? (
                          <StyledSortAriaLabel>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </StyledSortAriaLabel>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      header?.label
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              ? rows.map(({ key: rowKey, cells }) => (
                  <TableRow key={rowKey}>
                    {keys.map((key) => {
                      const cell = cells[key];
                      return (
                        <StyledTableCell
                          key={key}
                          onClick={onCellClick ? () => onCellClick(rowKey, key) : undefined}
                          size={size || 'small'}
                        >
                          <>{cell?.format ? cell.format(cell.value) : cell?.value}</>
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                ))
              : loadingItems.map((rowKey) => (
                  <TableRow key={rowKey}>
                    {keys.map((key) => (
                      <TableCell key={key} size={size || 'small'}>
                        <Skeleton variant="text" width={50} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
          </TableBody>
        </StyledMuiTable>
      </TableWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const StyledMuiTable = styled(MuiTable)`
  && * {
    font-size: 0.8rem;
  }

  & th {
    font-weight: 500;
  }

  &:last-child td {
    border-bottom: 0;
  }
`;

const StyledTableCell = styled(TableCell)`
  && {
    font-family: 'Courier New', Courier, monospace;
    min-width: fit-content;
  }
`;

const StyledSortAriaLabel = styled.div`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20px;
  width: 1px;
`;

export default SimpleTable;
