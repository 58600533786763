import { Box, Skeleton, Typography } from '@mui/material';
import { Chart } from 'chart.js';
import { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { TCartographicDataPageContext } from '../../context';

const roadsTypesChartId = 'cyclability-chart';
const colors = ['#1C9267', '#46CE9D', '#E76685', '#A42C49'];

function CyclabilityChart({
  cyclability: { roadsCountByCyclability },
}: TCartographicDataPageContext): JSX.Element {
  const { t } = useTranslation();
  const roadsTypesChartRef = useRef<Chart<'doughnut'>>();

  useEffect(() => {
    const roadsTypesChartCtx = document.getElementById(roadsTypesChartId);
    if (roadsTypesChartCtx && roadsTypesChartCtx instanceof HTMLCanvasElement) {
      roadsTypesChartRef.current?.destroy();

      roadsTypesChartRef.current = new Chart(roadsTypesChartCtx, {
        type: 'doughnut',
        data: {
          labels: [
            t('cycling-insights.facilities.cyclability.very_cycleable'),
            t('cycling-insights.facilities.cyclability.cycleable'),
            t('cycling-insights.facilities.cyclability.not_very_cycleable'),
            t('cycling-insights.facilities.cyclability.difficult_to_cycle'),
          ],
          datasets: roadsCountByCyclability
            ? [
                {
                  data: roadsCountByCyclability.slice().reverse(),
                  backgroundColor: colors,
                },
              ]
            : [
                {
                  data: [70, 30],
                  backgroundColor: ['#E3E7EE', '#F6F7FB'],
                },
              ],
        },
        options: {
          cutout: '80%',
          events: roadsCountByCyclability
            ? ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove']
            : [],
          responsive: true,
          maintainAspectRatio: false,
          plugins: roadsCountByCyclability
            ? {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: ({ parsed }) =>
                      `${
                        Math.round(
                          (parsed /
                            roadsCountByCyclability.reduce((value, res) => (res += value), 0)) *
                            1000,
                        ) / 10
                      } %`,
                  },
                },
                title: {
                  display: false,
                },
              }
            : {
                legend: {
                  display: false,
                },
                title: {
                  display: false,
                },
              },
        },
      });
    }
  }, [roadsCountByCyclability]);

  return (
    <Box display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" gap={1} marginBottom={3}>
        <Typography fontSize="1.125rem" fontWeight={700}>
          <Trans i18nKey="cycling-insights.facilities.cyclability.chart.title" />
        </Typography>
      </Box>
      {roadsCountByCyclability &&
      roadsCountByCyclability.reduce((value, res) => (res += value), 0) === 0 ? (
        <Typography overflow="hidden" textOverflow="ellipsis" variant="body2" whiteSpace="nowrap">
          <Trans i18nKey="commons.no_filtered_data" />
        </Typography>
      ) : (
        <Box
          display="flex"
          flexDirection="row"
          gap={4}
          justifyContent="space-between"
          paddingRight={2}
        >
          <Box
            alignSelf="center"
            height={200}
            maxWidth="100%"
            minWidth={200}
            overflow="hidden"
            position="relative"
            sx={{ overflow: 'hidden' }}
            width={200}
          >
            <canvas id={roadsTypesChartId} style={{ position: 'relative', zIndex: 2 }} />
          </Box>
          <Box display="flex" flexDirection="column" gap={2} justifyContent="center">
            {roadsCountByCyclability ? (
              <>
                <Box display="flex" flexDirection="column">
                  <Box
                    bgcolor="#1C9267"
                    borderRadius="5px"
                    height="10px"
                    marginBottom="2px"
                    minWidth="10px"
                    width="10px"
                  />
                  <Typography variant="caption">
                    <Trans i18nKey="cycling-insights.facilities.cyclability.very_cycleable" />
                  </Typography>
                  <Typography variant="caption">
                    {Math.round(
                      (roadsCountByCyclability[3] /
                        roadsCountByCyclability.reduce((value, res) => (res += value), 0)) *
                        1000,
                    ) / 10}
                    %
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box
                    bgcolor="#46CE9D"
                    borderRadius="5px"
                    height="10px"
                    marginBottom="2px"
                    minWidth="10px"
                    width="10px"
                  />
                  <Typography variant="caption">
                    <Trans i18nKey="cycling-insights.facilities.cyclability.cycleable" />
                  </Typography>
                  <Typography variant="caption">
                    {Math.round(
                      (roadsCountByCyclability[2] /
                        roadsCountByCyclability.reduce((value, res) => (res += value), 0)) *
                        1000,
                    ) / 10}
                    %
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box
                    bgcolor="#E76685"
                    borderRadius="5px"
                    height="10px"
                    marginBottom="2px"
                    minWidth="10px"
                    width="10px"
                  />
                  <Typography variant="caption">
                    <Trans i18nKey="cycling-insights.facilities.cyclability.not_very_cycleable" />
                  </Typography>
                  <Typography variant="caption">
                    {Math.round(
                      (roadsCountByCyclability[1] /
                        roadsCountByCyclability.reduce((value, res) => (res += value), 0)) *
                        1000,
                    ) / 10}
                    %
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box
                    bgcolor="#A42C49"
                    borderRadius="5px"
                    height="10px"
                    marginBottom="2px"
                    minWidth="10px"
                    width="10px"
                  />
                  <Typography variant="caption">
                    <Trans i18nKey="cycling-insights.facilities.cyclability.difficult_to_cycle" />
                  </Typography>
                  <Typography variant="caption">
                    {Math.round(
                      (roadsCountByCyclability[0] /
                        roadsCountByCyclability.reduce((value, res) => (res += value), 0)) *
                        1000,
                    ) / 10}
                    %
                  </Typography>
                </Box>
              </>
            ) : (
              [0, 1, 2].map((key) => <Skeleton key={key} variant="text" width={128} />)
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default CyclabilityChart;
