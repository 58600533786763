import { BikeRoute, IPoint, createImageMarker, useSource } from '@geovelo-frontends/commons';
import { blue } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { useRef, useState } from 'react';

import rideIcon from '../../assets/images/ride.svg';

import { Map, Marker } from '!maplibre-gl';

const sourceId = 'bike-route';
const layerId = 'bike-route';

function useBikeRoute(
  map: Map | undefined,
  canWrite: boolean,
  { onCenterUpdate }: { onCenterUpdate: (point: IPoint) => void },
): {
  initialized: boolean;
  init: () => void;
  update: (bikeRoute: BikeRoute) => void;
} {
  const [initialized, setInitialized] = useState(false);
  const markerRef = useRef<Marker>();
  const { palette } = useTheme();
  const { addGeoJSONSource, getGeoJSONSource, updateGeoJSONSource } = useSource(map, sourceId);

  function init() {
    if (getGeoJSONSource()) return;

    addGeoJSONSource();

    map?.addLayer(
      {
        id: layerId,
        type: 'line',
        source: sourceId,
        paint: {
          'line-color': ['get', 'color'],
          'line-width': ['get', 'width'],
        },
        filter: ['!=', 'dragged', true],
      },
      'labels',
    );

    setInitialized(true);
  }

  function update({
    condensedGeometry: geometry,
    condensedGeometryReturn: geometryReturn,
    primaryColor,
    secondaryColor,
    center,
    iconUrl,
  }: BikeRoute) {
    const features: GeoJSON.Feature[] = [];
    if (geometry) {
      if (geometryReturn) {
        features.push(
          {
            type: 'Feature',
            geometry: geometryReturn,
            properties: { color: '#fff', width: 7 },
          },
          {
            type: 'Feature',
            geometry: geometryReturn,
            properties: { color: secondaryColor || palette.secondary.dark, width: 5 },
          },
        );
      }
      features.push(
        { type: 'Feature', geometry, properties: { color: '#fff', width: 7 } },
        {
          type: 'Feature',
          geometry,
          properties: { color: primaryColor || palette.secondary.main, width: 5 },
        },
      );
    }

    updateGeoJSONSource({ type: 'FeatureCollection', features });

    if (map && center) {
      const [lng, lat] = center.coordinates;

      if (markerRef.current) markerRef.current.setLngLat({ lat, lng });
      else {
        createImageMarker(
          {
            radius: 12,
            borderWidth: 2,
            draggable: canWrite,
            ...(iconUrl
              ? { backgroundImage: iconUrl }
              : { backgroundColor: blue[500], backgroundImage: rideIcon, backgroundSize: 16 }),
          },
          {
            onDragEnd: onCenterUpdate,
          },
        )
          .setLngLat({ lat, lng })
          .addTo(map);
      }
    }
  }

  return { initialized, init, update };
}

export default useBikeRoute;
