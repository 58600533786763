import { SvgIcon, SvgIconProps } from '@mui/material';

export function CostIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 25" {...props}>
      <path
        d="M8.16019 12.2577V8.11023C8.16019 6.33179 10.5339 4.99023 13.6802 4.99023C16.8265 4.99023 19.2002 6.33179 19.2002 8.11023V14.8302C19.2002 16.6087 16.8265 17.9502 13.6802 17.9502C13.3267 17.9502 12.9733 17.9315 12.6217 17.8949C12.0517 19.2121 10.8311 20.1327 9.40791 20.3194C7.98571 20.506 6.56919 19.9313 5.67854 18.8063C4.78793 17.6803 4.55355 16.1701 5.06261 14.8285C5.57074 13.486 6.74729 12.5101 8.16005 12.2579L8.16019 12.2577ZM13.6802 6.43023C11.1892 6.43023 9.60019 7.42585 9.60019 8.11023C9.60019 8.79462 11.1893 9.79023 13.6802 9.79023C16.1711 9.79023 17.7602 8.79462 17.7602 8.11023C17.7602 7.42585 16.1711 6.43023 13.6802 6.43023ZM17.7602 10.2534C16.7665 10.8609 15.3292 11.2303 13.6802 11.2303C12.0312 11.2303 10.594 10.8609 9.60019 10.2534V11.4703C9.60019 12.1547 11.1893 13.1503 13.6802 13.1503C16.1711 13.1503 17.7602 12.1547 17.7602 11.4703V10.2534ZM13.6802 16.5102C16.1712 16.5102 17.7602 15.5146 17.7602 14.8302V13.6134C16.7665 14.2209 15.3292 14.5902 13.6802 14.5902C13.3071 14.5902 12.9349 14.5705 12.5646 14.5302C12.8243 15.074 12.9593 15.6684 12.9602 16.2702V16.4793C13.1833 16.5102 13.4261 16.5102 13.6802 16.5102ZM8.88019 18.9102C9.948 18.9102 10.9108 18.2671 11.3196 17.2809C11.7283 16.2937 11.5024 15.1583 10.7467 14.4037C9.99207 13.6481 8.85675 13.4222 7.86963 13.8309C6.88337 14.2397 6.24024 15.2025 6.24024 16.2703C6.24024 17.7281 7.42239 18.9102 8.88019 18.9102Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
