import { Box, Grid } from '@mui/material';

import { TQAPageContext } from '../../context';

import RefRoutesChart from './chart';
import RefRoutesTable from './table';

function RefRoutesData(context: TQAPageContext): JSX.Element {
  const {
    refRoutes: { failedCount, succeededCount },
  } = context;

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      gap={2}
      padding={2}
      sx={{ backgroundColor: 'whitesmoke', overflowY: 'auto' }}
    >
      <Grid container spacing={2}>
        <Grid item lg={4} md={12} xl={3}>
          <RefRoutesChart failedCount={failedCount} succeededCount={succeededCount} />
        </Grid>
        <Grid item lg={8} md={12} xl={9}>
          <RefRoutesTable {...context} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default RefRoutesData;
