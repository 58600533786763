import {
  GeogroupService,
  useCancellablePromise,
  useClipboard,
  useFileSaver,
} from '@geovelo-frontends/commons';
import { ContentCopy } from '@mui/icons-material';
import { Box, DialogProps, Skeleton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import html2canvas from 'html2canvas';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import { AppContext } from '../app/context';

import Button from './button';
import Dialog from './dialog';

type TProps = Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
};

function InvitationLinkDialog({ onClose, ...props }: TProps): JSX.Element {
  const [invitationLink, setInvitationLink] = useState<string>();
  const {
    partner: { current: currentPartner, currentGeogroup },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [downloading, setDownloading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const printRef = useRef<HTMLDivElement | null>(null);
  const { downloadBlob } = useFileSaver();
  const { transitions } = useTheme();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { copy: copyToClipboard } = useClipboard(
    () =>
      enqueueSnackbar(t('cycling-insights.community.members.invitation_link_dialog.link_copied'), {
        variant: 'success',
      }),
    () =>
      enqueueSnackbar(
        t('cycling-insights.community.members.invitation_link_dialog.link_not_copied'),
        {
          variant: 'error',
        },
      ),
  );

  useEffect(() => {
    if (props.open) {
      getLink();
    } else {
      cancelPromises();
      setTimeout(() => setInvitationLink(undefined), transitions.duration.leavingScreen);
    }
  }, [props.open]);

  async function getLink() {
    if (!currentPartner?.geoGroupId) return;

    try {
      const { link } = await cancellablePromise(
        GeogroupService.getInvitationLink(currentPartner.geoGroupId),
      );

      setInvitationLink(link);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(
          t('cycling-insights.community.members.invitation_link_dialog.server_error'),
          {
            variant: 'error',
          },
        );
      }
    }
  }

  function handleCopy() {
    if (!invitationLink) return;

    copyToClipboard(invitationLink);
  }

  async function handleDownload() {
    setDownloading(true);

    if (printRef.current) {
      const canvas = await html2canvas(printRef.current, {
        scale: 3,
        width: printRef.current.clientWidth,
        height: printRef.current.clientHeight,
        useCORS: true,
      });

      const blob = await new Promise<Blob>((resolve, reject) => {
        try {
          canvas.toBlob(
            (blob) => {
              if (!blob) throw new Error('no blob');
              else resolve(blob);
            },
            'image/png',
            1.0,
          );
        } catch (err) {
          reject(err);
        }
      });

      downloadBlob(`${currentGeogroup?.title}_qr_code.png`, blob);
    }

    setDownloading(false);
  }

  return (
    <Dialog
      closeHidden
      confirmTitle={<Trans i18nKey="commons.actions.close" />}
      dialogTitle="invitation-link-dialog"
      loading={downloading}
      maxWidth="sm"
      onCancel={onClose}
      onConfirm={onClose}
      title={
        <Trans
          i18nKey="cycling-insights.community.members.invitation_link_dialog.title"
          values={{ title: currentGeogroup?.title }}
        />
      }
      {...props}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography color="#283859" fontWeight={600}>
          <Trans i18nKey="cycling-insights.community.members.actions.copy_invitation_link" />
        </Typography>
        <Box
          alignItems="center"
          border="1px solid #D1D5DB"
          borderRadius={2}
          display="flex"
          justifyContent="space-between"
          marginBottom={3}
          paddingLeft="12px"
        >
          {invitationLink ? (
            <Typography>{invitationLink}</Typography>
          ) : (
            <Skeleton variant="text" width={300} />
          )}
          <Button
            disableElevation
            disabled={!invitationLink}
            onClick={handleCopy}
            size="large"
            startIcon={<ContentCopy />}
            variant="contained"
          >
            <Trans i18nKey="commons.actions.copy" />
          </Button>
        </Box>
        <Typography color="#283859" fontWeight={600}>
          <Trans i18nKey="cycling-insights.community.members.actions.share_qr_code" />
        </Typography>
        <Box alignItems="center" display="flex" gap={4}>
          {invitationLink ? (
            <Box height={128} ref={printRef} width={128}>
              <QRCode
                fgColor="#212121"
                size={128}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={invitationLink}
                viewBox={`0 0 128 128`}
              />
            </Box>
          ) : (
            <Skeleton height={128} variant="rectangular" width={128} />
          )}
          <Button
            disableElevation
            onClick={() => handleDownload()}
            size="large"
            variant="contained"
          >
            <Trans i18nKey="commons.actions.download_qr_code" />
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default InvitationLinkDialog;
