import { useOutletContext } from 'react-router-dom';

import PageContentLayout from '../../../../layouts/page/content';
import { IBicycleObservatoryPageContext } from '../../context';

import GeoveloActivityData from './data';
import GeoveloActivityForm from './form';

function GeoveloActivityTab(): JSX.Element {
  const context = useOutletContext<IBicycleObservatoryPageContext>();

  return (
    <PageContentLayout
      leftPanel={<GeoveloActivityForm {...context} />}
      main={<GeoveloActivityData {...context} />}
    />
  );
}

export default GeoveloActivityTab;
