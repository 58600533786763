import {
  RefRoute,
  RefRouteService,
  cyclingProfiles,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { CheckCircle, Error as ErrorIcon } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Card from '../../../../components/card';
import Dialog from '../../../../components/dialog';
import Table, { TRow } from '../../../../components/table';
import usePaginatedTable from '../../../../hooks/table/paginated';
import useSortableTable from '../../../../hooks/table/sortable';
import { TQAPageContext } from '../../context';

import RefRouteDetailDialog from './detail-dialog';

type TKey = 'status' | 'id' | 'creator' | 'successDate' | 'profile';

const keys: TKey[] = ['status', 'id', 'creator', 'successDate', 'profile'];

function RefRoutesTable({
  refRoutes: { search, succeededCount, failedCount },
}: TQAPageContext): JSX.Element {
  const [refRoutes, setRefRoutes] = useState<RefRoute[]>();
  const [count, setCount] = useState<number>();
  const [rows, setRows] = useState<TRow<number, TKey>[] | undefined>();
  const [selectedRefRoute, selectRefRoute] = useState<RefRoute | null>(null);
  const [detailDialogOpen, openDetailDialog] = useState(false);
  const [removeDialogOpen, openRemoveDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const { page, rowsPerPage, setPage, onPageChange, onRowsPerPageChange } = usePaginatedTable<
    number,
    TKey
  >(25);
  const { orderBy, order, setOrderBy, setOrder, onSortRequest } = useSortableTable<number, TKey>(
    'id',
    'desc',
    {
      setPage,
    },
  );
  const { transitions } = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    return () => {
      cancelPromises();
    };
  }, []);

  useEffect(() => {
    if (succeededCount !== undefined && failedCount !== undefined) {
      if (page !== 0 || orderBy !== 'id' || order !== 'desc') {
        setPage(0);
        setOrderBy('id');
        setOrder('desc');
      } else getRefRoutes();
    }
  }, [succeededCount, failedCount]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (page === 0) getRefRoutes();
      else setPage(0);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [search]);

  useEffect(() => {
    getRefRoutes();
  }, [orderBy, order, page, rowsPerPage]);

  async function getRefRoutes() {
    cancelPromises();
    setCount(undefined);
    setRefRoutes(undefined);

    if (succeededCount === undefined || failedCount === undefined) return;

    try {
      const { count: _count, refRoutes: _refRoutes } = await cancellablePromise(
        RefRouteService.getAll({
          page: page + 1,
          pageSize: rowsPerPage,
          search,
          orderBy: orderBy,
          order: order,
          query:
            '{ id, passed, created, profile, last_passed_date, description, computed_route_id }',
        }),
      );

      setCount(_count);
      setRefRoutes(_refRoutes);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError')
        enqueueSnackbar(t('cycling-insights.qa.server_error'), { variant: 'error' });
    }
  }

  useEffect(() => {
    if (refRoutes) {
      const _rows = [...refRoutes].map(
        ({ id, status, creator, profile: profileKey, successDate }) => {
          const profile = profileKey && cyclingProfiles[profileKey];

          return {
            key: id,
            cells: {
              status: {
                value: status || '',
                format: (_status?: 'success' | 'failure') => (
                  <>
                    {_status &&
                      (_status === 'success' ? (
                        <CheckCircle style={{ color: green[500] }} />
                      ) : (
                        <ErrorIcon style={{ color: red[500] }} />
                      ))}
                  </>
                ),
              },
              id: { value: id },
              creator: { value: creator || '' },
              successDate: {
                value: successDate || null,
                format: (date: Moment | null) => date?.format('LL') || '',
              },
              profile: { value: profile ? t(profile.labelKey) : '' },
            },
            inkBarColor: status === 'success' ? green[500] : red[500],
          };
        },
      );

      setRows(_rows);
    } else {
      setRows(undefined);
    }
  }, [refRoutes]);

  function handleRowClick(key: number) {
    const refRoute = refRoutes?.find(({ id }) => id === key);
    if (refRoute) {
      selectRefRoute(refRoute);
      openDetailDialog(true);
    }
  }

  function handleRemoveCancel() {
    openRemoveDialog(false);
    setTimeout(() => openDetailDialog(true), transitions.duration.leavingScreen);
  }

  function handleRemoveDialogOpen() {
    openDetailDialog(false);
    setTimeout(() => openRemoveDialog(true), transitions.duration.leavingScreen);
  }

  function handleDetailDialogClose() {
    openDetailDialog(false);
    setTimeout(() => selectRefRoute(null), transitions.duration.leavingScreen);
  }

  async function handleRemove() {
    if (!selectedRefRoute) return;

    setLoading(true);

    try {
      await RefRouteService.remove(selectedRefRoute.id);

      enqueueSnackbar(
        t('cycling-insights.qa.ref_routes.remove_dialog.removed', { id: selectedRefRoute.id }),
        {
          variant: 'success',
        },
      );
      openRemoveDialog(false);
      if (page !== 0) setPage(0);
      else getRefRoutes();

      setTimeout(() => selectRefRoute(null), transitions.duration.leavingScreen);
    } catch (err) {
      enqueueSnackbar(
        t('cycling-insights.qa.ref_routes.remove_dialog.server_error', { id: selectedRefRoute.id }),
        { variant: 'error' },
      );
    }

    setLoading(false);
  }

  function handleUpdated(refRoute: RefRoute) {
    if (selectedRefRoute && selectedRefRoute.id === refRoute.id) {
      if (refRoutes) {
        const _refRoutes = [...refRoutes];
        _refRoutes.splice(
          _refRoutes.findIndex(({ id }) => id === refRoute.id),
          1,
          refRoute,
        );

        setRefRoutes(_refRoutes);
      }

      selectRefRoute(refRoute.clone());
    }
  }

  return (
    <>
      <StyledCard
        permission={'write'}
        title={<Trans i18nKey="cycling-insights.qa.ref_routes.table.title" />}
      >
        <Table
          hasInkBars
          count={count}
          headers={{
            status: { label: t('commons.status'), sortable: true, width: 30 },
            id: { label: '#', sortable: true, width: 30 },
            creator: { label: t('commons.creator'), sortable: true, width: 100 },
            successDate: {
              label: t('cycling-insights.qa.ref_routes.table.success_date'),
              sortable: true,
              width: 100,
            },
            profile: { label: t('commons.stats.profile_label'), sortable: true, width: 100 },
          }}
          keys={keys}
          onClick={handleRowClick}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          onSortRequest={onSortRequest}
          order={order}
          orderBy={orderBy}
          page={page}
          rows={rows}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          title="ref-routes-table"
        />
      </StyledCard>
      <RefRouteDetailDialog
        keepMounted
        onClose={handleDetailDialogClose}
        onRemove={handleRemoveDialogOpen}
        onUpdated={handleUpdated}
        open={detailDialogOpen}
        refRoute={selectedRefRoute}
      />
      <Dialog
        dangerous
        dialogTitle="ref-route-removal-dialog"
        loading={loading}
        onCancel={handleRemoveCancel}
        onConfirm={handleRemove}
        open={removeDialogOpen}
        title={
          <Trans
            i18nKey="cycling-insights.qa.ref_routes.remove_dialog.title"
            values={{ id: selectedRefRoute?.id }}
          />
        }
      >
        <Typography color="error">
          <Trans i18nKey="cycling-insights.qa.ref_routes.remove_dialog.description" />
        </Typography>
      </Dialog>
    </>
  );
}

const StyledCard = styled(Card)`
  height: fit-content;
  flex: 2;
`;

export default RefRoutesTable;
