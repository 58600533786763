import { Box, Tab, Tabs } from '@mui/material';
import { ComponentType, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import Card from '../../../../components/card';
import DownloadIcon from '../../../../components/icons/download';
import TabPanel from '../../../../components/tab-panel';
import useQueryParams from '../../../../hooks/query-params';

export type TTabs<TTab> = Array<{
  key: TTab;
  icon?: JSX.Element;
  label: string;
  Tab: ComponentType<Record<string, never>>;
}>;

function DocumentationTab<TTab extends string>({
  defaultTab,
  tabs,
  titleKey,
}: {
  defaultTab: TTab;
  tabs: TTabs<TTab>;
  titleKey: string;
}): JSX.Element {
  const { searchParams, update: updateQueryParams } = useQueryParams();
  const [selectedTab, selectTab] = useState<TTab>(() => {
    const tab = searchParams.get('tab') as TTab;
    if (tabs.map(({ key }) => key).includes(tab)) return tab;

    return defaultTab;
  });

  useEffect(() => {
    document.getElementsByTagName('main')?.[0]?.scrollTo({ top: 0 });
  }, [selectedTab]);

  function handleDownload() {
    window.print();
  }

  return (
    <Wrapper
      actions={[
        {
          children: <Trans i18nKey="commons.actions.download" />,
          color: 'primary',
          key: 'download',
          onClick: handleDownload,
          startIcon: <DownloadIcon />,
          variant: 'outlined',
        },
      ]}
      title={<Trans i18nKey={titleKey} />}
    >
      <Box padding={2}>
        <Tabs
          onChange={(_, value) => selectTab(value)}
          sx={{
            '@media print': {
              display: 'none',
            },
          }}
          TabIndicatorProps={{ style: { display: 'none' } }}
          value={selectedTab}
        >
          {tabs.map(({ key, icon, label }) => (
            <Tab
              aria-controls={`tabpanel-${key}`}
              icon={icon}
              iconPosition="start"
              id={`tab-${key}`}
              key={key}
              label={label}
              onClick={() => updateQueryParams({ tab: key })}
              sx={({ palette }) => ({
                borderRadius: 1000,
                minHeight: 0,
                textTransform: 'initial',
                '&.Mui-selected': {
                  backgroundColor: palette.secondary.light,
                  color: palette.secondary.contrastText,
                },
              })}
              value={key}
            />
          ))}
        </Tabs>
        {tabs.map(({ key, Tab }) => (
          <StyledTabPanel
            className={selectedTab === key ? 'active' : ''}
            key={key}
            selectedValue={key}
            value={key}
          >
            <Tab />
          </StyledTabPanel>
        ))}
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled(Card)`
  && {
    .card-header {
      justify-content: flex-end;

      h6 {
        display: none;
      }
    }

    @media print {
      .card-header {
        justify-content: flex-start;

        h6 {
          display: block;
        }

        button {
          display: none;
        }
      }

      .MuiPaper-root {
        box-shadow: none;
      }
    }
  }
`;

const StyledTabPanel = styled(TabPanel)`
  display: none;

  &.active {
    display: block;
  }

  @media print {
    display: block;
  }
`;

export default DocumentationTab;
