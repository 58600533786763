import {
  BikeRoute,
  BikeRouteService,
  TBackendRideArea,
  TRideArea,
} from '@geovelo-frontends/commons';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';

import Button from '../../../components/button';
import Card from '../../../components/card';
import { areas } from '../../ride/components/data/main-form';

interface IValues {
  title: string;
  description: string;
  area: TRideArea;
  primaryColor: string;
  secondaryColor: string;
}

interface IProps {
  bikeRoute: BikeRoute;
  canWrite: boolean;
  onChange: (bikeRoute: BikeRoute) => void;
}

function MainForm({ canWrite, bikeRoute, onChange }: IProps): JSX.Element {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isSubmitting, values, touched, errors, setValues, handleChange, handleSubmit } =
    useFormik<IValues>({
      initialValues: {
        title: '',
        description: '',
        area: 'local',
        primaryColor: '',
        secondaryColor: '',
      },
      validationSchema: Yup.object().shape({
        title: Yup.string().required(),
        description: Yup.string().required(),
        primaryColor: Yup.string().matches(/^#[0-9a-f]{6}/i),
        secondaryColor: Yup.string().matches(/^#[0-9a-f]{6}/i),
      }),
      onSubmit,
      enableReinitialize: true,
    });

  useEffect(() => {
    init();
  }, []);

  function init() {
    const { title, description, area, primaryColor, secondaryColor } = bikeRoute;

    setValues({
      title: title,
      description: description || '',
      area,
      primaryColor: primaryColor || '',
      secondaryColor: secondaryColor || '',
    });
  }

  async function onSubmit(
    { title, description, area, primaryColor, secondaryColor }: IValues,
    { setSubmitting }: FormikHelpers<IValues>,
  ) {
    setSubmitting(true);

    const areaMap: { [key in TRideArea]: TBackendRideArea } = {
      local: 'LOCAL',
      regional: 'REGIONAL',
      national: 'NATIONAL',
    };

    try {
      const updatedRide = await BikeRouteService.updateBikeRoute(bikeRoute.id, {
        title,
        description,
        area_wide: areaMap[area],
        theme_primary_color: primaryColor,
        theme_secondary_color: secondaryColor,
      });

      onChange(updatedRide);
      enqueueSnackbar(t('cycling-insights.bike_route.updated'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('cycling-insights.bike_route.not_updated'), { variant: 'error' });
    }

    setSubmitting(false);
  }

  return (
    <Card title={<Trans i18nKey="cycling-insights.bike_route.main_form.title" />}>
      <Wrapper>
        <form onSubmit={handleSubmit}>
          <StyledRow>
            <TextField
              fullWidth
              required
              disabled={!canWrite || isSubmitting}
              error={touched.title && Boolean(errors.title)}
              label={t('cycling-insights.bike_route.main_form.bike_route_title')}
              margin="dense"
              name="title"
              onChange={handleChange}
              value={values.title}
              variant="outlined"
            />
          </StyledRow>
          <StyledRow>
            <TextField
              fullWidth
              multiline
              required
              disabled={!canWrite || isSubmitting}
              error={touched.description && Boolean(errors.description)}
              id="description"
              label={t('cycling-insights.bike_route.main_form.description')}
              margin="dense"
              name="description"
              onChange={handleChange}
              rows={3}
              value={values.description}
              variant="outlined"
            />
          </StyledRow>
          <StyledRow>
            <FormControl
              fullWidth
              disabled={!canWrite || isSubmitting}
              margin="dense"
              variant="outlined"
            >
              <InputLabel htmlFor="bike-route-area-input">
                <Trans i18nKey="cycling-insights.bike_route.main_form.area" />
              </InputLabel>
              <Select
                inputProps={{
                  id: 'bike-route-area-input',
                }}
                label={<Trans i18nKey="cycling-insights.bike_route.main_form.area" />}
                name="area"
                onChange={handleChange}
                value={values.area}
              >
                {areas.map(({ key, labelKey }) => (
                  <MenuItem key={key} value={key}>
                    <Trans i18nKey={labelKey} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              disabled={!canWrite || isSubmitting}
              error={touched.primaryColor && Boolean(errors.primaryColor)}
              label={t('cycling-insights.bike_route.main_form.primary_color')}
              margin="dense"
              name="primaryColor"
              onChange={handleChange}
              placeholder="#000000"
              value={values.primaryColor}
              variant="outlined"
            />
            <TextField
              fullWidth
              disabled={!canWrite || isSubmitting}
              error={touched.secondaryColor && Boolean(errors.secondaryColor)}
              label={t('cycling-insights.bike_route.main_form.secondary_color')}
              margin="dense"
              name="secondaryColor"
              onChange={handleChange}
              placeholder="#000000"
              value={values.secondaryColor}
              variant="outlined"
            />
          </StyledRow>
          {canWrite && (
            <StyledActions>
              <Button
                disabled={isSubmitting}
                onClick={() => init()}
                size="medium"
                type="reset"
                variant="outlined"
              >
                <Trans i18nKey="commons.actions.reset" />
              </Button>
              <Button
                color="primary"
                disabled={isSubmitting}
                size="medium"
                startIcon={
                  isSubmitting && <CircularProgress color="inherit" size={16} thickness={4} />
                }
                type="submit"
                variant="contained"
              >
                <Trans i18nKey="commons.actions.update" />
              </Button>
            </StyledActions>
          )}
        </form>
      </Wrapper>
    </Card>
  );
}

const Wrapper = styled.div`
  padding: 16px;
`;

const StyledRow = styled.div`
  align-items: center;
  display: flex;

  &:not(:first-child) {
    margin-top: 12px;
  }

  > *:not(:first-child) {
    margin-left: 16px;
  }
`;

const StyledActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  button:not(:first-child) {
    margin-left: 8px;
  }
`;

export default MainForm;
