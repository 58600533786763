import { Deck } from '@deck.gl/core';
import { ContextProviderValue, DeckProps } from '@deck.gl/core/lib/deck';
import { RefObject, useContext, useEffect, useRef, useState } from 'react';

import { AppContext } from '../../../app/context';

function useDeck({ canvasRef }: { canvasRef: RefObject<HTMLCanvasElement> }) {
  const [initialized, setInitialized] = useState(false);
  const {
    map: { current: currentMap },
  } = useContext(AppContext);
  const deckRef = useRef<Deck>();

  useEffect(() => {
    function handleMoveEnd() {
      if (!currentMap) return;

      const { lat: latitude, lng: longitude } = currentMap.getCenter();

      deckRef.current?.setProps({
        viewState: {
          longitude,
          latitude,
          zoom: currentMap.getZoom(),
          bearing: currentMap.getBearing(),
          pitch: currentMap.getPitch(),
        },
      });
    }

    if (initialized && currentMap) currentMap.on('move', handleMoveEnd);

    return () => {
      currentMap?.off('move', handleMoveEnd);
    };
  }, [initialized]);

  function flipTooltip({
    ele,
    x,
    y,
    viewport,
  }: {
    ele: HTMLElement;
    viewport: { height: number; width: number };
    x: number;
    y: number;
  }) {
    const x1 = x > viewport.width - ele.clientWidth - 16 ? x - ele.clientWidth - 16 : x;
    const y1 = y > viewport.height - ele.clientHeight - 16 ? y - ele.clientHeight - 16 : y;

    return `translate(${x1}px, ${y1}px)`;
  }

  function init() {
    if (!currentMap || deckRef.current || !canvasRef.current) {
      setInitialized(true);
      return;
    }

    const { lat: latitude, lng: longitude } = currentMap.getCenter();

    deckRef.current = new Deck({
      canvas: canvasRef.current,
      width: '100%',
      height: '100%',
      controller: true,
      initialViewState: {
        longitude,
        latitude,
        zoom: currentMap.getZoom(),
        bearing: currentMap.getBearing(),
        pitch: currentMap.getPitch(),
      },
      onViewStateChange: ({ viewState }) => {
        currentMap.jumpTo({
          center: [viewState.longitude, viewState.latitude],
          zoom: viewState.zoom,
          bearing: viewState.bearing,
          pitch: viewState.pitch,
        });
      },
      layers: [],
      effects: [],
    });

    setInitialized(true);
  }

  function update(props: Partial<DeckProps<ContextProviderValue>>) {
    deckRef.current?.setProps(props);
  }

  return { initialized, flipTooltip, init, update };
}

export default useDeck;
