import { BikeRoute, BikeRouteService, IRideMedia, mediaTypesMap } from '@geovelo-frontends/commons';
import { AddCircleOutline, Delete } from '@mui/icons-material';
import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Card from '../../../components/card';

import MediaFormDialog from './media-form-dialog';

interface IProps {
  bikeRoute: BikeRoute;
  canWrite: boolean;
  onChange: (bikeRoute: BikeRoute) => void;
}

function MediaList({ canWrite, bikeRoute, onChange }: IProps): JSX.Element {
  const [mediaToUpdate, setMediaToUpdate] = useState<IRideMedia | null>(null);
  const [formDialogOpen, openFormDialog] = useState(false);
  const { t } = useTranslation();
  const { transitions } = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  function handleFormDialogClose(media?: IRideMedia) {
    if (media) {
      if (mediaToUpdate) {
        const medias = [...bikeRoute.medias];
        medias.splice(
          bikeRoute.medias.findIndex(({ id }) => id === mediaToUpdate.id),
          1,
          media,
        );

        onChange({ ...bikeRoute, medias });
      } else {
        onChange({ ...bikeRoute, medias: [...bikeRoute.medias, media] });
      }
    }

    openFormDialog(false);
    setTimeout(() => setMediaToUpdate(null), transitions.duration.leavingScreen);
  }

  async function handleRemove(id: number) {
    try {
      await BikeRouteService.removeBikeRouteMedia(bikeRoute.id, id);

      const medias = [...bikeRoute.medias];
      medias.splice(
        bikeRoute.medias.findIndex((media) => id === media.id),
        1,
      );

      onChange({ ...bikeRoute, medias });
      enqueueSnackbar(t('cycling-insights.bike_route.updated'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('cycling-insights.bike_route.not_updated'), { variant: 'error' });
    }
  }

  return (
    <>
      <Card
        actions={
          canWrite
            ? [
                {
                  children: <Trans i18nKey="cycling-insights.bike_route.media_list.actions.add" />,
                  color: 'primary',
                  key: 'add',
                  onClick: () => openFormDialog(true),
                  startIcon: <AddCircleOutline />,
                  variant: 'contained',
                },
              ]
            : []
        }
        title={<Trans i18nKey="cycling-insights.bike_route.media_list.title" />}
      >
        {bikeRoute.medias.length > 0 ? (
          <List dense>
            {bikeRoute.medias.map((media, index) => (
              <Fragment key={media.id}>
                {index > 0 && <Divider />}
                {canWrite ? (
                  <ListItemButton
                    onClick={() => {
                      setMediaToUpdate(media);
                      openFormDialog(true);
                    }}
                  >
                    <MediaListItemContent
                      canWrite={canWrite}
                      handleRemove={handleRemove}
                      index={index}
                      media={media}
                    />
                  </ListItemButton>
                ) : (
                  <ListItem>
                    <MediaListItemContent
                      canWrite={canWrite}
                      handleRemove={handleRemove}
                      index={index}
                      media={media}
                    />
                  </ListItem>
                )}
              </Fragment>
            ))}
          </List>
        ) : (
          <Wrapper>
            <Typography color="textSecondary" variant="caption">
              <Trans i18nKey="cycling-insights.bike_route.media_list.empty" />
            </Typography>
          </Wrapper>
        )}
      </Card>
      <MediaFormDialog
        bikeRoute={bikeRoute}
        media={mediaToUpdate}
        onClose={handleFormDialogClose}
        open={formDialogOpen}
      />
    </>
  );
}

function MediaListItemContent({
  canWrite,
  index,
  media: { id, title, copyright, type },
  handleRemove,
}: {
  canWrite: boolean;
  handleRemove: (id: number) => void;
  index: number;
  media: IRideMedia;
}): JSX.Element {
  const { palette } = useTheme();
  const mediaType = mediaTypesMap.find(({ key }) => type === key);

  return (
    <>
      <ListItemAvatar>
        <Avatar style={{ backgroundColor: palette.primary.main }}>
          {mediaType ? <mediaType.Icon /> : <></>}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          title || (
            <Trans
              i18nKey="cycling-insights.bike_route.media_list.item_title"
              values={{ index: index + 1 }}
            />
          )
        }
        secondary={copyright && <>&copy; {copyright}</>}
      />
      {canWrite && (
        <ListItemSecondaryAction>
          <Tooltip placement="left" title={<Trans i18nKey="commons.actions.remove" />}>
            <IconButton onClick={() => id && handleRemove(id)} size="small">
              <Delete color="error" />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      )}
    </>
  );
}

const Wrapper = styled.div`
  padding: 16px;
`;

export default MediaList;
