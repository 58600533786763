import { Grid, InputAdornment, TextField } from '@mui/material';

const durationsCount = 4;

export const durations = Array(durationsCount)
  .fill(null)
  .map((_, index) => index);

interface IProps {
  errors: { [key: number]: boolean };
  values: { [key: number]: number | '' };
  onChange: (values: { [key: number]: number | '' }) => void;
}

function IsochronesDurationsForm({ values, errors, onChange }: IProps): JSX.Element {
  return (
    <Grid container spacing={1}>
      {durations.map((index) => (
        <Grid item key={index} xs={6}>
          <TextField
            fullWidth
            error={errors[index]}
            InputProps={{
              endAdornment: <InputAdornment position="end">min</InputAdornment>,
              inputProps: { min: 1 },
            }}
            margin="none"
            onChange={({ target: { value } }) =>
              onChange({ ...values, [index]: value !== '' ? parseInt(value) : '' })
            }
            size="small"
            type="number"
            value={values[index] !== undefined ? values[index] : ''}
            variant="outlined"
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default IsochronesDurationsForm;
