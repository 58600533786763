import { Report, ReportService, useCancellablePromise } from '@geovelo-frontends/commons';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import TabIntroduction from '../../../../components/tab-introduction';
import useDraw, { polygonLayerId } from '../../../../hooks/map/draw';
import { TOutletContext } from '../../../../layouts/page/container';
import { TQAPageContext } from '../../context';

import ExclusionZonesList from './list';

import { MapMouseEvent } from '!maplibre-gl';

function ExclusionZonesForm(context: TQAPageContext & TOutletContext): JSX.Element {
  const {
    period,
    exclusionZones: { data, setData, selectZone },
  } = context;
  const [highlightedZoneId, setHighlightedZoneId] = useState<number | null>(null);
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);
  const {
    map: { current: currentMap },
  } = useContext(AppContext);
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    initialized,
    init: initDraw,
    destroy: destroyDraw,
    drawZones: updateDraw,
  } = useDraw(currentMap);

  useEffect(() => {
    return () => {
      cancelPromises();
      destroyDraw();
    };
  }, []);

  useEffect(() => {
    if (currentMap) {
      initDraw();
    }
  }, [currentMap]);

  useEffect(() => {
    getReports();
  }, [period.values.current]);

  useEffect(() => {
    function _handleZoneClick(
      event?: MapMouseEvent & {
        features?: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[] | undefined;
      },
    ) {
      const feature = event?.features?.[0];
      if (feature?.properties?.id && typeof feature.properties.id === 'number')
        setHighlightedZoneId(feature.properties.id);
    }

    function handleZoneMouseEnter() {
      if (currentMap) currentMap.getCanvas().style.cursor = 'pointer';
    }

    function handleZoneMouseLeave() {
      if (currentMap) currentMap.getCanvas().style.cursor = 'grab';
    }

    if (initialized && data) {
      const polygons: GeoJSON.Feature[] = [];
      const lines: GeoJSON.Feature[] = [];
      data.forEach(({ id, geometry }) => {
        if (geometry) {
          if (geometry.type === 'MultiPolygon') {
            geometry.coordinates.forEach((coordinates) => {
              polygons.push({
                type: 'Feature',
                geometry: { type: 'Polygon', coordinates },
                properties: { id },
              });
            });
          } else {
            geometry.coordinates.forEach((coordinates, index) =>
              lines.push({
                type: 'Feature',
                geometry: { type: 'LineString', coordinates },
                properties: { lineIndex: index },
              }),
            );
          }
        }
      });

      updateDraw(
        { type: 'FeatureCollection', features: polygons },
        { type: 'FeatureCollection', features: lines },
      );

      currentMap?.on('click', polygonLayerId, _handleZoneClick);
      currentMap?.on('mouseenter', polygonLayerId, handleZoneMouseEnter);
      currentMap?.on('mouseleave', polygonLayerId, handleZoneMouseLeave);
    }

    return () => {
      currentMap?.off('click', polygonLayerId, _handleZoneClick);
      currentMap?.off('mouseenter', polygonLayerId, handleZoneMouseEnter);
      currentMap?.off('mouseleave', polygonLayerId, handleZoneMouseLeave);
    };
  }, [data, initialized]);

  async function getReports() {
    cancelPromises();
    setData(undefined);

    try {
      const _reports: Report[] = [];
      let hasNext = true;
      let page = 1;

      while (hasNext) {
        const { reports, next } = await cancellablePromise(
          ReportService.getReports({
            ongoingDateStart: period.values.current.from,
            ongoingDateEnd: period.values.current.to,
            typeCodes: ['exclusionZone'],
            page: page++,
            rowsPerPage: 500,
            query:
              '{id, geometry, creator{username}, created, updated, description, status, drop_zones, start_date, end_date}',
          }),
        );

        _reports.push(...reports);
        hasNext = Boolean(next);
      }

      setData(_reports);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.reports.cartographic_reports.server_error'), {
          variant: 'error',
        });
      }
    }
  }

  async function handleRemove(reportId: number | null): Promise<void> {
    if (reportId === null) return;

    setRemoveLoading(true);
    try {
      await ReportService.deleteReport(reportId);

      if (data) {
        const newReports = [...data];
        newReports.splice(
          newReports.findIndex(({ id }) => reportId === id),
          1,
        );

        setData(newReports);
      }
    } catch {
      enqueueSnackbar(t('commons.report.not_deleted'));
    }
  }

  return (
    <Box display="flex" flexDirection="column" gap={3} minHeight="calc(100% - 32px)">
      <TabIntroduction title="cycling-insights.qa.introduction.exclusion_zones" />
      <ExclusionZonesList
        data={data}
        highlightedZoneId={highlightedZoneId}
        loading={removeLoading}
        onDelete={handleRemove}
        period={period}
        selectZone={selectZone}
        setHighlightedZoneId={setHighlightedZoneId}
      />
    </Box>
  );
}

export default ExclusionZonesForm;
