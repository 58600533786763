import {
  FileInput,
  GISFile,
  GISFileMessage,
  GISService,
  TFile,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { AccountCircle, CommentsDisabled, FilePresent, Send } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  DialogProps,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import Dialog from '../../../../components/dialog';

function ChatDialog({
  selectedFile,
  onClose,
  ...props
}: Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
  selectedFile: GISFile | null;
}): JSX.Element {
  const [messages, setMessages] = useState<GISFileMessage[]>();
  const [message, setMessage] = useState('');
  const [file, setFile] = useState<TFile | null | undefined>(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    user: { current: currentUser },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    if (props.open && selectedFile !== null) {
      setMessage('');
      getMessages(selectedFile.id);
    }

    return () => {
      cancelPromises();
    };
  }, [props.open, selectedFile]);

  async function getMessages(fileId: number) {
    setMessages(undefined);

    try {
      const _messages = await cancellablePromise(
        GISService.getFileMessages({
          fileId,
          query: '{creator{email, username}, created, message, attachment}',
        }),
      );

      setMessages(_messages);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.facilities.gis_files.chat_dialog.server_error'), {
          variant: 'error',
        });
      }
    }
  }

  async function handleSend() {
    if (selectedFile === null || (!(file instanceof File) && file !== null)) return;

    setSubmitting(true);

    try {
      const newMessage = await GISService.sendMessage({
        fileId: selectedFile.id,
        message: message || '',
        document: file,
      });

      if (messages) setMessages([...messages, newMessage]);

      setMessage('');
      setFile(null);
    } catch (err) {
      enqueueSnackbar(t('cycling-insights.facilities.gis_files.chat_dialog.not_sent'), {
        variant: 'error',
      });
    }

    setSubmitting(false);
  }

  return (
    <Dialog
      fullWidth
      cancelTitle={<Trans i18nKey="commons.actions.close" />}
      dialogTitle="gis-file-chat-dialog"
      loading={isSubmitting}
      maxWidth="sm"
      onCancel={() => onClose()}
      scroll="paper"
      title={
        <Box>
          <Trans i18nKey="cycling-insights.facilities.gis_files.chat_dialog.title" />
          {selectedFile ? ` "${selectedFile.title}"` : ''}
        </Box>
      }
      {...props}
    >
      {currentUser && messages ? (
        <>
          {messages.length > 0 ? (
            <Box display="flex" flexDirection="column" gap={2}>
              {messages.map(({ created, creator, message, attachmentUrl }, index) => {
                const ownMessage = currentUser.email === creator.email;

                return (
                  <Box display="flex" flexDirection="column" gap={0.5} key={index}>
                    {attachmentUrl && (
                      <Box
                        display="flex"
                        flexDirection={ownMessage ? 'row-reverse' : 'row'}
                        gap={2}
                      >
                        <Box width={24} />
                        <IconButton
                          href={attachmentUrl}
                          LinkComponent="a"
                          rel="noreferrer"
                          size="small"
                          target="_blank"
                        >
                          <FilePresent color="action" />
                        </IconButton>
                      </Box>
                    )}
                    <Box display="flex" flexDirection={ownMessage ? 'row-reverse' : 'row'} gap={2}>
                      <Box flexShrink={0}>
                        {!ownMessage ? (
                          <Tooltip title={creator.username || creator.email}>
                            <AccountCircle color="primary" />
                          </Tooltip>
                        ) : (
                          <AccountCircle color="secondary" />
                        )}
                      </Box>
                      <Box
                        alignItems={ownMessage ? 'flex-end' : 'flex-start'}
                        display="flex"
                        flexDirection="column"
                        flexGrow={1}
                        gap={1}
                      >
                        {message && (
                          <Card variant="outlined">
                            <CardContent sx={{ '&&': { paddingX: 2, paddingY: 1 } }}>
                              <Typography variant="body2">{message}</Typography>
                            </CardContent>
                          </Card>
                        )}
                        <Typography color="textSecondary" variant="caption">
                          {creator.username || creator.email} &bull; {created.format('LLL')}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          ) : (
            <Box alignItems="center" display="flex" flexDirection="column" gap={1} paddingY={2}>
              <CommentsDisabled color="action" fontSize="large" />
              <Typography color="textSecondary">
                <Trans i18nKey="cycling-insights.facilities.gis_files.chat_dialog.empty_state" />
              </Typography>
            </Box>
          )}
          <Box alignItems="flex-end" display="flex" gap={2}>
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Box display="flex" flexDirection="column">
                <TextField
                  fullWidth
                  multiline
                  required
                  disabled={isSubmitting}
                  margin="dense"
                  name="description"
                  onChange={({ target: { value } }) => setMessage(value)}
                  placeholder={
                    t('cycling-insights.facilities.gis_files.chat_dialog.new_message') || ''
                  }
                  rows={2}
                  value={message}
                  variant="outlined"
                />
                {currentUser.isGeovelo && (
                  <FileInput disabled={isSubmitting} file={file} onChange={setFile} type="*" />
                )}
              </Box>
            </Box>
            <Box>
              <IconButton
                color="secondary"
                disabled={isSubmitting}
                onClick={handleSend}
                size="small"
              >
                {isSubmitting ? (
                  <CircularProgress color="inherit" size={20} thickness={4} />
                ) : (
                  <Send fontSize="small" />
                )}
              </IconButton>
            </Box>
          </Box>
        </>
      ) : (
        <Box display="flex" flexDirection="column" gap={2}>
          {[1, 2, 3].map((key) => (
            <Box display="flex" gap={2} key={key}>
              <Box flexShrink={0}>
                <Skeleton height={24} variant="circular" width={24} />
              </Box>
              <Box display="flex" flexDirection="column" flexGrow={1} gap={1}>
                <Card variant="outlined">
                  <CardContent sx={{ '&&': { paddingX: 2, paddingY: 1 } }}>
                    <Typography variant="body2">
                      <Skeleton variant="text" width={300} />
                    </Typography>
                  </CardContent>
                </Card>
                <Typography color="textSecondary" variant="caption">
                  <Skeleton variant="text" width={200} />
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Dialog>
  );
}

export default ChatDialog;
