import { TApiStatType, useClipboard, useFileSaver, useUnits } from '@geovelo-frontends/commons';
import { CheckCircle, Error, FileCopy, SentimentDissatisfied } from '@mui/icons-material';
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Button from '../../../../components/button';
import Card from '../../../../components/card';
import DualChart from '../../../../components/dual-chart';
import DownloadIcon from '../../../../components/icons/download';
import { IApiPageContext } from '../../context';

import { typesMap } from './types';

function ApiKeysData({
  period,
  apiKeys: {
    currentStats,
    list,
    selectedIndex,
    selectedTypes,
    selectedResponseType,
    requestsStats,
    responsesStats,
    selectResponseType,
  },
}: IApiPageContext): JSX.Element {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { downloadCSV } = useFileSaver();
  const { copy: copyToClipboard } = useClipboard(
    () => enqueueSnackbar(t('cycling-insights.api.keys.key_copied'), { variant: 'success' }),
    () => enqueueSnackbar(t('cycling-insights.api.keys.key_not_copied'), { variant: 'error' }),
  );
  const { formatNumber, toTime, toPercentage } = useUnits();

  function handleCopy() {
    const apiKey = list?.[selectedIndex || 0];

    if (apiKey) copyToClipboard(apiKey.keyNumber);
  }

  function handleDownload() {
    if (!currentStats) return;

    const currentPeriodValues: {
      [key: string]: { m2m: number; routeComputer: number; responseTimes: number; total: number };
    } = {};

    if (period.values.current.unit === 'month') {
      currentStats.forEach(({ date, stats }) => {
        const key = date.startOf('month').format('YYYY-MM');
        if (currentPeriodValues[key]) {
          currentPeriodValues[key].m2m += stats.m2m.count;
          currentPeriodValues[key].routeComputer += stats.routeComputer.count;
          if (stats.all.medianResponseTime) {
            currentPeriodValues[key].responseTimes +=
              stats.all.medianResponseTime * stats.all.count;
            currentPeriodValues[key].total += stats.all.count;
          }
        } else
          currentPeriodValues[key] = {
            m2m: stats.m2m.count,
            routeComputer: stats.routeComputer.count,
            responseTimes: stats.all.medianResponseTime
              ? stats.all.medianResponseTime * stats.all.count
              : 0,
            total: stats.all.count,
          };
      });
    }

    downloadCSV(
      `geovelo-api-key-${apiKey?.label}-${period.values.current.from.format(
        'YYYY-MM-DD',
      )}_${period.values.current.to.format('YYYY-MM-DD')}.csv`,
      [
        t('commons.stats.date_label'),
        ...Object.values(typesMap).map((type) => t(type.labelKey)),
        t('commons.stats.response_time'),
      ],
      period.values.current.unit === 'month'
        ? Object.keys(currentPeriodValues).map((key) => [
            key,
            ...Object.keys(typesMap).map(
              (typeKey) => currentPeriodValues[key][typeKey as 'm2m' | 'routeComputer'] || 0,
            ),
            currentPeriodValues[key].responseTimes
              ? Math.round(
                  10000 * (currentPeriodValues[key].responseTimes / currentPeriodValues[key].total),
                ) / 10000
              : 0,
          ])
        : period.values.current.times.map((timeStamp) => {
            const date = moment(timeStamp);
            const dayStats = currentStats.find(({ date: statsDate }) =>
              statsDate.isSame(date, 'day'),
            );
            return [
              date.format('YYYY-MM-DD'),
              ...Object.keys(typesMap).map(
                (key) => dayStats?.stats[key as 'm2m' | 'routeComputer'].count || 0,
              ),
              dayStats?.stats.all.medianResponseTime || 0,
            ];
          }),
    );
  }

  if (list && list.length === 0) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        gap={2}
        justifyContent="center"
        padding={2}
        sx={{ backgroundColor: 'whitesmoke', overflowY: 'auto' }}
      >
        <SentimentDissatisfied color="action" fontSize="large" />
        <Typography align="center" color="textSecondary">
          <Trans i18nKey="cycling-insights.api.keys.empty_state" />
        </Typography>
      </Box>
    );
  }

  if (list && (selectedIndex === null || !list[selectedIndex])) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        gap={2}
        justifyContent="center"
        padding={2}
        sx={{ backgroundColor: 'whitesmoke', overflowY: 'auto' }}
      >
        <SentimentDissatisfied color="action" fontSize="large" />
        <Typography align="center" color="textSecondary">
          <Trans i18nKey="cycling-insights.api.keys.no_key_selected" />
        </Typography>
      </Box>
    );
  }

  const apiKey = list?.[selectedIndex || 0];
  const nbRequests = requestsStats?.current.values.reduce((res, { value }) => res + value, 0);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      gap={2}
      padding={2}
      sx={{ backgroundColor: 'whitesmoke', overflowY: 'auto' }}
    >
      <Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            disabled={!currentStats}
            onClick={handleDownload}
            size="small"
            startIcon={<DownloadIcon />}
            variant="outlined"
          >
            <Trans i18nKey="commons.actions.download" />
          </Button>
        </Box>
        <Box alignItems="center" display="flex" gap={1}>
          <Typography component="div" variant="h6">
            {apiKey?.label || <Skeleton variant="text" width={300} />}
          </Typography>
          {apiKey?.id !== 'all' &&
            (apiKey ? (
              apiKey.enabled ? (
                <CheckCircle sx={{ color: green[500] }} />
              ) : (
                <Error color="error" />
              )
            ) : (
              <Skeleton height={24} variant="circular" width={24} />
            ))}
        </Box>
        {apiKey?.id !== 'all' && (
          <>
            <Typography color="textSecondary" variant="body2">
              {apiKey ? (
                <Trans
                  i18nKey="commons.created_on_fem"
                  values={{ created: apiKey?.creationDate?.format('L') }}
                />
              ) : (
                <Skeleton variant="text" width={150} />
              )}
            </Typography>
            <Box alignItems="center" display="flex" gap={1}>
              <Typography
                color="textSecondary"
                component="div"
                title={apiKey?.label}
                variant="body2"
              >
                {apiKey ? (
                  <span>{apiKey.keyNumberCutted}</span>
                ) : (
                  <Skeleton variant="text" width={75} />
                )}
              </Typography>
              <Tooltip placement="right" title={<Trans i18nKey="commons.actions.copy_key" />}>
                <span>
                  <IconButton disabled={!apiKey} onClick={handleCopy} size="small">
                    <FileCopy fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          </>
        )}
      </Box>
      <Card
        title={
          <Trans
            components={[
              requestsStats ? (
                <Fragment key={0} />
              ) : (
                <Skeleton key={0} sx={{ display: 'inline-block' }} variant="text" width={50} />
              ),
              requestsStats ? (
                <span
                  key={1}
                  style={{
                    color:
                      requestsStats.progression > 0
                        ? green[500]
                        : requestsStats.progression < 0
                          ? red[500]
                          : 'inherit',
                  }}
                />
              ) : (
                <Skeleton key={1} sx={{ display: 'inline-block' }} variant="text" width={50} />
              ),
            ]}
            count={nbRequests || 0}
            i18nKey="commons.stats.requests"
            values={{
              count: nbRequests || 0,
              countFormatted: nbRequests !== undefined ? formatNumber(nbRequests) : '',
              progression: requestsStats
                ? `${requestsStats.progression >= 0 ? '+' : ''}${toPercentage(
                    requestsStats.progression,
                    1,
                  )}`
                : '',
            }}
          />
        }
        titleVariant="body1"
      >
        <Box display="flex" flexDirection="column" gap={2} padding={2}>
          <Box alignItems="center" display="flex" gap={1}>
            {selectedTypes.map((key, index) => (
              <Fragment key={key}>
                {index > 0 && (
                  <Typography color="textSecondary" variant="caption">
                    &bull;
                  </Typography>
                )}
                <Typography color="textSecondary" variant="caption">
                  {requestsStats?.current ? (
                    <>
                      <Trans i18nKey={typesMap[key].labelKey} />:{' '}
                      {requestsStats.current.detailedCount?.[key]}
                    </>
                  ) : (
                    <Skeleton variant="text" width={200} />
                  )}
                </Typography>
              </Fragment>
            ))}
          </Box>
          <DualChart
            chartId="requests-chart"
            labelFormat={formatNumber}
            period={period}
            stats={requestsStats}
          />
        </Box>
      </Card>
      <Card
        actions={
          <>
            <Box flexGrow={1} />
            <FormControl margin="dense" size="small" variant="outlined">
              <Select
                name="type"
                onChange={({ target: { value } }) => selectResponseType(value as TApiStatType)}
                value={selectedResponseType}
              >
                {selectedTypes.map((key) => (
                  <MenuItem key={key} value={key}>
                    <Trans i18nKey={typesMap[key].labelKey} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        }
        title={
          <Trans
            components={[
              responsesStats ? (
                <Fragment key={0} />
              ) : (
                <Skeleton sx={{ display: 'inline-block' }} variant="text" width={50} />
              ),
            ]}
            i18nKey="commons.stats.response_time"
            values={{
              count: responsesStats
                ? responsesStats.current.values.reduce((res, { value }) => res + value, 0)
                : '',
            }}
          />
        }
        titleVariant="body1"
      >
        <Box display="flex" flexDirection="column" gap={2} padding={2}>
          <DualChart
            computeMean
            chartId="responses-chart"
            labelFormat={(value) => toTime(value, 'ms')}
            period={period}
            stats={responsesStats}
            type="line"
          />
        </Box>
      </Card>
    </Box>
  );
}

export default ApiKeysData;
