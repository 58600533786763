import { IRideStep, Ride, RideService } from '@geovelo-frontends/commons';
import { Delete, Photo, Subscriptions } from '@mui/icons-material';
import {
  Avatar,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IProps {
  canWrite: boolean;
  onClick: (step: IRideStep) => void;
  onMediasManaged: (step: IRideStep) => void;
  onPhotosManaged: (step: IRideStep) => void;
  onRemove: (step: IRideStep) => void;
  onReordered: (steps: IRideStep[]) => void;
  ride: Ride;
}

function RideStepsList({
  canWrite,
  ride,
  onClick,
  onPhotosManaged,
  onMediasManaged,
  onReordered,
  onRemove,
}: IProps): JSX.Element {
  const [orderedSteps, orderSteps] = useState<IRideStep[]>(ride.steps);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    orderSteps(ride.steps);
  }, [ride.steps]);

  async function handleDragEnd({ source, destination }: DropResult) {
    const rideId = ride.id;
    if (!rideId || !destination || source.index === destination.index) return;

    const reorderedSteps = [...orderedSteps];
    const [movedStep] = reorderedSteps.splice(source.index, 1);
    reorderedSteps.splice(destination.index, 0, movedStep);

    setLoading(true);
    orderSteps(reorderedSteps);

    try {
      await Promise.all(
        reorderedSteps.map(({ id, order }, index) =>
          id && index + 1 !== order
            ? RideService.updateRideStep(rideId, id, { order: index + 1 })
            : undefined,
        ),
      );

      reorderedSteps.forEach((step, index) => {
        step.order = index + 1;
      });

      onReordered(reorderedSteps);
      enqueueSnackbar(t('cycling-insights.ride.updated'), { variant: 'success' });
    } catch {
      orderSteps(ride.steps);
      enqueueSnackbar(t('cycling-insights.ride.not_updated'), { variant: 'error' });
    }

    setLoading(false);
  }

  return (
    <>
      {loading && <LinearProgress color="secondary" />}
      {orderedSteps.length > 0 ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ height: '100%', overflowY: 'auto' }}
              >
                <List dense>
                  {orderedSteps.map((step, index) => {
                    const { id, poi } = step;

                    return (
                      <Draggable
                        draggableId={`${id}`}
                        index={index}
                        isDragDisabled={loading || !canWrite}
                        key={id}
                      >
                        {(provided, { isDragging }) => (
                          <ListItemWrapper
                            className={isDragging ? 'dragging' : ''}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {poi ? (
                              <ListItem>
                                <RideStepsListItemContent
                                  canWrite={canWrite}
                                  loading={loading}
                                  onMediasManaged={onMediasManaged}
                                  onPhotosManaged={onPhotosManaged}
                                  onRemove={onRemove}
                                  step={step}
                                />
                              </ListItem>
                            ) : (
                              <ListItemButton disabled={loading} onClick={() => onClick(step)}>
                                <RideStepsListItemContent
                                  canWrite={canWrite}
                                  loading={loading}
                                  onMediasManaged={onMediasManaged}
                                  onPhotosManaged={onPhotosManaged}
                                  onRemove={onRemove}
                                  step={step}
                                />
                              </ListItemButton>
                            )}
                            {index < orderedSteps.length - 1 && !isDragging && <Divider />}
                          </ListItemWrapper>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </List>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Wrapper>
          <Typography align="center" color="textSecondary" component="p" variant="caption">
            <Trans components={[<br key={0} />]} i18nKey="cycling-insights.ride.steps.empty" />
          </Typography>
        </Wrapper>
      )}
    </>
  );
}

function RideStepsListItemContent({
  canWrite,
  loading,
  step,
  onMediasManaged,
  onPhotosManaged,
  onRemove,
}: {
  canWrite: boolean;
  loading: boolean;
  onMediasManaged: (step: IRideStep) => void;
  onPhotosManaged: (step: IRideStep) => void;
  onRemove: (step: IRideStep) => void;
  step: IRideStep;
}): JSX.Element {
  const { order, title, photos, medias, poi } = step;

  return (
    <>
      <ListItemAvatar>
        <StyledAvatar>{order}</StyledAvatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        primaryTypographyProps={{ noWrap: true }}
        secondary={
          <>
            <Trans
              count={photos.length}
              i18nKey="cycling-insights.ride.steps.item.photos"
              values={{ count: photos.length }}
            />
            &nbsp;&bull;&nbsp;
            <Trans
              count={medias.length}
              i18nKey="cycling-insights.ride.steps.item.medias"
              values={{ count: medias.length }}
            />
          </>
        }
        sx={{ paddingRight: '110px' }}
      />
      <StyledListItemSecondaryAction>
        {!poi && (
          <>
            <Tooltip
              placement="left"
              title={
                canWrite ? (
                  <Trans i18nKey="cycling-insights.ride.steps.item.actions.manage_photos" />
                ) : (
                  <Trans i18nKey="cycling-insights.ride.steps.item.actions.see_photos" />
                )
              }
            >
              <span>
                <IconButton
                  disabled={(!canWrite && step.photos.length === 0) || loading}
                  onClick={(event) => {
                    event.stopPropagation();
                    onPhotosManaged(step);
                  }}
                  size="small"
                >
                  <Photo fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              placement="left"
              title={
                canWrite ? (
                  <Trans i18nKey="cycling-insights.ride.steps.item.actions.manage_medias" />
                ) : (
                  <Trans i18nKey="cycling-insights.ride.steps.item.actions.see_medias" />
                )
              }
            >
              <span>
                <IconButton
                  disabled={(!canWrite && step.medias.length === 0) || loading}
                  onClick={(event) => {
                    event.stopPropagation();
                    onMediasManaged(step);
                  }}
                  size="small"
                >
                  <Subscriptions fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
        {canWrite && (
          <Tooltip placement="left" title={<Trans i18nKey="commons.actions.remove" />}>
            <span>
              <IconButton
                disabled={loading}
                onClick={(event) => {
                  event.stopPropagation();
                  onRemove(step);
                }}
                size="small"
              >
                <Delete color={loading ? 'inherit' : 'error'} fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </StyledListItemSecondaryAction>
    </>
  );
}

const Wrapper = styled.div`
  max-height: calc(100% - 32px);
  overflow-y: auto;
  padding: 16px;
`;

const ListItemWrapper = styled.div`
  &.dragging {
    background-color: ${({ theme }) => lighten(theme.palette.secondary.main, 0.7)};
  }
`;

const StyledAvatar = styled(Avatar)`
  && {
    background-color: #ff9800;
    height: 36px;
    width: 36px;
  }
`;

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)`
  button {
    margin-left: 4px;
  }
`;

export default RideStepsList;
