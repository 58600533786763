import {
  AuthService,
  DashboardPages,
  PartnerContract,
  PartnerService,
  useAmplitudeTracker,
} from '@geovelo-frontends/commons';
import { InfoOutlined, LogoutOutlined } from '@mui/icons-material';
import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../app/context';
import Button from '../../components/button';
import ConfirmDialog from '../../components/confirm-dialog';

import ManageContractsData from './components/contract-data';
import ManageContractFormDialog from './components/contract-form-dialog';
import ManageContractsList from './components/contracts-list';
import Language from './components/language';
import Subscription from './components/subscription';
import UserData from './components/user-data';
import ManageUsersTable from './components/users-table';

function SettingsPage(): JSX.Element {
  const [selectedContractIndex, selectContractIndex] = useState<number>();
  const [contractToRemove, setContractToRemove] = useState<number>();
  const [formDialogOpen, openFormDialog] = useState(false);
  const [contractUpdated, updateContract] = useState<PartnerContract>();
  const [removing, setRemoving] = useState(false);
  const [selectedTab, selectTab] = useState<'profile' | 'users' | 'contracts'>('profile');
  const [signingOut, setSigningOut] = useState(false);
  const {
    user: { current: currentUser },
    partner: { current: currentPartner, contracts, contractTemplates },
    actions: {
      setCurrentPage,
      setCurrentTab,
      setCurrentUser,
      setCurrentPartnerContracts,
      setCurrentPartnerActiveContracts,
    },
  } = useContext(AppContext);
  const { trackEvent } = useAmplitudeTracker();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  useEffect(() => {
    setCurrentPage(DashboardPages.Settings);
    setCurrentTab(undefined);
  }, []);

  useEffect(() => {
    if (currentUser && currentPartner) {
      trackEvent('Page Visited', {
        pathname: window.location.pathname,
        partner_id: currentPartner.id,
        partner_code: currentPartner.code,
      });
    }
  }, [currentUser, currentPartner]);

  async function handleSignOut() {
    setSigningOut(true);

    try {
      await AuthService.signOut();
      setCurrentUser(null);
    } catch (err) {
      console.error(err);
    }

    setSigningOut(false);
  }

  function handleUpdate(id: number | string) {
    if (typeof id !== 'number') return;

    const contract = contracts?.find((contract) => contract.id === id);
    if (!contract) return;

    updateContract(contract);
    openFormDialog(true);
  }

  function handleManageContractFormDialogClose(contract?: PartnerContract) {
    if (contracts && contract) {
      const now = moment();
      let newContracts: PartnerContract[] = [];
      if (contractUpdated) {
        const index = contracts.findIndex(({ id }) => id === contractUpdated.id);
        if (index >= 0) {
          newContracts = [...contracts];
          newContracts.splice(index, 1, contract);
          newContracts = newContracts.sort(PartnerService.sortContracts);
        }
      } else {
        newContracts = [...contracts, contract].sort(PartnerService.sortContracts);
      }

      setCurrentPartnerContracts(newContracts);
      setCurrentPartnerActiveContracts(
        [...newContracts].filter(
          ({ startDateTime, endDateTime }) =>
            startDateTime.isSameOrBefore(now) && endDateTime?.isSameOrAfter(now),
        ),
      );
      enqueueSnackbar(
        t('cycling-insights.admin.manage_contracts.form_dialog.success', {
          context: contractUpdated ? 'update' : undefined,
        }),
      );
    }

    openFormDialog(false);
    setTimeout(() => updateContract(undefined), theme.transitions.duration.leavingScreen);
  }

  async function handleRemove() {
    if (!currentPartner || contractToRemove === undefined) return;

    setRemoving(true);
    const now = moment();

    try {
      await PartnerService.deleteContract(contractToRemove, { partner: currentPartner });

      if (contracts) {
        const index = contracts.findIndex(({ id }) => id === contractToRemove);
        if (index >= 0) {
          const contractsUpdated = [...contracts];
          contractsUpdated.splice(index, 1);

          setCurrentPartnerContracts(contractsUpdated);
          setCurrentPartnerActiveContracts(
            [...contractsUpdated].filter(
              ({ startDateTime, endDateTime }) =>
                startDateTime.isSameOrBefore(now) && endDateTime?.isSameOrAfter(now),
            ),
          );
          selectContractIndex(0);
        }
      }

      setContractToRemove(undefined);
      enqueueSnackbar(t('cycling-insights.admin.manage_contracts.remove_dialog.success'));
    } catch {
      enqueueSnackbar(t('cycling-insights.admin.manage_contracts.remove_dialog.server_error'));
    }

    setRemoving(false);
  }

  return (
    <Box
      bgcolor="#F6F7FB"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      gap={3}
      paddingX={14}
      paddingY={8}
      sx={{ overflowY: 'auto' }}
    >
      <Typography
        color="primary"
        component="h2"
        flexDirection="column"
        fontSize="2rem"
        fontWeight="700"
      >
        <Trans i18nKey="commons.settings" />
      </Typography>
      <Tabs
        aria-label="Ride edition tabs"
        onChange={(_, value) => selectTab(value)}
        sx={(theme) => ({
          '.MuiTab-root': {
            color: theme.palette.primary.main,
          },
          '.Mui-selected': {
            color: '#528BE8',
          },
        })}
        TabIndicatorProps={{ sx: { backgroundColor: '#528BE8' } }}
        value={selectedTab}
      >
        <Tab label={<Trans i18nKey="cycling-insights.settings.tabs.profile" />} value="profile" />
        {currentPartner?.dashboardTabsPermissions.manageUsers !== 'none' && (
          <Tab label={<Trans i18nKey="cycling-insights.settings.tabs.users" />} value="users" />
        )}
        {currentPartner?.dashboardTabsPermissions.manageContracts !== 'none' && (
          <Tab
            label={<Trans i18nKey="cycling-insights.settings.tabs.contracts" />}
            value="contracts"
          />
        )}
      </Tabs>
      {selectedTab === 'profile' && (
        <Box display="flex" flexDirection="column" gap={3}>
          <UserData />
          <Subscription />
          <Language />
          <Box>
            <Button
              color="primary"
              disabled={signingOut}
              onClick={handleSignOut}
              startIcon={<LogoutOutlined />}
              variant="outlined"
            >
              <Trans i18nKey="commons.actions.sign_out" />
            </Button>
          </Box>
        </Box>
      )}
      {selectedTab === 'users' &&
        currentPartner?.dashboardTabsPermissions.manageUsers !== 'none' && <ManageUsersTable />}
      {selectedTab === 'contracts' &&
        currentPartner?.dashboardTabsPermissions.manageContracts !== 'none' && (
          <>
            {!contracts || contracts.length === 0 ? (
              <Box
                alignItems="center"
                bgcolor="#FFF5D0"
                borderRadius={4}
                display="flex"
                gap={3}
                justifyContent="space-between"
                padding={2}
              >
                <Box alignItems="center" display="flex" gap={2}>
                  <InfoOutlined sx={{ color: '#BB8A1A' }} />
                  <Typography fontSize="0.875rem" fontWeight={400}>
                    <Trans i18nKey="cycling-insights.admin.manage_contracts.no_contract" />
                  </Typography>
                </Box>
                <Button
                  color="primary"
                  component="a"
                  href={`mailto:emma.gaucher@geovelo.fr`}
                  size="medium"
                  variant="contained"
                >
                  <Trans i18nKey="cycling-insights.admin.manage_contracts.contact" />
                </Button>
              </Box>
            ) : (
              <Box display="flex" gap={3}>
                <Box flexShrink={0} width={340}>
                  <ManageContractsList
                    contracts={contracts}
                    onAdd={() => openFormDialog(true)}
                    onRemove={(key) => typeof key === 'number' && setContractToRemove(key)}
                    onUpdate={handleUpdate}
                    selectedIndex={selectedContractIndex}
                    selectIndex={selectContractIndex}
                  />
                </Box>
                {selectedContractIndex !== undefined && (
                  <ManageContractsData selectedContractIndex={selectedContractIndex} />
                )}
                {contractTemplates && contractTemplates.length > 0 && (
                  <ManageContractFormDialog
                    contract={contractUpdated}
                    onClose={handleManageContractFormDialogClose}
                    open={formDialogOpen}
                  />
                )}
                <ConfirmDialog
                  dialogTitle="point-attendance-remove-dialog"
                  loading={removing}
                  onCancel={() => setContractToRemove(undefined)}
                  onConfirm={handleRemove}
                  open={Boolean(contractToRemove)}
                  title={
                    <Trans i18nKey="cycling-insights.admin.manage_contracts.remove_dialog.title" />
                  }
                />
              </Box>
            )}
          </>
        )}
    </Box>
  );
}

export default SettingsPage;
