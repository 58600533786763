import { IOriginDestinationInput, TDayPeriod, TTimePeriod } from '@geovelo-frontends/commons';

export function toOriginDestinationInput({
  timePeriod,
  dayPeriod,
}: {
  timePeriod: TTimePeriod | 'peak';
  dayPeriod: TDayPeriod;
}): Omit<IOriginDestinationInput, 'period'> {
  let hoursOfDay: number[];
  let daysOfWeek: number[];
  if (dayPeriod === 'week') daysOfWeek = [1, 2, 3, 4, 5];
  else if (dayPeriod === 'weekend') daysOfWeek = [0, 6];
  else daysOfWeek = [0, 1, 2, 3, 4, 5, 6];
  switch (timePeriod) {
    case 'all_day':
      hoursOfDay = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
      ];
      break;
    case 'am_peak':
      hoursOfDay = [7, 8, 9];
      break;
    case 'pm_peak':
      hoursOfDay = [17, 18, 19];
      break;
    case 'peak':
      hoursOfDay = [7, 8, 9, 17, 18, 19];
      break;
    case 'off_peak':
      hoursOfDay = [0, 1, 2, 3, 4, 5, 6, 10, 11, 12, 13, 14, 15, 16, 20, 21, 22, 23];
      break;
    default:
      hoursOfDay = [0];
      break;
  }

  return {
    timePeriod: hoursOfDay,
    daysOfWeek,
  };
}
