import { Box, Typography } from '@mui/material';
import { CategoryScale, Chart } from 'chart.js';
import { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { IBicycleObservatoryPageContext } from '../../context';

const FluidityChartId = 'fluidity-chart';

function FluidityChart({
  averageSpeeds: { sectionsCountBySpeed },
}: IBicycleObservatoryPageContext): JSX.Element {
  const { t } = useTranslation();
  const fluidityChartRef = useRef<Chart<'bar'>>();

  useEffect(() => {
    Chart.register(CategoryScale);
    const fluidityChartCtx = document.getElementById(FluidityChartId);
    if (fluidityChartCtx && fluidityChartCtx instanceof HTMLCanvasElement) {
      fluidityChartRef.current?.destroy();

      fluidityChartRef.current = new Chart(fluidityChartCtx, {
        type: 'bar',
        data: {
          labels: ['<10', '12', '14', '16', '18', '20', '22', '24', '26', '28', '>30'],
          datasets: [
            {
              data: [],
              backgroundColor: '#3E7BDF',
              borderRadius: { topLeft: 8, topRight: 8 },
              barThickness: 8,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                title: ([{ label }]) =>
                  t(
                    `cycling-insights.usage.fluidity.chart.tooltip.${
                      label === '>30' ? 'more_than' : label === '<10' ? 'less_than' : 'title'
                    }`,
                    {
                      from: label === '>30' ? 30 : parseInt(label) - 1,
                      to: label === '<10' ? 10 : parseInt(label) + 1,
                    },
                  ) || '',
                label: ({ parsed: { y } }) =>
                  t('cycling-insights.usage.fluidity.chart.tooltip.label', { count: y }) || '',
              },
            },
            title: {
              display: false,
            },
          },
          scales: {
            x: {
              title: {
                align: 'end',
                display: true,
                text: 'km/h',
              },
              grid: { display: false },
            },
            y: {
              title: {
                align: 'end',
                display: true,
                text: t('cycling-insights.usage.fluidity.chart.roads_number') || '',
              },
              grid: { display: false },
            },
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (fluidityChartRef.current && sectionsCountBySpeed) {
      fluidityChartRef.current.data.datasets[0].data = sectionsCountBySpeed;
      fluidityChartRef.current.update();
    }
  }, [sectionsCountBySpeed]);

  return (
    <Box display="flex" flexDirection="column">
      <Typography fontSize="1.125rem" fontWeight={700} marginBottom={3}>
        <Trans i18nKey="cycling-insights.usage.fluidity.chart.title" />
      </Typography>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box
          alignSelf="center"
          height={200}
          maxWidth="100%"
          overflow="hidden"
          position="relative"
          sx={{ overflow: 'hidden' }}
          width="100%"
        >
          <canvas id={FluidityChartId} style={{ position: 'relative', zIndex: 2 }} />
        </Box>
      </Box>
    </Box>
  );
}

export default FluidityChart;
