import { Report, ReportType } from '@geovelo-frontends/commons';
import { CheckCircle, Error } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { ThemeProvider } from '@mui/material/styles';
import { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import theme from '../../app/theme';

export type TReportPopupRef = {
  update: (updatedReport: Report, detailedPopup: boolean, types?: ReportType[]) => void;
};

interface IProps {
  detailedPopup?: boolean;
  report: Report;
  types?: ReportType[];
}

function ReportPopup(
  { detailedPopup: _detailedPopup, report: _report, types: _types }: IProps,
  ref: Ref<TReportPopupRef>,
): JSX.Element {
  const [report, setReport] = useState<Report>(_report);
  const [detailedPopup, setDetailedPopup] = useState<boolean | undefined>(_detailedPopup);
  const [types, setTypes] = useState<ReportType[] | undefined>(_types);

  useImperativeHandle(ref, () => ({
    update: (updatedReport, detailed, reportsTypes) => {
      setReport(updatedReport);
      setDetailedPopup(detailed);
      setTypes(reportsTypes);
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        {detailedPopup ? (
          <Box display="flex" flexDirection="column">
            <Box alignItems="center" display="flex" gap={2}>
              {report.isClosed ? (
                <Tooltip placement="right" title={<Trans i18nKey="commons.statuses.processed" />}>
                  <CheckCircle style={{ color: green[500] }} />
                </Tooltip>
              ) : (
                <Tooltip placement="right" title={<Trans i18nKey="commons.statuses.unprocessed" />}>
                  <Error style={{ color: red[500] }} />
                </Tooltip>
              )}
              <Typography variant="h6">
                <Trans
                  i18nKey={
                    types?.find((value) => value.code === report.typeCode)?.titleKey ||
                    'commons.report.default_title'
                  }
                />
                <>&nbsp;#{report.id}</>
              </Typography>
            </Box>
            {report.startDate && (
              <Typography color="textSecondary" component="p" variant="caption">
                <Trans
                  i18nKey="commons.periods.from_to"
                  values={{
                    from: report.startDate.format('LL'),
                    to: report.endDate && report.isTemporary ? report.endDate.format('LL') : '?',
                  }}
                />
              </Typography>
            )}
            <Typography color="textSecondary" variant="caption">
              <Trans
                components={[<i key={0} />]}
                i18nKey="commons.created_by_on"
                values={{
                  creator: report.creator || 'unknown',
                  created: report.created.format('ll'),
                }}
              />
              {report.source && (
                <>
                  {' '}
                  (
                  <Typography color="textSecondary" variant="caption">
                    <Trans i18nKey={report.source.title} />
                  </Typography>
                  )
                </>
              )}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              <Trans
                i18nKey="cycling-insights.reports.cartographic_reports.detail.updated_on"
                values={{ date: report.updated.format('LL') }}
              />
            </Typography>
          </Box>
        ) : (
          <Typography>
            <Trans
              i18nKey="cycling-insights.reports.cartographic_reports.detail.title"
              values={{ id: report.id }}
            />
          </Typography>
        )}
      </Wrapper>
    </ThemeProvider>
  );
}

const Wrapper = styled.div`
  padding: 16px;
  width: 268px;
`;

export default forwardRef(ReportPopup);
