import { IEnvironment, defaultEnvironment } from '@geovelo-frontends/commons';

let _environment: IEnvironment & {
  bicycleFacilitiesUrl: string;
  helpPDF: { size: number; url: string };
  highlightedEventId?: number;
  widgetUrl: string;
  amplitude: { apiKey: string };
} = {
  ...defaultEnvironment,
  source: 'DaSHbOarD',
  apiKey: '270014c0-7966-4a0b-9fe4-918f66fbd0c1',
  bicycleFacilitiesUrl: 'https://amenagements-cyclables-dev.geovelo.fr/',
  widgetUrl: 'https://widget-geovelo-dev.web.app/iframe',
  helpPDF: {
    url: 'https://drive.google.com/file/d/1Ab7DmGP1hFJKREAFnvx4NBHpRz69yQr4/view?usp=share_link',
    size: 21,
  },
  crisp: {
    formPluginUrl:
      'https://plugins.crisp.chat/urn:crisp.im:contact-form:0/contact/5bba97eb-3532-469f-aaae-352acea16bf9',
  },
  amplitude: { apiKey: 'd4ac9234e968a7e94e993cff99669d80' },
};

if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development') {
  _environment = {
    ..._environment,
    highlightedEventId: 6,
  };
  _environment.tileServer.styleDirs.facilities = 'facilities_v2-vector';
}

if (process.env.REACT_APP_ENV === 'staging') {
  _environment = {
    ..._environment,
    bicycleFacilitiesUrl: 'https://amenagements-cyclables-staging.fr/',
    widgetUrl: 'https://widget-geovelo.web.app/iframe',
    highlightedEventId: 39,
  };
}

if (process.env.REACT_APP_ENV === 'production') {
  _environment = {
    ..._environment,
    bicycleFacilitiesUrl: 'https://amenagements-cyclables.fr/',
    widgetUrl: 'https://widget-geovelo.web.app/iframe',
    highlightedEventId: 39,
  };
}

export const environment = _environment;
