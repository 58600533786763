import { Period } from '@geovelo-frontends/commons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function usePeriod(): {
  getPrevPeriod: (props: { current: Period; prev: Period }) => Period;
  getTitle: (period: Period) => string | undefined;
} {
  const { t } = useTranslation();

  function getTitle({ from, to, title, titleKey }: Period) {
    return (
      title ||
      (titleKey &&
        t(titleKey, {
          from: from.format('L'),
          to: to.format('L'),
          dayFrom: from.format('Do MMM'),
          dayTo: to.format('Do MMM'),
          year: from.format('YYYY'),
        })) ||
      ''
    );
  }

  function getPrevPeriod({
    current: currentPeriod,
    prev: _prevPeriod,
  }: {
    current: Period;
    prev: Period;
  }) {
    const now = moment();

    if (currentPeriod.type === 'week') {
      return new Period(
        'week',
        _prevPeriod.from.clone(),
        _prevPeriod.to
          .clone()
          .set('day', currentPeriod.to.isAfter(now) ? now.get('day') : currentPeriod.to.get('day')),
      );
    }
    if (currentPeriod.type === 'month') {
      return new Period(
        'month',
        _prevPeriod.from.clone(),
        _prevPeriod.to
          .clone()
          .set(
            'date',
            Math.min(
              currentPeriod.to.isAfter(now) ? now.get('date') : currentPeriod.to.get('date'),
              _prevPeriod.nbDays,
            ),
          ),
      );
    }
    if (currentPeriod.type === 'year') {
      return new Period(
        'year',
        _prevPeriod.from.clone(),
        _prevPeriod.to
          .clone()
          .set(
            'dayOfYear',
            currentPeriod.to.isAfter(now)
              ? now.get('dayOfYear')
              : currentPeriod.to.get('dayOfYear'),
          ),
      );
    }

    return _prevPeriod.clone();
  }

  return { getTitle, getPrevPeriod };
}

export default usePeriod;
