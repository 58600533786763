import { TApiStatType } from '@geovelo-frontends/commons';

export const types: TApiStatType[] = ['routeComputer', 'm2m', 'routeComputedSimplified'];

export const typesMap: { [key in TApiStatType]: { labelKey: string } } = {
  routeComputer: { labelKey: 'cycling-insights.api.requests.types.route_computer' },
  routeComputedSimplified: {
    labelKey: 'cycling-insights.api.requests.types.route_computer_simplified',
  },
  m2m: { labelKey: 'cycling-insights.api.requests.types.m2m' },
};
