import { useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { TQAPageContext } from '../../context';

import ExclusionZonesForm from './form';

function ExclusionZonesFormTab(): JSX.Element {
  const context = useOutletContext<TQAPageContext & TOutletContext>();
  const {
    header: { setPrevButtonClick },
    exclusionZones: { selectZone },
  } = context;
  const navigate = useNavigate();

  useEffect(() => {
    setPrevButtonClick(() => () => {
      selectZone();
      navigate('../exclusion-zones', { relative: 'path' });
    });

    return () => {
      setPrevButtonClick(undefined);
    };
  }, []);

  return <PageContentLayout leftPanel={<ExclusionZonesForm {...context} />} />;
}

export default ExclusionZonesFormTab;
