import { TSectionFacility, useUnits } from '@geovelo-frontends/commons';
import { Box, Skeleton, Typography } from '@mui/material';
import { Chart } from 'chart.js';
import { Fragment, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Progression from '../../../../components/progression';
import usePeriod from '../../../../hooks/period';
import { IBicycleObservatoryPageContext } from '../../context';

const roadTypes: Array<{
  key: string;
  color: string;
  labelKey: string;
  subTypes?: { key: TSectionFacility; label: string }[];
}> = [
  {
    key: 'facilities',
    color: '#1DC97E',
    labelKey: 'cycling-insights.usage.roads_use.road_types.facilities',
    subTypes: [
      { key: 'cycleway', label: 'commons.facilities.cycleways' },
      { key: 'greenway', label: 'commons.facilities.greenways' },
    ],
  },
  {
    key: 'other-facilities',
    color: '#3E7BDF',
    labelKey: 'cycling-insights.usage.roads_use.road_types.other_facilities',
    subTypes: [
      { key: 'lane', label: 'commons.facilities.lanes' },
      { key: 'sharebusway', label: 'commons.facilities.sharedBusways' },
      { key: 'opposite', label: 'commons.facilities.opposites' },
      { key: 'mixedfacilities', label: 'commons.facilities.livingStreets' },
    ],
  },
  {
    key: 'no-facilities',
    color: '#F56B84',
    labelKey: 'cycling-insights.usage.roads_use.road_types.no_facilities',
  },
];

const roadsTypesChartId = 'roads-types-chart';

function FrequenciesChart({
  period,
  lastMonthDistance,
  lastYearDistance,
  roadsUse: { distancesByFacilities, prevDistancesByFacilities },
}: IBicycleObservatoryPageContext & {
  lastMonthDistance?: { normal: number; extrapolated: number };
  lastYearDistance?: { normal: number; extrapolated: number };
}): JSX.Element {
  const { t } = useTranslation();
  const { toDistance } = useUnits();
  const { getTitle } = usePeriod();
  const roadsTypesChartRef = useRef<Chart<'doughnut'>>();

  useEffect(() => {
    const roadsTypesChartCtx = document.getElementById(roadsTypesChartId);
    if (roadsTypesChartCtx && roadsTypesChartCtx instanceof HTMLCanvasElement) {
      roadsTypesChartRef.current?.destroy();

      roadsTypesChartRef.current = new Chart(roadsTypesChartCtx, {
        type: 'doughnut',
        data: {
          labels: roadTypes.map(({ labelKey }) => t(labelKey)),
          datasets: distancesByFacilities
            ? [
                {
                  data: roadTypes.map(({ key, subTypes }) => {
                    if (key === 'no-facilities') return distancesByFacilities['none'];
                    else {
                      return (
                        subTypes?.reduce<number>((res, { key }) => {
                          res += distancesByFacilities[key];
                          return res;
                        }, 0) || 0
                      );
                    }
                  }),
                  backgroundColor: roadTypes.map(({ color }) => color),
                },
              ]
            : [
                {
                  data: [70, 30],
                  backgroundColor: ['#E3E7EE', '#F6F7FB'],
                },
              ],
        },
        options: {
          cutout: '80%',
          events: distancesByFacilities
            ? ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove']
            : [],
          responsive: true,
          maintainAspectRatio: false,
          plugins: distancesByFacilities
            ? {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: ({ parsed, label }) =>
                      ` ${label}: ${
                        Math.round((parsed / distancesByFacilities.all) * 1000) / 10
                      } %`,
                  },
                },
                title: {
                  display: false,
                },
              }
            : {
                legend: {
                  display: false,
                },
                title: {
                  display: false,
                },
              },
        },
      });
    }
  }, [distancesByFacilities]);

  return (
    <Box display="flex" flexDirection="column">
      <Typography fontSize="1.125rem" fontWeight={700} marginBottom={3}>
        <Trans i18nKey="cycling-insights.usage.roads_use.chart_title" />
      </Typography>
      <Box display="flex" flexDirection="row" gap={4} justifyContent="space-between">
        <Box
          alignSelf="center"
          height={200}
          maxWidth="100%"
          minWidth={200}
          overflow="hidden"
          position="relative"
          sx={{ overflow: 'hidden' }}
          width={200}
        >
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
            left="36px"
            position="absolute"
            textAlign="center"
            width="128px"
          >
            <Typography fontWeight={700} variant="body1">
              {distancesByFacilities ? (
                toDistance(distancesByFacilities.allExtrapolated || distancesByFacilities.all)
              ) : (
                <Skeleton variant="text" width={128} />
              )}
            </Typography>
            <Typography variant="body2">
              {t('commons.stats.traveled').toLocaleLowerCase()}
            </Typography>
          </Box>
          <canvas id={roadsTypesChartId} style={{ position: 'relative', zIndex: 2 }} />
        </Box>
        <Box display="flex" flexDirection="column" gap={3} justifyContent="center">
          {period.comparisonEnabled ? (
            <>
              {(((!!prevDistancesByFacilities?.allExtrapolated ||
                !prevDistancesByFacilities?.all) &&
                (!!distancesByFacilities?.allExtrapolated || !distancesByFacilities?.all)) ||
                (!prevDistancesByFacilities?.allExtrapolated &&
                  !distancesByFacilities?.allExtrapolated)) && (
                <Progression
                  formatDiff={toDistance}
                  label={getTitle(period.values.prev)}
                  prevStat={
                    prevDistancesByFacilities?.allExtrapolated || prevDistancesByFacilities?.all
                  }
                  stat={distancesByFacilities?.allExtrapolated || distancesByFacilities?.all}
                />
              )}
            </>
          ) : (
            <>
              {(((!!lastMonthDistance?.extrapolated || !lastMonthDistance?.normal) &&
                (!!distancesByFacilities?.allExtrapolated || !distancesByFacilities?.all)) ||
                (!lastMonthDistance?.extrapolated && !distancesByFacilities?.allExtrapolated)) && (
                <Progression
                  formatDiff={toDistance}
                  label={t('commons.periods.prev_month').toLowerCase()}
                  prevStat={lastMonthDistance?.extrapolated || lastMonthDistance?.normal}
                  stat={distancesByFacilities?.allExtrapolated || distancesByFacilities?.all}
                />
              )}
              {(((!!lastYearDistance?.extrapolated || !lastYearDistance?.normal) &&
                (!!distancesByFacilities?.allExtrapolated || !distancesByFacilities?.all)) ||
                (!lastYearDistance?.extrapolated && !distancesByFacilities?.allExtrapolated)) && (
                <Progression
                  formatDiff={toDistance}
                  label={t('commons.periods.last_year').toLowerCase()}
                  prevStat={lastYearDistance?.extrapolated || lastYearDistance?.normal}
                  stat={distancesByFacilities?.allExtrapolated || distancesByFacilities?.all}
                />
              )}
            </>
          )}
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap" gap={1} marginTop={4}>
        {roadTypes.map(({ key, color, labelKey, subTypes }) => {
          const currentPercentage = distancesByFacilities?.all
            ? Math.round(
                ((key === 'no-facilities'
                  ? distancesByFacilities['none']
                  : subTypes?.reduce<number>((res, { key }) => {
                      res += distancesByFacilities[key];
                      return res;
                    }, 0) || 0) *
                  1000) /
                  distancesByFacilities.all,
              ) / 10
            : 0;
          const prevPercentage = prevDistancesByFacilities?.all
            ? Math.round(
                ((key === 'no-facilities'
                  ? prevDistancesByFacilities['none']
                  : subTypes?.reduce<number>((res, { key }) => {
                      res += prevDistancesByFacilities[key];
                      return res;
                    }, 0) || 0) *
                  1000) /
                  prevDistancesByFacilities.all,
              ) / 10
            : 0;
          const comparedPercentages = Math.round(10 * (currentPercentage - prevPercentage)) / 10;
          return (
            <Fragment key={key}>
              {distancesByFacilities ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={1}
                  minHeight={40}
                  width={
                    key === 'other-facilities'
                      ? 'calc(((100% - 16px) / 3) + 12px)'
                      : 'calc(((100% - 16px) / 3) - 6px)'
                  }
                >
                  <Box
                    bgcolor={color}
                    borderRadius="5px"
                    height="10px"
                    minWidth="10px"
                    width="10px"
                  />
                  <Box display="flex" flexDirection="column">
                    <Typography variant="caption">
                      <Trans i18nKey={labelKey} /> {currentPercentage}&nbsp;%
                      {period.comparisonEnabled && typeof comparedPercentages === 'number' && (
                        <>
                          {' '}
                          {comparedPercentages < 0 ? (
                            <span
                              style={{ color: '#A42C49', fontSize: '0.625rem', fontWeight: '700' }}
                            >
                              - {Math.abs(comparedPercentages)}&nbsp;%
                            </span>
                          ) : (
                            <span
                              style={{ color: '#038B63', fontSize: '0.625rem', fontWeight: '700' }}
                            >
                              + {comparedPercentages}&nbsp;%
                            </span>
                          )}
                        </>
                      )}
                    </Typography>
                    <Box color="#5D687E" display="flex" flexDirection="column">
                      {subTypes?.map(({ key, label }) => {
                        return (
                          <Typography fontSize="0.625rem" key={key}>
                            <Trans i18nKey={label} /> :{' '}
                            {distancesByFacilities.all
                              ? Math.round(
                                  (distancesByFacilities[key] * 1000) / distancesByFacilities.all,
                                ) / 10
                              : 0}
                            &nbsp;%
                          </Typography>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Skeleton variant="text" width="calc((100% - 16px) / 3)" />
              )}
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
}

export default FrequenciesChart;
