import {
  CyclabilityZone,
  Flow,
  OriginDestinationService,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { ExpandMore, FilterList } from '@mui/icons-material';
import { Box, Checkbox, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../../components/button';
import { IPeriodFormProps } from '../../../../components/form/period';
import Ranking from '../../../../components/ranking';
import { toOriginDestinationInput } from '../../../../models/origin-destination-form';

import { journeysTypePeriodProps } from './journeys-type';

const types = ['internal', 'external'] as const;

export type TType = (typeof types)[number];

function MainFlows({
  period,
  journeysType,
  zones,
  externalZones,
  flows: _flows,
}: {
  externalZones: CyclabilityZone[] | undefined;
  flows: Flow[] | undefined;
  journeysType: 'all' | 'leisure' | 'work';
  period: IPeriodFormProps;
  zones: CyclabilityZone[] | undefined;
}): JSX.Element {
  const [selectedTypes, selectTypes] = useState<{ [key in TType]: boolean }>({
    internal: true,
    external: true,
  });
  const [typesMenuAnchorEl, setTypesMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [flows, setFlows] = useState<Flow[]>();
  const [pageSize, setPageSize] = useState(5);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    if (zones) getFlows();

    return () => {
      cancelPromises();
      setFlows(undefined);
      setPageSize(5);
    };
  }, [zones, journeysType]);

  useEffect(() => {
    setPageSize(5);
  }, [selectedTypes]);

  useEffect(() => {
    if (journeysType === 'all' && _flows) setFlows(_flows);
  }, [_flows]);

  async function getFlows() {
    if (!zones) return;

    if (journeysType === 'all') {
      setFlows(_flows);

      return;
    }

    const {
      values: { current: currentPeriod },
    } = period;

    try {
      const zoneIds = zones.map(({ id }) => id);
      let flows: Flow[];

      if (journeysType === 'leisure') {
        const [outgoingFlows, ...otherPeriodOutgoingFlows] = await cancellablePromise(
          Promise.all(
            journeysTypePeriodProps.leisure.map((periodProps) =>
              OriginDestinationService.getFlows({
                period: currentPeriod.toIPeriod(),
                departureCyclabilityZoneIds: zoneIds,
                ...toOriginDestinationInput(periodProps),
              }),
            ),
          ),
        );

        otherPeriodOutgoingFlows.forEach((otherOutgoingFlows) => {
          otherOutgoingFlows.forEach((otherOutgoingFlow) => {
            const flow = outgoingFlows.find(
              ({ origin, destination }) =>
                origin === otherOutgoingFlow.origin &&
                destination === otherOutgoingFlow.destination,
            );
            if (flow) flow.count += otherOutgoingFlow.count;
            else outgoingFlows.push(otherOutgoingFlow);
          });
        });

        const outgoingFlowsMap = outgoingFlows.reduce<{
          [key: number]: { [key: number]: true };
        }>((res, { origin, destination }) => {
          if (res[origin]) res[origin][destination] = true;
          else res[origin] = { [destination]: true };

          return res;
        }, {});

        const [incomingFlows, ...otherPeriodIncomingFlows] = await cancellablePromise(
          Promise.all(
            journeysTypePeriodProps.leisure.map((periodProps) =>
              OriginDestinationService.getFlows({
                startId: outgoingFlows.length,
                period: currentPeriod.toIPeriod(),
                arrivalCyclabilityZoneIds: zoneIds,
                ...toOriginDestinationInput(periodProps),
              }),
            ),
          ),
        );

        otherPeriodIncomingFlows.forEach((otherIncomingFlows) => {
          otherIncomingFlows.forEach((otherIncomingFlow) => {
            const flow = incomingFlows.find(
              ({ origin, destination }) =>
                origin === otherIncomingFlow.origin &&
                destination === otherIncomingFlow.destination,
            );
            if (flow) flow.count += otherIncomingFlow.count;
            else incomingFlows.push(otherIncomingFlow);
          });
        });

        flows = [
          ...outgoingFlows,
          ...incomingFlows.filter(
            ({ origin, destination }) => !outgoingFlowsMap[origin]?.[destination],
          ),
        ];
      } else {
        const outgoingFlows = await cancellablePromise(
          OriginDestinationService.getFlows({
            period: currentPeriod.toIPeriod(),
            departureCyclabilityZoneIds: zoneIds,
            ...toOriginDestinationInput(journeysTypePeriodProps.work[0]),
          }),
        );

        const outgoingFlowsMap = outgoingFlows.reduce<{ [key: number]: { [key: number]: true } }>(
          (res, { origin, destination }) => {
            if (res[origin]) res[origin][destination] = true;
            else res[origin] = { [destination]: true };

            return res;
          },
          {},
        );

        const incomingFlows = await cancellablePromise(
          OriginDestinationService.getFlows({
            startId: outgoingFlows.length,
            period: currentPeriod.toIPeriod(),
            arrivalCyclabilityZoneIds: zoneIds,
            ...toOriginDestinationInput(journeysTypePeriodProps.work[0]),
          }),
        );

        flows = [
          ...outgoingFlows,
          ...incomingFlows.filter(
            ({ origin, destination }) => !outgoingFlowsMap[origin]?.[destination],
          ),
        ];
      }

      setFlows(flows);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.origin_destination.server_error_zones'), {
          variant: 'error',
        });

        setFlows([]);
      }
    }
  }

  const externalZonesMap =
    externalZones?.reduce<{ [key: number]: boolean }>((res, zone) => {
      res[zone.id] = true;

      return res;
    }, {}) || {};

  const zonesMap =
    zones && externalZones
      ? [...zones, ...externalZones].reduce<{ [key: number]: CyclabilityZone }>((res, zone) => {
          res[zone.id] = zone;

          return res;
        }, {})
      : undefined;

  const filteredFlows =
    zonesMap &&
    flows?.filter(({ origin, destination }) => {
      if (!zonesMap[origin] || !zonesMap[destination]) return false;
      if (!selectedTypes.internal && !externalZonesMap[origin] && !externalZonesMap[destination])
        return false;
      if (!selectedTypes.external && (externalZonesMap[origin] || externalZonesMap[destination]))
        return false;

      return true;
    });

  const flowsCount = filteredFlows?.reduce((res, { count }) => (res += count), 0);

  return (
    <Ranking
      disableProgression
      hasPercentageBar
      action={
        <>
          <Button
            color="inherit"
            endIcon={<FilterList />}
            onClick={({ currentTarget }) => setTypesMenuAnchorEl(currentTarget)}
            sx={{ border: '1px solid #C7CEDC', textTransform: 'initial' }}
            variant="outlined"
          >
            {t('commons.stats.type_label')}
          </Button>
          <Menu
            keepMounted
            anchorEl={typesMenuAnchorEl}
            id="types-menu"
            MenuListProps={{ style: { width: 250 } }}
            onClose={() => setTypesMenuAnchorEl(null)}
            open={Boolean(typesMenuAnchorEl)}
          >
            {types.map((type) => {
              const checked = selectedTypes[type];

              return (
                <MenuItem
                  dense
                  key={type}
                  onClick={() => selectTypes({ ...selectedTypes, [type]: !checked })}
                  value={type}
                >
                  <Checkbox checked={checked} style={{ padding: 4, marginRight: 8 }} />
                  <ListItemText
                    primary={t(`cycling-insights.usage.origin_destination.${type}_other`)}
                  />
                </MenuItem>
              );
            })}
          </Menu>
        </>
      }
      data={
        zonesMap &&
        filteredFlows
          ?.sort((a, b) => b.count - a.count)
          .slice(0, pageSize)
          .map(({ id, count, origin, destination }) => {
            const originZone = zonesMap[origin];
            const destinationZone = zonesMap[destination];

            return {
              id: id,
              title: (
                <>
                  <Typography
                    fontSize="1rem"
                    fontWeight={600}
                    maxWidth="calc(50% - 18px)"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    title={originZone.name}
                    whiteSpace="nowrap"
                  >
                    {originZone.name}
                  </Typography>
                  <Typography fontSize="1rem" fontWeight={600}>
                    &nbsp;{'>'}&nbsp;
                  </Typography>
                  <Typography
                    fontSize="1rem"
                    fontWeight={600}
                    maxWidth="calc(50% - 18px)"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    title={destinationZone.name}
                    whiteSpace="nowrap"
                  >
                    {destinationZone.name}
                  </Typography>
                </>
              ),
              value: count,
            };
          })
      }
      loadingRowsCount={5}
      title="cycling-insights.usage.origin_destination.ranking.title"
      total={flowsCount}
    >
      {filteredFlows && filteredFlows.length > pageSize && (
        <Box display="flex" justifyContent="flex-end" paddingBottom={3} paddingX={3}>
          <Button
            endIcon={<ExpandMore />}
            onClick={() => setPageSize(pageSize + 5)}
            size="small"
            variant="text"
          >
            {t('commons.actions.see_more')}
          </Button>
        </Box>
      )}
    </Ranking>
  );
}

export default MainFlows;
