import { DashboardPages } from '@geovelo-frontends/commons';
import { ReactNode } from 'react';

import useDashboardPage from '../hooks/dashboard-page';
import PageLayout from '../layouts/page';

interface IProps {
  children?: ReactNode;
  descriptionKey?: string;
  page: DashboardPages;
  titleKey: string;
}

function DashboardPage({ page, descriptionKey, titleKey, children }: IProps): JSX.Element {
  const { allowed } = useDashboardPage(page);

  if (!allowed) return <></>;

  return (
    <PageLayout descriptionKey={descriptionKey} titleKey={titleKey}>
      {children}
    </PageLayout>
  );
}

export default DashboardPage;
