import { Box } from '@mui/material';

import { IPromotionPageContext } from '../../context';

import WidgetDetails from './details';

function WidgetData({ widgets: { list, selectedIndex } }: IPromotionPageContext): JSX.Element {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        gap={2}
        padding={2}
        sx={{ backgroundColor: 'whitesmoke', overflowY: 'auto' }}
      >
        <WidgetDetails
          config={
            !list || list.length > 0
              ? (selectedIndex !== null && list?.[selectedIndex]) || undefined
              : null
          }
        />
      </Box>
    </>
  );
}

export default WidgetData;
