import { AppService, getDataFromUrl } from '@geovelo-frontends/commons';
import { useRef, useState } from 'react';

import { LayerSpecification, Map } from '!maplibre-gl';

function useFUBAndAccidents(map: Map | null | undefined): {
  initialized: boolean;
  init: () => void;
  toggle: (
    enabled: boolean,
    type: 'accidents' | 'pointsnoirs_fub' | 'demandes_stationnements_fub',
  ) => void;
  destroy: () => void;
} {
  const [initialized, setInitialized] = useState(false);
  const initializedRef = useRef(false);
  const accidentsVectorLayers = useRef<LayerSpecification[]>();
  const blackSpotsVectorLayers = useRef<LayerSpecification[]>();
  const parkingsVectorLayers = useRef<LayerSpecification[]>();
  const sourcesKeys = useRef<string[]>([]);

  async function init() {
    if (!map || initializedRef.current) return;

    const {
      tileServer: { url: tileServerUrl },
    } = AppService.environment;

    const { sources: accidentsSources, layers: accidentsLayers } = await getDataFromUrl(
      `${tileServerUrl}/styles/accidents-vector/style.json`,
    );

    const { sources: blackSpotsSources, layers: blackSpotsLayers } = await getDataFromUrl(
      `${tileServerUrl}/styles/pointsnoirs_fub-vector/style.json`,
    );

    const { sources: parkingsSources, layers: parkingsLayers } = await getDataFromUrl(
      `${tileServerUrl}/styles/demandes_stationnements_fub-vector/style.json`,
    );

    Object.keys({ ...accidentsSources, ...blackSpotsSources, ...parkingsSources }).map((key) => {
      if (!map?.getSource(key)) {
        map?.addSource(
          key,
          accidentsSources[key]
            ? accidentsSources[key]
            : blackSpotsSources[key]
              ? blackSpotsSources[key]
              : parkingsSources[key],
        );
        sourcesKeys.current.push(key);
      }
    });

    accidentsVectorLayers.current = accidentsLayers;
    blackSpotsVectorLayers.current = blackSpotsLayers;
    parkingsVectorLayers.current = parkingsLayers;

    initializedRef.current = true;
    setInitialized(true);
  }

  function toggle(
    enabled: boolean,
    type: 'accidents' | 'pointsnoirs_fub' | 'demandes_stationnements_fub',
  ) {
    if (!map) return;

    if (enabled) {
      switch (type) {
        case 'accidents':
          accidentsVectorLayers.current?.forEach((layer) => {
            if (!map.getLayer(layer.id)) map.addLayer(layer);
          });
          break;
        case 'pointsnoirs_fub':
          blackSpotsVectorLayers.current?.forEach((layer) => {
            if (layer.paint) layer.paint = { ...layer.paint, 'circle-color': '#FFD600' };
            if (!map.getLayer(layer.id))
              map.addLayer(
                layer,
                map.getLayer('sudden-brakings')
                  ? 'sudden-brakings'
                  : map.getLayer('accidents')
                    ? 'accidents'
                    : undefined,
              );
          });
          break;
        case 'demandes_stationnements_fub':
          parkingsVectorLayers.current?.forEach((layer) => {
            if (layer.paint) layer.paint = { ...layer.paint, 'circle-color': '#FE891A' };
            if (!map.getLayer(layer.id)) map.addLayer(layer);
          });
          break;
      }
    }

    map.getStyle().layers?.forEach((layer) => {
      if (layer.id === type) {
        map.setLayoutProperty(layer.id, 'visibility', enabled ? 'visible' : 'none');
      }
    });
  }

  function destroy() {
    initializedRef.current = false;
    setInitialized(false);
    if (map) {
      [
        ...(accidentsVectorLayers.current || []),
        ...(blackSpotsVectorLayers.current || []),
        ...(parkingsVectorLayers.current || []),
      ].forEach((layer) => {
        if (map.getLayer(layer.id)) map.removeLayer(layer.id);
      });
      sourcesKeys.current.forEach((key) => map.removeSource(key));
    }
    accidentsVectorLayers.current = undefined;
    blackSpotsVectorLayers.current = undefined;
    parkingsVectorLayers.current = undefined;
    sourcesKeys.current = [];
  }

  return { initialized, init, toggle, destroy };
}

export default useFUBAndAccidents;
