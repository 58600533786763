import {
  JOSMService,
  PersonIcon,
  RefRoute,
  RefRouteService,
  cyclingProfiles,
  toBounds,
  useCancellablePromise,
  useLayers,
  useSource,
} from '@geovelo-frontends/commons';
import {
  BugReport,
  CheckOutlined,
  Delete,
  DirectionsBike,
  ErrorOutline,
  Notes,
} from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogProps,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import Map from '../../../../components/map';
import { environment } from '../../../../environment';

import { LngLatBounds, Map as MaplibreMap } from '!maplibre-gl';

const sourceId = 'ref-routes';
const layerId = 'ref-routes';
const wayPointsSourceId = 'way-points';
const wayPointsLayerId = 'way-points';

type TProps = Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
  onRemove: () => void;
  onUpdated: (refRoute: RefRoute) => void;
  refRoute: RefRoute | null;
};

function RefRouteDetailDialog({ refRoute, onRemove, onUpdated, ...props }: TProps): JSX.Element {
  const [map, setMap] = useState<MaplibreMap>();
  const [geometries, setGeometries] = useState<{
    computed?: GeoJSON.LineString;
    expected?: GeoJSON.LineString;
  }>();
  const [josmLoadAndZoomUrl, setJOSMLoadAndZoomUrl] = useState<string>();
  const [mapInitialized, setMapInitialized] = useState(false);
  const [launching, setLaunching] = useState(false);
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { addGeoJSONSource, updateGeoJSONSource, clearGeoJSONSource } = useSource(map, sourceId);
  const {
    addGeoJSONSource: addWayPointsSource,
    updateGeoJSONSource: updateWayPointsSource,
    clearGeoJSONSource: clearWayPointsSource,
  } = useSource(map, wayPointsSourceId);
  const { addLineLayer, addCircleLayer } = useLayers(map);
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    if (props.open) init();
    else {
      cancelPromises();
      setGeometries(undefined);

      if (currentPartner && map) {
        const { north, east, south, west } = currentPartner.bounds;
        map.fitBounds(new LngLatBounds({ lat: south, lng: west }, { lat: north, lng: east }), {
          animate: false,
        });
      }
    }
  }, [props.open]);

  useEffect(() => {
    if (map && !mapInitialized) {
      addGeoJSONSource();
      addWayPointsSource();

      addLineLayer(
        layerId,
        sourceId,
        { 'line-join': 'round', 'line-cap': 'round' },
        {
          'line-color': ['get', 'color'],
          'line-width': ['get', 'width'],
        },
      );

      addCircleLayer(wayPointsLayerId, wayPointsSourceId, {
        'circle-radius': 6,
        'circle-stroke-width': 2,
        'circle-stroke-opacity': 1,
        'circle-color': ['get', 'color'],
        'circle-stroke-color': '#fff',
      });

      map.on('moveend', () => {
        const bounds = map.getBounds();
        const { lat: north, lng: east } = bounds.getNorthEast();
        const { lat: south, lng: west } = bounds.getSouthWest();

        setJOSMLoadAndZoomUrl(JOSMService.getLoadAndZoomUrl({ north, east, south, west }));
      });

      setMapInitialized(true);
    }
  }, [map]);

  useEffect(() => {
    if (map && mapInitialized && refRoute) {
      if (geometries) {
        const { status, wayPoints } = refRoute;
        const { computed, expected } = geometries;

        const features: GeoJSON.Feature<GeoJSON.LineString, { color: string; width: number }>[] =
          [];
        const wayPointsFeatures: GeoJSON.Feature<GeoJSON.Point, { color: string }>[] = [];

        if (computed && status === 'failure') {
          features.push(
            { type: 'Feature', geometry: computed, properties: { color: '#fff', width: 7 } },
            { type: 'Feature', geometry: computed, properties: { color: red[500], width: 5 } },
          );
        }
        if (expected) {
          features.push(
            { type: 'Feature', geometry: expected, properties: { color: '#fff', width: 7 } },
            { type: 'Feature', geometry: expected, properties: { color: green[500], width: 5 } },
          );
        }

        if (wayPoints && wayPoints.length > 0) {
          wayPointsFeatures.push({
            type: 'Feature',
            geometry: wayPoints[0],
            properties: { color: green[500] },
          });
        }

        if (wayPoints && wayPoints.length > 1) {
          wayPointsFeatures.push({
            type: 'Feature',
            geometry: wayPoints[wayPoints.length - 1],
            properties: { color: red[500] },
          });
        }

        updateGeoJSONSource({ type: 'FeatureCollection', features });
        updateWayPointsSource({ type: 'FeatureCollection', features: wayPointsFeatures });

        if (expected) {
          const { north, east, south, west } = toBounds(expected);
          map.fitBounds(new LngLatBounds({ lat: south, lng: west }, { lat: north, lng: east }), {
            animate: false,
          });
        }
      } else {
        clearGeoJSONSource();
        clearWayPointsSource();
      }
    }
  }, [mapInitialized, geometries]);

  async function init() {
    if (!refRoute) return;

    try {
      const { geometry, expectedGeometry } = await cancellablePromise(
        RefRouteService.get(refRoute.id),
      );

      setGeometries({ computed: geometry, expected: expectedGeometry });
    } catch (err) {
      //
    }
  }

  async function handleTest() {
    if (!refRoute) return;

    setLaunching(true);

    try {
      const updatedRefRoute = await RefRouteService.run(refRoute.id);

      onUpdated(updatedRefRoute);
    } catch (err) {
      //
    }

    setLaunching(false);
  }

  return (
    <Dialog fullWidth maxWidth="md" {...props}>
      <DialogTitle>
        <Trans
          i18nKey="cycling-insights.qa.ref_routes.dialog.title"
          values={{ id: refRoute?.id || '' }}
        />
      </DialogTitle>
      <StyledMap mapId="ref-route-map" onInit={setMap} />
      {refRoute && (
        <List dense>
          <ListItem>
            <ListItemIcon>
              <PersonIcon color="action" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Trans
                  components={[<i key={0} />]}
                  i18nKey="commons.created_by_on"
                  values={{
                    creator: refRoute.creator || 'unknown',
                    created: refRoute.created?.format('LLL'),
                  }}
                />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DirectionsBike color="action" />
            </ListItemIcon>
            <ListItemText
              primary={
                refRoute.profile && <Trans i18nKey={cyclingProfiles[refRoute.profile]?.labelKey} />
              }
            />
          </ListItem>
          {refRoute.description && (
            <ListItem>
              <ListItemIcon>
                <Notes color="action" />
              </ListItemIcon>
              <ListItemText primary={refRoute.description} />
            </ListItem>
          )}
          <ListItem>
            <ListItemIcon>
              {refRoute.status === 'success' ? (
                <CheckOutlined style={{ color: green[500] }} />
              ) : (
                <ErrorOutline style={{ color: red[500] }} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={
                <Trans
                  i18nKey={
                    refRoute.successDate
                      ? 'cycling-insights.qa.ref_routes.dialog.succeeded_on'
                      : 'cycling-insights.qa.ref_routes.dialog.never_succeeded'
                  }
                  values={{ date: refRoute.successDate?.format('LLL') }}
                />
              }
            />
          </ListItem>
        </List>
      )}
      <StyledDialogActions>
        <Button
          onClick={onRemove}
          size="small"
          startIcon={<Delete />}
          style={{ borderColor: red[500], color: red[500] }}
          variant="outlined"
        >
          <Trans i18nKey="commons.actions.remove" />
        </Button>
        <Button
          component="a"
          href={josmLoadAndZoomUrl || '#'}
          rel="noreferrer"
          size="small"
          target="_blank"
          variant="outlined"
        >
          <Trans i18nKey="commons.actions.see_on_josm" />
        </Button>
        <Button
          component="a"
          href={`${environment.frontendUrl}/route/${refRoute?.computedRouteId}`}
          rel="noreferrer"
          size="small"
          target="_blank"
          variant="outlined"
        >
          <Trans i18nKey="commons.actions.see_on_geovelo" />
        </Button>
        <StyledSpacer />
        <Button
          color="primary"
          disabled={launching}
          onClick={handleTest}
          size="small"
          startIcon={<BugReport />}
          variant="outlined"
        >
          <Trans i18nKey="cycling-insights.qa.ref_routes.actions.launch_test" />
        </Button>
        <Button onClick={props.onClose} size="small" variant="outlined">
          <Trans i18nKey="commons.actions.close" />
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
}

const StyledMap = styled(Map)`
  height: 400px;
`;

const StyledDialogActions = styled(DialogActions)`
  justify-content: flex-start;
`;

const StyledSpacer = styled.div`
  flex-grow: 1;
`;

export default RefRouteDetailDialog;
