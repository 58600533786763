import { BikeRoute, BikeRouteService, IBikeRouteRide, Ride } from '@geovelo-frontends/commons';
import { AddCircleOutline, Delete, OpenInNew } from '@mui/icons-material';
import {
  Avatar,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Fragment, useContext, useEffect, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../../app/context';
import Card from '../../../components/card';
import ConfirmDialog from '../../../components/confirm-dialog';
import { publicationStatus as publicationStatusMap } from '../../../models/publication-status';

import StepFormDialog from './step-form-dialog';

interface IProps {
  bikeRoute: BikeRoute;
  canWrite: boolean;
  onChange: (bikeRoute: BikeRoute) => void;
  onRidesChange: (rides: Ride[]) => void;
  rides?: Ride[];
}

function StepsList({ canWrite, bikeRoute, rides, onChange, onRidesChange }: IProps): JSX.Element {
  const [orderedSteps, orderSteps] = useState<IBikeRouteRide[]>(bikeRoute.rides);
  const [formDialogOpen, openFormDialog] = useState(false);
  const [stepToRemove, setStepToRemove] = useState<IBikeRouteRide | null>(null);
  const [loading, setLoading] = useState(false);
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    orderSteps(bikeRoute.rides);
  }, [bikeRoute.rides]);

  const loadingItems = [1, 2, 3];

  function handleFormDialogClose(bikeRoute?: BikeRoute) {
    if (bikeRoute) onChange(bikeRoute);
    openFormDialog(false);
  }

  async function handleDragEnd({ source, destination }: DropResult) {
    if (!destination || source.index === destination.index) return;

    const reorderedSteps = [...orderedSteps];
    const [movedStep] = reorderedSteps.splice(source.index, 1);
    reorderedSteps.splice(destination.index, 0, movedStep);

    setLoading(true);
    orderSteps(reorderedSteps);

    try {
      await Promise.all(
        reorderedSteps.map(({ rideId }, index) => {
          const prevRide = reorderedSteps[index - 1];
          const nextRide = reorderedSteps[index + 1];

          return BikeRouteService.updateBikeRouteRide(bikeRoute.id, rideId, {
            order_atob: index + 1,
            ride_atob_previous: prevRide?.rideId || null,
            ride_atob_next: nextRide?.rideId || null,
            order_btoa: reorderedSteps.length - index,
            ride_btoa_previous: nextRide?.rideId || null,
            ride_btoa_next: prevRide?.rideId || null,
          });
        }),
      );

      const updatedBikeRoute = await BikeRouteService.getBikeRoute(bikeRoute.id);

      onChange(updatedBikeRoute);
      enqueueSnackbar(t('cycling-insights.bike_route.updated'), { variant: 'success' });
    } catch {
      orderSteps(bikeRoute.rides);
      enqueueSnackbar(t('cycling-insights.bike_route.not_updated'), { variant: 'error' });
    }

    setLoading(false);
  }

  async function handleStepRemove() {
    if (stepToRemove === null) return;

    setLoading(true);

    try {
      const { rideId } = stepToRemove;

      const newSteps = [...bikeRoute.rides];
      newSteps.splice(
        bikeRoute.rides.findIndex((step) => step.rideId === rideId),
        1,
      );

      await BikeRouteService.removeRideFromBikeRoute(bikeRoute.id, rideId);

      await Promise.all(
        newSteps.map(({ rideId: id }, index) => {
          const prevRide = newSteps[index - 1];
          const nextRide = newSteps[index + 1];

          return BikeRouteService.updateBikeRouteRide(bikeRoute.id, id, {
            order_atob: index + 1,
            ride_atob_previous: prevRide?.rideId || null,
            ride_atob_next: nextRide?.rideId || null,
            order_btoa: newSteps.length - index,
            ride_btoa_previous: nextRide?.rideId || null,
            ride_btoa_next: prevRide?.rideId || null,
          });
        }),
      );

      const updatedBikeRoute = await BikeRouteService.getBikeRoute(bikeRoute.id);

      enqueueSnackbar(t('cycling-insights.bike_route.updated'), { variant: 'success' });
      onChange(updatedBikeRoute);
    } catch {
      enqueueSnackbar(t('cycling-insights.bike_route.not_updated'), { variant: 'error' });
    }

    setStepToRemove(null);
    setLoading(false);
  }

  return (
    <>
      <Card
        actions={[
          {
            children: <Trans i18nKey="cycling-insights.bike_route.steps.actions.add" />,
            color: 'primary',
            disabled: !rides,
            key: 'add',
            onClick: () => openFormDialog(true),
            needWritePermission: true,
            startIcon: <AddCircleOutline />,
            variant: 'contained',
          },
        ]}
        permission={'write'}
        title={<Trans i18nKey="cycling-insights.bike_route.steps.title" />}
      >
        {loading && <LinearProgress color="secondary" />}
        {rides ? (
          bikeRoute.rides.length > 0 ? (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ height: '100%', overflowY: 'auto' }}
                  >
                    <List dense>
                      {orderedSteps.map((step, index) => {
                        const { rideId, order } = step;
                        const ride = rides.find(({ id }) => id === rideId);
                        if (!ride) return <Fragment key={rideId} />;

                        const { title, publicationStatus: publicationStatusKey } = ride;
                        const publicationStatus = publicationStatusKey
                          ? publicationStatusMap[publicationStatusKey]
                          : undefined;

                        return (
                          <Draggable
                            draggableId={`${rideId}`}
                            index={index}
                            isDragDisabled={loading || !canWrite}
                            key={rideId}
                          >
                            {(provided, { isDragging }) => (
                              <ListItemWrapper
                                className={isDragging ? 'dragging' : ''}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ListItem>
                                  <ListItemAvatar>
                                    <StyledAvatar>{order}</StyledAvatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={title}
                                    secondary={
                                      <Typography
                                        style={{ color: publicationStatus?.color }}
                                        variant="caption"
                                      >
                                        <Trans i18nKey={publicationStatus?.titleKey} />
                                      </Typography>
                                    }
                                  />
                                  <StyledListItemSecondaryAction>
                                    <Tooltip
                                      placement="left"
                                      title={
                                        <Trans i18nKey="cycling-insights.bike_route.actions.open_ride" />
                                      }
                                    >
                                      <IconButton
                                        component={Link}
                                        size="small"
                                        state={{ fromBikeRoute: bikeRoute.id }}
                                        to={`/${currentPartner?.code}/promotion/rides/${rideId}`}
                                      >
                                        <OpenInNew fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                    {canWrite && (
                                      <Tooltip
                                        placement="left"
                                        title={<Trans i18nKey="commons.actions.remove" />}
                                      >
                                        <span>
                                          <IconButton
                                            disabled={loading}
                                            onClick={() => setStepToRemove(step)}
                                            size="small"
                                          >
                                            <Delete
                                              color={loading ? 'inherit' : 'error'}
                                              fontSize="small"
                                            />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                    )}
                                  </StyledListItemSecondaryAction>
                                </ListItem>
                                {index < orderedSteps.length - 1 && !isDragging && <Divider />}
                              </ListItemWrapper>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </List>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <Wrapper>
              <Typography color="textSecondary" variant="caption">
                <Trans i18nKey="cycling-insights.bike_route.steps.empty" />
              </Typography>
            </Wrapper>
          )
        ) : (
          <List dense>
            {loadingItems.map((key, index) => (
              <Fragment key={key}>
                <ListItem>
                  <ListItemAvatar>
                    <Skeleton height={36} variant="circular" width={36} />
                  </ListItemAvatar>
                  <ListItemText primary={<Skeleton variant="text" width={200} />} />
                </ListItem>
                {index < loadingItems.length - 1 && <Divider />}
              </Fragment>
            ))}
          </List>
        )}
      </Card>
      <StepFormDialog
        bikeRoute={bikeRoute}
        onClose={handleFormDialogClose}
        onRidesChange={onRidesChange}
        open={formDialogOpen}
        rides={rides}
      />
      <ConfirmDialog
        dialogTitle="bike-route-step-remove-dialog"
        loading={loading}
        onCancel={() => setStepToRemove(null)}
        onConfirm={handleStepRemove}
        open={Boolean(stepToRemove)}
        title={<Trans i18nKey="cycling-insights.bike_route.steps.remove_dialog.title" />}
      />
    </>
  );
}

const Wrapper = styled.div`
  padding: 16px;
`;

const ListItemWrapper = styled.div`
  &.dragging {
    background-color: ${({ theme }) => lighten(theme.palette.secondary.main, 0.7)};
  }
`;

const StyledAvatar = styled(Avatar)`
  && {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    height: 36px;
    width: 36px;
  }
`;

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)`
  button,
  a {
    margin-left: 8px;
  }
`;

export default StepsList;
