import { IRideStep, Ride, RideService } from '@geovelo-frontends/commons';
import { DialogProps, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Dialog from '../../../../components/dialog';

export interface IValues {
  title: string;
  description: string;
}

type TProps = Omit<DialogProps, 'onClose'> & {
  canWrite: boolean;
  ride: Ride;
  step: IRideStep | { location: GeoJSON.Point } | null;
  onClose: (step?: IRideStep) => void;
};

function RideStepFormDialog({ canWrite, ride, step, onClose, ...props }: TProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    isSubmitting,
    values,
    touched,
    errors,
    setValues,
    setSubmitting,
    handleChange,
    handleSubmit,
  } = useFormik<IValues>({
    initialValues: {
      title: '',
      description: '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(),
    }),
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (props.open) {
      if (step && 'title' in step) {
        const { title, description } = step;
        setValues({ title: title || '', description: description || '' });
      } else setValues({ title: '', description: '' });
    }
  }, [props.open]);

  async function onSubmit({ title, description }: IValues) {
    if (!ride.id || !step) return;

    setSubmitting(true);

    try {
      if ('id' in step) {
        if (!step.id) throw new Error('id is undefined');

        const updatedStep = await RideService.updateRideStep(ride.id, step.id, {
          title,
          description,
        });

        onClose(updatedStep);
      } else {
        if (!step.location) throw new Error('location is undefined');

        const newStep = await RideService.addRideStep(ride.id, {
          order: ride.steps.length + 1,
          title,
          description,
          geo_point: step.location,
        });

        onClose(newStep);
      }

      enqueueSnackbar(t('cycling-insights.ride.updated'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.not_updated'), { variant: 'error' });
      setSubmitting(false);
    }
  }

  return (
    <Dialog
      isForm
      cancelTitle={canWrite ? undefined : <Trans i18nKey="commons.actions.close" />}
      confirmHidden={!canWrite}
      confirmTitle={
        step && 'id' in step ? (
          <Trans i18nKey="commons.actions.update" />
        ) : (
          <Trans i18nKey="commons.actions.add" />
        )
      }
      dialogTitle="ride-form-dialog"
      maxWidth="xs"
      onCancel={onClose}
      onConfirm={canWrite ? handleSubmit : undefined}
      title={
        <Trans
          i18nKey="cycling-insights.ride.steps.data_content.title_new"
          values={{ context: canWrite ? (step && 'id' in step ? undefined : 'new') : 'view' }}
        />
      }
      {...props}
    >
      <TextField
        fullWidth
        required
        disabled={!canWrite || isSubmitting}
        error={touched.title && Boolean(errors.title)}
        label={t('commons.title')}
        margin="dense"
        name="title"
        onChange={handleChange}
        size="small"
        value={values.title}
        variant="outlined"
      />
      <TextField
        fullWidth
        multiline
        disabled={!canWrite || isSubmitting}
        error={touched.description && Boolean(errors.description)}
        id="description"
        label={t('commons.description')}
        margin="dense"
        maxRows={6}
        minRows={3}
        name="description"
        onChange={handleChange}
        size="small"
        value={values.description}
        variant="outlined"
      />
    </Dialog>
  );
}

export default RideStepFormDialog;
