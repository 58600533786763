import { Typography } from '@mui/material';
import { Trans } from 'react-i18next';

import ConfirmDialog from '../../components/confirm-dialog';
import { TPublicationDialogStatus, getContext } from '../ride/publication-status-dialog';

interface IProps {
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  status: TPublicationDialogStatus;
}

function PublicationStatusDialog({
  loading,
  open,
  status,
  onCancel,
  onConfirm,
}: IProps): JSX.Element {
  const { context, safe } = getContext(status);

  return (
    <ConfirmDialog
      description={
        <Typography color={safe ? 'initial' : 'error'}>
          <Trans
            i18nKey="cycling-insights.bike_route.publication_status_dialog.description"
            values={{ context }}
          />
        </Typography>
      }
      dialogTitle="bike-route-publication-status-dialog"
      loading={loading}
      onCancel={() => onCancel()}
      onConfirm={onConfirm}
      open={open}
      safe={safe}
      title={
        <Trans
          i18nKey="cycling-insights.bike_route.publication_status_dialog.title"
          values={{ context }}
        />
      }
    />
  );
}

export default PublicationStatusDialog;
