import {
  CyclabilityZone,
  CyclabilityZoneService,
  CyclabilityZoneStats,
  Facilities,
  IPartnerFlowsStats,
  Period,
  TPartnerFlowsRoadsTypes,
  backendAdministrativeLevels,
  childAdministrativeLevels,
  useCancellablePromise,
  useUnits,
} from '@geovelo-frontends/commons';
import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import { Chart } from 'chart.js';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../app/context';

import Card from './card';

const facilities: Array<{ color: string; key: Facilities; labelKey: string }> = [
  { key: Facilities.Cycleways, color: '#165DFF', labelKey: 'commons.facilities.cycleway_other' },
  { key: Facilities.Greenways, color: '#0FC6C2', labelKey: 'commons.facilities.greenway_other' },
  { key: Facilities.Lanes, color: '#F7BA1E', labelKey: 'commons.facilities.lane_other' },
  { key: Facilities.Opposites, color: '#50CD89', labelKey: 'commons.facilities.opposite_other' },
  {
    key: Facilities.SharedBusways,
    color: '#5db5e3',
    labelKey: 'commons.facilities.shared_busway_other',
  },
  {
    key: Facilities.MixedFacilities,
    color: '#D91AD9',
    labelKey: 'commons.facilities.mixed_facilities_other',
  },
];

const roadsTypes: Array<{
  color: string;
  key: TPartnerFlowsRoadsTypes;
  labelKey: string;
}> = [
  {
    color: '#03825c',
    key: 'safe',
    labelKey: 'cycling-insights.usage.monitoring_indicators.cycling_network.roads_used_labels.safe',
  },
  {
    color: '#8eeac2',
    key: 'others',
    labelKey:
      'cycling-insights.usage.monitoring_indicators.cycling_network.roads_used_labels.others',
  },
  {
    color: '#e24949',
    key: 'withoutFacilities',
    labelKey:
      'cycling-insights.usage.monitoring_indicators.cycling_network.roads_used_labels.without_facilities',
  },
];

const roadsTypesChartId = 'roads-types-chart';

function Security({
  period,
  flowsStatistics,
}: {
  flowsStatistics?: { current: IPartnerFlowsStats | null; prev: IPartnerFlowsStats | null } | null;
  period: Period;
}): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const [facilitiesStatistics, setFacilitiesStatistics] = useState<{
    current: CyclabilityZoneStats | null;
    prev: CyclabilityZoneStats | null;
  }>();
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    cancellablePromise: cancellableFacilitiesStatisticsPromise,
    cancelPromises: cancelFacilitiesStatisticsPromise,
  } = useCancellablePromise();
  const { toDistance } = useUnits();
  const roadsTypesChartRef = useRef<Chart<'doughnut'>>();

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized && currentPartner) {
      getFacilitiesStatistics();
    }

    return () => cancelFacilitiesStatisticsPromise();
  }, [initialized, currentPartner, period]);

  useEffect(() => {
    if (flowsStatistics?.current) {
      const {
        current: { roadsUsedPercentages },
      } = flowsStatistics;

      const roadsTypesChartCtx = document.getElementById(roadsTypesChartId);
      if (roadsTypesChartCtx && roadsTypesChartCtx instanceof HTMLCanvasElement) {
        roadsTypesChartRef.current?.destroy();

        roadsTypesChartRef.current = new Chart(roadsTypesChartCtx, {
          type: 'doughnut',
          data: {
            labels: roadsTypes.map(({ labelKey }) => t(labelKey)),
            datasets: [
              {
                data: roadsTypes.map(({ key }) => roadsUsedPercentages[key]),
                backgroundColor: roadsTypes.map(({ color }) => color),
              },
            ],
          },
          options: {
            cutout: '80%',
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: ({ parsed, label }) => ` ${label}: ${parsed} %`,
                },
              },
              title: {
                display: false,
              },
            },
          },
        });
      }
    }
  }, [flowsStatistics]);

  async function getFacilitiesStatistics() {
    setFacilitiesStatistics(undefined);

    if (!currentPartner || !currentPartner.administrativeLevel) return;

    try {
      let zones: CyclabilityZone[], prevZones: CyclabilityZone[];
      if (currentPartner.administrativeLevel === 'city') {
        [{ zones: prevZones }, { zones }] = await cancellableFacilitiesStatisticsPromise(
          Promise.all([
            CyclabilityZoneService.getZones({
              administrativeLevel: 'CITY',
              partnerCode: currentPartner.code,
              considerLivingStreets: true,
              period: period.getPrevPeriod(),
              rowsPerPage: 100,
              query: '{ id, code, administrative_level, name, stats }',
            }),
            CyclabilityZoneService.getZones({
              administrativeLevel: 'CITY',
              partnerCode: currentPartner.code,
              considerLivingStreets: true,
              period: period,
              rowsPerPage: 100,
              query: '{ id, code, administrative_level, name, stats }',
            }),
          ]),
        );
      } else {
        const childAdministrativeLevel =
          childAdministrativeLevels[currentPartner.administrativeLevel];
        if (!childAdministrativeLevel || childAdministrativeLevel === 'world') return;

        [{ zones: prevZones }, { zones }] = await cancellableFacilitiesStatisticsPromise(
          Promise.all([
            CyclabilityZoneService.getZones({
              administrativeLevel: backendAdministrativeLevels[childAdministrativeLevel],
              partnerCode: currentPartner.code,
              considerLivingStreets: true,
              period: period.getPrevPeriod(),
              rowsPerPage: 100,
              query: '{ id, code, administrative_level, name, stats }',
            }),
            CyclabilityZoneService.getZones({
              administrativeLevel: backendAdministrativeLevels[childAdministrativeLevel],
              partnerCode: currentPartner.code,
              considerLivingStreets: true,
              period: period,
              rowsPerPage: 100,
              query: '{ id, code, administrative_level, name, stats }',
            }),
          ]),
        );
      }

      const distances: { [key in Facilities]: number } = {
        [Facilities.Cycleways]: 0,
        [Facilities.Greenways]: 0,
        [Facilities.Lanes]: 0,
        [Facilities.Opposites]: 0,
        [Facilities.SharedBusways]: 0,
        [Facilities.MixedFacilities]: 0,
      };
      const prevDistances: { [key in Facilities]: number } = {
        [Facilities.Cycleways]: 0,
        [Facilities.Greenways]: 0,
        [Facilities.Lanes]: 0,
        [Facilities.Opposites]: 0,
        [Facilities.SharedBusways]: 0,
        [Facilities.MixedFacilities]: 0,
      };

      let cmp = 0;
      zones.forEach(({ stats }) => {
        ++cmp;
        if (stats[0]) {
          Object.values(Facilities).forEach((facilityKey) => {
            distances[facilityKey] += stats[0].distances[facilityKey] || 0;
          });
        }
      });

      let prevCmp = 0;
      prevZones.forEach(({ stats }) => {
        if (stats[0]) {
          ++prevCmp;
          Object.values(Facilities).forEach((facilityKey) => {
            prevDistances[facilityKey] += stats[0].distances[facilityKey] || 0;
          });
        }
      });

      setFacilitiesStatistics({
        current:
          cmp > 0
            ? new CyclabilityZoneStats(period.from, {
                ...distances,
                all: Object.values(distances).reduce((res, distance) => res + distance, 0),
              })
            : null,
        prev:
          prevCmp > 0
            ? new CyclabilityZoneStats(period.from, {
                ...prevDistances,
                all: Object.values(prevDistances).reduce((res, distance) => res + distance, 0),
              })
            : null,
      });
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.facilities.cyclability_zones.form.server_error'));
      }
    }
  }

  const filteredFacilitiesStatistics =
    facilitiesStatistics &&
    facilities.filter(({ key }) => facilitiesStatistics.current?.distances[key]);

  return (
    <>
      <Card
        currentData={
          facilitiesStatistics
            ? toDistance((facilitiesStatistics.current?.distances.all || 0) * 1000)
            : undefined
        }
        progression={
          facilitiesStatistics?.current
            ? (facilitiesStatistics.current.distances.all -
                (facilitiesStatistics.prev?.distances.all || 0)) *
              1000
            : undefined
        }
        progressionUnit="distance"
        titleKey="commons.facilities_label"
        to={`/${currentPartner?.code}/cartographic-data/facilities?period=month&from=${period.from.format(
          'YYYY-MM-DD',
        )}&compare=false`}
      >
        {(!facilitiesStatistics || facilitiesStatistics.current?.distances.all) &&
          (currentPartner?.dashboardTabsPermissions.facilities !== 'none' ||
            (flowsStatistics?.current &&
              Object.values(flowsStatistics.current.roadsUsedPercentages).filter(Boolean).length ===
                0)) && (
            <>
              <Box display="flex" sx={{ borderRadius: '10px', overflow: 'hidden' }}>
                {filteredFacilitiesStatistics ? (
                  filteredFacilitiesStatistics.map(({ key, color, labelKey }, index) => {
                    const distance = facilitiesStatistics.current?.distances[key] || 0;
                    const percentage = facilitiesStatistics.current?.distances.all
                      ? Math.round((distance / facilitiesStatistics.current.distances.all) * 100)
                      : 0;
                    const lastChild = index === filteredFacilitiesStatistics.length - 1;

                    return (
                      <Tooltip key={key} title={`${t(labelKey)}: ${percentage}%`}>
                        <Box
                          flexGrow={lastChild ? 1 : undefined}
                          flexShrink={lastChild ? 0 : undefined}
                          height={20}
                          sx={{ backgroundColor: color }}
                          width={`${percentage}%`}
                        />
                      </Tooltip>
                    );
                  })
                ) : (
                  <Skeleton height={20} variant="rectangular" />
                )}
              </Box>
              <Box display="flex" flexWrap="wrap" gap={2} marginTop={2}>
                {facilities.map(({ key, color, labelKey }) => (
                  <Box
                    alignItems="center"
                    display="flex"
                    gap={1}
                    key={key}
                    minHeight={40}
                    width={{ xs: '100%', lg: 'calc((100% - 16px) / 2)' }}
                  >
                    <Box
                      alignSelf="stretch"
                      borderLeft={`3px solid ${color}`}
                      borderRadius={3}
                      flexShrink={0}
                    />
                    <Box flexGrow={1}>
                      <Typography color="textSecondary" variant="caption">
                        <Trans i18nKey={labelKey} />
                      </Typography>
                    </Box>
                    <Box flexShrink={0}>
                      <Typography noWrap fontSize="1.125em">
                        {facilitiesStatistics ? (
                          `${
                            facilitiesStatistics.current?.distances.all
                              ? Math.round(
                                  (facilitiesStatistics.current.distances[key] /
                                    facilitiesStatistics.current.distances.all) *
                                    100,
                                )
                              : 0
                          } %`
                        ) : (
                          <Skeleton variant="text" width={40} />
                        )}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </>
          )}
      </Card>
      {currentPartner?.dashboardTabsPermissions.usageRoadsUse !== 'none' &&
        flowsStatistics?.current &&
        Object.values(flowsStatistics.current.roadsUsedPercentages).filter(Boolean).length > 0 && (
          <Card
            currentData={null}
            titleInfo={
              <Trans
                components={[
                  <br key={0} />,
                  <b key={1} />,
                  <br key={2} />,
                  <b key={3} />,
                  <br key={4} />,
                  <b key={5} />,
                ]}
                i18nKey="cycling-insights.usage.monitoring_indicators.cycling_network.roads_used_label"
              />
            }
            titleKey="cycling-insights.usage.monitoring_indicators.cycling_network.roads_used"
            to={`/${currentPartner?.code}/bicycle-observatory/roads-use?period=month&from=${period.from.format(
              'YYYY-MM-DD',
            )}&prev-from=${period.getPrevPeriod().from.format('YYYY-MM-DD')}&compare=true`}
          >
            <Box
              alignSelf="center"
              height={200}
              maxWidth="100%"
              overflow="hidden"
              position="relative"
              sx={{ overflow: 'hidden' }}
              width={200}
            >
              {flowsStatistics ? (
                <canvas id={roadsTypesChartId} />
              ) : (
                <Skeleton height={200} variant="circular" />
              )}
            </Box>
            <Box display="flex" flexWrap="wrap" gap={2} marginTop={2}>
              {roadsTypes.map(({ key, color, labelKey }) => (
                <Box
                  alignItems="center"
                  display="flex"
                  gap={1}
                  key={key}
                  minHeight={40}
                  width={{ xs: '100%', xl: 'calc((100% - 16px) / 2)' }}
                >
                  <Box
                    alignSelf="stretch"
                    borderLeft={`3px solid ${color}`}
                    borderRadius={3}
                    flexShrink={0}
                  />
                  <Box flexGrow={1}>
                    <Typography color="textSecondary" variant="caption">
                      <Trans i18nKey={labelKey} />
                    </Typography>
                  </Box>
                  <Box flexShrink={0}>
                    <Typography noWrap fontSize="1.125em">
                      {flowsStatistics ? (
                        `${flowsStatistics.current?.roadsUsedPercentages[key]} %`
                      ) : (
                        <Skeleton variant="text" width={40} />
                      )}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Card>
        )}
    </>
  );
}

export default Security;
