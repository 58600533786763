import {
  Counter,
  CounterService,
  Period,
  VirtualCounterService,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Box, Chip, Skeleton, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../app/context';
import usePeriod from '../hooks/period';
import RightPanelLayout from '../layouts/right-panel';
import {
  IBicycleObservatoryPageContext,
  TCounterStats,
} from '../pages/bicycle-observatory/context';
import { getPeriodsDiff } from '../utils/period';
import { getProgression } from '../utils/stats';

import DualChart from './dual-chart';

function CounterDetails({
  period,
  counters: { list, selectedKey, selectKey },
}: IBicycleObservatoryPageContext): JSX.Element {
  const [counter, setCounter] = useState<Counter | null>(null);
  const [counterStats, setCounterStats] = useState<TCounterStats>();
  const [expanded, expand] = useState(true);
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { getTitle, getPrevPeriod } = usePeriod();

  useEffect(() => {
    if (selectedKey) {
      const [type, _id] = selectedKey.split('_');
      const isVirtual = type === 'virtual';
      const id = parseInt(_id);

      setCounter(
        list?.find((counter) => counter.id === id && counter.isVirtual === isVirtual) || null,
      );
    }

    return () => setCounter(null);
  }, [list, selectedKey]);

  useEffect(() => {
    if (counter && selectedKey) getStats();

    return () => {
      cancelPromises();
      setCounterStats(undefined);
    };
  }, [counter, period.values.current]);

  async function getStats() {
    if (!counter) return;

    const { id, isVirtual } = counter;

    const {
      values: { current: currentPeriod },
    } = period;

    const prevPeriod = getPrevPeriod(period.values);
    const lastYear = new Period(
      'month',
      period.values.current.from.clone().add(-1, 'year').startOf('month'),
      period.values.current.from.clone().add(-1, 'year').endOf('month'),
    );

    try {
      const isExtrapolated =
        currentPartner?.dashboardTabsPermissions.usagePointAttendance === 'extrapolated';
      const [currentStats, prevPeriodStats, lastYearStats] = await cancellablePromise(
        Promise.all(
          isVirtual
            ? isExtrapolated
              ? [
                  VirtualCounterService.getVirtualCounterExtrapolatedStats(
                    id,
                    currentPeriod.toIPeriod(),
                  ),
                  VirtualCounterService.getVirtualCounterExtrapolatedStats(
                    id,
                    prevPeriod.toIPeriod(),
                  ),
                  VirtualCounterService.getVirtualCounterExtrapolatedStats(
                    id,
                    lastYear.toIPeriod(),
                  ),
                ]
              : [
                  VirtualCounterService.getVirtualCounterStats(id, currentPeriod.toIPeriod()),
                  VirtualCounterService.getVirtualCounterStats(id, prevPeriod.toIPeriod()),
                  VirtualCounterService.getVirtualCounterStats(id, lastYear.toIPeriod()),
                ]
            : [
                CounterService.getCounterStats(id, currentPeriod.toIPeriod()),
                CounterService.getCounterStats(id, prevPeriod.toIPeriod()),
                CounterService.getCounterStats(id, lastYear.toIPeriod()),
              ],
        ),
      );

      const currentCount = currentStats.reduce((res, { count }) => res + count, 0);
      const prevCount = prevPeriodStats.reduce((res, { count }) => res + count, 0);
      const progression = getProgression(currentCount, prevCount);
      const lastYearCount = lastYearStats.reduce((res, { count }) => res + count, 0);
      const lastYearProgression = getProgression(currentCount, lastYearCount);
      const { type: diffType, value: diff } = getPeriodsDiff(currentPeriod, prevPeriod);
      const { type: lastYearDiffType, value: lastYearDiff } = getPeriodsDiff(
        currentPeriod,
        lastYear,
      );

      setCounterStats({
        id,
        isVirtual,
        current: {
          count: currentCount,
          values: currentStats.map(({ date, count: value }) => ({
            date,
            value,
          })),
        },
        prev: {
          count: prevCount,
          values: prevPeriodStats.map(({ date, count: value }) => ({
            initialDate: date.clone(),
            date: date.clone().add(diff, diffType),
            value,
          })),
        },
        progression,
        lastYear: {
          count: lastYearCount,
          values: lastYearStats.map(({ date, count: value }) => ({
            initialDate: date.clone(),
            date: date.clone().add(lastYearDiff, lastYearDiffType),
            value,
          })),
        },
        yearProgression: lastYearProgression,
      });
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.point_attendance.server_error_stats'), {
          variant: 'error',
        });
      }
    }
  }

  if (!counter) return <></>;

  const count = counterStats?.current.count || 0;
  const trafficPerDay = Math.round(count / period.values.current.nbSpentDays);

  return (
    <RightPanelLayout
      disableCollapse
      content={
        <Box display="flex" flexDirection="column" paddingTop={3}>
          <Typography color="#283859" fontWeight={600} variant="body1">
            {counterStats ? (
              <Trans
                count={count}
                i18nKey="commons.stats.passages_per_day"
                values={{ count, trafficPerDay }}
              />
            ) : (
              <Skeleton variant="text" width={100} />
            )}
          </Typography>
          <Box display="flex" gap={3} marginBottom={3} marginTop="12px">
            {counterStats ? (
              <>
                <Box alignItems="center" display="flex" gap="6px">
                  <StyledChip
                    label={`${
                      counterStats.progression >= 0
                        ? '+ ' + counterStats.progression
                        : '- ' + Math.abs(counterStats.progression)
                    }% `}
                    negative={counterStats.progression < 0 ? 'true' : 'false'}
                  />
                  <Typography variant="body2">
                    vs {getTitle(period.values.prev)?.toLocaleLowerCase()}
                  </Typography>
                </Box>
                {period.values.current.type !== 'year' && (
                  <Box alignItems="center" display="flex" gap="6px">
                    <StyledChip
                      label={`${
                        counterStats.yearProgression >= 0
                          ? '+ ' + counterStats.yearProgression
                          : '- ' + Math.abs(counterStats.yearProgression)
                      }% `}
                      negative={counterStats.yearProgression < 0 ? 'true' : 'false'}
                    />
                    <Typography variant="body2">
                      vs {t('commons.periods.last_year').toLocaleLowerCase()}
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <>
                <Skeleton variant="text" width={50} />
                <Skeleton variant="text" width={50} />
              </>
            )}
          </Box>
          <DualChart chartId="point-attendance-chart" period={period} stats={counterStats} />
        </Box>
      }
      expand={expand}
      expanded={expanded}
      header={
        <Typography fontSize="1.125rem" fontWeight={700} variant="h6">
          {counter.title || (
            <Trans
              i18nKey="cycling-insights.usage.point_attendance.default_title"
              values={{ id: counter.id }}
            />
          )}
        </Typography>
      }
      onClose={() => selectKey(null)}
    />
  );
}

const StyledChip = styled(Chip)<{ negative: string }>`
  && {
    height: 26px;
    background-color: ${({ negative }) => (negative === 'true' ? '#FFEBEE' : '#EEF8F4')};
    color: ${({ negative }) => (negative === 'true' ? '#A42C49' : '#038B63')};
    > * {
      padding: 8px;
      font-size: 0.75rem;
    }
  }
`;

export default CounterDetails;
