import { Loading } from '@geovelo-frontends/commons';
import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { Navigate, Outlet, useParams } from 'react-router-dom';

import { AppContext } from '../app/context';
import VersionDialog from '../components/version-dialogs';
import usePartner from '../hooks/partner';
import Layout from '../layouts/default';

function Partner(): JSX.Element {
  const {
    partner: { currentGeogroup, contracts },
  } = useContext(AppContext);
  const { partner: partnerCode } = useParams<{ partner: string }>();
  const { loading, currentPartner, partnerToRedirect } = usePartner(partnerCode || '');

  if (partnerToRedirect) return <Navigate to={`/${partnerToRedirect.code}`} />;

  if (
    loading ||
    currentGeogroup === undefined ||
    partnerCode !== currentPartner?.code ||
    !contracts
  )
    return <Loading text={<Trans i18nKey="commons.partner.loading" />} />;

  return (
    <>
      {currentPartner && (
        <>
          <VersionDialog />
          <Layout>
            <Outlet />
          </Layout>
        </>
      )}
    </>
  );
}

export default Partner;
