import { TSectionFacility } from '@geovelo-frontends/commons';
import { Moment } from 'moment';

import { TResolution } from '../../../hooks/map/h3';

export const tilesZoom = 10;

export const resolutionsZooms: { [key in TResolution]: number } = {
  6: 7,
  7: 9,
  8: 11,
  9: 13.5,
  10: 15,
};

export const accidentTypes = ['deadly', 'hospitalized', 'injured'] as const;
export type TAccidentType = (typeof accidentTypes)[number];

export type TFubFeature = GeoJSON.Feature<
  GeoJSON.Point,
  {
    accidentData?: {
      facility: TSectionFacility | null;
      roadType?: string;
      type: TAccidentType;
      vehicles: string[];
    };
    date?: Moment;
    h3Indexes: { [resolution in TResolution]?: string };
  }
>;

export type TH3CellData = {
  accidents: number;
  blackSpots: number;
  deadlyAccidents: number;
  frequency: number;
  h3Index: string;
  hospitalizedAccidents: number;
  injuredAccidents: number;
  reports: number;
  resolution: TResolution | 'cyclabilityZones';
  score: number;
  suddenBrakings: number;
};

export type TH3CellFeatureProps = {
  center: { lat: number; lng: number };
  color?: [number, number, number];
  h3Index: string;
  resolution: TResolution | 'cyclabilityZones';
  zoneId: string;
} & Partial<TH3CellData>;
