import {
  Autocomplete,
  IPoint,
  IRideStep,
  Place,
  Poi,
  PoiService,
  Ride,
  RideService,
  Route,
  useCancellablePromise,
  usePois,
} from '@geovelo-frontends/commons';
import { Paper } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../../../../app/context';
import Map from '../../../../components/map';
import MapLoader from '../../../../components/map/loader';
import useRideAdmin from '../../../../hooks/map/ride-admin';

import { LngLatBounds, Map as MaplibreMap } from '!maplibre-gl';

interface IProps {
  canWrite: boolean;
  onMapClick: (location: IPoint) => void;
  onStepAdd: (step: IRideStep) => void;
  onStepDragged: (step: IRideStep, location: IPoint) => void;
  onStepUpdate: (step: IRideStep) => void;
  ride: Ride;
  returnRoute?: Route | null;
  route?: Route | null;
}

function RideStepsMap({
  canWrite,
  ride,
  returnRoute,
  route,
  onMapClick,
  onStepAdd,
  onStepUpdate,
  onStepDragged,
}: IProps): JSX.Element {
  const [map, setMap] = useState<MaplibreMap>();
  const [searchedPlace, setSearchedPlace] = useState<Place | null>(null);
  const [loading, setLoading] = useState(false);
  const {
    poi: { categories, selectedCategories },
  } = useContext(AppContext);
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { init, updatePreview, updateSteps, updateNewStep } = useRideAdmin(map, canWrite, {
    onClick,
    onStepAdd: onMapClick,
    onStepUpdate,
    onStepDragged,
  });
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    initialized: markersInitialized,
    init: initMarkers,
    update: updateMarkers,
    clear: clearMarkers,
  } = usePois(
    map,
    theme,
    false,
    categories,
    selectedCategories,
    {},
    { enqueueSnackbar, closeSnackbar, onAddAsRideStep },
  );

  useEffect(() => {
    if (map) {
      init();
      initMarkers();
      map.on('moveend', update);
    }

    return () => {
      map?.off('moveend', update);
    };
  }, [map]);

  useEffect(() => {
    if (!map) return;

    const { condensedGeometry, bounds: _bounds, steps } = ride;

    if (condensedGeometry) {
      updatePreview(ride, route, returnRoute);

      if (_bounds) {
        const { north, east, south, west } = _bounds;
        map.fitBounds(new LngLatBounds({ lat: south, lng: west }, { lat: north, lng: east }));
      }
    }

    updateSteps(steps);
  }, [map, ride]);

  useEffect(() => {
    if (markersInitialized && categories) update();

    return () => {
      if (markersInitialized) clearMarkers();
    };
  }, [markersInitialized, categories, selectedCategories]);

  useEffect(() => {
    if (map && searchedPlace) {
      const {
        point: {
          coordinates: [lng, lat],
        },
      } = searchedPlace;
      map.flyTo({ center: { lat, lng }, zoom: 17 });
    }
  }, [searchedPlace]);

  async function update() {
    cancelPromises();
    if (!map || !categories) return;

    try {
      const [[west, south], [east, north]] = map.getBounds().toArray();
      const categoryCodes = categories.flatMap(({ code, children }) => [
        code,
        ...children.map(({ code: childCode }) => childCode),
      ]);

      const pois = await cancellablePromise(
        PoiService.getPois(categories, {
          bounds: { north, east, south, west },
          zoom: map.getZoom(),
          selectedCategories: categoryCodes.filter((code) => selectedCategories[code]),
        }),
      );

      updateMarkers(pois);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') console.error(err);
    }
  }

  function onClick(location: IPoint) {
    if (canWrite) updateNewStep(location);
  }

  async function onAddAsRideStep(poi: Poi) {
    if (!ride.id) return;

    setLoading(true);

    const { title, categoryCodes, description, location, photos } = poi;

    const category = (categories?.filter(
      ({ code, labelKey }) => labelKey && categoryCodes.indexOf(code) > -1,
    ) || [])[0];

    try {
      const newStep = await RideService.addRideStep(ride.id, {
        order: ride.steps.length + 1,
        title: title || (category.labelKey ? t(category.labelKey, { context: 'one' }) : ''),
        description: description || '',
        geo_point: location.point,
      });

      if (newStep.id && photos?.[0]) {
        const { url, copyright } = photos[0];
        if (url) {
          const res = await fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'image/png' },
          });
          const blob = await res.blob();

          const photo = await RideService.addRideStepPhoto(ride.id, newStep.id, {
            copyright: copyright || '',
            file: new File([blob], url.slice(url.lastIndexOf('/') + 1)),
          });

          newStep.photos.push(photo);
        }
      }

      onStepAdd(newStep);
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.not_updated'), { variant: 'error' });
    }

    setLoading(false);
  }

  return (
    <Wrapper>
      <StyledMap hasLayersControl mapId="ride-route-map" onInit={setMap}>
        <StyledAutocomplete
          disableFloatingLabel
          defaultValue={searchedPlace}
          disabled={!map}
          onSelect={setSearchedPlace}
          size="small"
        />
      </StyledMap>
      {loading && <MapLoader />}
    </Wrapper>
  );
}

const Wrapper = styled(Paper)`
  height: 100%;
  position: relative;
`;

const StyledMap = styled(Map)`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const StyledAutocomplete = styled(Autocomplete)`
  position: absolute;
  left: 16px;
  top: 12px;
  z-index: 2;

  && {
    width: 300px !important;

    input {
      padding: 0;
    }
  }

  > * {
    background-color: white;
    > * {
      box-shadow:
        rgb(0 0 0 / 20%) 0px 2px 1px -1px,
        rgb(0 0 0 / 14%) 0px 1px 1px 0px,
        rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    }
  }
`;

export default RideStepsMap;
