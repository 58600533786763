import { SvgIcon, SvgIconProps } from '@mui/material';

function RacksIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 44 20" {...props}>
      <path
        clipRule="evenodd"
        d="M33.0233 3.35347C33.0233 1.88972 34.2099 0.703125 35.6737 0.703125C36.9098 0.703125 37.9818 1.55767 38.2573 2.76271L41.9132 18.7515V19.1309H40.9132V18.8644L37.2825 2.98561C37.1109 2.23524 36.4434 1.70312 35.6737 1.70312C34.7622 1.70312 34.0233 2.44201 34.0233 3.35347V14.8564L33.9196 14.8125H33.0233V3.35347Z"
        fill="#6B6C6E"
        fillRule="evenodd"
      />
      <mask
        fill="black"
        height="20"
        id="path-2-outside-1_2680_8062"
        maskUnits="userSpaceOnUse"
        width="11"
        x="28.6703"
        y="-0.296875"
      >
        <rect fill="white" height="20" width="11" x="28.6703" y="-0.296875" />
        <path
          clipRule="evenodd"
          d="M29.6703 3.35347C29.6703 1.88972 30.8569 0.703125 32.3206 0.703125C33.5568 0.703125 34.6288 1.55767 34.9043 2.76271L38.5601 18.7515V19.1309H37.5601V18.8644L33.9295 2.98561C33.7579 2.23524 33.0904 1.70312 32.3206 1.70312C31.4092 1.70312 30.6703 2.44201 30.6703 3.35347V14.8564L30.5665 14.8125H29.6703V3.35347Z"
          fillRule="evenodd"
        />
      </mask>
      <path
        clipRule="evenodd"
        d="M29.6703 3.35347C29.6703 1.88972 30.8569 0.703125 32.3206 0.703125C33.5568 0.703125 34.6288 1.55767 34.9043 2.76271L38.5601 18.7515V19.1309H37.5601V18.8644L33.9295 2.98561C33.7579 2.23524 33.0904 1.70312 32.3206 1.70312C31.4092 1.70312 30.6703 2.44201 30.6703 3.35347V14.8564L30.5665 14.8125H29.6703V3.35347Z"
        fill="#6B6C6E"
        fillRule="evenodd"
      />
      <path
        d="M34.9043 2.76271L35.3917 2.65126V2.65126L34.9043 2.76271ZM38.5601 18.7515H39.0601V18.6951L39.0476 18.6401L38.5601 18.7515ZM38.5601 19.1309V19.6309H39.0601V19.1309H38.5601ZM37.5601 19.1309H37.0601V19.6309H37.5601V19.1309ZM37.5601 18.8644H38.0601V18.808L38.0476 18.753L37.5601 18.8644ZM33.9295 2.98561L33.442 3.09706L33.442 3.09706L33.9295 2.98561ZM30.6703 14.8564L30.4753 15.3169L31.1703 15.6112V14.8564H30.6703ZM30.5665 14.8125L30.7615 14.3521L30.6681 14.3125H30.5665V14.8125ZM29.6703 14.8125H29.1703V15.3125H29.6703V14.8125ZM32.3206 0.203125C30.5807 0.203125 29.1703 1.61358 29.1703 3.35347H30.1703C30.1703 2.16587 31.133 1.20312 32.3206 1.20312V0.203125ZM35.3917 2.65126C35.0642 1.21888 33.79 0.203125 32.3206 0.203125V1.20312C33.3236 1.20312 34.1933 1.89645 34.4169 2.87416L35.3917 2.65126ZM39.0476 18.6401L35.3917 2.65126L34.4169 2.87416L38.0727 18.863L39.0476 18.6401ZM39.0601 19.1309V18.7515H38.0601V19.1309H39.0601ZM37.5601 19.6309H38.5601V18.6309H37.5601V19.6309ZM37.0601 18.8644V19.1309H38.0601V18.8644H37.0601ZM33.442 3.09706L37.0727 18.9759L38.0476 18.753L34.4169 2.87416L33.442 3.09706ZM32.3206 2.20312C32.8572 2.20312 33.3224 2.57403 33.442 3.09706L34.4169 2.87416C34.1933 1.89645 33.3236 1.20312 32.3206 1.20312V2.20312ZM31.1703 3.35347C31.1703 2.71815 31.6853 2.20312 32.3206 2.20312V1.20312C31.133 1.20312 30.1703 2.16587 30.1703 3.35347H31.1703ZM31.1703 14.8564V3.35347H30.1703V14.8564H31.1703ZM30.3715 15.2729L30.4753 15.3169L30.8653 14.396L30.7615 14.3521L30.3715 15.2729ZM29.6703 15.3125H30.5665V14.3125H29.6703V15.3125ZM29.1703 3.35347V14.8125H30.1703V3.35347H29.1703Z"
        fill="#F4F4F4"
        mask="url(#path-2-outside-1_2680_8062)"
      />
      <path
        clipRule="evenodd"
        d="M18.9373 3.35347C18.9373 1.88972 20.1239 0.703125 21.5876 0.703125C22.8237 0.703125 23.8957 1.55767 24.1713 2.76271L27.8271 18.7515V19.1309H26.8271V18.8644L23.1964 2.98561C23.0248 2.23524 22.3573 1.70312 21.5876 1.70312C20.6761 1.70312 19.9373 2.44201 19.9373 3.35347V14.8564L19.8335 14.8125H18.9373V3.35347Z"
        fill="#6B6C6E"
        fillRule="evenodd"
      />
      <mask
        fill="black"
        height="20"
        id="path-5-outside-2_2680_8062"
        maskUnits="userSpaceOnUse"
        width="11"
        x="14.5842"
        y="-0.296875"
      >
        <rect fill="white" height="20" width="11" x="14.5842" y="-0.296875" />
        <path
          clipRule="evenodd"
          d="M15.5842 3.35347C15.5842 1.88972 16.7708 0.703125 18.2346 0.703125C19.4707 0.703125 20.5427 1.55767 20.8182 2.76271L24.4741 18.7515V19.1309H23.4741V18.8644L19.8434 2.98561C19.6718 2.23524 19.0043 1.70312 18.2346 1.70312C17.3231 1.70312 16.5842 2.44201 16.5842 3.35347V14.8564L16.4805 14.8125H15.5842V3.35347Z"
          fillRule="evenodd"
        />
      </mask>
      <path
        clipRule="evenodd"
        d="M15.5842 3.35347C15.5842 1.88972 16.7708 0.703125 18.2346 0.703125C19.4707 0.703125 20.5427 1.55767 20.8182 2.76271L24.4741 18.7515V19.1309H23.4741V18.8644L19.8434 2.98561C19.6718 2.23524 19.0043 1.70312 18.2346 1.70312C17.3231 1.70312 16.5842 2.44201 16.5842 3.35347V14.8564L16.4805 14.8125H15.5842V3.35347Z"
        fill="#6B6C6E"
        fillRule="evenodd"
      />
      <path
        d="M20.8182 2.76271L21.3057 2.65126V2.65126L20.8182 2.76271ZM24.4741 18.7515H24.9741V18.6951L24.9615 18.6401L24.4741 18.7515ZM24.4741 19.1309V19.6309H24.9741V19.1309H24.4741ZM23.4741 19.1309H22.9741V19.6309H23.4741V19.1309ZM23.4741 18.8644H23.9741V18.808L23.9615 18.753L23.4741 18.8644ZM19.8434 2.98561L19.356 3.09706L19.356 3.09706L19.8434 2.98561ZM16.5842 14.8564L16.3892 15.3169L17.0842 15.6112V14.8564H16.5842ZM16.4805 14.8125L16.6755 14.3521L16.582 14.3125H16.4805V14.8125ZM15.5842 14.8125H15.0842V15.3125H15.5842V14.8125ZM18.2346 0.203125C16.4947 0.203125 15.0842 1.61358 15.0842 3.35347H16.0842C16.0842 2.16587 17.047 1.20312 18.2346 1.20312V0.203125ZM21.3057 2.65126C20.9781 1.21888 19.7039 0.203125 18.2346 0.203125V1.20312C19.2375 1.20312 20.1073 1.89645 20.3308 2.87416L21.3057 2.65126ZM24.9615 18.6401L21.3057 2.65126L20.3308 2.87416L23.9867 18.863L24.9615 18.6401ZM24.9741 19.1309V18.7515H23.9741V19.1309H24.9741ZM23.4741 19.6309H24.4741V18.6309H23.4741V19.6309ZM22.9741 18.8644V19.1309H23.9741V18.8644H22.9741ZM19.356 3.09706L22.9867 18.9759L23.9615 18.753L20.3308 2.87416L19.356 3.09706ZM18.2346 2.20312C18.7711 2.20312 19.2364 2.57403 19.356 3.09706L20.3308 2.87416C20.1073 1.89645 19.2375 1.20312 18.2346 1.20312V2.20312ZM17.0842 3.35347C17.0842 2.71815 17.5993 2.20312 18.2346 2.20312V1.20312C17.047 1.20312 16.0842 2.16587 16.0842 3.35347H17.0842ZM17.0842 14.8564V3.35347H16.0842V14.8564H17.0842ZM16.2855 15.2729L16.3892 15.3169L16.7792 14.396L16.6755 14.3521L16.2855 15.2729ZM15.5842 15.3125H16.4805V14.3125H15.5842V15.3125ZM15.0842 3.35347V14.8125H16.0842V3.35347H15.0842Z"
        fill="#F4F4F4"
        mask="url(#path-5-outside-2_2680_8062)"
      />
      <path
        clipRule="evenodd"
        d="M4.85107 3.35347C4.85107 1.88972 6.03767 0.703125 7.50142 0.703125C8.73756 0.703125 9.80955 1.55767 10.0851 2.76271L13.7409 18.7515V19.1309H12.7409V18.8644L9.11024 2.98561C8.93867 2.23524 8.27115 1.70312 7.50142 1.70312C6.58996 1.70312 5.85107 2.44201 5.85107 3.35347V14.8564L5.74732 14.8125H4.85107V3.35347Z"
        fill="#6B6C6E"
        fillRule="evenodd"
      />
      <mask
        fill="black"
        height="20"
        id="path-8-outside-3_2680_8062"
        maskUnits="userSpaceOnUse"
        width="11"
        x="0.498047"
        y="-0.296875"
      >
        <rect fill="white" height="20" width="11" x="0.498047" y="-0.296875" />
        <path
          clipRule="evenodd"
          d="M1.49805 3.35347C1.49805 1.88972 2.68465 0.703125 4.14839 0.703125C5.38453 0.703125 6.45652 1.55767 6.73205 2.76271L10.3879 18.7515V19.1309H9.3879V18.8644L5.75721 2.98561C5.58564 2.23524 4.91812 1.70312 4.14839 1.70312C3.23693 1.70312 2.49805 2.44201 2.49805 3.35347V14.8564L2.39429 14.8125H1.49805V3.35347Z"
          fillRule="evenodd"
        />
      </mask>
      <path
        clipRule="evenodd"
        d="M1.49805 3.35347C1.49805 1.88972 2.68465 0.703125 4.14839 0.703125C5.38453 0.703125 6.45652 1.55767 6.73205 2.76271L10.3879 18.7515V19.1309H9.3879V18.8644L5.75721 2.98561C5.58564 2.23524 4.91812 1.70312 4.14839 1.70312C3.23693 1.70312 2.49805 2.44201 2.49805 3.35347V14.8564L2.39429 14.8125H1.49805V3.35347Z"
        fill="#6B6C6E"
        fillRule="evenodd"
      />
      <path
        d="M6.73205 2.76271L7.21947 2.65126V2.65126L6.73205 2.76271ZM10.3879 18.7515H10.8879V18.6951L10.8753 18.6401L10.3879 18.7515ZM10.3879 19.1309V19.6309H10.8879V19.1309H10.3879ZM9.3879 19.1309H8.8879V19.6309H9.3879V19.1309ZM9.3879 18.8644H9.8879V18.808L9.87532 18.753L9.3879 18.8644ZM5.75721 2.98561L5.26979 3.09706L5.26979 3.09706L5.75721 2.98561ZM2.49805 14.8564L2.30304 15.3169L2.99805 15.6112V14.8564H2.49805ZM2.39429 14.8125L2.5893 14.3521L2.49582 14.3125H2.39429V14.8125ZM1.49805 14.8125H0.998047V15.3125H1.49805V14.8125ZM4.14839 0.203125C2.4085 0.203125 0.998047 1.61358 0.998047 3.35347H1.99805C1.99805 2.16587 2.96079 1.20312 4.14839 1.20312V0.203125ZM7.21947 2.65126C6.89196 1.21888 5.61774 0.203125 4.14839 0.203125V1.20312C5.15133 1.20312 6.02108 1.89645 6.24463 2.87416L7.21947 2.65126ZM10.8753 18.6401L7.21947 2.65126L6.24463 2.87416L9.90048 18.863L10.8753 18.6401ZM10.8879 19.1309V18.7515H9.8879V19.1309H10.8879ZM9.3879 19.6309H10.3879V18.6309H9.3879V19.6309ZM8.8879 18.8644V19.1309H9.8879V18.8644H8.8879ZM5.26979 3.09706L8.90048 18.9759L9.87532 18.753L6.24463 2.87416L5.26979 3.09706ZM4.14839 2.20312C4.68492 2.20312 5.1502 2.57403 5.26979 3.09706L6.24463 2.87416C6.02108 1.89645 5.15133 1.20312 4.14839 1.20312V2.20312ZM2.99805 3.35347C2.99805 2.71815 3.51307 2.20312 4.14839 2.20312V1.20312C2.96079 1.20312 1.99805 2.16587 1.99805 3.35347H2.99805ZM2.99805 14.8564V3.35347H1.99805V14.8564H2.99805ZM2.19928 15.2729L2.30304 15.3169L2.69306 14.396L2.5893 14.3521L2.19928 15.2729ZM1.49805 15.3125H2.39429V14.3125H1.49805V15.3125ZM0.998047 3.35347V14.8125H1.99805V3.35347H0.998047Z"
        fill="#F4F4F4"
        mask="url(#path-8-outside-3_2680_8062)"
      />
      <path d="M8.34399 17.668H43.479V19.1309H8.34399V17.668Z" fill="#6B6C6E" />
      <path d="M0.546021 14.1289H35.681V15.5918H0.546021V14.1289Z" fill="#6B6C6E" />
    </SvgIcon>
  );
}

export default RacksIcon;
