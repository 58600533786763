import { ChatBubbleOutline } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Ref, forwardRef, useImperativeHandle } from 'react';
import { Trans } from 'react-i18next';

import Table, { ITableRef } from '../../../../components/table';
import useReportsTable from '../../../../hooks/reports-table';
import { TOutletContext } from '../../../../layouts/page/container';
import { TQAPageContext } from '../../context';

type TKey = 'status' | 'type' | 'created' | 'updated' | 'reviews';

const keys: TKey[] = ['type', 'reviews', 'created', 'updated', 'status'];

function ReportsTable(
  {
    period: {
      values: { current: currentPeriod },
    },
    osmReports: {
      selectedReviewsCount,
      selectedStatuses,
      selectedTypeCodes,
      data: reports,
      selectedId,
      selectId,
    },
    setLoading,
  }: TQAPageContext & TOutletContext,
  ref: Ref<ITableRef>,
): JSX.Element {
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    rows,
    reorder: reorderTable,
    reloadPage: reloadTablePage,
    reloadReport: reloadTableReport,
    onPageChange,
    onRowsPerPageChange,
    onSortRequest,
  } = useReportsTable<TKey>({
    isBindToOSM: true,
    defaultOrderBy: 'updated',
    reports,
    selectedReviewsCount,
    selectedTypeCodes,
    selectedStatuses,
    currentPeriod,
    selectedId,
    errorMessageKey: 'cycling-insights.reports.osm_cartographic_reports.server_error',
    setLoading,
    selectId,
  });

  useImperativeHandle(ref, () => ({ reorder: reorderTable, reloadTablePage, reloadTableReport }));

  return (
    <Table
      count={reports?.length}
      headers={{
        type: {
          label: <Trans i18nKey="commons.stats.type_label" />,
          sortable: true,
          width: 70,
        },
        reviews: {
          label: (
            <Box alignItems="center" display="flex">
              <ChatBubbleOutline fontSize="small" />
            </Box>
          ),
        },
        created: {
          label: <Trans i18nKey="commons.stats.creation_label" />,
          sortable: true,
          width: 95,
        },
        updated: {
          label: <Trans i18nKey="commons.stats.update_label" />,
          sortable: true,
          width: 95,
        },
        status: { label: <Trans i18nKey="commons.status" />, sortable: true, width: 85 },
      }}
      keys={keys}
      onClick={(key) => selectId(key)}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      onSortRequest={onSortRequest}
      order={order}
      orderBy={orderBy}
      page={page}
      rows={rows}
      rowsPerPage={rowsPerPage}
      selectedKey={selectedId || undefined}
      tableProps={{ sx: { tableLayout: 'fixed' } }}
      title="Cartographic contributions table"
    />
  );
}

export default forwardRef(ReportsTable);
