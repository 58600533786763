import { Skeleton, Typography } from '@mui/material';
import { green, grey, red } from '@mui/material/colors';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

interface IProps {
  errorColor?: string;
  errorCount: number;
  errorLabelKey: string;
  loading: boolean;
  successCount: number;
  successLabelKey: string;
}

function DualBarStats({
  errorColor,
  loading,
  successCount,
  errorCount,
  successLabelKey,
  errorLabelKey,
}: IProps): JSX.Element {
  const totalCount = successCount + errorCount;

  return (
    <Wrapper>
      <Typography
        component="div"
        style={{ color: successCount > 0 ? green[500] : grey[500] }}
        variant="caption"
      >
        {loading ? (
          <Skeleton variant="text" width={100} />
        ) : (
          <Trans count={successCount} i18nKey={successLabelKey} values={{ count: successCount }} />
        )}
      </Typography>
      {loading ? (
        <Skeleton
          height={32}
          style={{ borderRadius: 16, margin: '4px 0' }}
          variant="rectangular"
          width="100%"
        />
      ) : (
        <BarWrapper>
          <StyledSuccessBar
            style={{
              width: `${(successCount / totalCount) * 100}%`,
              borderRadius: errorCount === 0 ? '16px' : '16px 0 0 16px',
            }}
          />
          <StyledErrorBar
            color={errorColor}
            style={{
              width: `${(errorCount / totalCount) * 100}%`,
              borderRadius: successCount === 0 ? '16px' : '0 16px 16px 0',
            }}
          />
          <StyledEmptyBar />
        </BarWrapper>
      )}
      <ErrorLabelWrapper>
        <Typography
          align="right"
          component="div"
          style={{ color: errorCount > 0 ? errorColor || red[500] : grey[500] }}
          variant="caption"
        >
          {loading ? (
            <Skeleton variant="text" width={100} />
          ) : (
            <Trans count={errorCount} i18nKey={errorLabelKey} values={{ count: errorCount }} />
          )}
        </Typography>
      </ErrorLabelWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

const BarWrapper = styled.div`
  display: flex;
  height: 34px;
  margin: 2px 0;
`;

const StyledSuccessBar = styled.div`
  background-color: ${green[500]};
  border: 2px solid white;
`;

const StyledErrorBar = styled.div<{ color?: string }>`
  background-color: ${({ color }) => color || red[500]};
  border: 2px solid white;
`;

const StyledEmptyBar = styled.div`
  background-color: #ddd;
  border: 2px solid white;
  border-radius: 16px;
  flex-grow: 1;
`;

const ErrorLabelWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default DualBarStats;
