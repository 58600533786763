import { DashboardPages, useAmplitudeTracker } from '@geovelo-frontends/commons';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../../app/context';
import i18n from '../../app/i18n';
import Button from '../../components/button';
import { environment } from '../../environment';
import Layout from '../../layouts/default';

function Help(): JSX.Element {
  const {
    user: { current: currentUser },
    partner: { current: currentPartner, lastVisited: lastVisitedPartner },
    actions: { setCurrentPage, setCurrentTab },
  } = useContext(AppContext);
  const { trackEvent } = useAmplitudeTracker();

  useEffect(() => {
    setCurrentPage(DashboardPages.Help);
    setCurrentTab(undefined);
  }, []);

  useEffect(() => {
    if (currentUser && currentPartner) {
      trackEvent('Page Visited', {
        pathname: window.location.pathname,
        partner_id: currentPartner.id,
        partner_code: currentPartner.code,
      });
    }
  }, [currentUser, currentPartner]);

  return (
    <Layout>
      <Box padding="72px 32px 32px" sx={{ overflowY: 'auto' }}>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          flexGrow={1}
          gap={2}
          justifyContent="center"
          margin="0 auto"
          maxWidth={1000}
        >
          <Box display="flex" flexDirection="column" gap={2} width={{ xs: '100%', md: '60%' }}>
            <Box alignItems="center" display="flex" flexDirection="row">
              <StyledTitle noWrap color="primary" variant="h6">
                <Trans i18nKey="commons.navigation.help" />
              </StyledTitle>
            </Box>
            <Typography variant="body1">
              <Trans i18nKey="commons.navigation.help_description" />
            </Typography>
            <iframe
              frameBorder="0"
              height="670px"
              referrerPolicy="origin"
              sandbox="allow-forms allow-popups allow-scripts allow-same-origin"
              src={environment.crisp?.formPluginUrl + `?language=${i18n.language}`}
              title="Contact Form"
            />
          </Box>
          {lastVisitedPartner?.enableDashboardPageAccess.Home && (
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              paddingTop={{ xs: 0, md: '110px' }}
              width={{ xs: '100%', md: '40%' }}
            >
              <StyledCard elevation={0}>
                <StyledCardContent>
                  <Box display="flex" flexDirection="row" gap={1}>
                    <InfoOutlined color="primary" />
                    <Typography color="primary">
                      <Trans i18nKey="cycling-insights.help.know_more" />
                    </Typography>
                  </Box>
                  <Typography color="primary">
                    <Trans i18nKey="cycling-insights.help.discover_features" />
                  </Typography>
                </StyledCardContent>
                <CardActions>
                  <Button
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    download
                    color="primary"
                    component="a"
                    href={environment.helpPDF.url}
                    onClick={() =>
                      trackEvent('File Downloaded', {
                        partner_id: currentPartner?.id,
                        partner_code: currentPartner?.code,
                        file: 'Help file',
                        cta: 'Help Page Button',
                      })
                    }
                    size="medium"
                    target="_blank"
                    variant="contained"
                  >
                    <p style={{ margin: 0, textAlign: 'center' }}>
                      <Trans i18nKey="cycling-insights.help.download" />
                    </p>
                  </Button>
                </CardActions>
              </StyledCard>
            </Box>
          )}
        </Box>
      </Box>
    </Layout>
  );
}

const StyledTitle = styled(Typography)`
  && {
    font-size: 30px;
    line-height: 36px;
  }
`;

const StyledCard = styled(Card)`
  && {
    padding: 40px;
    background-color: #e0ecdb;
    border-radius: 8px;
  }
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export default Help;
