import { UserService } from '@geovelo-frontends/commons';
import { CardActions, CardContent, TextField } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { AppContext } from '../../../app/context';
import Button from '../../../components/button';
import Card from '../../../components/card';

interface IValues {
  email: string;
}

function UserData(): JSX.Element {
  const {
    user: { current: currentUser },
    actions: { setCurrentUser },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const {
    isSubmitting,
    values,
    touched,
    errors,
    setValues,
    handleChange,
    handleSubmit: handleSubmit,
  } = useFormik<IValues>({
    initialValues: {
      email: currentUser?.email || '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required(),
    }),
    onSubmit: onSubmit,
    enableReinitialize: true,
  });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (currentUser) {
      setValues({ email: currentUser.email });
    }
  }, [currentUser]);

  async function onSubmit(
    { email }: IValues,
    { setSubmitting, setErrors }: FormikHelpers<IValues>,
  ) {
    setSubmitting(true);

    try {
      const user = await UserService.updateUser({ email });
      setCurrentUser(user);
      enqueueSnackbar(t('cycling-insights.profile.user_data.updated'));
    } catch (err) {
      if (err && typeof err === 'object' && (err as { code?: number }).code === 409) {
        setErrors({ email: t('commons.sign_up_form.email_already_exists') || '' });
      } else {
        enqueueSnackbar(t('cycling-insights.profile.user_data.server_error'));
      }
    }
    setSubmitting(false);
  }

  return (
    <Card title={<Trans i18nKey="cycling-insights.profile.user_data.title" />}>
      <form onSubmit={handleSubmit}>
        <CardContent>
          <TextField
            disabled={isSubmitting}
            error={Boolean(errors.email && touched.email)}
            helperText={touched.email && errors.email}
            id="email"
            label={<Trans i18nKey="commons.email" />}
            margin="dense"
            name="email"
            onChange={handleChange}
            size="small"
            sx={{ maxWidth: '100%', width: 350 }}
            type={'email'}
            value={values.email}
            variant="outlined"
          />
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Button
            color="primary"
            disabled={isSubmitting}
            size="small"
            type="submit"
            variant="contained"
          >
            <Trans i18nKey="commons.actions.update" />
          </Button>
        </CardActions>
      </form>
    </Card>
  );
}

export default UserData;
