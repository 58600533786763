import { useOutletContext } from 'react-router-dom';

import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { TQAPageContext } from '../../context';

import FacilitiesNewReportForm from './form';

function NewReportTab(): JSX.Element {
  const context = useOutletContext<TQAPageContext & TOutletContext>();

  return <PageContentLayout leftPanel={<FacilitiesNewReportForm {...context} />} />;
}

export default NewReportTab;
