import { Period } from '@geovelo-frontends/commons';
import { Box, Divider, Skeleton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Moment } from 'moment';
import { ReactNode, useEffect } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import useDualColumnChart from '../hooks/charts/dual-column';
import usePeriod from '../hooks/period';

import Card, { TAction } from './card';

export interface ICurrentStat {
  count: number;
  values: { date: Moment; value: number }[];
}

export interface IPrevStat {
  count: number;
  values: { initialDate: Moment; date: Moment; value: number }[];
}

export type TStats =
  | { id?: number; current: ICurrentStat; prev: IPrevStat; progression: number }
  | undefined;

interface IProps {
  actions?: TAction[];
  chartId: string;
  currentPeriod: Period;
  currentStatLabel: ReactNode;
  labelFormat?: (label: string | number) => string;
  prevPeriod: Period;
  prevStatLabel: ReactNode;
  stats: TStats;
  title?: ReactNode;
}

function DualStats({
  title,
  chartId,
  currentPeriod,
  prevPeriod,
  stats,
  currentStatLabel,
  prevStatLabel,
  labelFormat,
  actions,
}: IProps): JSX.Element {
  const theme = useTheme();
  const { getTitle: getPeriodTitle } = usePeriod();
  const {
    updateData: updateChartData,
    clearData: clearChartData,
    reset: resetChart,
  } = useDualColumnChart(chartId, currentPeriod, prevPeriod, theme, labelFormat, 'bar', true);

  useEffect(() => {
    resetChart(currentPeriod, prevPeriod);
  }, [currentPeriod, labelFormat]);

  useEffect(() => {
    if (!stats) {
      clearChartData();
    } else {
      updateChartData(
        currentPeriod,
        stats.current.values.map(({ date: t, value: y }) => ({ t, y })),
        stats.prev.values.map(({ initialDate, date: t, value: y }) => ({ initialDate, t, y })),
      );
    }
  }, [stats]);

  return (
    <Wrapper actions={actions} title={title || <Trans i18nKey="commons.stats.title" />}>
      <Box alignItems="stretch" display="flex" flexGrow={1} gap={2} padding={2}>
        <Box
          display="flex"
          flexDirection="column"
          flexShrink={0}
          gap={1}
          minWidth={200}
          width="33%"
        >
          <Box>
            <Typography color="primary">{getPeriodTitle(currentPeriod)}</Typography>
            <Typography color="primary" component="div" fontSize="1.2em" variant="h6">
              {stats ? currentStatLabel : <Skeleton variant="text" width={100} />}
            </Typography>
          </Box>
          <Divider />
          <Box>
            <Typography color="textSecondary">{getPeriodTitle(prevPeriod)}</Typography>
            <Typography color="textSecondary" component="div" fontSize="1.2em" variant="h6">
              {stats ? prevStatLabel : <Skeleton variant="text" width={100} />}
            </Typography>
          </Box>
        </Box>
        <Box flexGrow={1} overflow="hidden">
          <StyledChart id={chartId} />
        </Box>
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled(Card)`
  min-height: 400px;

  .MuiPaper-root {
    display: flex;
    flex-grow: 1;
    justify-content: stretch;
  }
`;

const StyledChart = styled.canvas`
  height: 100%;
  width: 100%;
`;

export default DualStats;
