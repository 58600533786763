import { StatsService } from '@geovelo-frontends/commons';
import { Box, Skeleton, Typography } from '@mui/material';
import { Chart } from 'chart.js';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../app/context';

import Card from './card';

const cyclabilityTypes: Array<{
  color: string;
  key: 'veryCycleable' | 'cycleable' | 'notVeryCycleable' | 'difficultToCycle';
  labelKey: string;
}> = [
  {
    color: '#1C9267',
    key: 'veryCycleable',
    labelKey: 'cycling-insights.facilities.cyclability.very_cycleable',
  },
  {
    color: '#46CE9D',
    key: 'cycleable',
    labelKey: 'cycling-insights.facilities.cyclability.cycleable',
  },
  {
    color: '#E76685',
    key: 'notVeryCycleable',
    labelKey: 'cycling-insights.facilities.cyclability.not_very_cycleable',
  },
  {
    color: '#A42C49',
    key: 'difficultToCycle',
    labelKey: 'cycling-insights.facilities.cyclability.difficult_to_cycle',
  },
];

const cyclabilityChartId = 'cyclability-chart';

function Cyclability(): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const {
    partner: { current: currentPartner, sections },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const cyclabilityChartRef = useRef<Chart<'doughnut'>>();
  const [roadsCountByCyclability, setRoadsCountByCyclability] = useState<number[]>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized && sections) getCyclabilityDistribution();
  }, [initialized, sections]);

  useEffect(() => {
    if (roadsCountByCyclability) {
      const cyclabilityChartCtx = document.getElementById(cyclabilityChartId);
      if (cyclabilityChartCtx && cyclabilityChartCtx instanceof HTMLCanvasElement) {
        cyclabilityChartRef.current?.destroy();

        cyclabilityChartRef.current = new Chart(cyclabilityChartCtx, {
          type: 'doughnut',
          data: {
            labels: [
              t('cycling-insights.facilities.cyclability.very_cycleable'),
              t('cycling-insights.facilities.cyclability.cycleable'),
              t('cycling-insights.facilities.cyclability.not_very_cycleable'),
              t('cycling-insights.facilities.cyclability.difficult_to_cycle'),
            ],
            datasets: [
              {
                data: roadsCountByCyclability.slice().reverse(),
                backgroundColor: cyclabilityTypes.map(({ color }) => color),
              },
            ],
          },
          options: {
            cutout: '80%',
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: ({ parsed }) =>
                    `${
                      Math.round(
                        (parsed /
                          roadsCountByCyclability.reduce((value, res) => (res += value), 0)) *
                          1000,
                      ) / 10
                    } %`,
                },
              },
              title: {
                display: false,
              },
            },
          },
        });
      }
    }
  }, [roadsCountByCyclability]);

  async function getCyclabilityDistribution() {
    if (!currentPartner) return;

    try {
      const { good, very_good, bad, very_bad } = await StatsService.getCyclabilityDistribution(
        currentPartner.id,
      );
      setRoadsCountByCyclability([very_bad, bad, good, very_good]);
    } catch {
      enqueueSnackbar(t('commons.stats.no_data'), { variant: 'error' });
    }
  }

  return (
    <>
      <Card
        currentData={null}
        titleKey="cycling-insights.facilities.cyclability.chart.title"
        to={`/${currentPartner?.code}/cartographic-data/cyclability`}
      >
        <Box
          alignSelf="center"
          height={200}
          maxWidth="100%"
          overflow="hidden"
          position="relative"
          sx={{ overflow: 'hidden' }}
          width={200}
        >
          {roadsCountByCyclability ? (
            <canvas id={cyclabilityChartId} />
          ) : (
            <Skeleton height={200} variant="circular" />
          )}
        </Box>
        <Box display="flex" flexWrap="wrap" gap={2} marginTop={2}>
          {cyclabilityTypes.map(({ key, color, labelKey }, index) => (
            <Box
              alignItems="center"
              display="flex"
              gap={1}
              key={key}
              minHeight={40}
              width={{ xs: '100%', xl: 'calc((100% - 16px) / 2)' }}
            >
              <Box
                alignSelf="stretch"
                borderLeft={`3px solid ${color}`}
                borderRadius={3}
                flexShrink={0}
              />
              <Box flexGrow={1}>
                <Typography color="textSecondary" variant="caption">
                  <Trans i18nKey={labelKey} />
                </Typography>
              </Box>
              <Box flexShrink={0}>
                <Typography noWrap fontSize="1.125em">
                  {roadsCountByCyclability ? (
                    `${
                      Math.round(
                        (roadsCountByCyclability.slice().reverse()[index] /
                          roadsCountByCyclability.reduce((value, res) => (res += value), 0)) *
                          1000,
                      ) / 10
                    } %`
                  ) : (
                    <Skeleton variant="text" width={40} />
                  )}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Card>
    </>
  );
}

export default Cyclability;
