import {
  FacilitiesToggle,
  LayersDrawer,
  TBaseLayer,
  useFacilities,
  useGeoveloMap,
} from '@geovelo-frontends/commons';
import { useTheme } from '@mui/material/styles';
import { ReactNode, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { AppContext } from '../app/context';

import { FitBoundsOptions, LngLatBoundsLike, Map as MaplibreMap } from '!maplibre-gl';

interface IProps {
  baseLayer?: TBaseLayer;
  bounds?: LngLatBoundsLike;
  children?: ReactNode;
  disableInteractions?: boolean;
  disableZoomControl?: boolean;
  enableFacilities?: boolean;
  fitBoundsOptions?: FitBoundsOptions;
  hasFacilitiesToggle?: boolean;
  hasLayersControl?: boolean;
  hasScaleControl?: boolean;
  mapId: string;
  onClick?: (point: GeoJSON.Point) => void;
  onInit?: (map: MaplibreMap) => void;
}

function Map({
  children,
  baseLayer,
  disableInteractions,
  disableZoomControl,
  enableFacilities,
  hasFacilitiesToggle,
  hasLayersControl,
  hasScaleControl,
  mapId,
  bounds: _bounds,
  fitBoundsOptions,
  onInit,
  onClick,
  ...props
}: IProps): JSX.Element {
  const [mapLayersDrawerOpen, openMapLayersDrawer] = useState(false);
  const theme = useTheme();
  const {
    map: { facilitiesShowed },
    partner: { current: currentPartner },
    poi: { categories, selectedCategories },
    actions: { selectPoiCategories, toggleFacilities: toggleFacilitiesOnMap },
  } = useContext(AppContext);
  const { map, initialized, init, destroy } = useGeoveloMap({
    baseLayers: ['geovelo', 'dark', 'aerial'],
    baseLayer,
    layersDrawerOpen: mapLayersDrawerOpen,
    theme,
    onClick,
    onLayersControlToggle: openMapLayersDrawer,
  });
  const {
    initialized: facilitiesInitialized,
    init: initFacilities,
    toggle: toggleFacilities,
  } = useFacilities(map);

  useEffect(() => {
    return () => destroy();
  }, []);

  useEffect(() => {
    if (initialized && map && onInit) onInit(map);
  }, [initialized]);

  useEffect(() => {
    if (facilitiesInitialized && hasFacilitiesToggle) toggleFacilities(facilitiesShowed);
  }, [facilitiesShowed]);

  useEffect(() => {
    if (map && (hasFacilitiesToggle || enableFacilities))
      initFacilities(enableFacilities || facilitiesShowed);
  }, [map]);

  useEffect(() => {
    setTimeout(() => {
      if (currentPartner) {
        const { north, east, south, west } = currentPartner.bounds;

        init({
          baseLayer,
          scaleControl: hasScaleControl,
          interactive: !disableInteractions,
          preserveDrawingBuffer: true,
          container: mapId,
          bounds: _bounds || [west, south, east, north],
          zoomControlsPosition: disableZoomControl ? undefined : 'bottom-right',
          layersControl: hasLayersControl,
          fitBoundsOptions,
        });
      }
    }, 100);
  }, [currentPartner]);

  return (
    <div {...props}>
      <StyledMap id={mapId}>
        {children}
        {hasFacilitiesToggle && (
          <FacilitiesToggleWrapper>
            <FacilitiesToggle
              facilitiesShowed={facilitiesShowed}
              sx={{ maxHeight: '100%', overflow: 'hidden' }}
              toggleFacilities={toggleFacilitiesOnMap}
            />
          </FacilitiesToggleWrapper>
        )}
        {hasLayersControl && (
          <LayersDrawer
            disableLayerSelection={true}
            disableParkings={true}
            facilitiesShowed={false}
            onClose={() => openMapLayersDrawer(false)}
            open={mapLayersDrawerOpen}
            PaperProps={{ style: { position: 'absolute' } }}
            poiCategories={categories}
            poiOptions={{ displayParkingSpaces: false }}
            selectedPoiCategories={selectedCategories}
            selectPoiCategories={selectPoiCategories}
            smallDevice={false}
          />
        )}
      </StyledMap>
    </div>
  );
}

const StyledMap = styled.div`
  height: 100%;
  position: relative;
`;

const FacilitiesToggleWrapper = styled.div`
  bottom: 44px;
  left: 10px;
  pointer-events: none;
  position: absolute;
  top: 10px;
  z-index: 2;
`;

export default Map;
