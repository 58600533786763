import {
  BikeRoute,
  BikeRouteService,
  IRideMedia,
  TBackendRideMediaType,
  TRideMediaType,
  mediaTypesMap,
} from '@geovelo-frontends/commons';
import { DialogProps, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { AppContext } from '../../../app/context';
import Dialog from '../../../components/dialog';

interface IValues {
  type: TRideMediaType;
  title: string;
  url: string;
  description: string;
  copyright: string;
}

type TProps = Omit<DialogProps, 'onClose'> & {
  bikeRoute: BikeRoute;
  media: IRideMedia | null;
  onClose: (media?: IRideMedia) => void;
};

function MediaFormDialog({ bikeRoute, media, onClose, ...props }: TProps): JSX.Element {
  const { t } = useTranslation();
  const { transitions } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { isSubmitting, values, touched, errors, setValues, handleChange, handleSubmit } =
    useFormik<IValues>({
      initialValues: {
        type: 'file',
        title: '',
        url: '',
        description: '',
        copyright: '',
      },
      validationSchema: Yup.object().shape({
        url: Yup.string().url().required(),
      }),
      onSubmit,
      enableReinitialize: true,
    });
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);

  useEffect(() => {
    if (props.open) {
      if (media) {
        const { url, title, description, copyright, type } = media;

        setValues({
          type: type || 'file',
          title: title || '',
          url: url || '',
          description: description || '',
          copyright: copyright || '',
        });
      }
    } else {
      setTimeout(() => {
        setValues({
          type: 'file',
          title: '',
          url: '',
          description: '',
          copyright: '',
        });
      }, transitions.duration.leavingScreen);
    }
  }, [props.open]);

  async function onSubmit({ type, title, url, description, copyright }: IValues) {
    if (!currentPartner) return;

    try {
      const types: { [key in TRideMediaType]: TBackendRideMediaType } = {
        audio: 'AUDIO',
        file: 'FILE',
        image: 'IMAGE',
        pdf: 'PDF',
        video: 'VIDEO',
        website: 'WEBSITE',
      };

      if (media) {
        if (!media.id) throw new Error('id is undefined');

        const updatedMedia = await BikeRouteService.updateBikeRouteMedia(bikeRoute.id, media.id, {
          type: types[type],
          title,
          url,
          description,
          copyright,
        });

        onClose(updatedMedia);
      } else {
        const newMedia = await BikeRouteService.addBikeRouteMedia(bikeRoute.id, {
          type: types[type],
          title,
          url,
          description,
          copyright,
        });

        onClose(newMedia);
      }
      enqueueSnackbar(t('cycling-insights.bike_route.updated'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('cycling-insights.bike_route.not_updated'), { variant: 'error' });
    }
  }

  return (
    <Dialog
      isForm
      confirmTitle={
        media ? <Trans i18nKey="commons.actions.update" /> : <Trans i18nKey="commons.actions.add" />
      }
      dialogTitle="bike-route-media-form-dialog"
      loading={isSubmitting}
      maxWidth="sm"
      onCancel={() => onClose()}
      onConfirm={handleSubmit}
      title={
        <Trans
          i18nKey="cycling-insights.bike_route.media_form.title"
          values={{ context: !media ? 'new' : '' }}
        />
      }
      {...props}
    >
      <FormControl fullWidth disabled={isSubmitting} margin="dense" variant="outlined">
        <InputLabel htmlFor="media-type-input">
          <Trans i18nKey="cycling-insights.bike_route.media_form.type" />
        </InputLabel>
        <Select
          inputProps={{
            id: 'media-type-input',
          }}
          label={<Trans i18nKey="cycling-insights.bike_route.media_form.type" />}
          name="type"
          onChange={handleChange}
          value={values.type}
        >
          {mediaTypesMap.map(({ key, labelKey }) => (
            <MenuItem key={key} value={key}>
              <Trans i18nKey={labelKey} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        disabled={isSubmitting}
        error={touched.title && Boolean(errors.title)}
        id="title"
        label={t('cycling-insights.bike_route.media_form.media_title')}
        margin="dense"
        name="title"
        onChange={handleChange}
        value={values.title}
        variant="outlined"
      />
      <TextField
        fullWidth
        required
        disabled={isSubmitting}
        error={touched.url && Boolean(errors.url)}
        id="url"
        label={t('cycling-insights.bike_route.media_form.url')}
        margin="dense"
        name="url"
        onChange={handleChange}
        type="url"
        value={values.url}
        variant="outlined"
      />
      <TextField
        fullWidth
        multiline
        disabled={isSubmitting}
        error={touched.description && Boolean(errors.description)}
        id="description"
        label={t('commons.description')}
        margin="dense"
        name="description"
        onChange={handleChange}
        rows={3}
        value={values.description}
        variant="outlined"
      />
      <TextField
        fullWidth
        disabled={isSubmitting}
        error={touched.copyright && Boolean(errors.copyright)}
        id="copyright"
        label={t('commons.copyright')}
        margin="dense"
        name="copyright"
        onChange={handleChange}
        value={values.copyright}
        variant="outlined"
      />
    </Dialog>
  );
}

export default MediaFormDialog;
