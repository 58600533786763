import {
  CyclabilityZone,
  CyclabilityZoneStats,
  Isochrone,
  Place,
  Report,
  Suggestion,
  TCyclingProfile,
  TReportSource,
  TReportTypeCode,
  TSectionFacility,
  TStatus,
  TSuddenBrakingsFeatureCollection,
} from '@geovelo-frontends/commons';
import { ReactNode, useRef, useState } from 'react';

import { IPeriodFormValues } from '../../components/form/period';
import { ISliderBounds, TSliderRange } from '../../components/form/slider';
import { ITableRef } from '../../components/table';
import useQueryParams from '../../hooks/query-params';
import PageContainerLayout from '../../layouts/page/container';
import { TSectionFeatureCollection, TSectionProperties } from '../../models/sections';
import { IDashboardPage } from '../dashboard-page';

import AccidentZoneTab from './components/accident-zones';
import AccidentologyTab from './components/accidentology';
import CyclabilityTab from './components/cyclability';
import DiscontinuityTab from './components/discontinuity';
import DiscontinuityAnalysisTab from './components/discontinuity-analysis';
import FacilitiesTab from './components/facilities';
import FacilitiesReportsTab from './components/facilities/reports';
import FacilitiesStatsTab from './components/facilities/stats';
import FacilitiesSuggestionsTab from './components/facilities-suggestions';
import FacilitiesSuggestionsFormTab from './components/facilities-suggestions-form';
import IsochronesTab from './components/isochrones';
import { TPointType } from './components/isochrones/form/points';
import FacilitiesNewReportTab from './components/new-report';
import ReportsTab from './components/reports';
import GeoveloRoadsQualityTab from './components/roads-quality';
import PotholeReportsTab from './components/roads-quality/reports';
import RoadsQualityAnalysisTab from './components/roads-quality-analysis';
import { TCartographicDataPageContext } from './context';
import { TFubFeature, TH3CellFeatureProps } from './models/accidentology';

const statuses: TStatus[] = ['OPEN', 'CLOSED'];
const sources: TReportSource[] = ['geovelo', 'openData'];

function CartographicDataPage(page: IDashboardPage): JSX.Element {
  const { searchParams, get: getQueryParams, getPeriods } = useQueryParams();

  // header context
  const [prevButtonClick, setPrevButtonClick] = useState<() => () => void>();
  const [actions, setActions] = useState<ReactNode>();
  const [title, setTitle] = useState<ReactNode>();

  // period context
  const [defaultPeriods] = useState(() => getPeriods());
  const [periods, setPeriods] = useState<IPeriodFormValues>(defaultPeriods);
  const [periodsComparisonEnabled, enablePeriodsComparison] = useState(
    searchParams.get('compare') === 'true',
  );

  // facilities context
  const [facilitiesCyclabilityZone, setFacilitiesCyclabilityZone] =
    useState<CyclabilityZone | null>();
  const [facilitiesPrevCyclabilityZone, setFacilitiesPrevCyclabilityZone] =
    useState<CyclabilityZone | null>();
  const [facilitiesCyclabilityZones, setFacilitiesCyclabilityZones] = useState<CyclabilityZone[]>();
  const [facilitiesPrevCyclabilityZones, setFacilitiesPrevCyclabilityZones] =
    useState<CyclabilityZone[]>();
  const [facilitiesZonesMap, setFacilitiesZonesMap] = useState<{ [id: number]: CyclabilityZone }>(
    {},
  );
  const [facilitiesSelectedZone, selectFacilitiesZone] = useState<CyclabilityZone | null>(null);
  const [facilitiesStats, setFacilitiesStats] = useState<CyclabilityZoneStats | undefined>();
  const [facilitiesPrevStats, setFacilitiesPrevStats] = useState<
    { [key: number]: CyclabilityZoneStats; global: CyclabilityZoneStats } | undefined
  >();
  const [parentsCyclabilityZones, setParentsCyclabilityZones] = useState<{
    [key: number]: CyclabilityZone[];
  }>({});
  const [facilitiesReports, setFacilitiesReports] = useState<Report[]>();
  const facilitiesReportsTableRef = useRef<ITableRef>(null);
  const [facilitiesReportSelectedId, selectFacilitiesReportId] = useState<number | null>(null);

  // reports context
  const [reportSelectedStatuses, selectReportStatuses] = useState<TStatus[]>(
    getQueryParams('statuses', statuses) || statuses,
  );
  const [reportSelectedTypeCodes, selectReportTypeCodes] = useState<TReportTypeCode[]>([]);
  const [reportSelectedSources, selectReportSources] = useState<TReportSource[]>(
    getQueryParams('sources', sources) || sources,
  );
  const [reports, setReports] = useState<Report[]>();
  const [reportSelectedId, selectReportId] = useState<number | null>(null);
  const reportsTableRef = useRef<ITableRef>(null);

  // accidentology context
  const [accidentologyFrequenciesCurrentRange, setAccidentologyFrequenciesCurrentRange] =
    useState<TSliderRange>();
  const [accidentologyFrequenciesBounds, setAccidentologyFrequenciesBounds] =
    useState<ISliderBounds>();
  const [accidentologyH3Features, setAccidentologyH3Features] =
    useState<Array<GeoJSON.Feature<GeoJSON.MultiPolygon | GeoJSON.Polygon, TH3CellFeatureProps>>>();
  const [accidentologyH3Map, setAccidentologyH3Map] = useState<{ [key: string]: { id: number } }>();
  const [accidentologyAccidentsFeatures, setAccidentologyAccidentsFeatures] =
    useState<TFubFeature[]>();
  const [accidentologyAccidentsYears, setAccidentologyAccidentsYears] = useState<number[]>();
  const [accidentologyBlackSpotsFeatures, setAccidentologyBlackSpotsFeatures] =
    useState<TFubFeature[]>();
  const [accidentologyBlackSpotsYears, setAccidentologyBlackSpotsYears] = useState<number[]>();
  const [accidentologySelectedH3Index, selectAccidentologyH3Index] = useState<string | null>(null);
  const [accidentologySelectedReport, selectAccidentologyReport] = useState<Report | null>(null);
  const accidentologyCanvasRef = useRef<HTMLCanvasElement>(null);

  // accidentology old context
  const [accidentologySectionsData, setAccidentologySectionsData] =
    useState<TSectionFeatureCollection | null>();
  const [accidentologySectionsQuartiles, setAccidentologySectionsQuartiles] = useState<number[]>();
  const [accidentologySectionsBounds, setAccidentologySectionsBounds] = useState<ISliderBounds>();
  const [accidentologyReports, setAccidentologyReports] = useState<Report[]>();
  const [suddenBrakings, setSuddenBrakings] = useState<TSuddenBrakingsFeatureCollection | null>();
  const [accidentologySuddenBrakingsBounds, setAccidentologySuddenBrakingsBounds] =
    useState<ISliderBounds>();
  const [
    accidentologySuddenBrakingsSecondaryBounds,
    setAccidentologySuddenBrakingsSecondaryBounds,
  ] = useState<ISliderBounds>();
  const [totalDistanceTraveled, setTotalDistanceTraveled] = useState<number>();
  const [distancesByFacilities, setDistancesByFacilities] =
    useState<{ [key in TSectionFacility | 'all' | 'allExtrapolated']: number }>();

  // roads quality context
  const [roadsQualityData, setRoadsQualityData] = useState<TSectionFeatureCollection | null>();
  const [roadsQualitySelectedFacilities, selectRoadsQualityFacilities] = useState<
    TSectionFacility[]
  >([
    'cycleway',
    'lane',
    'greenway',
    'opposite',
    'sharebusway',
    'mixedfacilities',
    'other',
    'none',
  ]);
  const [roadsQualityBounds, setRoadsQualityBounds] = useState<ISliderBounds>();
  const [roadsQualityCurrentRange, setRoadsQualityCurrentRange] = useState<TSliderRange>();

  // cyclability context
  const [cyclabilityData, setCyclabilityData] = useState<TSectionFeatureCollection | null>();
  const [cyclabilityBounds, setCyclabilityBounds] = useState<ISliderBounds>();
  const [cyclabilityCurrentRange, setCyclabilityCurrentRange] = useState<TSliderRange>();
  const [cyclabilitySecondaryBounds, setCyclabilitySecondaryBounds] = useState<ISliderBounds>();
  const [roadsCountByCyclability, setRoadsCountByCyclability] = useState<number[]>();

  // isochrones context
  const [isochrones, setIsochrones] = useState<Isochrone[]>();
  const [isochronesPointType, setIsochronesPointType] = useState<TPointType>('single');
  const [isochronesDepartures, setIsochronesDepartures] = useState<Place[]>([]);
  const [isochronesDurations, setIsochronesDurations] = useState<{ [key: number]: number | '' }>({
    0: 5,
    1: 10,
    2: 15,
  });
  const [isochronesEBikeEnabled, toggleIsochronesEBike] = useState(false);
  const [isochronesProfile, setIsochroneProfile] = useState<TCyclingProfile>('safe');

  // potholes context
  const [potholesReportsStatuses] = useState<TStatus[]>([
    'OPEN',
    'CLOSED',
    'CLOSED_BY_OSM',
    'ARCHIVED',
  ]);
  const [potholesReports, setPotholesReports] = useState<Report[]>();
  const [potholesSelectedId, selectPotholeReportId] = useState<number | null>(null);
  const potholesReportsTableRef = useRef<ITableRef>(null);

  // discontinuity context
  const [discontinuityData, setDiscontinuityData] = useState<TSectionFeatureCollection | null>();
  const [discontinuityBounds, setDiscontinuityBounds] = useState<ISliderBounds>();
  const [discontinuityCurrentRange, setDiscontinuityCurrentRange] = useState<TSliderRange>();
  const [discontinuitySelectedFacilities, selectDiscontinuityFacilities] = useState<
    TSectionFacility[]
  >(['cycleway', 'lane', 'greenway']);
  const [discontinuitySelectedSection, selectDiscontinuitySection] = useState<TSectionProperties>();

  // facility suggestions context
  const [facilitiesSuggestionsData, setFacilitiesSuggestionsData] = useState<
    Suggestion[] | undefined
  >();
  const [selectedSuggestion, selectSuggestion] = useState<Suggestion | null>(null);

  return (
    <PageContainerLayout<TCartographicDataPageContext>
      context={{
        defaultPeriods,
        header: {
          prevButtonClick,
          title,
          actions,
          setPrevButtonClick,
          setTitle,
          setActions,
        },
        period: {
          values: periods,
          comparisonEnabled: periodsComparisonEnabled,
          setValues: setPeriods,
          enableComparison: enablePeriodsComparison,
        },
        facilities: {
          cyclabilityZone: facilitiesCyclabilityZone,
          prevCyclabilityZone: facilitiesPrevCyclabilityZone,
          cyclabilityZones: facilitiesCyclabilityZones,
          prevCyclabilityZones: facilitiesPrevCyclabilityZones,
          parents: parentsCyclabilityZones,
          zonesMap: facilitiesZonesMap,
          selectedZone: facilitiesSelectedZone,
          stats: facilitiesStats,
          prevStats: facilitiesPrevStats,
          reports: facilitiesReports,
          selectedReportId: facilitiesReportSelectedId,
          reportsTableRef: facilitiesReportsTableRef,
          selectReportId: selectFacilitiesReportId,
          setCyclabilityZone: setFacilitiesCyclabilityZone,
          setPrevCyclabilityZone: setFacilitiesPrevCyclabilityZone,
          setCyclabilityZones: setFacilitiesCyclabilityZones,
          setPrevCyclabilityZones: setFacilitiesPrevCyclabilityZones,
          setParents: setParentsCyclabilityZones,
          setReports: setFacilitiesReports,
          setZonesMap: setFacilitiesZonesMap,
          setStats: setFacilitiesStats,
          setPrevStats: setFacilitiesPrevStats,
          selectZone: selectFacilitiesZone,
        },
        isochrones: {
          data: isochrones,
          pointType: isochronesPointType,
          departures: isochronesDepartures,
          durations: isochronesDurations,
          eBikeEnabled: isochronesEBikeEnabled,
          profile: isochronesProfile,
          setData: setIsochrones,
          setPointType: setIsochronesPointType,
          setDepartures: setIsochronesDepartures,
          setDurations: setIsochronesDurations,
          toggleEBike: toggleIsochronesEBike,
          setProfile: setIsochroneProfile,
        },
        roadsQuality: {
          data: roadsQualityData,
          bounds: roadsQualityBounds,
          currentRange: roadsQualityCurrentRange,
          selectedFacilities: roadsQualitySelectedFacilities,
          setData: setRoadsQualityData,
          setBounds: setRoadsQualityBounds,
          setCurrentRange: setRoadsQualityCurrentRange,
          selectFacilities: selectRoadsQualityFacilities,
        },
        reports: {
          tableRef: reportsTableRef,
          selectedStatuses: reportSelectedStatuses,
          selectedTypeCodes: reportSelectedTypeCodes,
          selectedSources: reportSelectedSources,
          data: reports,
          selectedId: reportSelectedId,
          selectStatuses: selectReportStatuses,
          selectTypeCodes: selectReportTypeCodes,
          selectSources: selectReportSources,
          setData: setReports,
          selectId: selectReportId,
        },
        accidentology: {
          canvasRef: accidentologyCanvasRef,
          h3Features: accidentologyH3Features,
          h3Map: accidentologyH3Map,
          bounds: accidentologyFrequenciesBounds,
          currentRange: accidentologyFrequenciesCurrentRange,
          accidentsFeatures: accidentologyAccidentsFeatures,
          accidentsYears: accidentologyAccidentsYears,
          blackSpotsFeatures: accidentologyBlackSpotsFeatures,
          blackSpotsYears: accidentologyBlackSpotsYears,
          selectedH3Index: accidentologySelectedH3Index,
          selectedReport: accidentologySelectedReport,
          setH3Features: setAccidentologyH3Features,
          setH3Map: setAccidentologyH3Map,
          setBounds: setAccidentologyFrequenciesBounds,
          setCurrentRange: setAccidentologyFrequenciesCurrentRange,
          setAccidentsFeatures: setAccidentologyAccidentsFeatures,
          setAccidentsYears: setAccidentologyAccidentsYears,
          setBlackSpotsFeatures: setAccidentologyBlackSpotsFeatures,
          setBlackSpotsYears: setAccidentologyBlackSpotsYears,
          selectH3Index: selectAccidentologyH3Index,
          selectReport: selectAccidentologyReport,
        },
        accidentologyOld: {
          bounds: accidentologySectionsBounds,
          data: accidentologySectionsData,
          distancesByFacilities,
          quartiles: accidentologySectionsQuartiles,
          reports: accidentologyReports,
          setBounds: setAccidentologySectionsBounds,
          setData: setAccidentologySectionsData,
          setDistancesByFacilities,
          setQuartiles: setAccidentologySectionsQuartiles,
          setReports: setAccidentologyReports,
          setSuddenBrakings,
          setSuddenBrakingsBounds: setAccidentologySuddenBrakingsBounds,
          setSuddenBrakingsSecondaryBounds: setAccidentologySuddenBrakingsSecondaryBounds,
          setTotalDistanceTraveled,
          suddenBrakings,
          suddenBrakingsBounds: accidentologySuddenBrakingsBounds,
          suddenBrakingsSecondaryBounds: accidentologySuddenBrakingsSecondaryBounds,
          totalDistanceTraveled,
        },
        potholes: {
          reportsTableRef: potholesReportsTableRef,
          statuses: potholesReportsStatuses,
          reports: potholesReports,
          selectedReportId: potholesSelectedId,
          setReports: setPotholesReports,
          selectReportId: selectPotholeReportId,
        },
        cyclability: {
          data: cyclabilityData,
          bounds: cyclabilityBounds,
          currentRange: cyclabilityCurrentRange,
          roadsCountByCyclability,
          secondaryBounds: cyclabilitySecondaryBounds,
          setData: setCyclabilityData,
          setBounds: setCyclabilityBounds,
          setCurrentRange: setCyclabilityCurrentRange,
          setRoadsCountByCyclability,
          setSecondaryBounds: setCyclabilitySecondaryBounds,
        },
        discontinuity: {
          bounds: discontinuityBounds,
          currentRange: discontinuityCurrentRange,
          data: discontinuityData,
          selectedFacilities: discontinuitySelectedFacilities,
          selectedSection: discontinuitySelectedSection,
          selectFacilities: selectDiscontinuityFacilities,
          setData: setDiscontinuityData,
          setBounds: setDiscontinuityBounds,
          setCurrentRange: setDiscontinuityCurrentRange,
          selectSection: selectDiscontinuitySection,
        },
        facilitiesSuggestions: {
          data: facilitiesSuggestionsData,
          selectedSuggestion,
          selectSuggestion,
          setData: setFacilitiesSuggestionsData,
        },
      }}
      page={page}
    />
  );
}

export {
  AccidentZoneTab,
  AccidentologyTab,
  DiscontinuityTab,
  DiscontinuityAnalysisTab,
  CyclabilityTab,
  FacilitiesTab,
  IsochronesTab,
  GeoveloRoadsQualityTab,
  ReportsTab,
  FacilitiesSuggestionsFormTab,
  FacilitiesSuggestionsTab,
  FacilitiesReportsTab,
  FacilitiesStatsTab,
  FacilitiesNewReportTab,
  PotholeReportsTab,
  RoadsQualityAnalysisTab,
};
export default CartographicDataPage;
