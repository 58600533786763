import { SvgIcon, SvgIconProps } from '@mui/material';

export function LockIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 25" {...props}>
      <path
        d="M12 13.6699C11.7348 13.6699 11.4804 13.7753 11.2929 13.9628C11.1054 14.1504 11 14.4047 11 14.6699V17.6699C11 17.9351 11.1054 18.1895 11.2929 18.377C11.4804 18.5646 11.7348 18.6699 12 18.6699C12.2652 18.6699 12.5196 18.5646 12.7071 18.377C12.8946 18.1895 13 17.9351 13 17.6699V14.6699C13 14.4047 12.8946 14.1504 12.7071 13.9628C12.5196 13.7753 12.2652 13.6699 12 13.6699ZM17 9.66992V7.66992C17 6.34384 16.4732 5.07207 15.5355 4.13439C14.5979 3.19671 13.3261 2.66992 12 2.66992C10.6739 2.66992 9.40215 3.19671 8.46447 4.13439C7.52678 5.07207 7 6.34384 7 7.66992V9.66992C6.20435 9.66992 5.44129 9.98599 4.87868 10.5486C4.31607 11.1112 4 11.8743 4 12.6699V19.6699C4 20.4656 4.31607 21.2286 4.87868 21.7912C5.44129 22.3539 6.20435 22.6699 7 22.6699H17C17.7956 22.6699 18.5587 22.3539 19.1213 21.7912C19.6839 21.2286 20 20.4656 20 19.6699V12.6699C20 11.8743 19.6839 11.1112 19.1213 10.5486C18.5587 9.98599 17.7956 9.66992 17 9.66992ZM9 7.66992C9 6.87427 9.31607 6.11121 9.87868 5.5486C10.4413 4.98599 11.2044 4.66992 12 4.66992C12.7956 4.66992 13.5587 4.98599 14.1213 5.5486C14.6839 6.11121 15 6.87427 15 7.66992V9.66992H9V7.66992ZM18 19.6699C18 19.9351 17.8946 20.1895 17.7071 20.377C17.5196 20.5646 17.2652 20.6699 17 20.6699H7C6.73478 20.6699 6.48043 20.5646 6.29289 20.377C6.10536 20.1895 6 19.9351 6 19.6699V12.6699C6 12.4047 6.10536 12.1504 6.29289 11.9628C6.48043 11.7753 6.73478 11.6699 7 11.6699H17C17.2652 11.6699 17.5196 11.7753 17.7071 11.9628C17.8946 12.1504 18 12.4047 18 12.6699V19.6699Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
