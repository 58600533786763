import { Scenario } from '@geovelo-frontends/commons';
import { AddCircleOutline } from '@mui/icons-material';
import { green, orange } from '@mui/material/colors';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import Card from '../../../../components/card';
import Table, { TRow } from '../../../../components/table';
import usePaginatedTable from '../../../../hooks/table/paginated';
import useSortableTable from '../../../../hooks/table/sortable';

type TKey = 'name' | 'status' | 'date';

const keys: TKey[] = ['name', 'status', 'date'];

interface IProps {
  scenarios?: Scenario[];
  onAdd: () => void;
  selectIndex: (index: number) => void;
  selectedIndex: number | null;
}

function ScenariosList({ scenarios, selectedIndex, selectIndex, onAdd }: IProps): JSX.Element {
  const [data, setData] = useState<TRow<number, TKey>[] | undefined>();
  const [rows, setRows] = useState<TRow<number, TKey>[] | undefined>();
  const { page, rowsPerPage, setPage, onPageChange, onRowsPerPageChange } = usePaginatedTable(10);
  const { orderBy, order, onSortRequest, sort } = useSortableTable<number, TKey>('date', 'desc', {
    setPage,
  });

  useEffect(() => {
    if (!scenarios) {
      setRows(undefined);

      return;
    }

    const _data: TRow<number, TKey>[] = scenarios.map(
      ({ computed, creationDate, scenarioId, scenarioDescription }) => ({
        key: scenarioId,
        inkBarColor: computed ? green[500] : orange[500],
        cells: {
          name: { value: scenarioDescription || scenarioId },
          status: {
            value: computed,
            format: (value: Moment) => (
              <Trans
                i18nKey={`cycling-insights.usage.travel_simulations.${
                  value ? 'available' : 'not_available'
                }`}
              />
            ),
          },
          date: { value: creationDate, format: (value: Moment) => value.format('L') },
        },
      }),
    );

    setData(_data);
    setRows(sort(_data, orderBy).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
  }, [scenarios]);

  useEffect(() => {
    if (data)
      setRows([...sort(data, orderBy).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)]);
  }, [order]);

  useEffect(() => {
    if (data) setRows([...data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)]);
  }, [page, rowsPerPage]);

  return (
    <Card
      actions={[
        {
          children: <Trans i18nKey="commons.actions.add" />,
          color: 'primary',
          disabled: !scenarios,
          key: 'add',
          onClick: onAdd,
          needWritePermission: true,
          startIcon: <AddCircleOutline />,
          variant: 'contained',
        },
      ]}
      permission="write"
      title={<Trans i18nKey="cycling-insights.usage.travel_simulations.scenarios_list" />}
      titleVariant="body1"
    >
      <Table
        hasInkBars
        count={scenarios?.length}
        headers={{
          name: { label: <Trans i18nKey="commons.last_name" /> },
          status: { label: <Trans i18nKey="commons.status" /> },
          date: { label: <Trans i18nKey="commons.date" />, sortable: true },
        }}
        keys={keys}
        onClick={(key) => selectIndex(key)}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        onSortRequest={onSortRequest}
        order={order}
        orderBy={orderBy}
        page={page}
        rows={rows}
        rowsPerPage={rowsPerPage}
        selectedKey={selectedIndex || undefined}
        tableProps={{ sx: { tableLayout: 'fixed' } }}
        title="Scenarios list"
      />
    </Card>
  );
}

export default ScenariosList;
