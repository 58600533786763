import {
  Autocomplete,
  ComputedRoute,
  ComputedRouteService,
  JOSMService,
  Place,
  Ride,
  RideService,
  RideTrace,
  Route,
  RouteService,
  Search,
  TCyclingProfile,
  TWayPoint,
  cyclingProfiles,
} from '@geovelo-frontends/commons';
import { Add, Delete, Edit, ExpandMore } from '@mui/icons-material';
import { FormControl, InputLabel, MenuItem, Select, Toolbar, Typography } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import { MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
// import { Prompt } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import ConfirmDialog from '../../../../components/confirm-dialog';
import useAlertBeforeUnload from '../../../../hooks/alert-before-unload';
import { TTab } from '../../tab';

import RouteMap from './map';
import TraceFormDialog from './trace-form-dialog';
import TracesMenu from './traces-menu';

import { Map as MaplibreMap } from '!maplibre-gl';

const profiles: TCyclingProfile[] = ['safe', 'daily', 'direct', 'touristic', 'hybridBike'];

interface IProps {
  canWrite: boolean;
  onChange: (ride: Ride) => void;
  onReturnRouteChange: (route: Route | null) => void;
  onRouteChange: (route: Route) => void;
  onTabChange: (tab: TTab) => void;
  onTracesChange: (traces: RideTrace[]) => void;
  ride: Ride;
  route?: Route | null;
  returnRoute?: Route | null;
  traces?: RideTrace[];
}

function RouteTab({
  canWrite,
  ride,
  returnRoute,
  route,
  traces,
  onTabChange,
  onChange,
  onRouteChange,
  onReturnRouteChange,
  onTracesChange,
}: IProps): JSX.Element {
  const [map, setMap] = useState<MaplibreMap>();
  const [snackbarKey, setSnackbarKey] = useState<SnackbarKey>();
  const [searchedPlace, setSearchedPlace] = useState<Place | null>(null);
  const [computedRoute, setComputedRoute] = useState<ComputedRoute | null>(null);
  const [computedRouteUpdated, setComputedRouteUpdated] = useState<boolean>(false);
  const [computedReturnRouteUpdated, setComputedReturnRouteUpdated] = useState<boolean>(false);
  const [computedReturnRoute, setComputedReturnRoute] = useState<ComputedRoute | null>(null);
  const [isReturnRoute, setReturnRoute] = useState<boolean>(false);
  const [isFirstEdit, setFirstEdit] = useState<boolean>(true);
  const [deleteReturnRoute, setDeleteReturnRoute] = useState<boolean>(false);
  const [isLoop, setLoop] = useState<boolean>(false);
  const [profile, setProfile] = useState<TCyclingProfile>('touristic');
  const [tracesMenuAnchorEl, setTracesMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [editing, setEditing] = useState(false);
  const [traceDialogOpen, openTraceDialog] = useState(false);
  const [saveDialogOpen, openSaveDialog] = useState(false);
  const [deleteDialogOpen, openDeleteDialog] = useState(false);
  const [loopDialogOpen, openLoopDialog] = useState(false);
  const [wayPoints, setWayPoints] = useState<TWayPoint[]>([]);
  const [loopWaypoints, setLoopWaypoints] = useState<TWayPoint[]>([]);
  const [loading, setLoading] = useState(false);
  const search = useRef(new Search({ profile: 'touristic' }));
  const returnSearch = useRef(new Search({ profile: 'touristic' }));
  const computationRef = useRef<number>(-1);
  const {
    ride: { traces: condensedTraces },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useAlertBeforeUnload();

  useEffect(() => {
    onTabChange('route');
  }, []);

  useEffect(() => {
    search.current.profile = route?.cyclingProfile || 'touristic';
    search.current.wayPoints = route?.wayPoints || [];
  }, [route]);

  useEffect(() => {
    async function startAsyncFunction() {
      let startEndEdited = false;
      if (ride?.routeId) {
        if (isReturnRoute) {
          startEndEdited = handleWaypointsChange(route, search);
        } else {
          setLoop(wayPoints[0]?.strPoint === wayPoints[wayPoints.length - 1]?.strPoint);
          startEndEdited = handleWaypointsChange(returnRoute, returnSearch);
        }
      }
      if (isReturnRoute) {
        returnSearch.current.wayPoints = wayPoints;
        await compute(returnSearch, true);
        if (startEndEdited) {
          compute(search, false);
        }
      } else {
        search.current.wayPoints = wayPoints;
        await compute(search, false);
        if (startEndEdited) {
          compute(returnSearch, true);
        }
      }
    }
    startAsyncFunction();
  }, [wayPoints]);

  useEffect(() => {
    returnSearch.current.profile =
      returnRoute?.cyclingProfile || route?.cyclingProfile || 'touristic';
    returnSearch.current.wayPoints = returnRoute?.wayPoints || [];
  }, [returnRoute]);

  useEffect(() => {
    if (editing) {
      setSnackbarKey(
        enqueueSnackbar(t('cycling-insights.ride.route.edit_indications'), {
          action: (key) => (
            <>
              <Button
                color="inherit"
                onClick={() => {
                  closeSnackbar(key);
                  setSnackbarKey(undefined);
                }}
                size="small"
              >
                <Trans i18nKey="commons.actions.close" />
              </Button>
            </>
          ),
          persist: true,
        }),
      );
    } else if (snackbarKey) {
      closeSnackbar(snackbarKey);
    }
    async function fetchData() {
      if (isReturnRoute) {
        if ((!returnRoute || returnRoute === null) && route && editing) {
          setFirstEdit(true);
          const _route: Route = JSON.parse(JSON.stringify(route));
          // reverse the order of the waypoints
          _route.wayPoints.reverse();
          await onReturnRouteChange(_route);
          compute(returnSearch, true);
        } else {
          setFirstEdit(false);
        }
      } else {
        setDeleteReturnRoute(ride.isLoop || false);
        setLoop(ride.isLoop || false);
      }
      setProfile(route?.cyclingProfile || 'touristic');
    }
    fetchData();
  }, [editing]);

  useEffect(() => {
    async function fetchData() {
      if (editing) {
        search.current.profile = profile;
        returnSearch.current.profile = profile;
        if (isReturnRoute) {
          await compute(search, false);
          await compute(returnSearch, true);
        } else {
          await compute(returnSearch, true);
          await compute(search, false);
        }
      }
    }
    fetchData();
  }, [profile]);

  async function handleTraceRemove(id: number) {
    if (!ride.id) return;

    setLoading(true);

    try {
      await RideService.removeTraceFromRide(ride.id, id);

      const updatedRide = ride.clone();
      updatedRide.traceIds.splice(
        ride.traceIds.findIndex((traceId) => id === traceId),
        1,
      );

      onChange(updatedRide);

      if (traces) {
        const updatedTraces = [...traces];
        updatedTraces.splice(
          traces.findIndex((trace) => id === trace.id),
          1,
        );

        onTracesChange(updatedTraces);
      }

      enqueueSnackbar(t('cycling-insights.ride.updated'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.not_updated'), { variant: 'error' });
    }

    setLoading(false);
    setTracesMenuAnchorEl(null);
  }

  function handleTraceDialogClose(trace?: RideTrace) {
    if (trace) {
      const updatedRide = ride.clone();
      updatedRide.traceIds.push(trace.id);
      onChange(updatedRide);
      if (traces) onTracesChange([...traces, trace]);
    }

    openTraceDialog(false);
  }

  function getJOSMLoadAndZoomUrl() {
    if (!map) return '';

    const bounds = map.getBounds();
    const { lat: north, lng: east } = bounds.getNorthEast();
    const { lat: south, lng: west } = bounds.getSouthWest();

    return JOSMService.getLoadAndZoomUrl({ north, east, south, west });
  }

  function handleCancel() {
    computationRef.current = -1;
    setDeleteReturnRoute(false);
    search.current.wayPoints = route?.wayPoints || [];
    returnSearch.current.wayPoints = returnRoute?.wayPoints || [];
    if (isFirstEdit) {
      onReturnRouteChange(null);
    }
    setEditing(false);
    setComputedReturnRoute(null);
    setComputedReturnRouteUpdated(true);
    setComputedRoute(null);
    setComputedRouteUpdated(true);
    setFirstEdit(false);
  }

  function handleWaypointsChange(
    route: Route | null | undefined,
    search: MutableRefObject<Search>,
  ) {
    let startEndEdited = false;
    const wp = [...search.current.wayPoints];
    if (route && wayPoints[0]?.strPoint !== route.wayPoints[route.wayPoints.length - 1].strPoint) {
      wp[wp.length - 1] = wayPoints[0];
      startEndEdited = true;
    }
    if (route && wayPoints[wayPoints.length - 1]?.strPoint !== route.wayPoints[0].strPoint) {
      startEndEdited = true;
      if (wayPoints[wayPoints.length - 2]?.strPoint === route.wayPoints[0].strPoint) {
        wp.unshift(wayPoints[wayPoints.length - 1]);
      } else {
        wp[0] = wayPoints[wayPoints.length - 1];
      }
    }
    search.current.wayPoints = wp as Place[];
    return startEndEdited;
  }

  function handleWaypointsClicked(wayPoints: TWayPoint[], index: number) {
    if (index === 0) {
      // handle click on start (to make a loop)
      if (!isReturnRoute) {
        setLoopWaypoints(wayPoints);
        openLoopDialog(true);
      }
    }
  }

  function handleLoop() {
    search.current.wayPoints = loopWaypoints;
    setDeleteReturnRoute(true);
    setLoop(true);
    compute(search, false);
    openLoopDialog(false);
  }

  async function handleSave(deleteReturnRoute?: boolean, isLoop?: boolean) {
    if (!ride.id || (!computedRoute && !computedReturnRoute && !deleteReturnRoute)) return;

    let computedRouteId, wayPoints;
    let computedReturnRouteId;

    setLoading(true);

    try {
      let _route: Route | null = null;
      let _returnRoute: Route | null = null;

      if (computedRoute) {
        computedRouteId = computedRoute.id;
        wayPoints = computedRoute.wayPoints;
        if (!ride.routeId) {
          _route = await RouteService.saveRoute({
            type: 'RIDE',
            computedRouteId,
          });
        } else {
          _route = await RouteService.updateRoute(ride.routeId, {
            type: 'RIDE',
            computedRouteId,
          });
        }
      }

      if (computedReturnRoute && !deleteReturnRoute) {
        computedReturnRouteId = computedReturnRoute.id;
        if (!ride.returnRouteId) {
          _returnRoute = await RouteService.saveRoute({
            type: 'RIDE',
            computedRouteId: computedReturnRouteId,
          });
        } else {
          _returnRoute = await RouteService.updateRoute(ride.returnRouteId, {
            type: 'RIDE',
            computedRouteId: computedReturnRouteId,
          });
        }
      }

      const _ride = await RideService.updateRideV1(ride.id, {
        route_atob: _route ? _route.id : undefined,
        route_btoa: deleteReturnRoute === true ? null : _returnRoute ? _returnRoute.id : undefined,
        geo_point_a: computedRoute && wayPoints ? wayPoints[0].point : undefined,
        geo_point_b: computedRoute && wayPoints ? wayPoints[wayPoints.length - 1].point : undefined,
        is_loop: isLoop,
      });

      if (_route !== null) {
        onRouteChange(_route);
      }
      if (_returnRoute !== null) {
        onReturnRouteChange(_returnRoute);
      }
      if (deleteReturnRoute) {
        setComputedReturnRoute(null);
        setComputedReturnRouteUpdated(true);
        onReturnRouteChange(null);
      }
      onChange(_ride);
      setFirstEdit(false);

      openSaveDialog(false);
      openDeleteDialog(false);
      setEditing(false);
      enqueueSnackbar(t('cycling-insights.ride.updated'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.not_updated'), { variant: 'error' });
    }

    setLoading(false);
  }

  async function compute(search: MutableRefObject<Search>, computeReturnRoute?: boolean) {
    if (!search.current.computable) {
      return;
    }

    const timestamp = new Date().getTime();

    computationRef.current = timestamp;

    try {
      const {
        computedRoutes: [computedRoute],
      } = await ComputedRouteService.compute(search.current, {
        geometry: true,
        singleResult: true,
        allowWaypointsSnapping: true,
      });

      if (!computedRoute) throw new Error('no route');

      if (timestamp === computationRef.current) {
        if (computeReturnRoute === true) {
          setComputedReturnRoute(computedRoute);
          setComputedReturnRouteUpdated(true);
        } else {
          setComputedRoute(computedRoute);
          setComputedRouteUpdated(true);
        }
      }
    } catch {
      if (timestamp === computationRef.current) {
        enqueueSnackbar(t('cycling-insights.ride.route.no_route'), { variant: 'error' });
        if (computeReturnRoute === true) {
          setComputedReturnRoute(null);
          setComputedReturnRouteUpdated(true);
        } else {
          setComputedRoute(null);
          setComputedRouteUpdated(true);
        }
      }
    }
  }

  return (
    <>
      <Wrapper>
        {canWrite && (
          <StyledToolbar>
            <StyledAutocomplete
              disableFloatingLabel
              defaultValue={searchedPlace}
              disabled={!map}
              onSelect={setSearchedPlace}
              size="small"
            />
            {editing ? (
              <>
                <FormControl margin="dense" size="small" variant="outlined">
                  <InputLabel htmlFor="profile-label">
                    <Trans i18nKey="cycling-insights.ride.route.profile" />
                  </InputLabel>
                  <Select
                    inputProps={{
                      id: 'profile-label',
                    }}
                    label={<Trans i18nKey="cycling-insights.ride.route.profile" />}
                    name="type"
                    onChange={({ target: { value } }) => setProfile(value as TCyclingProfile)}
                    size="small"
                    value={profile}
                  >
                    {profiles.map((key) => (
                      <MenuItem key={key} value={key}>
                        <Trans i18nKey={cyclingProfiles[key]?.labelKey} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <StyledSpacer />
                <Button
                  component="a"
                  disabled={!map}
                  href={getJOSMLoadAndZoomUrl()}
                  rel="noreferrer"
                  target="_blank"
                  variant="outlined"
                >
                  <Trans i18nKey="commons.actions.to_josm" />
                </Button>
                <Button onClick={handleCancel} variant="outlined">
                  <Trans i18nKey="commons.actions.cancel" />
                </Button>
                <Button
                  color="primary"
                  disabled={!computedRoute && !isReturnRoute}
                  onClick={() => openSaveDialog(true)}
                  variant="contained"
                >
                  <Trans i18nKey="commons.actions.save" />
                </Button>
              </>
            ) : (
              <>
                <StyledSpacer />
                {ride.returnRouteId && (
                  <Button
                    onClick={() => openDeleteDialog(true)}
                    startIcon={<Delete />}
                    style={{ borderColor: 'red', color: 'red' }}
                    variant="outlined"
                  >
                    <Trans i18nKey="cycling-insights.ride.actions.delete_return" />
                  </Button>
                )}
                <Button
                  color="primary"
                  onClick={() => {
                    setReturnRoute(false);
                    setEditing(true);
                  }}
                  startIcon={ride.routeId ? <Edit /> : <Add />}
                  variant="contained"
                >
                  <Trans
                    i18nKey={
                      ride.routeId
                        ? 'cycling-insights.ride.route.actions.edit_route'
                        : 'cycling-insights.ride.route.actions.add_route'
                    }
                  />
                </Button>
                {ride.routeId && !ride.isLoop && (
                  <Button
                    color="primary"
                    onClick={() => {
                      setReturnRoute(true);
                      setEditing(true);
                    }}
                    startIcon={ride.returnRouteId ? <Edit /> : <Add />}
                    variant="contained"
                  >
                    <Trans
                      i18nKey={
                        ride.returnRouteId
                          ? 'cycling-insights.ride.route.actions.edit_return_route'
                          : 'cycling-insights.ride.route.actions.add_return_route'
                      }
                    />
                  </Button>
                )}
                <Button
                  disabled={!condensedTraces}
                  endIcon={<ExpandMore />}
                  onClick={({ currentTarget }) => setTracesMenuAnchorEl(currentTarget)}
                  variant="outlined"
                >
                  <Trans i18nKey="cycling-insights.ride.route.actions.manage_traces" />
                </Button>
                {condensedTraces && (
                  <TracesMenu
                    anchorEl={tracesMenuAnchorEl}
                    loading={loading}
                    onAdd={() => openTraceDialog(true)}
                    onClose={() => setTracesMenuAnchorEl(null)}
                    onRemove={handleTraceRemove}
                    ride={ride}
                    traces={condensedTraces}
                  />
                )}
              </>
            )}
          </StyledToolbar>
        )}
        <RouteMap
          canWrite={canWrite}
          computedReturnRoute={computedReturnRoute}
          computedReturnRouteUpdated={computedReturnRouteUpdated}
          computedRoute={computedRoute}
          computedRouteUpdated={computedRouteUpdated}
          editing={editing}
          isDeletedRoute={deleteReturnRoute}
          isReturnRoute={isReturnRoute}
          map={map}
          onInit={setMap}
          onWayPointClicked={handleWaypointsClicked}
          onWayPointsUpdate={setWayPoints}
          returnRoute={returnRoute}
          ride={ride}
          route={route}
          search={search.current}
          searchedPlace={searchedPlace}
          setComputedReturnRouteUpdated={setComputedReturnRouteUpdated}
          setComputedRouteUpdated={setComputedRouteUpdated}
          setReturnRoute={setReturnRoute}
          traces={traces}
        />
      </Wrapper>
      {condensedTraces && (
        <TraceFormDialog
          onClose={handleTraceDialogClose}
          open={traceDialogOpen}
          ride={ride}
          traces={condensedTraces
            .filter(({ id }) => ride.traceIds.indexOf(id) === -1)
            .sort((a, b) => a.title.localeCompare(b.title))}
        />
      )}
      <ConfirmDialog
        safe
        description={
          <Typography>
            <Trans i18nKey="cycling-insights.ride.route.confirm_dialog.description" />
          </Typography>
        }
        dialogTitle="ride-route-save-dialog"
        loading={loading}
        onCancel={() => openSaveDialog(false)}
        onConfirm={() => handleSave(deleteReturnRoute, isLoop)}
        open={saveDialogOpen}
        title={<Trans i18nKey="cycling-insights.ride.route.confirm_dialog.title" />}
      />
      <ConfirmDialog
        description={
          <Typography style={{ color: 'red' }}>
            <Trans i18nKey="cycling-insights.ride.route.confirm_dialog.description" />
          </Typography>
        }
        dialogTitle="ride-route-delete-dialog"
        loading={loading}
        onCancel={() => openDeleteDialog(false)}
        onConfirm={() => handleSave(true, false)}
        open={deleteDialogOpen}
        title={<Trans i18nKey="cycling-insights.ride.route.confirm_dialog.delete_title" />}
      />
      <ConfirmDialog
        safe
        description={
          <Typography color="secondary">
            <Trans i18nKey="cycling-insights.ride.route.confirm_dialog.loop_description" />
          </Typography>
        }
        dialogTitle="ride-route-loop-dialog"
        loading={loading}
        onCancel={() => openLoopDialog(false)}
        onConfirm={() => handleLoop()}
        open={loopDialogOpen}
        title={<Trans i18nKey="cycling-insights.ride.route.confirm_dialog.loop_title" />}
      />
      {/* <Prompt message={t('cycling-insights.ride.lose_changes_alert')} when={editing} /> */}
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledToolbar = styled(Toolbar)`
  flex-shrink: 0;

  button {
    margin-left: 8px;
  }
`;

const StyledAutocomplete = styled(Autocomplete)`
  && {
    margin-right: 16px;
    width: 300px !important;

    input {
      padding: 0;
    }
  }
`;

const StyledSpacer = styled.div`
  flex-grow: 1;
`;

export default RouteTab;
