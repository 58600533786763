import { Box, Skeleton, Typography } from '@mui/material';
import { ReactNode } from 'react';

function DataCard({
  backgroundColor,
  data,
  dataColor,
  caption,
  icon,
}: {
  backgroundColor: string;
  data?: string;
  dataColor: string;
  caption: ReactNode;
  icon: ReactNode;
}): JSX.Element {
  return (
    <Box
      alignItems="center"
      bgcolor={backgroundColor}
      borderRadius={4}
      display="flex"
      gap={3}
      paddingX={4}
      paddingY={3}
    >
      {icon}
      <Box display="flex" flexDirection="column">
        <Typography color={dataColor} fontSize="1.5rem" fontWeight={700}>
          {data ? (
            data
          ) : (
            <Skeleton height={24} sx={{ marginBottom: '8px' }} variant="rectangular" width={144} />
          )}
        </Typography>
        {caption}
      </Box>
    </Box>
  );
}

export default DataCard;
