import { SvgIcon, SvgIconProps } from '@mui/material';

function IndicatorsIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 55 54" {...props}>
      <path
        d="M39.1819 11.15H26.7743C25.7513 11.15 24.9219 11.9793 24.9219 13.0023C24.9219 14.0254 25.7513 14.8547 26.7743 14.8547H39.1819C41.8966 14.8547 44.2939 17.1352 44.2939 20.0061C44.2939 22.9756 42.1438 25.1491 39.5105 25.1491H15.6511C10.8012 25.1491 7.15 29.2202 7.15 33.9939C7.15 38.7759 10.8011 42.85 15.6511 42.85H28.6595C29.681 42.85 30.509 42.0219 30.509 41.0005C30.509 39.979 29.681 39.1509 28.6595 39.1509H15.6511C12.7885 39.1509 10.7142 36.722 10.7142 33.9939C10.7142 31.2826 12.7886 28.8425 15.6511 28.8425H39.5078C44.1142 28.8425 47.85 25.0053 47.85 20.0033C47.85 15.0762 43.8818 11.15 39.1819 11.15Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
      <path
        d="M14.8 13.4919C14.8 15.1827 13.427 16.55 11.75 16.55C10.073 16.55 8.7 15.1827 8.7 13.4919C8.7 11.8195 10.0654 10.45 11.75 10.45C13.4346 10.45 14.8 11.8195 14.8 13.4919ZM11.75 20.35C15.5286 20.35 18.6 17.2785 18.6 13.4919C18.6 9.71594 15.5284 6.65 11.75 6.65C7.9716 6.65 4.9 9.71594 4.9 13.4919C4.9 17.2785 7.97139 20.35 11.75 20.35Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
      <path
        d="M46.3 40.4892C46.3 42.1837 44.926 43.55 43.25 43.55C41.574 43.55 40.2 42.1837 40.2 40.4892C40.2 38.8205 41.5644 37.45 43.25 37.45C44.9356 37.45 46.3 38.8205 46.3 40.4892ZM43.25 47.35C47.0286 47.35 50.1 44.2784 50.1 40.4892C50.1 36.7159 47.0284 33.65 43.25 33.65C39.4716 33.65 36.4 36.7159 36.4 40.4892C36.4 44.2784 39.4714 47.35 43.25 47.35Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
    </SvgIcon>
  );
}

export default IndicatorsIcon;
