import {
  ApiKey,
  ApiService,
  ApiStats,
  TApiStatType,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  MenuProps,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import { ICurrentStat, IPrevStat } from '../../../../components/dual-chart';
import PeriodForm from '../../../../components/form/period';
import { getPeriodsDiff } from '../../../../utils/period';
import { getProgression } from '../../../../utils/stats';
import { IApiPageContext } from '../../context';

import ApiKeyFormDialog from './form-dialog';
import ApiKeysList from './list';
import { types, typesMap } from './types';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const menuProps: Partial<MenuProps> = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ApiKeysForm({
  period,
  apiKeys: {
    list,
    selectedIndex,
    selectedTypes,
    selectedResponseType,
    setCurrentStats,
    selectTypes,
    selectResponseType,
    setList,
    setRequestsStats,
    setResponsesStats,
    selectIndex,
  },
}: IApiPageContext): JSX.Element {
  const [stats, setStats] = useState<{ current: ApiStats[]; prev: ApiStats[] }>();
  const [formDialogOpen, openFormDialog] = useState(false);
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { cancellablePromise: cancellableStatsPromise, cancelPromises: cancelStatsPromises } =
    useCancellablePromise();

  useEffect(() => {
    getApiKeys();

    return () => {
      cancelPromises();
      cancelStatsPromises();
      setList(undefined);
      selectIndex(null);
      setRequestsStats(undefined);
      setResponsesStats(undefined);
    };
  }, []);

  useEffect(() => {
    getStats();
  }, [period.values, period.comparisonEnabled, list, selectedIndex]);

  useEffect(() => {
    if (stats) {
      const { current: currentPeriod, prev: prevPeriod } = period.values;
      const { current: currentStats, prev: prevStats } = stats;
      setCurrentStats(currentStats);
      const diff = getPeriodsDiff(currentPeriod, prevPeriod);

      const currentRequestsStats: ICurrentStat<TApiStatType> = formatStats(currentStats);
      const prevRequestsStats: IPrevStat<TApiStatType> = formatStats(prevStats, diff);
      const progression = getProgression(currentRequestsStats.count, prevRequestsStats.count);

      setRequestsStats({ current: currentRequestsStats, prev: prevRequestsStats, progression });
    }
  }, [stats, selectedTypes]);

  useEffect(() => {
    if (!stats) return;

    if (selectedTypes.indexOf(selectedResponseType) === -1) selectResponseType(selectedTypes[0]);
    else {
      const { current: currentPeriod, prev: prevPeriod } = period.values;
      const { current: currentStats, prev: prevStats } = stats;
      const { type: diffType, value: diff } = getPeriodsDiff(currentPeriod, prevPeriod);

      const currentResponsesStats: ICurrentStat<TApiStatType> = {
        count: -1,
        values: currentStats.map(({ date, stats: dateStats }) => ({
          date,
          value: dateStats[selectedResponseType].medianResponseTime || 0,
        })),
      };

      const prevResponsesStats: IPrevStat<TApiStatType> = {
        count: -1,
        values: prevStats.map(({ date, stats: dateStats }) => ({
          initialDate: date.clone(),
          date: date.clone().add(diff, diffType),
          value: dateStats[selectedResponseType].medianResponseTime || 0,
        })),
      };

      setResponsesStats({
        current: currentResponsesStats,
        prev: prevResponsesStats,
        progression: -1,
      });
    }
  }, [stats, selectedTypes, selectedResponseType]);

  function formatStats(
    stats: ApiStats[],
    diff?: { type: 'year' | 'month' | 'week' | 'days'; value: number },
  ) {
    let count = 0;
    const detailedCount: { [key in TApiStatType]: number } = {
      routeComputer: 0,
      routeComputedSimplified: 0,
      m2m: 0,
    };
    const values: { initialDate: Moment; date: Moment; value: number }[] = [];
    stats.forEach(({ date, stats: dateStats }) => {
      let currentStatsValue = 0;
      selectedTypes.forEach((key) => {
        const dateCount = dateStats[key].count;
        detailedCount[key] += dateCount;
        count += dateCount;
        currentStatsValue += dateCount;
      });
      values.push({
        initialDate: date.clone(),
        date: diff ? date.clone().add(diff.value, diff.type) : date,
        value: currentStatsValue,
      });
    });

    return { count, detailedCount, values };
  }

  async function getApiKeys() {
    if (!currentPartner) return;

    try {
      const apiKeys = await cancellablePromise(ApiService.getApiKeys(currentPartner));
      apiKeys.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

      if (apiKeys.length > 1) {
        apiKeys.splice(0, 0, new ApiKey('all', t('cycling-insights.api.keys.table.all'), ''));
      }

      setList(apiKeys);

      if (apiKeys.length > 0) selectIndex(0);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.api.keys.server_error'), { variant: 'error' });
      }
    }
  }

  async function getStats() {
    cancelStatsPromises();
    setStats(undefined);
    setRequestsStats(undefined);
    setResponsesStats(undefined);

    if (!currentPartner || !list || selectedIndex === null) return;

    const selectedApiKey = list[selectedIndex];
    if (!selectedApiKey) return;

    try {
      const { current: currentPeriod, prev: prevPeriod } = period.values;
      const [currentStats, prevStats] = await cancellableStatsPromise(
        Promise.all([
          ApiService.getApiStats(currentPartner, currentPeriod.toIPeriod(), selectedApiKey),
          ApiService.getApiStats(currentPartner, prevPeriod.toIPeriod(), selectedApiKey),
        ]),
      );

      setStats({ current: currentStats, prev: prevStats });
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.api.requests.server_error'), { variant: 'error' });
      }
    }
  }

  function handleAdd() {
    openFormDialog(true);
  }

  function handleFormDialogClose(apiKey?: ApiKey | null) {
    if (apiKey && list) {
      const apiKeys = [...list, apiKey];
      apiKeys.sort((a, b) =>
        a.id === 'all'
          ? -1
          : b.id === 'all'
            ? 1
            : a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
      );

      setList(apiKeys);
      selectIndex(apiKeys.findIndex(({ id }) => apiKey.id === id));
    }

    openFormDialog(false);
  }

  function handleTypesChange({ target: { value } }: SelectChangeEvent<TApiStatType[]>) {
    if (typeof value !== 'string' && value.length > 0) selectTypes?.(value);
  }

  return (
    <>
      <PeriodForm forceComparison {...period} comparisonEnabled />
      <Box marginBottom={1} paddingX={2}>
        <FormControl fullWidth margin="dense" size="small" variant="outlined">
          <InputLabel id="request-type-label">
            <Trans i18nKey="cycling-insights.api.requests.type" />
          </InputLabel>
          <Select
            multiple
            id="request-type-chip"
            input={
              <OutlinedInput
                id="select-request-type-chip"
                label={<Trans i18nKey="cycling-insights.api.requests.type" />}
              />
            }
            labelId="request-type-chip-label"
            MenuProps={menuProps}
            onChange={handleTypesChange}
            renderValue={(selected) => (
              <Box display="flex" flexWrap="wrap" gap="4px">
                {selected.map((key) => (
                  <Chip key={key} label={<Trans i18nKey={typesMap[key].labelKey} />} size="small" />
                ))}
              </Box>
            )}
            value={selectedTypes}
          >
            {types.map((key) => (
              <MenuItem dense key={key} value={key}>
                <Checkbox
                  checked={selectedTypes && selectedTypes.indexOf(key) > -1}
                  disabled={selectedTypes && selectedTypes.length === 1 && selectedTypes[0] === key}
                  style={{ padding: 4, marginRight: 8 }}
                />
                <ListItemText primary={<Trans i18nKey={typesMap[key].labelKey} />} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box flexGrow={1} padding={2} sx={{ overflowY: 'auto' }}>
        <ApiKeysList
          apiKeys={list}
          onAdd={handleAdd}
          selectedIndex={selectedIndex}
          selectIndex={selectIndex}
        />
      </Box>
      <ApiKeyFormDialog apiKeys={list} onClose={handleFormDialogClose} open={formDialogOpen} />
    </>
  );
}

export default ApiKeysForm;
