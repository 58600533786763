import { useOutletContext } from 'react-router-dom';

import PageContentLayout from '../../../../layouts/page/content';
import { TGISPageContext } from '../../context';

import SendDataTable from './table';

function SendDataTab(): JSX.Element {
  const context = useOutletContext<TGISPageContext>();

  return <PageContentLayout main={<SendDataTable {...context} />} />;
}

export default SendDataTab;
