import { AuthService, DashboardPages } from '@geovelo-frontends/commons';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../app/context';
import pages from '../pages/dashboard-routes';

function useDashboardPage(key: DashboardPages): { allowed?: boolean } {
  const [allowed, allow] = useState<boolean>();
  const {
    partner: { current: currentPartner },
    actions: { setCurrentUser, setCurrentPage },
  } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentPartner) {
      if (!currentPartner.enableDashboardPageAccess[key]) {
        const defaultPage = pages.find(
          ({ pageKey, tabs }) =>
            (
              tabs?.filter(
                ({ key, hideInDrawer }) =>
                  currentPartner?.dashboardTabsPermissions[key] !== 'none' && !hideInDrawer,
              ) || []
            ).length > 0 && currentPartner.enableDashboardPageAccess[pageKey],
        );

        allow(false);

        if (defaultPage || currentPartner.dashboardTabsPermissions['downloads'])
          navigate(`/${currentPartner.code}/${defaultPage?.path || 'downloads'}`);
        else signOut();
      } else {
        allow(true);
        setCurrentPage(key);
      }
    }
  }, [currentPartner, key]);

  async function signOut() {
    await AuthService.signOut();
    setCurrentUser(null);
  }

  return { allowed };
}

export default useDashboardPage;
