import { Report, ReportService, useCancellablePromise } from '@geovelo-frontends/commons';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../app/context';
import useCartographicReports from '../../hooks/map/cartographic-reports';
import useQueryParams from '../../hooks/query-params';
import PeriodForm from '../form/period';
import TabIntroduction from '../tab-introduction';

import FacilitiesReportsTable, { TReportsTableProps } from './table';

function ReportsForm(props: TReportsTableProps & { introductionKey?: string }): JSX.Element {
  const {
    period,
    context: { statuses, reports, selectedReportId, setReports, selectReportId },
    typeCodes,
  } = props;
  const [initialized, setInitialized] = useState(false);
  const {
    map: { current: currentMap },
    actions: { getReportTypes },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { update: updateQueryParams } = useQueryParams();
  const { initialized: layersInitialized, selectReport: selectReportOnMap } =
    useCartographicReports(currentMap, {
      cartographicReports: reports || [],
      onReportSelected: selectReportId,
    });

  useEffect(() => {
    setInitialized(true);

    return () => {
      cancelPromises();
      setReports(undefined);
    };
  }, []);

  useEffect(() => {
    if (initialized) {
      try {
        getReportTypes();
      } catch (err) {
        enqueueSnackbar(t('cycling-insights.reports.types.server_error'), { variant: 'error' });
      }
    }
  }, [initialized]);

  useEffect(() => {
    if (initialized) update();
  }, [initialized, period.values]);

  useEffect(() => {
    const report = reports?.find(({ id }) => selectedReportId === id);
    if (layersInitialized) selectReportOnMap(report || null);
  }, [layersInitialized, selectedReportId]);

  async function update() {
    cancelPromises();
    setReports(undefined);

    try {
      const _reports: Report[] = [];
      let hasNext = true;
      let page = 1;

      while (hasNext) {
        const { reports, next } = await cancellablePromise(
          ReportService.getReports({
            period: period.values.current.toIPeriod(),
            typeCodes,
            status: statuses || ['CLOSED', 'ARCHIVED', 'CLOSED_BY_OSM'],
            page: page++,
            rowsPerPage: 500,
            query:
              '{id, geo_point, creator{username}, created, updated, description, source, report_type_code, status, reviews, report_source, osm_note_id, photo, is_valid, city_name, city_reference}',
          }),
        );

        _reports.push(...reports);
        hasNext = Boolean(next);
      }

      setReports(_reports);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.reports.cartographic_reports.server_error'), {
          variant: 'error',
        });
      }
    }

    updateQueryParams({
      currentPeriod: period.values.current,
      timePeriod: period.values.timePeriod,
      dayPeriod: period.values.dayPeriod,
    });
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={5} minHeight="100%">
        <TabIntroduction
          title={
            props.introductionKey ||
            'cycling-insights.cartographic_data.introduction.cartographic_reports'
          }
        />
        <Box display="flex" flexDirection="column" gap={3}>
          <PeriodForm disableComparison disablePadding {...period} />
          <Box flexGrow={1} marginX={-3} sx={{ overflowY: 'auto' }}>
            <FacilitiesReportsTable {...props} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ReportsForm;
