import { WidgetConfig } from '@geovelo-frontends/commons';
import { AddCircleOutline, Delete, Edit, WebAssetOff } from '@mui/icons-material';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Fragment, MouseEvent, useContext } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../../../../app/context';
import Card from '../../../../components/card';

function WidgetsList({
  widgets,
  selectedIndex,
  onAdd,
  onSelect,
  onRemove,
  onEdit,
  ...props
}: {
  onAdd: (event: MouseEvent<HTMLButtonElement>) => void;
  onEdit: (event: MouseEvent<HTMLButtonElement>) => void;
  onRemove: (event: MouseEvent<HTMLButtonElement>) => void;
  onSelect: (index: number) => void;
  selectedIndex: number | null;
  widgets: WidgetConfig[] | undefined;
}): JSX.Element {
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);

  return (
    <Card
      actions={[
        {
          needWritePermission: true,
          children: <Trans i18nKey="commons.actions.add" />,
          color: 'primary',
          key: 'add',
          onClick: onAdd,
          startIcon: <AddCircleOutline />,
          variant: 'contained',
        },
      ]}
      contentProps={
        (widgets &&
          widgets.length === 0 && { elevation: 0, style: { backgroundColor: 'transparent' } }) ||
        undefined
      }
      permission={
        currentPartner && widgets && (currentPartner.code === 'geovelo' || widgets.length < 1)
          ? 'write'
          : 'none'
      }
      title={<Trans i18nKey="cycling-insights.widgets.list.title" />}
      titleVariant="body2"
      {...props}
    >
      {widgets ? (
        widgets.length > 0 ? (
          <List>
            {widgets.map(({ id, title }, index) => {
              const active = index === selectedIndex;

              return (
                <Fragment key={id}>
                  <StyledListItemButton
                    className={active ? 'active' : ''}
                    disableRipple={active}
                    onClick={() => onSelect(index)}
                  >
                    <ListItemText primary={title} />
                    {active && (
                      <StyledActions>
                        <Tooltip placement="left" title={<Trans i18nKey="commons.actions.edit" />}>
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation();

                              onEdit(event);
                            }}
                            size="small"
                          >
                            <Edit fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          placement="left"
                          title={<Trans i18nKey="commons.actions.remove" />}
                        >
                          <IconButton
                            color="error"
                            onClick={(event) => {
                              event.stopPropagation();

                              onRemove(event);
                            }}
                            size="small"
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </StyledActions>
                    )}
                  </StyledListItemButton>
                </Fragment>
              );
            })}
          </List>
        ) : (
          <EmptyStateWrapper>
            <WebAssetOff color="action" fontSize="large" />
            <Typography color="textSecondary">
              <Trans i18nKey="cycling-insights.widgets.list.empty_state" />
            </Typography>
          </EmptyStateWrapper>
        )
      ) : (
        <List>
          {[1, 2, 3].map((key) => (
            <Fragment key={key}>
              <StyledListItem>
                <ListItemText primary={<Skeleton variant="text" width={200} />} />
                <StyledActions>
                  <Skeleton height={30} variant="circular" width={30} />
                  <Skeleton height={30} variant="circular" width={30} />
                </StyledActions>
              </StyledListItem>
            </Fragment>
          ))}
        </List>
      )}
    </Card>
  );
}

const EmptyStateWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  padding: 16px;
`;

const StyledListItemButton = styled(ListItemButton)`
  && {
    padding-right: 100px;

    &.active {
      background-color: ${({ theme }) => alpha(theme.palette.secondary.main, 0.3)};
    }
  }
`;

const StyledListItem = styled(ListItem)`
  && {
    padding-right: 100px;
  }
`;

const StyledActions = styled(ListItemSecondaryAction)`
  display: flex;
  gap: 8px;
`;

export default WidgetsList;
