import { Box } from '@mui/material';
import { useOutletContext } from 'react-router-dom';

import { TOutletContext } from './container';

function PageContentLayout({
  leftPanel,
  main,
  rightPanel,
}: {
  leftPanel?: JSX.Element;
  main?: JSX.Element;
  rightPanel?: JSX.Element;
}): JSX.Element {
  const outletContext = useOutletContext<TOutletContext | undefined>();

  switch (outletContext?.contentType) {
    case 'leftPanel':
      return (
        <Box display="flex" flexDirection="column" flexGrow={1} padding="0 24px 24px">
          {leftPanel}
        </Box>
      );
    case 'main':
      return <>{main}</>;
    case 'rightPanel':
      return <>{rightPanel}</>;
    default:
      return <></>;
  }
}

export default PageContentLayout;
