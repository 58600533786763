import { CheckCircle, Error, Info, Warning } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { VariantType } from 'notistack';

import theme from './theme';

type Icon = (props: SvgIconProps) => JSX.Element;

const CheckIcon: Icon = (props) => (
  <ThemeProvider theme={theme}>
    <CheckCircle {...props} />
  </ThemeProvider>
);

const WarningIcon: Icon = (props) => (
  <ThemeProvider theme={theme}>
    <Warning {...props} />
  </ThemeProvider>
);

const ErrorIcon: Icon = (props) => (
  <ThemeProvider theme={theme}>
    <Error {...props} />
  </ThemeProvider>
);

const InfoIcon: Icon = (props) => (
  <ThemeProvider theme={theme}>
    <Info {...props} />
  </ThemeProvider>
);

const iconStyles = {
  fontSize: 20,
  marginInlineEnd: 8,
};

const iconVariants: Partial<Record<VariantType, React.ReactNode>> = {
  default: undefined,
  success: <CheckIcon style={iconStyles} />,
  warning: <WarningIcon style={iconStyles} />,
  error: <ErrorIcon style={iconStyles} />,
  info: <InfoIcon style={iconStyles} />,
};

export default iconVariants;
