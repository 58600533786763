import { PartnerContract } from '@geovelo-frontends/commons';
import { AddCircleOutline, CalendarToday } from '@mui/icons-material';
import { Box, Chip } from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { AppContext } from '../../../app/context';
import Button from '../../../components/button';
import List, { IItem } from '../../../components/list';

interface IProps {
  onAdd: () => void;
  onRemove: (id: number | string) => void;
  onUpdate: (id: number | string) => void;
  selectIndex: (index: number) => void;
  selectedIndex?: number;
  contracts?: PartnerContract[];
}

const now = moment();

function ManageContractsList({
  contracts,
  selectedIndex,
  selectIndex,
  onAdd,
  onRemove,
  onUpdate,
}: IProps): JSX.Element {
  const [items, setItems] = useState<IItem<number>[]>();
  const {
    partner: { contractTemplates },
    user: { current: currentUser },
  } = useContext(AppContext);

  useEffect(() => {
    if (contracts)
      setItems(
        contracts.map(({ id: key, title, startDateTime, endDateTime }) => ({
          disableActions: !currentUser?.isGeovelo,
          key,
          title: (
            <Box display="flex" gap={1} marginBottom={1}>
              {title}
              <Chip
                label={
                  <Trans
                    i18nKey={`cycling-insights.admin.manage_contracts.status.${
                      startDateTime < now && (!endDateTime || endDateTime > now)
                        ? 'in_progress'
                        : startDateTime > now
                          ? 'future'
                          : 'passed'
                    }`}
                  />
                }
                size="small"
                sx={
                  startDateTime < now && (!endDateTime || endDateTime > now)
                    ? {
                        backgroundColor: '#E4F2DE',
                        color: '#268B1D',
                        border: '1px solid #97D391',
                        borderRadius: '4px',
                      }
                    : {
                        backgroundColor: '#F2F2F2',
                        color: '#666666',
                        border: '1px solid #B0B0B0',
                        borderRadius: '4px',
                      }
                }
              />
            </Box>
          ),
          titleProps: { fontWeight: 700 },
          subtitle: endDateTime ? (
            <Box display="flex" gap={1}>
              <CalendarToday sx={{ color: '#326AC2', fontSize: '16px' }} />
              <Trans
                i18nKey="commons.periods.from_to"
                values={{ from: startDateTime.format('L'), to: endDateTime.format('L') }}
              />
            </Box>
          ) : (
            <Trans i18nKey="commons.periods.from" values={{ from: startDateTime.format('L') }} />
          ),
        })),
      );
    else setItems(undefined);
  }, [contracts]);

  return (
    <>
      <List
        dense
        emptyState={
          <Trans i18nKey="cycling-insights.admin.manage_contracts.contracts_list.empty_state" />
        }
        items={items}
        onClick={selectIndex}
        onEdit={currentUser?.isAdmin ? onUpdate : undefined}
        onRemove={currentUser?.isAdmin ? onRemove : undefined}
        permission={'write'}
        selectedIndex={selectedIndex}
      />
      <Box display="flex" justifyContent="center" marginTop={2}>
        <Button
          color="primary"
          disabled={!items || !contractTemplates || contractTemplates.length === 0}
          onClick={onAdd}
          startIcon={<AddCircleOutline />}
          variant="contained"
        >
          <Trans i18nKey="commons.actions.add" />
        </Button>
      </Box>
    </>
  );
}

export default ManageContractsList;
