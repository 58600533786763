import { ICounterStats } from '@geovelo-frontends/commons';
import moment, { Moment } from 'moment';

import { IPeriodFormValues } from '../components/form/period';

export function getProgression(currentCount: number, prevCount: number): number {
  if (currentCount === 0) {
    if (prevCount === 0) return 0;

    return -100;
  }

  if (prevCount === 0) return 100;

  return Math.round(((currentCount - prevCount) / prevCount) * 100);
}

export function getRelativeProgression(
  currentCount: number,
  prevData: Array<
    | {
        date: Moment;
        nbComputedRoutes: number;
        distance: number;
        duration: number;
        nbRoutes: number;
      }
    | ICounterStats
  >,
  periods: IPeriodFormValues,
  key?: 'nbComputedRoutes' | 'distance' | 'duration' | 'nbRoutes',
): number {
  const lastDay = moment().add(
    -(periods.current.type === 'week'
      ? periods.current.from.year() * 12 +
        periods.current.from.week() -
        (periods.prev.from.year() * 12 + periods.prev.from.week())
      : periods.current.type === 'month'
        ? periods.current.from.year() * 12 +
          periods.current.from.month() -
          (periods.prev.from.year() * 12 + periods.prev.from.month())
        : periods.current.from.year() - periods.prev.from.year()),
    periods.current.type as 'week' | 'month' | 'year',
  );
  const prevCount = prevData.reduce((res, data) => {
    if (data.date.isBefore(lastDay)) res += 'count' in data ? data.count : !!key ? data[key] : 0;
    return res;
  }, 0);

  return getProgression(currentCount, prevCount);
}
