import {
  CyclabilityZone,
  CyclabilityZoneBNSCStats,
  CyclabilityZoneService,
  IPartnerFlowsStats,
  ParkingTypes,
  Period,
  backendAdministrativeLevels,
  childAdministrativeLevels,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../app/context';
import Distribution from '../../../components/distribution';
import { CostIcon, LockIcon } from '../../../components/icons';

import Card from './card';

function Parkings({
  period,
}: {
  flowsStatistics?: { current: IPartnerFlowsStats | null; prev: IPartnerFlowsStats | null } | null;
  period: Period;
}): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const [parkingsStatistics, setParkingsStatistics] = useState<{
    current: CyclabilityZoneBNSCStats;
    prev?: CyclabilityZoneBNSCStats;
  }>();
  const [cyclabilityZone, setCyclabilityZone] = useState<{
    current: CyclabilityZone | null;
    prev: CyclabilityZone | null;
  }>();
  const [cyclabilityZones, setCyclabilityZones] = useState<{
    current: CyclabilityZone[] | null;
    prev: CyclabilityZone[] | null;
  }>();

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized && currentPartner) getParentZones();

    return () => cancelPromises();
  }, [initialized, currentPartner, period]);

  useEffect(() => {
    if (!!cyclabilityZone?.current)
      setParkingsStatistics({
        current: getStats([cyclabilityZone.current]),
        prev: cyclabilityZone.prev ? getStats([cyclabilityZone.prev]) : undefined,
      });
    else if (!!cyclabilityZones?.current)
      setParkingsStatistics({
        current: getStats(cyclabilityZones.current),
        prev: cyclabilityZones.prev ? getStats(cyclabilityZones.prev) : undefined,
      });
    else setParkingsStatistics(undefined);

    return () => {
      setParkingsStatistics(undefined);
    };
  }, [cyclabilityZone?.current, cyclabilityZones?.current]);

  function getStats(cyclabilityZones: CyclabilityZone[]): CyclabilityZoneBNSCStats {
    const slots: { [key in ParkingTypes | 'all']: number } = {
      [ParkingTypes.Arch]: 0,
      [ParkingTypes.Free]: 0,
      [ParkingTypes.Private]: 0,
      [ParkingTypes.Rack]: 0,
      [ParkingTypes.Secure]: 0,
      [ParkingTypes.Sheltered]: 0,
      [ParkingTypes.Locked]: 0,
      all: 0,
    };

    cyclabilityZones.forEach(({ statsBNSC }) => {
      if (statsBNSC[0]) {
        Object.values(ParkingTypes).forEach((typeKey) => {
          slots[typeKey] += statsBNSC[0].slots[typeKey] || 0;
        });
        slots.all += statsBNSC[0].slots.all || 0;
      }
    });

    return new CyclabilityZoneBNSCStats(period.from, {
      ...slots,
    });
  }

  async function getParentZones() {
    if (!currentPartner) return;

    const childAdministrativeLevel = childAdministrativeLevels[currentPartner.administrativeLevel];
    const administrativeLevel = childAdministrativeLevel
      ? childAdministrativeLevel === 'world'
        ? undefined
        : childAdministrativeLevel
      : currentPartner.administrativeLevel === 'world'
        ? undefined
        : currentPartner.administrativeLevel;
    if (!administrativeLevel) return;

    try {
      if (!administrativeLevel) return;
      const [zone, prevZone, { zones }, { zones: prevZones }] = await cancellablePromise(
        Promise.all([
          currentPartner.cyclabilityZoneId
            ? CyclabilityZoneService.getZone(currentPartner.cyclabilityZoneId, {
                period: period,
              })
            : null,
          currentPartner.cyclabilityZoneId
            ? CyclabilityZoneService.getZone(currentPartner.cyclabilityZoneId, {
                period: period.getPrevPeriod(),
              })
            : null,
          CyclabilityZoneService.getZones({
            administrativeLevel: backendAdministrativeLevels[administrativeLevel],
            partnerCode: currentPartner.code,
            considerLivingStreets: true,
            period: period,
            rowsPerPage: 100,
            query: '{ id, code, name, administrative_level, geo_polygon_simplified, stats }',
          }),
          CyclabilityZoneService.getZones({
            administrativeLevel: backendAdministrativeLevels[administrativeLevel],
            partnerCode: currentPartner.code,
            considerLivingStreets: true,
            period: period.getPrevPeriod(),
            rowsPerPage: 100,
            query: '{ id, code, name, administrative_level, stats }',
          }),
        ]),
      );

      setCyclabilityZone({ current: zone, prev: prevZone });
      setCyclabilityZones({ current: zones, prev: prevZones });
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.facilities.cyclability_zones.form.server_error'));
      }
    }
  }

  return (
    <Card
      currentData={parkingsStatistics ? parkingsStatistics.current.slots.all : undefined}
      progression={
        parkingsStatistics?.current
          ? parkingsStatistics.current.slots.all - (parkingsStatistics.prev?.slots.all || 0)
          : undefined
      }
      titleKey="cycling-insights.cartographic_data.parkings.stats.title"
      to={`/${currentPartner?.code}/parkings/cycle-parkings?period=month&from=${period.from.format(
        'YYYY-MM-DD',
      )}&compare=false`}
    >
      {currentPartner?.dashboardTabsPermissions.parkings !== 'none' && (
        <>
          <Box display="flex" sx={{ borderRadius: '10px', overflow: 'hidden' }}>
            {parkingsStatistics ? (
              <>
                {parkingsStatistics.current.slots.all === 0 ? (
                  <Box height={20} sx={{ backgroundColor: '#ddd' }} width="100%" />
                ) : (
                  <>
                    <Tooltip
                      title={`${t('commons.parking_types.racks')}: ${Math.round(
                        (parkingsStatistics?.current.slots.rack /
                          parkingsStatistics?.current.slots.all) *
                          100,
                      )}%`}
                    >
                      <Box
                        height={20}
                        sx={{ backgroundColor: '#905EFA' }}
                        width={`${Math.round(
                          (parkingsStatistics?.current.slots.rack /
                            parkingsStatistics?.current.slots.all) *
                            100,
                        )}%`}
                      />
                    </Tooltip>
                    <Tooltip
                      title={`${t('commons.parking_types.arches')}: ${Math.round(
                        (parkingsStatistics?.current.slots.arch /
                          parkingsStatistics?.current.slots.all) *
                          100,
                      )}%`}
                    >
                      <Box
                        flexGrow={1}
                        flexShrink={0}
                        height={20}
                        sx={{ backgroundColor: '#46CE9D' }}
                        width={`${Math.round(
                          (parkingsStatistics?.current.slots.arch /
                            parkingsStatistics?.current.slots.all) *
                            100,
                        )}%`}
                      />
                    </Tooltip>
                  </>
                )}
              </>
            ) : (
              <Skeleton height={20} variant="rectangular" />
            )}
          </Box>
          <Box display="flex" flexWrap="wrap" gap={2} marginTop={2}>
            <Box
              alignItems="center"
              display="flex"
              gap={1}
              minHeight={40}
              width={{ xs: '100%', lg: 'calc((100% - 16px) / 2)' }}
            >
              <Box
                alignSelf="stretch"
                borderLeft={`3px solid #905EFA`}
                borderRadius={3}
                flexShrink={0}
              />
              <Box flexGrow={1}>
                <Typography color="textSecondary" variant="caption">
                  <Trans i18nKey="commons.parking_types.racks" />
                </Typography>
              </Box>
              <Box flexShrink={0}>
                <Typography noWrap fontSize="1.125em">
                  {parkingsStatistics ? (
                    `${
                      parkingsStatistics.current?.slots.all
                        ? Math.round(
                            (parkingsStatistics.current.slots.rack /
                              parkingsStatistics.current.slots.all) *
                              100,
                          )
                        : 0
                    } %`
                  ) : (
                    <Skeleton variant="text" width={40} />
                  )}
                </Typography>
              </Box>
            </Box>
            <Box
              alignItems="center"
              display="flex"
              gap={1}
              minHeight={40}
              width={{ xs: '100%', lg: 'calc((100% - 16px) / 2)' }}
            >
              <Box
                alignSelf="stretch"
                borderLeft={`3px solid #46CE9D`}
                borderRadius={3}
                flexShrink={0}
              />
              <Box flexGrow={1}>
                <Typography color="textSecondary" variant="caption">
                  <Trans i18nKey="commons.parking_types.arches" />
                </Typography>
              </Box>
              <Box flexShrink={0}>
                <Typography noWrap fontSize="1.125em">
                  {parkingsStatistics ? (
                    `${
                      parkingsStatistics.current?.slots.all
                        ? Math.round(
                            (parkingsStatistics.current.slots.arch /
                              parkingsStatistics.current.slots.all) *
                              100,
                          )
                        : 0
                    } %`
                  ) : (
                    <Skeleton variant="text" width={40} />
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexWrap="wrap" justifyContent="space-evenly" marginTop={2}>
            <Distribution
              chartId="safety-chart"
              data={
                parkingsStatistics
                  ? [
                      parkingsStatistics.current.slots.secure,
                      parkingsStatistics.current.slots.sheltered,
                    ]
                  : undefined
              }
              Icon={LockIcon}
              label={
                <Trans i18nKey="cycling-insights.cartographic_data.parkings.distributions.safety" />
              }
            />
            <Distribution
              chartId="cost-chart"
              data={
                parkingsStatistics
                  ? [
                      parkingsStatistics.current.slots.free,
                      parkingsStatistics.current.slots.private,
                    ]
                  : undefined
              }
              Icon={CostIcon}
              label={
                <Trans i18nKey="cycling-insights.cartographic_data.parkings.distributions.cost" />
              }
            />
          </Box>
        </>
      )}
    </Card>
  );
}

export default Parkings;
