import {
  File as DownloadFile,
  FileService,
  TFileStatus,
  TFileType,
  statuses,
  useFileSaver,
} from '@geovelo-frontends/commons';
import { ArrowBackIosNew } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Table, { TRow } from '../../../components/table';
import usePaginatedTable from '../../../hooks/table/paginated';
import useSortableTable from '../../../hooks/table/sortable';

import { types } from './types';

type TKey = 'date' | 'title' | 'type' | 'downloadsCount' | 'status';

const keys: TKey[] = ['date', 'title', 'type', 'downloadsCount', 'status'];

interface IProps {
  files?: DownloadFile[];
  onDowloaded: (id: number) => void;
}

function DownloadsTable({ files, onDowloaded }: IProps): JSX.Element {
  const [rows, setRows] = useState<TRow<number, TKey>[] | undefined>();
  const [downloading, setDownloading] = useState<{ [key: number]: boolean }>({});
  const { page, rowsPerPage, setPage, paginate, onPageChange, onRowsPerPageChange } =
    usePaginatedTable<number, TKey>();
  const { orderBy, order, sort, onSortRequest } = useSortableTable<number, TKey>('date', 'desc', {
    setPage,
  });
  const { downloadBlob } = useFileSaver();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (files) {
      const _rows = [...files].map<TRow<number, TKey>>((file) => {
        const { id: key, created, title, type, downloadsCount, status } = file;

        return {
          key,
          cells: {
            date: { value: created, format: (date: Moment) => date.format('L') },
            title: { value: title || '' },
            type: {
              value: type,
              format: (_type: TFileType) => <Trans i18nKey={types[_type].titleKey} />,
            },
            downloadsCount: { value: downloadsCount },
            status: {
              value: status,
              format: (_status: TFileStatus) => {
                const { titleKey, color } = statuses[_status];
                return (
                  <span style={{ color }}>
                    <Trans i18nKey={titleKey} />
                  </span>
                );
              },
            },
          },
        };
      });

      paginate(sort(_rows, 'title'));

      setRows(paginate(sort(_rows, 'title')));
    } else {
      setRows(undefined);
    }
  }, [files, orderBy, order, page, rowsPerPage]);

  useEffect(() => {
    if (files) {
      const _downloading = { ...downloading };
      files.forEach(({ id, status }) => {
        if (status !== 'prepared' && status !== 'downloaded') _downloading[id] = true;
      });
      setDownloading(_downloading);
    }
  }, [files]);

  async function handleDownload(id: number) {
    const file = files?.find((file) => file.id === id);
    if (!file) return;

    setDownloading({ ...downloading, [id]: true });

    try {
      const blob = await FileService.getFile(id);
      const { type, title } = file;
      const format = type === 'virtualCounters' ? 'csv' : 'zip';

      downloadBlob(`${title}.${format}`, blob);
      onDowloaded(id);

      setTimeout(() => setDownloading({ ...downloading, [id]: false }), 200);
    } catch {
      enqueueSnackbar(t('cycling-insights.downloads.download_error'), { variant: 'error' });
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      gap={2}
      padding={3}
      sx={{ overflowY: 'auto' }}
    >
      <Typography
        color="primary"
        component="h2"
        flexDirection="column"
        fontWeight="700"
        lineHeight="2rem"
        variant="h5"
      >
        <IconButton component={Link} sx={{ marginRight: '8px' }} to="../facilities">
          <ArrowBackIosNew color="primary" />
        </IconButton>
        <Trans i18nKey="commons.navigation.downloads" />
      </Typography>
      <Table
        hasBorder
        count={files?.length}
        downloading={downloading}
        headers={{
          date: {
            label: <Trans i18nKey="cycling-insights.downloads.table.date" />,
            sortable: true,
          },
          title: {
            label: <Trans i18nKey="commons.title" />,
            sortable: true,
          },
          type: {
            label: <Trans i18nKey="cycling-insights.downloads.table.file_type" />,
            sortable: true,
          },
          downloadsCount: {
            label: <Trans i18nKey="cycling-insights.downloads.table.downloads_count" />,
            sortable: true,
          },
          status: {
            label: <Trans i18nKey="commons.status" />,
            sortable: true,
          },
        }}
        keys={keys}
        onDownload={handleDownload}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        onSortRequest={onSortRequest}
        order={order}
        orderBy={orderBy}
        page={page}
        rows={rows}
        rowsPerPage={rowsPerPage}
        title="Download files table"
      />
    </Box>
  );
}

export default DownloadsTable;
