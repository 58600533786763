import { Period, last12Months, last30Days, last7Days, today } from '@geovelo-frontends/commons';
import { DialogProps, useTheme } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DateRangePicker, Range } from 'react-date-range';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as locales from 'react-date-range/dist/locale';
import { useTranslation } from 'react-i18next';

import Dialog from '../dialog';

function DatePickerDialog({
  open,
  period,
  minDate,
  maxDate,
  onClose,
  ...props
}: Omit<DialogProps, 'onClose'> & {
  minDate?: Date;
  maxDate?: Date;
  onClose: (period?: Period) => void;
  period?: Period;
}): JSX.Element {
  const [dates, setDates] = useState<Range>({
    startDate: undefined,
    endDate: undefined,
  });
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    if (open) {
      if (period?.type === 'custom') {
        setDates({
          startDate: period.from.toDate(),
          endDate: period.to.toDate(),
        });
      } else {
        setDates({
          startDate: moment().startOf('month').toDate(),
          endDate: moment().endOf('month').toDate(),
        });
      }
    }
  }, [open]);

  function handleConfirm() {
    const { startDate, endDate } = dates;
    if (startDate && endDate) {
      onClose(new Period('custom', moment(startDate).startOf('day'), moment(endDate).endOf('day')));
    }
  }

  return (
    <Dialog
      {...props}
      disableContentPadding
      disableFullWidth
      dialogTitle="date-picker-dialog"
      maxWidth="lg"
      onCancel={onClose}
      onConfirm={handleConfirm}
      open={open}
      scroll="paper"
      sx={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}
    >
      <DateRangePicker
        inputRanges={[]}
        locale={locales[language] || locales['enUS']}
        maxDate={maxDate}
        minDate={minDate}
        moveRangeOnFirstSelection={false}
        onChange={({ range1: period }) => setDates(period)}
        rangeColors={[theme.palette.primary.main]}
        ranges={[dates]}
        staticRanges={[today, last7Days, last30Days, last12Months].map(
          ({ titleKey, from, to }) => ({
            label: t(titleKey || '') || '',
            range: () => ({ startDate: from.clone().toDate(), endDate: to.clone().toDate() }),
            isSelected() {
              return true;
            },
          }),
        )}
      />
    </Dialog>
  );
}

export default DatePickerDialog;
