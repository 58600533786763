import { Counter, useLayers, useSource } from '@geovelo-frontends/commons';
import { Theme } from '@mui/material';
import { useRef, useState } from 'react';

import counterIcon from '../../assets/images/counter.svg';

import { Map, Marker } from '!maplibre-gl';

const sourceId = 'counters';
const layerId = 'counters';

interface IActions {
  onClick?: (key?: string) => void;
  onDragEnd?: (id: number, point: GeoJSON.Point) => void;
}

function useCounters(
  map: Map | undefined | null,
  counters: Counter[] | undefined,
  { palette }: Theme,
  { onClick, onDragEnd }: IActions = {},
): {
  initialized: boolean;
  init: () => void;
  update: (selectedKey: string | null, options?: { draggable?: boolean }) => void;
  updateMinimizeCounters: (counters: Counter[], selectedId?: number) => void;
  clear: () => void;
  destroy: () => void;
  selectCounter: (counter?: Counter) => void;
} {
  const [initialized, setInitialized] = useState(false);
  const markers = useRef<{ [key: number]: { id: number; marker: Marker } }>({});
  const initializedRef = useRef(false);
  const draggedFeatureIndex = useRef<number | null>(null);
  const selectedMarker = useRef<{ marker: Marker; isVirtual: boolean }>();
  const { addCircleLayer } = useLayers(map);
  const { addGeoJSONSource, getGeoJSONSource, updateGeoJSONSource, clearGeoJSONSource } = useSource(
    map,
    sourceId,
  );

  function init() {
    if (!map || initializedRef.current) return;
    if (getGeoJSONSource()) {
      initializedRef.current = true;
      setInitialized(true);
      return;
    }

    addGeoJSONSource();

    addCircleLayer(layerId, sourceId, {
      'circle-radius': 6,
      'circle-color': '#326ac2',
    });

    map.on('click', () => {
      if (selectedMarker) onClick?.();
    });

    initializedRef.current = true;
    setInitialized(true);
  }

  function addMarker(counter: Counter, selected: boolean, draggable?: boolean) {
    const {
      id,
      isVirtual,
      place: {
        point: { coordinates },
      },
    } = counter;
    if (!map || markers.current[id]) return;

    const el = document.createElement('div');
    el.className = 'marker';
    el.style.borderRadius = '50%';
    el.style.backgroundColor = isVirtual
      ? selected
        ? palette.primary.light
        : '#9BC2FF'
      : selected
        ? palette.secondary.main
        : '#D3D545';
    el.style.backgroundImage = `url(${counterIcon})`;
    el.style.backgroundPosition = 'center';
    el.style.backgroundRepeat = 'no-repeat';
    el.style.width = `22px`;
    el.style.height = `22px`;
    el.style.cursor = 'pointer';

    el.addEventListener('click', (event) => {
      event.stopPropagation();

      onClick?.(`${isVirtual ? 'virtual' : 'real'}_${id}`);
    });

    const marker = new Marker({ element: el, draggable })
      .setLngLat({ lat: coordinates[1], lng: coordinates[0] })
      .addTo(map);
    if (draggable) {
      marker.on('dragend', ({ target }: { target: Marker }) => {
        if (onDragEnd) {
          const { lat, lng } = target.getLngLat();
          onDragEnd(counter.id, {
            type: 'Point',
            coordinates: [lng, lat],
          });
        }
      });
    }

    markers.current[id] = { id, marker };
    if (selected)
      selectedMarker.current = {
        marker,
        isVirtual: isVirtual,
      };
  }

  function update(selectedKey: string | null, { draggable }: { draggable?: boolean } = {}) {
    clearMarkers();
    if (selectedKey) {
      const [type, _id] = selectedKey.split('_');
      const isVirtual = type === 'virtual';
      const id = parseInt(_id);

      counters?.map((counter) =>
        addMarker(counter, counter.id === id && counter.isVirtual === isVirtual, draggable),
      );
    } else {
      counters?.map((counter) => addMarker(counter, false, draggable));
    }
  }

  function updateMinimizeCounters(counters: Counter[], selectedId?: number) {
    updateGeoJSONSource({
      type: 'FeatureCollection',
      features: counters
        .filter(({ id }) => id !== selectedId)
        .map(({ place }) => {
          return {
            type: 'Feature',
            geometry: { type: 'Point', coordinates: place.point.coordinates },
            properties: {},
          };
        }),
    });
  }

  function selectCounter(counter?: Counter) {
    if (!markers.current) return;
    if (selectedMarker.current)
      selectedMarker.current.marker.getElement().style.backgroundColor = selectedMarker.current
        ?.isVirtual
        ? '#9BC2FF'
        : '#D3D545';
    if (counter && markers.current[counter.id]) {
      selectedMarker.current = {
        marker: markers.current[counter.id].marker,
        isVirtual: counter.isVirtual,
      };
      selectedMarker.current.marker.getElement().style.backgroundColor = counter.isVirtual
        ? palette.primary.light
        : palette.secondary.main;
      map?.flyTo({ center: selectedMarker.current.marker.getLngLat() });
    } else {
      selectedMarker.current = undefined;
    }
  }

  function clearMarkers() {
    Object.values(markers.current).forEach(({ id, marker }) => {
      marker.remove();
      delete markers.current[id];
    });
  }

  function clear() {
    draggedFeatureIndex.current = null;
    clearMarkers();
    clearGeoJSONSource();
  }

  function destroy() {
    clear();

    initializedRef.current = false;
    setInitialized(false);
  }

  return { initialized, init, update, updateMinimizeCounters, clear, destroy, selectCounter };
}

export default useCounters;
