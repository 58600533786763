import { Ride } from '@geovelo-frontends/commons';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import useAlertBeforeUnload from '../../../../hooks/alert-before-unload';
import { TTab } from '../../tab';

import ImageList from './image-list';
import MainForm from './main-form';
import MediaList from './media-list';
import PartnerList from './partner-list';
import ThemeForm from './theme-form';

interface IProps {
  canWrite: boolean;
  onChange: (ride: Ride) => void;
  onTabChange: (tab: TTab) => void;
  ride: Ride;
}

function DataTab({ onTabChange, ...props }: IProps): JSX.Element {
  const [, setDirty] = useState(false);
  useAlertBeforeUnload();

  useEffect(() => {
    onTabChange('data');
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <StyledGridItem item md={6} xs={12}>
          <MainForm setDirty={setDirty} {...props} />
          <PartnerList {...props} />
        </StyledGridItem>
        <StyledGridItem item md={6} xs={12}>
          <ThemeForm {...props} />
          {/* <IconForm {...props} /> */}
          <ImageList {...props} />
          <MediaList {...props} />
        </StyledGridItem>
      </Grid>
      {/* FIXME: uncomment those lines when react-router-dom will be upgraded
      <Prompt
        message={t('cycling-insights.ride.lose_changes_alert')}
        when={dirty}
      /> */}
    </>
  );
}

const StyledGridItem = styled(Grid)`
  > *:not(:first-child) {
    margin-top: 16px;
  }
`;

export default DataTab;
