import { useAmplitudeTracker } from '@geovelo-frontends/commons';
import { Add, FileDownloadOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import PageContentLayout from '../../../../layouts/page/content';
import { TCartographicDataPageContext } from '../../context';

import BNACDialog from './bnac-dialog';
import DownloadDialog from './download-dialog';
import FacilitiesForm from './form';
import SendFileDialog from './send-file-dialog';

function FacilitiesTab(): JSX.Element {
  const [initialized, initialize] = useState(false);
  const [downloadDialogOpen, openDownloadDialog] = useState(false);
  const [sendFileDialogOpen, openSendFileDialog] = useState(false);
  const [BNACDialogOpen, openBNACDialog] = useState(false);
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const context = useOutletContext<TCartographicDataPageContext>();
  const {
    header: { setActions, setTitle, setPrevButtonClick },
    period: {
      values: { current: currentPeriod },
    },
    facilities: { cyclabilityZones, selectZone, selectedZone, stats },
  } = context;
  const { trackEvent } = useAmplitudeTracker();

  useEffect(() => {
    if (initialized) return;
    initialize(true);

    return () => {
      setPrevButtonClick(undefined);
      setTitle(undefined);
      setActions(undefined);
    };
  }, []);

  useEffect(() => {
    setActions(
      <>
        <Tooltip title={<Trans i18nKey="cycling-insights.facilities.actions.gis_files" />}>
          <IconButton
            color="primary"
            onClick={() => {
              trackEvent('Button Clicked', {
                pathname: `${window.location.host}${window.location.pathname}`,
                partner_id: currentPartner?.id,
                partner_code: currentPartner?.code,
                cta: 'Facilities File Upload Button',
              });
              openSendFileDialog(true);
            }}
            size="small"
          >
            <Add />
          </IconButton>
        </Tooltip>
        <Tooltip title={<Trans i18nKey="cycling-insights.facilities.actions.download" />}>
          <span>
            <IconButton
              color="primary"
              disabled={!stats}
              onClick={() => {
                trackEvent('Button Clicked', {
                  pathname: `${window.location.host}${window.location.pathname}`,
                  partner_id: currentPartner?.id,
                  partner_code: currentPartner?.code,
                  cta: 'Facilities File Download Button',
                });
                openDownloadDialog(true);
              }}
              size="small"
            >
              <FileDownloadOutlined />
            </IconButton>
          </span>
        </Tooltip>
      </>,
    );
  }, [stats]);

  useEffect(() => {
    if (selectedZone) {
      setPrevButtonClick(() => () => selectZone(null));
      setTitle(selectedZone.name);
    } else {
      setPrevButtonClick(undefined);
      setTitle(undefined);
    }
  }, [selectedZone]);

  useEffect(() => {
    if (!initialized) return;
    let value: string | null = null;
    try {
      value = localStorage.getItem('has_seen_bnac_dialog');
    } catch {
      console.error('localStorage access is denied');
    }

    openBNACDialog(value !== 'read');

    try {
      localStorage.setItem('has_seen_bnac_dialog', 'read');
    } catch {
      console.error('localStorage access is denied');
    }
  }, [initialized]);

  return (
    <>
      <PageContentLayout
        leftPanel={
          <FacilitiesForm
            openDownloadDialog={openDownloadDialog}
            openSendFileDialog={openSendFileDialog}
            {...context}
          />
        }
      />
      <BNACDialog onClose={() => openBNACDialog(false)} open={BNACDialogOpen} />
      <SendFileDialog
        onAdd={() => openSendFileDialog(false)}
        onCancel={() => openSendFileDialog(false)}
        open={sendFileDialogOpen}
      />
      <DownloadDialog
        cyclabilityZones={cyclabilityZones}
        onClose={() => openDownloadDialog(false)}
        open={downloadDialogOpen}
        period={currentPeriod}
        selectedZone={selectedZone}
      />
    </>
  );
}

export default FacilitiesTab;
