import {
  ParkingService,
  Period,
  ReportService,
  TPeriod,
  prevMonth,
  useCancellablePromise,
  useCyclabilityZones,
  useUnits,
} from '@geovelo-frontends/commons';
import { KeyboardArrowRight, Place } from '@mui/icons-material';
import { Box, Card, CardContent, Skeleton, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import illuParking from '../../../../assets/images/illu-parking.svg';
import Button from '../../../../components/button';
import Distribution from '../../../../components/distribution';
import PeriodForm, { IPeriodFormValues } from '../../../../components/form/period';
import { ArchesIcon, CostIcon, LockIcon } from '../../../../components/icons';
import LinkCard from '../../../../components/link-card';
import Paper from '../../../../components/paper';
import Progression from '../../../../components/progression';
import Ranking from '../../../../components/ranking';
import TabIntroduction from '../../../../components/tab-introduction';
import useFUBAndAccidents from '../../../../hooks/map/fub-and-accidents';
import { TParkingsPageContext } from '../../context';
import useParkings from '../../hooks/parkings';

import { LngLatBounds } from '!maplibre-gl';

const minPeriod = new Period(
  'month',
  moment('2023-03').startOf('month'),
  moment('2023-03').endOf('month'),
);

const maxPeriod = moment().get('date') <= 7 ? prevMonth : undefined;

function ParkingsForm(context: TParkingsPageContext): JSX.Element {
  const {
    defaultPeriods,
    period,
    parkings: { cyclabilityZones, stats, prevStats, zonesMap, selectZone, selectedZone },
  } = context;
  const [currentYearReportsCount, setCurrentYearReportsCount] = useState<number>();
  const [safetyChartData, setSafetyChartData] = useState<number[]>();
  const [furnitureChartData, setFurnitureChartData] = useState<number[]>();
  const [costChartData, setCostChartData] = useState<number[]>();
  const [initialized, setInitialized] = useState(false);
  const [customPeriodTypes] = useState<{
    defaultPeriods: IPeriodFormValues;
    enabledTypes: TPeriod[];
  }>({ defaultPeriods, enabledTypes: ['month'] });
  const {
    map: { current: currentMap, baseLayer, parkingsShowed, parkingRequestsShowed },
    partner: { current: currentPartner, currentArea },
    actions: { enableParkingsToggle },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { cancellablePromise: cancellableReportPromise, cancelPromises: cancelReportPromise } =
    useCancellablePromise();
  const { progressions } = useParkings({ context });
  const {
    init: initLayers,
    initialized: layersInitialized,
    update: updateLayers,
    displayCurrentArea,
    destroy: destroyLayers,
  } = useCyclabilityZones(
    currentMap,
    zonesMap || {},
    {
      onClick: selectZone,
      getTooltipContent: (properties) => {
        return `<h3>${properties?.name}</h3><span>${t(
          'cycling-insights.cartographic_data.parkings.space',
          { count: properties?.capacite },
        )}</span>`;
      },
    },
    { isParkings: true, darkTheme: baseLayer === 'dark' },
  );
  const {
    initialized: FUBAndAccidentesInitialized,
    init: initFUBAndAccidents,
    toggle: toggleFUBAndAccidents,
    destroy: destroyFubAndAccidents,
  } = useFUBAndAccidents(currentMap);
  const { formatNumber } = useUnits();

  useEffect(() => {
    setInitialized(true);

    return () => {
      cancelPromises();
      cancelReportPromise();
    };
  }, []);

  useEffect(() => {
    if (initialized) getReports();
  }, [initialized]);

  useEffect(() => {
    if (currentMap) {
      initLayers();
      initFUBAndAccidents();
    }

    return () => {
      destroyLayers();
      destroyFubAndAccidents();
    };
  }, [currentMap]);

  useEffect(() => {
    if (
      currentPartner &&
      currentArea &&
      ['region', 'department'].includes(currentPartner.administrativeLevel)
    )
      displayCurrentArea(currentArea);
  }, [currentMap, currentArea]);

  useEffect(() => {
    if (FUBAndAccidentesInitialized)
      toggleFUBAndAccidents(parkingRequestsShowed, 'demandes_stationnements_fub');
  }, [FUBAndAccidentesInitialized, parkingRequestsShowed]);

  useEffect(() => {
    if (!currentMap) return;

    let bounds;
    if (selectedZone) bounds = selectedZone.bounds;
    else if (currentPartner) bounds = currentPartner.bounds;

    if (bounds) {
      const { north, east, south, west } = bounds;
      currentMap.fitBounds(new LngLatBounds({ lat: south, lng: west }, { lat: north, lng: east }));
    }
  }, [currentMap, selectedZone]);

  useEffect(() => {
    enableParkingsToggle((cyclabilityZones && cyclabilityZones.length === 1) || false);
  }, [cyclabilityZones]);

  useEffect(() => {
    if (layersInitialized && cyclabilityZones) {
      if (cyclabilityZones.length > 1)
        updateLayers(
          null,
          selectedZone?.administrativeLevel === 'city' ? [selectedZone] : cyclabilityZones,
          period.values.current.to.format('YYYY-MM'),
        );
      else getParkings();
    }

    return () => cancelPromises();
  }, [layersInitialized, cyclabilityZones, period.values, parkingsShowed]);

  useEffect(() => {
    if (stats) {
      setSafetyChartData([stats.slots.secure, stats.slots.sheltered]);
      setFurnitureChartData([stats.slots.rack, stats.slots.arch]);
      setCostChartData([stats.slots.free, stats.slots.private]);
    } else {
      setSafetyChartData(undefined);
      setFurnitureChartData(undefined);
      setCostChartData(undefined);
    }
  }, [stats]);

  async function getParkings() {
    updateLayers(
      selectedZone ? selectedZone : cyclabilityZones?.[0] || null,
      null,
      period.values.current.to.format('YYYY-MM'),
      [],
    );

    if (!selectedZone && (!cyclabilityZones || cyclabilityZones.length > 1)) return;
    try {
      const parkingLots = await cancellablePromise(
        ParkingService.getParkingLots({
          cyclabilityZoneId: selectedZone ? selectedZone.id : cyclabilityZones?.[0].id,
          zoom: 16,
        }),
      );

      updateLayers(
        selectedZone ? selectedZone : cyclabilityZones?.[0] || null,
        null,
        period.values.current.to.format('YYYY-MM'),
        parkingLots.filter(({ type, free, rackType }) => {
          if (!parkingsShowed.free && free) return false;
          if (!parkingsShowed.private && !free) return false;
          if (!parkingsShowed.arch && rackType === 'stands') return false;
          if (!parkingsShowed.rack && rackType === 'wallLoops') return false;
          if (!parkingsShowed.secure && type === 'supervised') return false;
          if (!parkingsShowed.sheltered && type === 'sheltered') return false;
          if (!parkingsShowed.locked && type === 'locked') return false;

          return true;
        }),
      );
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') console.error(err);
    }
  }

  async function getReports() {
    setCurrentYearReportsCount(undefined);

    try {
      const { count } = await cancellableReportPromise(
        ReportService.getReports({
          period: {
            period: 'custom',
            from: moment().startOf('year'),
            to: moment(),
            unit: 'day',
          },
          typeCodes: ['bikeParking'],
          status: ['CLOSED', 'ARCHIVED', 'CLOSED_BY_OSM'],
          page: 1,
          rowsPerPage: 1,
          query: '{id}',
        }),
      );

      setCurrentYearReportsCount(count);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.reports.reports.server_error'), {
          variant: 'error',
        });
      }
    }
  }

  return (
    <Box display="flex" flexDirection="column" gap={3} minHeight="100%">
      <TabIntroduction title="cycling-insights.cartographic_data.introduction.parkings" />
      <Paper
        header={
          <PeriodForm
            disablePadding
            disablePeriodTypeChange
            customPeriodTypes={customPeriodTypes}
            maxPeriod={maxPeriod}
            minPeriod={minPeriod}
            {...period}
          />
        }
      >
        <Card sx={{ alignSelf: 'flex-start', borderRadius: 4 }} variant="outlined">
          <CardContent sx={{ '&&': { paddingBottom: 2 } }}>
            <Typography color="textSecondary" variant="body2">
              <Trans i18nKey="cycling-insights.cartographic_data.parkings.stats.title" />
            </Typography>
            <Typography variant="h5">
              {stats ? formatNumber(stats.slots.all) : <Skeleton variant="text" width={100} />}
            </Typography>
            {!period.values.current
              .getPrevPeriod()
              .from.isBefore(moment('2023-03').startOf('month')) && (
              <Progression
                disableHighlight
                formatDiff={(value) =>
                  t('commons.parking.racks', { count: value, capacity: formatNumber(value) })
                }
                label={
                  period.comparisonEnabled
                    ? period.values.prev.title
                    : t('commons.periods.prev_month').toLowerCase()
                }
                prevStat={prevStats?.global.slots.all}
                stat={stats?.slots.all}
              />
            )}
          </CardContent>
        </Card>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography fontSize="1.125rem" fontWeight={700}>
            <Trans i18nKey="cycling-insights.cartographic_data.parkings.distribution" />
          </Typography>
          <Box display="flex" gap={2}>
            <Distribution
              chartId="furniture-chart"
              data={furnitureChartData}
              descriptions={[
                <Trans i18nKey="commons.parking_types.racks_label" key={0} />,
                <Trans i18nKey="commons.parking_types.arches_label" key={0} />,
              ]}
              Icon={ArchesIcon}
              label={
                <Trans i18nKey="cycling-insights.cartographic_data.parkings.distributions.furniture" />
              }
              labels={[t('commons.parking_types.racks'), t('commons.parking_types.arches')]}
            />
            <Distribution
              chartId="safety-chart"
              data={safetyChartData}
              descriptions={[<Trans i18nKey="commons.parking_types.secure_label" key={0} />]}
              Icon={LockIcon}
              label={
                <Trans i18nKey="cycling-insights.cartographic_data.parkings.distributions.safety" />
              }
              labels={[t('commons.parking_types.secure'), t('commons.parking_types.sheltered')]}
            />
            <Distribution
              chartId="cost-chart"
              data={costChartData}
              Icon={CostIcon}
              label={
                <Trans i18nKey="cycling-insights.cartographic_data.parkings.distributions.cost" />
              }
              labels={[t('commons.parking_types.free'), t('commons.parking_types.private')]}
            />
          </Box>
        </Box>
      </Paper>
      {!selectedZone && (
        <>
          <Ranking
            enableSubtitles
            action={
              <Button
                component={Link}
                endIcon={<KeyboardArrowRight />}
                to="../cycle-parkings-stats"
                variant="text"
              >
                <Trans i18nKey="cycling-insights.facilities.progress.all_zones" />
              </Button>
            }
            data={progressions}
            formatProgress={(value) =>
              `+${t('commons.parking.racks_short', {
                count: value,
                capacity: formatNumber(value),
              })}`
            }
            onClick={(id) => selectZone(zonesMap[id])}
            startIcon={<Place sx={{ color: '#E76685' }} />}
            subtitle={
              !period.comparisonEnabled ? (
                <Typography marginBottom={2} marginTop={1} variant="body2">
                  <Trans i18nKey="cycling-insights.cartographic_data.parkings.progress.subtitle" />
                </Typography>
              ) : undefined
            }
            title="cycling-insights.facilities.progress.title"
          />
          <LinkCard
            description={
              <Trans
                i18nKey="cycling-insights.cartographic_data.parkings.contributions.description"
                values={{ year: moment().year() }}
              />
            }
            icon={illuParking}
            title={
              currentYearReportsCount !== undefined ? (
                <Trans
                  count={currentYearReportsCount}
                  i18nKey="cycling-insights.cartographic_data.parkings.contributions.title"
                  values={{ count: currentYearReportsCount }}
                />
              ) : (
                <Skeleton variant="text" width={200} />
              )
            }
            to="../cycle-parkings-reports"
          />
        </>
      )}
    </Box>
  );
}

export default ParkingsForm;
