import { DropZone, Place, useLayers, useSource } from '@geovelo-frontends/commons';
import { useTheme } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';

import { CustomControl } from '../../components/map/custom-control';

import { Map, MapGeoJSONFeature, MapMouseEvent } from '!maplibre-gl';

const polygonSourceId = 'polygon-zone';
export const polygonLayerId = 'polygon-zone';
const lineSourceId = 'line-zone';
const lineLayerId = 'line-zone';
const secondaryPolygonSourceId = 'secondary-polygon-zone';
const secondaryPolygonLayerId = 'secondary-polygon-zone';
const secondaryLineSourceId = 'secondary-line-zone';
const secondaryLineLayerId = 'secondary-line-zone';

function useDraw(
  map: Map | undefined | null,
  { drawType }: { drawType?: 'line' | 'polygon' } = {},
): {
  initialized: boolean;
  init: () => void;
  enableDrawing: (display: boolean) => void;
  clear: () => void;
  destroy: () => void;
  drawZones: (polygons: GeoJSON.FeatureCollection, lines: GeoJSON.FeatureCollection) => void;
  drawings?: GeoJSON.FeatureCollection<
    GeoJSON.LineString | GeoJSON.MultiLineString,
    GeoJSON.GeoJsonProperties
  >;
  handleDelete: (
    event?: MapMouseEvent & {
      features?: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[] | undefined;
    },
    indexFromList?: number,
  ) => void;
  setDrawings: (
    drawings: GeoJSON.FeatureCollection<
      GeoJSON.LineString | GeoJSON.MultiLineString,
      GeoJSON.GeoJsonProperties
    >,
  ) => void;
  secondaryDrawings?: GeoJSON.FeatureCollection<
    GeoJSON.LineString | GeoJSON.MultiLineString,
    GeoJSON.GeoJsonProperties
  >;
  setBaseZone: (points?: Place[][], type?: 'line' | 'polygon', dropZone?: DropZone[]) => void;
  swapDrawingZones: ({
    drawTypeForced,
    allowMultiPolygon,
  }: {
    drawTypeForced?: 'line' | 'polygon';
    allowMultiPolygon?: boolean;
  }) => void;
} {
  const [initialized, setInitialized] = useState(false);
  const initializedRef = useRef(false);
  const drawControlRef = useRef<CustomControl>();
  const theme = useTheme();
  const [mode, setMode] = useState<'edit' | 'delete' | 'none'>('none');
  const modeRef = useRef<'edit' | 'delete' | 'none'>('none');
  const drawTypeRef = useRef<'line' | 'polygon'>('polygon');
  const forceDrawTypeRef = useRef<'line' | 'polygon'>();
  const allowMultiPolygonRef = useRef<boolean>(false);
  const [polygonPoints, setPolygonPoints] = useState<Place[][]>([[]]);
  const [linePoints, setLinePoints] = useState<Place[][]>([[]]);
  const [secondaryPolygonPoints, setSecondaryPolygonPoints] = useState<Place[][]>([[]]);
  const [secondaryLinePoints, setSecondaryLinePoints] = useState<Place[][]>([[]]);
  const polygonPointsRef = useRef<Place[][]>([[]]);
  const linePointsRef = useRef<Place[][]>([[]]);
  const draggedWayPointIndex = useRef<number | null>(null);
  const draggedWayPointLineIndex = useRef<number | null>(null);
  const draggedWayPointPolygonIndex = useRef<number | null>(null);
  const dropZonesRef = useRef<DropZone[]>([]);
  const [dragging, setDragging] = useState<boolean>(false);
  const figureClosed = useRef<boolean>(false);
  const {
    addGeoJSONSource: addPolygonSource,
    updateGeoJSONSource: updatePolygonSource,
    clearGeoJSONSource: clearPolygonSource,
  } = useSource(map, polygonSourceId);
  const {
    addGeoJSONSource: addLineSource,
    updateGeoJSONSource: updateLineSource,
    clearGeoJSONSource: clearLineSource,
  } = useSource(map, lineSourceId);
  const {
    addGeoJSONSource: addSecondaryPolygonSource,
    updateGeoJSONSource: updateSecondaryPolygonSource,
    clearGeoJSONSource: clearSecondaryPolygonSource,
  } = useSource(map, secondaryPolygonSourceId);
  const {
    addGeoJSONSource: addSecondaryLineSource,
    updateGeoJSONSource: updateSecondaryLineSource,
    clearGeoJSONSource: clearSecondaryLineSource,
  } = useSource(map, secondaryLineSourceId);
  const {
    addGeoJSONSource: addWayPointsSource,
    updateGeoJSONSource: updateWayPointsSource,
    clearGeoJSONSource: clearWayPointsSource,
  } = useSource(map, 'points');
  const { addCircleLayer } = useLayers(map);
  const [drawings, setDrawings] =
    useState<
      GeoJSON.FeatureCollection<
        GeoJSON.LineString | GeoJSON.MultiLineString,
        GeoJSON.GeoJsonProperties
      >
    >();
  const [secondaryDrawings, setSecondaryDrawings] =
    useState<
      GeoJSON.FeatureCollection<
        GeoJSON.LineString | GeoJSON.MultiLineString,
        GeoJSON.GeoJsonProperties
      >
    >();

  useEffect(() => {
    if (!map) return;

    if (mode === 'edit') {
      const features: GeoJSON.Feature[] = [];
      if (
        forceDrawTypeRef.current === 'polygon' ||
        (!forceDrawTypeRef.current && drawTypeRef.current === 'polygon')
      )
        polygonPoints.forEach((polygon, polygonIndex) => {
          polygon.forEach((point, index) => {
            if (point) {
              features.push({
                type: 'Feature',
                geometry: point.point,
                properties: {
                  index,
                  polygonIndex,
                  color: '#326ac2',
                  width: 4,
                  strokeColor: '#fff',
                  strokeWidth: 1,
                  wayPoint: true,
                  movable: true,
                },
              });
            }
          });
        });
      else
        linePoints.forEach((line, lineIndex) => {
          if (line) {
            line.map((point, index) => {
              if (point) {
                features.push({
                  type: 'Feature',
                  geometry: point.point,
                  properties: {
                    index,
                    lineIndex,
                    color: '#326ac2',
                    width: 4,
                    strokeColor: '#fff',
                    strokeWidth: 1,
                    wayPoint: true,
                    movable: true,
                  },
                });
              }
            });
          }
        });
      updateWayPointsSource({ type: 'FeatureCollection', features });

      map.getCanvas().style.cursor = 'pointer';
      if (allowMultiPolygonRef.current || polygonPoints[0].length === 0) {
        map.on('click', 'points', handleClickOnPoint);
        map.on('click', handleClick);
      }
      map.on('mousedown', 'points', handleWayPointMouseDown);
      window.addEventListener('keydown', handleKeyDown);
    } else {
      if (mode === 'delete') {
        if (drawTypeRef.current === 'polygon') map.on('click', polygonLayerId, handleDelete);
        else map.on('click', lineLayerId, handleDelete);
      } else {
        map.getCanvas().style.cursor = 'grab';
      }
      map.off('click', 'points', handleClickOnPoint);
      map.off('mousedown', 'points', handleWayPointMouseDown);
      updateWayPointsSource({ type: 'FeatureCollection', features: [] });
    }

    return () => {
      map.off('click', handleClick);
      map.off('click', polygonLayerId, handleDelete);
      map.off('click', lineLayerId, handleDelete);
    };
  }, [mode]);

  useEffect(() => {
    if (!!secondaryPolygonPoints[0] && secondaryPolygonPoints[0].length > 0) {
      const polygonFeatures: GeoJSON.Feature<GeoJSON.LineString | GeoJSON.MultiLineString>[] = [];
      secondaryPolygonPoints.forEach((polygon, index) => {
        if (polygon.length === 0) return;
        const coordinates = [
          ...polygon.map((place) => place.point.coordinates),
          polygon[0].point.coordinates,
        ];
        polygonFeatures.push({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates,
          },
          properties: {
            polygonIndex: dropZonesRef.current[index]?.id || index,
            capacity: dropZonesRef.current[index]?.capacity || null,
            title: dropZonesRef.current[index]?.title || null,
            description: dropZonesRef.current[index]?.description || null,
          },
        });
      });
      dropZonesRef.current = [];
      updateSecondaryPolygonSource({ type: 'FeatureCollection', features: polygonFeatures });
      setSecondaryDrawings({ type: 'FeatureCollection', features: polygonFeatures });
    } else if (!!secondaryLinePoints[0] && secondaryLinePoints[0].length > 0) {
      const linesFeatures: GeoJSON.Feature[] = [];
      secondaryLinePoints.forEach((line, index) => {
        linesFeatures.push({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: line.map((point) => point.point.coordinates),
          },
          properties: {
            lineIndex: index,
          },
        });
      });
      updateSecondaryLineSource({ type: 'FeatureCollection', features: linesFeatures });
      setSecondaryDrawings({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'MultiLineString',
              coordinates: secondaryLinePoints.map((line) =>
                line.map((point) => point.point.coordinates),
              ),
            },
            properties: {},
          },
        ],
      });
    } else {
      updateSecondaryPolygonSource({ type: 'FeatureCollection', features: [] });
      updateLineSource({ type: 'FeatureCollection', features: [] });
      setSecondaryDrawings(undefined);
    }
  }, [secondaryPolygonPoints, secondaryLinePoints]);

  useEffect(() => {
    updatePoints();
  }, [polygonPoints, linePoints, dragging]);

  useEffect(() => {
    if (drawType) drawTypeRef.current = drawType;
  }, [drawType]);

  function init(allowMultiPolygon?: boolean) {
    if (!map || initializedRef.current) return;

    allowMultiPolygonRef.current = !!allowMultiPolygon;
    addPolygonSource();
    addLineSource();
    addSecondaryPolygonSource();
    addSecondaryLineSource();
    addWayPointsSource();
    drawControlRef.current = new CustomControl('draw-control');

    map?.addLayer(
      {
        id: polygonLayerId,
        type: 'fill',
        source: polygonSourceId,
        paint: {
          'fill-color': '#326ac2',
          'fill-opacity': 0.3,
          'fill-outline-color': '#326ac2',
        },
      },
      'labels',
    );

    map?.addLayer(
      {
        id: secondaryPolygonLayerId,
        type: 'fill',
        source: secondaryPolygonSourceId,
        paint: {
          'fill-color': '#ff0000',
          'fill-opacity': 0.3,
          'fill-outline-color': '#ff0000',
        },
      },
      'labels',
    );

    map?.addLayer(
      {
        id: lineLayerId,
        type: 'line',
        source: lineSourceId,
        paint: {
          'line-color': '#326ac2',
          'line-width': 2,
        },
      },
      'labels',
    );

    map?.addLayer(
      {
        id: secondaryLineLayerId,
        type: 'line',
        source: secondaryLineSourceId,
        paint: {
          'line-color': '#ff0000',
          'line-width': 2,
        },
      },
      'labels',
    );

    addCircleLayer(
      'points',
      'points',
      {
        'circle-radius': ['get', 'width'],
        'circle-stroke-width': ['get', 'strokeWidth'],
        'circle-stroke-opacity': 1,
        'circle-color': ['get', 'color'],
        'circle-stroke-color': ['get', 'strokeColor'],
      },
      {
        filter: ['all', ['==', 'wayPoint', true], ['!=', 'arrival', true]],
        disableCursorChange: true,
      },
    );

    initializedRef.current = true;
    setInitialized(true);
  }

  function enableDrawing(display: boolean) {
    if (!map || !drawControlRef.current) return;

    if (display) {
      map.addControl(drawControlRef.current, 'top-left');
      drawControlRef.current.init(theme, switchEditMode, deleteDrawings);
      modeRef.current = 'edit';
      setMode(modeRef.current);
    } else {
      map.removeControl(drawControlRef.current);
      modeRef.current = 'none';
      setMode(modeRef.current);
    }
  }

  function switchEditMode() {
    if (modeRef.current === 'edit') modeRef.current = 'none';
    else modeRef.current = 'edit';
    setMode(modeRef.current);
  }

  function deleteDrawings() {
    if (modeRef.current === 'delete') modeRef.current = 'none';
    else modeRef.current = 'delete';
    setMode(modeRef.current);
  }

  function handleClick(event: MapMouseEvent) {
    if (event.defaultPrevented) return;

    const { lngLat } = event;
    const { lat, lng } = lngLat;
    const newWayPoint = new Place(undefined, { type: 'Point', coordinates: [lng, lat] });
    addPoint(newWayPoint);
  }

  function addPoint(newWayPoint: Place) {
    if (drawTypeRef.current === 'line') {
      linePointsRef.current[linePointsRef.current.length - 1].push(newWayPoint);
      setLinePoints([...linePointsRef.current]);
    } else {
      polygonPointsRef.current[polygonPointsRef.current.length - 1].push(newWayPoint);
      setPolygonPoints([...polygonPointsRef.current]);
    }
    figureClosed.current = false;
    if (
      (polygonPointsRef.current[polygonPointsRef.current.length - 1].length === 1 ||
        linePointsRef.current[linePointsRef.current.length - 1].length === 1) &&
      map
    )
      map.on('mousemove', handleMove);
  }

  function closePolygon(points?: Place[][]) {
    if (!map) return;
    map.off('mousemove', handleMove);
    if (!allowMultiPolygonRef.current) {
      map.off('click', handleClick);
      map.off('click', 'points', handleClickOnPoint);
    }

    const polygonFeatures: GeoJSON.Feature<GeoJSON.LineString | GeoJSON.MultiLineString>[] = [];
    (points || polygonPointsRef.current).forEach((polygon, index) => {
      polygonFeatures.push({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            ...polygon.map((place) => place.point.coordinates),
            polygon[0].point.coordinates,
          ],
        },
        properties: { polygonIndex: index, capacity: null, title: null, description: null },
      });
    });
    updatePolygonSource({ type: 'FeatureCollection', features: polygonFeatures });
    if (points) polygonPointsRef.current = points;
    else if (allowMultiPolygonRef) polygonPointsRef.current.push([]);
    figureClosed.current = true;
    setPolygonPoints(points || polygonPointsRef.current);

    map.on('mousedown', 'points', handleWayPointMouseDown);
  }

  function endLine(points?: Place[][]) {
    if (!map) return;
    map.off('mousemove', handleMove);
    map.off('click', 'points', handleClickOnPoint);
    if (points) linePointsRef.current = points;
    else linePointsRef.current.push([]);
    setLinePoints(points || linePointsRef.current);
    figureClosed.current = true;
    map.on('mousedown', 'points', handleWayPointMouseDown);
  }

  function handleClickOnPoint(
    event: MapMouseEvent & {
      features?: MapGeoJSONFeature[] | undefined;
    },
  ) {
    event.preventDefault();
    const feature = event.features?.[0];
    if (drawTypeRef.current === 'polygon') {
      if (
        feature &&
        (feature.properties.polygonIndex === undefined ||
          feature.properties.polygonIndex === polygonPointsRef.current.length - 1) &&
        (feature.properties.index === 0 || feature.properties.index === polygonPoints.length - 1)
      )
        closePolygon();
    } else {
      if (
        feature &&
        feature.properties.index ===
          linePointsRef.current[linePointsRef.current.length - 1].length - 1
      )
        endLine();
    }
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (!map) return;
    if (event.key === 'Escape') {
      if (drawTypeRef.current === 'polygon') {
        if (polygonPointsRef.current[polygonPointsRef.current.length - 1].length > 2)
          closePolygon();
        else if (polygonPoints.length > 0) {
          polygonPointsRef.current[polygonPointsRef.current.length - 1] = [];
          setPolygonPoints([...polygonPointsRef.current]);
          map.off('mousemove', handleMove);
        }
      } else if (linePointsRef.current[linePointsRef.current.length - 1].length > 1) endLine();
      else {
        linePointsRef.current[linePointsRef.current.length - 1] = [];
        setLinePoints([...linePointsRef.current]);
        map.off('mousemove', handleMove);
      }
    }
  }

  function handleWayPointMouseDown(
    event: MapMouseEvent & {
      features?: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[] | undefined;
    },
  ) {
    const feature = event.features?.[0];
    if (!map) return;

    event.preventDefault();

    map.getCanvas().style.cursor = 'grab';

    draggedWayPointIndex.current = feature?.properties?.index;
    draggedWayPointLineIndex.current = feature?.properties?.lineIndex;
    draggedWayPointPolygonIndex.current = feature?.properties?.polygonIndex;

    map.on('mousemove', handleDragging);
    map.once('mouseup', handleDrop);
  }

  function handleDragging({ lngLat: { lat, lng } }: MapMouseEvent) {
    if (!map || draggedWayPointIndex.current === null) return;

    setDragging(true);
    map.getCanvas().style.cursor = 'grabbing';

    const newWayPoint = new Place(undefined, {
      type: 'Point',
      coordinates: [Math.round(lng * 1000000) / 1000000, Math.round(lat * 1000000) / 1000000],
    });

    if (drawTypeRef.current === 'polygon') {
      if (draggedWayPointPolygonIndex.current === null) return;
      polygonPointsRef.current[draggedWayPointPolygonIndex.current][draggedWayPointIndex.current] =
        newWayPoint;
      setPolygonPoints([...polygonPointsRef.current]);
    } else {
      if (draggedWayPointLineIndex.current === null) return;
      linePointsRef.current[draggedWayPointLineIndex.current][draggedWayPointIndex.current] =
        newWayPoint;
      setLinePoints([...linePointsRef.current]);
    }
  }

  function handleDrop(event: MapMouseEvent) {
    event.preventDefault();

    if (!map) return;

    map.getCanvas().style.cursor = 'pointer';

    draggedWayPointIndex.current = null;
    draggedWayPointLineIndex.current = null;
    setDragging(false);

    map.off('mousemove', handleDragging);
  }

  function handleDelete(
    event?: MapMouseEvent & {
      features?: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[] | undefined;
    },
    indexFromList?: number,
  ) {
    event?.preventDefault();
    if (drawTypeRef.current === 'polygon') {
      const feature = event?.features?.[0];
      let polygonIndex = feature?.properties?.polygonIndex || indexFromList;
      polygonPointsRef.current.forEach((polygon, index) => {
        if (
          (feature?.properties?.polygonIndex !== undefined &&
            polygon[0]?.id === feature?.properties?.polygonIndex) ||
          (!!indexFromList && polygon[0]?.id === indexFromList)
        )
          polygonIndex = index;
      });
      polygonPointsRef.current.splice(polygonIndex, 1);
      setPolygonPoints([...polygonPointsRef.current]);
    } else {
      const feature = event?.features?.[0];
      linePointsRef.current.splice(feature?.properties?.lineIndex, 1);
      setLinePoints([...linePointsRef.current]);
    }
  }

  function updatePoints() {
    const features: GeoJSON.Feature[] = [];
    const drawingFeatures: GeoJSON.Feature<GeoJSON.LineString | GeoJSON.MultiLineString>[] = [];

    if (drawTypeRef.current === 'polygon') {
      polygonPoints.forEach((polygon, polygonIndex) => {
        polygon.forEach((point, index) => {
          if (point) {
            features.push({
              type: 'Feature',
              geometry: point.point,
              properties: {
                index,
                polygonIndex,
                color: '#326ac2',
                width: 4,
                strokeColor: '#fff',
                strokeWidth: 1,
                wayPoint: true,
                movable: true,
              },
            });
          }
        });
      });
    } else
      linePoints.forEach((line, lineIndex) => {
        if (line) {
          line.map((point, index) => {
            if (point) {
              features.push({
                type: 'Feature',
                geometry: point.point,
                properties: {
                  index,
                  lineIndex,
                  color: '#326ac2',
                  width: 4,
                  strokeColor: '#fff',
                  strokeWidth: 1,
                  wayPoint: true,
                  movable: true,
                },
              });
            }
          });
        }
      });

    if (mode === 'edit') updateWayPointsSource({ type: 'FeatureCollection', features });

    if (drawTypeRef.current === 'polygon') {
      polygonPoints
        .filter((polygon) => polygon.length > 0)
        .forEach((polygon, index) => {
          const drawing = drawings?.features.find(
            (feature) => feature.properties?.polygonIndex === (polygon[0].id || index),
          );
          const coordinates = polygon.map((point) => point.point.coordinates);
          if (
            index < polygonPoints.length - 1 ||
            (index === polygonPoints.length - 1 && figureClosed.current)
          )
            coordinates.push(polygon[0].point.coordinates);
          drawingFeatures.push({
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates,
            },
            properties: {
              polygonIndex: polygon[0].id || index,
              capacity: drawing?.properties?.capacity || null,
              title: drawing?.properties?.title || null,
              description: drawing?.properties?.description || null,
            },
          });
        });
    } else {
      linePoints.forEach((line, index) => {
        if (line.length > 0)
          drawingFeatures.push({
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: line.map((point) => point.point.coordinates),
            },
            properties: {
              lineIndex: index,
            },
          });
      });
    }

    if (drawTypeRef.current === 'line') {
      updateLineSource({ type: 'FeatureCollection', features: drawingFeatures });
      if (!dragging && figureClosed.current) {
        setDrawings({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'MultiLineString',
                coordinates: linePointsRef.current.map((line) =>
                  line.map((point) => point.point.coordinates),
                ),
              },
              properties: {},
            },
          ],
        });
      }
    } else {
      updatePolygonSource({ type: 'FeatureCollection', features: drawingFeatures });
      if (!dragging && figureClosed.current) {
        setDrawings({ type: 'FeatureCollection', features: drawingFeatures });
      }
    }
  }

  function setBaseZone(points?: Place[][], type?: 'line' | 'polygon', dropZones?: DropZone[]) {
    if (points) {
      if (type === 'line') endLine(points);
      else closePolygon(points.map((polygon) => polygon.slice(0, -1)));

      if (dropZones && dropZones.length > 0) {
        dropZonesRef.current = dropZones;
        setSecondaryPolygonPoints(
          dropZones.map(
            (dropZone) =>
              (dropZone.geometry as GeoJSON.MultiPolygon | undefined)?.coordinates[0][0]
                .slice(0, -1)
                .map(
                  (point) =>
                    new Place(dropZone.id, { type: 'Point', coordinates: [point[0], point[1]] }),
                ) || [],
          ),
        );
      }
    } else {
      setLinePoints([[]]);
      setPolygonPoints([]);
    }
  }

  function handleMove(event: MapMouseEvent) {
    const features: GeoJSON.Feature[] = [];

    if (drawTypeRef.current === 'polygon') {
      const polygons = polygonPointsRef.current.map((polygon) =>
        polygon.map((place) => place.point.coordinates),
      );
      polygons[polygons.length - 1].push([event.lngLat.lng, event.lngLat.lat]);
      polygons.forEach((polygon, index) => {
        features.push({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: polygon,
          },
          properties: { polygonIndex: index },
        });
      });
      updatePolygonSource({ type: 'FeatureCollection', features });
    } else {
      const lines = linePointsRef.current.map((line) =>
        line.map((point) => point.point.coordinates),
      );
      lines[lines.length - 1].push([event.lngLat.lng, event.lngLat.lat]);
      lines.forEach((line, index) => {
        features.push({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: line,
          },
          properties: { lineIndex: index },
        });
      });
      updateLineSource({ type: 'FeatureCollection', features });
    }
  }

  function drawZones(polygons: GeoJSON.FeatureCollection, lines: GeoJSON.FeatureCollection) {
    updatePolygonSource(polygons);
    updateLineSource(lines);
  }

  function swapDrawingZones({
    drawTypeForced,
    allowMultiPolygon,
  }: {
    drawTypeForced?: 'line' | 'polygon';
    allowMultiPolygon?: boolean;
  }) {
    allowMultiPolygonRef.current = !!allowMultiPolygon;
    modeRef.current = 'none';
    setMode(modeRef.current);
    const secondaryPolygonPointsTemp = [...secondaryPolygonPoints];
    const secondaryLinePointsTemp = [...secondaryLinePoints];
    const secondaryDrawingsTemp = secondaryDrawings
      ? ({
          type: secondaryDrawings.type,
          features: secondaryDrawings.features,
        } as GeoJSON.FeatureCollection<GeoJSON.LineString | GeoJSON.MultiLineString>)
      : undefined;
    setSecondaryPolygonPoints([...polygonPoints]);
    setSecondaryLinePoints([...linePoints]);
    polygonPointsRef.current = secondaryPolygonPointsTemp;
    linePointsRef.current = secondaryLinePointsTemp;
    setPolygonPoints(secondaryPolygonPointsTemp);
    setLinePoints(secondaryLinePointsTemp);
    setDrawings(secondaryDrawingsTemp);
    forceDrawTypeRef.current = drawTypeForced;
  }

  function clear() {
    clearPolygonSource();
    clearLineSource();
    clearSecondaryPolygonSource();
    clearSecondaryLineSource();
    clearWayPointsSource();
  }

  function destroy() {
    if (drawControlRef.current) map?.removeControl(drawControlRef.current);
    clear();
    initializedRef.current = false;
    setInitialized(false);
    map?.removeLayer(polygonLayerId);
    map?.removeLayer(lineLayerId);
    map?.removeLayer(secondaryPolygonLayerId);
    map?.removeLayer(secondaryLineLayerId);
    map?.removeLayer('points');
    map?.removeSource(polygonSourceId);
    map?.removeSource(lineSourceId);
    map?.removeSource(secondaryPolygonSourceId);
    map?.removeSource(secondaryLineSourceId);
    map?.removeSource('points');
  }

  return {
    initialized,
    init,
    destroy,
    clear,
    enableDrawing,
    drawZones,
    drawings,
    handleDelete,
    secondaryDrawings,
    setBaseZone,
    setDrawings,
    swapDrawingZones,
  };
}

export default useDraw;
