import { IPoint, Isochrone, Place, useSource } from '@geovelo-frontends/commons';
import { Theme } from '@mui/material';
import difference from '@turf/difference';
import truncate from '@turf/truncate';
import union from '@turf/union';
import { useRef, useState } from 'react';

import { colors as _colors } from '../../components/map/isochrones-legend-control';

import { LngLat, LngLatBounds, Map } from '!maplibre-gl';

const isochronesDeparturesSourceId = 'isochrones-departures';
const isochronesDeparturesLayerId = 'isochrones-departures';
const isochronesSourceId = 'isochrones';
const isochronesLabelsSourceId = 'isochrones-labels';
const isochronesLayerId = 'isochrones';
const isochronesBordersLayerId = 'isochrones-borders';

function useIsochrones(
  map: Map | null | undefined,
  { palette }: Theme,
  onClick?: (point: IPoint) => void,
): {
  initialized: boolean;
  init: () => void;
  setDepartures: (departures: Place[], fitBounds?: boolean) => void;
  update: (isochrones?: Isochrone[], fitBounds?: boolean) => void;
  clear: () => void;
  destroy: () => void;
} {
  const [initialized, setInitialized] = useState(false);
  const initializedRef = useRef(false);
  const {
    addGeoJSONSource: addIsochronesDeparturesSource,
    getGeoJSONSource: getIsochronesDeparturesSource,
    updateGeoJSONSource: updateIsochronesDeparturesSource,
    clearGeoJSONSource: clearIsochronesDeparturesSource,
  } = useSource(map, isochronesDeparturesSourceId);
  const {
    addGeoJSONSource: addIsochronesSource,
    updateGeoJSONSource: updateIsochronesSource,
    clearGeoJSONSource: clearIsochronesSource,
  } = useSource(map, isochronesSourceId);
  const {
    addGeoJSONSource: addIsochronesLabelsSource,
    updateGeoJSONSource: updateIsochronesLabelsSource,
    clearGeoJSONSource: clearIsochronesLabelsSource,
  } = useSource(map, isochronesLabelsSourceId);

  function init() {
    if (!map || initializedRef.current) return;
    if (getIsochronesDeparturesSource()) {
      initializedRef.current = true;
      setInitialized(true);
      return;
    }

    addIsochronesDeparturesSource();
    addIsochronesSource();
    addIsochronesLabelsSource();

    map.addLayer({
      id: isochronesDeparturesLayerId,
      type: 'circle',
      source: isochronesDeparturesSourceId,
      paint: {
        'circle-radius': 6,
        'circle-stroke-width': 2,
        'circle-stroke-opacity': 0.3,
        'circle-color': palette.secondary.main,
        'circle-stroke-color': palette.secondary.main,
      },
    });

    map.addLayer(
      {
        id: isochronesLayerId,
        type: 'fill',
        source: isochronesSourceId,
        paint: {
          'fill-color': ['get', 'color'],
        },
      },
      'labels',
    );

    map.addLayer(
      {
        id: isochronesBordersLayerId,
        type: 'line',
        source: isochronesSourceId,
        paint: {
          'line-color': ['get', 'borderColor'],
          'line-width': 2,
        },
      },
      'labels',
    );

    map.addLayer({
      id: 'isochrones-labels',
      type: 'symbol',
      source: isochronesLabelsSourceId,
      layout: {
        'symbol-placement': 'line',
        'text-field': '{title}',
        'text-font': ['Nunito'],
        'text-size': 14,
        'text-max-width': 15,
      },
      paint: {
        'text-color': ['get', 'color'],
        'text-halo-color': '#fff',
        'text-halo-width': 2,
      },
    });

    if (onClick) {
      map.on('click', ({ lngLat }) => onClick(lngLat));
    }

    initializedRef.current = true;
    setInitialized(true);
  }

  function setDepartures(departures: Place[], fitBounds = true) {
    if (!map) return;

    updateIsochronesDeparturesSource({
      type: 'FeatureCollection',
      features: departures.map(({ point: geometry }) => ({
        type: 'Feature',
        geometry,
        properties: {},
      })),
    });

    if (fitBounds && departures.length > 0) {
      let [lng, lat] = departures[0].point.coordinates;
      const bounds = LngLatBounds.fromLngLat(new LngLat(lng, lat), 100);

      departures.slice(1).forEach(({ point: { coordinates } }) => {
        [lng, lat] = coordinates;
        bounds.extend(LngLatBounds.fromLngLat(new LngLat(lng, lat), 100));
      });

      map.fitBounds(bounds);
    }
  }

  function update(isochrones?: Isochrone[], fitBounds = true) {
    if (!isochrones || isochrones.length === 0) {
      clearIsochronesSource();
      clearIsochronesLabelsSource();

      return;
    }

    const colors = [..._colors].slice(0, isochrones.length).reverse();
    const sortedIsochrones = [...isochrones].sort((a, b) => a.duration - b.duration);

    const isochronesLabelsFeatures: GeoJSON.Feature<GeoJSON.MultiPolygon | GeoJSON.Polygon>[] = [];
    let min = 0;
    let unionGeometry: GeoJSON.MultiPolygon | GeoJSON.Polygon | undefined;

    updateIsochronesSource({
      type: 'FeatureCollection',
      features: sortedIsochrones
        .map(({ duration, geometry: _geometry }, index) => {
          const color = colors[index];
          let geometry: GeoJSON.MultiPolygon | GeoJSON.Polygon | undefined;

          if (index === 0) {
            geometry = _geometry;
            unionGeometry = { ..._geometry };
          } else {
            min = sortedIsochrones[index - 1].duration;
            const newUnionGeometry = unionGeometry && union(unionGeometry, _geometry)?.geometry;
            geometry =
              unionGeometry &&
              newUnionGeometry &&
              difference(truncate(newUnionGeometry), truncate(unionGeometry))?.geometry;
            unionGeometry = newUnionGeometry;
          }

          isochronesLabelsFeatures.push({
            type: 'Feature',
            geometry: unionGeometry || { type: 'MultiPolygon', coordinates: [] },
            properties: {
              color: color.text,
              title: `${Math.round(min / 60)} -> ${Math.round(duration / 60)} min`,
            },
          });

          return {
            type: 'Feature',
            geometry: geometry || { type: 'MultiPolygon', coordinates: [] },
            properties: {
              borderColor: color.border,
              color: color.main,
            },
          };
        })
        .reverse() as Array<GeoJSON.Feature<GeoJSON.MultiPolygon | GeoJSON.Polygon>>,
    });

    updateIsochronesLabelsSource({
      type: 'FeatureCollection',
      features: isochronesLabelsFeatures,
    });

    if (fitBounds) {
      const {
        bounds: { north, east, south, west },
      } = sortedIsochrones[sortedIsochrones.length - 1];
      const bounds = new LngLatBounds({ lat: south, lng: west }, { lat: north, lng: east });

      map?.fitBounds(bounds, { padding: 50, animate: false });
    }
  }

  function clear() {
    clearIsochronesDeparturesSource();
    clearIsochronesSource();
    clearIsochronesLabelsSource();
  }

  function destroy() {
    clear();

    initializedRef.current = false;
    setInitialized(false);
  }

  return { initialized, init, setDepartures, update, clear, destroy };
}

export default useIsochrones;
