import { RideService } from '@geovelo-frontends/commons';
import { DialogProps, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { AppContext } from '../../../../app/context';
import Dialog from '../../../../components/dialog';

export interface IValues {
  title: string;
}

type TProps = Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
};

function RideFormDialog({ onClose, ...props }: TProps): JSX.Element {
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    isSubmitting,
    values,
    touched,
    errors,
    setValues,
    setSubmitting,
    handleChange,
    handleSubmit,
  } = useFormik<IValues>({
    initialValues: {
      title: '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(),
    }),
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (props.open) setValues({ title: '' });
  }, [props.open]);

  async function onSubmit({ title }: IValues) {
    setSubmitting(true);

    try {
      const ride = await RideService.addRideV1({ title });

      navigate(`/${currentPartner?.code}/promotion/rides/${ride.id}`);
    } catch {
      enqueueSnackbar(t('cycling-insights.tourism.rides.form_dialog.server_error'), {
        variant: 'error',
      });
      setSubmitting(false);
    }
  }

  return (
    <Dialog
      isForm
      confirmTitle={<Trans i18nKey="commons.actions.add" />}
      dialogTitle="ride-form-dialog"
      maxWidth="sm"
      onCancel={onClose}
      onConfirm={handleSubmit}
      title={<Trans i18nKey="cycling-insights.tourism.rides.form_dialog.title" />}
      {...props}
    >
      <TextField
        fullWidth
        required
        disabled={isSubmitting}
        error={touched.title && Boolean(errors.title)}
        label={t('cycling-insights.tourism.rides.form_dialog.ride_title')}
        margin="dense"
        name="title"
        onChange={handleChange}
        size="small"
        value={values.title}
        variant="outlined"
      />
    </Dialog>
  );
}

export default RideFormDialog;
