import { TPublicationStatus } from '@geovelo-frontends/commons';
import { Cancel, Check, Close, CloudOff, CloudUpload } from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { useContext } from 'react';
import { Trans } from 'react-i18next';

import { AppContext } from '../../app/context';
import Button from '../../components/button';

import { TPublicationDialogStatus } from './publication-status-dialog';

interface IProps {
  onChange: (status: TPublicationDialogStatus) => void;
  status: TPublicationStatus;
}

function PublicationStatus({ status, onChange }: IProps): JSX.Element {
  const {
    user: { current: currentUser },
  } = useContext(AppContext);

  if (!currentUser) return <></>;

  const { isGeovelo } = currentUser;

  if (status === 'unpublished') {
    return (
      <Button
        color="primary"
        onClick={() => onChange(isGeovelo ? 'publish' : 'requestPublication')}
        startIcon={<CloudUpload />}
        variant="contained"
      >
        <Trans
          i18nKey={
            isGeovelo
              ? 'cycling-insights.ride.actions.publish'
              : 'cycling-insights.ride.actions.request_publication'
          }
        />
      </Button>
    );
  }

  if (status === 'waitingForApproval') {
    return currentUser?.isGeovelo ? (
      <>
        <Typography style={{ color: orange[500], marginLeft: 16 }} variant="caption">
          <Trans i18nKey="cycling-insights.ride.waiting_for_approval" />
        </Typography>
        <Tooltip title={<Trans i18nKey="commons.actions.accept" />}>
          <IconButton
            onClick={() => onChange('publish')}
            size="small"
            style={{ border: `1px solid ${green[500]}`, color: green[500] }}
          >
            <Check />
          </IconButton>
        </Tooltip>
        <Tooltip title={<Trans i18nKey="commons.actions.reject" />}>
          <IconButton
            onClick={() => onChange('rejectRequest')}
            size="small"
            style={{ border: `1px solid ${red[500]}`, color: red[500] }}
          >
            <Close />
          </IconButton>
        </Tooltip>
      </>
    ) : (
      <Button onClick={() => onChange('cancelRequest')} startIcon={<Cancel />} variant="outlined">
        <Trans i18nKey="cycling-insights.ride.actions.cancel_publication_request" />
      </Button>
    );
  }

  return (
    <Button
      onClick={() => onChange('unpublish')}
      startIcon={<CloudOff />}
      style={{ backgroundColor: red[500], color: '#fff' }}
      variant="contained"
    >
      <Trans i18nKey="cycling-insights.ride.actions.unpublish" />
    </Button>
  );
}

export default PublicationStatus;
