import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Moment } from 'moment';
import { useEffect } from 'react';

import useDualColumnChart from '../hooks/charts/dual-column';

import { IPeriodFormProps } from './form/period';

export interface ICurrentStat<T extends string = ''> {
  count: number;
  detailedCount?: { [key in T]?: number };
  values: { date: Moment; value: number }[];
}

export interface IPrevStat<T extends string = ''> {
  count: number;
  detailedCount?: { [key in T]?: number };
  values: { initialDate: Moment; date: Moment; value: number }[];
}

export type TStats<T extends string = ''> =
  | { current: ICurrentStat<T>; prev: IPrevStat<T>; progression: number }
  | undefined;

interface IProps<T extends string = ''> {
  chartId: string;
  computeMean?: boolean;
  period: IPeriodFormProps;
  labelFormat?: (label: string | number) => string;
  stats: TStats<T>;
  type?: 'bar' | 'line';
}

function DualChart<T extends string = ''>({
  chartId,
  computeMean,
  type,
  period,
  stats,
  labelFormat,
}: IProps<T>): JSX.Element {
  const theme = useTheme();
  const {
    values: { current: currentPeriod, prev: prevPeriod },
  } = period;
  const {
    updateData: updateChartData,
    clearData: clearChartData,
    reset: resetChart,
  } = useDualColumnChart(chartId, currentPeriod, prevPeriod, theme, labelFormat, type);

  useEffect(() => {
    resetChart(currentPeriod, prevPeriod);
  }, [period.values]);

  useEffect(() => {
    if (!stats) {
      clearChartData();
    } else {
      updateChartData(
        currentPeriod,
        stats.current.values.map(({ date: t, value: y }) => ({ t, y })),
        stats.prev.values.map(({ initialDate, date: t, value: y }) => ({ initialDate, t, y })),
        { computeMean },
      );
    }
  }, [stats]);

  return (
    <Box height={200}>
      <canvas id={chartId} style={{ height: '100%', width: '100%' }} />
    </Box>
  );
}

export default DualChart;
