import {
  Ride,
  RideService,
  TBackendRideArea,
  TBackendRidePublicKind,
  TRideArea,
  TRidePublicKind,
  difficultiesMap,
  publicKindsMap,
  useUnits,
} from '@geovelo-frontends/commons';
import { AddCircleOutline, InfoOutlined, RemoveCircleOutline } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Slider,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';

import Button from '../../../../components/button';
import Card from '../../../../components/card';

interface IValues {
  title: string;
  isHighlighted: boolean;
  duration: number;
  departureCity: string;
  arrivalCity: string;
  description: string;
  publicKind: TRidePublicKind;
  area: TRideArea;
  metaTitle: string;
  metaDescription: string;
  primaryColor: string;
  secondaryColor: string;
}

const publicKinds: TRidePublicKind[] = ['everyone', 'family', 'sportive'];

export const areas: Array<{ key: TRideArea; labelKey: string }> = [
  { key: 'local', labelKey: 'cycling-insights.ride.areas.local' },
  { key: 'regional', labelKey: 'cycling-insights.ride.areas.regional' },
  { key: 'national', labelKey: 'cycling-insights.ride.areas.national' },
];

const durationMin = 900;
const durationMax = 28800;
const durationStep = 900;
const durationDefault = 9000;

interface IProps {
  canWrite: boolean;
  onChange: (ride: Ride) => void;
  ride: Ride;
  setDirty: (dirty: boolean) => void;
}

function MainForm({ canWrite, ride, setDirty, onChange }: IProps): JSX.Element {
  const [durationType, setDurationType] = useState<'custom' | 'default'>(
    ride.overloadDuration ? 'custom' : 'default',
  );
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { toTime, toDistance } = useUnits();
  const {
    isSubmitting,
    values,
    touched,
    errors,
    setFieldValue,
    setValues,
    handleChange: _handleChange,
    handleSubmit,
  } = useFormik<IValues>({
    initialValues: {
      title: '',
      isHighlighted: false,
      duration: durationMin,
      departureCity: '',
      arrivalCity: '',
      description: '',
      publicKind: 'everyone',
      area: 'local',
      metaTitle: '',
      metaDescription: '',
      primaryColor: '',
      secondaryColor: '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(),
      primaryColor: Yup.string().matches(/^#[0-9a-f]{6}/i),
      secondaryColor: Yup.string().matches(/^#[0-9a-f]{6}/i),
    }),
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    init();
  }, []);

  function init() {
    const {
      title,
      isHighlighted,
      overloadDuration,
      from,
      to,
      description,
      publicKind,
      area,
      metaTitle,
      metaDescription,
      primaryColor,
      secondaryColor,
    } = ride;

    setValues({
      title: title || '',
      isHighlighted: isHighlighted || false,
      duration: overloadDuration || durationDefault,
      departureCity: from?.addressDetail?.primaryText || '',
      arrivalCity: to?.addressDetail?.primaryText || '',
      description: description || '',
      publicKind: publicKind || 'everyone',
      area: area || 'local',
      metaTitle: metaTitle || '',
      metaDescription: metaDescription || '',
      primaryColor: primaryColor || '',
      secondaryColor: secondaryColor || '',
    });
    setDirty(false);
  }

  async function onSubmit(
    {
      title,
      isHighlighted,
      duration,
      departureCity,
      arrivalCity,
      description,
      publicKind,
      area,
      metaTitle,
      metaDescription,
      primaryColor,
      secondaryColor,
    }: IValues,
    { setSubmitting }: FormikHelpers<IValues>,
  ) {
    if (!ride.id) return;

    setSubmitting(true);

    const publicKindMap: { [key in TRidePublicKind]: TBackendRidePublicKind } = {
      everyone: 'EVERYONE',
      family: 'FAMILY',
      sportive: 'ATHLETIC',
    };

    const areaMap: { [key in TRideArea]: TBackendRideArea } = {
      local: 'LOCAL',
      regional: 'REGIONAL',
      national: 'NATIONAL',
    };

    try {
      const updatedRide = await RideService.updateRideV1(ride.id, {
        title,
        is_highlighted: isHighlighted,
        duration: durationType === 'custom' ? duration : 0,
        geo_point_a_title: departureCity,
        geo_point_b_title: arrivalCity,
        description,
        public_kind: publicKindMap[publicKind],
        area_wide: areaMap[area],
        meta_title: metaTitle,
        meta_description: metaDescription,
        theme_primary_color: primaryColor,
        theme_secondary_color: secondaryColor,
      });

      onChange(updatedRide);
      setDirty(false);
      enqueueSnackbar(t('cycling-insights.ride.updated'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.not_updated'), { variant: 'error' });
    }

    setSubmitting(false);
  }

  function handleChange(event: ChangeEvent | SelectChangeEvent) {
    setDirty(true);
    _handleChange(event);
  }

  return (
    <Card title={<Trans i18nKey="cycling-insights.ride.main_form.title" />}>
      <Wrapper>
        <form onSubmit={handleSubmit}>
          <StyledRow>
            <TextField
              fullWidth
              required
              disabled={!canWrite || isSubmitting}
              error={touched.title && Boolean(errors.title)}
              label={t('cycling-insights.ride.main_form.ride_title')}
              margin="dense"
              name="title"
              onChange={handleChange}
              size="small"
              value={values.title}
              variant="outlined"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={values.isHighlighted}
                  name="isHighlighted"
                  onChange={handleChange}
                />
              }
              disabled={!canWrite || isSubmitting}
              label={
                <Typography noWrap>
                  <Trans i18nKey="cycling-insights.ride.main_form.highlight" />
                </Typography>
              }
            />
          </StyledRow>
          <Box alignItems="flex-start" flexDirection="column" marginTop={1}>
            <Typography color="textSecondary" variant="body2">
              <Trans
                components={[
                  <Typography color="primary" component="span" key={0} variant="body2" />,
                ]}
                i18nKey="cycling-insights.ride.main_form.distance"
                values={{ distance: toDistance(ride.distance || 0) }}
              />
            </Typography>
            <Typography color="textSecondary" variant="body2">
              <Trans
                components={[
                  <Typography color="primary" component="span" key={0} variant="body2" />,
                ]}
                i18nKey="cycling-insights.ride.main_form.duration"
                values={{
                  duration: toTime(
                    durationType === 'custom' ? values.duration : ride.duration || 0,
                  ),
                }}
              />
            </Typography>
            {ride.difficulty && (
              <Box alignItems="center" display="flex" gap={0.5}>
                <Typography color="textSecondary" variant="body2">
                  <Trans
                    components={[
                      <Typography color="primary" component="span" key={0} variant="body2" />,
                    ]}
                    i18nKey="cycling-insights.ride.main_form.difficulty"
                    values={{ difficulty: t(difficultiesMap[ride.difficulty].labelKey) }}
                  />
                </Typography>
                <Tooltip
                  title={
                    <Box display="flex" flexDirection="column">
                      <Typography variant="caption">
                        {t('cycling-insights.ride.main_form.levels.description')}
                      </Typography>
                      <Typography component="ul" variant="caption">
                        <li>{t('cycling-insights.ride.main_form.levels.easy')}</li>
                        <li>{t('cycling-insights.ride.main_form.levels.medium')}</li>
                        <li>{t('cycling-insights.ride.main_form.levels.hard')}</li>
                      </Typography>
                    </Box>
                  }
                >
                  <InfoOutlined color="action" sx={{ height: 14, width: 14 }} />
                </Tooltip>
              </Box>
            )}
          </Box>
          <DurationRow>
            <StyledRadioGroup
              row
              onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
                setDurationType(value as 'default' | 'custom')
              }
              value={durationType}
            >
              <FormControlLabel
                control={<Radio />}
                disabled={!canWrite || isSubmitting}
                label={<Trans i18nKey="commons.periods.default" />}
                value="default"
              />
              <FormControlLabel
                control={<Radio />}
                disabled={!canWrite || isSubmitting}
                label={<Trans i18nKey="commons.periods.custom" />}
                value="custom"
              />
            </StyledRadioGroup>
            <Grid container spacing={2}>
              <Grid item>
                <IconButton
                  disabled={
                    !canWrite ||
                    isSubmitting ||
                    values.duration <= durationMin ||
                    durationType === 'default'
                  }
                  onClick={() =>
                    values.duration > durationMin &&
                    setFieldValue('duration', values.duration - durationStep)
                  }
                  size="small"
                >
                  <RemoveCircleOutline />
                </IconButton>
              </Grid>
              <Grid item xs>
                <Slider
                  disabled={!canWrite || isSubmitting || durationType === 'default'}
                  max={durationMax}
                  min={durationMin}
                  name="duration"
                  onChange={(_, value) =>
                    typeof value === 'number' && setFieldValue('duration', value)
                  }
                  step={durationStep}
                  value={values.duration}
                />
              </Grid>
              <Grid item>
                <IconButton
                  disabled={
                    !canWrite ||
                    isSubmitting ||
                    values.duration >= durationMax ||
                    durationType === 'default'
                  }
                  onClick={() =>
                    values.duration < durationMax &&
                    setFieldValue('duration', values.duration + durationStep)
                  }
                  size="small"
                >
                  <AddCircleOutline />
                </IconButton>
              </Grid>
            </Grid>
          </DurationRow>
          <StyledRow>
            <TextField
              fullWidth
              disabled={!canWrite || isSubmitting}
              label={t('cycling-insights.ride.main_form.departure_city')}
              margin="dense"
              name="departureCity"
              onChange={handleChange}
              size="small"
              value={values.departureCity}
              variant="outlined"
            />
            <TextField
              fullWidth
              disabled={!canWrite || isSubmitting}
              label={t('cycling-insights.ride.main_form.arrival_city')}
              margin="dense"
              name="arrivalCity"
              onChange={handleChange}
              size="small"
              value={values.arrivalCity}
              variant="outlined"
            />
          </StyledRow>
          <StyledRow>
            <TextField
              fullWidth
              multiline
              disabled={!canWrite || isSubmitting}
              id="description"
              label={t('cycling-insights.ride.main_form.description')}
              margin="dense"
              maxRows={8}
              minRows={3}
              name="description"
              onChange={handleChange}
              value={values.description}
              variant="outlined"
            />
          </StyledRow>
          <StyledRow>
            <FormControl
              fullWidth
              disabled={!canWrite || isSubmitting}
              margin="dense"
              variant="outlined"
            >
              <InputLabel htmlFor="ride-public-input">
                <Trans i18nKey="cycling-insights.ride.main_form.public_kind" />
              </InputLabel>
              <Select
                inputProps={{
                  id: 'ride-public-input',
                }}
                label={<Trans i18nKey="cycling-insights.ride.main_form.public_kind" />}
                name="publicKind"
                onChange={handleChange}
                size="small"
                value={values.publicKind}
              >
                {publicKinds.map((key) => (
                  <MenuItem key={key} value={key}>
                    <Trans i18nKey={publicKindsMap[key].labelKey} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              disabled={!canWrite || isSubmitting}
              margin="dense"
              variant="outlined"
            >
              <InputLabel htmlFor="ride-area-input">
                <Trans i18nKey="cycling-insights.ride.main_form.area" />
              </InputLabel>
              <Select
                inputProps={{
                  id: 'ride-area-input',
                }}
                label={<Trans i18nKey="cycling-insights.ride.main_form.area" />}
                name="area"
                onChange={handleChange}
                size="small"
                value={values.area}
              >
                {areas.map(({ key, labelKey }) => (
                  <MenuItem key={key} value={key}>
                    <Trans i18nKey={labelKey} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </StyledRow>
          <StyledRow>
            <TextField
              fullWidth
              disabled={!canWrite || isSubmitting}
              error={touched.primaryColor && Boolean(errors.primaryColor)}
              label={t('cycling-insights.ride.main_form.primary_color')}
              margin="dense"
              name="primaryColor"
              onChange={handleChange}
              placeholder="#000000"
              size="small"
              value={values.primaryColor}
              variant="outlined"
            />
            <TextField
              fullWidth
              disabled={!canWrite || isSubmitting}
              error={touched.secondaryColor && Boolean(errors.secondaryColor)}
              label={t('cycling-insights.ride.main_form.secondary_color')}
              margin="dense"
              name="secondaryColor"
              onChange={handleChange}
              placeholder="#000000"
              size="small"
              value={values.secondaryColor}
              variant="outlined"
            />
          </StyledRow>
          <StyledRow>
            <TextField
              fullWidth
              disabled={!canWrite || isSubmitting}
              error={touched.metaTitle && Boolean(errors.metaTitle)}
              label={t('cycling-insights.ride.main_form.meta_title')}
              margin="dense"
              name="metaTitle"
              onChange={handleChange}
              size="small"
              value={values.metaTitle}
              variant="outlined"
            />
            <TextField
              fullWidth
              disabled={!canWrite || isSubmitting}
              error={touched.metaDescription && Boolean(errors.metaDescription)}
              label={t('cycling-insights.ride.main_form.meta_description')}
              margin="dense"
              name="metaDescription"
              onChange={handleChange}
              size="small"
              value={values.metaDescription}
              variant="outlined"
            />
          </StyledRow>
          {canWrite && (
            <StyledActions>
              <Button
                disabled={isSubmitting}
                onClick={() => init()}
                size="medium"
                type="reset"
                variant="outlined"
              >
                <Trans i18nKey="commons.actions.reset" />
              </Button>
              <Button
                color="primary"
                disabled={isSubmitting}
                size="medium"
                startIcon={
                  isSubmitting && <CircularProgress color="inherit" size={16} thickness={4} />
                }
                type="submit"
                variant="contained"
              >
                <Trans i18nKey="commons.actions.save" />
              </Button>
            </StyledActions>
          )}
        </form>
      </Wrapper>
    </Card>
  );
}

const Wrapper = styled.div`
  padding: 16px;
`;

const StyledRow = styled.div`
  align-items: center;
  display: flex;

  &:not(:first-child) {
    margin-top: 12px;
  }

  > *:not(:first-child) {
    margin-left: 16px;
  }
`;

const StyledActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  button:not(:first-child) {
    margin-left: 8px;
  }
`;

const DurationRow = styled.div`
  align-items: center;
  display: flex;
`;

const StyledRadioGroup = styled(RadioGroup)`
  flex-shrink: 0;
`;

export default MainForm;
