import {
  CyclabilityZone,
  CyclabilityZoneBNSCStats,
  CyclabilityZoneService,
  ParkingTypes,
  backendAdministrativeLevels,
  childAdministrativeLevels,
  useCancellablePromise,
  useUnits,
} from '@geovelo-frontends/commons';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../app/context';
import { TParkingsPageContext } from '../context';

function useParkings({
  context: {
    period,
    parkings: {
      cyclabilityZone,
      prevCyclabilityZone,
      cyclabilityZones,
      prevCyclabilityZones,
      zonesMap,
      setCyclabilityZone,
      setPrevCyclabilityZone,
      setCyclabilityZones,
      setPrevCyclabilityZones,
      setZonesMap,
      setStats,
      setPrevStats,
      selectedZone,
    },
  },
}: {
  context: TParkingsPageContext;
}) {
  const [progressions, setProgressions] =
    useState<{ id: number; title: string; subtitle: string; value: number }[]>();
  const [initialized, setInitialized] = useState(false);
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { formatNumber } = useUnits();

  useEffect(() => {
    setInitialized(true);

    return () => {
      cancelPromises();
      setCyclabilityZones(undefined);
      setPrevCyclabilityZones(undefined);
      setZonesMap({});
      setStats(undefined);
      setPrevStats(undefined);
    };
  }, []);

  useEffect(() => {
    if (!initialized) return;

    setCyclabilityZones(undefined);
    setPrevCyclabilityZones(undefined);

    if (currentPartner) {
      if (!selectedZone) getParentZones();
      else getChildrenZones(true);
    }

    return () => {
      cancelPromises();
    };
  }, [initialized, selectedZone, period.values, period.comparisonEnabled]);

  useEffect(() => {
    if (!currentPartner || !cyclabilityZones) return;

    if (!selectedZone) {
      if (cyclabilityZone) {
        setStats(getStats([cyclabilityZone]));
        setPrevStats(
          prevCyclabilityZone
            ? {
                [prevCyclabilityZone.id]: getStats([prevCyclabilityZone]),
                global: getStats([prevCyclabilityZone]),
              }
            : undefined,
        );
      } else {
        setStats(getStats(cyclabilityZones));
        setPrevStats(
          prevCyclabilityZones?.reduce<{
            [key: number]: CyclabilityZoneBNSCStats;
            global: CyclabilityZoneBNSCStats;
          }>(
            (res, { id, statsBNSC }) => {
              res[id] = statsBNSC[0];

              return res;
            },
            { global: getStats(prevCyclabilityZones) },
          ),
        );
      }
    } else {
      const prevSelectedZone = prevCyclabilityZones?.find((zone) => zone.id === selectedZone.id);
      setStats(getStats([selectedZone]));
      setPrevStats(
        prevSelectedZone
          ? {
              [prevSelectedZone.id]: getStats([prevSelectedZone]),
              global: getStats([prevSelectedZone]),
            }
          : undefined,
      );
    }

    setProgressions(
      prevCyclabilityZones
        ?.map((zone) => {
          const count =
            cyclabilityZones?.find(({ id }) => zone.id === id)?.statsBNSC[0]?.slots.all || 0;

          return {
            zone,
            count,
            diff: count - (zone.statsBNSC[0]?.slots.all || 0),
          };
        })
        .sort((a, b) => b.diff - a.diff)
        .filter(({ diff }) => diff > 0)
        .slice(0, 3)
        .map(({ zone, count, diff }) => ({
          id: zone.id,
          title: zone.name,
          subtitle: `${t('commons.parking.racks', { count, capacity: formatNumber(count) })}`,
          value: diff,
        })),
    );

    return () => {
      setStats(undefined);
      setPrevStats(undefined);
      setProgressions(undefined);
    };
  }, [cyclabilityZones, prevCyclabilityZones]);

  function getStats(cyclabilityZones: CyclabilityZone[]): CyclabilityZoneBNSCStats {
    const slots: { [key in ParkingTypes | 'all']: number } = {
      [ParkingTypes.Arch]: 0,
      [ParkingTypes.Free]: 0,
      [ParkingTypes.Private]: 0,
      [ParkingTypes.Rack]: 0,
      [ParkingTypes.Secure]: 0,
      [ParkingTypes.Sheltered]: 0,
      [ParkingTypes.Locked]: 0,
      all: 0,
    };

    cyclabilityZones.forEach(({ statsBNSC }) => {
      if (statsBNSC[0]) {
        Object.values(ParkingTypes).forEach((typeKey) => {
          slots[typeKey] += statsBNSC[0].slots[typeKey] || 0;
        });
        slots.all += statsBNSC[0].slots.all || 0;
      }
    });

    return new CyclabilityZoneBNSCStats(period.values.current.from, {
      ...slots,
    });
  }

  async function getParentZones() {
    if (!currentPartner) return;

    const childAdministrativeLevel = childAdministrativeLevels[currentPartner.administrativeLevel];
    const administrativeLevel = childAdministrativeLevel
      ? childAdministrativeLevel === 'world'
        ? undefined
        : childAdministrativeLevel
      : currentPartner.administrativeLevel === 'world'
        ? undefined
        : currentPartner.administrativeLevel;
    if (!administrativeLevel) return;

    try {
      if (!administrativeLevel) return;
      const [zone, prevZone, { zones }, { zones: prevZones }] = await cancellablePromise(
        Promise.all([
          currentPartner.cyclabilityZoneId
            ? CyclabilityZoneService.getZone(currentPartner.cyclabilityZoneId, {
                period: period.values.current,
              })
            : null,
          currentPartner.cyclabilityZoneId
            ? CyclabilityZoneService.getZone(currentPartner.cyclabilityZoneId, {
                period: period.comparisonEnabled
                  ? period.values.prev
                  : period.values.current.getPrevPeriod(),
              })
            : null,
          CyclabilityZoneService.getZones({
            administrativeLevel: backendAdministrativeLevels[administrativeLevel],
            partnerCode: currentPartner.code,
            considerLivingStreets: true,
            period: period.values.current,
            rowsPerPage: 100,
            query: '{ id, code, name, administrative_level, geo_polygon_simplified, stats }',
          }),
          CyclabilityZoneService.getZones({
            administrativeLevel: backendAdministrativeLevels[administrativeLevel],
            partnerCode: currentPartner.code,
            considerLivingStreets: true,
            period: period.comparisonEnabled
              ? period.values.prev
              : period.values.current.getPrevPeriod(),
            rowsPerPage: 100,
            query: '{ id, code, name, administrative_level, stats }',
          }),
        ]),
      );

      setCyclabilityZone(zone);
      setPrevCyclabilityZone(prevZone);

      zones.forEach((zone) => {
        zonesMap[zone.id] = zone;
      });

      setCyclabilityZones(zones);
      setPrevCyclabilityZones(prevZones);
      setZonesMap({ ...zonesMap });
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.facilities.cyclability_zones.form.server_error'));
      }
    }
  }

  async function getChildrenZones(forceReload = false) {
    if (!selectedZone) return;

    if (selectedZone.administrativeLevel === 'city') {
      if (forceReload) {
        try {
          const [zone, prevZone] = await cancellablePromise(
            Promise.all([
              CyclabilityZoneService.getZone(selectedZone.id, {
                period: period.values.current,
                query: '{ id, code, name, administrative_level, stats }',
              }),
              CyclabilityZoneService.getZone(selectedZone.id, {
                period: period.comparisonEnabled
                  ? period.values.prev
                  : period.values.current.getPrevPeriod(),
                query: '{ id, code, name, administrative_level, stats }',
              }),
            ]),
          );

          setPrevCyclabilityZones([prevZone]);

          setCyclabilityZones([zone]);
          setZonesMap({ [zone.id]: zone });
        } catch {
          enqueueSnackbar(t('cycling-insights.facilities.cyclability_zones.form.server_error'));
        }
      } else {
        setCyclabilityZones([selectedZone]);
        setZonesMap({ [selectedZone.id]: selectedZone });

        setPrevCyclabilityZones(
          prevCyclabilityZones?.filter(({ id }) => id === selectedZone.id) || [],
        );
      }

      return;
    }

    const childAdministrativeLevel = childAdministrativeLevels[selectedZone.administrativeLevel];
    if (!childAdministrativeLevel || childAdministrativeLevel === 'world') return;

    try {
      const [zones, prevZones] = await cancellablePromise(
        Promise.all([
          CyclabilityZoneService.getChildrenZones(selectedZone.id, {
            administrativeLevel: backendAdministrativeLevels[childAdministrativeLevel],
            period: period.values.current,
            query: '{ id, code, name, administrative_level, geo_polygon_simplified, stats }',
          }),
          CyclabilityZoneService.getChildrenZones(selectedZone.id, {
            administrativeLevel: backendAdministrativeLevels[childAdministrativeLevel],
            period: period.comparisonEnabled
              ? period.values.prev
              : period.values.current.getPrevPeriod(),
            query: '{ id, code, name, administrative_level, stats }',
          }),
        ]),
      );

      setPrevCyclabilityZones(prevZones);

      zones.forEach((zone) => {
        if (zonesMap) zonesMap[zone.id] = zone;
      });

      setCyclabilityZones(zones);
      setZonesMap({ ...zonesMap });
      setPrevCyclabilityZones(prevCyclabilityZones);
    } catch {
      enqueueSnackbar(t('cycling-insights.facilities.cyclability_zones.form.server_error'));
    }
  }

  return { progressions };
}

export default useParkings;
