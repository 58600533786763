import { useOutletContext } from 'react-router-dom';

import PageContentLayout from '../../../../layouts/page/content';
import { IPromotionPageContext } from '../../context';

import WidgetData from './data';
import WidgetsForm from './form';

function WidgetsTab(): JSX.Element {
  const context = useOutletContext<IPromotionPageContext>();

  return (
    <PageContentLayout
      leftPanel={<WidgetsForm {...context} />}
      main={<WidgetData {...context} />}
    />
  );
}

export default WidgetsTab;
