import { Box, Divider, Paper as MuiPaper } from '@mui/material';
import { ReactNode } from 'react';

function Paper({
  header,
  disablePadding,
  children,
}: {
  children?: ReactNode;
  disablePadding?: boolean;
  header?: ReactNode;
}): JSX.Element {
  return (
    <MuiPaper
      elevation={0}
      sx={{ borderRadius: 4, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
    >
      {header && (
        <Box paddingX={3} paddingY={2}>
          {header}
        </Box>
      )}
      {children && (
        <>
          {header && <Divider />}
          <Box display="flex" flexDirection="column" gap={4} padding={disablePadding ? 0 : 3}>
            {children}
          </Box>
        </>
      )}
    </MuiPaper>
  );
}

export default Paper;
