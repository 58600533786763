import {
  Box,
  ButtonBase,
  Divider,
  IconButton,
  LinearProgress,
  Paper,
  Skeleton,
  SvgIcon,
  SvgIconProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { Fragment, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

interface IProps<T extends number | string = number> {
  action?: ReactNode;
  data?: {
    id?: T;
    title: string | ReactNode;
    subtitle?: ReactNode;
    value?: number;
    secondValue?: number;
  }[];
  disableValue?: boolean;
  disableProgression?: boolean;
  children?: ReactNode;
  enableSubtitles?: boolean;
  formatData?: (element: {
    id?: T;
    title: string | ReactNode;
    subtitle?: ReactNode;
    value?: number;
    secondValue?: number;
  }) => ReactNode;
  formatProgress?: (value: number) => ReactNode;
  hasPercentageBar?: boolean;
  loadingRowsCount?: number;
  onClick?: (id: T) => void;
  onSecondaryClick?: (id: T) => void;
  secondaryAction?: {
    tooltip: ReactNode;
    Icon: ((props: SvgIconProps) => JSX.Element) | typeof SvgIcon;
  };
  selectedKey?: T | null;
  subtitle?: ReactNode;
  subtitlesLinesCount?: number;
  startIcon?: ReactNode;
  title?: string;
  total?: number;
}

function Ranking<T extends number | string = number>({
  disableValue,
  disableProgression,
  title,
  action,
  subtitle,
  enableSubtitles,
  subtitlesLinesCount,
  hasPercentageBar,
  loadingRowsCount,
  data,
  startIcon,
  selectedKey,
  total,
  secondaryAction,
  children,
  formatData,
  formatProgress,
  onClick,
  onSecondaryClick,
}: IProps<T>): JSX.Element {
  return (
    <Paper elevation={0} sx={{ borderRadius: 4, width: '100%', overflow: 'hidden' }}>
      {(title || action || subtitle) && (
        <Box padding="24px 24px 8px">
          <Box alignItems="center" display="flex" gap={1} justifyContent="space-between">
            <Typography fontSize="1.125rem" fontWeight={700} whiteSpace="nowrap">
              <Trans i18nKey={title} />
            </Typography>
            {action}
          </Box>
          {subtitle}
        </Box>
      )}
      <Box display="flex" flexDirection="column">
        {data && data.length === 0 ? (
          <Typography
            overflow="hidden"
            paddingX={3}
            paddingY={2}
            textOverflow="ellipsis"
            variant="body2"
            whiteSpace="nowrap"
          >
            <Trans i18nKey="commons.no_filtered_data" />
          </Typography>
        ) : (
          <>
            {(data || new Array(loadingRowsCount || 3).fill(null).map((_, index) => index + 1)).map(
              (element, index) => {
                const selected = typeof element !== 'number' && element.id === selectedKey;

                return (
                  <Fragment key={index}>
                    {index > 0 && <Divider />}
                    <Box display="flex" position="relative">
                      <Box
                        alignItems="center"
                        bgcolor={selected ? '#F2F7FF' : undefined}
                        component={
                          typeof element !== 'number' && element.id !== undefined && onClick
                            ? ButtonBase
                            : undefined
                        }
                        display="flex"
                        flexGrow={1}
                        gap={2}
                        onClick={() =>
                          typeof element !== 'number' &&
                          element.id !== undefined &&
                          onClick?.(element.id)
                        }
                        paddingLeft={3}
                        paddingRight={secondaryAction ? 8 : 3}
                        paddingY={2}
                        textAlign="left"
                      >
                        {startIcon}
                        <Box display="flex" flexDirection="column" flexGrow={1} overflow="hidden">
                          {typeof element === 'number' ? (
                            <Typography>
                              <Skeleton sx={{ maxWidth: '100%' }} variant="text" width={200} />
                            </Typography>
                          ) : (
                            <>
                              {typeof element.title === 'string' ? (
                                <Typography
                                  color={selected ? '#3E7BDF' : undefined}
                                  fontSize="1rem"
                                  fontWeight={600}
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  title={(typeof element !== 'number' && element.title) || ''}
                                  whiteSpace="nowrap"
                                  width="100%"
                                >
                                  {element.title}
                                </Typography>
                              ) : (
                                <Box display="flex" width="100%">
                                  {element.title}
                                </Box>
                              )}
                            </>
                          )}
                          {enableSubtitles && (typeof element === 'number' || element.subtitle) && (
                            <Typography
                              color="textSecondary"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              variant="body2"
                              width="100%"
                            >
                              {typeof element !== 'number' ? (
                                element.subtitle
                              ) : (
                                <>
                                  {new Array(subtitlesLinesCount || 1).fill(null).map((_, key) => (
                                    <Skeleton
                                      key={key}
                                      sx={{ display: 'block', maxWidth: '100%' }}
                                      variant="text"
                                      width={150}
                                    />
                                  ))}
                                </>
                              )}
                            </Typography>
                          )}
                        </Box>
                        {hasPercentageBar &&
                          typeof element !== 'number' &&
                          element.value !== undefined &&
                          total && (
                            <StyledLinearProgress
                              value={(100 * element.value) / total}
                              variant="determinate"
                            />
                          )}
                        {!disableValue && (
                          <>
                            {disableProgression ? (
                              <>
                                {typeof element !== 'number' ? (
                                  element.value !== undefined && (
                                    <>
                                      {formatData?.(element) || (
                                        <Typography
                                          fontWeight={600}
                                          minWidth={50}
                                          textAlign="end"
                                          variant="body2"
                                        >
                                          {element.value}
                                        </Typography>
                                      )}
                                    </>
                                  )
                                ) : (
                                  <Typography fontWeight={600} variant="body2">
                                    <Skeleton variant="text" width={50} />
                                  </Typography>
                                )}
                              </>
                            ) : (
                              <>
                                {typeof element !== 'number' ? (
                                  element.value !== undefined && (
                                    <Box
                                      bgcolor={element.value < 0 ? '#ffebee' : '#eef8f4'}
                                      borderRadius={1}
                                      color={element.value < 0 ? '#a42c49' : '#038b63'}
                                      paddingX={1}
                                      paddingY={0.5}
                                    >
                                      <Typography
                                        fontWeight={600}
                                        minWidth={50}
                                        textAlign="end"
                                        variant="body2"
                                      >
                                        {formatProgress?.(element.value) || element.value}
                                      </Typography>
                                    </Box>
                                  )
                                ) : (
                                  <Typography fontWeight={600} variant="body2">
                                    <Skeleton variant="text" width={50} />
                                  </Typography>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </Box>
                      {secondaryAction && (
                        <Box
                          alignItems="center"
                          display="flex"
                          height="100%"
                          paddingX={2}
                          position="absolute"
                          right={0}
                          top={0}
                        >
                          {typeof element !== 'number' ? (
                            <Tooltip placement="left" title={secondaryAction.tooltip}>
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  if (element.id !== undefined && onSecondaryClick)
                                    onSecondaryClick(element.id);
                                }}
                                size="small"
                              >
                                <secondaryAction.Icon color="action" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Skeleton height={34} variant="circular" width={34} />
                          )}
                        </Box>
                      )}
                    </Box>
                  </Fragment>
                );
              },
            )}
          </>
        )}
      </Box>
      {children}
    </Paper>
  );
}

const StyledLinearProgress = styled(LinearProgress)`
  && {
    flex-shrink: 0;
    width: 112px;
    min-width: 112px;
    height: 8px;
    border-radius: 8px;
    background-color: #e3e7ee;

    > .MuiLinearProgress-bar {
      background-color: #3e7bdf;
      border-radius: 8px;
    }
  }
`;

export default Ranking;
