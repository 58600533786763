import {
  IRideStep,
  Ride,
  RideService,
  TBackendRideMediaType,
  TRideMediaType,
  mediaTypesMap,
} from '@geovelo-frontends/commons';
import { Delete, Link as LinkIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';

import Button from '../../../../components/button';

interface IValues {
  type: TRideMediaType;
  title: string;
  url: string;
  description: string;
  copyright: string;
}

type TProps = {
  canWrite: boolean;
  onChange: (ride: Ride) => void;
  onClose: () => void;
  ride: Ride;
  step: IRideStep | null;
};

function RideStepMediasContent({ canWrite, ride, step, onChange, onClose }: TProps): JSX.Element {
  const [_loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { palette, transitions } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { isSubmitting, values, touched, errors, setValues, handleChange, handleSubmit } =
    useFormik<IValues>({
      initialValues: {
        type: 'file',
        title: '',
        url: '',
        description: '',
        copyright: '',
      },
      validationSchema: Yup.object().shape({
        url: Yup.string().url().required(),
      }),
      onSubmit,
      enableReinitialize: true,
    });

  useEffect(() => {
    setTimeout(() => {
      setValues({
        type: 'file',
        title: '',
        url: '',
        description: '',
        copyright: '',
      });
    }, transitions.duration.leavingScreen);
  }, []);

  async function onSubmit({ type, title, url, description, copyright }: IValues) {
    if (!ride.id || !step || !step.id) return;

    try {
      const index = ride.steps.findIndex(({ id }) => id === step.id);

      const types: { [key in TRideMediaType]: TBackendRideMediaType } = {
        audio: 'AUDIO',
        file: 'FILE',
        image: 'IMAGE',
        pdf: 'PDF',
        video: 'VIDEO',
        website: 'WEBSITE',
      };

      const media = await RideService.addRideStepMedia(ride.id, step.id, {
        type: types[type],
        title,
        url,
        description,
        copyright,
      });

      const updatedRide = ride.clone();
      updatedRide.steps[index].medias.push(media);

      onChange(updatedRide);
      setValues({
        type: 'file',
        title: '',
        url: '',
        description: '',
        copyright: '',
      });
      enqueueSnackbar(t('cycling-insights.ride.updated'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.not_updated'), { variant: 'error' });
    }
  }

  async function handleRemove(mediaId: number) {
    if (!ride.id || !step || !step.id) return;

    setLoading(true);

    try {
      const stepIndex = ride.steps.findIndex(({ id }) => id === step.id);
      const index = ride.steps[stepIndex].medias.findIndex(({ id }) => id === mediaId);

      await RideService.removeRideStepMedia(ride.id, step.id, mediaId);

      const updatedRide = ride.clone();
      updatedRide.steps[stepIndex].medias.splice(index, 1);

      onChange(updatedRide);
      enqueueSnackbar(t('cycling-insights.ride.updated'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.not_updated'), { variant: 'error' });
    }

    setLoading(false);
  }

  const loading = _loading || isSubmitting;

  return (
    <>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={3}>
          {canWrite && (
            <>
              <Card variant="outlined">
                <CardHeader
                  subheader={
                    <Trans i18nKey="cycling-insights.ride.steps.medias_content.new_media" />
                  }
                />
                <form onSubmit={handleSubmit}>
                  <CardContent>
                    <FormControl
                      fullWidth
                      disabled={isSubmitting}
                      margin="dense"
                      variant="outlined"
                    >
                      <InputLabel htmlFor="media-type-input">
                        <Trans i18nKey="cycling-insights.ride.media_form.type" />
                      </InputLabel>
                      <Select
                        inputProps={{
                          id: 'media-type-input',
                        }}
                        label={<Trans i18nKey="cycling-insights.ride.media_form.type" />}
                        name="type"
                        onChange={handleChange}
                        size="small"
                        value={values.type}
                      >
                        {mediaTypesMap.map(({ key, labelKey }) => (
                          <MenuItem key={key} value={key}>
                            <Trans i18nKey={labelKey} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      disabled={isSubmitting}
                      error={touched.title && Boolean(errors.title)}
                      id="title"
                      label={t('cycling-insights.ride.media_form.media_title')}
                      margin="dense"
                      name="title"
                      onChange={handleChange}
                      size="small"
                      value={values.title}
                      variant="outlined"
                    />
                    <TextField
                      fullWidth
                      required
                      disabled={isSubmitting}
                      error={touched.url && Boolean(errors.url)}
                      id="url"
                      label={t('cycling-insights.ride.media_form.url')}
                      margin="dense"
                      name="url"
                      onChange={handleChange}
                      size="small"
                      type="url"
                      value={values.url}
                      variant="outlined"
                    />
                    <TextField
                      fullWidth
                      multiline
                      disabled={isSubmitting}
                      error={touched.description && Boolean(errors.description)}
                      id="description"
                      label={t('commons.description')}
                      margin="dense"
                      name="description"
                      onChange={handleChange}
                      rows={3}
                      value={values.description}
                      variant="outlined"
                    />
                    <TextField
                      fullWidth
                      disabled={isSubmitting}
                      error={touched.copyright && Boolean(errors.copyright)}
                      id="copyright"
                      label={t('commons.copyright')}
                      margin="dense"
                      name="copyright"
                      onChange={handleChange}
                      size="small"
                      value={values.copyright}
                      variant="outlined"
                    />
                  </CardContent>
                  <CardActions style={{ justifyContent: 'flex-end' }}>
                    <Button
                      color="primary"
                      disabled={loading}
                      size="medium"
                      type="submit"
                      variant="contained"
                    >
                      <Trans i18nKey="commons.actions.add" />
                    </Button>
                  </CardActions>
                </form>
              </Card>
            </>
          )}
          <Box>
            {canWrite && (
              <Typography gutterBottom color="textSecondary">
                <Trans i18nKey="cycling-insights.ride.steps.medias_content.medias" />
              </Typography>
            )}
            <List dense>
              {step &&
                (step.medias.length > 0 ? (
                  step.medias.map(({ id, title, url, copyright, type }, index) => {
                    const mediaType = mediaTypesMap.find(({ key }) => type === key);

                    return (
                      <Fragment key={id}>
                        {index > 0 && <Divider />}
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar style={{ backgroundColor: palette.primary.main }}>
                              {mediaType ? <mediaType.Icon /> : <></>}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              title || (
                                <Trans
                                  i18nKey="cycling-insights.ride.media_list.item_title"
                                  values={{ index: index + 1 }}
                                />
                              )
                            }
                            secondary={copyright && <>&copy; {copyright}</>}
                          />
                          <StyledListItemSecondaryAction>
                            <Tooltip
                              placement="left"
                              title={<Trans i18nKey="commons.actions.open_website" />}
                            >
                              <IconButton component="a" href={url} size="small" target="_blank">
                                <LinkIcon />
                              </IconButton>
                            </Tooltip>
                            {canWrite && (
                              <Tooltip
                                placement="left"
                                title={<Trans i18nKey="commons.actions.remove" />}
                              >
                                <IconButton onClick={() => id && handleRemove(id)} size="small">
                                  <Delete color="error" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </StyledListItemSecondaryAction>
                        </ListItem>
                      </Fragment>
                    );
                  })
                ) : (
                  <Typography color="textSecondary" variant="caption">
                    <Trans i18nKey="cycling-insights.ride.steps.medias_content.empty" />
                  </Typography>
                ))}
            </List>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={() => onClose()} size="large" variant="outlined">
          <Trans i18nKey="commons.actions.close" />
        </Button>
      </DialogActions>
    </>
  );
}

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)`
  button,
  a {
    margin-left: 8px;
  }
`;

export default RideStepMediasContent;
