import { useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { TCartographicDataPageContext } from '../../context';

import FacilitiesSuggestionsForm from './form';

function FacilitiesSuggestionsFormTab(): JSX.Element {
  const context = useOutletContext<TCartographicDataPageContext & TOutletContext>();
  const {
    header: { setPrevButtonClick },
    facilitiesSuggestions: { selectSuggestion },
  } = context;
  const navigate = useNavigate();

  useEffect(() => {
    setPrevButtonClick(() => () => {
      selectSuggestion(null);
      navigate('../facilities-suggestions');
    });

    return () => {
      setPrevButtonClick(undefined);
    };
  }, []);

  return <PageContentLayout leftPanel={<FacilitiesSuggestionsForm {...context} />} />;
}

export default FacilitiesSuggestionsFormTab;
