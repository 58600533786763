import { CondensedRideTrace, Ride } from '@geovelo-frontends/commons';
import { Add, Delete } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

interface IProps {
  anchorEl: HTMLButtonElement | null;
  loading: boolean;
  onAdd: () => void;
  onClose: () => void;
  onRemove: (id: number) => void;
  ride: Ride;
  traces: CondensedRideTrace[];
}

function TracesMenu({
  loading,
  traces,
  ride,
  anchorEl,
  onAdd,
  onRemove,
  onClose,
}: IProps): JSX.Element {
  function handleAdd() {
    onAdd();
    onClose();
  }

  const rideTraces = traces.filter(({ id }) => ride.traceIds.indexOf(id) > -1);

  return (
    <Menu
      keepMounted
      anchorEl={anchorEl}
      id="ride-traces-menu"
      MenuListProps={{ style: { width: 250 } }}
      onClose={onClose}
      open={Boolean(anchorEl)}
    >
      {rideTraces.map(({ id, title }) => (
        <StyledMenuItem dense key={id}>
          <ListItemText
            primary={
              <Typography noWrap style={{ fontSize: 'inherit' }}>
                {title}
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <IconButton disabled={loading} onClick={() => onRemove(id)} size="small">
              <Delete color={loading ? 'disabled' : 'error'} />
            </IconButton>
          </ListItemSecondaryAction>
        </StyledMenuItem>
      ))}
      {rideTraces.length > 0 && <Divider />}
      <MenuItem dense disabled={loading} onClick={handleAdd}>
        <ListItemIcon>
          <Add color="primary" />
        </ListItemIcon>
        <ListItemText primary={<Trans i18nKey="cycling-insights.ride.route.actions.add_trace" />} />
      </MenuItem>
    </Menu>
  );
}

const StyledMenuItem = styled(MenuItem)`
  && {
    cursor: initial;

    > .MuiTouchRipple-root {
      display: none;
    }

    :hover {
      background-color: initial;
    }
  }
`;

export default TracesMenu;
