import { Place, ReportService } from '@geovelo-frontends/commons';
import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { gpx } from '@tmcw/togeojson';
import area from '@turf/area';
import center from '@turf/center';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import ConfirmDialog from '../../../../components/confirm-dialog';
import useDraw from '../../../../hooks/map/draw';
import { TOutletContext } from '../../../../layouts/page/container';
import { TQAPageContext } from '../../context';

import { LngLatBounds } from '!maplibre-gl';

const maxExclusionZoneSurface = 3000000;

type TZoneType = 'polygon' | 'line';

function ExclusionZonesForm({
  exclusionZones: { importedFile, selectedZone, selectZone },
}: TQAPageContext & TOutletContext): JSX.Element {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [updateTypeDialogOpen, openUpdateTypeDialog] = useState(false);
  const activeStepRef = useRef(0);
  const {
    map: { current: currentMap },
    report: { types: reportTypes },
    actions: { getReportTypes },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [zoneName, setZoneName] = useState<string>();
  const [zoneType, setZoneType] = useState<TZoneType>(
    selectedZone?.geometry?.type === 'MultiLineString' ? 'line' : 'polygon',
  );
  const {
    init: initDraw,
    destroy: destroyDraw,
    enableDrawing,
    drawings,
    handleDelete,
    secondaryDrawings,
    setBaseZone,
    setDrawings,
    swapDrawingZones,
  } = useDraw(currentMap, { drawType: zoneType });
  const tempZoneType = useRef<TZoneType>('polygon');
  const [period, setPeriod] = useState<{ startDate: Moment; endDate: Moment }>({
    startDate: moment(),
    endDate: moment().add(14, 'day'),
  });
  const [editingDropZone, setEditingDropZone] = useState<GeoJSON.GeoJsonProperties>();
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [capacity, setCapacity] = useState<number>();
  const [authorizedZoneSurface, authorizeZoneSurface] = useState<boolean>(false);

  useEffect(() => {
    try {
      getReportTypes();
    } catch (err) {
      enqueueSnackbar(t('cycling-insights.reports.types.server_error'), { variant: 'error' });
    }

    return () => destroyDraw();
  }, []);

  useEffect(() => {
    if (selectedZone) {
      setZoneType(selectedZone.geometry?.type === 'MultiLineString' ? 'line' : 'polygon');
      setZoneName(selectedZone.description);
      if (selectedZone.startDate && selectedZone.endDate)
        setPeriod({ startDate: selectedZone.startDate, endDate: selectedZone.endDate });
      const bounds = new LngLatBounds();
      if (selectedZone.geometry?.type === 'MultiLineString') {
        const points: Place[][] = [];
        selectedZone.geometry?.coordinates.forEach((line) => {
          points.push([]);
          line.forEach(([lng, lat]) => {
            points[points.length - 1].push(
              new Place(undefined, { type: 'Point', coordinates: [lng, lat] }),
            );
            bounds.extend([lng, lat]);
          });
        });
        setBaseZone(points, 'line', selectedZone.dropZones);
      } else {
        const points: Place[][] = [];
        selectedZone.geometry?.coordinates.forEach((ele) => {
          ele.forEach((polygon) => {
            points.push([]);
            polygon.forEach(([lng, lat]) => {
              points[points.length - 1].push(
                new Place(undefined, { type: 'Point', coordinates: [lng, lat] }),
              );
              bounds.extend([lng, lat]);
            });
          });
        });
        setBaseZone(points, 'polygon', selectedZone.dropZones);
      }
      currentMap?.fitBounds(bounds);
    }
  }, [selectedZone]);

  useEffect(() => {
    if (currentMap) initDraw();
  }, [currentMap]);

  useEffect(() => {
    getFileContext();
  }, [importedFile]);

  useEffect(() => {
    if (currentMap && (activeStep === 2 || activeStep === 3)) enableDrawing(true);

    return () => enableDrawing(false);
  }, [currentMap, activeStep]);

  useEffect(() => {
    if (activeStep <= 2 && drawings && drawings.features.length > 0) {
      const authorization =
        area({
          type: 'Polygon',
          coordinates: [drawings.features[0].geometry.coordinates],
        } as GeoJSON.Polygon) < maxExclusionZoneSurface;
      authorizeZoneSurface(authorization);
      if (!authorization)
        enqueueSnackbar(t('cycling-insights.qa.exclusion_zones.too_large'), { variant: 'error' });
    }
  }, [drawings]);

  async function getFileContext() {
    if (importedFile instanceof File) {
      let collection: GeoJSON.FeatureCollection | undefined;

      if (importedFile.name.includes('.geojson')) {
        collection = JSON.parse(await importedFile.text());
      } else if (importedFile.name.includes('.gpx')) {
        const text = await importedFile.text();
        collection = gpx(new DOMParser().parseFromString(text, 'text/xml'));
      }

      if (
        collection &&
        'type' in collection &&
        collection.type === 'FeatureCollection' &&
        'features' in collection &&
        Array.isArray(collection.features)
      ) {
        const bounds = new LngLatBounds();
        const firstFeature = collection.features[0];

        if (firstFeature && 'geometry' in firstFeature) {
          let points: Place[][] = [];
          if (firstFeature.geometry.type === 'MultiPolygon') {
            points = (
              firstFeature as GeoJSON.Feature<GeoJSON.MultiPolygon>
            ).geometry.coordinates.reduce<Place[][]>((res, multipolygon) => {
              const places: Place[] = [];
              multipolygon.forEach((polygon) => {
                polygon.forEach((coordinates) => {
                  places.push(new Place(undefined, { type: 'Point', coordinates }));
                  bounds.extend([coordinates[0], coordinates[1]]);
                });
              });
              res.push(places);

              return res;
            }, []);

            setBaseZone(points, 'polygon');
          } else if (firstFeature.geometry.type === 'Polygon') {
            points = (firstFeature as GeoJSON.Feature<GeoJSON.Polygon>).geometry.coordinates.reduce<
              Place[][]
            >((res, polygon) => {
              const places: Place[] = [];
              polygon.forEach((coordinates) => {
                places.push(new Place(undefined, { type: 'Point', coordinates }));
                bounds.extend([coordinates[0], coordinates[1]]);
              });
              res.push(places);

              return res;
            }, []);

            setBaseZone(points, 'polygon');
          } else if (firstFeature.geometry.type === 'MultiLineString') {
            points = (
              firstFeature as GeoJSON.Feature<GeoJSON.MultiLineString>
            ).geometry.coordinates.reduce<Place[][]>((res, line) => {
              const places: Place[] = [];
              line.forEach((coordinates) => {
                places.push(new Place(undefined, { type: 'Point', coordinates }));
                bounds.extend([coordinates[0], coordinates[1]]);
              });
              res.push(places);

              return res;
            }, []);

            setBaseZone(points, 'line');
          } else if (firstFeature.geometry.type === 'LineString') {
            points = [
              (firstFeature as GeoJSON.Feature<GeoJSON.LineString>).geometry.coordinates.reduce<
                Place[]
              >((res, coordinates) => {
                res.push(new Place(undefined, { type: 'Point', coordinates }));
                bounds.extend([coordinates[0], coordinates[1]]);

                return res;
              }, []),
            ];

            setBaseZone(points, 'line');
          }
        }

        currentMap?.fitBounds(bounds);
      }
    }
  }

  async function sendReport() {
    const type = reportTypes?.find(({ code }) => code === 'exclusionZone');
    if (!type || !secondaryDrawings) {
      enqueueSnackbar(t('cycling-insights.qa.exclusion_zones.server_error'), {
        variant: 'error',
      });
      return;
    }
    try {
      let reportId: number;
      if (selectedZone) {
        await ReportService.updateReport(selectedZone.id, {
          description: zoneName || '',
          scheduleDates: [period.startDate, period.endDate],
          geometry:
            secondaryDrawings.features[0].geometry.type === 'MultiLineString'
              ? secondaryDrawings.features[0].geometry
              : {
                  type: 'MultiPolygon',
                  coordinates: secondaryDrawings.features.map(({ geometry }) => [
                    (geometry as GeoJSON.LineString).coordinates,
                  ]),
                },
          point: center(secondaryDrawings).geometry,
        });
        reportId = selectedZone.id;
      } else {
        const { id } = await ReportService.addReport({
          description: zoneName || '',
          type: type.id,
          scheduleDates: [period.startDate, period.endDate],
          geometry:
            secondaryDrawings.features[0].geometry.type === 'MultiLineString'
              ? secondaryDrawings.features[0].geometry
              : {
                  type: 'MultiPolygon',
                  coordinates: secondaryDrawings.features.map(({ geometry }) => [
                    (geometry as GeoJSON.LineString).coordinates,
                  ]),
                },
          point: center(secondaryDrawings).geometry,
        });
        reportId = id;
      }
      if (drawings) {
        await Promise.all([
          ...drawings.features.map((feature) => {
            if (selectedZone?.dropZones?.find(({ id }) => id === feature.properties?.polygonIndex))
              return ReportService.updateDropZone({
                reportId,
                dropZoneId: feature.properties?.polygonIndex,
                geometry: {
                  type: 'MultiPolygon',
                  coordinates: [[(feature.geometry as GeoJSON.LineString).coordinates]],
                },
                point: center({
                  type: 'Polygon',
                  coordinates: [(feature.geometry as GeoJSON.LineString).coordinates],
                }).geometry,
                capacity: feature.properties?.capacity,
                description: feature.properties?.description,
                title: feature.properties?.title,
              });
            else
              return ReportService.addDropZone({
                reportId,
                geometry: {
                  type: 'MultiPolygon',
                  coordinates: [[(feature.geometry as GeoJSON.LineString).coordinates]],
                },
                point: center({
                  type: 'Polygon',
                  coordinates: [(feature.geometry as GeoJSON.LineString).coordinates],
                }).geometry,
                capacity: feature.properties?.capacity,
                description: feature.properties?.description,
                title: feature.properties?.title,
              });
          }),
          ...(selectedZone?.dropZones || [])
            .filter(
              ({ id }) =>
                !drawings.features.find((feature) => id === feature.properties?.polygonIndex),
            )
            .map(({ id }) => ReportService.deleteDropZone(reportId, id)),
        ]);
      }
      enqueueSnackbar(
        t(`cycling-insights.qa.exclusion_zones.${selectedZone ? 'updated' : 'added'}`),
        {
          variant: 'success',
        },
      );
      selectZone();
      navigate('../exclusion-zones');
    } catch (err) {
      enqueueSnackbar(t('cycling-insights.qa.exclusion_zones.server_error'), {
        variant: 'error',
      });
    }
  }

  function updateDropZone() {
    if (!editingDropZone || !drawings) return;
    setDrawings({
      type: 'FeatureCollection',
      features: drawings.features.map((feature) => {
        if (feature.properties?.polygonIndex === editingDropZone.polygonIndex)
          return {
            type: 'Feature',
            geometry: feature.geometry,
            properties: {
              polygonIndex: editingDropZone?.polygonIndex,
              capacity,
              title,
              description,
            },
          };
        else return feature;
      }),
    });
    setEditingDropZone(undefined);
    setTitle(undefined);
    setDescription(undefined);
    setCapacity(undefined);
  }

  return (
    <>
      <Box display="flex" flexDirection="column" minHeight="calc(100% - 32px)">
        <Box padding={2}>
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step>
              <StepLabel>
                <Trans i18nKey="cycling-insights.qa.exclusion_zones.actions.zone_name" />
              </StepLabel>
              <StepContent>
                <TextField
                  fullWidth
                  required
                  margin="dense"
                  onChange={(event) => setZoneName(event.target.value)}
                  size="small"
                  value={zoneName}
                  variant="outlined"
                />
                <StyledStepActions>
                  <Button
                    color="primary"
                    disabled={!zoneName || zoneName === ''}
                    onClick={() => {
                      setActiveStep(activeStep + 1);
                      activeStepRef.current += 1;
                    }}
                    variant="contained"
                  >
                    <Trans i18nKey="commons.actions.next" />
                  </Button>
                </StyledStepActions>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <Trans i18nKey="cycling-insights.qa.exclusion_zones.actions.zone_type" />
              </StepLabel>
              <StepContent>
                <RadioGroup
                  onChange={({ target: { value } }) => {
                    if (drawings) {
                      tempZoneType.current = value as TZoneType;
                      openUpdateTypeDialog(true);
                    } else setZoneType(value as TZoneType);
                  }}
                  value={zoneType}
                >
                  <FormControlLabel
                    control={<Radio color="primary" size="small" />}
                    label={<Trans i18nKey="cycling-insights.qa.exclusion_zones.polygon" />}
                    value="polygon"
                  />
                  <FormControlLabel
                    control={<Radio color="primary" size="small" />}
                    label={<Trans i18nKey="cycling-insights.qa.exclusion_zones.line" />}
                    value="line"
                  />
                </RadioGroup>
                <StyledStepActions>
                  <Button
                    color="primary"
                    onClick={() => {
                      setActiveStep(activeStep - 1);
                      activeStepRef.current -= 1;
                    }}
                    variant="outlined"
                  >
                    <Trans i18nKey="commons.actions.prev" />
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      setActiveStep(activeStep + 1);
                      activeStepRef.current += 1;
                    }}
                    variant="contained"
                  >
                    <Trans i18nKey="commons.actions.next" />
                  </Button>
                </StyledStepActions>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <Trans i18nKey="cycling-insights.qa.exclusion_zones.actions.define_zones" />
              </StepLabel>
              <StepContent>
                <Trans
                  count={drawings ? drawings.features.length : 0}
                  i18nKey="cycling-insights.qa.exclusion_zones.zones"
                  values={{ count: drawings ? drawings.features.length : 0 }}
                />
                <StyledStepActions>
                  <Button
                    color="primary"
                    onClick={() => {
                      setActiveStep(activeStep - 1);
                      activeStepRef.current -= 1;
                    }}
                    variant="outlined"
                  >
                    <Trans i18nKey="commons.actions.prev" />
                  </Button>
                  <Button
                    color="primary"
                    disabled={!drawings || drawings.features.length === 0 || !authorizedZoneSurface}
                    onClick={() => {
                      setActiveStep(activeStep + 1);
                      activeStepRef.current += 1;
                      swapDrawingZones({ drawTypeForced: 'polygon', allowMultiPolygon: true });
                    }}
                    variant="contained"
                  >
                    <Trans i18nKey="commons.actions.next" />
                  </Button>
                </StyledStepActions>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <Trans i18nKey="cycling-insights.qa.exclusion_zones.actions.choose_parkings" />
              </StepLabel>
              <StepContent>
                <List>
                  {drawings && drawings.features.length > 0 ? (
                    <>
                      {drawings.features.map(({ properties }, index) => (
                        <>
                          <ListItem
                            key={properties?.polygonIndex}
                            secondaryAction={
                              <Box>
                                <IconButton
                                  disabled={editingDropZone !== undefined}
                                  onClick={() => {
                                    setTitle(properties?.title);
                                    setDescription(properties?.description);
                                    setCapacity(properties?.capacity);
                                    setEditingDropZone(properties);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    setTitle(undefined);
                                    setDescription(undefined);
                                    setCapacity(undefined);
                                    setEditingDropZone(undefined);
                                    handleDelete(undefined, properties?.polygonIndex);
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              </Box>
                            }
                          >
                            <ListItemText
                              primary={
                                properties?.title ||
                                t('cycling-insights.qa.exclusion_zones.drop_zone', {
                                  count: properties?.polygonIndex,
                                })
                              }
                            />
                          </ListItem>
                          <Collapse
                            unmountOnExit
                            in={
                              !!editingDropZone &&
                              properties?.polygonIndex === editingDropZone?.polygonIndex
                            }
                          >
                            <Box display="flex" flexDirection="column" gap={1}>
                              <TextField
                                fullWidth
                                margin="none"
                                onChange={({ target: { value } }) => setTitle(value)}
                                placeholder={t('commons.title')}
                                size="small"
                                value={title}
                              />
                              <TextField
                                fullWidth
                                margin="none"
                                onChange={({ target: { value } }) => setDescription(value)}
                                placeholder={t('commons.description')}
                                size="small"
                                value={description}
                              />
                              <TextField
                                fullWidth
                                margin="none"
                                onChange={({ target: { value } }) => setCapacity(parseInt(value))}
                                placeholder={t('cycling-insights.qa.exclusion_zones.capacity')}
                                size="small"
                                type="number"
                                value={capacity}
                              />
                              <Box display="flex" gap={2} justifyContent="flex-end">
                                <Button
                                  onClick={() => updateDropZone()}
                                  size="small"
                                  variant="contained"
                                >
                                  <Trans i18nKey="commons.actions.validate" />
                                </Button>
                                <Button
                                  onClick={() => {
                                    setEditingDropZone(undefined);
                                    setTitle(undefined);
                                    setDescription(undefined);
                                    setCapacity(undefined);
                                  }}
                                  size="small"
                                  variant="outlined"
                                >
                                  <Trans i18nKey="commons.actions.cancel" />
                                </Button>
                              </Box>
                            </Box>
                          </Collapse>
                          {index !== drawings.features.length && <Divider sx={{ marginY: 1 }} />}
                        </>
                      ))}
                    </>
                  ) : (
                    <ListItem>
                      <ListItemText
                        primary={<Trans i18nKey="cycling-insights.qa.exclusion_zones.no_parking" />}
                      />
                    </ListItem>
                  )}
                </List>
                <StyledStepActions>
                  <Button
                    color="primary"
                    onClick={() => {
                      setActiveStep(activeStep - 1);
                      activeStepRef.current -= 1;
                      swapDrawingZones({ allowMultiPolygon: false });
                    }}
                    variant="outlined"
                  >
                    <Trans i18nKey="commons.actions.prev" />
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      setActiveStep(activeStep + 1);
                      activeStepRef.current += 1;
                    }}
                    variant="contained"
                  >
                    <Trans i18nKey="commons.actions.next" />
                  </Button>
                </StyledStepActions>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <Trans i18nKey="cycling-insights.qa.exclusion_zones.actions.choose_period" />
              </StepLabel>
              <StepContent>
                <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterMoment}>
                  <Box display="flex" flexDirection="column" gap={1} marginY={2}>
                    <Typography color="textSecondary" variant="body2">
                      <Trans i18nKey="cycling-insights.qa.exclusion_zones.start_date" />
                    </Typography>
                    <DateTimePicker
                      maxDate={moment().add(1, 'year').endOf('year')}
                      onChange={(newValue) =>
                        setPeriod({ startDate: newValue || moment(), endDate: period.endDate })
                      }
                      value={period.startDate}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Typography color="textSecondary" variant="body2">
                      <Trans i18nKey="cycling-insights.qa.exclusion_zones.end_date" />
                    </Typography>
                    <DateTimePicker
                      maxDate={moment().add(1, 'year').endOf('year')}
                      onChange={(newValue) =>
                        setPeriod({
                          startDate: period.startDate,
                          endDate: newValue || moment().add(14, 'day'),
                        })
                      }
                      value={period.endDate}
                    />
                  </Box>
                </LocalizationProvider>
                <StyledStepActions>
                  <Button
                    color="primary"
                    onClick={() => {
                      setActiveStep(activeStep - 1);
                      activeStepRef.current -= 1;
                    }}
                    variant="outlined"
                  >
                    <Trans i18nKey="commons.actions.prev" />
                  </Button>
                  <Button color="primary" onClick={sendReport} variant="contained">
                    <Trans i18nKey="commons.actions.validate" />
                  </Button>
                </StyledStepActions>
              </StepContent>
            </Step>
          </Stepper>
        </Box>
      </Box>
      <ConfirmDialog
        description={<></>}
        dialogTitle="exclusion-zone-type-change-dialog"
        loading={false}
        onCancel={() => openUpdateTypeDialog(false)}
        onConfirm={() => {
          setBaseZone();
          setZoneType(tempZoneType.current);
          openUpdateTypeDialog(false);
        }}
        open={updateTypeDialogOpen}
        title={<Trans i18nKey="cycling-insights.qa.exclusion_zones.update_type" />}
      />
    </>
  );
}

const StyledStepActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  > * {
    &:last-child {
      margin-left: 8px;
    }
  }
`;

export default ExclusionZonesForm;
