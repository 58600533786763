import { useOutletContext } from 'react-router-dom';

import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { IBicycleObservatoryPageContext } from '../../context';

import FluidityForm from './form';

function FluidityAnalysisTab(): JSX.Element {
  const context = useOutletContext<IBicycleObservatoryPageContext & TOutletContext>();

  return <PageContentLayout leftPanel={<FluidityForm {...context} />} />;
}

export default FluidityAnalysisTab;
