import { FileInput, IRideStep, Ride, RideService, TFile } from '@geovelo-frontends/commons';
import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from '../../../../components/button';

interface IValues {
  copyright: string;
}

type TProps = {
  canWrite: boolean;
  onChange: (ride: Ride) => void;
  onClose: () => void;
  ride: Ride;
  step: IRideStep | null;
};

function RideStepImagesContent({ canWrite, ride, step, onChange, onClose }: TProps): JSX.Element {
  const [file, setFile] = useState<TFile>();
  const [error, setError] = useState(false);
  const [_loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { transitions } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { isSubmitting, values, touched, errors, setValues, handleChange, handleSubmit } =
    useFormik<IValues>({
      initialValues: {
        copyright: '',
      },
      onSubmit,
      enableReinitialize: true,
    });

  useEffect(() => {
    setTimeout(() => {
      setValues({
        copyright: '',
      });
      setFile(undefined);
      setError(false);
    }, transitions.duration.leavingScreen);
  }, []);

  async function onSubmit({ copyright }: IValues) {
    if (!ride.id || !step || !step.id) return;

    if (!(file instanceof File)) {
      setError(true);

      return;
    }

    setError(false);

    try {
      if (!(file instanceof File)) return;

      const index = ride.steps.findIndex(({ id }) => id === step.id);
      const photo = await RideService.addRideStepPhoto(ride.id, step.id, {
        copyright,
        file,
      });

      const updatedRide = ride.clone();
      updatedRide.steps[index].photos.push(photo);

      onChange(updatedRide);
      setValues({
        copyright: '',
      });
      setFile(undefined);
      enqueueSnackbar(t('cycling-insights.ride.updated'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.not_updated'), { variant: 'error' });
    }
  }

  async function handleRemove(photoId: number) {
    if (!ride.id || !step || !step.id) return;

    setLoading(true);

    try {
      const stepIndex = ride.steps.findIndex(({ id }) => id === step.id);
      const index = ride.steps[stepIndex].photos.findIndex(({ id }) => id === photoId);

      await RideService.removeRideStepPhoto(ride.id, step.id, photoId);

      const updatedRide = ride.clone();
      if (index !== undefined) updatedRide.steps[stepIndex].photos.splice(index, 1);

      onChange(updatedRide);
      enqueueSnackbar(t('cycling-insights.ride.updated'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.not_updated'), { variant: 'error' });
    }

    setLoading(false);
  }

  const loading = _loading || isSubmitting;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          {canWrite && step && step.photos.length === 0 && (
            <>
              <FileInput
                disabled={loading}
                error={error}
                file={file}
                onChange={setFile}
                size="small"
                type="image"
              />
              <Box alignItems="center" display="flex" gap={0.5} marginX={2} marginY={0.5}>
                <InfoOutlined color="action" sx={{ height: 14, width: 14 }} />
                <Typography color="textSecondary" variant="caption">
                  {t('cycling-insights.ride.image_form.helper')}
                </Typography>
              </Box>
              <TextField
                fullWidth
                disabled={loading}
                error={touched.copyright && Boolean(errors.copyright)}
                id="copyright"
                label={t('commons.copyright')}
                margin="dense"
                name="copyright"
                onChange={handleChange}
                size="small"
                value={values.copyright}
                variant="outlined"
              />
            </>
          )}
          {step &&
            step.photos &&
            (step.photos.length > 0 ? (
              <Grid container spacing={2}>
                {step.photos.map(({ id, thumbnailUrl, copyright }) => (
                  <Grid item key={id} xs={4}>
                    <Card variant="outlined">
                      <StyledCardMedia image={thumbnailUrl} />
                      {copyright && (
                        <CardContent>
                          <Typography color="textSecondary" variant="caption">
                            &copy; {copyright}
                          </Typography>
                        </CardContent>
                      )}
                      {canWrite && (
                        <CardActions style={{ justifyContent: 'flex-end' }}>
                          <Button
                            color="error"
                            disabled={loading}
                            onClick={() => id && handleRemove(id)}
                          >
                            <Trans i18nKey="commons.actions.remove" />
                          </Button>
                        </CardActions>
                      )}
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              !canWrite && (
                <Typography color="textSecondary" variant="caption">
                  <Trans i18nKey="cycling-insights.ride.steps.images_content.empty" />
                </Typography>
              )
            ))}
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={() => onClose()} size="large" variant="outlined">
            <Trans i18nKey="commons.actions.close" />
          </Button>
          {canWrite && step && step.photos.length === 0 && (
            <Button
              disableElevation
              color="primary"
              disabled={loading}
              size="large"
              type="submit"
              variant="contained"
            >
              <Trans i18nKey="commons.actions.save" />
            </Button>
          )}
        </DialogActions>
      </form>
    </>
  );
}

const StyledCardMedia = styled(CardMedia)`
  background-color: whitesmoke;
  height: 100px;
`;

export default RideStepImagesContent;
