import { BikeRoute, BikeRouteService, FileInput, IPhoto, TFile } from '@geovelo-frontends/commons';
import { Box, DialogProps, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../app/context';
import Dialog from '../../../components/dialog';

interface IValues {
  title: string;
  description: string;
  copyright: string;
}

type TProps = Omit<DialogProps, 'onClose'> & {
  bikeRoute: BikeRoute;
  photo: IPhoto | null;
  onClose: (photo?: IPhoto) => void;
};

function ImageFormDialog({ bikeRoute, photo, onClose, ...props }: TProps): JSX.Element {
  const [file, setFile] = useState<TFile>();
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const { transitions } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { isSubmitting, values, touched, errors, setValues, handleChange, handleSubmit } =
    useFormik<IValues>({
      initialValues: {
        title: '',
        description: '',
        copyright: '',
      },
      onSubmit,
      enableReinitialize: true,
    });
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);

  useEffect(() => {
    if (props.open) {
      if (photo) {
        const { url, title, description, copyright } = photo;

        setValues({
          title: title || '',
          description: description || '',
          copyright: copyright || '',
        });
        setFile(
          url
            ? {
                url,
                name: url.slice(url.lastIndexOf('/') + 1),
              }
            : undefined,
        );
      }
    } else {
      setTimeout(() => {
        setValues({
          title: '',
          description: '',
          copyright: '',
        });
        setFile(undefined);
        setError(false);
      }, transitions.duration.leavingScreen);
    }
  }, [props.open]);

  async function onSubmit({ title, description, copyright }: IValues) {
    if (!currentPartner) return;

    if (!photo && !(file instanceof File)) {
      setError(true);

      return;
    }

    setError(false);

    try {
      if (photo) {
        if (!photo.id) throw new Error('id is undefined');

        const updatedImage = await BikeRouteService.updateBikeRoutePhoto(bikeRoute.id, photo.id, {
          title,
          description,
          copyright,
        });

        onClose(updatedImage);
      } else {
        if (!(file instanceof File)) return;

        const newImage = await BikeRouteService.addBikeRoutePhoto(bikeRoute.id, {
          title,
          description,
          copyright,
          file,
        });

        onClose(newImage);
      }
      enqueueSnackbar(t('cycling-insights.bike_route.updated'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('cycling-insights.bike_route.not_updated'), { variant: 'error' });
    }
  }

  return (
    <Dialog
      isForm
      confirmTitle={
        photo ? <Trans i18nKey="commons.actions.update" /> : <Trans i18nKey="commons.actions.add" />
      }
      dialogTitle="bike-route-image-form-dialog"
      loading={isSubmitting}
      maxWidth="sm"
      onCancel={() => onClose()}
      onConfirm={handleSubmit}
      title={
        <Trans
          i18nKey="cycling-insights.bike_route.image_form.title"
          values={{ context: !photo ? 'new' : '' }}
        />
      }
      {...props}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <TextField
          fullWidth
          disabled={isSubmitting}
          error={touched.title && Boolean(errors.title)}
          id="title"
          label={t('cycling-insights.bike_route.image_form.image_title')}
          margin="none"
          name="title"
          onChange={handleChange}
          size="small"
          value={values.title}
          variant="outlined"
        />
        <TextField
          fullWidth
          multiline
          disabled={isSubmitting}
          error={touched.description && Boolean(errors.description)}
          id="description"
          label={t('commons.description')}
          margin="none"
          name="description"
          onChange={handleChange}
          rows={3}
          size="small"
          value={values.description}
          variant="outlined"
        />
        {!photo && (
          <FileInput
            disabled={isSubmitting}
            error={error}
            file={file}
            onChange={setFile}
            size="small"
            type="image"
          />
        )}
        <TextField
          fullWidth
          disabled={isSubmitting}
          error={touched.copyright && Boolean(errors.copyright)}
          id="copyright"
          label={t('commons.copyright')}
          margin="none"
          name="copyright"
          onChange={handleChange}
          size="small"
          value={values.copyright}
          variant="outlined"
        />
      </Box>
    </Dialog>
  );
}

export default ImageFormDialog;
