import {
  Challenge,
  ChallengesCustomForm,
  GeogroupService,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import { closedDrawerWidth } from '../../../../layouts/default/drawer';

function ChallengeUpdate(): JSX.Element {
  const {
    partner: { current: currentPartner, currentGeogroup },
  } = useContext(AppContext);
  const [challenge, setChallenge] = useState<Challenge>();
  const { t } = useTranslation();
  const { id: _id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    if (_id) getChallenge(parseInt(_id));

    return () => cancelPromises();
  }, [currentPartner, _id]);

  async function getChallenge(id: number) {
    if (!currentGeogroup) return;

    try {
      const result = await cancellablePromise(GeogroupService.getChallenge(currentGeogroup.id, id));

      setChallenge(result);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('commons.challenge_details.server_error'), { variant: 'error' });
        navigate(`/${currentPartner?.code}/promotion/animation`);
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {t('cycling-insights.app_title')}&nbsp;|&nbsp;
          {t('cycling-insights.community.navigation.challenges')}
        </title>
        <meta content={t('commons.navigation.community_description') || ''} name="description" />
      </Helmet>
      <Box
        bgcolor="#F6F7FB"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        gap={5}
        marginBottom="58px"
        paddingX={14}
        paddingY={8}
        sx={{ overflowY: 'auto' }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography
            color="primary"
            component="h2"
            flexDirection="column"
            fontSize="2rem"
            fontWeight="700"
          >
            <Trans i18nKey="commons.challenge_post.form.title_update" />
          </Typography>
        </Box>
        <ChallengesCustomForm
          challenge={challenge}
          currentPartner={currentPartner}
          drawerWidth={closedDrawerWidth}
          onCreated={(newChallenge) =>
            currentPartner &&
            navigate(`/${currentPartner.code}/promotion/challenges/${newChallenge.id}`)
          }
          prevPath=".."
        />
      </Box>
    </>
  );
}

export default ChallengeUpdate;
