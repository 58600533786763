import { GISFile } from '@geovelo-frontends/commons';
import { ReactNode, useState } from 'react';

import PageContainerLayout from '../../layouts/page/container';
import { IDashboardPage } from '../dashboard-page';

import SendDataTab from './components/send-data';
import { TGISPageContext } from './context';

function GISPage(page: IDashboardPage): JSX.Element {
  // header context
  const [prevButtonClick, setPrevButtonClick] = useState<() => () => void>();
  const [actions, setActions] = useState<ReactNode>();
  const [title, setTitle] = useState<ReactNode>();

  // send data context
  const [sendDataFiles, setSendDataFiles] = useState<GISFile[]>();

  return (
    <PageContainerLayout<TGISPageContext>
      context={{
        header: {
          prevButtonClick,
          title,
          actions,
          setPrevButtonClick,
          setTitle,
          setActions,
        },
        sendData: {
          files: sendDataFiles,
          setFiles: setSendDataFiles,
        },
      }}
      page={page}
    />
  );
}

export { SendDataTab };
export default GISPage;
