import { ApiKey } from '@geovelo-frontends/commons';
import { AddCircleOutline } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import List, { IItem } from '../../../../components/list';

interface IProps {
  apiKeys?: ApiKey[];
  onAdd: () => void;
  selectIndex: (key: number | null) => void;
  selectedIndex: number | null;
}

function ApiKeysList({ apiKeys, selectedIndex, selectIndex, onAdd }: IProps): JSX.Element {
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const [items, setItems] = useState<IItem<'all' | number>[]>();

  useEffect(() => {
    if (apiKeys)
      setItems(
        apiKeys.map(({ id: key, label, enabled }) => ({
          key,
          title: label,
          titleProps: {
            color: enabled ? 'inherit' : red[500],
            fontWeight: key === 'all' ? 900 : 'inherit',
          },
        })),
      );
    else setItems(undefined);
  }, [apiKeys]);

  return (
    <List
      dense
      actions={[
        {
          children: <Trans i18nKey="commons.actions.add" />,
          color: 'primary',
          disabled: !items,
          key: 'add',
          onClick: onAdd,
          needWritePermission: true,
          startIcon: <AddCircleOutline />,
          variant: 'contained',
        },
      ]}
      items={items}
      onClick={selectIndex}
      permission={currentPartner?.dashboardTabsPermissions.apiKeys}
      selectedIndex={selectedIndex}
      title={<Trans i18nKey="cycling-insights.api.keys.table.title" />}
    />
  );
}

export default ApiKeysList;
