import { UserService } from '@geovelo-frontends/commons';
import { CardContent, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../app/context';
import Card from '../../../components/card';

function Subscription(): JSX.Element {
  const {
    user: { current: currentUser },
    actions: { setCurrentUser },
  } = useContext(AppContext);
  const [newsletter, setNewsletter] = useState(currentUser?.options.dashboardNewsletter || false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (currentUser) {
      setNewsletter(currentUser.options.dashboardNewsletter);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser && newsletter !== currentUser.options.dashboardNewsletter) {
      updateUserOptions({ dashboard_newsletter: newsletter });
    }
  }, [newsletter]);

  async function updateUserOptions(props: { dashboard_newsletter?: boolean }) {
    if (!currentUser) return;

    try {
      const options = await UserService.updateUserOptions(currentUser.id, props);

      currentUser.options = options;
      setCurrentUser(currentUser);
      enqueueSnackbar(t('commons.newsletter_dialog.updated'));
    } catch (err) {
      enqueueSnackbar(t('commons.newsletter_dialog.not_updated'));
    }
  }

  if (!currentUser) return <></>;

  return (
    <Card title={<Trans i18nKey="cycling-insights.profile.subscriptions.title" />}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="caption">
          <Trans i18nKey="cycling-insights.profile.subscriptions.description" />
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={newsletter}
              color="primary"
              onChange={(_, checked) => setNewsletter(checked)}
            />
          }
          label={<Trans i18nKey="cycling-insights.profile.subscriptions.accept_newsletter" />}
        />
      </CardContent>
    </Card>
  );
}

export default Subscription;
