import { IRideStep } from '@geovelo-frontends/commons';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import theme from '../../app/theme';
import Button from '../button';

interface IProps {
  canWrite: boolean;
  onUpdate?: (step: IRideStep) => void;
  onClose: () => void;
  step: IRideStep;
}

function RideStepPopup({ canWrite, step, onUpdate, onClose }: IProps): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Typography>{step.title}</Typography>
        <StyledActions>
          <Button onClick={() => onClose()} variant="outlined">
            <Trans i18nKey="commons.actions.close" />
          </Button>
          {!step.poi && onUpdate && (
            <Button color="primary" onClick={() => onUpdate(step)} variant="outlined">
              {canWrite ? (
                <Trans i18nKey="commons.actions.update" />
              ) : (
                <Trans i18nKey="commons.actions.view" />
              )}
            </Button>
          )}
        </StyledActions>
      </Wrapper>
    </ThemeProvider>
  );
}

const Wrapper = styled.div`
  width: 300px;
`;

const StyledActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  button:not(:first-child) {
    margin-left: 8px;
  }
`;

export default RideStepPopup;
