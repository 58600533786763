import { Loading } from '@geovelo-frontends/commons';
import { Launch } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import Button from '../../../../components/button';

const src = 'https://stats.uptimerobot.com/RkzQkcr4RX';

function AvailabilityData(): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [error] = useState(false);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      sx={{ backgroundColor: 'whitesmoke', overflowY: 'auto' }}
    >
      <Iframe
        onError={(event: SyntheticEvent<HTMLIFrameElement>) => console.error(event)}
        onLoad={() => {
          setLoading(false);
          // setError(currentTarget.contentDocument === null); // TODO : gerer cas iframe loade une page d'erreur
        }}
        src={src}
      />
      {loading && <StyledLoading text={<Trans i18nKey="commons.api.loading" />} />}
      {error && (
        <ErrorWrapper>
          <Typography>
            <Trans i18nKey="commons.api.error_message" />
          </Typography>
          <Button color="primary" component="a" href={src} target="_blank" variant="contained">
            <Trans i18nKey="commons.api.error_link_to_status" />
            &nbsp;&nbsp;
            <Launch />
          </Button>
        </ErrorWrapper>
      )}
    </Box>
  );
}

const StyledLoading = styled(Loading)`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 32px);
  background-color: whitesmoke;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;

const ErrorWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    margin-top: 24px;
    font-size: 20px;
    padding: 5px 30px;
  }
`;

export default AvailabilityData;
