import {
  CondensedRideTrace,
  FileInput,
  Ride,
  RideService,
  RideTrace,
  RideTraceService,
  TFile,
} from '@geovelo-frontends/commons';
import {
  Box,
  Collapse,
  DialogProps,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Dialog from '../../../../components/dialog';

interface IValues {
  existing: boolean;
  traceId: number | '';
  title: string;
  description: string;
  osmRelationId: number | '';
  referenceUrl: string;
}

type TProps = Omit<DialogProps, 'onClose'> & {
  onClose: (trace?: RideTrace) => void;
  ride: Ride;
  traces: CondensedRideTrace[];
};

function TraceFormDialog({ traces, ride, onClose, ...props }: TProps): JSX.Element {
  const [file, setFile] = useState<TFile>();
  const [fileError, setFileError] = useState(false);
  const { t } = useTranslation();
  const { transitions } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isSubmitting,
    values,
    touched,
    errors,
    setValues,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik<IValues>({
    initialValues: {
      existing: traces.length > 0,
      traceId: traces[0]?.id || '',
      title: '',
      description: '',
      osmRelationId: '',
      referenceUrl: '',
    },
    validationSchema: Yup.object().shape({
      existing: Yup.boolean(),
      traceId: Yup.number().when('existing', { is: true, then: (shema) => shema.required() }),
      title: Yup.string().when('existing', { is: false, then: (shema) => shema.required() }),
      referenceUrl: Yup.string().url(),
    }),
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (props.open) {
      setFieldValue('existing', traces.length > 0);
    } else {
      setTimeout(() => {
        setValues({
          existing: true,
          traceId: traces[0]?.id || '',
          title: '',
          description: '',
          osmRelationId: '',
          referenceUrl: '',
        });
        setFile(undefined);
        setFileError(false);
      }, transitions.duration.leavingScreen);
    }
  }, [props.open]);

  async function onSubmit({
    existing,
    traceId,
    title,
    description,
    osmRelationId,
    referenceUrl,
  }: IValues) {
    if (!ride.id) return;

    try {
      if (existing) {
        if (!traceId) return;

        const trace = await RideService.addTraceToRide(ride.id, traceId);

        setFieldValue('traceId', '');
        onClose(trace);
      } else {
        if (!(file instanceof File)) {
          setFileError(true);

          return;
        }

        setFileError(false);

        const trace = await RideTraceService.addRideTrace({
          title,
          description,
          osm_relation_id: osmRelationId || undefined,
          reference_id: referenceUrl || undefined,
          gpx: file,
        });
        await RideService.addTraceToRide(ride.id, trace.id);

        onClose(trace);
      }

      enqueueSnackbar(t('cycling-insights.ride.updated'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.not_updated'), { variant: 'error' });
    }
  }

  return (
    <Dialog
      isForm
      confirmTitle={<Trans i18nKey="commons.actions.add" />}
      dialogTitle="ride-trace-form-dialog"
      loading={isSubmitting}
      maxWidth="sm"
      onCancel={() => onClose()}
      onConfirm={handleSubmit}
      title={<Trans i18nKey="cycling-insights.ride.trace_dialog.title" />}
      {...props}
    >
      <RadioGroup
        aria-label="trace type"
        name="existing"
        onChange={({ target: { value } }) => setFieldValue('existing', value === 'true')}
        value={String(values.existing)}
      >
        <FormControlLabel
          control={<Radio size="small" />}
          disabled={isSubmitting || traces.length === 0}
          label={<Trans i18nKey="cycling-insights.ride.trace_dialog.existing_trace" />}
          value="true"
        />
        <Collapse in={values.existing}>
          <Box display="flex" flexDirection="column" gap={2} marginY={2}>
            <FormControl fullWidth disabled={isSubmitting} margin="none" variant="outlined">
              <InputLabel htmlFor="trace-input">
                <Trans i18nKey="cycling-insights.ride.trace_dialog.trace" />
              </InputLabel>
              <Select
                inputProps={{
                  id: 'trace-input',
                }}
                label={<Trans i18nKey="cycling-insights.ride.trace_dialog.trace" />}
                name="traceId"
                onChange={handleChange}
                size="small"
                value={values.traceId}
              >
                {traces.map(({ id, title }) => (
                  <MenuItem key={id} value={id}>
                    {title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Collapse>
        <FormControlLabel
          control={<Radio size="small" />}
          disabled={isSubmitting}
          label={<Trans i18nKey="cycling-insights.ride.trace_dialog.new_trace" />}
          value="false"
        />
        <Collapse in={!values.existing}>
          <Box display="flex" flexDirection="column" gap={2} marginY={2}>
            <TextField
              fullWidth
              disabled={isSubmitting}
              error={touched.title && Boolean(errors.title)}
              label={t('cycling-insights.ride.trace_dialog.trace_title')}
              margin="none"
              name="title"
              onChange={handleChange}
              required={!values.existing}
              size="small"
              value={values.title}
              variant="outlined"
            />
            <TextField
              fullWidth
              multiline
              disabled={isSubmitting}
              error={touched.description && Boolean(errors.description)}
              label={t('cycling-insights.ride.trace_dialog.description')}
              margin="none"
              name="description"
              onChange={handleChange}
              rows={3}
              value={values.description}
              variant="outlined"
            />
            <TextField
              fullWidth
              disabled={isSubmitting}
              error={touched.osmRelationId && Boolean(errors.osmRelationId)}
              label={t('cycling-insights.ride.trace_dialog.osm_relation_id')}
              margin="none"
              name="osmRelationId"
              onChange={handleChange}
              size="small"
              type="number"
              value={values.osmRelationId}
              variant="outlined"
            />
            <FileInput
              disabled={isSubmitting}
              error={fileError}
              file={file}
              onChange={setFile}
              size="small"
              type="gpx"
            />
            <TextField
              fullWidth
              disabled={isSubmitting}
              error={touched.referenceUrl && Boolean(errors.referenceUrl)}
              label={t('cycling-insights.ride.trace_dialog.reference_url')}
              margin="none"
              name="referenceUrl"
              onChange={handleChange}
              size="small"
              type="url"
              value={values.referenceUrl}
              variant="outlined"
            />
          </Box>
        </Collapse>
      </RadioGroup>
    </Dialog>
  );
}

export default TraceFormDialog;
