import { RefRoute, RefRouteService, useCancellablePromise } from '@geovelo-frontends/commons';
import { AddCircleOutline, Close, Search } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Button from '../../../../components/button';
import { TQAPageContext } from '../../context';

import RefRouteFormDialog from './form-dialog';

function RefRoutesForm({
  refRoutes: { search, succeededCount, failedCount, setSearch, setSucceededCount, setFailedCount },
}: TQAPageContext): JSX.Element {
  const [formDialogOpen, openFormDialog] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    getAllRefRoutes();
    setInitialized(true);

    return () => {
      cancelPromises();
      setSearch('');
      setSucceededCount(undefined);
      setFailedCount(undefined);
    };
  }, []);

  useEffect(() => {
    if (!initialized) return;

    const timeout = setTimeout(() => getAllRefRoutes(), 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [search]);

  async function getAllRefRoutes() {
    cancelPromises();
    setSucceededCount(undefined);
    setFailedCount(undefined);

    try {
      const { count } = await cancellablePromise(
        RefRouteService.getAll({ search, page: 1, pageSize: 1, query: '{ id }' }),
      );

      const res = await cancellablePromise(
        Promise.all(
          new Array(Math.ceil(count / 100)).fill(null).map((_, index) =>
            RefRouteService.getAll({
              search,
              page: index + 1,
              pageSize: 100,
              query: '{ id, passed }',
            }),
          ),
        ),
      );

      let _succeededCount = 0;
      let _failedCount = 0;
      res.forEach(({ refRoutes }) => {
        refRoutes.forEach(({ status }) => {
          if (status === 'success') ++_succeededCount;
          else ++_failedCount;
        });
      });

      setSucceededCount(_succeededCount);
      setFailedCount(_failedCount);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError')
        enqueueSnackbar(t('cycling-insights.qa.server_error'), { variant: 'error' });
    }
  }

  function handleSearchChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    setSearch(value);
  }

  function handleFormDialogClose(newRefRoute?: RefRoute) {
    if (newRefRoute) {
      if (search) setSearch('');
      else {
        if (newRefRoute.status === 'success' && succeededCount !== undefined)
          setSucceededCount(++succeededCount);
        else if (failedCount !== undefined) setFailedCount(++failedCount);
      }
    }

    openFormDialog(false);
  }

  return (
    <>
      <Box display="flex" flexDirection="column" minHeight="100%">
        <Box display="flex" flexDirection="column" flexShrink={0} gap={2} padding={2}>
          <Box>
            <TextField
              fullWidth
              disabled={succeededCount === undefined || failedCount === undefined}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearch('')} size="small">
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleSearchChange}
              placeholder={t('commons.actions.search') || ''}
              size="small"
              value={search}
              variant="outlined"
            />
          </Box>
        </Box>
        <Box flexGrow={1} />
        <Box display="flex" flexShrink={0} justifyContent="center" padding={1}>
          <Button
            color="primary"
            disabled={succeededCount === undefined || failedCount === undefined}
            onClick={() => openFormDialog(true)}
            size="small"
            startIcon={<AddCircleOutline />}
            variant="contained"
          >
            <Trans i18nKey="commons.actions.add" />
          </Button>
        </Box>
      </Box>
      <RefRouteFormDialog onClose={handleFormDialogClose} open={formDialogOpen} />
    </>
  );
}

export default RefRoutesForm;
