import {
  Ride,
  RideService,
  TBackendPublicationStatus,
  TPublicationStatus,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { AddCircleOutline, Directions, PhotoCamera } from '@mui/icons-material';
import { Avatar, Box, Tooltip } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { useSnackbar } from 'notistack';
import {
  MouseEventHandler,
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import Card from '../../../../components/card';
import Table, { TRow } from '../../../../components/table';
import usePaginatedTable from '../../../../hooks/table/paginated';
import useSortableTable from '../../../../hooks/table/sortable';
import { publicationStatuses } from '../../../../models/publication-status';

export type TKey = 'id' | 'status' | 'title' | 'updated' | 'data';

const keys: TKey[] = ['id', 'title', 'updated', 'data'];

export interface IRidesTableRef {
  getRides: () => void;
}

function RidesTable(
  {
    rides,
    count,
    search,
    selectedPublicationStatuses,
    setRides,
    setCount,
    cancelSearch,
    onAdd,
  }: {
    count?: number;
    onAdd: MouseEventHandler<HTMLButtonElement>;
    cancelSearch: () => void;
    rides?: Ride[];
    search?: string;
    selectedPublicationStatuses: TPublicationStatus[];
    setCount: (count?: number) => void;
    setRides: (rides?: Ride[]) => void;
  },
  ref: Ref<IRidesTableRef>,
): JSX.Element {
  const [rows, setRows] = useState<TRow<number, TKey>[] | undefined>();
  const {
    ride: { themes },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { page, rowsPerPage, setPage, onPageChange, onRowsPerPageChange } = usePaginatedTable(10);
  const { orderBy, order, onSortRequest } = useSortableTable<number, TKey>('id', 'desc', {
    setPage,
  });
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    cancelPromises();
  }, [search]);

  useEffect(() => {
    getRides();

    return () => cancelSearch();
  }, [themes, orderBy, order, page, rowsPerPage, selectedPublicationStatuses]);

  useEffect(() => {
    setRows(rides ? rides.map(parseRide) : undefined);
  }, [rides]);

  useImperativeHandle(ref, () => ({
    getRides: () => {
      if (page === 0) getRides();
      else setPage(0);
    },
  }));

  async function getRides() {
    setRides(undefined);
    setCount(undefined);
    cancelPromises();

    if (!themes) return;

    try {
      const publicationStatusMap: { [key in TPublicationStatus]: TBackendPublicationStatus } = {
        published: 'PUBLISHED',
        unpublished: 'UNPUBLISHED',
        waitingForApproval: 'WAITING_FOR_APPROVAL',
      };

      const { count, rides } = await cancellablePromise(
        RideService.getRides({
          page: page + 1,
          pageSize: rowsPerPage,
          orderBy: orderBy === 'id' || orderBy === 'title' ? orderBy : 'id',
          order,
          search,
          publicationStatuses: publicationStatuses
            .filter((key) => selectedPublicationStatuses.includes(key))
            .map((key) => publicationStatusMap[key]),
          query: '{id, title, route_atob, photos{id}, publication_status, updated }',
        }),
      );

      setRides([...rides]);
      setCount(count);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.tourism.rides.list.server_error'), {
          variant: 'error',
        });
      }
    }
  }

  function parseRide(ride: Ride): TRow<number, TKey> {
    const { id, publicationStatus, title, updated, routeId, photos } = ride;

    return {
      key: id || -1,
      inkBarColor:
        publicationStatus === 'published'
          ? green[500]
          : publicationStatus === 'waitingForApproval'
            ? orange[500]
            : red[500],
      cells: {
        id: { value: id || '' },
        title: { value: title || '' },
        updated: { value: updated?.format('L') || '' },
        data: {
          value: '',
          format: () => {
            return (
              <Box display="flex" gap={1}>
                <Tooltip
                  placement="left"
                  title={
                    <Trans
                      i18nKey={
                        routeId
                          ? 'cycling-insights.tourism.rides.list.defined_route'
                          : 'cycling-insights.tourism.rides.list.undefined_route'
                      }
                    />
                  }
                >
                  <Avatar
                    sx={{ backgroundColor: routeId ? green[500] : red[500], height: 24, width: 24 }}
                  >
                    <Directions fontSize="small" />
                  </Avatar>
                </Tooltip>
                <Tooltip
                  placement="left"
                  title={
                    <Trans
                      count={photos.length}
                      i18nKey={'cycling-insights.tourism.rides.list.pictures'}
                      values={{ count: photos.length }}
                    />
                  }
                >
                  <Avatar
                    sx={{
                      backgroundColor: photos.length > 0 ? green[500] : red[500],
                      height: 24,
                      width: 24,
                    }}
                  >
                    <PhotoCamera fontSize="small" />
                  </Avatar>
                </Tooltip>
              </Box>
            );
          },
        },
      },
    };
  }

  return (
    <>
      <Card
        actions={[
          {
            children: <Trans i18nKey="cycling-insights.tourism.rides.actions.add" />,
            color: 'primary',
            disabled: !rides,
            key: 'add',
            onClick: onAdd,
            needWritePermission: true,
            startIcon: <AddCircleOutline />,
            variant: 'contained',
          },
        ]}
        permission={'write'}
      >
        <Table
          hasInkBars
          count={count}
          headers={{
            id: { label: '#', width: 50, sortable: true },
            title: { label: <Trans i18nKey="commons.title" />, width: 200, sortable: true },
            updated: {
              label: <Trans i18nKey="commons.stats.update_label" />,
              width: 100,
            },
            data: { width: 56 },
          }}
          keys={keys}
          onClick={(id) =>
            currentPartner && navigate(`/${currentPartner.code}/promotion/rides/${id}`)
          }
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          onSortRequest={onSortRequest}
          order={order}
          orderBy={orderBy}
          page={page}
          rows={rows}
          rowsPerPage={rowsPerPage}
          tablePaginationProps={{ sx: { '> div': { paddingX: 1 } } }}
          tableProps={{ sx: { tableLayout: 'fixed' } }}
          title="Cartographic contributions table"
        />
      </Card>
    </>
  );
}

export default forwardRef(RidesTable);
