import { Box, Tab, Tabs } from '@mui/material';
import { ComponentType, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../../../../app/context';
import TabPanel from '../../../../components/tab-panel';
import useQueryParams from '../../../../hooks/query-params';
import { typesMap } from '../api-keys/types';

import M2MDocumentation from './m2m';
import RouteComputerDocumentation from './route-computer';

type TTab = 'route-computer' | 'm2m';

const tabs: Array<{
  key: TTab;
  labelKey: string;
  Tab: ComponentType<Record<string, never>>;
}> = [
  {
    key: 'route-computer',
    labelKey: typesMap.routeComputer.labelKey,
    Tab: RouteComputerDocumentation,
  },
  {
    key: 'm2m',
    labelKey: typesMap.m2m.labelKey,
    Tab: M2MDocumentation,
  },
];

function DocumentationData(): JSX.Element {
  const { searchParams, update: updateQueryParams } = useQueryParams();
  const [selectedTab, selectTab] = useState<TTab>(() => {
    const tab = searchParams.get('tab');
    if (tab === 'route-computer-request' || tab === 'route-computer-response')
      return 'route-computer';
    if (tab === 'm2m-request' || tab === 'm2m-response') return 'm2m';

    return 'route-computer';
  });
  const {
    user: { current: currentUser },
  } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        {currentUser?.isGeovelo && (
          <Tabs
            onChange={(_, value) => selectTab(value)}
            sx={{
              '@media print': {
                display: 'none',
              },
            }}
            value={selectedTab}
          >
            {tabs.map(({ key, labelKey }) => (
              <Tab
                aria-controls={`tabpanel-${key}`}
                id={`tab-${key}`}
                key={key}
                label={t(labelKey)}
                onClick={() => updateQueryParams({ tab: `${key}-request` })}
                value={key}
              />
            ))}
          </Tabs>
        )}
      </Box>
      {tabs.map(({ key, Tab }) => (
        <StyledTabPanel
          className={selectedTab === key ? 'active' : ''}
          key={key}
          selectedValue={key}
          value={key}
        >
          <Tab />
        </StyledTabPanel>
      ))}
    </Box>
  );
}

const StyledTabPanel = styled(TabPanel)`
  display: none;
  overflow-y: auto;

  &.active {
    display: block;
  }
`;

export default DocumentationData;
