import { Ride } from '@geovelo-frontends/commons';
import { Chip } from '@mui/material';
import { useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import Card from '../../../../components/card';

import PartnerFormDialog from './partner-form-dialog';

interface IProps {
  canWrite: boolean;
  onChange: (ride: Ride) => void;
  ride: Ride;
}

function PartnerList({ canWrite, ride, onChange }: IProps): JSX.Element {
  const [formDialogOpen, openFormDialog] = useState(false);
  const {
    partner: { all: allPartners, list: partners },
  } = useContext(AppContext);

  function handleFormDialogClose(updatedRide?: Ride) {
    if (updatedRide) onChange(updatedRide);

    openFormDialog(false);
  }

  if (!partners) return <></>;

  return (
    <>
      <Card title={<Trans i18nKey="cycling-insights.ride.partner_list.title" />}>
        <Wrapper>
          {ride.partners && (
            <StyledContent>
              {ride.partners.map(({ id }) => {
                const partner =
                  allPartners?.find((partner) => partner.id === id) ||
                  partners.find((partner) => partner.id === id);

                return partner && <Chip color="secondary" key={id} label={partner.title} />;
              })}
            </StyledContent>
          )}
          {canWrite && (
            <StyledActions>
              <Button
                color="primary"
                onClick={() => openFormDialog(true)}
                size="medium"
                type="reset"
                variant="contained"
              >
                <Trans i18nKey="commons.actions.update" />
              </Button>
            </StyledActions>
          )}
        </Wrapper>
      </Card>
      <PartnerFormDialog onClose={handleFormDialogClose} open={formDialogOpen} ride={ride} />
    </>
  );
}

const Wrapper = styled.div`
  padding: 16px;
`;

const StyledContent = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-right: 4px;
  }
`;

const StyledActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;

export default PartnerList;
