import { Add } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Link, useOutletContext } from 'react-router-dom';

import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { TCartographicDataPageContext } from '../../context';

import FacilitiesSuggestionsContent from './content';

function FacilitiesSuggestionsTab(): JSX.Element {
  const context = useOutletContext<TCartographicDataPageContext & TOutletContext>();
  const {
    header: { setActions },
    facilitiesSuggestions: { data },
  } = context;

  useEffect(() => {
    return () => {
      setActions(undefined);
    };
  }, []);

  useEffect(() => {
    setActions(
      <Tooltip title={<Trans i18nKey="cycling-insights.qa.facilities_suggestions.actions.add" />}>
        <span>
          <IconButton
            color="primary"
            component={Link}
            disabled={!data}
            size="small"
            to="./facilities-suggestions-form"
          >
            <Add />
          </IconButton>
        </span>
      </Tooltip>,
    );
  }, [data]);

  return <PageContentLayout leftPanel={<FacilitiesSuggestionsContent {...context} />} />;
}

export default FacilitiesSuggestionsTab;
