import { useState } from 'react';

import { TRow, TRowsPerPage } from '../../components/table';

function usePaginatedTable<TKey extends string | number, TCellKey extends string>(
  initialRowsPerPage: TRowsPerPage = 5,
): {
  page: number;
  rowsPerPage: number;
  setPage: (page: number) => void;
  paginate: (rows: TRow<TKey, TCellKey>[]) => TRow<TKey, TCellKey>[];
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
} {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<TRowsPerPage>(initialRowsPerPage);

  function onPageChange(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) {
    setPage(newPage);
  }

  function onRowsPerPageChange({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+value as TRowsPerPage);
    setPage(0);
  }

  function paginate(rows: TRow<TKey, TCellKey>[]) {
    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }

  return { page, rowsPerPage, setPage, paginate, onPageChange, onRowsPerPageChange };
}

export default usePaginatedTable;
