import { useOutletContext } from 'react-router-dom';

import PageContentLayout from '../../../../layouts/page/content';
import { IPromotionPageContext } from '../../context';

import RidesForm from './form';

function RidesTab(): JSX.Element {
  const context = useOutletContext<IPromotionPageContext>();

  return <PageContentLayout leftPanel={<RidesForm {...context} />} main={<></>} />;
}

export default RidesTab;
