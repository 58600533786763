import { TColorScale, slopePercentages, speeds } from '@geovelo-frontends/commons';
import { ExpandMore, Info } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';

interface IProps {
  colorScale: TColorScale;
  setColorScale: (scale: TColorScale) => void;
}

function TraceToggle({ colorScale, setColorScale }: IProps): JSX.Element {
  const [expended, expend] = useState(false);

  return (
    <Accordion expanded={expended} onChange={() => expend(!expended)}>
      <AccordionSummary
        aria-controls="trace-legend"
        aria-label="Expand"
        expandIcon={expended ? <ExpandMore /> : <Info />}
        id="trace-header"
      >
        <FormControl size="small" style={{ marginRight: 16, width: 150 }}>
          <Select
            onChange={({ target: { value } }) => setColorScale(value as TColorScale)}
            onClick={(event) => event.stopPropagation()}
            value={colorScale}
          >
            <MenuItem onClick={(event) => event.stopPropagation()} value="elevations">
              Profil altimétrique
            </MenuItem>
            <MenuItem onClick={(event) => event.stopPropagation()} value="speed">
              Vitesse
            </MenuItem>
          </Select>
        </FormControl>
      </AccordionSummary>
      <AccordionDetails>
        <StyledList dense>
          {colorScale === 'elevations'
            ? slopePercentages.map(({ min, max, color }) => (
                <ListItem key={min}>
                  <ListItemIcon>
                    <ColorSquare color={color} />
                  </ListItemIcon>
                  <ListItemText primary={max ? <>&lt; {max}%</> : <>&ge; {min}%</>} />
                </ListItem>
              ))
            : speeds.map(({ min, max, color }) => (
                <ListItem key={min}>
                  <ListItemIcon>
                    <ColorSquare color={color} />
                  </ListItemIcon>
                  <ListItemText primary={max ? <>&lt; {max} km/h</> : <>&ge; {min} km/h</>} />
                </ListItem>
              ))}
        </StyledList>
      </AccordionDetails>
    </Accordion>
  );
}

const StyledList = styled(List)`
  && {
    padding: 0;
    width: 100%;

    li {
      padding: 0;
    }
  }
`;

const ColorSquare = styled.div<{ color: string }>`
  height: 20px;
  width: 20px;
  background-color: ${({ color }) => color};
`;

export default TraceToggle;
