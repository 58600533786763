import { AuthService, PasswordValidation, TLanguage, TPartner } from '@geovelo-frontends/commons';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';

import { AppContext } from '../../app/context';
import Button from '../../components/button';
import { environment } from '../../environment';
import LoginLayout from '../../layouts/login';

interface IValues {
  password: string;
  passwordConfirmation: string;
  approveGCU: boolean;
  approvePrivacyPolicy: boolean;
}

function SetPassword(): JSX.Element {
  const [passwordShowed, showPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<{
    email: string;
    resetPasswordToken?: string;
    partner: TPartner;
  }>();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    actions: { setCurrentUser },
  } = useContext(AppContext);
  const { isSubmitting, values, touched, errors, isValid, handleChange, handleSubmit } =
    useFormik<IValues>({
      initialValues: {
        password: '',
        passwordConfirmation: '',
        approveGCU: false,
        approvePrivacyPolicy: false,
      },
      validationSchema: Yup.object().shape({
        password: Yup.string()
          .required(t('commons.sign_up_form.password_pattern') || '')
          .matches(
            /^(?=.*[\d])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/g,
            t('commons.sign_up_form.password_pattern') || '',
          ),
        passwordConfirmation: Yup.string().when('password', {
          is: (password: string) => password,
          then: (shema) =>
            shema
              .oneOf([Yup.ref('password')], t('commons.sign_up_form.passwords_must_match') || '')
              .required(t('commons.sign_up_form.passwords_must_match') || ''),
        }),
        approveGCU: Yup.boolean().oneOf([true]),
        approvePrivacyPolicy: Yup.boolean().oneOf([true]),
      }),
      enableReinitialize: true,
      onSubmit,
    });

  useEffect(() => {
    try {
      const token = searchParams.get('data');
      if (!token) throw new Error('missing token');

      const {
        email,
        reset_password_token: resetPasswordToken,
        partner_code: partnerCode,
        partner_title: partnerTitle,
        partner_icon: partnerIcon,
      } = JSON.parse(atob(token || ''));
      const partner: TPartner = {
        code: partnerCode,
        title: partnerTitle || null,
        icon: partnerIcon ? `${environment.backendUrl}${partnerIcon}` : null,
      };

      if (!resetPasswordToken || !partnerCode) throw new Error('invalid token');

      setState({ email, resetPasswordToken, partner });
    } catch (err) {
      console.error(err);
      navigate('/login');
    }
  }, []);

  async function onSubmit({ password }: IValues, { setSubmitting }: FormikHelpers<IValues>) {
    if (!state) return;
    setSubmitting(true);

    try {
      await AuthService.resetPassword(state?.resetPasswordToken || '', password);

      enqueueSnackbar(t('commons.reset_password_form.updated') || '');
      const user = await AuthService.signInWithEmail(
        state.email,
        password,
        false,
        language as TLanguage,
      );

      setCurrentUser(user);
    } catch (err) {
      enqueueSnackbar(t('commons.reset_password_form.server_error') || '');
      setSubmitting(false);
    }
  }

  return (
    <LoginLayout>
      <StyledForm onSubmit={handleSubmit}>
        <Box alignItems="center" display="flex" gap={2}>
          {state?.partner.icon ? (
            <StyledAvatar src={state.partner.icon} />
          ) : (
            <>
              {state?.partner.code && (
                <StyledAvatar>{state.partner.code.toUpperCase()}</StyledAvatar>
              )}
            </>
          )}
          <Typography fontWeight={700} lineHeight={1.8}>
            <Trans
              i18nKey="cycling-insights.sign_up.partner_invite"
              values={{ partner: state?.partner.title }}
            />
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <StyledTextField
            disabled={isSubmitting}
            error={Boolean(errors.password && touched.password)}
            id="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => showPassword(!passwordShowed)}
                    size="small"
                  >
                    {passwordShowed ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            margin="none"
            name="password"
            onChange={handleChange}
            placeholder={t('commons.sign_in_form.password') || ''}
            size="small"
            type={passwordShowed ? 'text' : 'password'}
            value={values.password}
            variant="filled"
          />
          <PasswordValidation password={values.password} />
          <StyledTextField
            disabled={isSubmitting}
            error={Boolean(errors.passwordConfirmation && touched.passwordConfirmation)}
            helperText={touched.passwordConfirmation && errors.passwordConfirmation}
            id="passwordConfirmation"
            margin="none"
            name="passwordConfirmation"
            onChange={handleChange}
            placeholder={t('commons.sign_up_form.password_confirmation') || ''}
            size="small"
            type="password"
            value={values.passwordConfirmation}
            variant="filled"
          />
          <Box display="flex" flexDirection="column">
            <FormControl component="fieldset" disabled={isSubmitting} margin="none">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="approveGCU"
                    onChange={handleChange}
                    style={errors.approveGCU && touched.approveGCU ? { color: red[500] } : {}}
                    value={values.approveGCU}
                  />
                }
                label={
                  <Typography variant="caption">
                    <Trans
                      components={[
                        <Typography
                          color="primary"
                          component={Link}
                          key={0}
                          to={`/${language}/eula`}
                          variant="caption"
                        />,
                      ]}
                      i18nKey="commons.sign_up_form.accept_gcu"
                    />
                  </Typography>
                }
              />
            </FormControl>
            <FormControl component="fieldset" disabled={isSubmitting} margin="none">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="approvePrivacyPolicy"
                    onChange={handleChange}
                    style={
                      errors.approvePrivacyPolicy && touched.approvePrivacyPolicy
                        ? { color: red[500] }
                        : {}
                    }
                    value={values.approvePrivacyPolicy}
                  />
                }
                label={
                  <Typography variant="caption">
                    <Trans
                      components={[
                        <Typography
                          color="primary"
                          component={Link}
                          key={0}
                          to={`/${language}/privacy-policy`}
                          variant="caption"
                        />,
                      ]}
                      i18nKey="commons.sign_up_form.accept_privacy_policy"
                    />
                  </Typography>
                }
              />
            </FormControl>
          </Box>
          <Button
            color="primary"
            disabled={isSubmitting || !isValid || !values.password}
            size="medium"
            type="submit"
            variant="contained"
          >
            {t('commons.actions.sign_up')}
          </Button>
        </Box>
        <Box alignItems="center" display="flex" flexDirection="row" gap={1}>
          <Typography align="center" color="textSecondary" component="p" variant="caption">
            {t('commons.sign_in_dialog.already_registered')}
          </Typography>
          <Button
            color="primary"
            component={Link}
            size="small"
            to={`/${language}/login`}
            variant="text"
          >
            {t('commons.actions.log_in')}
          </Button>
        </Box>
      </StyledForm>
    </LoginLayout>
  );
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 40px auto;
  max-width: 100%;
  width: 400px;
`;

const StyledAvatar = styled(Avatar)`
  && {
    background-color: ${({ theme }) => theme.palette.secondary.light};
    border: 1px solid whitesmoke;
    color: ${({ theme }) => theme.palette.primary.dark};
    font-size: 2em;
    font-weight: 700;
    height: 64px;
    width: 64px;
  }
`;

const StyledTextField = styled(TextField)`
  &&& {
    .MuiInputBase-root {
      &::before,
      &::after {
        border-bottom: 0 !important;
      }

      border-radius: 8px;

      input {
        padding-bottom: 12px;
        padding-top: 12px;
      }
    }
  }
`;

export default SetPassword;
