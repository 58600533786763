import { Trace, useUnits } from '@geovelo-frontends/commons';
import { green, grey, purple } from '@mui/material/colors';
import { Chart } from 'chart.js';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const chartId = 'elevation-chart';

function ElevationsSpeedsChart({
  trace,
  distances,
}: {
  distances: number[] | undefined;
  trace: Trace | null;
}): JSX.Element {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const { toDistance } = useUnits();

  useEffect(() => {
    let _chart: Chart | undefined;

    if (trace && distances && chartRef.current) {
      let elevationMin = Number.MAX_VALUE;
      let elevationMax = Number.MIN_VALUE;
      let speedMin = Number.MAX_VALUE;
      let speedMax = Number.MIN_VALUE;

      const elevationsData = trace.elevations.map((elevation, index) => {
        elevationMin = Math.min(elevationMin, elevation);
        elevationMax = Math.max(elevationMax, elevation);
        return {
          x: distances[index],
          y: elevation,
        };
      });

      const speedsData = trace.speeds.map((speed, index) => {
        speedMin = Math.min(speedMin, speed);
        speedMax = Math.max(speedMax, speed);
        return {
          x: distances[index],
          y: speed,
        };
      });

      const elevationOffset = Math.round(Math.max(50 - (elevationMax - elevationMin), 0) / 2);
      const speedOffset = Math.round(Math.max(50 - (speedMax - speedMin), 0) / 2);

      _chart = new Chart(chartRef.current, {
        type: 'line',
        data: {
          datasets: [
            {
              label: 'Profil altimétrique',
              data: elevationsData,
              backgroundColor: purple[500],
              borderColor: purple[500],
              tension: 0.4,
              pointRadius: 1,
              fill: false,
              yAxisID: 'yAxisElevations',
            },
            {
              label: 'Vitesse',
              data: speedsData,
              borderColor: grey[300],
              backgroundColor: grey[300],
              tension: 0.4,
              pointRadius: 1,
              fill: true,
              yAxisID: 'yAxisSpeed',
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              type: 'linear',
              min: 0,
              max: trace.distance,
              ticks: {
                callback: (value) => toDistance(value),
              },
            },
            yAxisElevations: {
              type: 'linear',
              min: Math.floor(elevationMin - elevationOffset),
              max: Math.ceil(elevationMax + elevationOffset),
              ticks: {
                callback: (value) => `${value} m`,
              },
              grid: {
                drawOnChartArea: false,
              },
            },
            yAxisSpeed: {
              type: 'linear',
              position: 'right',
              min: Math.max(0, Math.floor(speedMin - speedOffset)),
              max: Math.max(Math.ceil(speedMax + speedOffset), 65),
              ticks: {
                callback: (value) => `${value} km/h`,
                backdropColor: green[500],
              },
            },
          },
          interaction: {
            intersect: true,
            mode: 'index',
          },
          plugins: {
            legend: {
              display: true,
              position: 'top',
            },
            tooltip: {
              callbacks: {
                title: ([
                  {
                    parsed: { x },
                  },
                ]) => toDistance(x),
                label: (value) => {
                  return value.datasetIndex === 0
                    ? `${value.parsed.y} m`
                    : `${value.parsed.y} km/h`;
                },
              },
            },
            filler: {
              propagate: false,
            },
          },
        },
      });
    }

    return () => {
      if (_chart) _chart.destroy();
    };
  }, [trace, distances, chartRef.current]);

  return <StyledChart id={chartId} ref={chartRef} />;
}

const StyledChart = styled.canvas`
  height: 150px;
  width: 100%;
`;

export default ElevationsSpeedsChart;
