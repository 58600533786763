import { AuthService, useAmplitudeTracker } from '@geovelo-frontends/commons';
import { ArrowBackIos } from '@mui/icons-material';
import { Box, TextField, Typography } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppContext } from '../../app/context';
import Button from '../../components/button';
import LoginLayout from '../../layouts/login';

interface IValues {
  email: string;
}

function ForgotPassword(): JSX.Element {
  const [emailSent, setEmailSent] = useState(false);
  const {
    user: { current: currentUser },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isSubmitting, values, touched, errors, handleChange, handleSubmit } = useFormik<IValues>({
    initialValues: { email: '' },
    enableReinitialize: true,
    onSubmit,
  });
  const { trackEvent } = useAmplitudeTracker();

  useEffect(() => {
    if (currentUser && currentPartner) {
      trackEvent('Page Visited', {
        pathname: window.location.pathname,
        partner_id: currentPartner.id,
        partner_code: currentPartner.code,
      });
    }
  }, [currentUser, currentPartner]);

  async function onSubmit({ email }: IValues, { setSubmitting }: FormikHelpers<IValues>) {
    setSubmitting(true);

    try {
      await AuthService.sendResetPasswordEmail(email);

      setEmailSent(true);
    } catch (err) {
      enqueueSnackbar(t('commons.forgot_password_form.server_error') || '');
      setSubmitting(false);
    }
  }

  return (
    <LoginLayout>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        margin="40px auto"
        maxWidth="100%"
        width={400}
      >
        {!emailSent && (
          <Box alignSelf="flex-start">
            <Button
              color="inherit"
              component={Link}
              startIcon={<ArrowBackIos />}
              to={`/sign-in`}
              variant="text"
            >
              {t('commons.actions.go_back')}
            </Button>
          </Box>
        )}
        <Typography color="primary" variant="h6">
          <Trans
            i18nKey={
              emailSent
                ? 'commons.forgot_password_form.title_email_sent'
                : 'commons.forgot_password_form.title'
            }
          />
        </Typography>
        <Typography>
          <Trans
            i18nKey={
              emailSent
                ? 'commons.forgot_password_form.description_email_sent'
                : 'commons.forgot_password_form.description'
            }
          />
        </Typography>
        {emailSent ? (
          <Box alignSelf="flex-start">
            <Button
              color="primary"
              component={Link}
              size="large"
              to={`/sign-in`}
              variant="contained"
            >
              {t('commons.actions.sign_in')}
            </Button>
          </Box>
        ) : (
          <Box
            component="form"
            display="flex"
            flexDirection="column"
            gap={4}
            onSubmit={handleSubmit}
            width="100%"
          >
            <Box display="flex" flexDirection="column" gap={2}>
              {errors.email && touched.email && (
                <Typography color="error">{errors.email}</Typography>
              )}
              <TextField
                disabled={isSubmitting}
                error={Boolean(errors.email && touched.email)}
                id="email"
                label={<Trans i18nKey="commons.sign_in_form.email" />}
                name="email"
                onChange={handleChange}
                size="small"
                type="email"
                value={values.email}
                variant="outlined"
              />
            </Box>
            <Box alignSelf="flex-start">
              <Button
                color="primary"
                disabled={isSubmitting}
                size="large"
                type="submit"
                variant="contained"
              >
                <Trans i18nKey="commons.forgot_password_form.actions.send" />
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </LoginLayout>
  );
}

export default ForgotPassword;
