import { Facilities, Period, TPeriod, prevMonth, useUnits } from '@geovelo-frontends/commons';
import { ArrowBackIosNew } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import PeriodForm, { IPeriodFormValues } from '../../../../../components/form/period';
import TabIntroduction from '../../../../../components/tab-introduction';
import Table, { TCells, THeader, TRow } from '../../../../../components/table';
import usePaginatedTable from '../../../../../hooks/table/paginated';
import useSortableTable from '../../../../../hooks/table/sortable';
import { facilities } from '../../../../../models/facilities';
import { TCartographicDataPageContext } from '../../../context';
import useFacilities from '../../../hooks/facilities';

type TKey = 'name' | Facilities | 'total';

const keys: TKey[] = [
  'name',
  'total',
  Facilities.Cycleways,
  Facilities.Greenways,
  Facilities.Lanes,
  Facilities.Opposites,
  Facilities.SharedBusways,
  Facilities.MixedFacilities,
];

const minPeriod = new Period(
  'month',
  moment('2021-10').startOf('month'),
  moment('2021-10').endOf('month'),
);

const maxPeriod = moment().get('date') <= 7 ? prevMonth : undefined;

function FacilitiesStatsTable(context: TCartographicDataPageContext): JSX.Element {
  const {
    defaultPeriods,
    period,
    facilities: { cyclabilityZones, prevCyclabilityZones },
  } = context;
  const [customPeriodTypes] = useState<{
    defaultPeriods: IPeriodFormValues;
    enabledTypes: TPeriod[];
  }>({ defaultPeriods, enabledTypes: ['month'] });
  const [rows, setRows] = useState<TRow<number, TKey>[] | undefined>();
  useFacilities({ context });
  const { page, rowsPerPage, setPage, paginate, onPageChange, onRowsPerPageChange } =
    usePaginatedTable<number, TKey>(10);
  const { orderBy, order, sort, onSortRequest } = useSortableTable<number, TKey>('total', 'desc', {
    setPage,
  });
  const { toDistance } = useUnits();

  useEffect(() => {
    if (cyclabilityZones) {
      const _rows = [...cyclabilityZones].reduce<TRow<number, TKey>[]>(
        (res, { id: key, name, stats }) => {
          const distances = stats[0]?.distances || {
            cycleways: 0,
            greenways: 0,
            lanes: 0,
            opposites: 0,
            sharedBusways: 0,
            livingStreets: 0,
            all: 0,
          };
          const prevDistances = prevCyclabilityZones?.find((zone) => zone.id === key)?.stats?.[0]
            ?.distances;
          const globalDiff = (distances && distances.all - (prevDistances?.all || 0)) || 0;

          res.push({
            key,
            cells: {
              name: { value: name },
              ...facilities.reduce<TCells<TKey>>((res, { key }) => {
                const distance = distances[key];
                const prevDistance = prevDistances?.[key] || 0;
                const diff = distance - prevDistance;

                res[key] = {
                  value: distance,
                  format: (value: number) => (
                    <>
                      <Typography noWrap variant="body2">
                        {toDistance(value * 1000)}
                      </Typography>{' '}
                      {period.comparisonEnabled && (
                        <Typography
                          noWrap
                          color={diff > 0 ? green[500] : diff < 0 ? red[500] : 'textSecondary'}
                          variant="caption"
                        >
                          ({diff >= 0 ? '+' : '-'} {toDistance(Math.abs(diff) * 1000)})
                        </Typography>
                      )}
                    </>
                  ),
                };

                return res;
              }, {}),
              total: {
                value: distances.all,
                format: (value: number) => (
                  <>
                    <Typography noWrap variant="body2">
                      {toDistance(value * 1000)}
                    </Typography>{' '}
                    {period.comparisonEnabled && (
                      <Typography
                        noWrap
                        color={
                          globalDiff > 0 ? green[500] : globalDiff < 0 ? red[500] : 'textSecondary'
                        }
                        variant="caption"
                      >
                        ({globalDiff >= 0 ? '+' : '-'} {toDistance(Math.abs(globalDiff) * 1000)})
                      </Typography>
                    )}
                  </>
                ),
              },
            },
          });

          return res;
        },
        [],
      );

      paginate(sort(_rows, 'name'));

      setRows(paginate(sort(_rows, 'name')));
    } else {
      setRows(undefined);
    }
  }, [cyclabilityZones, prevCyclabilityZones, orderBy, order, page, rowsPerPage]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      gap={2}
      padding={3}
      sx={{ overflowY: 'auto' }}
    >
      <Typography
        color="primary"
        component="h2"
        flexDirection="column"
        fontWeight="700"
        lineHeight="2rem"
        variant="h5"
      >
        <IconButton component={Link} sx={{ marginRight: '8px' }} to="../facilities">
          <ArrowBackIosNew color="primary" />
        </IconButton>
        <Trans i18nKey="cycling-insights.facilities.navigation.analysis" />
      </Typography>
      <TabIntroduction title="cycling-insights.cartographic_data.introduction.facilities" />
      <Box marginTop={5}>
        <PeriodForm
          disablePadding
          customPeriodTypes={customPeriodTypes}
          maxPeriod={maxPeriod}
          minPeriod={minPeriod}
          {...period}
        />
      </Box>
      <TableWrapper>
        <Table
          hasBorder
          count={cyclabilityZones?.length}
          headers={{
            name: {
              label: (
                <Typography fontWeight={600} variant="caption">
                  <Trans i18nKey="cycling-insights.facilities.cyclability_zones.table.zone" />
                </Typography>
              ),
              sortable: true,
              width: 150,
            },
            ...facilities.reduce<{ [key in Facilities]?: THeader }>((res, { key, titleKey }) => {
              res[key] = {
                label: (
                  <Typography fontWeight={600} variant="caption">
                    <Trans i18nKey={titleKey} /> (km)
                  </Typography>
                ),
                sortable: true,
              };

              return res;
            }, {}),
            total: {
              label: (
                <Typography fontWeight={600} variant="caption">
                  <Trans i18nKey="commons.stats.total" />
                </Typography>
              ),
              sortable: true,
            },
          }}
          keys={keys}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          onSortRequest={onSortRequest}
          order={order}
          orderBy={orderBy}
          page={page}
          rows={rows}
          rowsPerPage={rowsPerPage}
          tableProps={{ sx: { tableLayout: 'fixed' } }}
          title="Cyclability zones table"
        />
      </TableWrapper>
    </Box>
  );
}

const TableWrapper = styled.div`
  flex-grow: 1;
  overflow-y: hidden;
`;

export default FacilitiesStatsTable;
