import { useOutletContext } from 'react-router-dom';

import CounterDetails from '../../../../components/counter-details';
import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { IBicycleObservatoryPageContext } from '../../context';

import RoadsUseNewForm from './form';

function RoadsUseAnalysisTab(): JSX.Element {
  const context = useOutletContext<IBicycleObservatoryPageContext & TOutletContext>();

  return (
    <PageContentLayout
      leftPanel={<RoadsUseNewForm {...context} />}
      rightPanel={<CounterDetails {...context} />}
    />
  );
}

export default RoadsUseAnalysisTab;
