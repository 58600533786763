import { NewsCustomForm, NewsModelForm } from '@geovelo-frontends/commons';
import { ArrowDownward, ArrowForward, Close } from '@mui/icons-material';
import { Box, ButtonBase, IconButton, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import customIcon from '../../../../assets/images/new-custom-post.svg';
import modelIcon from '../../../../assets/images/new-model-post.svg';
import { environment } from '../../../../environment';
import { closedDrawerWidth } from '../../../../layouts/default/drawer';

export type TPageType = 'custom' | 'model';

function NewsFormContent(): JSX.Element {
  const [searchParams] = useSearchParams();
  const [postType, setPostType] = useState<TPageType>(() => {
    const searchParam = searchParams.get('type');
    if (searchParam === 'custom') return 'custom';

    return 'model';
  });
  const {
    user: { current: currentUser },
    partner: { current: currentPartner, currentGeogroup },
  } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Box
      bgcolor="#F6F7FB"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      gap={5}
      marginBottom="58px"
      paddingX={14}
      paddingY={8}
      sx={{ overflowY: 'auto' }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography
          color="primary"
          component="h2"
          flexDirection="column"
          fontSize="2rem"
          fontWeight="700"
        >
          <Trans i18nKey="commons.news.actions.create_long" />
        </Typography>
        <Box display="flex" gap={3}>
          <IconButton component={Link} size="large" to="../news-feed">
            <Close />
          </IconButton>
        </Box>
      </Box>
      <Box alignSelf="stretch" display="flex" gap={3} width="100%">
        <ButtonBase
          onClick={() => setPostType('model')}
          sx={{
            borderRadius: 4,
            flexShrink: 0,
            width: 'calc((100% - 24px) / 2)',
            maxWidth: '408px',
          }}
        >
          <Box
            alignItems="center"
            bgcolor={postType === 'model' ? '#528BE8' : '#FFF'}
            borderRadius={4}
            display="flex"
            flexGrow={1}
            gap={3}
            padding="12px"
          >
            <img height="44px" src={modelIcon} />
            <Typography
              color={postType === 'model' ? 'white' : 'black'}
              fontSize="1rem"
              fontWeight={700}
            >
              {t('commons.news_form.title_creation_model')}
            </Typography>
            <Box flexGrow={1} />
            {postType === 'model' ? <ArrowDownward sx={{ color: 'white' }} /> : <ArrowForward />}
          </Box>
        </ButtonBase>
        <ButtonBase
          onClick={() => setPostType('custom')}
          sx={{
            borderRadius: 4,
            flexShrink: 0,
            width: 'calc((100% - 24px) / 2)',
            maxWidth: '408px',
          }}
        >
          <Box
            alignItems="center"
            bgcolor={postType === 'custom' ? '#528BE8' : '#FFF'}
            borderRadius={4}
            display="flex"
            flexGrow={1}
            gap={3}
            padding="12px"
          >
            <img height="44px" src={customIcon} />
            <Typography
              color={postType === 'custom' ? 'white' : 'black'}
              fontSize="1rem"
              fontWeight={700}
            >
              {t('commons.news_form.title_creation_custom')}
            </Typography>
            <Box flexGrow={1} />
            {postType === 'custom' ? <ArrowDownward sx={{ color: 'white' }} /> : <ArrowForward />}
          </Box>
        </ButtonBase>
      </Box>
      <Box>
        {postType === 'custom' ? (
          <NewsCustomForm
            backendUrl={environment.backendUrl}
            currentPartner={currentPartner}
            currentUser={currentUser}
            drawerWidth={closedDrawerWidth}
            geogroup={currentGeogroup}
            prevPath="../animation"
            redirectUrl={currentPartner ? `/${currentPartner.code}/promotion/news-feed` : '/'}
            userType={'admin'}
          />
        ) : (
          <NewsModelForm
            alternate
            currentPartner={currentPartner}
            currentUser={currentUser}
            drawerWidth={closedDrawerWidth}
            geogroup={currentGeogroup}
            prevPath="../animation"
            redirectUrl={currentPartner ? `/${currentPartner.code}/promotion/news-feed` : '/'}
          />
        )}
      </Box>
    </Box>
  );
}

export default NewsFormContent;
