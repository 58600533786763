import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { IBicycleObservatoryPageContext } from '../../context';

import OriginDestinationAnalysisForm from './form';

function OriginDestinationAnalysisTab(): JSX.Element {
  const context = useOutletContext<IBicycleObservatoryPageContext & TOutletContext>();

  return (
    <PageContentLayout
      leftPanel={<OriginDestinationAnalysisForm {...context} />}
      main={
        <StyledCanvas
          id="origin-destination-analysis-deck-canvas"
          ref={context.oldOriginDestination.canvasRef}
        />
      }
    />
  );
}

const StyledCanvas = styled.canvas`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export default OriginDestinationAnalysisTab;
