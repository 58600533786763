import { Facilities, useUnits } from '@geovelo-frontends/commons';
import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import { Chart } from 'chart.js';
import { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Progression from '../../../../components/progression';
import { TCartographicDataPageContext } from '../../context';

const facilities: Array<{ color: string; key: Facilities; labelKey: string; tooltipKey?: string }> =
  [
    {
      key: Facilities.Cycleways,
      color: '#1DC97E',
      labelKey: 'commons.facilities.cycleway_other',
      tooltipKey: 'commons.facilities.cycleway_tooltip',
    },
    { key: Facilities.Greenways, color: '#03865F', labelKey: 'commons.facilities.greenway_other' },
    { key: Facilities.Lanes, color: '#32B6EF', labelKey: 'commons.facilities.lane_other' },
    {
      key: Facilities.Opposites,
      color: '#003AAB',
      labelKey: 'commons.facilities.opposite_other',
      tooltipKey: 'commons.facilities.opposite_tooltip',
    },
    {
      key: Facilities.SharedBusways,
      color: '#2870FD',
      labelKey: 'commons.facilities.shared_busway_other',
    },
    {
      key: Facilities.MixedFacilities,
      color: '#7E48F0',
      labelKey: 'commons.facilities.mixed_facilities_other',
      tooltipKey: 'commons.facilities.mixed_facilities_tooltip',
    },
  ];

const roadsTypesChartId = 'roads-types-chart';

function FacilitiesChart({
  period,
  facilities: { prevStats, stats },
}: TCartographicDataPageContext): JSX.Element {
  const { t } = useTranslation();
  const { toDistance } = useUnits();
  const roadsTypesChartRef = useRef<Chart<'doughnut'>>();

  useEffect(() => {
    if (stats) {
      const { distances } = stats;

      const roadsTypesChartCtx = document.getElementById(roadsTypesChartId);
      if (roadsTypesChartCtx && roadsTypesChartCtx instanceof HTMLCanvasElement) {
        roadsTypesChartRef.current?.destroy();

        roadsTypesChartRef.current = new Chart(roadsTypesChartCtx, {
          type: 'doughnut',
          data: {
            labels: facilities.map(({ labelKey }) => t(labelKey)),
            datasets: [
              {
                data: facilities.map(({ key }) => distances[key]),
                backgroundColor: facilities.map(({ color }) => color),
              },
            ],
          },
          options: {
            cutout: '80%',
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: ({ parsed, label }) =>
                    ` ${label}: ${Math.round((parsed / distances.all) * 1000) / 10} %`,
                },
              },
              title: {
                display: false,
              },
            },
          },
        });
      }
    }
  }, [stats]);

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box
          alignSelf="center"
          height={200}
          maxWidth="100%"
          overflow="hidden"
          position="relative"
          sx={{ overflow: 'hidden' }}
          width={200}
        >
          {stats ? (
            <>
              <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
                left="36px"
                position="absolute"
                textAlign="center"
                width="128px"
              >
                <Typography fontWeight={700} variant="body1">
                  {toDistance(stats.distances.all * 1000)}
                </Typography>
                <Typography variant="body2">
                  <Trans i18nKey="cycling-insights.facilities.cyclability_zones.total_distance" />
                </Typography>
              </Box>
              <canvas id={roadsTypesChartId} style={{ position: 'relative', zIndex: 2 }} />
            </>
          ) : (
            <Skeleton height={200} variant="circular" />
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={3} justifyContent="center">
          <Progression
            formatDiff={toDistance}
            label={
              period.comparisonEnabled
                ? period.values.prev.title
                : t('commons.periods.last_year').toLowerCase()
            }
            prevStat={
              prevStats?.global.distances.all ? prevStats?.global.distances.all * 1000 : undefined
            }
            stat={stats?.distances.all ? stats.distances.all * 1000 : undefined}
          />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap" gap={1} marginTop={4}>
        {facilities.map(({ key, color, labelKey, tooltipKey }) => (
          <Box
            alignItems="center"
            display="flex"
            gap={1}
            key={key}
            minHeight={40}
            width="calc((100% - 16px) / 3)"
          >
            <Box bgcolor={color} borderRadius="5px" height="10px" minWidth="10px" width="10px" />
            <Box display="flex" flexDirection="column">
              {tooltipKey ? (
                <Tooltip title={<Trans i18nKey={tooltipKey} />}>
                  <Typography variant="caption">
                    <Trans i18nKey={labelKey} />
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="caption">
                  <Trans i18nKey={labelKey} />
                </Typography>
              )}
              <Box alignItems="center" display="flex" flexDirection="row">
                <Typography variant="body2">
                  {stats ? (
                    `${toDistance(stats?.distances[key] * 1000)}`
                  ) : (
                    <Skeleton variant="text" width={40} />
                  )}
                </Typography>
                {period.comparisonEnabled && (
                  <Typography
                    color={
                      (prevStats?.global.distances[key] || 0) > (stats?.distances[key] || 0)
                        ? '#A42C49'
                        : '#038B63'
                    }
                    fontWeight={600}
                    variant="caption"
                  >
                    <span style={{ margin: '0 2px 0 4px' }}>
                      {(prevStats?.global.distances[key] || 0) > (stats?.distances[key] || 0)
                        ? '-'
                        : '+'}
                    </span>
                    {toDistance(
                      Math.abs(
                        (stats?.distances[key] || 0) - (prevStats?.global.distances[key] || 0),
                      ) * 1000,
                    )}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
}

export default FacilitiesChart;
