import { Close, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Trans } from 'react-i18next';

function RightPanelLayout({
  disableCollapse,
  expanded,
  expand,
  header,
  actions,
  content,
  children,
  footer,
  onClose,
}: {
  actions?: JSX.Element;
  disableCollapse?: boolean;
  expanded?: boolean;
  expand: (expanded: boolean) => void;
  children?: JSX.Element;
  content?: JSX.Element;
  header?: JSX.Element;
  footer?: JSX.Element;
  onClose?: () => void;
}): JSX.Element {
  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', padding: '24px' }}>
      <CardHeader
        disableTypography
        sx={{
          flexShrink: 0,
          padding: 0,
          '.MuiCardHeader-content': {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 2,
          },
        }}
        title={
          <>
            {header || <div />}
            <Box alignItems="center" display="flex" flexShrink={0} flexWrap="nowrap" gap={0.5}>
              {disableCollapse ? (
                <Tooltip placement="left" title={<Trans i18nKey="commons.actions.close" />}>
                  <IconButton onClick={onClose} size="small">
                    <Close />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  placement="left"
                  title={
                    <Trans
                      i18nKey={
                        expanded ? 'commons.actions.less_details' : 'commons.actions.more_details'
                      }
                    />
                  }
                >
                  <IconButton onClick={() => expand(!expanded)} size="small">
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </>
        }
      />
      <CardContent sx={{ overflowY: 'auto', padding: 0, paddingBottom: '0 !important' }}>
        {content}
        <Collapse in={expanded} sx={{ overflowY: 'auto' }}>
          {children}
        </Collapse>
        {footer}
      </CardContent>
      {actions && <CardActions sx={{ padding: '16px 0 0' }}>{actions}</CardActions>}
    </Card>
  );
}

export default RightPanelLayout;
