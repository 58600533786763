import { IPartnerFlowsStats, Period, useUnits } from '@geovelo-frontends/commons';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../app/context';

import Card from './card';
import Stat from './stat';
import { IActivityStats } from './types';

function Journeys({
  period,
  activityStatistics,
  flowsStatistics,
}: {
  activityStatistics?: IActivityStats;
  flowsStatistics?: { current: IPartnerFlowsStats | null; prev: IPartnerFlowsStats | null } | null;
  period: Period;
}): JSX.Element {
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { toDistance, toSpeed, toTime } = useUnits();

  return (
    <>
      <Card
        currentData={
          activityStatistics
            ? activityStatistics.current.distance && activityStatistics.current.nbRoutes
              ? toDistance(
                  activityStatistics.current.distance / activityStatistics.current.nbRoutes,
                )
              : '-'
            : undefined
        }
        progression={
          activityStatistics
            ? activityStatistics.current.distance / activityStatistics.current.nbRoutes -
              activityStatistics.prev.distance / activityStatistics.prev.nbRoutes
            : undefined
        }
        progressionUnit="distance"
        titleKey="commons.stats.mean_distance"
        to={`/${currentPartner?.code}/bicycle-observatory/${
          currentPartner?.dashboardTabsPermissions.usageFluidity !== 'none'
            ? 'fluidity'
            : 'geovelo-activity'
        }?period=month&from=${period.from.format('YYYY-MM-DD')}${
          currentPartner?.dashboardTabsPermissions.usageFluidity !== 'none'
            ? ''
            : `&prev-from=${period.getPrevPeriod().from.format('YYYY-MM-DD')}&compare=true`
        }`}
      >
        <Stat
          label={t('commons.stats.average_speed_label')}
          progression={
            activityStatistics &&
            (activityStatistics.current.distance / activityStatistics.current.duration -
              activityStatistics.prev.distance / activityStatistics.prev.duration) *
              3.6
          }
          progressionUnit="speed"
          value={
            activityStatistics &&
            ((activityStatistics.current.distance &&
              activityStatistics.current.duration &&
              toSpeed(
                (activityStatistics.current.distance / activityStatistics.current.duration) * 3.6,
              )) ||
              '-')
          }
        />
        <Stat
          label={t('commons.stats.mean_duration')}
          progression={
            activityStatistics &&
            Math.round(
              activityStatistics.current.duration / activityStatistics.current.nbRoutes -
                activityStatistics.prev.duration / activityStatistics.prev.nbRoutes,
            )
          }
          progressionUnit="duration"
          value={
            activityStatistics &&
            (activityStatistics.current.duration && activityStatistics.current.nbRoutes
              ? toTime(activityStatistics.current.duration / activityStatistics.current.nbRoutes)
              : '-')
          }
        />
        {currentPartner?.dashboardTabsPermissions.usageFluidity !== 'none' &&
          flowsStatistics !== null &&
          flowsStatistics?.current && (
            <Stat
              label={t('commons.stats.mean_stop_time_per_km')}
              progression={
                (flowsStatistics?.prev &&
                  flowsStatistics.current.meanStopTimeByKilometer -
                    flowsStatistics.prev.meanStopTimeByKilometer) ||
                undefined
              }
              progressionUnit="duration"
              value={flowsStatistics && toTime(flowsStatistics.current.meanStopTimeByKilometer)}
            />
          )}
      </Card>
    </>
  );
}

export default Journeys;
