import {
  NewReviewFormDialog,
  Report,
  RouteReport,
  RouteReportService,
  TStatus,
  useClipboard,
} from '@geovelo-frontends/commons';
import { AccountCircle, CheckCircle, Error as ErrorIcon, Message } from '@mui/icons-material';
import { Box, Card, CardContent, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import { environment } from '../../../../environment';
import RightPanelLayout from '../../../../layouts/right-panel';
import { TQAPageContext } from '../../context';

function ItineraryReportDetail({
  reports: { reports, selectedReport, setReports },
}: TQAPageContext): JSX.Element {
  const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [newCommentFormDialogOpen, openNewCommentFormDialog] = useState(false);
  const {
    user: { current: currentUser },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { copy: copyToClipboard } = useClipboard(
    () => enqueueSnackbar(t('commons.copied_to_clipboard')),
    () => enqueueSnackbar(t('commons.not_copied_to_clipboard')),
  );
  const [expanded, expand] = useState(true);

  async function updateReport(id: number, data: { status?: TStatus }): Promise<void> {
    try {
      const updatedReport = await RouteReportService.updateRouteReport(id, data);
      reports?.splice(
        reports?.findIndex(({ id: updatedId }) => updatedId === id),
        1,
        updatedReport,
      );
      setReports([...(reports || [])]);
    } catch {
      enqueueSnackbar(t('cycling-insights.reports.itinerary_reports.not_updated'));
    }
    setSettingsMenuAnchorEl(null);
  }

  function seeOnJOSM(JOSMParams: string): void {
    window.open('http://127.0.0.1:8111/load_and_zoom?' + JOSMParams, '_blank');
    setSettingsMenuAnchorEl(null);
  }

  function seeOnGeovelo(computedRouteId: string): void {
    window.open(id ? `${environment.frontendUrl}/route/${computedRouteId}` : '', '_blank');
  }

  function handleNewReviewFormDialogClose(updatedReport?: RouteReport | Report) {
    if (updatedReport instanceof RouteReport) {
      if (reports && updatedReport) {
        const newReports = [...reports];
        newReports.splice(
          newReports.findIndex(({ id }) => id === selectedReport?.id),
          1,
          updatedReport,
        );

        setReports(newReports);
      }
      // if (updatedReport) reloadTableReport?.(updatedReport);
    }
    openNewCommentFormDialog(false);
  }

  if (!selectedReport) return <></>;

  const { id, isClosed, created, creator, source, description, response } = selectedReport;

  return (
    <>
      <RightPanelLayout
        actions={
          <Box display="flex" flexGrow={1} justifyContent="space-between">
            <Button
              onClick={(event) => setSettingsMenuAnchorEl(event.currentTarget)}
              size="large"
              variant="outlined"
            >
              <Trans i18nKey="commons.actions.see_on" />
            </Button>
            <Button
              onClick={() => {
                if (selectedReport)
                  updateReport(selectedReport.id, {
                    status: selectedReport.isClosed ? 'OPEN' : 'CLOSED',
                  });
              }}
              size="large"
              variant="outlined"
            >
              <Trans
                i18nKey={
                  selectedReport?.isClosed
                    ? 'commons.report.actions.to_open'
                    : 'commons.report.actions.to_solved'
                }
              />
            </Button>
          </Box>
        }
        expand={expand}
        expanded={expanded}
        header={
          <Box alignItems="center" display="flex" gap={2}>
            {isClosed ? (
              <Tooltip placement="right" title={<Trans i18nKey="commons.statuses.closed" />}>
                <CheckCircle style={{ color: green[500] }} />
              </Tooltip>
            ) : (
              <Tooltip placement="right" title={<Trans i18nKey="commons.statuses.open" />}>
                <ErrorIcon style={{ color: red[500] }} />
              </Tooltip>
            )}
            <Typography variant="h6">
              <Trans
                i18nKey={'cycling-insights.reports.itinerary_reports.default_title'}
                values={{ id }}
              />
            </Typography>
          </Box>
        }
      >
        <Box display="flex" flexDirection="column" gap={2} padding={2}>
          <Box display="flex" flexDirection="column">
            <Typography color="textSecondary" variant="caption">
              <Trans
                components={[<i key={0} />]}
                i18nKey="commons.created_by_on"
                values={{
                  creator: creator || 'unknown',
                  created: moment(created).format('ll'),
                }}
              />
              {source && (
                <>
                  {' '}
                  (
                  <Typography color="textSecondary" variant="caption">
                    <Trans i18nKey={source.title} />
                  </Typography>
                  )
                </>
              )}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={1} padding={1}>
            {description && (
              <StyledComment>
                <Tooltip title={creator}>
                  <AccountCircle color="secondary" />
                </Tooltip>
                <Card sx={{ display: 'flex', width: 'min-content' }} variant="outlined">
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="body2">{description}</Typography>
                    <Typography color="textSecondary" variant="caption">
                      {moment(created).format('LLL')}
                      <>
                        {source && (
                          <>
                            {' '}
                            (<Trans i18nKey={source.title} />)
                          </>
                        )}
                      </>
                    </Typography>
                  </CardContent>
                </Card>
              </StyledComment>
            )}
            {response && (
              <StyledResponse>
                <Card sx={{ display: 'flex', width: 'min-content' }} variant="outlined">
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="body2">{response}</Typography>
                  </CardContent>
                </Card>
                <AccountCircle color="secondary" />
              </StyledResponse>
            )}
            {!response && (
              <Box display="flex" justifyContent="flex-end">
                <Button
                  color="primary"
                  onClick={() => openNewCommentFormDialog(true)}
                  size="small"
                  startIcon={<Message />}
                  variant="contained"
                >
                  <Trans i18nKey="commons.actions.comment" />
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </RightPanelLayout>
      <Menu
        keepMounted
        anchorEl={settingsMenuAnchorEl}
        onClose={() => setSettingsMenuAnchorEl(null)}
        open={Boolean(settingsMenuAnchorEl)}
      >
        {selectedReport?.JOSMParams && (
          <MenuItem key="see_on_josm" onClick={() => seeOnJOSM(selectedReport.JOSMParams)}>
            <Typography>
              <Trans i18nKey="commons.report.actions.see_on_josm" />
            </Typography>
          </MenuItem>
        )}
        <MenuItem
          key="see_on_geovelo"
          onClick={() => {
            if (selectedReport) seeOnGeovelo(selectedReport.computedRouteId);
            setSettingsMenuAnchorEl(null);
          }}
        >
          <Typography>
            <Trans i18nKey="commons.report.actions.see_on_geovelo" />
          </Typography>
        </MenuItem>
        <MenuItem
          key="copy_to_clipboard"
          onClick={() => {
            copyToClipboard(`${environment.bicycleFacilitiesUrl}fr/route-issues/${id}`);
            setSettingsMenuAnchorEl(null);
          }}
        >
          <Typography>
            <Trans i18nKey="commons.report.actions.copy_to_clipboard" />
          </Typography>
        </MenuItem>
      </Menu>
      <NewReviewFormDialog
        canClose={currentUser?.isGeovelo}
        onClose={handleNewReviewFormDialogClose}
        open={newCommentFormDialogOpen}
        report={selectedReport}
      />
    </>
  );
}

const StyledComment = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  > * {
    &:last-child {
      flex-shrink: 0;
    }

    &:last-child {
      flex-grow: 1;
    }
  }
`;

const StyledResponse = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  > * {
    &:first-child {
      flex-shrink: 0;
    }

    &:first-child {
      flex-grow: 1;
    }
  }
`;

export default ItineraryReportDetail;
