import { PartnerContract, Permissions } from '@geovelo-frontends/commons';
import { CalendarToday, SentimentDissatisfied } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { AppContext } from '../../../app/context';
import Button from '../../../components/button';

const permissionsLabels: { [key in Exclude<Permissions, Permissions.CVTC>]: string } = {
  [Permissions.HOMEPAGE]: 'commons.permissions.homepage',
  [Permissions.HOMEPAGE_ESSENTIELLE]: 'commons.permissions.homepage',
  [Permissions.HOMEPAGE_EXTRAPOLATED]: 'commons.permissions.homepage_extrapolated',
  [Permissions.BICYCLE_FACILITIES]: 'commons.permissions.bicycle_facilities',
  [Permissions.ROADS_QUALITY]: 'commons.permissions.roads_quality',
  [Permissions.ISOCHRONES]: 'commons.permissions.isochrones',
  [Permissions.NETWORK_CYCLABILITY]: 'commons.permissions.network_cyclability',
  [Permissions.ACCIDENTALITY]: 'commons.permissions.accidentality',
  [Permissions.ACCIDENTALITY_EXTRAPOLATED]: 'commons.permissions.accidentality_extrapolated',
  [Permissions.GEOVELO_ACTIVITY]: 'commons.permissions.geovelo_activity',
  [Permissions.ROAD_USE]: 'commons.permissions.road_use',
  [Permissions.ROAD_USE_EXTRAPOLATED]: 'commons.permissions.road_use_extrapolated',
  [Permissions.BICYCLE_TRAFFIC_FLUIDITY]: 'commons.permissions.bicycle_traffic_fluidity',
  [Permissions.ORIGIN_DESTINATION]: 'commons.permissions.origin_destination',
  [Permissions.SUDDEN_BRAKINGS]: 'commons.permissions.sudden_brakings',
  [Permissions.TRAVEL_SIMULATION]: 'commons.permissions.travel_simulations',
  [Permissions.CONTRACTS_MANAGEMENT]: 'commons.permissions.contracts_management',
  [Permissions.ACCESS_MANAGEMENT]: 'commons.permissions.access_management',
  [Permissions.DOWNLOADS]: 'commons.permissions.downloads',
  [Permissions.REFERENCE_ROUTES]: 'commons.permissions.reference_routes',
  [Permissions.USERS_LOGS]: 'commons.permissions.users_logs',
  [Permissions.EXCLUSION_AREAS]: 'commons.permissions.exclusion_areas',
  [Permissions.ITINERARY_REPORTS]: 'commons.permissions.itinerary_reports',
  [Permissions.OSM_REPORTS]: 'commons.permissions.osm_reports',
  [Permissions.REPORTS]: 'commons.permissions.reports',
  [Permissions.RIDES]: 'commons.permissions.rides',
  [Permissions.BIKE_ROUTES]: 'commons.permissions.bike_routes',
  [Permissions.PARKINGS]: 'commons.permissions.parkings',
  [Permissions.WIDGETS]: 'commons.permissions.widgets',
  [Permissions.API]: 'commons.permissions.api',
  [Permissions.API_CREATE_API_KEY]: 'commons.permissions.api_key_creation',
  [Permissions.ANIMATION]: 'commons.permissions.animation',
  [Permissions.FACILITIES_SUGGESTIONS]: 'commons.permissions.facilities_suggestions',
  [Permissions.SIG_FILES]: 'commons.permissions.sig_files',
  [Permissions.DISCONTINUITY]: 'commons.permissions.discontinuity',
};

const maxContractPermission = [
  Permissions.ROAD_USE,
  Permissions.BICYCLE_TRAFFIC_FLUIDITY,
  Permissions.ORIGIN_DESTINATION,
  Permissions.NETWORK_CYCLABILITY,
  Permissions.ACCIDENTALITY,
  Permissions.ROADS_QUALITY,
];

function ManageContractsData({
  selectedContractIndex,
}: {
  selectedContractIndex: number;
}): JSX.Element {
  const [contract, setContract] = useState<PartnerContract | null>();
  const {
    partner: { contracts, contractTemplates },
  } = useContext(AppContext);

  useEffect(() => {
    if (contracts) setContract(contracts[selectedContractIndex || 0] || null);
  }, [contracts, selectedContractIndex]);

  if (contract === undefined) return <></>;

  if (contract === null) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        gap={2}
        justifyContent="center"
        padding={2}
        sx={{ backgroundColor: 'whitesmoke', overflowY: 'auto' }}
      >
        <SentimentDissatisfied color="action" fontSize="large" />
        <Typography align="center" color="textSecondary">
          <Trans i18nKey="cycling-insights.admin.manage_contracts.contract_detail.empty_state" />
        </Typography>
      </Box>
    );
  }

  const { startDateTime, endDateTime, apiUsageLimit, contractTemplateId } = contract;
  const contractTemplate = contractTemplates?.find(({ id }) => contractTemplateId === id);

  return (
    <Box
      bgcolor="white"
      border="1px solid #CFCFCF"
      borderRadius={4}
      display="flex"
      flexDirection="column"
      flexGrow={1}
      gap={4}
      padding={4}
    >
      <Box>
        {contractTemplate && (
          <Typography fontSize="1.5rem" fontWeight={700}>
            {contractTemplate.title}
          </Typography>
        )}
        <Typography color="#326AC2" fontSize="1rem" fontWeight={400}>
          <CalendarToday sx={{ color: '#326AC2', fontSize: '16px', marginRight: '8px' }} />
          {endDateTime ? (
            <Trans
              i18nKey="commons.periods.from_to"
              values={{ from: startDateTime.format('L'), to: endDateTime.format('L') }}
            />
          ) : (
            <Trans i18nKey="commons.periods.from" values={{ from: startDateTime.format('L') }} />
          )}
        </Typography>
        {contractTemplate?.permissions.includes(Permissions.API) && (
          <Typography color="textSecondary" variant="body2">
            <Trans
              count={apiUsageLimit || undefined}
              i18nKey="cycling-insights.admin.manage_contracts.contract_detail.api_usage_limit"
              values={{ count: apiUsageLimit }}
            />
          </Typography>
        )}
      </Box>
      <Box display="flex" gap={4}>
        <Box display="flex" flex={1} flexDirection="column" gap={2}>
          <Typography fontSize="1.125rem" fontWeight={700}>
            <Trans i18nKey="cycling-insights.admin.manage_contracts.features" />
          </Typography>
          <ul>
            {contractTemplate?.permissions.map((permission) => {
              if (permission === Permissions.CVTC) return <Fragment key={permission} />;
              else
                return (
                  <li key={permission}>
                    <Typography>
                      <Trans i18nKey={permissionsLabels[permission]} />
                    </Typography>
                  </li>
                );
            })}
          </ul>
        </Box>
        {contractTemplate?.code?.includes('essentielle') && (
          <Box
            bgcolor="#FFF5D0"
            borderRadius={4}
            display="flex"
            flex={1}
            flexDirection="column"
            gap={2}
            padding={3}
          >
            <Typography fontSize="1.125rem" fontWeight={700}>
              <Trans i18nKey="cycling-insights.admin.manage_contracts.upgrade" />
            </Typography>
            <Typography fontSize="1rem" fontWeight={400}>
              <Trans i18nKey="cycling-insights.admin.manage_contracts.upgrade_subtitle" />
            </Typography>
            <ul>
              {maxContractPermission?.map((permission) => {
                if (permission === Permissions.CVTC) return <Fragment key={permission} />;
                else
                  return (
                    <li key={permission}>
                      <Typography>
                        <Trans i18nKey={permissionsLabels[permission]} />
                      </Typography>
                    </li>
                  );
              })}
            </ul>
            <Button
              color="primary"
              component="a"
              href={`mailto:emma.gaucher@geovelo.fr`}
              size="medium"
              variant="contained"
            >
              <Trans i18nKey="cycling-insights.admin.manage_contracts.contact" />
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ManageContractsData;
