import { TFileType } from '@geovelo-frontends/commons';

export const types: { [key in TFileType]: { titleKey: string } } = {
  challenges: { titleKey: 'cycling-insights.downloads.types.challenges' },
  communityMembers: { titleKey: 'cycling-insights.downloads.types.community_members' },
  computedRoutes: { titleKey: 'cycling-insights.downloads.types.computed_routes' },
  contributions: { titleKey: 'cycling-insights.downloads.types.contributions' },
  facilities: { titleKey: 'cycling-insights.downloads.types.facilities' },
  isochrone: { titleKey: 'cycling-insights.downloads.types.isochrone' },
  QA: { titleKey: 'cycling-insights.downloads.types.qa' },
  reports: { titleKey: 'cycling-insights.downloads.types.reports' },
  sessions: { titleKey: 'cycling-insights.downloads.types.sessions' },
  userTraces: { titleKey: 'cycling-insights.downloads.types.user_traces' },
  userData: { titleKey: 'cycling-insights.downloads.types.user_data' },
  virtualCounters: { titleKey: 'cycling-insights.downloads.types.virtual_counters' },
};
