import { Box, Skeleton, Typography } from '@mui/material';
import { Chart } from 'chart.js';
import { cellToParent } from 'h3-js';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import { IBicycleObservatoryPageContext } from '../../context';

const chartId = 'od-chart';

function OriginDestinationChart({
  originDestination: { h3Resolution, selectedH3Indices, flows: _flows },
}: IBicycleObservatoryPageContext): JSX.Element {
  const [flows, setFlows] = useState<{
    externalFromPartner: number;
    externalFromSelectedZones: number;
    internal: number;
    total: number;
  }>();
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const chartRef = useRef<Chart<'doughnut'>>();
  const { t } = useTranslation();

  useEffect(() => {
    if (_flows) {
      const flows = _flows.reduce(
        (
          res,
          {
            origin: _origin,
            destination: _destination,
            isInternalOrigin,
            isInternalDestination,
            count,
          },
        ) => {
          const origin =
            typeof h3Resolution === 'number' && h3Resolution < 9
              ? cellToParent(`${_origin}`, h3Resolution)
              : _origin;
          const destination =
            typeof h3Resolution === 'number' && h3Resolution < 9
              ? cellToParent(`${_destination}`, h3Resolution)
              : _destination;

          if (
            selectedH3Indices.length > 0 &&
            !selectedH3Indices.includes(origin) &&
            !selectedH3Indices.includes(destination)
          )
            return res;

          res.total += count;

          if (selectedH3Indices.length > 0) {
            if (selectedH3Indices.includes(origin) && selectedH3Indices.includes(destination))
              res.internal += count;
            else if (isInternalOrigin && isInternalDestination)
              res.externalFromSelectedZones += count;
            else res.externalFromPartner += count;
          } else {
            if (isInternalOrigin && isInternalDestination) res.internal += count;
            else res.externalFromPartner += count;
          }

          return res;
        },
        {
          internal: 0,
          externalFromPartner: 0,
          externalFromSelectedZones: 0,
          total: 0,
        },
      );

      setFlows(flows);

      const chartCtx = document.getElementById(chartId);
      if (chartCtx && chartCtx instanceof HTMLCanvasElement) {
        chartRef.current = new Chart(chartCtx, {
          type: 'doughnut',
          data: {
            labels:
              selectedH3Indices.length > 0
                ? [
                    t('cycling-insights.usage.origin_destination.chart.zone_internal_flows', {
                      count: selectedH3Indices.length,
                    }),
                    t('cycling-insights.usage.origin_destination.chart.zone_other_flows', {
                      count: selectedH3Indices.length,
                      partner: currentPartner?.title,
                    }),
                    t('cycling-insights.usage.origin_destination.chart.zone_external_flows', {
                      count: selectedH3Indices.length,
                      partner: currentPartner?.title,
                    }),
                  ]
                : [
                    t('cycling-insights.usage.origin_destination.chart.internal_flows', {
                      partner: currentPartner?.title,
                    }),
                    t('cycling-insights.usage.origin_destination.chart.external_flows', {
                      partner: currentPartner?.title,
                    }),
                  ],
            datasets: flows
              ? [
                  {
                    data:
                      selectedH3Indices.length > 0
                        ? [
                            flows.internal,
                            flows.externalFromSelectedZones,
                            flows.externalFromPartner,
                          ]
                        : [flows.internal, flows.externalFromPartner],
                    backgroundColor:
                      selectedH3Indices.length > 0
                        ? ['#3E7BDF', '#9BC2FF', '#FFD12F']
                        : ['#3E7BDF', '#FFD12F'],
                  },
                ]
              : [
                  {
                    data: [70, 30],
                    backgroundColor: ['#E3E7EE', '#F6F7FB'],
                  },
                ],
          },
          options: {
            cutout: '80%',
            events: flows ? ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'] : [],
            responsive: true,
            maintainAspectRatio: false,
            plugins: flows
              ? {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: ({ parsed }) =>
                        ` ${parsed} (${flows.total ? Math.round((parsed / flows.total) * 1000) / 10 : 0} %)`,
                    },
                  },
                  title: {
                    display: false,
                  },
                }
              : {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false,
                  },
                },
          },
        });
      }
    }

    return () => {
      chartRef.current?.destroy();
      setFlows(undefined);
    };
  }, [_flows, selectedH3Indices]);

  return (
    <Box display="flex" flexDirection="column">
      <Typography fontSize="1.125rem" fontWeight={700} marginBottom={3}>
        {t('cycling-insights.usage.origin_destination.chart.title', {
          context: selectedH3Indices.length > 0 ? 'selected' : '',
          count: selectedH3Indices.length,
        })}
      </Typography>
      <Box
        alignItems="flex-start"
        display="flex"
        flexDirection="row"
        gap={4}
        justifyContent="space-between"
      >
        <Box
          height={200}
          maxWidth="100%"
          minWidth={200}
          overflow="hidden"
          position="relative"
          sx={{ overflow: 'hidden' }}
          width={200}
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
            left="36px"
            position="absolute"
            textAlign="center"
            width="128px"
          >
            <Typography fontWeight={700} variant="body1">
              {flows ? flows.total : <Skeleton variant="text" width={80} />}
            </Typography>
            <Typography variant="body2">
              {t('commons.stats_types.journeys').toLocaleLowerCase()}
            </Typography>
          </Box>
          <canvas id={chartId} style={{ position: 'relative', zIndex: 2 }} />
        </Box>
        <Box display="flex" flexDirection="column" flexGrow={1} gap={2} justifyContent="center">
          <Box display="flex" flexDirection="column">
            <Box
              bgcolor="#3E7BDF"
              borderRadius="5px"
              height="10px"
              marginBottom="2px"
              minWidth="10px"
              width="10px"
            />
            <Typography variant="caption">
              {selectedH3Indices.length > 0
                ? t('cycling-insights.usage.origin_destination.chart.zone_internal_flows', {
                    count: selectedH3Indices.length,
                  })
                : t('cycling-insights.usage.origin_destination.chart.internal_flows', {
                    partner: currentPartner?.title,
                  })}
            </Typography>
            <Typography variant="caption">
              {flows ? (
                `${flows.total ? Math.round((flows.internal / flows.total) * 1000) / 10 : 0} %`
              ) : (
                <Skeleton variant="text" width="100%" />
              )}
            </Typography>
          </Box>
          {selectedH3Indices.length > 0 && (
            <Box display="flex" flexDirection="column">
              <Box
                bgcolor="#9BC2FF"
                borderRadius="5px"
                height="10px"
                marginBottom="2px"
                minWidth="10px"
                width="10px"
              />
              <Typography variant="caption">
                {t('cycling-insights.usage.origin_destination.chart.zone_other_flows', {
                  count: selectedH3Indices.length,
                  partner: currentPartner?.title,
                })}
              </Typography>
              <Typography variant="caption">
                {flows ? (
                  `${flows.total ? Math.round((flows.externalFromSelectedZones / flows.total) * 1000) / 10 : 0} %`
                ) : (
                  <Skeleton variant="text" width="100%" />
                )}
              </Typography>
            </Box>
          )}
          <Box display="flex" flexDirection="column">
            <Box
              bgcolor="#FFD12F"
              borderRadius="5px"
              height="10px"
              marginBottom="2px"
              minWidth="10px"
              width="10px"
            />
            <Typography variant="caption">
              {selectedH3Indices.length > 0
                ? t('cycling-insights.usage.origin_destination.chart.zone_external_flows', {
                    count: selectedH3Indices.length,
                    partner: currentPartner?.title,
                  })
                : t('cycling-insights.usage.origin_destination.chart.external_flows', {
                    partner: currentPartner?.title,
                  })}
            </Typography>
            <Typography variant="caption">
              {flows ? (
                `${flows.total ? Math.round((flows.externalFromPartner / flows.total) * 1000) / 10 : 0} %`
              ) : (
                <Skeleton variant="text" width="100%" />
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default OriginDestinationChart;
