import { useEffect } from 'react';

function useAlertBeforeUnload(): void {
  useEffect(() => {
    function alertUser(event: BeforeUnloadEvent) {
      event.preventDefault();
      event.returnValue = '';
    }

    window.addEventListener('beforeunload', alertUser);

    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);
}

export default useAlertBeforeUnload;
