import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import type { TColorCollection } from '../../components/color-legend';
import { getFacilityLabelKey } from '../../components/form/facilities';
import { IPeriodFormProps } from '../../components/form/period';
import { ISliderBounds, TSliderRange } from '../../components/form/slider';
import { TSectionFeatureCollection } from '../../models/sections';

import useHeatmap from './heatmap';

import { Map } from '!maplibre-gl';

export const defaultColors: TColorCollection = [
  {
    value: '#F56B84',
    min: 0,
    max: 2.5,
    labelKey: 'cycling-insights.facilities.roads_quality.form.roughnesses.bad',
  },
  {
    value: '#FFD159',
    min: 2.5,
    max: 2.75,
    labelKey: 'cycling-insights.facilities.roads_quality.form.roughnesses.medium',
  },
  {
    value: '#46CE9D',
    min: 2.75,
    max: 4,
    labelKey: 'cycling-insights.facilities.roads_quality.form.roughnesses.good',
  },
];

function useRoadsQuality(
  map: Map | null | undefined,
  period: IPeriodFormProps,
): {
  initialized: boolean;
  init: () => void;
  update: (
    { features }: TSectionFeatureCollection,
    props: {
      colors?: TColorCollection;
      currentRange: TSliderRange;
      primaryBounds: ISliderBounds;
    },
  ) => void;
  destroy: () => void;
} {
  const { t } = useTranslation();
  const periodRef = useRef(period);

  useEffect(() => {
    periodRef.current = period;
  }, [period]);

  return useHeatmap(
    map,
    {
      sourceId: 'roads-quality',
      layerId: 'roads-quality',
      primaryCriterion: 'roughness',
      colors: defaultColors,
      interpolateWidths: true,
    },
    function roadsQualityTooltipContent({ labelKey, facility }) {
      return (
        `<h3>${t('cycling-insights.facilities.roads_quality.tooltip.title')}</h3>` +
        (facility && facility !== 'none' ? `<div>${t(getFacilityLabelKey(facility))}</div>` : '') +
        `<div>${t(labelKey || '')}</div>`
      );
    },
  );
}

export default useRoadsQuality;
