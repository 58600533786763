import { Box, DialogProps, TextField } from '@mui/material';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';

import Dialog from '../../../../components/dialog';

function NewScenarioDialog({
  open,
  onConfirm,
  scenarioTitle,
  setScenarioTitle,
  ...props
}: Omit<DialogProps, 'onClose' | 'title'> & {
  onCancel: () => void;
  onConfirm: () => void;
  scenarioTitle: string;
  setScenarioTitle: (title: string) => void;
}): JSX.Element {
  useEffect(() => {
    if (open) setScenarioTitle('');
  }, [open]);

  return (
    <Dialog
      cancelTitle={<Trans i18nKey="commons.actions.cancel" />}
      dialogTitle="new-scenario-dialog-title"
      maxWidth="xs"
      onConfirm={onConfirm}
      open={open}
      scroll="paper"
      title={<Trans i18nKey="cycling-insights.usage.travel_simulations.create" />}
      {...props}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <TextField
          fullWidth
          label={<Trans i18nKey="cycling-insights.usage.travel_simulations.title" />}
          margin="dense"
          onChange={({ target: { value } }) => setScenarioTitle(value)}
          size="small"
          value={scenarioTitle || ''}
          variant="outlined"
        />
      </Box>
    </Dialog>
  );
}

export default NewScenarioDialog;
