import { SvgIcon, SvgIconProps } from '@mui/material';
import { red } from '@mui/material/colors';

function ArrivalIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 32" {...props}>
      <circle cx="12" cy="24" fill="#000" r="5" />
      <circle cx="12" cy="24" fill="#fff" r="3" />
      <path
        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z"
        fill={red[500]}
      />
      <circle cx="12" cy="9" fill={red[900]} r="3" />
    </SvgIcon>
  );
}

export default ArrivalIcon;
