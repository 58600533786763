import { SvgIcon, SvgIconProps } from '@mui/material';
import { green } from '@mui/material/colors';

function DepartureIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="12" fill="#fff" r="8" />
      <circle cx="12" cy="12" fill={green[500]} r="6" />
    </SvgIcon>
  );
}

export default DepartureIcon;
