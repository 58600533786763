import { IBackendSuggestionResult, StatsService } from '@geovelo-frontends/commons';
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { AppContext } from '../../../../app/context';
import useFacilitiesSuggestions, {
  TSuggestionSectionProperties,
} from '../../../../hooks/map/facilities-suggestions';
import { TOutletContext } from '../../../../layouts/page/container';
import { TCartographicDataPageContext } from '../../context';

interface IValues {
  budget: number;
  endDate: string;
  model: string;
  startDate: string;
  title: string;
}

const models = [
  'BAO_SIMPLE_MODEL',
  'L2S',
  'L2C',
  'L2S_DISTANCE_WEIGHT',
  'L2C_DISTANCE_WEIGHT',
  'BAO_RENTABILITY',
  'BAO_RISK',
] as const;

function FacilitiesSuggestionsForm(context: TCartographicDataPageContext & TOutletContext) {
  const {
    facilitiesSuggestions: { selectedSuggestion, selectSuggestion },
  } = context;
  const [initialized, setInitialized] = useState(false);
  const {
    map: { current: currentMap },
    partner: { current: currentPartner, sections },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    initialized: mapInitialized,
    init,
    destroy,
    update,
  } = useFacilitiesSuggestions(currentMap);
  const { isSubmitting, isValid, values, errors, touched, handleChange, handleSubmit } =
    useFormik<IValues>({
      initialValues: selectedSuggestion
        ? {
            model: 'BAO_SIMPLE_MODEL',
            title: selectedSuggestion.title,
            budget: selectedSuggestion.budget,
            startDate: selectedSuggestion.start_date.format('YYYY-MM-DD'),
            endDate: selectedSuggestion.end_date.format('YYYY-MM-DD'),
          }
        : {
            model: 'BAO_SIMPLE_MODEL',
            title: '',
            budget: 0,
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().add(1, 'month').format('YYYY-MM-DD'),
          },
      validationSchema: Yup.object().shape({
        title: Yup.string().required(
          t('cycling-insights.qa.facilities_suggestions.title_required') || '',
        ),
        budget: Yup.number()
          .moreThan(0)
          .required(t('cycling-insights.qa.facilities_suggestions.budget_required') || ''),
        startDate: Yup.string().required(
          t('cycling-insights.qa.facilities_suggestions.start_date_required') || '',
        ),
        endDate: Yup.string().required(
          t('cycling-insights.qa.facilities_suggestions.end_date_required') || '',
        ),
      }),
      validateOnMount: true,
      validateOnChange: true,
      onSubmit,
      enableReinitialize: true,
    });
  const navigate = useNavigate();
  const [suggestionResults, setSuggestionResults] = useState<IBackendSuggestionResult[]>();

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (currentMap) init();

    return () => {
      destroy();
    };
  }, [currentMap]);

  useEffect(() => {
    if (initialized && selectedSuggestion) {
      getSuggestionResults(selectedSuggestion.id);
    }
  }, [initialized]);

  useEffect(() => {
    if (mapInitialized) filterSections();
  }, [mapInitialized, suggestionResults, sections]);

  async function onSubmit(
    { model, title, budget, startDate, endDate }: IValues,
    { setSubmitting, setErrors }: FormikHelpers<IValues>,
  ) {
    if (!currentPartner) return;
    if (!title) {
      setErrors({ title: t('cycling-insights.qa.facilities_suggestions.title_required') });
      return;
    } else if (!budget) {
      setErrors({ budget: t('cycling-insights.qa.facilities_suggestions.budget_required') });
      return;
    } else if (!startDate) {
      setErrors({ startDate: t('cycling-insights.qa.facilities_suggestions.start_date_required') });
      return;
    } else if (!endDate) {
      setErrors({ endDate: t('cycling-insights.qa.facilities_suggestions.end_date_required') });
      return;
    }

    setErrors({});
    setSubmitting(true);

    try {
      if (selectedSuggestion) {
        await StatsService.updateSuggestion({
          partnerId: currentPartner.id,
          suggestionId: selectedSuggestion.id,
          model,
          budget,
          title,
          startDate,
          endDate,
        });
      } else {
        await StatsService.createSuggestion({
          partnerId: currentPartner.id,
          model,
          budget,
          title,
          startDate,
          endDate,
        });
      }
      enqueueSnackbar(
        t(`cycling-insights.qa.facilities_suggestions.${selectedSuggestion ? 'updated' : 'added'}`),
        {
          variant: 'success',
        },
      );
      selectSuggestion(null);
      navigate('../facilities-suggestions');
    } catch (err) {
      enqueueSnackbar(t('cycling-insights.qa.facilities_suggestions.error'), {
        variant: 'error',
      });
      setSubmitting(false);
    }
  }

  async function getSuggestionResults(id: number) {
    if (!currentPartner) return;

    try {
      const results = await StatsService.getSuggestionResults({
        partnerId: currentPartner.id,
        suggestionId: id,
      });
      setSuggestionResults(results);
    } catch (err) {
      enqueueSnackbar(t('cycling-insights.qa.facilities_suggestions.error'), {
        variant: 'error',
      });
    }
  }

  function filterSections() {
    if (suggestionResults && sections) {
      const features: GeoJSON.Feature<GeoJSON.LineString, TSuggestionSectionProperties>[] = [];
      sections.features.forEach(({ id, geometry, properties }) => {
        if (typeof id === 'number') {
          const suggestion = suggestionResults.find(
            ({ osm_way_section_id }) => osm_way_section_id === id,
          );
          if (suggestion) {
            features.push(
              suggestion.is_left_side_suggestion
                ? {
                    type: 'Feature',
                    id: `${id}-backward`,
                    geometry: {
                      ...geometry,
                      coordinates: [...geometry.coordinates].reverse(),
                    },
                    properties: {
                      facility: properties.facilityLeft,
                      id,
                      roadType: properties.roadType,
                      wayName: properties.wayName,
                      color: '#a50026',
                    },
                  }
                : {
                    type: 'Feature',
                    id,
                    geometry: geometry,
                    properties: {
                      facility: properties.facilityRight,
                      id,
                      roadType: properties.roadType,
                      wayName: properties.wayName,
                      color: '#a50026',
                    },
                  },
            );
          }
        }
      });
      update({ type: 'FeatureCollection', features });
    } else update({ type: 'FeatureCollection', features: [] });
  }

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      justifyContent="space-between"
      onSubmit={handleSubmit}
      paddingTop={4}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" flexDirection="column" gap={3} justifyContent="space-between">
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography fontSize="1.125rem" fontWeight={700}>
              <Trans i18nKey="cycling-insights.qa.facilities_suggestions.model" />
            </Typography>
            <FormControl margin="none" size="small" variant="outlined">
              <Select name="model" onChange={handleChange} value={values.model}>
                {models.map((key) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography fontSize="1.125rem" fontWeight={700}>
              <Trans i18nKey="cycling-insights.qa.facilities_suggestions.title" />
            </Typography>
            <FormControl margin="none" size="small" variant="outlined">
              <TextField
                fullWidth
                required
                disabled={isSubmitting}
                error={touched.title && Boolean(errors.title)}
                label={t('commons.title')}
                margin="none"
                name="title"
                onChange={handleChange}
                size="small"
                value={values.title}
                variant="outlined"
              />
            </FormControl>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography fontSize="1.125rem" fontWeight={700}>
              <Trans i18nKey="cycling-insights.qa.facilities_suggestions.budget" />
            </Typography>
            <FormControl margin="none" size="small" variant="outlined">
              <TextField
                fullWidth
                required
                disabled={isSubmitting}
                error={touched.budget && Boolean(errors.budget)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">km</InputAdornment>,
                }}
                margin="none"
                name="budget"
                onChange={handleChange}
                size="small"
                type="number"
                value={values.budget}
                variant="outlined"
              />
            </FormControl>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography fontSize="1.125rem" fontWeight={700}>
              <Trans i18nKey="commons.period" />
            </Typography>
            <TextField
              required
              disabled={isSubmitting}
              error={touched.startDate && Boolean(errors.startDate)}
              id="startDate"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: {
                  max: values.endDate,
                },
              }}
              label={t('cycling-insights.qa.facilities_suggestions.start_date')}
              margin="none"
              name="startDate"
              onChange={handleChange}
              size="small"
              type="date"
              value={values.startDate}
              variant="outlined"
            />
            <TextField
              required
              disabled={isSubmitting}
              error={touched.endDate && Boolean(errors.endDate)}
              id="endDate"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: {
                  min: values.startDate,
                },
              }}
              label={t('cycling-insights.qa.facilities_suggestions.end_date')}
              margin="none"
              name="endDate"
              onChange={handleChange}
              size="small"
              type="date"
              value={values.endDate}
              variant="outlined"
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" gap={3} justifyContent="flex-end">
        <Button
          disableElevation
          disabled={!isValid || isSubmitting}
          size="large"
          type="submit"
          variant="contained"
        >
          <Trans i18nKey="commons.actions.validate" />
        </Button>
      </Box>
    </Box>
  );
}

export default FacilitiesSuggestionsForm;
