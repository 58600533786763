import { Period, Report, TPeriod } from '@geovelo-frontends/commons';
import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import moment from 'moment';
import { Fragment, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import ConfirmDialog from '../../../../components/confirm-dialog';
import PeriodForm, {
  IPeriodFormProps,
  IPeriodFormValues,
} from '../../../../components/form/period';
import Paper from '../../../../components/paper';
import useQueryParams from '../../../../hooks/query-params';

function ExclusionZonesList({
  period,
  loading,
  data,
  highlightedZoneId,
  onDelete,
  setHighlightedZoneId,
  selectZone,
}: {
  data?: Report[];
  highlightedZoneId: number | null;
  loading: boolean;
  onDelete: (id: number | null) => void;
  period: IPeriodFormProps;
  selectZone: (zone?: Report) => void;
  setHighlightedZoneId: (id: number | null) => void;
}): JSX.Element {
  const {
    map: { current: currentMap },
  } = useContext(AppContext);
  const { getPeriods } = useQueryParams();
  const [customPeriodTypes] = useState<{
    defaultPeriods: IPeriodFormValues;
    enabledTypes: TPeriod[];
  }>({
    defaultPeriods: getPeriods(
      new Period('custom', moment().add(-1, 'month').startOf('day'), moment().add(3, 'year')),
    ),
    enabledTypes: ['custom'],
  });
  const [zoneToRemove, setZoneToRemove] = useState<number | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  function fitZone(zone: Report): void {
    if (zone.bounds && !zone.bounds.isEmpty()) {
      currentMap?.fitBounds(zone.bounds, { padding: 50 });
    }
  }

  return (
    <>
      <Paper
        disablePadding
        header={
          <PeriodForm
            disableComparison
            disablePadding
            disablePeriodTypeChange
            customPeriodTypes={customPeriodTypes}
            {...period}
          />
        }
      >
        <Box display="flex" flexDirection="column">
          {data && data.length === 0 ? (
            <Typography
              overflow="hidden"
              paddingX={3}
              paddingY={2}
              textOverflow="ellipsis"
              variant="body2"
              whiteSpace="nowrap"
            >
              <Trans i18nKey="commons.no_filtered_data" />
            </Typography>
          ) : (
            <List>
              {(data || [1, 2, 3]).map((element, index) => {
                const highlighted = typeof element !== 'number' && highlightedZoneId === element.id;

                return (
                  <Fragment key={index}>
                    {index > 0 && <Divider />}
                    <ListItemButton
                      onClick={() => {
                        if (typeof element !== 'number') {
                          fitZone(element);
                          setHighlightedZoneId(element.id);
                        }
                      }}
                      sx={({ palette }) =>
                        highlighted ? { bgcolor: lighten(palette.primary.main, 0.9) } : {}
                      }
                    >
                      <ListItemText
                        primary={
                          typeof element !== 'number' ? (
                            element.description
                          ) : (
                            <Skeleton variant="text" width={200} />
                          )
                        }
                        secondary={
                          typeof element !== 'number' ? (
                            t('commons.periods.from_to', {
                              from: element.startDate?.format('LL'),
                              to: element.endDate?.format('LL'),
                            })
                          ) : (
                            <Skeleton variant="text" width={200} />
                          )
                        }
                      />
                      <ListItemSecondaryAction>
                        {typeof element !== 'number' && (
                          <Box display="flex" gap={1}>
                            <IconButton
                              color="inherit"
                              onClick={(event) => {
                                event.stopPropagation();
                                selectZone(element);
                                navigate('../exclusion-zones-form');
                              }}
                              size="small"
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={(event) => {
                                event.stopPropagation();
                                setZoneToRemove(element.id);
                              }}
                              size="small"
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </Box>
                        )}
                      </ListItemSecondaryAction>
                    </ListItemButton>
                  </Fragment>
                );
              })}
            </List>
          )}
        </Box>
      </Paper>
      <ConfirmDialog
        dialogTitle="exclusion-zone-remove-dialog"
        loading={loading}
        onCancel={() => setZoneToRemove(null)}
        onConfirm={() => {
          onDelete(zoneToRemove);
          setZoneToRemove(null);
        }}
        open={Boolean(zoneToRemove)}
        title={<Trans i18nKey="cycling-insights.qa.exclusion_zones.remove_dialog.title" />}
      />
    </>
  );
}

export default ExclusionZonesList;
