import { useFileSaver } from '@geovelo-frontends/commons';
import { FileDownloadOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { environment } from '../../../../environment';
import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { TQAPageContext } from '../../context';

import ItineraryReportDetail from './details';
import ItineraryReportsForm from './form';

function ItineraryReportsTab(): JSX.Element {
  const context = useOutletContext<TQAPageContext & TOutletContext>();
  const {
    header: { setActions },
    period: {
      values: { current: currentPeriod },
    },
    reports: { reports },
  } = context;
  const { t } = useTranslation();
  const { downloadCSV } = useFileSaver();

  useEffect(() => {
    return () => {
      setActions(undefined);
    };
  }, []);

  useEffect(() => {
    setActions(
      <Tooltip title={<Trans i18nKey="commons.actions.export" />}>
        <span>
          <IconButton
            color="primary"
            disabled={!reports || reports.length === 0}
            onClick={() => handleDownload()}
            size="small"
          >
            <FileDownloadOutlined />
          </IconButton>
        </span>
      </Tooltip>,
    );
  }, [reports]);

  function handleDownload() {
    if (!reports) return;

    downloadCSV(
      `${t('cycling-insights.reports.cartographic_reports.table.title')
        .replace(/ /g, '_')
        .toLowerCase()}-${currentPeriod.from.format('YYYY-MM-DD')}_${currentPeriod.to.format(
        'YYYY-MM-DD',
      )}.csv`,
      [
        'X',
        'Y',
        t('commons.stats.date_label'),
        t('commons.stats.type_label'),
        t('commons.stats.source_label'),
        t('commons.description'),
        t('commons.status'),
        t('commons.stats.links', { count: 1 }),
      ],
      reports.map(({ id, created, source, description, status }) => [
        created.toISOString(),
        t(source?.title || '').toString(),
        `"${(description || '').replace(/"/g, "'")}"`,
        status,
        `${environment.bicycleFacilitiesUrl}fr/route-issues/${id}`,
      ]),
    );
  }

  return (
    <PageContentLayout
      leftPanel={<ItineraryReportsForm {...context} />}
      rightPanel={<ItineraryReportDetail {...context} />}
    />
  );
}

export default ItineraryReportsTab;
