import { Isochrone } from '@geovelo-frontends/commons';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import styled from 'styled-components';

export const colors = [
  { main: 'rgb(48, 99, 142, 0.3)', border: 'rgb(48, 99, 142)', text: '#003D5B' },
  { main: 'rgba(255, 228, 0, 0.3)', border: 'rgb(255, 228, 0)', text: '#B2A000' },
  { main: 'rgba(245, 163, 38, 0.3)', border: 'rgb(245, 163, 38)', text: '#D18C21' },
  { main: 'rgba(231, 63, 61, 0.3)', border: 'rgb(231, 63, 61)', text: '#E73F3D' },
];

interface IProps {
  isochrones: Isochrone[];
  horizontal?: boolean;
  size?: 'large';
}

function IsochronesLegendControl({ isochrones, horizontal, size }: IProps): JSX.Element {
  let min = 0;

  return (
    <Box marginY={-2}>
      <StyledList dense className={horizontal ? 'horizontal' : ''}>
        {[...isochrones]
          .sort((a, b) => a.duration - b.duration)
          .map(({ duration }, index) => {
            const item = (
              <ListItem key={duration}>
                <ListItemIcon sx={{ minWidth: size === 'large' ? 100 : 24 }}>
                  <StyledColor index={isochrones.length - 1 - index} large={size === 'large'} />
                </ListItemIcon>
                <ListItemText
                  primary={`${Math.round(min / 60)} → ${Math.round(duration / 60)} min`}
                  primaryTypographyProps={{
                    noWrap: true,
                    lineHeight: 1,
                    variant: size === 'large' ? 'h4' : undefined,
                  }}
                />
              </ListItem>
            );

            min = duration;

            return item;
          })}
      </StyledList>
    </Box>
  );
}

const StyledColor = styled.div<{ index: number; large: boolean }>`
  background-color: ${({ index }) => colors[index].main};
  border: ${({ index }) => `2px solid ${colors[index].border}`};
  border-radius: 33%;
  height: ${({ large }) => (large ? '50px' : '12px')};
  width: ${({ large }) => (large ? '50px' : '12px')};
`;

const StyledList = styled(List)`
  &.horizontal {
    display: flex;
    flex-direction: row;
  }
`;

export default IsochronesLegendControl;
