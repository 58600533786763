import { Period, PeriodSelector, TPermission } from '@geovelo-frontends/commons';
import {
  Box,
  Fab,
  FabProps,
  Paper,
  PaperProps,
  Tooltip,
  Typography,
  TypographyVariant,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Moment } from 'moment';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button, { TButtonProps } from './button';

export type TAction = TButtonProps & { key: string; needWritePermission?: boolean };

interface IProps {
  actions?: TAction[] | JSX.Element;
  children: ReactNode;
  contentHeader?: ReactNode;
  contentProps?: PaperProps;
  dates?: Moment[];
  onDateIndexSelected?: (index: number) => void;
  onPeriodSelected?: (period: Period) => void;
  period?: Period;
  permission?: TPermission;
  selectedDateIndex?: number;
  subtitle?: string | null;
  title?: ReactNode;
  titleVariant?: TypographyVariant;
}

function Card({
  permission,
  titleVariant,
  title,
  subtitle,
  actions,
  period,
  dates,
  selectedDateIndex,
  children,
  contentHeader,
  contentProps,
  onPeriodSelected,
  onDateIndexSelected,
  ...props
}: IProps): JSX.Element {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();

  return (
    <Wrapper {...props}>
      {(title || actions || dates) && (
        <StyledHeader className="card-header">
          <Typography
            noWrap
            color={titleVariant === 'body1' ? 'textSecondary' : 'primary'}
            component="h2"
            flexDirection="column"
            fontWeight="700"
            lineHeight="2rem"
            variant={titleVariant || 'h5'}
          >
            {title}
          </Typography>
          {actions &&
            ('type' in actions
              ? actions
              : actions
                  .filter(
                    ({ needWritePermission }) => !needWritePermission || permission === 'write',
                  )
                  .map(({ key, needWritePermission, ..._otherProps }) => {
                    if (isLargeScreen) {
                      return <Button key={key} {..._otherProps} />;
                    }

                    const { children, size, startIcon, variant, ...otherProps } = _otherProps;

                    return (
                      startIcon && (
                        <Tooltip key={key} placement="bottom" title={<>{children}</>}>
                          <span>
                            <Fab {...(otherProps as FabProps)}>{startIcon}</Fab>
                          </span>
                        </Tooltip>
                      )
                    );
                  }))}
          {period ? (
            <>
              <StyledSpacer />
              <PeriodSelector
                nextPeriodTooltip={t('commons.periods.actions.next')}
                onPeriodSelected={onPeriodSelected}
                period={period}
                prevPeriodTooltip={t('commons.periods.actions.prev')}
              />
            </>
          ) : (
            dates &&
            selectedDateIndex !== undefined && (
              <>
                <StyledSpacer />
                <PeriodSelector
                  dates={dates}
                  nextPeriodTooltip={t('commons.periods.actions.next')}
                  onDateIndexSelected={onDateIndexSelected}
                  prevPeriodTooltip={t('commons.periods.actions.prev')}
                  selectedDateIndex={selectedDateIndex}
                />
              </>
            )
          )}
        </StyledHeader>
      )}
      {subtitle && (
        <Box marginBottom={2}>
          <Typography color="textSecondary" variant="body2">
            {subtitle}
          </Typography>
        </Box>
      )}
      {contentHeader}
      <StyledContent {...contentProps}>{children}</StyledContent>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2px 0;
`;

const StyledHeader = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 54px;

  h6 {
    font-size: 30px;
    line-height: 36px;

    &.MuiTypography-subtitle1 {
      font-size: 20px;
      line-height: 20px;
    }
  }

  button {
    flex-shrink: 0;
    margin-left: 16px;
  }

  .MuiFab-root {
    height: 36px;
    width: 36px;
  }
`;

const StyledSpacer = styled.div`
  flex-grow: 1;
`;

const StyledContent = styled(Paper)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  overflow-y: auto;
`;

export default Card;
