import {
  Challenge,
  ChallengeCard,
  ChallengeEmptyCard,
  GeogroupService,
  News,
  Period,
  useCancellablePromise,
  useUnits,
} from '@geovelo-frontends/commons';
import { Add, Done, Event, KeyboardArrowRight, ShareOutlined } from '@mui/icons-material';
import { Avatar, Box, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import CreateContentDialog from '../../../../components/create-content-dialog';
import DataCard from '../../../../components/data-card';
import MembershipsIcon from '../../../../components/icons/memberships';
import PlottyBikeIcon from '../../../../components/icons/plotty-bike';
import PlottyUsersIcon from '../../../../components/icons/plotty-users';
import InvitationLinkDialog from '../../../../components/invitation-link-dialog';

const now = moment();

function AnimationContent(): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const [membersCount, setMembersCount] = useState<number>();
  const [newMembersCount, setNewMembersCount] = useState<number>();
  const [currentChallenge, setCurrentChallenge] = useState<Challenge | null>();
  const [invitationLinkDialogOpen, openInvitationLinkDialog] = useState(false);
  const [createContentDialogOpen, openCreateContentDialog] = useState(false);
  const [hasJoinedHighlightedEvent, setHasJoinedHighlightedEvent] = useState<boolean>();
  const {
    app: { highlightedEvent },
    partner: { current: currentPartner, currentGeogroup },
    user: { current: currentUser },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { toDistance } = useUnits();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const {
    cancellablePromise: cancellableChallengePromise,
    cancelPromises: cancelChallengePromise,
  } = useCancellablePromise();

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    update();
  }, [currentPartner]);

  useEffect(() => {
    getCurrentChallenge();

    return () => {
      cancelPromises();
    };
  }, [currentGeogroup]);

  useEffect(() => {
    async function getChallenge() {
      if (!currentPartner?.geoGroupId || !highlightedEvent) return;

      try {
        const { challenges } = await cancellableChallengePromise(
          GeogroupService.getChallenges(currentPartner.geoGroupId, {
            page: 1,
            pageSize: 100,
            period: new Period('custom', highlightedEvent.startDate, highlightedEvent.endDate),
          }),
        );

        setHasJoinedHighlightedEvent(
          Boolean(challenges.find(({ eventId }) => eventId === highlightedEvent.id)),
        );
      } catch (err) {
        if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
          console.error(err);
        }
      }
    }

    if (!initialized) return;

    if (highlightedEvent) getChallenge();

    return () => {
      setHasJoinedHighlightedEvent(undefined);
      cancelChallengePromise();
    };
  }, [initialized, highlightedEvent]);

  async function getCurrentChallenge() {
    if (!currentGeogroup) return;

    try {
      const {
        challenges: [currentChallenge],
      } = await cancellablePromise(
        GeogroupService.getChallenges(currentGeogroup.id, {
          page: 1,
          pageSize: 1,
          state: ['running'],
        }),
      );

      setCurrentChallenge(currentChallenge || null);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('companies.pages.animation.ongoing_challenges.server_error'), {
          variant: 'error',
        });
        setCurrentChallenge(null);
      }
    }
  }

  async function update() {
    setMembersCount(undefined);
    setNewMembersCount(undefined);

    if (!currentPartner?.geoGroupId) return;

    try {
      const [{ count }, { count: newCount }] = await Promise.all([
        GeogroupService.getMembersCount(currentPartner.geoGroupId, {
          partner: currentPartner,
        }),
        GeogroupService.getMembersCount(currentPartner.geoGroupId, {
          partner: currentPartner,
          registrationStartDate: moment().add(-30, 'days').startOf('day'),
          registrationEndDate: moment(),
        }),
      ]);

      setMembersCount(count);
      setNewMembersCount(newCount);
    } catch {
      enqueueSnackbar(t('cycling-insights.community.members.server_error'), { variant: 'error' });
    }
  }

  return (
    <Box
      bgcolor="#F6F7FB"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      gap={5}
      paddingX={14}
      paddingY={8}
      sx={{ overflowY: 'auto' }}
    >
      {highlightedEvent &&
        highlightedEvent.endDate.isAfter(now) &&
        hasJoinedHighlightedEvent !== undefined && (
          <Box display="flex" justifyContent="center">
            <Box
              alignItems="center"
              borderRadius={1}
              display="flex"
              gap={2}
              paddingX={3}
              paddingY={2}
              sx={{
                backgroundColor: hasJoinedHighlightedEvent
                  ? 'rgba(142, 234, 194, 0.2)'
                  : 'rgba(255, 214, 0, 0.29)',
              }}
            >
              <Avatar>
                <Event />
              </Avatar>
              <Typography>
                <Trans
                  i18nKey={
                    hasJoinedHighlightedEvent
                      ? 'cycling-insights.community.challenges.list.highlighted_event.title_joined'
                      : 'cycling-insights.home.participate_in_the_challenge'
                  }
                  values={{ title: highlightedEvent.title }}
                />
              </Typography>
              {hasJoinedHighlightedEvent ? (
                <Button disabled size="medium" startIcon={<Done />} variant="outlined">
                  <Trans i18nKey="cycling-insights.community.challenges.list.highlighted_event.joined" />
                </Button>
              ) : (
                <Button
                  color="primary"
                  component={Link}
                  size="medium"
                  to={`/${currentPartner?.code}/promotion/challenges`}
                  variant="contained"
                >
                  <Trans i18nKey="cycling-insights.home.actions.join_challenge" />
                </Button>
              )}
            </Box>
          </Box>
        )}
      <Box display="flex" justifyContent="space-between">
        <Typography
          color="primary"
          component="h2"
          flexDirection="column"
          fontSize="2rem"
          fontWeight="700"
        >
          <Trans i18nKey="commons.navigation.animation" />
        </Typography>
        <Box display="flex" gap={3}>
          {!currentGeogroup?.isPremium && (
            <Button
              onClick={() => openInvitationLinkDialog(true)}
              size="large"
              startIcon={<ShareOutlined />}
              variant="outlined"
            >
              <Trans i18nKey="cycling-insights.community.members.actions.invite" />
            </Button>
          )}
          <Button
            disableElevation
            onClick={() => openCreateContentDialog(true)}
            size="large"
            startIcon={<Add />}
            variant="contained"
          >
            <Trans i18nKey="commons.actions.create" />
          </Button>
        </Box>
      </Box>
      <Box display="flex" gap={3}>
        <DataCard
          backgroundColor="#2AC68226"
          caption={
            <Typography sx={{ textTransform: 'lowercase' }}>
              <Trans i18nKey="commons.stats.distance_label" />
            </Typography>
          }
          data={toDistance(currentGeogroup?.totalDistance || 0)}
          dataColor="#03825C"
          icon={<PlottyBikeIcon sx={{ height: '52px', width: '57px' }} />}
        />
        <DataCard
          backgroundColor="#D9E7FFCC"
          caption={
            <Typography component={Link} to="../members">
              <Trans i18nKey="commons.actions.manage" />
            </Typography>
          }
          data={
            membersCount !== undefined
              ? t('cycling-insights.community.members.count', { count: membersCount })
              : undefined
          }
          dataColor="#326AC2"
          icon={<PlottyUsersIcon sx={{ height: '72px', width: '80px' }} />}
        />
        {newMembersCount !== 0 && (
          <DataCard
            backgroundColor="#C9E9F8BF"
            caption={
              <Typography sx={{ textTransform: 'lowercase' }}>
                <Trans i18nKey="cycling-insights.community.members.last_30_days" />
              </Typography>
            }
            data={
              newMembersCount !== undefined
                ? t('cycling-insights.community.members.memberships', { count: newMembersCount })
                : undefined
            }
            dataColor="#1589BB"
            icon={<MembershipsIcon sx={{ height: '52px', width: '57px' }} />}
          />
        )}
      </Box>
      <Box display="flex" gap={8}>
        <Box display="flex" flexDirection="column" gap={2} minWidth={380} width="35%">
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Typography color="#283859" fontSize="1.5rem" fontWeight={700}>
              <Trans i18nKey="cycling-insights.community.challenges.title" />
            </Typography>
            <Button
              color="primary"
              component={Link}
              endIcon={<KeyboardArrowRight />}
              to="../challenges"
            >
              <Trans i18nKey="commons.all" />
            </Button>
          </Box>
          {currentChallenge === null ? (
            <ChallengeEmptyCard backgroundColor="#FFF" userType="user" />
          ) : currentChallenge ? (
            <ChallengeCard
              challenge={currentChallenge}
              containerProps={{ flexGrow: 0 }}
              redirectLink={
                currentPartner
                  ? `/${currentPartner.code}/promotion/challenges/${currentChallenge.id}`
                  : '/'
              }
            />
          ) : (
            <ChallengeCard containerProps={{ flexGrow: 0 }} />
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={2} width="65%">
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Typography color="#283859" fontSize="1.5rem" fontWeight={700}>
              <Trans i18nKey="cycling-insights.community.news.title" />
            </Typography>
            <Button
              color="primary"
              component={Link}
              endIcon={<KeyboardArrowRight />}
              to="../news-feed"
            >
              <Trans i18nKey="cycling-insights.community.news.all" />
            </Button>
          </Box>
          <News
            small
            currentPartner={currentPartner}
            currentUser={currentUser}
            geogroup={currentGeogroup}
            newPostRedirection="../new-post"
            userType={'admin'}
          />
        </Box>
      </Box>
      <InvitationLinkDialog
        onClose={() => openInvitationLinkDialog(false)}
        open={invitationLinkDialogOpen}
      />
      <CreateContentDialog
        onClose={() => openCreateContentDialog(false)}
        open={createContentDialogOpen}
      />
    </Box>
  );
}

export default AnimationContent;
