import { TLanguage, UserService, i18nCommons } from '@geovelo-frontends/commons';
import { CardContent, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { languages } from '../../../app/i18n';
import Card from '../../../components/card';

function Language(): JSX.Element {
  const [submitting, setSubmitting] = useState(false);
  const {
    i18n: { language, changeLanguage },
  } = useTranslation();

  async function handleLanguageChange(key: string) {
    setSubmitting(true);

    try {
      await UserService.updateUserParameters({ language: key as TLanguage });
    } catch (err) {
      console.error(err);
    }

    setSubmitting(false);

    try {
      localStorage.setItem('language', key);
    } catch {
      console.error('localStorage access is denied');
    }

    i18nCommons.changeLanguage(key);
    changeLanguage(key);
  }

  return (
    <Card title={<Trans i18nKey="commons.language" />}>
      <CardContent sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
        {Object.values(languages).map(({ key, icon, label }) => {
          return (
            <Tooltip key={key} placement="bottom" title={label}>
              <div>
                <IconButton
                  disabled={submitting}
                  onClick={() => handleLanguageChange(key)}
                  size="small"
                  sx={({
                    palette: {
                      primary: { main },
                    },
                  }) => ({ border: `1px solid ${key === language ? main : 'transparent'}` })}
                >
                  {icon}
                </IconButton>
              </div>
            </Tooltip>
          );
        })}
      </CardContent>
    </Card>
  );
}

export default Language;
