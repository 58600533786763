import { TBNACFacility, TRoadType, TSectionFacility } from '@geovelo-frontends/commons';

export type TSectionProperties = {
  averageSpeed?: number;
  customColor?: string;
  currentAverageSpeed?: number;
  currentExtrapolation?: number;
  currentFrequency?: number;
  cyclability?: number;
  direction?: 'onward' | 'backward';
  distanceTraveled?: number;
  sectionDistanceTraveled?: number;
  sectionFrequency?: number;
  extrapolation?: number;
  facility?: TSectionFacility;
  frequency?: number;
  id: number;
  labelKey?: string;
  osmWayId?: number | null;
  prevAverageSpeed?: number;
  prevExtrapolation?: number;
  prevFrequency?: number;
  lastYearFrequency?: number;
  roadType?: TRoadType | null;
  roughness?: number | null;
  wayName?: string | null;
  discontinuityId?: number;
  discontinuityIndex?: number;
  contactFacility?: TSectionFacility;
  beforeFacility?: boolean;
  tracesCount?: number;
  discontinuityTracesCount?: number;
};

export type TSectionFeatureCollection = GeoJSON.FeatureCollection<
  GeoJSON.LineString,
  TSectionProperties
>;

export type TSectionsStatsContext = {
  data?: TSectionFeatureCollection | null;
  distancesByFacilities?: { [key in TSectionFacility | 'all' | 'allExtrapolated']: number };
  prevDistancesByFacilities?: { [key in TSectionFacility | 'all' | 'allExtrapolated']: number };
  quartiles?: number[];
  totalDistanceTraveled?: number;
  selectedSection?: TSectionProperties;
  selectSection?: (section?: TSectionProperties) => void;
  setData: (data?: TSectionFeatureCollection | null) => void;
  setQuartiles?: (quartiles?: number[]) => void;
  setTotalDistanceTraveled?: (distance?: number) => void;
  setDistancesByFacilities?: (value?: {
    [key in TSectionFacility | 'all' | 'allExtrapolated']: number;
  }) => void;
  setPrevDistancesByFacilities?: (value?: {
    [key in TSectionFacility | 'all' | 'allExtrapolated']: number;
  }) => void;
};

export const cyclabilityIndex: Map<TRoadType, Map<TBNACFacility | 'other', number>> = new Map([
  [
    'cycleway',
    new Map([
      ['autre chemin velo uni', 4],
      ['bande bi', 4],
      ['bande uni', 4],
      ['chaucidou', 4],
      ['chemin dedie uni', 4],
      ['cheminement trottoir uni', 4],
      ['cheminement uni', 4],
      ['velorue', 4],
      ['chemin service site propre uni', 4],
      ['DSC', 4],
      ['DSC bande', 4],
      ['DSC piste', 4],
      ['piste bi', 4],
      ['piste trottoir uni', 4],
      ['piste uni', 4],
      ['shoulder uni', 4],
      ['voie bus uni', 4],
      ['voie bus bi', 4],
      ['voie verte uni', 4],
      ['other', 4],
    ]),
  ],
  [
    'footway',
    new Map([
      ['autre chemin velo uni', 2],
      ['bande bi', 1],
      ['bande uni', 1],
      ['chaucidou', 1],
      ['chemin dedie uni', 3],
      ['cheminement trottoir uni', 3],
      ['cheminement uni', 1],
      ['velorue', 1],
      ['chemin service site propre uni', 1],
      ['DSC', 1],
      ['DSC bande', 1],
      ['DSC piste', 1],
      ['piste bi', 1],
      ['piste trottoir uni', 1],
      ['piste uni', 1],
      ['shoulder uni', 1],
      ['voie bus uni', 1],
      ['voie bus bi', 1],
      ['voie verte uni', 1],
      ['other', 1],
    ]),
  ],
  [
    'living_street',
    new Map([
      ['autre chemin velo uni', 3],
      ['bande bi', 3],
      ['bande uni', 3],
      ['chaucidou', 3],
      ['chemin dedie uni', 3],
      ['cheminement trottoir uni', 3],
      ['cheminement uni', 3],
      ['velorue', 3],
      ['chemin service site propre uni', 3],
      ['DSC', 1],
      ['DSC bande', 1],
      ['DSC piste', 4],
      ['piste bi', 4],
      ['piste trottoir uni', 4],
      ['piste uni', 4],
      ['shoulder uni', 3],
      ['voie bus uni', 3],
      ['voie bus bi', 3],
      ['voie verte uni', 4],
      ['other', 3],
    ]),
  ],
  [
    'path',
    new Map([
      ['autre chemin velo uni', 2],
      ['bande bi', 1],
      ['bande uni', 1],
      ['chaucidou', 1],
      ['chemin dedie uni', 3],
      ['cheminement trottoir uni', 1],
      ['cheminement uni', 3],
      ['velorue', 1],
      ['chemin service site propre uni', 1],
      ['DSC', 1],
      ['DSC bande', 1],
      ['DSC piste', 1],
      ['piste bi', 1],
      ['piste trottoir uni', 1],
      ['piste uni', 1],
      ['shoulder uni', 1],
      ['voie bus uni', 1],
      ['voie bus bi', 1],
      ['voie verte uni', 4],
      ['other', 1],
    ]),
  ],
  [
    'pedestrian',
    new Map([
      ['autre chemin velo uni', 3],
      ['bande bi', 3],
      ['bande uni', 3],
      ['chaucidou', 3],
      ['chemin dedie uni', 3],
      ['cheminement trottoir uni', 3],
      ['cheminement uni', 3],
      ['velorue', 3],
      ['chemin service site propre uni', 3],
      ['DSC', 1],
      ['DSC bande', 1],
      ['DSC piste', 4],
      ['piste bi', 4],
      ['piste trottoir uni', 4],
      ['piste uni', 4],
      ['shoulder uni', 3],
      ['voie bus uni', 3],
      ['voie bus bi', 3],
      ['voie verte uni', 3],
      ['other', 3],
    ]),
  ],
  [
    'primary',
    new Map([
      ['autre chemin velo uni', 1],
      ['bande bi', 1],
      ['bande uni', 1],
      ['chaucidou', 1],
      ['chemin dedie uni', 1],
      ['cheminement trottoir uni', 1],
      ['cheminement uni', 1],
      ['velorue', 1],
      ['chemin service site propre uni', 1],
      ['DSC', 1],
      ['DSC bande', 1],
      ['DSC piste', 4],
      ['piste bi', 4],
      ['piste trottoir uni', 4],
      ['piste uni', 4],
      ['shoulder uni', 1],
      ['voie bus uni', 3],
      ['voie bus bi', 3],
      ['voie verte uni', 1],
      ['other', 1],
    ]),
  ],
  [
    'primary_link',
    new Map([
      ['autre chemin velo uni', 1],
      ['bande bi', 1],
      ['bande uni', 1],
      ['chaucidou', 1],
      ['chemin dedie uni', 1],
      ['cheminement trottoir uni', 1],
      ['cheminement uni', 1],
      ['velorue', 1],
      ['chemin service site propre uni', 1],
      ['DSC', 1],
      ['DSC bande', 1],
      ['DSC piste', 4],
      ['piste bi', 4],
      ['piste trottoir uni', 4],
      ['piste uni', 4],
      ['shoulder uni', 1],
      ['voie bus uni', 3],
      ['voie bus bi', 3],
      ['voie verte uni', 1],
      ['other', 1],
    ]),
  ],
  [
    'residential',
    new Map([
      ['autre chemin velo uni', 3],
      ['bande bi', 3],
      ['bande uni', 3],
      ['chaucidou', 3],
      ['chemin dedie uni', 3],
      ['cheminement trottoir uni', 3],
      ['cheminement uni', 3],
      ['velorue', 3],
      ['chemin service site propre uni', 3],
      ['DSC', 1],
      ['DSC bande', 2],
      ['DSC piste', 4],
      ['piste bi', 4],
      ['piste trottoir uni', 4],
      ['piste uni', 4],
      ['shoulder uni', 3],
      ['voie bus uni', 3],
      ['voie bus bi', 3],
      ['voie verte uni', 3],
      ['other', 3],
    ]),
  ],
  [
    'secondary',
    new Map([
      ['autre chemin velo uni', 1],
      ['bande bi', 1],
      ['bande uni', 1],
      ['chaucidou', 1],
      ['chemin dedie uni', 1],
      ['cheminement trottoir uni', 1],
      ['cheminement uni', 1],
      ['velorue', 1],
      ['chemin service site propre uni', 1],
      ['DSC', 1],
      ['DSC bande', 1],
      ['DSC piste', 4],
      ['piste bi', 4],
      ['piste trottoir uni', 4],
      ['piste uni', 4],
      ['shoulder uni', 1],
      ['voie bus uni', 3],
      ['voie bus bi', 3],
      ['voie verte uni', 1],
      ['other', 1],
    ]),
  ],
  [
    'secondary_link',
    new Map([
      ['autre chemin velo uni', 1],
      ['bande bi', 1],
      ['bande uni', 1],
      ['chaucidou', 1],
      ['chemin dedie uni', 1],
      ['cheminement trottoir uni', 1],
      ['cheminement uni', 1],
      ['velorue', 1],
      ['chemin service site propre uni', 1],
      ['DSC', 1],
      ['DSC bande', 1],
      ['DSC piste', 4],
      ['piste bi', 4],
      ['piste trottoir uni', 4],
      ['piste uni', 4],
      ['shoulder uni', 1],
      ['voie bus uni', 3],
      ['voie bus bi', 3],
      ['voie verte uni', 1],
      ['other', 1],
    ]),
  ],
  [
    'service',
    new Map([
      ['autre chemin velo uni', 2],
      ['bande bi', 2],
      ['bande uni', 2],
      ['chaucidou', 2],
      ['chemin dedie uni', 2],
      ['cheminement trottoir uni', 2],
      ['cheminement uni', 2],
      ['velorue', 2],
      ['chemin service site propre uni', 2],
      ['DSC', 1],
      ['DSC bande', 1],
      ['DSC piste', 4],
      ['piste bi', 4],
      ['piste trottoir uni', 4],
      ['piste uni', 4],
      ['shoulder uni', 2],
      ['voie bus uni', 3],
      ['voie bus bi', 3],
      ['voie verte uni', 2],
      ['other', 2],
    ]),
  ],
  [
    'tertiary',
    new Map([
      ['autre chemin velo uni', 2],
      ['bande bi', 2],
      ['bande uni', 2],
      ['chaucidou', 2],
      ['chemin dedie uni', 2],
      ['cheminement trottoir uni', 2],
      ['cheminement uni', 2],
      ['velorue', 2],
      ['chemin service site propre uni', 2],
      ['DSC', 1],
      ['DSC bande', 1],
      ['DSC piste', 4],
      ['piste bi', 4],
      ['piste trottoir uni', 4],
      ['piste uni', 4],
      ['shoulder uni', 2],
      ['voie bus uni', 3],
      ['voie bus bi', 3],
      ['voie verte uni', 2],
      ['other', 2],
    ]),
  ],
  [
    'tertiary_link',
    new Map([
      ['autre chemin velo uni', 2],
      ['bande bi', 2],
      ['bande uni', 2],
      ['chaucidou', 2],
      ['chemin dedie uni', 2],
      ['cheminement trottoir uni', 2],
      ['cheminement uni', 2],
      ['velorue', 2],
      ['chemin service site propre uni', 2],
      ['DSC', 1],
      ['DSC bande', 1],
      ['DSC piste', 4],
      ['piste bi', 4],
      ['piste trottoir uni', 4],
      ['piste uni', 4],
      ['shoulder uni', 2],
      ['voie bus uni', 3],
      ['voie bus bi', 3],
      ['voie verte uni', 2],
      ['other', 2],
    ]),
  ],
  [
    'track',
    new Map([
      ['autre chemin velo uni', 2],
      ['bande bi', 1],
      ['bande uni', 1],
      ['chaucidou', 1],
      ['chemin dedie uni', 3],
      ['cheminement trottoir uni', 1],
      ['cheminement uni', 4],
      ['velorue', 1],
      ['chemin service site propre uni', 4],
      ['DSC', 1],
      ['DSC bande', 2],
      ['DSC piste', 4],
      ['piste bi', 4],
      ['piste trottoir uni', 4],
      ['piste uni', 4],
      ['shoulder uni', 2],
      ['voie bus uni', 3],
      ['voie bus bi', 3],
      ['voie verte uni', 1],
      ['other', 1],
    ]),
  ],
  [
    'trunk',
    new Map([
      ['autre chemin velo uni', 1],
      ['bande bi', 1],
      ['bande uni', 1],
      ['chaucidou', 1],
      ['chemin dedie uni', 1],
      ['cheminement trottoir uni', 1],
      ['cheminement uni', 1],
      ['velorue', 1],
      ['chemin service site propre uni', 1],
      ['DSC', 1],
      ['DSC bande', 1],
      ['DSC piste', 4],
      ['piste bi', 4],
      ['piste trottoir uni', 4],
      ['piste uni', 4],
      ['shoulder uni', 1],
      ['voie bus uni', 1],
      ['voie bus bi', 1],
      ['voie verte uni', 1],
      ['other', 1],
    ]),
  ],
  [
    'trunk_link',
    new Map([
      ['autre chemin velo uni', 1],
      ['bande bi', 1],
      ['bande uni', 1],
      ['chaucidou', 1],
      ['chemin dedie uni', 1],
      ['cheminement trottoir uni', 1],
      ['cheminement uni', 1],
      ['velorue', 1],
      ['chemin service site propre uni', 1],
      ['DSC', 1],
      ['DSC bande', 1],
      ['DSC piste', 4],
      ['piste bi', 4],
      ['piste trottoir uni', 4],
      ['piste uni', 4],
      ['shoulder uni', 1],
      ['voie bus uni', 1],
      ['voie bus bi', 1],
      ['voie verte uni', 1],
      ['other', 1],
    ]),
  ],
  [
    'unclassified',
    new Map([
      ['autre chemin velo uni', 3],
      ['bande bi', 3],
      ['bande uni', 3],
      ['chaucidou', 3],
      ['chemin dedie uni', 3],
      ['cheminement trottoir uni', 3],
      ['cheminement uni', 3],
      ['velorue', 3],
      ['chemin service site propre uni', 3],
      ['DSC', 1],
      ['DSC bande', 2],
      ['DSC piste', 4],
      ['piste bi', 4],
      ['piste trottoir uni', 4],
      ['piste uni', 4],
      ['shoulder uni', 3],
      ['voie bus uni', 3],
      ['voie bus bi', 3],
      ['voie verte uni', 3],
      ['other', 3],
    ]),
  ],
]);
