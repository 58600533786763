import { GeoveloIcon, GeoveloTextIcon, useFileSaver } from '@geovelo-frontends/commons';
import { CalendarMonthOutlined } from '@mui/icons-material';
import {
  Box,
  DialogProps,
  FormControl,
  FormLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../../../app/context';
import Dialog from '../../../../components/dialog';
import DepartureIcon from '../../../../components/icons/departure';
import NorthIcon from '../../../../components/icons/north';
import Map from '../../../../components/map';
import useOriginDestinationFlows from '../../../../hooks/map/old-origin-destination-flows';
import usePeriod from '../../../../hooks/period';
import { TOutletContext } from '../../../../layouts/page/container';
import { IBicycleObservatoryPageContext } from '../../context';

import { Map as MaplibreMap } from '!maplibre-gl';

type TProps = Omit<DialogProps, 'onClose'> & { onClose: () => void };

function PreviewDialog({ onClose, ...props }: TProps): JSX.Element {
  const { t } = useTranslation();
  const [map, setMap] = useState<MaplibreMap>();
  const printRef = useRef<HTMLDivElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [downloading, setDownloading] = useState(false);
  const [downloadType, setDownloadType] = useState<'PNG' | 'CSV'>('PNG');
  const context = useOutletContext<IBicycleObservatoryPageContext & TOutletContext>();
  const {
    period,
    oldOriginDestination: {
      zones,
      externalZones,
      flows,
      currentRange,
      selectedZoneId,
      internalFlowsCount,
      externalFlowsCount,
    },
  } = context;
  const {
    map: { baseLayer },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { downloadBlob, downloadCSV } = useFileSaver();
  const { getTitle } = usePeriod();
  const {
    init: initLayers,
    update: updateLayers,
    destroy: destroyLayers,
  } = useOriginDestinationFlows(map, { disableController: true });

  useEffect(() => {
    if (!props.open) destroyLayers();
  }, [props.open]);

  useEffect(() => {
    if (map && canvasRef.current) {
      initLayers(canvasRef.current);
      updateLayers(zones, externalZones, flows, currentRange, selectedZoneId);
    }
  }, [map, canvasRef.current]);

  async function handleDownload() {
    setDownloading(true);

    if (downloadType === 'PNG') {
      if (printRef.current) {
        const canvas = await html2canvas(printRef.current, { allowTaint: true });

        const blob = await new Promise<Blob>((resolve, reject) => {
          try {
            canvas.toBlob(
              (blob) => {
                if (!blob) throw new Error('no blob');
                else resolve(blob);
              },
              'image/png',
              1.0,
            );
          } catch (err) {
            reject(err);
          }
        });

        downloadBlob(`origin-destination.png`, blob);
      }
    } else if (flows && zones) {
      downloadCSV(
        `${t('cycling-insights.usage.origin_destination.all_rides')
          .replace(/ /g, '_')
          .toLowerCase()}-${period.values.current.from.format(
          'YYYY-MM-DD',
        )}_${period.values.current.to.format('YYYY-MM-DD')}.csv`,
        [
          t('cycling-insights.usage.origin_destination.origin'),
          t('cycling-insights.usage.origin_destination.destination'),
          t('commons.stats_types.journeys'),
        ],
        flows.map(({ origin, destination, count }) => [
          zones.find(({ id }) => id === origin)?.name ||
            externalZones?.find(({ id }) => id === origin)?.name ||
            '',
          zones.find(({ id }) => id === destination)?.name ||
            externalZones?.find(({ id }) => id === destination)?.name ||
            '',
          count,
        ]),
      );
    }

    setDownloading(false);
    onClose();
  }

  return (
    <Dialog
      disableContentPadding
      confirmTitle={<Trans i18nKey={'commons.actions.download'} />}
      dialogTitle="facilities-download-dialog"
      loading={downloading}
      maxWidth="sm"
      onCancel={onClose}
      onConfirm={handleDownload}
      title={<Trans i18nKey="cycling-insights.usage.origin_destination.export_dialog.title" />}
      {...props}
    >
      <Box
        borderTop="1px solid #E3E7EE"
        display="flex"
        flexDirection="column"
        marginX={3}
        paddingTop={2}
      >
        <Typography fontSize="1rem" fontWeight={700}>
          <Trans i18nKey="cycling-insights.usage.origin_destination.export_dialog.custom_format" />
        </Typography>
        <FormControl margin="normal">
          <FormLabel component="legend" id="format-label" sx={{ fontSize: '0.875rem' }}>
            <Trans i18nKey="cycling-insights.facilities.cyclability_zones.download_dialog.format" />
          </FormLabel>
          <Select
            labelId="format-label"
            name="format"
            onChange={({ target: { value } }) => setDownloadType(value as 'PNG' | 'CSV')}
            size="small"
            value={downloadType}
          >
            <MenuItem value="PNG">PNG</MenuItem>
            <MenuItem value="CSV">CSV</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {downloadType === 'PNG' && (
        <>
          <Typography fontSize="1rem" fontWeight={700} marginBottom={1} marginTop={2} paddingX={3}>
            <Trans i18nKey="cycling-insights.usage.origin_destination.export_dialog.preview" />
          </Typography>
          <Box paddingX={3} paddingY={2} ref={printRef}>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="column">
                <Typography variant="subtitle2">
                  <Trans i18nKey="cycling-insights.usage.origin_destination.export_dialog.subtitle" />
                </Typography>
                <Box alignItems="center" display="flex" flexDirection="row" gap={1}>
                  <DepartureIcon />
                  <Typography variant="body2">
                    {selectedZoneId
                      ? zones?.find((zone) => zone.id === selectedZoneId)?.name
                      : currentPartner?.title}
                  </Typography>
                </Box>
                <Box alignItems="center" display="flex" flexDirection="row" gap={1}>
                  <CalendarMonthOutlined />
                  <Typography variant="body2">{getTitle(period.values.current)}</Typography>
                </Box>
              </Box>
              <Box alignItems="center" display="flex" flexDirection="column">
                <GeoveloIcon style={{ height: 50, width: 50 }} />
                <GeoveloTextIcon style={{ height: 20, width: 'auto' }} />
              </Box>
            </Box>
            <Box height="418px" margin="16px 0" position="relative" width="552px">
              {props.open && (
                <StyledMap
                  disableInteractions
                  disableZoomControl
                  hasScaleControl
                  baseLayer={baseLayer}
                  mapId="isochrones-map"
                  onInit={setMap}
                >
                  <StyledNorthIcon />
                </StyledMap>
              )}
              <StyledCanvas id="origin-destination-export-deck-canvas" ref={canvasRef} />
            </Box>
            {flows && (
              <>
                <Typography variant="subtitle2">
                  <Trans i18nKey="cycling-insights.usage.origin_destination.export_dialog.caption" />
                </Typography>
                <Box display="flex" flexDirection="row" height="16px" width="100%">
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    marginTop={1}
                    style={{ backgroundColor: '#3E7BDF' }}
                    width={`${
                      (100 * internalFlowsCount) / (internalFlowsCount + externalFlowsCount)
                    }%`}
                  />
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    marginTop={1}
                    style={{ backgroundColor: '#FFD12F' }}
                    width={`${
                      (100 * externalFlowsCount) / (internalFlowsCount + externalFlowsCount)
                    }%`}
                  />
                </Box>
                <StyledList dense>
                  <ListItem>
                    <StyledListItemIcon>
                      <StyledColor color="#3E7BDF" />
                    </StyledListItemIcon>
                    <ListItemText
                      primary={t('cycling-insights.usage.origin_destination.internal_flows', {
                        count: internalFlowsCount,
                      })}
                      primaryTypographyProps={{ noWrap: true }}
                    />
                  </ListItem>
                  <ListItem>
                    <StyledListItemIcon>
                      <StyledColor color="#FFD12F" />
                    </StyledListItemIcon>
                    <ListItemText
                      primary={t('cycling-insights.usage.origin_destination.incoming_flows', {
                        count: externalFlowsCount,
                      })}
                      primaryTypographyProps={{ noWrap: true }}
                    />
                  </ListItem>
                </StyledList>
              </>
            )}
            <Typography alignSelf="flex-end" marginTop={2} variant="body2">
              Sources: Les Contributeurs OpenStreetMap ; geovelo - {moment().format('MMMM YYYY')}
            </Typography>
          </Box>
        </>
      )}
    </Dialog>
  );
}

const StyledMap = styled(Map)`
  height: 100%;
  width: 100%;
  position: relative;
`;

const StyledCanvas = styled.canvas`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  cursor: default !important;
`;

const StyledNorthIcon = styled(NorthIcon)`
  z-index: 2;
  position: absolute;
  left: 8px;
  bottom: 8px;
  && {
    height: 36px;
    width: 36px;
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  && {
    min-width: 24px;
  }
`;

const StyledColor = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: 4px;
  height: 12px;
  width: 12px;
`;

const StyledList = styled(List)`
  display: flex;
  flex-direction: row;
`;

export default PreviewDialog;
