import { Box, Card, CardContent } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import TabIntroduction from '../../../../components/tab-introduction';
import { TOutletContext } from '../../../../layouts/page/container';
import { TCartographicDataPageContext } from '../../context';
import useAccidentology from '../../hooks/accidentology';
import { TH3CellData } from '../../models/accidentology';
import AccidentZonesRanking, { sortData } from '../accidentology/ranking';

function AccidentZonesList(context: TCartographicDataPageContext & TOutletContext): JSX.Element {
  const {
    header: { setPrevButtonClick },
    period,
    accidentology: { selectedH3Index, selectH3Index },
  } = context;
  const [allItems, setAllItems] = useState<TH3CellData[]>();
  const [displayedItems, setDisplayedItems] = useState<TH3CellData[]>();
  const [displayedItemsCount, setDisplayedItemsCount] = useState(10);
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { h3Map, data } = useAccidentology(context);

  useEffect(() => {
    setPrevButtonClick(() => () => navigate('../accidentology'));

    return () => {
      setPrevButtonClick(undefined);
      selectH3Index(null);
    };
  }, []);

  useEffect(() => {
    if (currentPartner && h3Map && data) {
      setAllItems(sortData(h3Map, data));
    }

    return () => setAllItems(undefined);
  }, [h3Map, data]);

  useEffect(() => {
    setDisplayedItems(allItems?.slice(0, displayedItemsCount));

    return () => setDisplayedItems(undefined);
  }, [allItems, displayedItemsCount]);

  return (
    <Box display="flex" flexDirection="column" minHeight="100%">
      <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.accident_zones" />
      <Box display="flex" flexDirection="column" gap={3} marginTop={3}>
        <Card elevation={0} sx={{ borderRadius: 4 }}>
          <CardContent
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, '&&': { padding: 0 } }}
          >
            <AccidentZonesRanking
              data={displayedItems}
              h3Map={h3Map}
              period={period.values.current}
              selectedH3Index={selectedH3Index}
              selectH3Index={selectH3Index}
            />
            <Box alignSelf="center" padding={2}>
              <Button
                disabled={!allItems || !displayedItems || displayedItemsCount >= allItems.length}
                onClick={() => setDisplayedItemsCount(displayedItemsCount + 10)}
                size="small"
                variant="outlined"
              >
                {t('cycling-insights.usage.accidentology.accident_zones.actions.see_more')}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default AccidentZonesList;
